import store from '../../store';
import authenticate from './authenticate';

/**
 * Before the route can be opened the JWT token should not be expired
 *
 * @param {Object} to - The object containing the to route information
 * @param {Object} from - The object containing the from route information
 * @param {function} next - The next handler
 * @return {Promise<void>}
 */
const beforeEnter = async (to, from, next) => {
    if (store.getters['account/getAuthenticated']) {
        //already signed in, we can navigate anywhere
        next();
    } else if (to.matched.some((record) => record.meta.requiresAuth)) {
        //authentication is required. Trigger the sign in process, including the return URI
        authenticate(to.path).then(() => {
            // console.log('authenticating a protected url:' + to.path);
            next();
        });
    } else {
        //No auth required. We can navigate
        next();
    }
    const prev = localStorage.getItem('prev');
    if (prev && prev != to.fullPath) {
        localStorage.removeItem('van');
        localStorage.removeItem('tot');
    }
    localStorage.setItem('prev', to.fullPath);
};

export default beforeEnter;

