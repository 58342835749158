<template>
    <b-card @submit.stop.prevent="handleSubmit" no-body>
        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
            {{ alertText }}
        </b-alert>
        <b-tabs card>
            <b-tab title="Normen">
                <b-card-text>
                    <div>
                        <b-table
                            striped
                            responsive
                            :fields="detailNormFields"
                            :items="localNormList"
                            stacked="md"
                            show-empty
                            :empty-text="$t('com_geen_data', { ns: 'common' })"
                            small
                        >
                            <template v-slot:cell(actions)="row">
                                <div class="form-inline">
                                    <!--                                   <b-button
                                        v-if="checkRight('AnalyticsConfigure')"
                                        size="sm"
                                        @click="bewerkItem(row.item)"
                                        class="bgc_aux_warning"
                                        ><font-awesome-icon
                                            icon="edit"
                                            size="1x"
                                        />
                                    </b-button> -->
                                    <b-button
                                        v-if="checkRight('AnalyticsConfigure')"
                                        size="sm"
                                        @click="verwijderItem(row.item)"
                                        class="bgc_aux_alarm"
                                        ><font-awesome-icon
                                            icon="trash-alt"
                                            size="1x"
                                        />
                                    </b-button>
                                </div>
                            </template>
                        </b-table>
                    </div>
                </b-card-text>
            </b-tab>
            <activity-log
                paramName="investigationquantityID"
                action="analytics/loadIQActiviteitenLijst"
                :id="detailID"
            />
        </b-tabs>
        <modal-norm
            v-if="detailsVisible"
            v-model="detailsVisible"
            v-bind:inItem="selectednorm"
            v-bind:idIQ="detailID"
            @hide="reset"
        />
    </b-card>
</template>

<script>
import { Permission } from '../../models/Permissions';
import ActivityLog from '../components/ActivityLog.vue';
import Helper from '../../models/Helper';
import { loadItem } from './utils';
import ModalNorm from './ModalNorm.vue';
export default {
    name: 'InvestQuantityCard',
    data: function () {
        return {
            showDismissibleAlert: false,
            alertText: '',
            detailsVisible: false,
            selectednorm: null,
            verwijderString: this.$t('com_delete', {
                ns: 'common',
                val: Helper.stringformat(
                    '{0} {1} {2}',
                    this.$t('an_norm', { ns: 'analytics' }).toLowerCase(),
                    this.$t('com_voor', { ns: 'common' }).toLowerCase(),
                    this.$t('an_meetplaats', { ns: 'analytics' }).toLowerCase()
                )
            })
        };
    },
    model: {
        prop: 'visible',
        event: 'changed'
    },
    props: {
        normList: {
            type: Array
        },
        detailID: {
            type: Number
        },
        editAllowed: {
            type: Boolean,
            default: false
        }
    },
    components: {
        ActivityLog,
        ModalNorm
    },
    watch: {},
    computed: {
        detailNormFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                {
                    key: 'meetplekNaam',
                    label: this.$t('an_meetplaats', { ns: 'analytics' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'normwaarde',
                    label: this.$t('an_norm', { ns: 'analytics' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'eenheidNaam',
                    label: this.$t('com_unit', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'formuleDisplay',
                    label: this.$t('an_grenzen', { ns: 'analytics' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        localNormList() {
            return this.normList;
        }
    },
    methods: {
        reset() {
            this.detailsVisible = false;
        },
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        async bewerkItem(row) {
            this.selectednorm = await loadItem(
                'analytics/loadNorm',
                {
                    entityObjectID: row.meetplekID,
                    invQuantityID: row.onderzoeksgrootheidID
                },
                this.$t
            );
            this.detailsVisible = !this.detailsVisible;
        },
        async verwijderItem(row) {
            const bericht = Helper.stringformat(
                '{0}: {1}',
                this.verwijderString,
                row.meetplekNaam
            );
            const doClose = await this.$root.showConfirmationBox(bericht);
            if (doClose) {
                await loadItem(
                    'analytics/deleteNorm',
                    {
                        eonormID: row.id
                    },
                    this.$t
                );
            }
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        }
    },
    mounted() {},
    created() {}
};
</script>

<style scoped></style>

