const getters = {
    getOstLijst: (state) => {
        return state.ostLijst;
    },
    getVersionInfo: (state) => {
        return state.versionInfo;
    },
    getPackageVersion: (state) => {
        return state.packageVersion;
    },
    getLocationLijst: (state) => {
        return state.locationLijst;
    },
    getCompanyLijst: (state) => {
        return state.companyLijst;
    },
    getShapesLijst: (state) => {
        return state.shapesLijst;
    },
    getEquipmentLijst: (state) => {
        return state.equipmentLijst;
    },
    getStockLocationTypesLijst: (state) => {
        return state.stockLocationTypesLijst;
    },
    getConstantsLijst: (state) => {
        return state.constantsLijst;
    },
    getBerichtenLijst: (state) => {
        return state.berichtenLijst;
    },
    getReportGroupLijst: (state) => {
        return state.reportgroupLijst;
    }
};

export default getters;

