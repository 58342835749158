<template>
    <b-modal
        id="modal-entityobject"
        size="lg"
        ref="modal"
        :title="this.formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
    >
        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
            {{ alertText }}
        </b-alert>

        <div>
            <b-card>
                <b-tabs card>
                    <b-tab
                        :title="$t('an_eo', { ns: 'analytics' })"
                        v-bind:key="1"
                        active
                    >
                        <b-card-text>
                            <b-row>
                                <b-col sm="3">
                                    <label for="input-itemnaam"
                                        >{{
                                            $t('com_naam', { ns: 'common' })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <b-form-input
                                        id="input-itemnaam"
                                        :placeholder="
                                            $t('com_naam', { ns: 'common' })
                                        "
                                        v-model="naam"
                                        :disabled="!magEdit"
                                        @change="setChanged"
                                        :state="
                                            naam != undefined && naam.length > 2
                                        "
                                        autofocus
                                    ></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row v-if="isNieuw">
                                <b-col sm="3">
                                    <label
                                        >{{
                                            $t('com_categorie', {
                                                ns: 'common'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <b-input-group class="mb-2">
                                        <b-input-group-prepend is-text>
                                            <b-form-checkbox
                                                class="mr-n2"
                                                v-model="doselect"
                                                value="true"
                                                unchecked-value="false"
                                                :disabled="!magEdit"
                                            >
                                                <span class="sr-only">{{
                                                    $t('com_free', {
                                                        ns: 'common'
                                                    })
                                                }}</span>
                                            </b-form-checkbox>
                                        </b-input-group-prepend>
                                        <b-form-select
                                            v-if="!writable"
                                            v-model="selectedType"
                                            :options="eotypes"
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        >
                                            <template #first>
                                                <b-form-select-option value=""
                                                    >--
                                                    {{
                                                        $t('com_selecteer', {
                                                            ns: 'common'
                                                        })
                                                    }}
                                                    --</b-form-select-option
                                                >
                                            </template>
                                        </b-form-select>
                                        <b-form-input
                                            v-if="writable"
                                            v-model="selectedType"
                                        ></b-form-input>
                                    </b-input-group>
                                </b-col>
                            </b-row>
                            <template-object-editor
                                v-model="localItem.templateID"
                                :disabled="!editAllowed"
                                @change="setChanged"
                                @touched="setTouched"
                            >
                            </template-object-editor>
                            <b-row>
                                <div v-if="localItem.externref">
                                    <b-col sm="3">
                                        <label for="input-guid"
                                            >{{
                                                $t('com_externalid', {
                                                    ns: 'common'
                                                })
                                            }}:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            id="input-guid"
                                            v-model="localItem.externref"
                                            readonly
                                        ></b-form-input>
                                    </b-col>
                                </div>
                                <div
                                    v-if="localItem.barcode"
                                    class="form-inline"
                                >
                                    <b-col>
                                        <barcode
                                            v-bind:value="localItem.barcode"
                                            id="targetDiv"
                                        >
                                            {{ nodisplay }}
                                        </barcode>
                                    </b-col>
                                    <b-col cols="2">
                                        <b-button
                                            @click="PrintElem()"
                                            class="bgc_aux_unimportant"
                                            ><font-awesome-icon
                                                icon="print"
                                                size="1x"
                                            /> </b-button
                                    ></b-col>
                                </div>
                            </b-row>
                        </b-card-text>
                    </b-tab>
                    <b-tab
                        v-if="localItem.id > 0"
                        :title="$t('com_bijlagen', { ns: 'common' })"
                        v-bind:key="3"
                        @click="loadAttachments()"
                        lazy
                    >
                        <b-card-text>
                            <file-upload
                                :attID="localItem.id"
                                :attType="attachmentType"
                                @input="loadAttachments"
                                @queue="setQueuedAmount"
                            />
                            <b-table
                                :items="attachmentLijst"
                                :fields="visibleFields"
                                stacked="md"
                                show-empty
                                :empty-text="
                                    $t('com_geen_data', { ns: 'common' })
                                "
                                small
                                striped
                            >
                                <template v-slot:cell(actions)="row">
                                    <b-button
                                        size="sm"
                                        @click="showAttachment(row.item)"
                                        class="bgc_aux_normal"
                                        ><font-awesome-icon
                                            icon="edit"
                                            size="1x"
                                        />
                                    </b-button>
                                    <b-button
                                        size="sm"
                                        @click="verwijderAttachment(row.item)"
                                        class="bgc_aux_alarm"
                                        ><font-awesome-icon
                                            icon="trash-alt"
                                            size="1x"
                                        />
                                    </b-button>
                                </template>

                                <template v-slot:row-details> </template>
                            </b-table>
                        </b-card-text>
                    </b-tab>
                </b-tabs>
            </b-card>
        </div>
    </b-modal>
</template>

<script>
import VueBarcode from 'vue-barcode';
import { loadList, loadItem } from './utils';
import { EntityObject } from '../../models/Anayltics';
import TemplateObjectEditor from './TemplateObjectEditor.vue';
import { AttachmentInfo } from '@/models/PlusProduction';
import { FileUpload } from '../components';
export default {
    name: 'ModalEntityObject',
    data: function () {
        return {
            eotypes: [],
            lctypeTemplateObject: window.constants.LCA_TEMPLATEOBJECT,
            attachmentType: window.constants.ATT_ENTITYOBJECT,
            queuedAmount: 0,
            attachmentLijst: [], // as Array<AttachmentInfo>,
            strDeleteAttachment: this.$t('com_delete', {
                ns: 'common',
                val: this.$t('com_bijlage', { ns: 'common' })
            }),
            showDismissibleAlert: false,
            alertText: '',
            isChanged: false,
            naam: null,
            locItem: new EntityObject(),
            returnObject: null,
            editAllowed: true,
            templateChanged: false,
            doselect: 'true',
            selectedType: '',
            isNieuw: false,

            nodisplay: this.$t('com_nodisplaybarcode', { ns: 'common' }),
            berichtWijziging: this.$t('an_warnxwijzigopslaan', {
                ns: 'analytics',
                val: this.$t('an_template', { ns: 'analytics' })
            })
        };
    },
    model: {
        prop: 'visible',
        event: 'changed'
    },
    props: {
        inItem: {
            type: EntityObject,
            required: false
        },
        typeName: {
            type: String,
            required: false
        },
        keyNummeric: {
            type: Number,
            required: false
        },
        itemName: {
            type: String,
            required: false
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    components: {
        //LookupCombo,
        barcode: VueBarcode,
        FileUpload,
        TemplateObjectEditor
    },
    watch: {},
    computed: {
        formtitle() {
            const loweo = this.$t('an_eo', { ns: 'analytics' }).toLowerCase();
            if (this.locItem == undefined)
                return this.$t('com_newx', {
                    ns: 'common',
                    val: loweo
                });

            return this.locItem.isNew
                ? this.$t('com_makenew', {
                      ns: 'common',
                      val: loweo
                  })
                : this.$t('com_edit', {
                      ns: 'common',
                      val: this.locItem.naam ?? loweo
                  });
        },
        localItem() {
            return this.locItem;
        },
        magEdit() {
            return !(this.locItem.typenaam && this.locItem.veldid);
        },
        writable() {
            return this.doselect == 'false';
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                { key: 'naam', label: 'Naam', sortable: true, visible: true },
                {
                    key: 'mimetype',
                    label: 'Type',
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        }
    },
    methods: {
        PrintElem() {
            const mywindow = window.open('', 'PRINT', 'height=400,width=600');

            mywindow.document.write(
                '<html><head><title>' + document.title + '</title>'
            );
            mywindow.document.write('</head><body >');
            mywindow.document.write(
                document.getElementById('targetDiv').innerHTML
            );
            mywindow.document.write('</body></html>');

            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/

            mywindow.print();
            mywindow.close();

            return true;
        },
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        hide() {
            this.$emit('hide', this.returnObject);
        },
        setChanged() {
            this.isChanged = true;
        },
        setTouched(value) {
            this.templateChanged = value;
        },
        checkFormValidity() {
            if (this.isNieuw) {
                this.locItem.veldref = this.naam;
                this.locItem.typenaambasis = this.selectedType;
            }
            const invalidBasis = this.locItem.CheckValidity(this.$t);
            if (invalidBasis) {
                this.showAlert(invalidBasis);
                return false;
            }
            const valid =
                this.locItem != undefined &&
                !invalidBasis &&
                !this.templateChanged;
            return valid;
        },
        async checkTemplateEdited() {
            let gaDoor = true;
            if (this.templateChanged) {
                gaDoor = await this.$root.showConfirmationBox(
                    this.berichtWijziging
                );
                if (gaDoor) this.templateChanged = false;
            }
            return gaDoor;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        async handleSubmit() {
            // Exit when the form isn't valid
            const gaDoor = await this.checkTemplateEdited();
            if (!gaDoor) return;
            if (this.isChanged) {
                this.locItem.naam = this.naam;
                if (!this.checkFormValidity()) {
                    return;
                }
                this.returnObject = await this.saveThisEntityObject();
                this.$emit('forcerefresh', true);
            }
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-entityobject');
            });
        },
        async saveThisEntityObject() {
            const addObject = this.locItem.SetForAdd();
            return loadItem('analytics/addEntityObject', addObject, this.$t);
        },
        addCustomTypesToList(lijst) {
            for (let i = 0; i < lijst.length; i++) {
                const str = lijst[i];
                const obj = { value: str, text: str };
                this.eotypes.push(obj);
            }
        },
        async loadAttachments() {
            this.attachmentLijst = await loadItem(
                'analytics/loadAttachmentInfo',
                {
                    data: {
                        id: this.locItem.id,
                        attachmentType: this.attachmentType
                    },
                    func: this.$t
                }
            );
        },
        setQueuedAmount(aant) {
            this.queuedAmount = aant;
        },
        async showAttachment(item) {
            return await loadItem(
                'analytics/loadAttachment',
                {
                    id: item.sourceID,
                    attachmentID: item.id,
                    attachmentType: this.attachmentType
                },
                this.$t
            ).then((response) => {
                const fileURL = window.URL.createObjectURL(
                    new Blob([response])
                );
                const fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', item.naam);
                document.body.appendChild(fileLink);

                fileLink.click();
            });
        },
        async verwijderAttachment(item) {
            const doClose = await this.$root.showConfirmationBox(
                this.strDeleteAttachment + item.id + ' : ' + item.naam
            );
            if (doClose) {
                await loadItem(
                    'analytics/deleteAttachment',
                    {
                        id: item.sourceID,
                        attachmentID: item.id,
                        attachmentType: this.attachmentType
                    },
                    this.$t
                );
                this.loadAttachments();
            }
        }
    },
    async mounted() {
        const lijst = await loadList(
            'analytics/getEOTypeNamesLijst',
            'analytics/loadUserDefinedTypeNames',
            null,
            this.forcerefresh,
            this.$t
        );
        this.addCustomTypesToList(lijst);
    },
    created() {
        if (this.inItem) {
            this.locItem = this.inItem;
            this.naam = this.locItem.naam;
        } else {
            this.isNieuw = true;
            this.locItem = new EntityObject();
            this.locItem.naam = this.itemName;
            this.locItem.veldid = this.keyNummeric;
            this.naam = this.locItem.naam;
        }
    }
};
</script>

<style scoped></style>

