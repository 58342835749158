<template>
    <b-card no-body :header="$t('prod_osts', { ns: 'production' })">
        <div>
            <b-card>
                <b-row>
                    <b-col sm="3">
                        <label for="input-itemnaam"
                            >{{ $t('com_naam', { ns: 'common' }) }}:</label
                        >
                    </b-col>
                    <b-col sm="9">
                        <b-form-input
                            id="input-itemnaam"
                            :placeholder="$t('com_naam', { ns: 'common' })"
                            v-model="naam"
                            :disabled="readonly"
                            @change="setChangedNaam"
                            :state="naam != undefined && naam.length > 2"
                            autofocus
                        ></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="3">
                        <label for="input-volg"
                            >{{ $t('com_nummer', { ns: 'common' }) }}:</label
                        >
                    </b-col>
                    <b-col sm="9">
                        <b-form-input
                            id="input-volg"
                            :placeholder="$t('com_nummer', { ns: 'common' })"
                            v-model="nummer"
                            @change="setChangednummer"
                            :disabled="readonly"
                        ></b-form-input>
                    </b-col>
                    <b-col sm="3">
                        <label for="input_max"
                            >{{ $t('com_maximum', { ns: 'common' }) }}:
                        </label>
                    </b-col>
                    <b-col sm="9">
                        <b-form-input
                            id="input-max"
                            :placeholder="$t('com_maximum', { ns: 'common' })"
                            type="number"
                            step="50"
                            v-model.number="maximum"
                            @change="set_inputMaximum"
                            :disabled="readonly"
                            :state="validMaximum"
                        ></b-form-input>
                    </b-col>
                    <b-col sm="3">
                        <label for="input_min"
                            >{{ $t('com_minimum', { ns: 'common' }) }}:</label
                        >
                    </b-col>
                    <b-col sm="9">
                        <b-form-input
                            id="input-min"
                            :placeholder="
                                $t('prod_minrww', { ns: 'production' })
                            "
                            type="number"
                            step="50"
                            v-model.number="rest"
                            @change="set_inputRest"
                            :disabled="readonly"
                            :state="validRest"
                        ></b-form-input>
                    </b-col>
                </b-row>
            </b-card>
            <analytics-display
                v-if="isVersion30 && eo != undefined"
                :eo="eo"
                :forceRefresh="forceRefresh"
                :landscape="landscape"
            />
        </div>
    </b-card>
</template>

<script>
import { Ost } from '../../models/MasterData';
import { loadItem } from '@/models/DataHelper';
import AnalyticsDisplay from './AnalyticsDisplay.vue';

export default {
    name: 'CardOst',
    data() {
        return {
            nummer: null,
            naam: '',
            maximum: null,
            rest: null,
            lijn: window.constants.DEFPRODLINE,
            forceRefresh: false,
            landscape: true,
            eo: null
        };
    },
    components: {
        AnalyticsDisplay},
    model: {
        prop: 'theOst',
        event: 'changed'
    },
    props: {
        theOst: {
            type: Ost,
            default: null
        },
        editAllowed: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        validMaximum() {
            return this.maximum
                ? this.maximum > 100 && this.maximum < 30000
                : true;
        },
        validRest() {
            return this.rest ? this.maximum > this.rest && this.rest > 0 : true;
        },
        readonly() {
            return !this.editAllowed;
        },
        isVersion30() {
            return (
                this.$store.getters['masterdata/getPackageVersion'] >=
                window.constants.VERSION30
            );
        }
    },
    methods: {
        set_inputMaximum: function () {
            this.theOst.maximum = this.maximum;
            this.setChanged();
        },
        set_inputRest: function () {
            this.theOst.rest = this.rest;
            this.setChanged();
        },
        setChangedNaam: function () {
            this.theOst.naam = this.naam;
            this.setChanged();
        },
        setChangednummer: function () {
            this.theOst.nummer = this.nummer;
            this.setChanged();
        },
        setChanged: function () {
            this.$emit(
                'validComponent',
                this.validMaximum &&
                    this.validRest &&
                    this.theOst.naam != undefined
            );
            this.$emit('change', true);
        }
    },
    mounted() {
        this.nummer = this.theOst.nummer;
        this.naam = this.theOst.naam;
        this.maximum = this.theOst.maximum;
        this.rest = this.theOst.rest;
        this.lijn = this.theOst.lijn;
    },
    async created() {
        if (this.isVersion30) {
            await loadItem(
                'analytics/loadEntityObjectByTypename',
                {
                    typename: this.theOst.tableName
                },
                this.$t
            );
            this.eo = this.$store.getters['analytics/getEntityObjectByTypeID'](
                this.theOst.id
            );
        }
    }
};
</script>

