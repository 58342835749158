<template>
    <div class="mx-0 px-0 my-0 py-0">
            <lookup-combo
                id="productnaam"
                v-model="localSelected.id"
                v-bind:combotype="lctypeSimple"
                v-bind:discrimArray="discrimArray"
                @change="set_input"
                :disabled="setDisabledProduct"
            ></lookup-combo>
            <b-form-textarea
                id="input-itemremark"
                :placeholder="strRemarkAdditive"
                v-model="localSelected.opmerking"
                @change="set_input"
                :disabled="setDisabled"
                rows="2"
            ></b-form-textarea>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { SimpleRecipeElement } from '../../models/Recept';
import { LookupCombo } from '../components';
export default defineComponent({
    name: 'PlusIngredientCombo',
    data: function () {
        return {
            lctypeSimple: window.constants.LCSIMPLEPRODUCT,
            strRemarkAdditive: this.$t('tien_remarkadditive', { ns: 'tien'})
        };
    },
    model: {
        prop: 'selected',
        event: 'input'
    },
    props: {
        selected: {
            type: SimpleRecipeElement,
            required: true
        },
        discrimArray: {
            type: Array<SimpleRecipeElement>
        },
        editAllowed: {
            type: Boolean,
            default: true
        }
    },
    components: {
        LookupCombo
    },
    computed: {
        localSelected() {
            return this.selected;
        },
        setDisabled() {
            return !this.editAllowed;
        },
        setDisabledProduct(){
            if (!this.localSelected.isNew) return false;
            return !this.editAllowed ;
        }
    },
    methods: {
        set_input: function () {
            this.$emit('change', true);
            this.$emit('input', this.localSelected);
        }
    },
    mounted() {},
    created() {}
});
</script>

<style scoped></style>

