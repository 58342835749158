<template>
    <b-container fluid style="margin-top: 60px">
        <h1>
            {{
                $t('com_reportfor', {
                    ns: 'common',
                    val: $t('com_production', {
                        ns: 'common'
                    })
                })
            }}
        </h1>

        <b-row>
            <b-col sm="5">
                <period-selector
                    v-model="selectDays"
                    v-bind:periodDays="periodeDagen"
                    @change="setNewDates"
                />
            </b-col>
            <b-col sm="4">
                <b-row>
                    <b-col sm="7">
                        <b-form-select
                            v-model="report"
                            :options="reports"
                            @change="clearOutputAndFilter()"
                        >
                            <template #first>
                                <b-form-select-option value=""
                                    >--
                                    {{ $t('com_selecteer', { ns: 'common' }) }}
                                    --</b-form-select-option
                                >
                            </template>
                        </b-form-select>
                    </b-col>
                    <b-col sm="5">
                        <b-form-select
                            v-model="groepering"
                            :options="groupingPeriods"
                            @change="clearOutput()"
                        >
                            <template #first>
                                <b-form-select-option value=""
                                    >--
                                    {{ $t('com_selecteer', { ns: 'common' }) }}
                                    --</b-form-select-option
                                >
                            </template>
                        </b-form-select>
                    </b-col>
                </b-row>
            </b-col>

            <b-col sm="1">
                <b-row>
                    <b-col sm="12">
                        <b-button
                            @click="loadData()"
                            class="bgc_aux_normal"
                            v-b-tooltip.hover
                            :title="$t('com_showdata', { ns: 'common' })"
                            ><font-awesome-icon icon="arrow-down" size="1x" />
                        </b-button>
                    </b-col>
                </b-row>
            </b-col>
            <b-col sm="2">
                <b-row>
                    <b-col sm="11">
                        <div class="form-inline">
                            <print-to-pdf
                                v-bind:titel="reportTitle"
                                v-bind:filenaam="reportFileName"
                                v-bind:reportList="reportList"
                                v-bind:orientation="orientation"
                            />
                            <vue-json-to-csv
                                :json-data="reportList"
                                :csv-title="reportFileName"
                                :separator="';'"
                            >
                                <b-button
                                    class="bgc_aux_normal"
                                    v-b-tooltip.hover
                                    :title="
                                        $t('com_downloadas', {
                                            ns: 'common',
                                            val: 'CSV'
                                        })
                                    "
                                >
                                    <font-awesome-icon
                                        icon="file-csv"
                                        size="1x"
                                    />
                                </b-button>
                            </vue-json-to-csv>
                        </div>
                    </b-col>
                    <b-col sm="1"> </b-col>
                </b-row>
            </b-col>
        </b-row>
        <div slot="pdfdata">
            <b-row class="my-1">
                <b-col sm="12">
                    <b-row no-gutters>
                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_filter', { ns: 'common' })"
                                label-for="filter-input"
                                label-cols-sm="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-input-group size="sm">
                                    <b-form-input
                                        id="filter-input"
                                        v-model="filter"
                                        type="search"
                                        :placeholder="
                                            $t('com_typetosearch', {
                                                ns: 'common'
                                            })
                                        "
                                    ></b-form-input>

                                    <b-input-group-append>
                                        <b-button
                                            :disabled="!filter"
                                            @click="filter = ''"
                                            >{{
                                                $t('com_wis', { ns: 'common' })
                                            }}</b-button
                                        >
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>

                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_perpagina', { ns: 'common' })"
                                label-for="per-page-select"
                                label-cols-sm="6"
                                label-cols-md="4"
                                label-cols-lg="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-form-select
                                    id="per-page-select"
                                    v-model="perPage"
                                    :options="pageOptions"
                                    size="sm"
                                ></b-form-select>
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                ></b-pagination>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-table
                        striped
                        :items="reportList"
                        :fields="tableFields"
                        primary-key
                        :current-page="currentPage"
                        :per-page="perPage"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        stacked="md"
                        show-empty
                        :empty-text="$t('com_geen_data', { ns: 'common' })"
                        small
                        @filtered="onFiltered"
                    >
                    </b-table>
                    <div v-if="taskCount === 0">
                        {{ strNoData }}
                    </div>
                </b-col>
            </b-row>
        </div>
    </b-container>
</template>

<script>
import PrintToPdf from './PrintToPdf.vue';
import VueJsonToCsv from 'vue-json-to-csv';
import PeriodSelector from '../components/PeriodSelector.vue';
import DateHelper from '../../models/DateHelper';
import Helper from '@/models/Helper';
import { loadItem } from '../../models/DataHelper';
import { Feature } from '../../models/Features';

export default {
    name: 'RapProduction',
    data: function () {
        return {
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [10, 20, 40, { value: 100, text: '100' }],
            filter: null,
            filterOn: [],
            tableFields: [],
            van: null,
            tot: null,
            geraakt_teller: 1,
            report: '/Reporting/ProductionReport',
            groepering: 'week',
            selectDays: null,
            periodeDagen: 184,
            clearlist: false,
            filterSelectionPeriods: false
        };
    },
    components: {
        PrintToPdf,
        VueJsonToCsv,
        PeriodSelector
    },
    computed: {
        strNoData() {
            return Helper.stringformat(
                this.$t('prod_nomaintenancedata', {
                    ns: 'production'
                }),
                this.van,
                this.tot
            );
        },
        reportList() {
            // eslint-disable-next-line no-unused-vars
            const x = this.geraakt_teller;
            let list = [];
            if (this.clearlist) {
                this.clearOutput();
            } else {
                list = this.$store.getters['rapportage/getReportItems'];
                list = Helper.removeProperty(list, 'id');
                list = Helper.replaceValueInObject(list, null, '');
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.tableFields = Helper.giveHeaderFields(list, this.$t);
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.totalRows = list ? list.length : 0;
            }
            return list;
        },
        reportFileName: function () {
            let textVan = this.van
                ? new Date(this.van).toLocaleDateString()
                : new Date().toLocaleDateString();
            let textTot = this.tot
                ? new Date(this.tot).toLocaleDateString()
                : new Date().toLocaleDateString();
            textVan = textVan.replaceAll('-', '');
            textTot = textTot.replaceAll('-', '');
            const result = this.van
                ? this.selectedOption + '_' + textVan + '_tot_' + textTot
                : 'Onbekend';
            return result;
        },
        reportTitle: function () {
            const result = Helper.stringformat(
                this.$t('com_reportvantot', {
                    ns: 'common'
                }),
                this.selectedOption,
                this.van,
                this.tot
            );
            return result;
        },
        orientation() {
            return 'landscape';
        },
        isVersion10() {
            return (
                this.$store.getters['masterdata/getPackageVersion'] ==
                window.constants.VERSION10
            );
        },
        useVersion10() {
            return this.checkFeature('conf_CH10Enabled');
        },
        groupingPeriods() {
            const lijst = [
                { value: 'day', text: this.$t('com_dag', { ns: 'common' }) },
                { value: 'week', text: this.$t('com_week', { ns: 'common' }) },
                {
                    value: 'month',
                    text: this.$t('com_maand', { ns: 'common' })
                },
                { value: 'year', text: this.$t('com_jaar', { ns: 'common' }) }
            ];
            return this.filterSelectionPeriods
                ? lijst.filter((x) => x.show)
                : lijst;
        },
        selectedOption() {
            const report = this.reports.find(
                (option) => option.value === this.report
            );
            return report.text;
        },
        reports() {
            const fieldsInit = [
                {
                    value: '/Reporting/ProductionReport',
                    text: this.$t('prod_xreport', {
                        ns: 'production',
                        val: this.$t('com_production', {
                            ns: 'common'
                        })
                    }),
                    filter: false,
                    visible: !this.isVersion10
                },
                {
                    value: '/Reporting/DeliveryReport',
                    text: this.$t('prod_xreport', {
                        ns: 'production',
                        val: this.$t('log_uitslag', {
                            ns: 'logistics'
                        })
                    }),
                    filter: false,
                    visible: !this.isVersion10
                },
                {
                    value: '/Reporting/IngredientsReport',
                    text: this.$t('prod_xreport', {
                        ns: 'production',
                        val: this.$t('prod_ingredienten', {
                            ns: 'production'
                        })
                    }),
                    filter: false,
                    visible: !this.isVersion10
                },
                {
                    value: '/Reporting/ItemStockIngredientsReport',
                    text: this.$t('prod_xreport', {
                        ns: 'production',
                        val: this.$t('prod_toevoegingen', {
                            ns: 'production'
                        })
                    }),
                    filter: true,
                    visible: this.isVersion10 || this.useVersion10
                }
            ];
            const filtered = fieldsInit.filter((field) => field.visible);
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.report = filtered.length > 0 ? filtered[0].value : '';
            return filtered;
        }
    },
    methods: {
        checkFeature(feature) {
            const key = Feature.enum(feature);
            return this.$root.handleFeature(key);
        },
        clearOutput() {
            this.clearlist = !this.clearlist;
        },
        clearOutputAndFilter() {
            const report = this.reports.find(
                (option) => option.value === this.report
            );
            this.filterSelectionPeriods = report.filter;
            this.clearOutput();
        },
        taskCount() {
            let rijen = 0;
            if (this.reportList != undefined) {
                rijen = this.reportList.length;
            }
            return rijen;
        },
        setNewDates(selDates) {
            this.van = selDates.van;
            this.tot = selDates.tot;
            this.clearOutput();
        },
        async loadData() {
            const selectionData = {
                from: DateHelper.localDateToUTC(this.van),
                until: DateHelper.localDateToUTC(this.tot),
                groupingPeriod: this.groepering
            };
            const payload = {
                data: selectionData,
                report: this.report
            };
            loadItem('rapportage/loadReport', payload, this.$t);
            this.geraakt_teller += 1;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        }
    },
    mounted: function () {
        //this.setDates();
        this.totalRows = this.reportList ? this.reportList.length : 0;
        this.clearOutput();
    }
};
</script>

<style scoped></style>

