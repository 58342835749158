<template>
    <b-modal
        id="modal-item"
        size="lg"
        ref="modal"
        :title="this.formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
        @change="change"
    >
        <b-card @submit.stop.prevent="handleSubmit">
            <b-alert
                v-model="showDismissibleAlert"
                variant="danger"
                dismissible
            >
                {{ alertText }}
            </b-alert>
            <b-tabs card>
                <b-tab
                    :title="$t('com_product', { ns: 'common' })"
                    v-bind:key="1"
                    active
                >
                    <b-card-text>
                        <div>
                            <b-row>
                                <b-col sm="3">
                                    <label for="input-itemnaam"
                                        >{{
                                            $t('com_naam', { ns: 'common' })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <b-form-input
                                        id="input-itemnaam"
                                        :placeholder="
                                            $t('com_naam', { ns: 'common' })
                                        "
                                        v-model="localItem.naam"
                                        @change="setChanged"
                                    ></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col sm="3">
                                    <label for="input-itemiid"
                                        >{{
                                            $t('com_internalid', {
                                                ns: 'common'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <b-form-input
                                        id="input-itemiid"
                                        :placeholder="
                                            $t('com_internalid', {
                                                ns: 'common'
                                            })
                                        "
                                        v-model="localItem.internID"
                                        @change="setChanged"
                                    ></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col sm="3">
                                    <label for="input-itemremark"
                                        >{{
                                            $t('com_opmerking', {
                                                ns: 'common'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <b-form-textarea
                                        id="input-itemremark"
                                        :placeholder="
                                            $t('com_opmerking', {
                                                ns: 'common'
                                            })
                                        "
                                        v-model="localItem.opmerking"
                                        @change="setChanged"
                                        rows="3"
                                    ></b-form-textarea>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col sm="3">
                                    <label
                                        >{{ $t('com_basis', { ns: 'common' }) }}:
                                    </label>
                                </b-col>
                                <b-col sm="9">
                                    <b-form-select
                                        v-model="localItem.eenheid"
                                        :options="eenheidList"
                                        :disabled="setDisabled"
                                    >
                                        <template #first>
                                            <b-form-select-option value=""
                                                >--
                                                {{
                                                    $t('com_selecteer', {
                                                        ns: 'common'
                                                    })
                                                }}
                                                --</b-form-select-option
                                            >
                                        </template>
                                    </b-form-select>
                                    <b-form-checkbox
                                        v-if="toonProductType"
                                        v-model="localItem.productType"
                                        value="1"
                                        unchecked-value="0"
                                    >
                                        {{
                                            $t('prod_pekeladdition', {
                                                ns: 'production'
                                            })
                                        }}
                                    </b-form-checkbox>
                                </b-col>
                            </b-row>
                        </div>
                    </b-card-text>
                </b-tab>
                <b-tab v-if="showkaasoringredient" @click="loadUsedIn()" lazy>
                    <template #title>
                        <b-spinner
                            v-if="showSpinner"
                            type="border"
                            small
                        ></b-spinner>
                        {{ lijstTitel }}
                    </template>
                    <b-card-text>
                        <b-table
                            striped
                            responsive
                            :fields="fieldsToon"
                            :items="ingredientenLijst"
                            primary-key
                        >
                        </b-table>
                    </b-card-text>
                </b-tab>
                <b-tab
                    v-if="localItem.id > 0"
                    :title="$t('com_bijlagen', { ns: 'common' })"
                    v-bind:key="3"
                    @click="loadAttachments()"
                    lazy
                >
                    <b-card-text>
                        <file-upload
                            :attID="localItem.id"
                            :attType="attachmentType"
                            @input="loadAttachments"
                            @queue="setQueuedAmount"
                        />
                        <b-table
                            :items="attachmentLijst"
                            :fields="visibleFields"
                            stacked="md"
                            show-empty
                            :empty-text="$t('com_geen_data', { ns: 'common' })"
                            small
                            striped
                        >
                            <template v-slot:cell(actions)="row">
                                <b-button
                                    size="sm"
                                    @click="showAttachment(row.item)"
                                    class="bgc_aux_normal"
                                    ><font-awesome-icon icon="edit" size="1x" />
                                </b-button>
                                <b-button
                                    size="sm"
                                    @click="verwijderAttachment(row.item)"
                                    class="bgc_aux_alarm"
                                    ><font-awesome-icon
                                        icon="trash-alt"
                                        size="1x"
                                    />
                                </b-button>
                            </template>

                            <template v-slot:row-details> </template>
                        </b-table>
                    </b-card-text>
                </b-tab>
            </b-tabs>
        </b-card>
        <b-tabs v-if="showkaas" @submit.stop.prevent="handleSubmit" card>
            <b-tab :title="$t('com_kaas', { ns: 'common' })" active>
                <b-card-text>
                    <div>
                        <b-row>
                            <b-col sm="3">
                                <label for="input-vetklasse">
                                    {{
                                        $t('prod_vetklasse', {
                                            ns: 'production'
                                        })
                                    }}:</label
                                >
                            </b-col>
                            <b-col sm="9">
                                <lookup-combo
                                    id="input-vetklasse"
                                    :placeholder="
                                        $t('prod_vetklasse', {
                                            ns: 'production'
                                        })
                                    "
                                    v-model="localItem.vetklasse"
                                    v-bind:combotype="lctypeFatclass"
                                ></lookup-combo>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="3">
                                <label for="input-melksoort"
                                    >{{
                                        $t('prod_melktype', {
                                            ns: 'production'
                                        })
                                    }}:</label
                                >
                            </b-col>
                            <b-col sm="9">
                                <lookup-combo
                                    id="input-melksoort"
                                    :placeholder="
                                        $t('prod_melktype', {
                                            ns: 'production'
                                        })
                                    "
                                    v-model="localItem.melkID"
                                    v-bind:combotype="lctypeMilktype"
                                ></lookup-combo>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="3">
                                <label for="input-vatvorm"
                                    >{{
                                        $t('com_kaasvorm', { ns: 'common' })
                                    }}:</label
                                >
                            </b-col>
                            <b-col sm="9">
                                <lookup-combo
                                    id="input-vatvorm"
                                    :placeholder="
                                        $t('com_kaasvorm', { ns: 'common' })
                                    "
                                    v-model="localItem.vormID"
                                    v-bind:combotype="lctypeShapes"
                                ></lookup-combo>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="3">
                                <label for="input-merktype"
                                    >{{
                                        $t('com_cheesemark', { ns: 'common' })
                                    }}:</label
                                >
                            </b-col>
                            <b-col sm="9">
                                <lookup-combo
                                    id="input-merktype"
                                    :placeholder="
                                        $t('com_cheesemark', { ns: 'common' })
                                    "
                                    v-model="localItem.merkID"
                                    v-bind:combotype="lctypeMarktype"
                                    v-bind:discrim="localItem.vetklasse"
                                ></lookup-combo>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="3">
                                <label for="input-merktype"
                                    >{{ strBasiskaas }}:</label
                                >
                            </b-col>
                            <b-col sm="9">
                                <lookup-combo
                                    id="input-basiskaas"
                                    :placeholder="strBasiskaas"
                                    v-model="localItem.baseCTID"
                                    v-bind:combotype="lctypeCheesetype"
                                    v-bind:discrim="discrimBasiskaas"
                                ></lookup-combo>
                            </b-col>
                        </b-row>
                    </div>
                </b-card-text>
            </b-tab>
            <b-tab
                v-if="isVersion30"
                :title="$t('prod_treatment', { ns: 'production' })"
            >
                <b-card-text>
                    <cheese-treatments-base
                        v-bind:inItem="localItem.behandelRecepten"
                        @resetdefault="resetDefault"
                        @add="addCheeseTypeTreatmentRecipe"
                        @delete="delCheeseTypeTreatmentRecipe"
                    />
                </b-card-text>
            </b-tab>
        </b-tabs>
        <b-card
            v-if="showkaasmerk"
            @submit.stop.prevent="handleSubmit"
            :header="$t('com_cheesemark', { ns: 'common' })"
        >
            <div>
                <b-row>
                    <b-col sm="3">
                        <label for="input-vetklasse"
                            >{{
                                $t('prod_vetklasse', { ns: 'production' })
                            }}:</label
                        >
                    </b-col>
                    <b-col sm="9">
                        <lookup-combo
                            id="input-vetklasse"
                            :placeholder="
                                $t('prod_vetklasse', { ns: 'production' })
                            "
                            v-model="localItem.vetklasse"
                            v-bind:combotype="lctypeFatclass"
                        ></lookup-combo>
                    </b-col>
                </b-row>
            </div>
        </b-card>
        <b-card
            v-if="showmelk"
            @submit.stop.prevent="handleSubmit"
            :header="$t('prod_melk', { ns: 'production' })"
        >
            <div>
                <b-row>
                    <b-col sm="3">
                        <label for="input-melksoort"
                            >{{
                                $t('prod_melktype', { ns: 'production' })
                            }}:</label
                        >
                    </b-col>
                    <b-col sm="9">
                        <div class="form-inline">
                            <lookup-combo
                                id="input-melksoort"
                                :placeholder="
                                    $t('prod_melktype', { ns: 'production' })
                                "
                                v-model="localItem.groepID"
                                v-bind:combotype="lctypeMilktype"
                            ></lookup-combo>
                            <b-button
                                @click="toonGraph()"
                                class="bgc_aux_unknown"
                                ><font-awesome-icon
                                    icon="info-circle"
                                    size="1x"
                                />
                            </b-button>
                        </div>
                        <b-card
                            no-body
                            :header="
                                $t('prod_jaaropbrengst', { ns: 'production' })
                            "
                            v-if="showGraph"
                        >
                            <b-tabs card>
                                <b-tab
                                    active
                                    :title="$t('com_grafiek', { ns: 'common' })"
                                >
                                    <b-card-text>
                                        <simpel-chart
                                            style="
                                                width: 100%;
                                                max-width: 700px;
                                            "
                                            :key="componentKey1"
                                            v-bind:displayNumber="showline"
                                            v-bind:anID="chartId"
                                            v-bind:dataSource="graphDataSource"
                                        />
                                    </b-card-text>
                                </b-tab>
                                <b-tab @click="loadSVData()" lazy>
                                    <template #title>
                                        <b-spinner
                                            v-if="showSpinner"
                                            type="border"
                                            small
                                        ></b-spinner>
                                        {{
                                            $t('prod_seasonvalues', {
                                                ns: 'production'
                                            })
                                        }}
                                    </template>
                                    <b-card-text>
                                        <b-table
                                            striped
                                            responsive
                                            :fields="fieldsSV"
                                            :items="svLijst"
                                            primary-key
                                        >
                                            <template
                                                v-slot:cell(actions)="row"
                                            >
                                                <b-button
                                                    size="sm"
                                                    @click="editItem(row.item)"
                                                    class="bgc_aux_warning"
                                                    ><font-awesome-icon
                                                        icon="edit"
                                                        size="1x"
                                                    />
                                                </b-button>
                                            </template>
                                        </b-table>
                                    </b-card-text>
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </b-col>
                </b-row>
            </div>
        </b-card>
        <b-card
            v-if="showzuursel"
            @submit.stop.prevent="handleSubmit"
            :header="$t('prod_zuursel', { ns: 'production' })"
        >
            <div>
                <b-row>
                    <b-col sm="3">
                        <label
                            >{{
                                $t('prod_startergroup', { ns: 'production' })
                            }}:</label
                        >
                    </b-col>
                    <b-col sm="9">
                        <b-form-input
                            placeholder="-"
                            v-model="zuurseltekst"
                            readonly
                        ></b-form-input>
                    </b-col>
                </b-row>
            </div>
        </b-card>
        <season-value-pop-form
            v-if="showEditSV"
            :seizoenswaarde="currentSV"
            v-model="showEditSV"
            @hide="reset"
            @melkfactor="setMelkfactor"
        />
    </b-modal>
</template>

<script>
import { PRODCAT_ENUM } from '../../models/ProductCategorie';
import { INGRCAT_ENUM } from '../../models/IngredientCategorie';
import { loadProductData, loadIngredientData } from './loaddata';
import { loadItem } from '../../models/DataHelper';
import { Feature } from '../../models/Features';
import { LookupCombo, SimpelChart, FileUpload } from '../components';
import SeasonValuePopForm from './SeasonValuePopForm.vue';
import { CheeseTreatmentsBase } from '../treatment';
import { SeasonValues } from '../../models/Recept';

export default {
    name: 'ItemProductModal',
    data: function () {
        return {
            showDismissibleAlert: false,
            alertText: '',
            lctypeShapes: window.constants.LCSHAPES,
            lctypeMilktype: window.constants.LCMILKTYPE,
            lctypeMarktype: window.constants.LCMARKTYPE,
            lctypeFatclass: window.constants.LCFATCLASS,
            lctypeCheesetype: window.constants.LCCHEESE,
            attachmentType: window.constants.ATT_PRODUCT,
            perUnitsId: 0,
            melkId: 0,
            localNaam: '',
            opmerking: '',
            lokaalItem: null,
            showkaas: false,
            showkaasoringredient: false,
            showkaasmerk: false,
            showmelk: false,
            showzuursel: false,
            showSpinner: false,
            showline: window.constants.DB_SHOW_LINE,
            svList: [],
            eenheidList: [
                {
                    value: window.constants.KILOS,
                    text: this.$t('com_massa', { ns: 'common' })
                },
                {
                    value: window.constants.LITERS,
                    text: this.$t('com_volume', { ns: 'common' })
                },
                {
                    value: window.constants.STUKS,
                    text: this.$t('com_number', { ns: 'common' })
                }
            ],
            ingredientenLijst: [],
            fieldsToon: [],
            lijstTitel: '',
            seasonValues: null,
            showEditSV: false,
            showGraph: false,
            componentKey1: 1,
            theID: -1,
            disabled: false,
            currentSV: null,
            queuedAmount: 0,
            attachmentLijst: [], //as Array<AttachmentInfo>,
            strDeleteAttachment: this.$t('com_delete', {
                ns: 'common',
                val: this.$t('com_bijlage', { ns: 'common' })
            }),
            strBasiskaas:
                this.$t('com_basis', { ns: 'common' }) +
                this.$t('com_kaas', { ns: 'common' }).toLowerCase()
        };
    },
    model: {
        prop: 'visible',
        event: 'change'
    },
    props: {
        inItem: {
            type: Object,
            default: null
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    components: {
        LookupCombo,
        SimpelChart,
        FileUpload,
        CheeseTreatmentsBase,
        SeasonValuePopForm
    },
    watch: {},
    computed: {
        isProductCategorie() {
            return this.inItem.prodcat.type === 'ProductCategorie';
        },
        toonProductType() {
            if (!this.isProductCategorie) return true;
            return this.inItem.prodcat.id == PRODCAT_ENUM.Overige;
        },
        svLijst() {
            return this.svList;
        },
        availableGroups() {
            return this.$store.getters['account/getGroepen'];
        },
        setDisabled() {
            return this.disabled;
        },
        formtitle() {
            if (this.inItem == undefined)
                return this.$t('com_geen_data', { ns: 'common' });
            if (this.inItem.prodcat == undefined)
                return this.$t('prod_onbekend', { ns: 'production' });
            return this.inItem.isNew
                ? this.$t('com_makenew', {
                      ns: 'common',
                      val: this.inItem.prodcat.naam.toLowerCase()
                  })
                : this.$t('com_edit', {
                      ns: 'common',
                      val: this.inItem.prodcat.naam.toLowerCase()
                  });
        },
        localItem() {
            if (this.inItem == undefined) return null; // kan niet
            return this.inItem;
        },
        graphDataSource() {
            return 'lijnMelkOpbrengstFactor';
        },
        chartId() {
            return this.theID;
        },
        zuurseltekst() {
            return this.localItem.zuurselSubType
                ? this.$t('com_groep', { ns: 'common' }) + this.localItem.zuurselSubType
                : '';
        },
        discrimBasiskaas() {
            const res = this.localItem.productID
                ? this.localItem.productID.toString()
                : '';
            return res;
        },
        isVersion30() {
            return (
                this.$store.getters['masterdata/getPackageVersion'] >=
                window.constants.VERSION30
            );
        },
        fieldsSV() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                {
                    key: 'maand',
                    label: this.$t('com_maand', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'factor',
                    label: this.$t('prod_melkfactor', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'eiwit',
                    label: this.$t('an_eiwit', { ns: 'analytics' }),
                    sortable: true,
                    visible: false
                },
                {
                    key: 'vet',
                    label: this.$t('an_vet', { ns: 'analytics' }),
                    sortable: true,
                    visible: false
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                { key: 'naam', label: 'Naam', sortable: true, visible: true },
                {
                    key: 'mimetype',
                    label: 'Type',
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        useExternals() {
            const useExternals = this.checkFeature(
                'conf_useExternalConnections'
            );
            return useExternals; 
        }
    },
    methods: {
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        hide() {
            this.opmerking = '';
            this.$emit('hide');
        },
        reset() {
            this.showEditSV = false;
        },
        setMelkfactor(value) {
            this.currentSV.isChanged = true;
            this.currentSV.factor = value;
        },
        change(visible) {
            this.setChanged();
            this.$emit('change', visible);
        },
        setChanged() {
            this.localItem.isChanged = true;
        },
        editItem(item) {
            this.currentSV = item;
            this.showEditSV = !this.showEditSV;
        },
        resetDefault() {
            this.localItem.behandelRecepten.map((x) => (x.default = false));
        },
        addCheeseTypeTreatmentRecipe(item) {
            this.localItem.behandelRecepten.push(item);
            this.change(true);
        },
        delCheeseTypeTreatmentRecipe(item) {
            const test = this.localItem.behandelRecepten.find(
                (x) =>
                    x.vormID == item.vormID &&
                    x.behandelreceptID == item.behandelreceptID
            );
            if (test) {
                const i = this.localItem.behandelRecepten.indexOf(test);
                this.localItem.behandelRecepten.splice(i, 1);
                this.change(true);
            }
        },
        async checkFormValidity() {
            // doe iets met zetten van bepaalde waarden
            const invalidBasis = this.localItem.CheckValidity(this.$t);
            if (invalidBasis) this.showAlert(invalidBasis);

            if (this.queuedAmount > 0) {
                const doClose = await this.$root.showConfirmationBox(
                    this.$t('com_queuedbijlagen', { ns: 'common' })
                );
                if (!doClose) {
                    return false;
                }
            }
            this.localItem.prodcat = this.inItem.prodcat;
            const valid =
                this.localItem != undefined &&
                this.localItem.prodcat != undefined;
            return valid;
        },
        toonGraph() {
            this.showGraph = !this.showGraph;
            if (this.showGraph) {
                this.theID = this.localItem.groepID;
                this.componentKey1 += 10;
            }
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        async loadSVData() {
            this.showSpinner = true;
            const data = {
                milkGroupTypeID: this.localItem.groepID
            };
            this.seasonValues = await loadItem(
                'product/loadSeasonValues',
                data,
                this.$t
            );
            this.svList = this.seasonValues
                ? this.seasonValues.elementen
                : new SeasonValues().elementen;
            this.showSpinner = false;
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return;
            }
            const svchanged = this.svList.some((x) => x.isChanged == true);
            if (svchanged) this.saveSVList();
            this.saveThisProduct();
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-item');
            });
        },
        async saveSVList() {
            this.seasonValues.isChanged = true;
            const model = this.seasonValues.SetForSave();
            await loadItem('product/saveSeasonValues', model, this.$t);
        },
        async saveThisProduct() {
            this.localItem.isChanged = true;
            const model = this.localItem.SetForSave();
            if (this.isProductCategorie) {
                await loadItem('product/saveProduct', model, this.$t);
                loadProductData(this.localItem.prodcat.id, this.$t);
            } else {
                await loadItem('product/saveIngredient', model, this.$t);
                loadIngredientData(this.localItem.prodcat, this.$t);
            }
        },
        async loadAttachments() {
            this.attachmentLijst = await loadItem(
                'components/loadAttachmentInfo',
                {
                    data: {
                        id: this.inItem.id,
                        attachmentType: this.attachmentType
                    },
                    func: this.$t
                }
            );
        },
        setQueuedAmount(aant) {
            this.queuedAmount = aant;
        },
        async showAttachment(item) {
            return await loadItem(
                'components/loadAttachment',
                {
                    id: item.sourceID,
                    attachmentID: item.id,
                    attachmentType: this.attachmentType
                },
                this.$t
            ).then((response) => {
                const fileURL = window.URL.createObjectURL(
                    new Blob([response])
                );
                const fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', item.naam);
                document.body.appendChild(fileLink);

                fileLink.click();
            });
        },
        async verwijderAttachment(item) {
            const doClose = await this.$root.showConfirmationBox(
                this.strDeleteAttachment + item.id + ' : ' + item.naam
            );
            if (doClose) {
                await loadItem(
                    'components/deleteAttachment',
                    {
                        id: item.sourceID,
                        attachmentID: item.id,
                        attachmentType: this.attachmentType
                    },
                    this.$t
                );
                this.loadAttachments();
            }
        },
        checkFeature(feature) {
            const key = Feature.enum(feature);
            return this.$root.handleFeature(key);
        },
        async loadUsedIn() {
            if (this.inItem.id > 0) {
                if (this.showkaas) {
                    const lijst = await loadItem(
                        'product/loadCheeseTypeIngredients',
                        { productID: this.inItem.productID },
                        this.$t
                    );
                    this.ingredientenLijst = lijst;
                    this.fieldsToon = [
                        {
                            key: 'ingredientNaam',
                            label: this.$t('prod_ingredient', {
                                ns: 'production'
                            }),
                            sortable: true,
                            visible: true
                        }
                    ];
                } else {
                    if (this.ingredientenLijst.length == 0) {
                        const lijst = await loadItem(
                            'product/loadIngredientCheeseTypes',
                            { productID: this.inItem.productID },
                            this.$t
                        );
                        this.ingredientenLijst = lijst;
                        this.fieldsToon = [
                            {
                                key: 'kaasNaam',
                                label: this.$t('com_kaassoort', {
                                    ns: 'common'
                                }),
                                sortable: true,
                                visible: true
                            }
                        ];
                    }
                }
            }
        }
    },
    mounted() {
        if (this.inItem.prodcat != undefined) {
            if (this.isProductCategorie) {
                switch (this.inItem.prodcat.id) {
                    case PRODCAT_ENUM.Kaas: {
                        this.lijstTitel = this.$t('prod_ingredienten', {
                            ns: 'production'
                        });
                        this.showkaasoringredient = true;
                        this.showkaas = true;
                        break;
                    }
                    case PRODCAT_ENUM.Ingrediënt: {
                        this.lijstTitel = this.$t('com_kaassoorten', {
                            ns: 'common'
                        });
                        this.showkaasoringredient = true;
                        break;
                    }
                    case PRODCAT_ENUM.Kaasmerk:
                        this.showkaasmerk = true;
                        break;
                    case PRODCAT_ENUM.Onvolwaardig:
                    case PRODCAT_ENUM.Kaasdekmiddel:
                    case PRODCAT_ENUM.Wei:
                    case PRODCAT_ENUM.Overige:
                    default: {
                        break;
                    }
                }
            } else {
                this.showkaasoringredient = true;
                this.lijstTitel = this.$t('com_kaassoorten', { ns: 'common' });
                this.loadUsedIn();
                switch (this.inItem.prodcat.id) {
                    case INGRCAT_ENUM.Melk:
                        this.showmelk = true;
                        break;
                    case INGRCAT_ENUM.Zuursel:
                        this.showzuursel = true;
                        break;
                    case INGRCAT_ENUM.Stremsel:
                    case INGRCAT_ENUM.Kleursel:
                    case INGRCAT_ENUM.Conserveermiddel:
                    case INGRCAT_ENUM.Calcium:
                    case INGRCAT_ENUM.Zout:
                    case INGRCAT_ENUM.Kruiden:
                    case INGRCAT_ENUM.Aromas:
                    case INGRCAT_ENUM.Vetten:
                    case INGRCAT_ENUM.Grootzuursel:
                    default: {
                        break;
                    }
                }
            }
        }
    },
    created() {}
};
</script>

<style scoped></style>

