<template>
    <b-card @submit.stop.prevent="handleSubmit" no-body>
        <b-tabs card>
            <b-table :items="uitpekelItemLijst" :fields="fields" small striped>
            </b-table>
        </b-tabs>
    </b-card>
</template>

<script>
export default {
    name: 'OutbrineCard',
    data: function() {
        return {
            fields: [
                { key: 'id', label: 'Id', sortable: false, visible: false },
                { key: 'vormNaam', label: this.$t('prod_vorm', { ns: 'production' }) },
                { key: 'amount', label: this.$t('com_number', { ns: 'common' }) },
                { key: 'stocklocationNaam',  label: this.$t('prod_tolocation', { ns: 'production' }) },
                { key: 'uitpekelTijd', label: this.$t('com_tijd', { ns: 'common' }) }
            ]
        };
    },
    model: {
        prop: 'uitpekelItemLijst',
        event: 'changedRecipe'
    },
    props: {
        uitpekelItemLijst: {
            type: Array,
            default: null
        },
        editAllowed: {
            type: Boolean,
            default: false
        }
    },
    components: {},
    watch: {},
    computed: {
        localItem() {
            return this.theRecipe;
        }
    },
    methods: {
        hide() {
            this.$emit('hide');
        },
        change(visible) {
            this.$emit('change', visible);
        }
    },
    mounted() {},
    created() {}
};
</script>

<style scoped></style>
