const mutations = {
    assignLijst(state, lijst) {
        state.reportItemList = [];
        if (lijst != undefined) {
            state.reportItemList = lijst;
            state.listcount = lijst.length
        }
    }
};

export default mutations;
