<template>
    <b-container fluid style="margin-top: 60px">
        <div class="form-inline">
            <h1 v-on:click="flipTreated">
                {{ title }} &nbsp;
                <b-button :class="giveBGclass">
                    {{ btnTekst }}
                </b-button>
            </h1>
            <b-button
                v-if="uitbehandeld"
                @click="flipUitbehandeld()"
                class="bgc_aux_normal"
            >
                {{ btnTekstUitbehandeld }}</b-button
            >
        </div>

        <b-row>
            <b-col sm="12">
                <div v-if="showFilter">
                    <b-row no-gutters>
                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_filter', { ns: 'common' })"
                                label-for="filter-input"
                                label-cols-sm="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-input-group size="sm">
                                    <b-form-input
                                        id="filter-input"
                                        v-model="filter"
                                        type="search"
                                        :placeholder="
                                            $t('com_typetosearch', {
                                                ns: 'common'
                                            })
                                        "
                                    ></b-form-input>

                                    <b-input-group-append>
                                        <b-button
                                            :disabled="!filter"
                                            @click="filter = ''"
                                            >{{
                                                $t('com_wis', { ns: 'common' })
                                            }}</b-button
                                        >
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>

                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_perpagina', { ns: 'common' })"
                                label-for="per-page-select"
                                label-cols-sm="6"
                                label-cols-md="4"
                                label-cols-lg="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-form-select
                                    id="per-page-select"
                                    v-model="perPage"
                                    :options="pageOptions"
                                    size="sm"
                                ></b-form-select>
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                ></b-pagination>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div v-else></div>

                <b-table
                    :items="displayList"
                    :fields="visibleFields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    stacked="md"
                    show-empty
                    :empty-text="$t('com_geen_data', { ns: 'common' })"
                    small
                    striped
                    @filtered="onFiltered"
                >
                    <template v-slot:cell(actions)="row">
                        <b-button
                            v-if="uitbehandeld"
                            size="sm"
                            @click="assignSchema(row.item)"
                            class="bgc_aux_normal"
                            ><font-awesome-icon icon="info-circle" size="1x" />
                        </b-button>
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <assign-treatment-modal
            v-if="detailsVisible"
            v-model="detailsVisible"
            :voorraadItem="currentObject"
            @hide="reset"
            @change="applyChange"
        />
    </b-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DateHelper from '@/models/DateHelper';
import { loadItem, loadList } from '../../models/DataHelper';
import { TacStatusView } from '@/models/CheeseTreatment';
import { VoorraadItem } from '@/models/Voorraad';
import AssignTreatmentModal from './AssignTreatmentModal.vue';
import { Permission } from '../../models/Permissions';
import { VueExtension } from '../../models/VueExtension';

export default defineComponent({
    name: 'TreatmentsOverview',
    data: function () {
        return {
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [10, 20, 40, { value: 100, text: '100' }],
            filter: null,
            filterOn: [],
            currentID: -1,
            currentObject: {} as VoorraadItem | null,
            detailsVisible: false,
            uitbehandeld: false,
            localItemslist: [],
            displayFilterCount: 5,
            uitbehandeldCount: 0,
            onbehandeldCount: 0,

            showNooitBehandeld: false,
            showDetails: false,
            forcerefresh: false,
            activitiesList: [] as Array<TacStatusView>,
            noSchemaList: [] as Array<TacStatusView>,
            strNotInList: this.$t('prod_nietinlijst', {
                ns: 'production',
                val: this.$t('com_batch', { ns: 'common' })
            })
        };
    },
    props: {},
    components: {
        AssignTreatmentModal
    },
    computed: {
        title(): string {
            return this.uitbehandeld
                ? this.$t('prod_uitbehandeld', {
                      ns: 'production'
                  })
                : this.$t('com_status', { ns: 'common' });
        },
        btnTekst(): string {
            const str =
                this.uitbehandeldCount > 0
                    ? this.uitbehandeldCount +
                      this.$t('prod_uitbehandeld', {
                          ns: 'production'
                      }).toLowerCase()
                    : this.$t('com_show', { ns: 'common' }) +
                      ' ' +
                      this.$t('prod_uitbehandeld', {
                          ns: 'production'
                      }).toLowerCase();
            return this.uitbehandeld
                ? this.$t('com_show', { ns: 'common' }) +
                      ' ' +
                      this.$t('com_status', { ns: 'common' }).toLowerCase()
                : str;
        },
        btnTekstUitbehandeld(): string {
            const str = this.showNooitBehandeld
                ? this.$t('prod_onbehandeld', {
                      ns: 'production'
                  })
                : this.$t('prod_uitbehandeld', {
                      ns: 'production'
                  });
            return str;
        },
        giveBGclass() {
            return this.uitbehandeldCount > 0
                ? 'bgc_aux_alarm '
                : 'bgc_auxzenze_gray';
        },
        showFilter(): boolean {
            return this.displayList
                ? this.displayList.length > this.displayFilterCount
                : false;
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'id', sortable: false, visible: false },
                {
                    key: 'batchnummer',
                    label: this.$t('com_batch', { ns: 'common' }) + '#',
                    sortable: true,
                    visible: true
                },
                {
                    key: 'productiedatum',
                    label: this.$t('com_production', { ns: 'common' }),
                    sortable: true,
                    visible: true,
                    formatter: 'displayDate'
                },
                {
                    key: 'product',
                    label: this.$t('com_product', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'vorm',
                    label: this.$t('prod_vorm', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'locatie',
                    label: this.$t('com_location', { ns: 'common' }),
                    sortable: true,
                    visible: !this.uitbehandeld
                },
                {
                    key: 'aantal',
                    label:
                        this.$t('com_number', { ns: 'common' }) +
                        this.$t('com_kazen', { ns: 'common' }).toLowerCase(),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'gepland',
                    label: this.$t('prod_gepland', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'inbehandeling',
                    label: this.$t('prod_bezig', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'gedaan',
                    label: this.$t('prod_done', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'nietgedaan',
                    label:
                        this.$t('prod_niet', { ns: 'production' }) +
                        ' ' +
                        this.$t('prod_done', {
                            ns: 'production'
                        }).toLowerCase(),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'totaal',
                    label: this.$t('com_totaal', { ns: 'common' }),
                    sortable: true,
                    visible: !this.uitbehandeld
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        displayList() {
            const tempList = this.uitbehandeld
                ? this.showNooitBehandeld
                    ? this.noSchemaList
                    : this.activitiesList.filter(
                          (x) => x.gedaan + x.nietgedaan == x.totaal
                      )
                : this.activitiesList.filter(
                      (x) => x.gedaan + x.nietgedaan != x.totaal
                  );
            return tempList;
        },
        itemList() {
            return this.localItemslist;
        },
        allowEdit() {
            return true;
        }
    },
    methods: {
        flipTreated() {
            this.uitbehandeld = !this.uitbehandeld;
            this.displayList;
        },
        flipUitbehandeld() {
            this.showNooitBehandeld = !this.showNooitBehandeld;
            if (this.showNooitBehandeld) {
                this.loadNooitBehandeld();
            } else {
                this.displayList;
            }
        },
        applyChange(item) {
            if (item) {
                const selectionData = item.SetForConfirm();
                const response = loadItem(
                    'treatment/confirmTreatment',
                    selectionData,
                    this.$t
                );
                this.forcerefresh = true;
                this.reset();
                this.reloadData();
            }
        },
        async assignSchema(item) {
            // todo toon scherm assign schema
            const id = item ? parseInt(item.id) : -1;
            this.currentObject = await loadItem(
                'stock/loadStockItem',
                { stockitemid: id },
                this.$t
            );
            if (this.currentObject) this.currentObject.naam = item.product; // qend
            this.detailsVisible = true;
        },
        reset() {
            this.currentID = -1;
            this.currentObject = null;
            this.detailsVisible = false;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async reloadData() {
            const tempList = await loadList(
                'treatment/getTreatmentStatus',
                'treatment/loadTreatmentStatus',
                null,
                this.forcerefresh,
                this.$t
            );
            tempList.forEach((t) => {
                this.$set(t, '_showDetails', false);
            });
            this.totalRows = tempList ? tempList.length : 0;
            this.activitiesList = tempList;
            this.forcerefresh = false;
        },
        async loadNooitBehandeld() {
            const tempList = await loadList(
                'treatment/getUitbehandeldLijst',
                'treatment/loadTreatmentWithoutSchema',
                null,
                this.forcerefresh,
                this.$t
            );
            tempList.forEach((t) => {
                this.$set(t, '_showDetails', false);
            });
            this.totalRows = tempList ? tempList.length : 0;
            this.noSchemaList = tempList;
            this.forcerefresh = false;
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return (this.$root as VueExtension).handleRight(id);
        },
        displayDate(value) {
            return DateHelper.convertedToDate(value, false);
        }
    },
    mounted() {},
    async created() {
        // Set the initial number of items
        const list = await loadItem(
            'treatment/loadSchemasEnded',
            null,
            this.$t
        );
        this.uitbehandeldCount = list.length;

        this.reloadData();
        this.totalRows = this.displayList ? this.displayList.length : 0;
    }
});
</script>

<style scoped></style>

