export {
    Onderhoud,
    OnderhoudLijst,
    OnderhoudItem,
    OnderhoudsType,
    OnderhoudsActivity
};
import { RecurrencyPattern, RecurrencyModel } from './Recurrency';
import DateHelper from './DateHelper';

class Onderhoud {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.ma_id ?? data.id,
                writable: false
            },
            gewijzigd: {
                value: data.updated,
                writable: false
            },
            tijdstip: {
                type: Date,
                value: data.bookDate ?? data.bookdate ?? data.tijdstip,
                writable: true,
                enumerable: true
            },
            locatie: {
                type: String,
                value: data.object ?? data.description,
                writable: true,
                enumerable: true
            },
            activiteitTekst: {
                type: String,
                value: data.activiteit ?? data.activity,
                writable: true,
                enumerable: true
            },
            typeTekst: {
                type: String,
                value: data.soort ?? data.type,
                writable: true,
                enumerable: true
            },
            locatieID: {
                type: Number,
                value: data.stockLocationID,
                writable: true,
                enumerable: true
            },
            wbID: {
                type: Number,
                value: data.ostID,
                writable: true,
                enumerable: true
            },
            apparaatID: {
                type: Number,
                value: data.equipmentID,
                writable: true,
                enumerable: true
            },

            // group
            opmerking: {
                type: String,
                value: data.remark ?? data.opmerking,
                writable: true,
                enumerable: true
            },
            ondType: {
                type: OnderhoudsType,
                value: data.type ? data.type : 0,
                writable: true,
                enumerable: true
            },
            ondActiviteit: {
                type: OnderhoudsActivity,
                value: data.activity ? data.activity : 0,
                writable: true,
                enumerable: true
            },
            herhalingActief: {
                type: Number,
                value: data.rp_active,
                writable: true,
                enumerable: true
            },
            herhalingID: {
                type: Number,
                value: data.rp_id,
                writable: true,
                enumerable: true
            },
            laatsteKeer: {
                type: String,
                value: data.recurrency?.lastOccurrence,
                writable: true,
                enumerable: true
            },
            volgendeKeer: {
                type: String,
                value: data.recurrency?.nextOccurrence,
                writable: true,
                enumerable: true
            },
            herhaling: {
                type: RecurrencyModel,
                value: data.recurrency
                    ? new RecurrencyModel(data.recurrency)
                    : null,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }
    SetForAdd() {
        const MaintenanceModel = {
            BookDate: DateHelper.localDateToUTC(this.tijdstip),
            StockLocationID: this.locatieID,
            OstID: this.wbID,
            EquipmentID: this.apparaatID,
            LastOccurrence: DateHelper.localDateToUTC(this.laatsteKeer),
            Recurrency: this.herhaling ? this.herhaling.SetForAdd() : null,
            Items: []
        };
        return MaintenanceModel;
    }
    CheckValidity(func) {
        const warnText = '';
        return warnText;
    }
}

class OnderhoudLijst {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        this.editAllowed = true;
        data = data ?? {};
        Object.defineProperties(this, {
            gewijzigd: {
                value: data.updated,
                writable: false
            },
            tijdstip: {
                type: Date,
                value: data.bookDate ?? data.bookdate ?? data.tijdstip,
                writable: true,
                enumerable: true
            },
            locatieID: {
                type: Number,
                value: data.stockLocationID,
                writable: true,
                enumerable: true
            },
            wbID: {
                type: Number,
                value: data.ostID,
                writable: true,
                enumerable: true
            },
            apparaatID: {
                type: Number,
                value: data.equipmentID,
                writable: true,
                enumerable: true
            },
            laatsteKeer: {
                type: String,
                value: data.recurrency?.lastOccurrence,
                writable: true,
                enumerable: true
            },
            volgendeKeer: {
                type: String,
                value: data.recurrency?.nextOccurrence,
                writable: true,
                enumerable: true
            },
            herhaling: {
                type: RecurrencyModel,
                value: data.recurrency
                    ? new RecurrencyModel(data.recurrency)
                    : null,
                writable: true,
                enumerable: true
            },
            regels: {
                value: data.items
                    ? data.items.map((x) => new OnderhoudItem(x))
                    : [],
                writable: true,
                enumerable: true
            }
        });
    }
    SetForAdd(remark) {
        const items = [];
        for (let i = 0; i < this.regels.length; i++) {
            const o = this.regels[i].SetForAdd();
            if (i == 0 && remark) o.Remark = remark;
            items.push(o);
        }
        const MaintenanceModel = {
            BookDate: DateHelper.localDateToUTC(new Date()),
            StockLocationID: this.locatieID,
            OstID: this.wbID,
            EquipmentID: this.apparaatID,
            LastOccurrence: DateHelper.localDateToUTC(this.laatsteKeer),
            Recurrency: this.herhaling ? this.herhaling.SetForAdd() : null,
            Items: items
        };
        return MaintenanceModel;
    }
}

class OnderhoudItem {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false
            },
            gewijzigd: {
                value: data.updated,
                writable: false
            },
            tijdstip: {
                type: Date,
                value: data.bookDate ?? data.bookdate ?? data.tijdstip,
                writable: true,
                enumerable: true
            },
            typeID: {
                type: Number,
                value: data.type,
                writable: true,
                enumerable: true
            },
            activteitID: {
                type: Number,
                value: data.activity,
                writable: true,
                enumerable: true
            },
            // voor opzoeken
            ondType: {
                type: OnderhoudsType,
                writable: true,
                enumerable: true
            },
            ondActiviteit: {
                type: OnderhoudsActivity,
                writable: true,
                enumerable: true
            }
        });
    }
    SetForAdd() {
        const MaintenanceItem = {
            Type: this.typeID,
            Activity: this.activteitID
        };
        return MaintenanceItem;
    }
}

class OnderhoudsType {
    constructor(data, func) {
        this.id = -1;
        this.type = 'OnderhoudsType';
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false,
                enumerable: true
            },
            naam: {
                type: String,
                value: OnderhoudsType.giveName(data.id, func),
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }
    static giveName(id, func) {
        if (id == undefined) return undefined;
        const lookup = 'ondtype_' + id.toString();
        return id >= 0 ? func(lookup, { ns: 'permissies' }) : undefined;
    }
}

class OnderhoudsActivity {
    constructor(data, func) {
        this.id = -1;
        this.type = 'OnderhoudsActivity';
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false,
                enumerable: true
            },
            naam: {
                type: String,
                value: OnderhoudsActivity.giveName(data.id, func),
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }
    static giveName(id, func) {
        if (id == undefined) return undefined;
        const lookup = 'ondact_' + id.toString();
        return id >= 0 ? func(lookup, { ns: 'permissies' }) : undefined;
    }
}

