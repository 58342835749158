<template>
    <div style="margin-top: 60px;">
        <b-img center src="logo.png" alt="Logo"></b-img>
    </div>
</template>

<script>
export default {
    name: 'Empty',
    data: function() {
        return {     
        };
    },    
    props: {
        navigeerUrl: {
            type: String,
            required: false
        }
    },
    computed: {
        selectData: function() {
            const selectionData = {
                us_id: this.$store.getters['account/getClaim']('km')
            };
            return selectionData;
        }
    },
    methods: {
    }
};
</script>

<style scoped></style>
