
const state = {
    cheeseTypeList: [],    
    cheeseTypeInfoList: [],  
    markTypeList: [],    
    markTypeInfoList: [],   
    cheeseCoatingList: [],    
    cheeseCoatingInfoList: [],   
    wheyTypeList: [],    
    wheyTypeInfoList: [],  
    failureCheeseList: [],
    failureCheeseInfoList: [],
    overigeList: [],
    overigeInfoList: [],
    ingredientList: [],
    ingredientInfoList: [],
    voorraadItemLijst: [],
    productCategorieLijst: [],
    ingredientCategorieLijst: [],
    starterSubTypeList:[],
    allProductsList:[],
    selectedIngredient: null
};

export default state;
