var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{attrs:{"no-body":"","header":_vm.$t('com_user', {
            ns: 'common'
        })}},[_c('div',[_c('b-form-group',{staticClass:"form-inline"},[_c('b-form-input',{attrs:{"id":"display-naam","placeholder":_vm.$t('com_naam', {
                        ns: 'common'
                    }),"state":_vm.checkName},model:{value:(_vm.displayNaam),callback:function ($$v) {_vm.displayNaam=$$v},expression:"displayNaam"}}),_c('b-form-input',{attrs:{"id":"gebruikers-naam","placeholder":_vm.$t('com_gebruikersnaam', {
                        ns: 'common'
                    })},model:{value:(_vm.gebruikersNaam),callback:function ($$v) {_vm.gebruikersNaam=$$v},expression:"gebruikersNaam"}}),_c('b-form-input',{attrs:{"id":"gebruikers-mail","placeholder":_vm.$t('com_mail', {
                        ns: 'common'
                    })},model:{value:(_vm.gebruikersMail),callback:function ($$v) {_vm.gebruikersMail=$$v},expression:"gebruikersMail"}}),(_vm.checkRight('UserCreate') && _vm.checkUserLicenses)?_c('b-button',{staticClass:"bgc_aux_normal",on:{"click":function($event){return _vm.addNewGebruiker()}}},[_c('font-awesome-icon',{attrs:{"icon":"arrow-down","size":"1x"}})],1):_vm._e(),_vm._v("   "),_c('b-button',{staticClass:"bgc_aux_normal",on:{"click":function($event){return _vm.loadData()}}},[_c('font-awesome-icon',{attrs:{"icon":"sync","size":"1x"}}),_vm._v(" Ververs ")],1)],1),_c('b-table',{attrs:{"items":_vm.operatorList,"fields":_vm.gebruikers,"stacked":"md","show-empty":"","empty-text":_vm.$t('com_geen_data', { ns: 'common' }),"small":"","striped":""},on:{"row-clicked":_vm.toonDetail},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(row){return [_c('div',{staticClass:"form-inline"},[(_vm.checkRight('UserDelete'))?_c('b-button',{staticClass:"bgc_aux_alarm",attrs:{"size":"sm"},on:{"click":function($event){return _vm.verwijderItem(row.item)}}},[_c('font-awesome-icon',{attrs:{"icon":"trash-alt","size":"1x"}})],1):_vm._e(),_c('b-button',{staticClass:"bgc_aux_unknown",attrs:{"size":"sm"},on:{"click":function($event){return _vm.toonDetail(row.item)}}},[_c('font-awesome-icon',{attrs:{"icon":"info-circle","size":"1x"}})],1)],1)]}},{key:"row-details",fn:function(){return [_c('b-table',{attrs:{"items":_vm.filteredGroupList,"fields":_vm.groepen,"stacked":"md","show-empty":"","empty-text":_vm.$t('com_geen_data', { ns: 'common' }),"small":"","striped":""}})]},proxy:true}])}),_c('br')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }