<template>
    <div class="form-inline">
        <b-col sm="6" class="mx-0 px-0 my-0 py-0">
            <b-input-group>
                <lookup-combo
                    key="rerenderkey"
                    v-model="productID"
                    v-bind:combotype="lctypeBrineProducts"
                    v-bind:forcerefresh="forcerefresh"
                    @change="set_inputProduct"
                    :disabled="!editAllowed"
                ></lookup-combo>
                <b-form-select
                    v-if="!existing && hasstock"
                    v-model="partijID"
                    @change="set_inputPartij"
                    :disabled="!editAllowed"
                    v-bind:class="{ tc_aux_highlighted: warnForStockAmount }"
                >
                    <template #first>
                        <b-form-select-option value=""
                            >--
                            {{
                                $t('com_selecteer', { ns: 'common' })
                            }}
                            --</b-form-select-option
                        >
                    </template>
                    <option
                        v-for="option in itemStockList"
                        :value="option.value"
                        :key="option.value"
                        v-bind:class="{ tc_aux_highlighted: option.warn }"
                    >
                        {{ option.text }}
                    </option>
                </b-form-select>
                <b-form-input
                    v-if="!hasstock"
                    v-model="strNoStock"
                    readonly
                ></b-form-input>

                <b-form-input
                    v-if="existing"
                    v-model="localSelected.partijNummer"
                    readonly
                ></b-form-input>
            </b-input-group>
        </b-col>

        <b-col sm="3" class="mx-0 px-0 my-0 py-0">
            <b-form-input
                id="input-hoeveelheid"
                :placeholder="$t('com_amount', { ns: 'common' })"
                type="number"
                step="1"
                v-model.number="localSelected.hoeveelheid"
                @change="set_input"
                :disabled="!editAllowed"
            ></b-form-input>
        </b-col>

        <b-col sm="3" class="mx-0 px-0 my-0 py-0">
            <lookup-combo
                v-model="localSelected.eenheidID"
                v-bind:combotype="lctypeUnits"
                :disabled="!editAllowed"
                @change="set_inputEenheid()"
            ></lookup-combo>
        </b-col>
    </div>
</template>
<script>
import { SiloIngredient } from '../../models/BakInfo_helper';
import Helper from '../../models/Helper';
import DateHelper from '../../models/DateHelper';
import { loadItem, loadList } from '../../models/DataHelper';
import { LookupCombo } from '.';
import { Permission } from '../../models/Permissions';
export default {
    name: 'StockLocIngredientCombo',
    data: function () {
        return {
            lctypeUnits: window.constants.LCPERUNITS,
            lctypeBrineProducts: window.constants.LCBRINEPRODUCT,
            productID: null,
            errorMessage: '',
            partijID: null,
            eenheidID: null,
            flexList: [],
            warnAmount: false,
            hasstock: true,
            forcerefresh: false,
            editAllowed: true,
            strNoStock: this.$t('com_createstock', { ns: 'common' })
        };
    },
    model: {
        prop: 'selected',
        event: 'input'
    },
    props: {
        selected: {
            type: SiloIngredient,
            required: true
        }
    },
    components: {
        LookupCombo
    },
    watch: {
        selected() {
            this.reset();
        }
    },
    computed: {
        localSelected() {
            return this.selected;
        },
        localSelectedHoeveelheid() {
            return this.selected.hoeveelheid;
        },
        warnForStockAmount() {
            return this.warnAmount;
        },

        itemStockList() {
            const tempList = this.flexList.map((x) => {
                const tekst = x.datum
                    ? Helper.stringformat(
                          '{0} (' +
                              this.$t('com_tht', { ns: 'common' }) +
                              ': {1})',
                          x.batchID,
                          DateHelper.convertedToDateShort(x.datum)
                      )
                    : x.batchID;
                return {
                    value: x.id,
                    text: tekst,
                    warn: x.waarschuwen
                };
            });

            return tempList;
        },
        existing() {
            return this.localSelected.id > 0;
        },
        toonSelectie() {
            return this.existing || this.itemStockList.length > 0;
        },
        giveNaam() {
            return this.withPrepend ? this.localSelected.naam : '';
        },
        giveBGclass() {
            return this.localSelected.toegevoegdTijd != undefined
                ? 'bg-green'
                : ' bg-blue';
        },
        giveBGstyle() {
            return this.localSelected.toegevoegdTijd != undefined
                ? 'color=ghostwhite'
                : ' fontcolor ';
        }
    },
    methods: {
        reset() {
            this.partijID = this.localSelected.partijID;
            this.productID = this.localSelected.productID;
            this.eenheidID = this.localSelected.eenheidID;
            this.localSelected.hoeveelheid = 0;
        },
        findPartijID: function (batchID) {
            let partijID = '';
            const stockItem = this.flexList.find((x) => x.batchID == batchID);
            if (stockItem) partijID = stockItem.id;
            return partijID;
        },
        findPartijNummer: function (partijID) {
            let partijnummer = '';
            const stockItem = this.flexList.find((x) => x.id == partijID);
            if (stockItem) partijnummer = stockItem.batchID;
            return partijnummer;
        },
        findPartijAmount: function (partijID) {
            let hh = 0;
            const stockItem = this.flexList.find((x) => x.id == partijID);
            if (stockItem) hh = stockItem.aantal;
            return hh;
        },
        async set_inputPartij() {
            const hh = this.findPartijAmount(this.partijID);
            this.warnAmount = hh < 0;

            if (this.localSelected.partijID != undefined) {
                if (
                    this.localSelected.partijID &&
                    this.localSelected.partijID != this.partijID
                ) {
                    if (this.checkRight('StockClose')) {
                        const message = Helper.stringformat(
                            this.$t('com_stockempty', { ns: 'common' }),
                            this.localSelected.partijNummer
                        );
                        const doClose = await this.$root.showConfirmationBox(
                            message
                        );
                        if (doClose) {
                            // zet de voorraad op leeg
                            loadItem(
                                'stock/loadStockItem',
                                { stockitemid: this.localSelected.partijID },
                                this.$t
                            )
                                .then((x) => {
                                    const selectionData = {
                                        ID: x.id,
                                        LastUpdated: x.gewijzigd,
                                        IsEmpty: true,
                                        NeverUseAgain: false
                                    };
                                    loadItem(
                                        'stock/closeStockItem',
                                        selectionData,
                                        this.$t
                                    );
                                })
                                .then(() => {
                                    this.loadStockItemList(
                                        this.selected.productID
                                    );
                                });
                        }
                    }
                }
            }
            this.localSelected.partijID = this.partijID;
            this.localSelected.partijNummer = this.findPartijNummer(
                this.partijID
            );
            this.set_input();
        },
        set_inputEenheid() {
            if (this.localSelected.eenheid == undefined) {
                const unit = this.$store.getters['components/getUnitbyID'](
                    this.localSelected.eenheidID
                );
                this.localSelected.eenheid = unit.text;
            }
            this.set_input();
        },
        set_inputProduct() {
            this.$nextTick(async () => {
                const locList =
                    this.$store.getters['components/getBrineIngrComboLijst'];
                const prod = locList.find((x) => x.value == this.productID);
                this.localSelected.productID = this.productID;
                this.localSelected.productNaam = prod?.text;
                this.localSelected.eenheidID = prod?.eenheid;

                if (this.localSelected.eenheidID == undefined) {
                    this.localSelected.eenheidID = this.$store.getters[
                        'components/getUnitIDbyName'
                    ](this.localSelected.eenheid);
                }
                this.loadStockItemList(this.productID);
                this.set_input();
                this.rerenderkey += 1;
            });
        },
        set_input: function () {
            this.localSelected.isChanged = true;
            this.$emit('change', true);
            this.$emit('input', this.localSelected);
        },
        async loadStockItemList(productID) {
            const selectionData = { productid: productID };
            await loadItem('product/loadStockByProduct', selectionData, this.$t)
                .then(() => {
                    const locList =
                        this.$store.getters['product/getStockItemLijst'];
                    if (locList.length > 0) {
                        const dezelfde = productID == locList[0].productID;
                        if (dezelfde) this.flexList = locList;
                    } else {
                        this.flexList = [];
                    }
                })
                .then(() => {
                    this.partijID =
                        this.localSelected.partijID ??
                        (this.itemStockList.length > 0
                            ? this.itemStockList[0].value
                            : null);
                    if (this.itemStockList.length < 1 && !this.existing) {
                        this.hasstock = false;
                        this.localSelected.partijID = -1;
                        this.localSelected.partijNummer = -1;
                    } else {
                        this.hasstock = true;
                        this.localSelected.partijID = this.partijID;
                        this.localSelected.partijNummer = this.findPartijNummer(
                            this.partijID
                        );
                    }
                });
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        }
    },
    async mounted() {
        this.set_input();
        if (this.existing) return;
        const prodID = this.selected.productID;
        if (prodID > 0) {
            const locList = await loadList(
                'product/getStockItemLijst',
                'stock/loadStockByProduct',
                { productid: prodID },
                true,
                this.$t
            );
            if (locList.length > 0) {
                const dezelfde = prodID == locList[0].productID;
                if (dezelfde) {
                    this.flexList = locList;
                    this.partijID =
                        this.localSelected.partijID ??
                        (this.itemStockList.length > 0
                            ? this.itemStockList[0].value
                            : null);
                    this.set_inputPartij();
                } else this.loadStockItemList(prodID);
            } else {
                this.loadStockItemList(prodID);
            }
        }
    },
    created() {}
};
// .bgc_aux_normal
// .bgc_auxzenze_blue
</script>

<style scoped>
.bg-green {
    background-color: #c1e881 !important;
    color: ghostwhite !important;
}
.bg-blue {
    background-color: hwb(203 55% 7%) !important;
    color: ghostwhite !important;
}
.fontcolor {
    color: red;
}
</style>

