<template>
    <div>
        <b-row>
            <b-col :sm="labelSize" class="mx-0 px-0 my-0 py-0">
                <label>{{ showTheLabel }}</label>
            </b-col>
            <b-col :sm="objectSize" class="mx-0 px-0 my-0 py-0"
                >{{ showTheObject }}
            </b-col>
        </b-row>
    </div>
</template>
<script>
export default {
    name: 'ShowRowCol',
    data: function () {
        return {
            labelColSize: 3
        };
    },
    props: {
        showObject: {},
        showLabel: {
            type: String
        },
        size: {
            type: Number,
            default: 3
        },
    },
    computed: {
        showTheLabel() {
            return this.showLabel;
        },
        showTheObject() {
            return this.showObject;
        },
        labelSize() {
            return this.size ?? this.labelColSize;
        },
        objectSize(){
            return 12 - this.labelSize;
        }
    },
    methods: {},
    mounted() {},
    created() {}
};
</script>

<style scoped></style>
