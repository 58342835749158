<template>
    <!-- b-card :header="biProduct" header-tag="header" -->
    <b-card :header="biTitle" kaas header-tag="header">
        <div class="form-inline">
            <lookup-combo
                id="lcCheeseAlternatives"
                :placeholder="$t('prod_alternatief', { ns: 'production' })"
                v-model="newAlternativeID"
                v-bind:combotype="lctypeCheeseAlternatives"
                v-bind:discrim="cheeseProductIDString"
            ></lookup-combo>
            <b-button @click="addAlternativeCheese()" class="bgc_aux_normal"
                ><font-awesome-icon icon="arrow-down" size="1x" />
            </b-button>
        </div>
        <hr />
        <div
            v-for="(bilijst, index) in BIlijstarray"
            v-bind:key="bilijst.productID"
        >
            <card-alternative-cheese-lijst-detail
                v-model="BIlijstarray[index]"
                v-bind:editAllowed="editAllowed"
                v-bind:cheeseProductID="cheeseProductID"
                v-bind:altProductID="newAlternativeID"
                v-bind:showIngredientline="showIngredientline"
                v-bind:baseDateTime="baseDateTime"
                @delete="verwijderItem"
            />
        </div>
        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
            {{ alertText }}
        </b-alert>
    </b-card>
</template>

<script>
import CardAlternativeCheeseLijstDetail from './CardAlternativeCheeseLijstDetail.vue';
import { LookupCombo } from '../components';
import { loadItem } from '../../models/DataHelper';
import { Alternativeslijst } from '../../models/BakInfo_helper';
import { BakIngredient, AlternativeCheese } from '../../models/BakInfo';
import { INGRCAT_ENUM } from '../../models/IngredientCategorie';

export default {
    name: 'CardAlternativeCheeseLijst',
    data() {
        return {
            showDismissibleAlert: false,
            alertText: '',
            lctypeCheeseAlternatives: window.constants.LCCHEESEALTERNATIVES,
            aangeraakt: 1,
            newAlternativeID: -1
        };
    },
    components: {
        CardAlternativeCheeseLijstDetail,
        LookupCombo
    },
    model: {
        prop: 'alternatievenLijst',
        event: 'changedLijst'
    },
    props: {
        alternatievenLijst: {
            type: Array,
            required: true
        },
        altBaseBI: {
            type: Array,
            required: true
        },
        bakId: {
            type: Number,
            required: false
        },
        cheeseProductID: {
            type: Number,
            required: false
        },
        editAllowed: {
            type: Boolean,
            default: false
        },
        baseDateTime: {
            type: String
        }
    },
    computed: {
        biTitle() {
            return this.$t('prod_selaltcheese', { ns: 'production' });
        },
        BIlijstarray() {
            // eslint-disable-next-line no-unused-vars
            const tel = this.aangeraakt;
            return this.alternatievenLijst.filter((x) => x.isDeleted == false);
        },
        cheeseProductIDString() {
            return this.cheeseProductID ? this.cheeseProductID.toString() : '';
        },
        showIngredientline() {
            const result =
                this.altBaseBI.length > 0
                    ? this.altBaseBI[0].showIngredient
                    : this.alternatievenLijst[0].showIngredient;
            return result;
        }
    },
    methods: {
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        async addAlternativeCheese() {
            // ze kiezen hier de alternatieve kaas
            // er is nog geen bakingredient om de alternatieven aan op te hangen
            const kaas = this.BIlijstarray.find(
                (x) => x.productID == this.newAlternativeID
            );
            if (kaas == undefined) {
                const lijst =
                    this.$store.getters[
                        'components/getCheesesAlternativesComboLijst'
                    ];
                let isBaseAlt = false;
                let ding = lijst.find((x) => x.value == this.newAlternativeID);
                if (ding == undefined) {
                    ding = lijst.find(
                        (x) => x.baseproductid == this.newAlternativeID
                    );
                    isBaseAlt = true;
                }
                const nieuw = new Alternativeslijst({ id: this.bakId });
                nieuw.productID = isBaseAlt
                    ? ding.item.baseproductid
                    : ding.value;
                nieuw.naam = isBaseAlt ? ding.item.baseproductnaam : ding.text;
                nieuw.aantal = 0;
                nieuw.bakIngredienten = [];
                if (isBaseAlt) {
                    if (this.altBaseBI.length > 0) {
                        const bakingredientid =
                            this.altBaseBI[0].bakIngredienten[0].id;
                        if (bakingredientid == -1) {
                            await this.$root.showMessageBox(
                                this.$t('prod_warn1kruid', { ns: 'production' })
                            );
                            return;
                        }
                        const bi = await loadItem(
                            'production/loadBatchIngredient',
                            {
                                batchingredientid: bakingredientid
                            },
                            this.$t
                        );
                        bi.visible = !isBaseAlt;
                        const ctalt = new AlternativeCheese();
                        ctalt.productID = nieuw.productID;
                        ctalt.naam = nieuw.naam;
                        ctalt.aantal = 0;
                        bi.alternatieven.push(ctalt);
                        bi.isAlternatief = true;
                        nieuw.bakIngredienten.push(bi);
                    }
                } else {
                    const recept = await loadItem(
                        'recipe/loadOpenRecipeComplete',
                        { productid: ding.value },
                        this.$t
                    );
                    const kruiden = recept.recIngredienten.filter(
                        (x) => x.ingredientCatID == INGRCAT_ENUM.Kruiden
                    );
                    for (let i = 0; i < kruiden.length; i++) {
                        const bi = new BakIngredient({ id: -(i + 1) });
                        bi.hoeveelheid = 0; //Math.round((amountToAdd / kruiden[i].calcFactor) * 100) / 100;
                        bi.eenheidID = kruiden[i].eenheid;
                        bi.productID = kruiden[i].productID;
                        bi.hoeveelheid = kruiden[i].hoeveelheid;
                        bi.bakID = this.bakId;
                        const productNaam = await loadItem(
                            'product/loadProduct',
                            { productID: bi.productID },
                            this.$t
                        );
                        bi.naam = productNaam.internalName;
                        const ctalt = new AlternativeCheese();
                        ctalt.productID = nieuw.productID;
                        ctalt.naam = nieuw.naam;
                        ctalt.aantal = 0;
                        bi.alternatieven.push(ctalt);
                        bi.isAlternatief = true;
                        nieuw.bakIngredienten.push(bi);
                    }
                }
                this.alternatievenLijst.push(nieuw);
                this.aangeraakt += 1;
                this.$emit('change', true);
                //this.set_input();
            } else {
                this.showAlert(
                    this.$t('prod_alinlijst', {
                        ns: 'production',
                        val: this.$t('com_kaassoort', {
                            ns: 'common'
                        })
                    })
                );
            }
        },
        async verwijderItem(productID) {
            const item = this.BIlijstarray.find((x) => {
                return (
                    x.productID === productID &&
                    (x.nouse == undefined || x.nouse == false)
                );
            });
            if (item) {
                const index = this.BIlijstarray.findIndex(
                    (x) => x.productID === productID
                );
                this.BIlijstarray[index].isDeleted = true;
                this.$emit('change', true);
                for (
                    let i = 0;
                    i < this.BIlijstarray[index].bakIngredienten.length;
                    i++
                ) {
                    const item2 = this.BIlijstarray[index].bakIngredienten[i];
                    item2.isChanged = true;
                    // const altCheese = item2.alternatieven.find(
                    //     (x) => (x.productID = this.alternatievenLijst.productID)
                    // );
                }
            }
            this.aangeraakt += 1;
        }
    },
    mounted() {}
};
</script>

