<template>
    <b-dropdown
        id="dropdown-form"
        :disabled="disabled"
        variant="warning"
        :text="$t('prod_changemelk', { ns: 'production' })"
        ref="dropdown"
        :size="size"
    >
        <b-dropdown-form style="width: 340px" class="bgc_aux_warning">
            <b-form-group
                :label="$t('prod_melk', { ns: 'production' })"
                @submit.stop.prevent
            >
                <span>
                    <b-form-select v-model="productID" :options="productList">
                        <template #first>
                            <b-form-select-option value=""
                                >--
                                {{
                                    $t('com_selecteer', {
                                        ns: 'common'
                                    })
                                }}
                                --</b-form-select-option
                            >
                        </template>
                    </b-form-select>
                </span>
            </b-form-group>
            <b-button size="sm" @click="changeItem" class="mr-1"
                ><font-awesome-icon icon="folder-plus" size="1x" />
                {{ $t('com_change', { ns: 'common', val: this.catNaam }) }}
            </b-button>
        </b-dropdown-form>
    </b-dropdown>
</template>

<script>
import { VoorraadItem } from '../../models/Voorraad';
import Helper from '../../models/Helper';
import { loadItem } from '../../models/DataHelper';
import DateHelper from '../../models/DateHelper';
import { INGRCAT_ENUM } from '../../models/IngredientCategorie';

export default {
    name: 'InstantMilkChange',
    data() {
        return {
            flexList: [],
            productList: [],
            productID: null,
            selectedAmount: 0,
            catNaam: ''
        };
    },
    components: {},
    model: {
        prop: 'productIn',
        event: 'input'
    },
    props: {
        productIn: {
            type: Number,
            default: 0
        },
        recipeProducts: {
            default: []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: ''
        }
    },
    computed: {},
    methods: {
        async changeItem() {
            this.$emit('productID', this.productID);
            this.$nextTick(() => {
                this.$refs.dropdown.hide();
            });
        }
    },
    mounted() {
        if (this.productIn > 0) {
            this.productID = this.productIn;
        }
    },
    async created() {
        let cat = this.$store.getters['product/getIngredientCategorieByID'](
            INGRCAT_ENUM.Melk
        );
        if (cat == undefined) {
            await loadItem('product/loadIngredientCategories', null, this.$t);
            cat = this.$store.getters['product/getIngredientCategorieByID'](
                INGRCAT_ENUM.Melk
            );
        }
        const ingrcat = await loadItem(
            'product/loadAlternativeIngredients',
            { productID: this.productIn },
            this.$t
        );
        if (ingrcat != undefined && this.recipeProducts != undefined) {
            for (let i = ingrcat.length - 1; i >= 0; i--) {
                const id = ingrcat[i].id;
                const test = this.recipeProducts.find((x) => x === id);
                if (test) ingrcat.splice(i, 1);
            }
        }
        this.catNaam = cat.naam;
        this.productList = ingrcat.map((x) => ({
            value: x.id,
            text: x.naam
        }));
    }
};
</script>

