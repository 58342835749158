var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-modal',{ref:"modal",attrs:{"id":"modal-quicklist","size":"lg","title":_vm.formtitle,"visible":_vm.visible},on:{"hidden":_vm.hide,"ok":_vm.handleOk}},[_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-reference"}},[_vm._v(_vm._s(_vm.$t('com_reference', { ns: 'common' }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-input',{attrs:{"id":"input-reference","placeholder":_vm.$t('com_reference', { ns: 'common' })},model:{value:(_vm.localItem.mutatieReferentie),callback:function ($$v) {_vm.$set(_vm.localItem, "mutatieReferentie", $$v)},expression:"localItem.mutatieReferentie"}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"input-customer"}},[_vm._v(_vm._s(_vm.$t('com_klant', { ns: 'common' }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('lookup-combo',{attrs:{"id":"input-customer","combotype":_vm.lctypeCompany},model:{value:(_vm.localItem.klant),callback:function ($$v) {_vm.$set(_vm.localItem, "klant", $$v)},expression:"localItem.klant"}})],1)],1),_c('b-card',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-tabs',{attrs:{"card":""}},[_c('b-tab',{attrs:{"active":!_vm.showSelect}},[_c('b-card-text',[_c('div',{staticClass:"form-inline"},[_c('b-button',{staticClass:"bgc_aux_unimportant float-md-right",on:{"click":function($event){return _vm.scan()}}},[_c('font-awesome-icon',{attrs:{"icon":"barcode","size":"1x"}})],1),_c('b-form-input',{attrs:{"placeholder":_vm.$t('log_serialorbatch', {
                                    ns: 'logistics'
                                })},model:{value:(_vm.merkNumber),callback:function ($$v) {_vm.merkNumber=$$v},expression:"merkNumber"}}),_c('b-form-input',{attrs:{"placeholder":_vm.$t('com_amount', { ns: 'common' }),"type":"number"},model:{value:(_vm.hoeveelheid),callback:function ($$v) {_vm.hoeveelheid=_vm._n($$v)},expression:"hoeveelheid"}}),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"bgc_aux_normal",attrs:{"title":_vm.$t('prod_toevoegen', {
                                    ns: 'production'
                                })},on:{"click":function($event){return _vm.addLine()}}},[_c('font-awesome-icon',{attrs:{"icon":"arrow-down","size":"1x"}})],1)],1)])],1),_c('b-tab',{attrs:{"active":_vm.showSelect}},[_c('b-card-text',[_c('b-alert',{attrs:{"variant":"warning","dismissible":""},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v(" "+_vm._s(_vm.alertTekst)+" ")]),_c('div',{staticClass:"form-inline"},[_c('b-form-select',{attrs:{"options":_vm.selectionList},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":""}},[_vm._v("-- "+_vm._s(_vm.$t('com_selecteer', { ns: 'common' }))+" --")])]},proxy:true}]),model:{value:(_vm.aselected),callback:function ($$v) {_vm.aselected=$$v},expression:"aselected"}}),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"bgc_aux_normal",attrs:{"title":_vm.$t('prod_toevoegen', {
                                    ns: 'production'
                                })},on:{"click":function($event){return _vm.addSelected()}}},[_c('font-awesome-icon',{attrs:{"icon":"arrow-down","size":"1x"}})],1)],1)],1)],1)],1),_c('b-alert',{attrs:{"variant":"danger","dismissible":""},model:{value:(_vm.showDismissibleAlert),callback:function ($$v) {_vm.showDismissibleAlert=$$v},expression:"showDismissibleAlert"}},[_vm._v(" "+_vm._s(_vm.alertText)+" ")]),_c('b-tabs',{attrs:{"card":""}},[_c('b-tab',{attrs:{"title":_vm.strRegels}},[_c('b-card-text',_vm._l((_vm.localItem.regels),function(pv,index){return _c('div',{key:index,staticClass:"mx-0 px-0 my-0 py-0"},[_c('b-col',{staticClass:"mx-0 px-0 my-0 py-0 form-inline",attrs:{"sm":"11"}},[_c('b-form-input',{attrs:{"readonly":""},model:{value:(pv.naam),callback:function ($$v) {_vm.$set(pv, "naam", $$v)},expression:"pv.naam"}}),_c('b-form-input',{attrs:{"type":"number","readonly":""},model:{value:(pv.hoeveelheid),callback:function ($$v) {_vm.$set(pv, "hoeveelheid", _vm._n($$v))},expression:"pv.hoeveelheid"}})],1),_c('b-col',{staticClass:"mx-0 px-0 my-0 py-0",attrs:{"sm":"1"}},[_c('b-button',{staticClass:"bgc_aux_alarm",on:{"click":function($event){return _vm.verwijderRegel(pv.id)}}},[_c('font-awesome-icon',{attrs:{"icon":"trash-alt","size":"1x"}})],1)],1)],1)}),0)],1)],1)],1),(_vm.showScanner)?_c('scan-barcode',{on:{"result":_vm.showDetailStock},model:{value:(_vm.showScanner),callback:function ($$v) {_vm.showScanner=$$v},expression:"showScanner"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }