import { api } from '../api';
import { showBadToast, showGoodToast } from '../../errors/toast';
import logMessage from '../../errors/foutlog';
import { BakInfo, BakInfoHist } from '../../../models/BakInfo';
import { InpekelItem, UitpekelItem, SiloIngredient } from '../../../models/BakInfo_helper';
import { ActiviteitenLog } from '../../../models/ActiviteitenLog';
const actions = {
    async loadOutbrineInfo(context, data) {
        let tempList = [];
        try {
            await api.fetchOutbrineInfo(data.data).then(response => {
                tempList = response.data.Items.map(x => new BakInfo(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_uitpekelinfo', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertOutbrineInfoLijst', tempList);
    },
    async loadBGOutbrineInfo(context, data) {
        let tempList = [];
        try {
            await api.fetchBGOutbrineInfo(data.data).then(response => {
                tempList = response.data.Items.map(x => new BakInfo(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_uitpekelinfo', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertBGOutbrineInfoLijst', tempList);
    },
    async loadOutbrineInfoOverPeriod(context, data) {
        let tempList = [];
        try {
            await api.fetchOutbrineInfoOverPeriod(data.data).then(response => {
                tempList = response.data.Items.map(x => new BakInfoHist(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_uitpekelinfo', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertOutbrineInfoHistLijst', tempList);
    },
    async loadOutbrineAmountsforBG(context, data) {
        let tempList = [];
        try {
            await api.fetchBGoutbrineAmounts(data.data).then(response => {
                tempList = response.data.amounts.map(x => new UitpekelItem(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_uitpekelinfo', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertOutbrineForBGLijst', tempList);
    },
    async loadInbrineAmountsforBG(context, data) {
        let tempList = [];
        try {
            await api.fetchBGinbrineAmounts(data.data).then(response => {
                tempList = response.data.amounts.map(x => new InpekelItem(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_uitpekelinfo', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertInbrineForBGLijst', tempList);
    },    
    async updateBatchGroupOutBrine(context, data) {
        try {
            await api.updateBatchGroupOutBrine(data.data);
            return true;
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('prod_uitpekelinfo', { ns: 'production' })
                }),
                error
            );
        }
    },
    async loadStockLocationActiviteitenLijst(context, data) {
        let tempList = [];
        try {
            await api.fetchStockLocationActivities(data.data).then((response) => {
                tempList = response.data.map((x) => new ActiviteitenLog(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_activitylog', { ns: 'common' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return tempList;
    },
    async loadStockLocationIngredientInfo(context, data) {
        let tempList = [];
        try {
            await api.fetchStockLocationIngredientInfo(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new SiloIngredient(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_ingredienten', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return tempList;
    },  
    async loadStockLocIngredient(context, data) {
        let item = null;
        try {
            const response = await api.fetchStockLocationIngredient(data.data);
            item = new SiloIngredient(response.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_ingredient', { ns: 'production' })
                }),
                error
            );
        }
        return item;
    },  
    async addStockLocIngredients(context, data) {
        let item = null;
        try {
            const response = await api.addStockLocIngredients(data.data);
            item = response.data;
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('prod_ingredienten', { ns: 'production' })
                }),
                error
            );
        }
        return item;
    },
    //int id, int stocklocationid, DateTime lastupdated
    async delStockLocIngredient(context, data) {
        try {
            await api.deleteStockLocationIngredient(data.data);
            showGoodToast(
                context,
                data.func('com_verwijderd', {
                    ns: 'common',
                    val: data.func('prod_toevoeging', { ns: 'production' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data.func('prod_toevoeging', { ns: 'production' })
                }),
                error
            );
        }
    },
};

export default actions;
