<template>
    <b-modal
        id="modal-executor"
        size="lg"
        ref="modal"
        :title="this.formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
    >
        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
            {{ alertText }}
        </b-alert>

        <div>
            <b-card>
                <b-row>
                    <b-col sm="3">
                        <label for="input-itemtype"
                            >{{
                                $t('an_uitvoerderstype', { ns: 'analytics' })
                            }}:</label
                        >
                    </b-col>
                    <b-col sm="9">
                        <b-form-radio-group
                            id="input-itemtype"
                            v-model="localItem.type"
                            :options="options"
                            :disabled="!allowTypeSelection"
                        ></b-form-radio-group>
                    </b-col>
                    <b-col sm="3">
                        <label for="input-itemnaam"
                            >{{ $t('com_naam', { ns: 'common' }) }}:</label
                        >
                    </b-col>
                    <b-col sm="9">
                        <b-form-input
                            id="input-itemnaam"
                            :placeholder="$t('com_naam', { ns: 'common' })"
                            v-model="naam"
                            :disabled="!magEdit"
                            @change="setChanged"
                            :state="naam != undefined && naam.length > 2"
                            autofocus
                        ></b-form-input>
                    </b-col>
                </b-row>

                <b-form-textarea
                    v-model="localItem.opmerking"
                    :placeholder="$t('com_opmerking', { ns: 'common' })"
                    rows="3"
                    max-rows="6"
                    @change="setChanged"
                ></b-form-textarea>
                <list-selector
                    v-model="localItem.grootheden"
                    v-bind:tekst="localItem.groothedentekst"
                    :listtype="grootheden"
                    :disabled="!editAllowed"
                    @touched="setListEditted"
                    @change="setChangedList"
                    @input="setInputGrootheden"
                >
                </list-selector>
                <list-selector
                    v-model="localItem.qualities"
                    v-bind:tekst="localItem.gualitiestekst"
                    :listtype="qualities"
                    :disabled="!editAllowed"
                    @change="setChangedList"
                    @input="setInputQualities"
                >
                </list-selector>
                <b-row>
                    <div>
                        <b-col sm="3">
                            <label for="input-guid"
                                >{{
                                    $t('com_externalid', { ns: 'common' })
                                }}:</label
                            >
                        </b-col>
                        <b-col sm="9">
                            <b-form-input
                                id="input-guid"
                                v-model="localItem.externref"
                                readonly
                            ></b-form-input>
                        </b-col>
                    </div>
                    <div v-if="localItem.barcode" class="form-inline">
                        <b-col>
                            <barcode
                                v-bind:value="localItem.barcode"
                                id="targetDiv"
                            >
                                {{ nodisplay }}
                            </barcode>
                        </b-col>
                        <b-col cols="2">
                            <b-button
                                @click="PrintElem()"
                                class="bgc_aux_unimportant"
                                ><font-awesome-icon
                                    icon="print"
                                    size="1x"
                                /> </b-button
                        ></b-col>
                    </div>
                </b-row>
            </b-card>
        </div>
    </b-modal>
</template>

<script>
import VueBarcode from 'vue-barcode';
import { ExecutorBaseView } from '../../models/Anayltics';
import ListSelector from './ListSelector.vue';
import { loadList, loadItem } from './utils';
export default {
    name: 'ModalExecutor',
    data: function () {
        return {
            showDismissibleAlert: false,
            grootheden: window.constants.LISTTYPE_QUANTITY,
            qualities: window.constants.LISTTYPE_QUALITY,
            alertText: '',
            isChanged: false,
            naam: null,
            locItem: {},
            returnObject: null,
            editAllowed: true,
            executorChanged: false,
            options: [
                {
                    value: window.constants.AEX_DEVICE,
                    text: this.$t('prod_apparaat', { ns: 'production' })
                },
                {
                    value: window.constants.AEX_PERSON,
                    text: this.$t('an_persoon', { ns: 'analytics' })
                },
                {
                    value: window.constants.AEX_ORG,
                    text: this.$t('an_organisatie', { ns: 'analytics' })
                }
            ],
            allowTypeSelection: false,

            nodisplay: this.$t('com_nodisplaybarcode', { ns: 'common' }),
            berichtWijziging: this.$t('an_warnxwijzigopslaan', {
                ns: 'analytics',
                val: this.$t('an_template', { ns: 'analytics' })
            })
        };
    },
    model: {
        prop: 'visible',
        event: 'changed'
    },
    props: {
        inType: {
            type: String,
            required: false
        },
        inID: {
            type: Number,
            required: false
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    components: {
        //LookupCombo,
        barcode: VueBarcode,
        ListSelector
    },
    watch: {},
    computed: {
        formtitle() {
            const lowuitvoerder = this.$t('an_uitvoerder', {
                ns: 'analytics'
            }).toLowerCase();
            return this.locItem.isNew
                ? this.$t('com_makenew', {
                      ns: 'common',
                      val: lowuitvoerder
                  })
                : this.$t('com_edit', {
                      ns: 'common',
                      val: this.locItem.naam ?? lowuitvoerder
                  });
        },
        localItem() {
            return this.locItem;
        },
        magEdit() {
            return true; // !(this.locItem.typenaam && this.locItem.veldid);
        }
    },
    methods: {
        PrintElem() {
            const mywindow = window.open('', 'PRINT', 'height=400,width=600');

            mywindow.document.write(
                '<html><head><title>' + document.title + '</title>'
            );
            mywindow.document.write('</head><body >');
            mywindow.document.write(
                document.getElementById('targetDiv').innerHTML
            );
            mywindow.document.write('</body></html>');

            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/

            mywindow.print();
            mywindow.close();

            return true;
        },
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        hide() {
            this.$emit('hide', this.returnObject);
        },
        setListEditted() {
            this.executorChanged = true;
            this.isChanged = true;
        },
        setChangedList() {
            this.executorChanged = false;
            this.isChanged = true;
        },
        setChanged() {
            this.isChanged = true;
        },
        setInputGrootheden(value) {
            this.locItem.grootheden = value;
        },
        setInputQualities(value) {
            this.locItem.qualities = value;
        },
        checkFormValidity() {
            const invalidBasis = this.locItem.CheckValidity(this.$t);
            if (invalidBasis) this.showAlert(invalidBasis);
            const valid =
                this.locItem != undefined &&
                !invalidBasis &&
                !this.executorChanged;
            return valid;
        },
        async checkExecutorEdited() {
            let gaDoor = true;
            if (this.executorChanged) {
                gaDoor = await this.$root.showConfirmationBox(
                    this.berichtWijziging
                );
                if (gaDoor) this.executorChanged = false;
            }
            return gaDoor;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        async handleSubmit() {
            // Exit when the form isn't valid
            const gaDoor = await this.checkExecutorEdited();
            if (!gaDoor) return;
            if (this.isChanged) {
                this.locItem.naam = this.naam;
                if (!this.checkFormValidity()) {
                    return;
                }
                this.returnObject = await this.saveThisExecutor();
                this.$emit('forcerefresh', true);
            }
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-executor');
            });
        },
        async saveThisExecutor() {
            const addObject = this.locItem.SetForSave();
            switch (this.locItem.type) {
                case window.constants.AEX_DEVICE: {
                    loadItem('analytics/saveDevice', addObject, this.$t);
                    break;
                }
                case window.constants.AEX_PERSON: {
                    loadItem('analytics/savePerson', addObject, this.$t);
                    break;
                }
                case window.constants.AEX_ORG: {
                    loadItem('analytics/saveOrganisation', addObject, this.$t);
                    break;
                }
                default: {
                    break;
                }
            }
        }
    },
    mounted() {},
    async created() {
        if (this.inID) {
            let obj = null;
            switch (this.inType) {
                case window.constants.AEX_DEVICE: {
                    obj = await loadItem(
                        'analytics/loadDevice',
                        {
                            executorID: this.inID
                        },
                        this.$t
                    );
                    break;
                }
                case window.constants.AEX_PERSON: {
                    obj = await loadItem(
                        'analytics/loadPerson',
                        {
                            executorID: this.inID
                        },
                        this.$t
                    );
                    break;
                }
                case window.constants.AEX_ORG: {
                    obj = await loadItem(
                        'analytics/loadOrganisation',
                        {
                            executorID: this.inID
                        },
                        this.$t
                    );
                    break;
                }
                default: {
                    obj = new ExecutorBaseView();
                    this.allowTypeSelection = true;
                    break;
                }
            }
            this.locItem = obj;
            this.locItem.type = this.inType;
            this.naam = this.locItem.naam;
        } else {
            this.locItem = new ExecutorBaseView();
            this.locItem.type = window.constants.AEX_DEVICE;
            this.allowTypeSelection = true;
        }
    }
};
</script>

<style scoped></style>

