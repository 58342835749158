<template>
    <div
        id="targetDiv"
        class="form-inline"
        overflow="none"
        width="95%"
        v-if="theStockItem != undefined"
    >
        <div class="mx-0 px-0 my-0 py-0" style="float: left">
            <barcode
                v-bind:value="barcodeValue"
                id="targetBarcode"
                class="mx-0 px-0 my-0 py-0"
                width="2"
            >
            </barcode>
        </div>
        <div v-if="isKaas" class="mx-1 px-1 my-1 py-1" style="float: left">
            <table>
                <tr>
                    <td>{{ $t('com_kaassoort', { ns: 'common' }) }}:</td>
                    <td>{{ theStockItem.naam }}</td>
                </tr>
                <tr>
                    <td>{{ $t('com_production', { ns: 'common' }) }}:</td>
                    <td>{{ showDate(theStockItem.productiedatum) }}</td>
                </tr>
                <tr>
                    <td>{{ $t('prod_bakgroep', { ns: 'production' }) }}:</td>
                    <td>{{ theStockItem.batchID }}</td>
                </tr>
                <tr>
                    <td>{{ $t('com_amountshort', { ns: 'common' }) }}:</td>
                    <td>
                        {{ theStockItem.aantal }}&nbsp;{{
                            theStockItem.eenheid
                        }}
                    </td>
                </tr>
                <tr>
                    <td>{{ $t('com_marks', { ns: 'common' }) }}:</td>
                    <td>
                        {{ theMarksString }}
                    </td>
                </tr>
            </table>
        </div>
        <div v-if="!isKaas" class="mx-1 px-1 my-1 py-1" style="float: left">
            <table>
                <tr>
                    <td>{{ $t('com_product', { ns: 'common' }) }}:</td>
                    <td>{{ theStockItem.naam }}</td>
                </tr>
                <tr>
                    <td>{{ $t('com_tht', { ns: 'common' }) }}:</td>
                    <td>{{ showDate(theStockItem.datum) }}</td>
                </tr>
                <tr>
                    <td>{{ $t('com_batch', { ns: 'common' }) }}:</td>
                    <td>{{ theStockItem.batchID }}</td>
                </tr>
                <tr>
                    <td>{{ $t('com_amountshort', { ns: 'common' }) }}:</td>
                    <td>
                        {{ theStockItem.aantal }}&nbsp;{{
                            theStockItem.eenheid
                        }}
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>
<script>
import { PRODCAT_ENUM } from '../../models/ProductCategorie';
import DateHelper from '../../models/DateHelper';
import { loadList, loadItem } from '../../models/DataHelper';
import Helper from '../../models/Helper';
import VueBarcode from 'vue-barcode';
export default {
    name: 'PrintProdLabel',
    data: function () {
        return {
            errorMessage: '',
            refreshedStockItem: null,
            refreshedMarks: null,
            marksstring: '',
            teller: 1,
            text: 'cvbcvbxcxvcb \t xvkkxncv \r xcvnxv'
        };
    },
    components: {
        barcode: VueBarcode
    },
    model: {
        prop: 'visible',
        event: 'hide'
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        stockItemID: {
            type: Number,
            default: -1
        },
        landscape: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        visible() {
            if (this.visible) this.getData();
        }
    },
    computed: {
        nodisplay() {
            return this.stockItemID == -1
                ? this.$t('com_nodisplaybarcode', { ns: 'common' })
                : '';
        },
        printArea() {
            return this.isKaas ? 'printKaas' : 'printStock';
        },
        printName() {
            return this.isKaas
                ? this.$t('com_stock', { ns: 'common' }) + ' id'
                : this.$t('com_stock', { ns: 'common' }) + ' id';
        },
        theStockItem() {
            return this.refreshedStockItem;
        },
        barcodeValue() {
            return this.theStockItemId;
        },
        theStockItemId() {
            return this.stockItemID;
        },
        theMarksString() {
            // eslint-disable-next-line no-unused-vars
            const a = this.teller;
            return this.marksstring;
        },
        theMarksDataset() {
            return this.refreshedMarks;
        },
        isKaas() {
            return (
                this.refreshedStockItem.prodcatID == PRODCAT_ENUM.Kaas ||
                this.refreshedStockItem.prodcatID == PRODCAT_ENUM.Onvolwaardig
            );
        }
    },
    methods: {
        print() {
            if (this.landscape) {
                this.$htmlToPaper('targetDiv', {
                    styles: ['/css/bootstrap.min.css', '/css/landscape.css']
                });
                // this.$htmlToPaper('targetDiv', {
                //     style: `@page { margin: 0mm; size: landscape 89m 36m; } @media print { body { margin: 0mm; width: 89mm; height: 36mm } }`
                // });
            } else {
                this.$htmlToPaper('targetDiv', {
                    styles: ['/css/bootstrap.min.css', '/css/portrait.css']
                });
            }
        },
        printTwee() {
            const prthtml = document.getElementById('targetDiv').innerhtml;

            // get all stylesheets html
            let styleshtml = '';
            for (const node of [
                ...document.querySelectorAll('link[rel="stylesheet"], style')
            ]) {
                styleshtml += node.outerhtml;
            }

            // open the print window
            const winprint = window.open(
                '',
                '',
                'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0'
            );

            winprint.document.write(`<!doctype html>
<html>
  <head>
    ${styleshtml}
  </head>
  <body>
    ${prthtml}
  </body>
</html>`);

            winprint.document.close();
            winprint.focus();
            winprint.print();
            winprint.close();

            return true;
        },
        showDate(aDate) {
            return DateHelper.convertedToDateShort(aDate);
        },
        showMarks() {
            let result =
                '-- ' + this.$t('com_geen_data', { ns: 'common' }) + '--';
            if (this.refreshedMarks.data == undefined) {
                return result;
            }
            let startno = null;
            let eindno = null;
            let startchar = null;
            let eindchar = null;
            let prevchar = null;
            let prevstart = null;
            let preveind = null;
            const doAdd = this.refreshedMarks.data.Items.length < 3;
            let counter = 0;
            for (
                let index = 0;
                index < this.refreshedMarks.data.Items.length;
                index++
            ) {
                const element = this.refreshedMarks.data.Items[index];
                if (element.marktype != undefined) {
                    counter += 1;
                    startno = element.startnumber;
                    eindno = element.endnumber;
                    startchar = element.startcharacters;
                    eindchar = element.endcharacters;
                    const bak = element.number;
                    const isGelijk = startchar == eindchar;
                    if (doAdd) {
                        const merkstring = isGelijk
                            ? Helper.stringformat(
                                  this.$t('prod_bak', { ns: 'production' }) +
                                      ' {0}: {1} {2}-{3}',
                                  bak,
                                  startchar,
                                  startno,
                                  eindno
                              )
                            : Helper.stringformat(
                                  this.$t('prod_bak', { ns: 'production' }) +
                                      ' {0}: {1} {2}-{3} {4}',
                                  bak,
                                  startchar,
                                  startno,
                                  eindchar,
                                  eindno
                              );
                        result =
                            counter == 1
                                ? merkstring
                                : Helper.concatString(result, merkstring);
                    } else {
                        if (counter == 1) {
                            result = '';
                            prevchar = startchar;
                            prevstart = startno;
                        }
                        if (prevchar == startchar) {
                            //optellen
                            if (!isGelijk) {
                                const merkstring = Helper.stringformat(
                                    '{0} {1}-{2}',
                                    prevchar,
                                    prevstart,
                                    startno
                                );
                                result = Helper.concatString(
                                    result,
                                    merkstring
                                );
                                prevchar = eindchar;
                                prevstart = eindno; // dubieus
                            }
                        } else {
                            const merkstring = Helper.stringformat(
                                '{0} {1}-{2}',
                                prevchar,
                                prevstart,
                                preveind
                            );
                            result = Helper.concatString(result, merkstring);
                            prevchar = startchar;
                            prevstart = startno;
                        }
                        preveind = eindno;
                    }
                }
            }
            if (!doAdd) {
                const merkstring = Helper.stringformat(
                    '{0} {1}-{2}',
                    prevchar,
                    prevstart,
                    preveind
                );
                result = Helper.concatString(result, merkstring);
            }
            this.marksstring = result;
        },
        async getData() {
            if (this.theStockItemId > -1) {
                this.refreshedMarks = null;
                this.refreshedStockItem = await loadItem(
                    'stock/loadStockItemInfo',
                    {
                        stockitemid: this.stockItemID
                    },
                    this.$t
                );
                if (this.isKaas) {
                    this.refreshedMarks = await loadItem(
                        'stock/loadStockItemMarksInfo',
                        {
                            batchgroup: this.refreshedStockItem.batchID
                        },
                        this.$t
                    );
                    this.showMarks();
                }

                this.$nextTick(() => {
                    this.print();
                    //this.$htmlToPaper('targetDiv');
                    //this.printTwee();
                    // { window.print(); }
                });
            }
        }
    },
    mounted() {},
    async created() {
        loadList(
            'product/getProductCategorieLijst',
            'product/loadProductCategories',
            null,
            false,
            this.$t
        );
        await this.getData();
    }
};
</script>

<style scoped>
</style>

