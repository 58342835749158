<template>
    <b-modal
        id="modal-deliverynoteitem"
        size="xl"
        ref="modal"
        :title="this.formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
        @change="change"
    >
        <b-card
            @submit.stop.prevent="handleSubmit"
            :header="$t('com_product', { ns: 'common' })"
        >
            <div>
                <b-row v-if="localItem.isNew">
                    <b-col sm="2">
                        <div class="form-inline">
                            <label for="input-merknummer"
                                >{{ $t('com_mark', { ns: 'common' }) }}:</label
                            >
                            &nbsp;
                            <b-button
                                @click="scanMerk()"
                                class="bgc_aux_unimportant float-md-right"
                                ><font-awesome-icon icon="barcode" size="1x" />
                            </b-button>
                        </div>
                    </b-col>
                    <b-col sm="10">
                        <div class="form-inline">
                            <b-form-input
                                id="input-merknummer"
                                :placeholder="
                                    $t('com_cheesemark', { ns: 'common' })
                                "
                                v-model="merknummer"
                                :disabled="!magEdit"
                                @change="lookupMarkNumber"
                            ></b-form-input>
                        </div>
                    </b-col>
                </b-row>
                <b-row v-if="localItem.isNew">
                    <b-col sm="2">
                        <div class="form-inline">
                            <label for="input-kaas"
                                >{{ $t('com_kaas', { ns: 'common' }) }}:</label
                            >
                            &nbsp;
                            <b-button
                                @click="scanBakgroep()"
                                class="bgc_aux_unimportant float-md-right"
                                ><font-awesome-icon icon="barcode" size="1x" />
                            </b-button>
                        </div>
                    </b-col>
                    <b-col sm="10">
                        <div class="form-inline" v-if="!stockFound">
                            <lookup-combo
                                v-if="showBron"
                                v-model="bron"
                                :disabled="!magEdit"
                                v-bind:combotype="lctypeCompany"
                                @input="setDiscrim"
                            ></lookup-combo>
                            &nbsp;
                            <lookup-combo
                                v-model="localItem.productID"
                                v-bind:combotype="lctypeProduct"
                                v-bind:discrim="discrimKaas"
                                :showfilter="true"
                                :disabled="!magEdit"
                                @input="lookupProduct"
                            ></lookup-combo>
                            &nbsp;
                            <lookup-combo
                                v-model="localItem.stockItemID"
                                v-bind:combotype="lctypeProductStock"
                                v-bind:discrim="selProductDiscrim"
                                v-bind:filter="selBatchnumber"
                                :showfilter="true"
                                :disabled="!magEdit"
                                @input="setChangedPartij"
                            ></lookup-combo>
                        </div>
                        <b-form-input
                            v-if="stockFound"
                            v-model="productNaamStock"
                            readonly
                        ></b-form-input>
                    </b-col>
                </b-row>
                <b-row v-if="localItem.isNew">
                    <b-col sm="2">
                        <label for="input-product"
                            >{{ $t('com_product', { ns: 'common' }) }}:</label
                        >
                        &nbsp;
                        <b-button
                            @click="scanOverig()"
                            class="bgc_aux_unimportant float-md-right"
                            ><font-awesome-icon icon="barcode" size="1x" />
                        </b-button>
                    </b-col>
                    <b-col sm="10">
                        <div class="form-inline" v-if="!stockFound">
                            <lookup-combo
                                v-model="localItem.productID"
                                v-bind:combotype="lctypeProduct"
                                v-bind:discrim="discrimNotKaas"
                                :showfilter="true"
                                :disabled="!magEdit"
                                @input="lookupProduct"
                            ></lookup-combo>
                        </div>
                        <b-form-input
                            v-if="stockFound"
                            v-model="productNaamStock"
                            readonly
                        ></b-form-input>
                    </b-col>
                </b-row>
                <b-row v-if="!localItem.isNew">
                    <b-col sm="2">
                        <label
                            >{{ $t('com_product', { ns: 'common' }) }}:</label
                        >
                    </b-col>
                    <b-col sm="10">
                        <b-form-input
                            v-model="productNaamStock"
                            readonly
                        ></b-form-input>
                    </b-col>
                </b-row>
                <b-row v-if="!localItem.isNew">
                    <b-col sm="2">
                        <label for="input-proddate"
                            >{{
                                $t('com_production', { ns: 'common' })
                            }}:</label
                        >
                    </b-col>
                    <b-col sm="10">{{ showTheProdDate }} </b-col>
                </b-row>
            </div>
        </b-card>
        <b-card
            @submit.stop.prevent="handleSubmit"
            :header="$t('com_choice', { ns: 'common' })"
        >
            <b-alert
                v-model="showDismissibleAlert"
                variant="danger"
                dismissible
            >
                {{ alertText }}
            </b-alert>
            <div>
                <b-row>
                    <b-col sm="6">
                        <b-row>
                            <b-col sm="3">
                                <label for="input-amount"
                                    >{{
                                        $t('com_amount', { ns: 'common' })
                                    }}:</label
                                >
                            </b-col>
                            <b-col sm="9">
                                <unit-input
                                    id="input-amount"
                                    :placeholder="
                                        $t('com_amount', { ns: 'common' })
                                    "
                                    :unitId="eenheidAmount"
                                    :minvalue="0"
                                    :maxvalue="500000"
                                    v-model="localItem.aantal"
                                    :editAllowed="magEdit"
                                    @input="setChangedAmount"
                                />
                            </b-col>
                        </b-row>
                        <b-row
                            v-if="
                                useWeight &&
                                isKaas(refreshedStockItem.prodcatID)
                            "
                        >
                            <b-col sm="3">
                                <label for="input-weight"
                                    >{{
                                        $t('com_weight', { ns: 'common' })
                                    }}:</label
                                >
                            </b-col>
                            <b-col sm="9">
                                <unit-input
                                    id="input-weight"
                                    :placeholder="
                                        $t('com_weight', { ns: 'common' })
                                    "
                                    :unitId="eenheidWeight"
                                    :minvalue="-50"
                                    :maxvalue="500000"
                                    v-model="localItem.gewicht"
                                    :editAllowed="magEdit"
                                />
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col sm="6">
                        <b-table
                            :items="marksList"
                            :fields="visibleMarksFields"
                            stacked="md"
                            show-empty
                            :empty-text="$t('com_geen_data', { ns: 'common' })"
                            small
                            striped
                            responsive
                        >
                            <template #cell(startmerk)="data">
                                {{ data.item.startcharacters }}
                                &nbsp;
                                {{ data.item.startnumber }}
                            </template>
                            <template #cell(eindmerk)="data">
                                {{ data.item.endcharacters }}
                                &nbsp;
                                {{ data.item.endnumber }}
                            </template>
                        </b-table>
                    </b-col>
                </b-row>
                <!--                 <b-row>
                    <b-col sm="2">
                        <label for="input-itemremark">Opmerking:</label>
                    </b-col>
                    <b-col sm="10">
                        <b-form-textarea
                            id="input-itemremark"
                            placeholder="Opmerking bij de leverregel"
                            v-model="localItem.opmerking"
                            @change="setChanged"
                            rows="3"
                        ></b-form-textarea>
                    </b-col>
                </b-row> -->
            </div>
        </b-card>
        <scan-barcode
            v-if="showScanner"
            v-model="showScanner"
            @result="showDetailStockScan"
        />
    </b-modal>
</template>

<script lang="ts">
import { DeliveryItemStock } from '../../models/Delivery';
import { VoorraadItem } from '../../models/Voorraad';
import { LookupCombo, UnitInput } from '../components';
import { defineComponent } from 'vue';
import DateHelper from '../../models/DateHelper';
import { loadList, loadItem } from '../../models/DataHelper';
import Helper from '../../models/Helper';
import ScanBarcode from '../components/ScanBarcode.vue';
import { VueExtension } from '../../models/VueExtension';
import { Feature } from '@/models/Features';
import PRODCAT_ENUM from '@/models/ProductCategorie';

export default defineComponent({
    name: 'DeliveryNoteItemModal',
    data: function () {
        return {
            showDismissibleAlert: false,
            alertText: '',
            lctypeProduct: window.constants.LCPRODUCT,
            lctypeCompany: window.constants.LCCOMPANY,
            lctypeProductStock: window.constants.LCPRODUCTSTOCK,
            opmerking: '',

            stockIsFound: false,
            productNaamStockTekst: '',
            aangeraakt_teller: 0,
            discrimKaas: PRODCAT_ENUM.Kaas.toString(),
            discrimNotKaas: '!' + PRODCAT_ENUM.Kaas.toString(),
            eenheidWeight: -1,
            eenheidAmount: -1,
            bron: -1,

            refreshedStockItem: {} as VoorraadItem | null,
            refreshedMarks: null,
            showScanner: false,
            scanmerk: false,
            scanbakgroep: false,
            scanoverig: false,
            merknummer: '',
            availableCheeseList: [],
            selProduct: '-1',
            selDiscrim: null,
            selBatchnumber: '',
            selBatchnumbertemp: '',
            stringPartijGevonden: this.$t('prod_partijgevonden', {
                ns: 'production'
            }),
            stringMeerDanVoorraad: this.$t('prod_meerdanvoorraad', {
                ns: 'production'
            }),
            stringOnleesbaarMerk: this.$t('prod_onleesbaarmerk', {
                ns: 'production'
            }),
            stringMeerderePartijen: this.$t('prod_meerderepartijen', {
                ns: 'production'
            }),
            stringOverigGevonden: this.$t('prod_overiggevonden', {
                ns: 'production'
            })
        };
    },
    model: {
        prop: 'visible',
        event: 'change'
    },
    props: {
        inItem: {
            type: DeliveryItemStock,
            required: true
        },
        visible: {
            type: Boolean,
            default: false
        },
        editAllowed: {
            type: Boolean,
            default: false
        }
    },
    components: {
        ScanBarcode,
        LookupCombo,
        UnitInput
    },
    watch: {},
    computed: {
        formtitle() {
            return this.inItem.isNew
                ? this.$t('com_makenew', {
                      ns: 'common',
                      val: this.$t('com_regel', { ns: 'common' }).toLowerCase()
                  })
                : this.$t('com_edit', {
                      ns: 'common',
                      val: this.$t('com_regel', { ns: 'common' }).toLowerCase()
                  });
        },
        localItem(): DeliveryItemStock {
            return this.inItem;
        },
        stockFound(): boolean {
            return this.stockIsFound;
        },
        productNaamStock(): string {
            return this.productNaamStockTekst;
        },
        availableCheese() {
            // eslint-disable-next-line no-unused-vars
            const tel = this.aangeraakt_teller;
            return this.availableCheeseList;
        },
        magEdit() {
            if (this.editAllowed && this.localItem) {
                return true; // this.localItem.id == undefined || this.localItem.id < 0;
            } else {
                return false;
            }
        },
        marksList() {
            if (this.refreshedMarks) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const o = this.refreshedMarks as any;
                return o.data ? o.data.Items : [];
            } else return [];
        },
        showTheProdDate(): string {
            const a = this.aangeraakt_teller;
            return this.refreshedStockItem
                ? DateHelper.convertedToDateShort(
                      this.refreshedStockItem.productiedatum
                  )
                : '';
        },
        visibleMarksFields() {
            const fieldsInit = [
                {
                    key: 'number',
                    label: this.$t('prod_bak', { ns: 'production' }) + '#',
                    visible: true
                },
                {
                    key: 'marktype',
                    label: this.$t('com_marktype', { ns: 'common' }),
                    visible: true
                },
                {
                    key: 'startmerk',
                    label:
                        this.$t('com_start', { ns: 'common' }) +
                        this.$t('com_mark', { ns: 'common' }).toLowerCase(),
                    visible: true
                },
                {
                    key: 'eindmerk',
                    label:
                        this.$t('com_eind', { ns: 'common' }) +
                        this.$t('com_mark', { ns: 'common' }).toLowerCase(),
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        showBron() {
            return this.checkFeature('conf_useMilkSupplier');
        },
        selProductDiscrim() {
            // eslint-disable-next-line no-unused-vars
            const tel = this.aangeraakt_teller;
            const samen = this.selDiscrim
                ? this.selProduct + '&&' + this.selDiscrim
                : this.selProduct;
            return samen;
        },
        useWeight() {
            return this.checkFeature('conf_UseCheeseWeights');
        }
    },
    methods: {
        isKaas(item) {
            return (
                item == PRODCAT_ENUM.Kaas || item == PRODCAT_ENUM.Onvolwaardig
            );
        },
        checkFeature(feature) {
            const key = Feature.enum(feature);
            return (this.$root as VueExtension).handleFeature(key);
        },
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        scanMerk() {
            this.scanmerk = true;
            this.showScanner = !this.showScanner;
        },
        scanBakgroep() {
            this.scanbakgroep = true;
            this.showScanner = !this.showScanner;
        },
        scanOverig() {
            this.scanoverig = true;
            this.showScanner = !this.showScanner;
        },
        async lookupMarkNumber(itemString) {
            const mark = itemString ?? this.merknummer;
            const myArray = mark.split(' ');
            if (myArray.length != 2) {
                (this.$root as VueExtension).showMessageBox(
                    Helper.stringformat(this.stringOnleesbaarMerk, mark)
                );
                return;
            }
            const selectLetters = myArray[0];
            const selectNummers = myArray[1];
            // vind het product
            const selectionData = {
                text: selectLetters,
                number: selectNummers
            };
            const list = await loadItem(
                'delivery/loadStockForMark',
                selectionData,
                this.$t
            );
            const distList = Helper.distinctList(list, 'stockitemid');
            let stockItemID = -1;
            if (distList.length > 1) {
                // als meer dan 1 stockitem dan selectie laten maken
                const product = distList[0].productid;
                const batch = distList[0].batchgroup;
                (this.$root as VueExtension).showMessageBox(
                    Helper.stringformat(this.stringMeerderePartijen, batch)
                );
                this.localItem.productID = product;
                this.selBatchnumbertemp = batch ? batch.toString() : '';
                this.lookupProduct(product);
                return;
            } else {
                stockItemID =
                    distList.length == 1 ? distList[0].stockitemid : -1;
            }
            // als kaas nog in de pekel zit is stockitemid null
            stockItemID = stockItemID ?? -1;
            this.localItem.stockItemID = stockItemID;
            this.setChangedPartij(stockItemID);
        },

        hide() {
            this.opmerking = '';
            this.$emit('hide');
        },
        change(visible) {
            this.setChanged();
            this.$emit('change', visible);
        },
        setChanged() {
            this.localItem.isChanged = true;
        },
        async setChangedAmount(aantal) {
            const marge = this.refreshedStockItem
                ? this.refreshedStockItem.aantal - aantal
                : 0;
            let gaDoor = true;
            if (marge < 0) {
                gaDoor = await (this.$root as VueExtension).showConfirmationBox(
                    Helper.stringformat(
                        this.stringMeerDanVoorraad,
                        aantal,
                        this.refreshedStockItem?.aantal
                    )
                );
            }
            if (!gaDoor) {
                this.localItem.aantal = this.refreshedStockItem?.aantal;
            }
            this.localItem.isChanged = true;
        },
        async showDetailStock(stockItemID) {
            const itemNumber = stockItemID;
            this.refreshedStockItem = await loadItem(
                'stock/loadStockItem',
                { stockitemid: itemNumber },
                this.$t
            );
            this.eenheidAmount = this.refreshedStockItem?.unitID;
            const bakid = this.refreshedStockItem
                ? this.refreshedStockItem.batchID
                : -1;
            if (bakid) {
                this.localItem.productID = this.refreshedStockItem?.productID;
                this.localItem.stockItemID = this.refreshedStockItem
                    ? this.refreshedStockItem.id
                    : -1;
                this.stockIsFound = true;
                // ophalen naam als niet beschikbaar
                let naam = this.refreshedStockItem?.naam;
                let prodCat = this.refreshedStockItem?.prodcatID;
                if (naam == undefined || prodCat == undefined) {
                    const productLijst = await loadList(
                        'components/getProductsComboLijst',
                        'components/loadProductsComboLijst',
                        null,
                        false,
                        this.$t
                    );
                    const product = productLijst.find(
                        (x) => x.value == this.refreshedStockItem?.productID
                    );
                    naam = product ? product.text : '??';
                    prodCat = product ? product.catid : null;
                    if (this.refreshedStockItem)
                        this.refreshedStockItem.prodcatID = prodCat;
                }
                if (this.isKaas(prodCat)) {
                    await this.showDetailStockCheese(
                        this.refreshedStockItem,
                        naam
                    );
                } else {
                    await this.showDetailStockOverig(
                        this.refreshedStockItem,
                        naam
                    );
                }
            } else {
                this.stockIsFound = false;
            }
        },
        async showDetailStockCheese(si, naam) {
            this.refreshedMarks = await loadItem(
                'stock/loadStockItemMarksInfo',
                { batchgroup: si.batchID },
                this.$t
            );
            const loclist = await loadList(
                'components/getShapesComboLijst',
                'components/loadShapesComboLijst',
                null,
                false,
                this.$t
            );
            const o = loclist.find((x) => x.value == si.vormID);
            const vorm = o?.text ?? null;

            this.productNaamStockTekst = Helper.stringformat(
                this.stringPartijGevonden,
                naam,
                si.batchID ?? '-',
                si.aantal ?? '-',
                vorm ?? '-',
                si.leeftijdKlasse ?? '-',
                si.leeftijdDagen ?? '-'
            );
            this.localItem.referentie = this.productNaamStockTekst;
            this.aangeraakt_teller += 1;
        },
        async showDetailStockOverig(si, naam) {
            this.productNaamStockTekst = Helper.stringformat(
                this.stringOverigGevonden,
                naam,
                si.batchID ?? '?',
                si.aantal ?? '?'
            );
            this.localItem.referentie = this.productNaamStockTekst;
            this.aangeraakt_teller += 1;
        },
        async showDetailStockScan(value) {
            if (this.scanmerk) {
                // lezen datamatrix of merk
                const itemString = value;
                this.merknummer = itemString;
                this.lookupMarkNumber(itemString);
            }
            if (this.scanbakgroep) {
                const itemNumber = Number(value);
                this.showDetailStock(itemNumber);
            }
            if (this.scanoverig) {
                const itemNumber = Number(value);
                this.showDetailStock(itemNumber);
            }
            this.scanmerk = false;
            this.scanbakgroep = false;
            this.scanoverig = false;
        },
        setChangedPartij(val) {
            const nieuwePartij = val;
            this.showDetailStock(nieuwePartij);
            this.localItem.isChanged = true;
        },
        setDiscrim(val) {
            const lijst =
                this.$store.getters['components/getCompaniesComboLijst'];

            const o = lijst.find((x) => x.value == val);
            this.selDiscrim = o ? o.afkort ?? o.text : null;
            this.aangeraakt_teller += 1;
        },
        lookupProduct(val) {
            if (val == undefined || val < 0) return;
            this.selProduct = val.toString();
            this.selBatchnumber = this.selBatchnumbertemp;
            this.aangeraakt_teller += 1;
            this.setChanged();
        },
        checkFormValidity(): boolean {
            const invalidBasis = this.localItem.CheckValidity(this.$t);
            if (invalidBasis) this.showAlert(invalidBasis);
            const valid = this.localItem != undefined && !invalidBasis;
            return valid;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        handleSubmit(): void {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return;
            }
            this.saveThisProduct();
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-deliverynoteitem');
            });
        },
        async saveThisProduct() {
            if (this.localItem.isChanged || this.localItem.isNew) {
                this.$emit('input', this.localItem);
            }
        }
    },
    created() {
        this.eenheidWeight = window.constants.KILOS; // todo
        const a = this.inItem.eenheidID;
        this.eenheidAmount = a;
        if (!this.inItem.isNew) {
            this.showDetailStock(this.inItem.id);
        }
    }
});
</script>

<style scoped></style>

