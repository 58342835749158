<template>
    <div class="form-inline">
        <b-col sm="6" class="mx-0 px-0 my-0 py-0">
            <b-input-group :prepend="$t('prod_step', { ns: 'production' })" :label="$t('prod_step', { ns: 'production' })">
                <b-form-select
                    v-model="stepID"
                    :options="flexList"
                    @change="set_changed"
                    :disabled="disabled"
                >
                    <template #first>
                        <b-form-select-option value=""
                            >-- {{$t('com_selecteer', { ns: 'common' })}} --</b-form-select-option
                        >
                    </template>
                </b-form-select>
            </b-input-group>
        </b-col>

        <b-col sm="6" class="mx-0 px-0 my-0 py-0">
            <b-input-group :prepend="$t('prod_wachttijd', { ns: 'production' })" :label="$t('prod_wachttijd', { ns: 'production' })">
                <b-form-input
                    :placeholder="$t('prod_wachttijd', { ns: 'production' })"
                    type="number"
                    step="1"
                    v-model.number="wachttijd"
                    @change="set_changed"
                    :disabled="disabled"
                ></b-form-input>
            </b-input-group>
        </b-col>
    </div>
</template>
<script>
import { loadList } from '../../models/DataHelper';
import { TreatmentRecipeStep } from '@/models/CheeseTreatment';
export default {
    name: 'TreatmentStepCombo',
    data: function () {
        return {
            stepID: -1,
            wachttijd: 48,
            flexList: [],
            isChanged: false
        };
    },
    model: {
        prop: 'selected',
        event: 'input'
    },
    props: {
        selected: {
            type: TreatmentRecipeStep,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    components: {},
    computed: {},
    methods: {
        set_changed() {
            this.isChanged = true;
            this.selected.behandelstapID = this.stepID;
            const ob = this.flexList.find((x) => x.value == this.stepID);
            this.selected.behandelstap = ob?.text;
            this.selected.wachttijd = this.wachttijd;
            this.$emit('change', this.selected);
        },
        async loadData() {
            const tempList = await loadList(
                'treatment/getTreatmentSteps',
                'treatment/loadTreatmentSteps',
                null,
                this.forcerefresh,
                this.$t
            );
            this.flexList = tempList.map((x) => ({
                value: x.id,
                text: x.naam
            }));
        }
    },
    async mounted() {
        await this.loadData();
        this.stepID = this.selected.behandelstapID;
        const ob = this.flexList.find((x) => x.value == this.stepID);
        this.selected.behandelstap = ob?.text;
        this.wachttijd = this.selected.wachttijd;
    },
    created() {}
};
</script>

<style scoped></style>
