import Api from '../../../api';
import store from '../../../store';

// int rpID
const fetchRecurrencyPattern = async data => {
    return await fetchInfo( '/Recurrency/GetRecurrencyPattern', data) ;
};

// datetime from , until
const fetchRecurrencyOverPeriod = async data => {
    return await fetchInfo( '/Recurrency/GetRecurrencyOverPeriod', data) ;
};




 const fetchInfo = async (apicall, data) => {
    if (data != undefined) {
        return await Api(store).get(
            apicall +'?' +
                Object.keys(data)
                    .map(
                        k =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    } else {
        return await Api(store).get(apicall, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    }
};

export default {
    fetchRecurrencyPattern,
    fetchRecurrencyOverPeriod
};
