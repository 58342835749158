<template>
    <b-card>
        <div class="form-inline">
            {{ strOccurs }} &nbsp;
            <b-form-input
                id="input-intervalday"
                type="number"
                step="1"
                style="width: 30%"
                v-model.number="localInterval"
                :disabled="!editAllowed"
                @change="setChanged"
            ></b-form-input>
            &nbsp;{{ strOccurs4 }}
        </div>
        <b-form-group>
            <b-form-radio v-model="selected" :value="constYearDay">
                <div class="form-inline">
                    {{ strOccurs1 }} &nbsp;
                    <b-form-input
                        id="input-day"
                        type="number"
                        step="1"
                        style="width: 30%"
                        :min="1"
                        :max="31"
                        v-model.number="localMaanddagen"
                        :disabled="!editAllowed"
                        @change="setChanged"
                    ></b-form-input>
                    &nbsp;
                    <b-form-select
                        style="width: 40%"
                        v-model="localMaand"
                        :options="locallijstMaanden"
                        :disabled="!editAllowed"
                        @change="setChanged"
                    >
                        <template #first>
                            <b-form-select-option value=""
                                >--
                                {{
                                    $t('com_selecteer', {
                                        ns: 'common'
                                    })
                                }}
                                --</b-form-select-option
                            >
                        </template>
                    </b-form-select>
                </div>
            </b-form-radio>
            <b-form-radio v-model="selected" :value="constYearWeek">
                <div class="form-inline">
                    {{ strOccurs2 }} &nbsp;
                    <b-form-select
                        style="width: 25%"
                        v-model="localWeek"
                        :options="locallijstWeken"
                        :disabled="!editAllowed"
                        @change="setChanged"
                    >
                        <template #first>
                            <b-form-select-option value=""
                                >--
                                {{
                                    $t('com_selecteer', {
                                        ns: 'common'
                                    })
                                }}
                                --</b-form-select-option
                            >
                        </template>
                    </b-form-select>
                    <b-form-select
                        style="width: 25%"
                        v-model="localDag"
                        :options="locallijstDagen"
                        :disabled="!editAllowed"
                        @change="setChanged"
                    >
                        <template #first>
                            <b-form-select-option value=""
                                >--
                                {{
                                    $t('com_selecteer', {
                                        ns: 'common'
                                    })
                                }}
                                --</b-form-select-option
                            >
                        </template>
                    </b-form-select>
                    &nbsp;{{ strOccurs3 }} &nbsp;
                    <b-form-select
                        style="width: 30%"
                        v-model="localMaand"
                        :options="locallijstMaanden"
                        :disabled="!editAllowed"
                        @change="setChanged"
                    >
                        <template #first>
                            <b-form-select-option value=""
                                >--
                                {{
                                    $t('com_selecteer', {
                                        ns: 'common'
                                    })
                                }}
                                --</b-form-select-option
                            >
                        </template>
                    </b-form-select>
                </div></b-form-radio
            >
        </b-form-group>
    </b-card>
</template>

<script>
import { recurrencyHelper, RECURRENCY_ENUM } from '../../models/Recurrency';
export default {
    name: 'CardYearly',
    data() {
        return {
            selected: 1,
            constYearWeek: 2,
            constYearDay: 1,
            localInterval: 1,
            localDag: 1,
            localWeek: 1,
            localMaanddagen: 12,
            strOccurs: this.$t('rec_occursevery', { ns: 'recurrency' }),
            strOccurs4: this.$t('rec_occursdayyear', { ns: 'recurrency' }),
            strOccurs1: this.$t('rec_occursdayyear1', { ns: 'recurrency' }),
            strOccurs2: this.$t('rec_occursdayyear2', { ns: 'recurrency' }),
            strOccurs3: this.$t('rec_occursdayyear3', { ns: 'recurrency' })
        };
    },
    components: {},
    props: {
        weken: {
            type: Array,
            default() {
                return [];
            }
        },
        dagen: {
            type: Array,
            default() {
                return [];
            }
        },
        maanden: {
            type: Array,
            default() {
                return [];
            }
        },
        maanddagen: {
            type: Array,
            default() {
                return [];
            }
        },
        interval: {
            type: Number,
            default: 1
        },
        editAllowed: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        locallijstWeken() {
            return recurrencyHelper.lijstWeken(this.$t);
        },
        locallijstMaanden() {
            return recurrencyHelper.lijstMaanden(this.$t);
        },
        locallijstDagen() {
            return recurrencyHelper.lijstDagen(this.$t);
        }
    },
    methods: {
        setChanged() {
            const maanddagen =
                this.selected == this.constYearDay
                    ? this.localMaanddagen
                    : undefined;
            const o = {
                type: RECURRENCY_ENUM.Yearly,
                interval: this.localInterval,
                dagen: [this.localDag],
                weken: [this.localWeek],
                maanden: [this.localMaand],
                maanddagen: [maanddagen]
            };
            this.$emit('changed', o);
        }
    },
    mounted() {},
    async created() {
        this.localMaanddagen = this.maanddagen[0];
        this.localMaand = this.maanden[0];
        this.localWeek = this.weken[0];
        this.localDag = this.dagen[0];
        this.localInterval = this.interval;
        this.selected =
            this.localMaanddagen ? this.constYearDay : this.constYearWeek;
    }
};
</script>

