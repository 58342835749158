const mutations = {

    insertDevicesLijst(state, lijst) {
        state.extDevicesLijst = [];
        if (lijst != undefined) {
            state.extDevicesLijst = lijst;
        }
    },
    insertDeviceTypesLijst(state, lijst) {
        state.extDeviceTypesLijst = [];
        if (lijst != undefined) {
            state.extDeviceTypesLijst = lijst;
        }
    },
    insertLinkedLijst(state, lijst) {
        state.linkItemsLijst = [];
        if (lijst != undefined) {
            state.linkItemsLijst = lijst;
        }
    },
    
};

export default mutations;
