
const state = {
    jwtToken: null,
    userName: null,
    loggedIn: null,
    loaded: null,
    loggedInUser: null,
    authUser: null,
    isAuthenticated: null,
    usersInGroupsList:[],
    permissionsList:[],
    packagePermissionsList:[],
    featuresList:[],
    groupPermissionsList:[],
    groupsList:[],
    operatorList:[],
    mgr: null    
};

export default state;
