<template>
    <div>
        <b-dropdown id="dropdown-form" text="..." ref="dropdown" size="sm">
            <div class="fluid-container">
                <b-dropdown-item
                    @click="showMelk"
                    v-if="checkRight('BatchAddIngredients')"
                    >{{ $t('prod_melkgeg', { ns: 'production' }) }}</b-dropdown-item
                >
                <b-dropdown-item
                    @click="showStrem"
                    v-if="checkRight('BatchAddIngredients')"
                    >{{ $t('prod_stremgeg', { ns: 'production' }) }}</b-dropdown-item
                >
                <b-dropdown-item
                    @click="showWWW"
                    v-if="checkRight('BatchAddIngredients')"
                    >{{ $t('prod_wwwkruidgeg', { ns: 'production' }) }}</b-dropdown-item
                >
                <b-dropdown-item
                    @click="showOverpomp"
                    v-if="checkRight('BatchAddIngredients')"
                    >{{ $t('prod_overpompgeg', { ns: 'production' }) }}</b-dropdown-item
                >
                <b-dropdown-item
                    @click="showMerken"
                    v-if="checkRight('BatchSetCheeseMaks')"
                    >{{ $t('prod_kaasmerkgeg', { ns: 'production' }) }}</b-dropdown-item
                >
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item
                    @click="showInpekel"
                    v-if="checkRight('BatchInBrine')"
                    >{{ $t('prod_inpekelgeg', { ns: 'production' }) }}</b-dropdown-item
                >
            </div>
        </b-dropdown>
        <production-pop-form
            v-if="detailsVisible"
            v-bind:bak="localBak"
            v-bind:prodRecept="localProdRecept"
            v-bind:displayNumber="localdisplayNumber"
            v-bind:isLastBatch="isLastBatch"
            v-bind:editAllowed="editAllowed"
            v-model="detailsVisible"
            @hide="reset"
            @production_refresh="refresh"
        />
    </div>
</template>

<script>
import ProductionPopForm from './ProductionPopForm.vue';
import { Permission } from '../../models/Permissions';
import { loadItem } from '@/models/DataHelper';
export default {
    name: 'ProdDropDownBuilder',
    data() {
        return {
            currentBak: null,
            currentProdRecept: null,
            aangeraakt: 1,
            detailsVisible: false,
            localdisplayNumber: 5,
            newAmount: null
        };
    },
    components: {
        ProductionPopForm
    },
    props: {
        bakID: {
            type: Number,
            required: true
        },
        isLastBatch: {
            type: Boolean,
            default: false
        },
        editAllowed: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        localBak() {
            // eslint-disable-next-line no-unused-vars
            const tel = this.aangeraakt;
            return this.currentBak;
        },
        localProdRecept() {
            // eslint-disable-next-line no-unused-vars
            const tel = this.aangeraakt;
            return this.currentProdRecept;
        }
    },
    methods: {
        reset() {
            this.detailsVisible = false;
            this.currentBak = null;
        },
        refresh() {
            this.$emit('production_refresh');
        },
        async showMelk() {
            this.showObject(window.constants.BI_SHOW_MELK);
        },
        async showStrem() {
            this.showObject(window.constants.BI_SHOW_STREM);
        },
        async showWWW() {
            this.showObject(window.constants.BI_SHOW_WWW);
        },
        async showMerken() {
            await this.loaddataMerken();
            this.showObject(window.constants.BI_SHOW_MERKEN);
        },
        async showOverpomp() {
            this.showObject(window.constants.BI_SHOW_OVERPOMP);
        },
        async showInpekel() {
            await this.loaddataInpekelLijst();
            this.showObject(window.constants.BI_SHOW_INPEKEL);
        },
        save() {
            // Close the menu and (by passing true) return focus to the toggle button
            this.$refs.dropdown.hide(true);
        },
        async showObject(nummer) {
            await this.loaddataBak();
            await this.loaddataRecIngredienten();
            await this.loaddataBakIngredienten();
            this.localdisplayNumber = nummer;
            this.detailsVisible = true;
        },
        async loaddataMerken() {
            const o = { batchID: this.bakID };
            //await loadItem('production/loadCheeseMarksForBatch', o, this.$t);
            await loadItem('production/loadBatchInbrineAmounts', o, this.$t);
            await loadItem('production/loadBatchDefaults', o, this.$t);
            await loadItem('production/loadMilkusagefactor', o, this.$t);
            await loadItem(
                'production/loadBatchAlternativeCheeseTypes',
                o, this.$t);
        },
        async loaddataInpekelLijst() {
            const o = { batchID: this.bakID };
            //await loadItem('production/loadCheeseMarksForBatch', o, this.$t);
            await loadItem('production/loadMilkusagefactor', o, this.$t);
            await loadItem('production/loadBatchInbrineAmounts', o, this.$t);
            await loadItem('production/loadBatchDefaults', o, this.$t);
            await loadItem(
                'production/loadBatchAlternativeCheeseTypes',
                o, this.$t);
        },
        async loaddataBak() {
            if (this.currentBak == undefined) {
                const o = { batchID: this.bakID };
                this.currentBak = await loadItem(
                    'production/loadBatch',
                    o, this.$t
                );
                await loadItem('production/loadBatchDefaults', o, this.$t);// todo qend
            }
        },
        async loaddataRecIngredienten() {
            if (this.currentBak.recIngredienten.length == 0) {
                const recept = await loadItem(
                    'recipe/loadRecipeComplete',
                    { recipeid: this.currentBak.receptID }, this.$t);
                this.currentProdRecept = recept.recProduction;
                this.currentBak.recIngredienten = recept.recIngredienten ?? [];
                this.aangeraakt += 1;
            }
        },
        async loaddataBakIngredienten() {
            if (this.currentBak.bakIngredienten.length == 0) {
                const toegevoegd = await loadItem(
                    'production/loadBatchIngredients',
                    { batchid: this.bakID }, this.$t
                );
                this.currentBak.bakIngredienten = toegevoegd ?? [];
                this.aangeraakt += 1;
            }
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        }
    },
    async mounted() {}
};
</script>
