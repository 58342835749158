<template>
    <div class="form-inline">
        <b-form-select
            v-if="existing"
            v-model="localSelected.productID"
            @change="set_input"
            :disabled="!editAllowed"
            v-bind:class="{ tc_aux_inform: showInform }"
        >
            <template #first>
                <b-form-select-option value=""
                    >--
                    {{ $t('com_selecteer', { ns: 'common' }) }}
                    --</b-form-select-option
                >
            </template>
            <option
                v-for="option in productList"
                :value="option.value"
                :key="option.value"
                v-bind:class="{ tc_aux_inform: option.warn }"
            >
                {{ option.text }}
            </option>
        </b-form-select>

        <b-form-input
            v-if="!existing"
            v-model="productNaam"
            readonly
        ></b-form-input>

        <b-form-input
            id="input-hoeveelheid"
            :placeholder="$t('com_amount', { ns: 'common' })"
            type="number"
            step="0.1"
            v-model.number="localSelected.hoeveelheid"
            @change="set_input"
            :disabled="!editAllowed"
        ></b-form-input>

        <lookup-combo
            v-model="localSelected.eenheid"
            v-bind:combotype="lctypeUnits"
            v-bind:discrim="baseUnitString"
            @change="set_input"
            :disabled="!editAllowed"
        ></lookup-combo>
    </div>
</template>
<script>
import { RecIngredient } from '../../models/Recept';
import { loadItem } from '../../models/DataHelper';
import { LookupCombo } from '.';
export default {
    name: 'IngredientCombo',
    data: function () {
        return {
            lctypeUnits: window.constants.LCPERUNITS,
            errorMessage: '',
            flexList: [],
            findCount: 0,
            aangeraakt: 1,
            isInList: true,
            productNaam: ''
        };
    },
    model: {
        prop: 'selected',
        event: 'input'
    },
    props: {
        selected: {
            type: RecIngredient,
            required: true
        },
        editAllowed: {
            type: Boolean,
            default: false
        },
        baseUnit: {
            type: Number,
            default: -1
        }
    },
    components: {
        LookupCombo
    },
    computed: {
        localSelected() {
            return this.selected;
        },
        productList() {
            const tempList = this.flexList.map((x) => ({
                value: x.id,
                text: x.naam,
                warn: x.isstartersubtype == undefined ? false : true
            }));
            return tempList;
        },
        baseUnitString() {
            // eslint-disable-next-line no-unused-vars
            const tel = this.aangeraakt;
            const baseu = this.localSelected.eenheid ?? this.baseUnit;
            return baseu > -1 ? baseu.toString() : '';
        },
        existing() {
            return this.isInList;
        },
        showInform() {
            // eslint-disable-next-line no-unused-vars
            const tel = this.aangeraakt;
            return this.localSelected.heeftSubType != undefined;
        }
    },
    methods: {
        set_input: function () {
            this.aangeraakt += 1;
            this.$emit('change', true);
            this.$emit('input', this.localSelected);
        },
        async refreshLists() {
            //console.log(JSON.stringify(this.selected));
            this.flexList = this.$store.getters[
                'product/getIngredientsInfoFor'
            ](this.selected.ingredientCatID);
            if (this.selected.productID != undefined) {
                const item = this.flexList.find(
                    (x) => x.id == this.selected.productID
                );
                this.isInList = item;
                if (!this.isInList && this.findCount < 2) {
                    if (this.findCount == 0) {
                        const product = await loadItem(
                            'product/loadProduct',
                            {
                                productID: this.selected.productID
                            },
                            this.$t
                        );
                        this.productNaam = product.naam;
                        this.findCount += 1;
                    } 
                }
            }
            this.aangeraakt += 1;
        }
    },
    beforeUpdate() {
        this.refreshLists();
    },
    mounted() {
        this.refreshLists();
    },
    created() {}
};
</script>

<style scoped></style>

