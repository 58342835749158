<template>
    <b-modal
        id="modal-resetpass"
        size="lg"
        ref="modal"
        :title="this.formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
    >
        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
            {{ alertText }}
        </b-alert>

        <b-input-group class="mb-2">
            <b-input-group-prepend>
                <b-button disabled
                    ><font-awesome-icon icon="envelope"
                /></b-button>
            </b-input-group-prepend>
            <b-form-input
                id="input-mail"
                :placeholder="
                    $t('com_mailorinlog', {
                        ns: 'common'
                    })
                "
                v-model="lokaalmail"
            ></b-form-input>
        </b-input-group>
    </b-modal>
</template>

<script>
import { loadItem } from '@/models/DataHelper';
export default {
    name: 'ResetPassModal',
    data: function () {
        return {
            showDismissibleAlert: false,
            alertText: '',
            lokaalmail: ''
        };
    },
    model: {
        prop: 'visible',
        event: 'hide'
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    watch: {},
    computed: {
        formtitle() {
            return this.$t('com_change', {
                ns: 'common',
                val: this.$t('com_ww', { ns: 'common' })
            });
        }
    },
    methods: {
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        hide() {
            this.$emit('hide');
        },
        checkFormValidity() {
            return true;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return;
            }
            this.doReset();
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-resetpass');
            });
        },
        async doReset() {
            loadItem(
                'gebruiker/resetPassword',
                { emailOrUserName: this.lokaalmail },
                this.$t
            );
        }
    },
    mounted() {},
    created() {}
};
</script>

<style scoped></style>

