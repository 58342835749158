<template>
    <b-card no-body :header="$t('prod_apparaten', { ns: 'production' })">
        <div>
            <b-card>
                <b-row>
                    <b-col sm="3">
                        <label for="input-itemnaam"
                            >{{ $t('com_naam', { ns: 'common' }) }}:</label
                        >
                    </b-col>
                    <b-col sm="9">
                        <b-form-input
                            id="input-itemnaam"
                            :placeholder="$t('com_naam', { ns: 'common' })"
                            v-model="naam"
                            :disabled="readonly"
                            @change="setChangedNaam"
                            :state="naam != undefined && naam.length > 2"
                            autofocus
                        ></b-form-input>
                    </b-col>

                    <b-col sm="3">
                        <label
                            >{{ $t('com_opmerking', { ns: 'common' }) }}:</label
                        >
                    </b-col>
                    <b-col sm="9">
                        <b-form-textarea
                            v-model="opmerking"
                            :placeholder="$t('com_opmerking', { ns: 'common' })"
                            rows="3"
                            max-rows="6"
                            @change="setChangedOpm"
                        ></b-form-textarea>
                    </b-col>
                </b-row>
                <analytics-display
                    v-if="isVersion30 && eo!=undefined"
                    :eo="eo"
                    :forceRefresh="forceRefresh"
                    :landscape="landscape"
                />
            </b-card>
        </div>
        <modal-entity-object
            v-if="detailsVisible"
            :inItem="eo"
            v-model="detailsVisible"
            v-bind:typeName="theEquipment.tableName"
            v-bind:itemName="theEquipment.naam"
            v-bind:keyNummeric="theEquipment.id"
            @hide="reset"
        />
    </b-card>
</template>

<script>
import { Equipment } from '../../models/MasterData';
import { loadItem, loadList } from '@/models/DataHelper';
import { Permission } from '../../models/Permissions';
import ModalEntityObject from '../analytics/ModalEntityObject.vue';
import AnalyticsDisplay from './AnalyticsDisplay.vue';

export default {
    name: 'CardEquipment',
    data() {
        return {
            heightBC: 50,
            widthBC: 2,
            opmerking: null,
            naam: '',
            nodisplay: this.$t('com_nodisplaybarcode', { ns: 'common' }),
            flexList: [],
            detailsVisible: false,
            forceRefresh: false,
            landscape: true,
            eo: null
        };
    },
    components: {
        AnalyticsDisplay,
        ModalEntityObject
    },
    model: {
        prop: 'theEquipment',
        event: 'changed'
    },
    props: {
        theEquipment: {
            type: Equipment,
            default: null
        },
        editAllowed: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        readonly() {
            return !this.editAllowed;
        },
        hasBarcode() {
            return this.eo != undefined;
        },
        isVersion30() {
            return (
                this.$store.getters['masterdata/getPackageVersion'] >=
                window.constants.VERSION30
            );
        }
    },
    methods: {
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        },
        setChangedNaam: function () {
            this.theEquipment.naam = this.naam;
            this.setChanged();
        },
        setChangedOpm: function () {
            this.theEquipment.opmerking = this.opmerking;
            this.setChanged();
        },
        setChanged: function () {
            this.$emit('validComponent', this.theEquipment.naam != undefined);
            this.$emit('change', true);
        }
    },
    mounted() {
        this.naam = this.theEquipment.naam;
        this.opmerking = this.theEquipment.opmerking;
    },
    async created() {
        this.flexList = await loadList(
            'masterdata/getStockLocationTypesLijst',
            'masterdata/loadStockLocationTypes',
            null,
            true,
            this.$t
        );
        if (this.isVersion30) {
            await loadItem(
                'analytics/loadEntityObjectByTypename',
                {
                    typename: this.theEquipment.tableName
                },
                this.$t
            );
            this.eo = this.$store.getters['analytics/getEntityObjectByTypeID'](
                this.theEquipment.id
            );
        }
    }
};
</script>
<style scoped>
</style>

