<template>
    <div>
        <b-modal
            id="modal-itemBakInfo"
            size="xl"
            ref="modal"
            :title="this.formtitle"
            :visible="visible"
            @hidden="hide"
            @ok="handleOk"
        >
            <b-alert
                v-model="showDismissibleAlert"
                variant="danger"
                dismissible
            >
                {{ alertText }}
            </b-alert>
            <card-melk
                v-if="biMelk === displayNumber"
                @submit.stop.prevent="handleSubmit"
                v-model="localMelk"
                v-bind:editAllowed="editAllowed"
                @change="setChanged"
                @validComponent="validCardMelk"
            >
            </card-melk>
            <card-strem
                v-if="biStrem === displayNumber"
                @submit.stop.prevent="handleSubmit"
                v-model="localStrem"
                v-bind:editAllowed="editAllowed"
                @change="setChanged"
                @validComponent="validCardStrem"
            >
            </card-strem>
            <card-www
                v-if="biWWW === displayNumber"
                @submit.stop.prevent="handleSubmit"
                v-model="localWWW"
                v-bind:editAllowed="editAllowed"
                @change="setChanged"
                @validComponent="validCardWaswater"
            >
            </card-www>
            <card-merk-pers
                v-if="biMerkPers === displayNumber"
                @submit.stop.prevent="handleSubmit"
                v-model="localMerkPers"
                v-bind:editAllowed="editAllowed"
                @change="setChanged"
                @validComponent="validCardMelkPers"
                @saveSubseries="setSaveSubseries"
            >
            </card-merk-pers>
            <card-overpomp
                v-if="biOverpomp === displayNumber"
                @submit.stop.prevent="handleSubmit"
                v-model="localOverpomp"
                v-bind:editAllowed="editAllowed"
                @change="setChanged"
                @validComponent="validCardOverpomp"
                @validAlternatives="validAlternatives"
            >
            </card-overpomp>
            <card-inpekel
                v-if="biInpekel === displayNumber"
                @submit.stop.prevent="handleSubmit"
                v-model="localInpekel"
                v-bind:editAllowed="editAllowed"
                @change="setChanged"
                @validComponent="validCardInpekel"
            >
            </card-inpekel>
            <b-card :header="$t('com_opmerking', { ns: 'common' })">
                <b-col sm="9" class="mx-0 px-0 my-0 py-0">
                    <b-col sm="3" class="mx-0 px-0 my-0 py-0">
                        <label
                            >{{ $t('prod_verantw', { ns: 'production' }) }}:
                        </label>
                    </b-col>
                    <b-col sm="9" class="mx-0 px-0 my-0 py-0">
                        <b-form-input
                            id="dropdown-form-responsibel"
                            :placeholder="
                                $t('prod_verantw', { ns: 'production' })
                            "
                            v-model="selectedPerson"
                            :disabled="!editAllowed"
                            @change="setPersonChanged"
                            size="sm"
                        ></b-form-input>
                    </b-col>
                </b-col>
                <b-col sm="3" class="mx-0 px-0 my-0 py-0"> </b-col>
                <b-form-textarea
                    id="bakopmerking"
                    v-model="bakOpmerking"
                    :placeholder="$t('com_opmerking', { ns: 'common' })"
                    rows="3"
                    max-rows="6"
                    :disabled="!editAllowed"
                    @change="setRemarkChanged"
                ></b-form-textarea>
            </b-card>
        </b-modal>
        <maintenance-pop-form
            v-if="detailsVisible"
            :displayNumber="selectedRadio"
            :ostID="ostID"
            v-model="detailsVisible"
            v-bind:editAllowed="true"
            @hide="reset"
        />
    </div>
</template>

<script>
import { Bak } from '../../models/BakInfo';
import { NextStepTimer } from '../../models/BakInfo_helper';
import DateHelper from '../../models/DateHelper';
import { loadItem, loadList } from '../../models/DataHelper';
import { RecProduction } from '../../models/Recept';
import Helper from '../../models/Helper';
import { loadSubObject } from './productionservice';
import CardMelk from './CardMelk';
import CardStrem from './CardStrem';
import CardWww from './CardWww';
import CardOverpomp from './CardOverpomp';
import CardInpekel from './CardInpekel';
import CardMerkPers from './CardMerkPers';
import { MaintenancePopForm } from '../maintenance';
import { Feature } from '../../models/Features';
import logMessage from '../errors/foutlog';

export default {
    name: 'ProductionPopForm',
    data() {
        return {
            showDismissibleAlert: false,
            alertText: '',
            readonly: false,
            isChanged: false,
            biMelk: window.constants.BI_SHOW_MELK,
            biStrem: window.constants.BI_SHOW_STREM,
            biWWW: window.constants.BI_SHOW_WWW,
            biOverpomp: window.constants.BI_SHOW_OVERPOMP,
            biInpekel: window.constants.BI_SHOW_INPEKEL,
            biMerkPers: window.constants.BI_SHOW_MERKEN,
            biIngredienten: window.constants.BI_SHOW_INGREDIENT,
            objectMelk: null,
            objectStrem: null,
            objectWWW: null,
            objectOverpomp: null,
            objectInpekel: null,
            objectMerkPers: null,
            melkValid: true,
            stremValid: true,
            wwwValid: true,
            overpompValid: true,
            inpekelValid: true,
            merkPersValid: true,
            alternativesValid: true,
            bakOpmerking: '',
            selectedPerson: '',
            opmerkingChanged: false,
            personChanged: false,
            merkSaveSubseries: false,
            ostID: -1,

            selectedRadio: 'ost',
            detailsVisible: false
        };
    },
    components: {
        CardStrem,
        CardMelk,
        CardWww,
        CardOverpomp,
        CardInpekel,
        CardMerkPers,
        MaintenancePopForm
    },
    model: {
        prop: 'visible',
        event: 'change'
    },
    props: {
        bak: {
            type: Bak,
            required: true
        },
        prodRecept: {
            type: RecProduction,
            required: true
        },
        displayNumber: {
            type: Number,
            required: true
        },
        visible: {
            type: Boolean,
            default: false
        },
        isLastBatch: {
            type: Boolean,
            default: false
        },
        editAllowed: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        formtitle() {
            return Helper.stringformat(
                '{0} {1} {2}',
                this.$t('prod_prodgegvoor', { ns: 'production' }),
                this.bak
                    ? this.$t('prod_bak', { ns: 'production' }).toLowerCase()
                    : 'ID',
                this.bak ? this.bak.bakno : this.bakID
            );
        },
        localBak() {
            return this.bak;
        },
        showCard(nummer) {
            return nummer === this.displayNumber;
        },
        localMelk() {
            return this.objectMelk;
        },
        localStrem() {
            return this.objectStrem;
        },
        localWWW() {
            return this.objectWWW;
        },
        localOverpomp() {
            return this.objectOverpomp;
        },
        localMerkPers() {
            return this.objectMerkPers;
        },
        localInpekel() {
            return this.objectInpekel;
        },
        localCheck() {
            return this.doCheckParent;
        }
    },
    methods: {
        reset() {
            this.detailsVisible = false;
            this.$parent.$emit('production_refresh');
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-itemBakInfo');
            });
        },
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        hide() {
            this.opmerking = '';
            this.$emit('hide');
        },
        validCardMelk(valid) {
            this.melkValid = valid;
        },
        validCardStrem(valid) {
            this.stremValid = valid;
        },
        validCardWaswater(valid) {
            this.wwwValid = valid;
        },
        validCardOverpomp(valid) {
            this.overpompValid = valid;
        },
        validAlternatives(valid) {
            this.alternativesValid = valid;
        },
        validCardMelkPers(valid) {
            this.merkPersValid = valid;
        },
        validCardInpekel(valid) {
            this.inpekelValid = valid;
        },
        setSaveSubseries(save) {
            this.merkSaveSubseries = save;
        },
        set_input: function () {
            this.setChanged();
            // this.$emit('change', true);
            // this.$emit('input', this.localSelected);
        },
        setRemarkChanged() {
            this.opmerkingChanged = true;
            this.setChanged();
        },
        setPersonChanged() {
            this.personChanged = true;
            this.setChanged();
        },
        setChanged() {
            this.isChanged = true;
        },
        checkFormValidity() {
            let tekst = '';
            if (this.objectMelk)
                tekst += this.checkObjectValidity(this.objectMelk);
            if (this.objectStrem)
                tekst += this.checkObjectValidity(this.objectStrem);
            if (this.objectWWW)
                tekst += this.checkObjectValidity(this.objectWWW);
            if (this.objectOverpomp)
                tekst += this.checkObjectValidity(this.objectOverpomp);
            if (this.objectMerkPers)
                tekst += this.checkObjectValidity(this.objectMerkPers);
            if (this.objectInpekel)
                tekst += this.checkObjectValidity(this.objectInpekel);
            const valid = !tekst;
            return valid;
        },
        checkObjectValidity(ob) {
            const invalidTekst = ob.CheckValidity(this.$t);
            if (invalidTekst) this.showAlert(invalidTekst);
            return invalidTekst;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        async handleSubmit() {
            if (this.isChanged) {
                if (
                    !this.melkValid &&
                    !this.stremValid &&
                    !this.wwwValid &&
                    !this.overpompValid &&
                    !this.alternativesValid &&
                    !this.inpekelValid &&
                    !this.merkPersValid
                ) {
                    this.showAlert(
                        this.$t('com_ongeldigeinput', { ns: 'common' })
                    );
                    return;
                }
                // Exit when the form isn't valid
                if (!this.checkFormValidity()) {
                    return;
                }
                this.saveThisBak();
                // extra service

                if (
                    this.isLastBatch &&
                    this.displayNumber == window.constants.BI_SHOW_MERKEN
                ) {
                    if (this.checkFeature('conf_storeAskOstCleaning')) {
                        const doClean = await this.$root.showConfirmationBox(
                            this.$t('prod_asklastbakclean', {
                                ns: 'production'
                            })
                        );
                        if (doClean) {
                            this.detailsVisible = !this.detailsVisible;
                            return;
                        }
                    }
                }
            }
            // Hide the modal manually
            this.$nextTick(() => {
                this.$parent.$emit('production_refresh');
                this.$bvModal.hide('modal-itemBakInfo');
            });
        },
        checkFeature(feature) {
            const key = Feature.enum(feature);
            return this.$root.handleFeature(key);
        },
        async getBakIngredientItem(item) {
            return await loadItem(
                'production/loadBatchIngredient',
                {
                    batchingredientid: item.id
                },
                this.$t
            );
        },
        async getBakItem(item) {
            return await loadItem(
                'production/loadBatch',
                {
                    batchID: item.id
                },
                this.$t
            );
        },
        async deleteBakIngredientItem(item) {
            const refreshedItem = await this.getBakIngredientItem(item);
            const RemoveBatchIngredientModel = refreshedItem.SetForDelete();
            loadItem(
                'production/deleteBatchIngredient',
                RemoveBatchIngredientModel,
                this.$t
            );
        },
        // eslint-disable-next-line no-unused-vars
        async deleteBIItem(item) {
            // todo delete uit alternateives
        },
        async refreshBakIngredientItem(item) {
            const refreshedItem = await this.getBakIngredientItem(item);
            if (item.gewijzigd < refreshedItem.gewijzigd) {
                this.showAlert(
                    Helper.stringformat(
                        this.$t(prod_ingrnosave, { ns: 'production' }),
                        this.$t(prod_ingredient, {
                            ns: 'production'
                        }).toLowerCase(),
                        item.naam
                    )
                );
                return;
            }
            refreshedItem.klaarzetten = item.klaarzetten;
            refreshedItem.hoeveelheid = item.hoeveelheid;
            refreshedItem.eenheidID = item.eenheidID;
            refreshedItem.toegevoegdTijd = item.toegevoegdTijd;
            refreshedItem.alternatieven = item.alternatieven;
            if (item.partijID) refreshedItem.partijID = item.partijID;
            return refreshedItem.SetForAdd();
        },
        async handleBakIngredientenLijst(lijst, isMilk = false) {
            if (lijst == undefined) return false;
            // verandering 20230710:  lijst in zijn geheel aanbieden ivm timing
            // delete blijft per item, update en add als lijst
            const filtered = lijst.filter((x) => !x.nouse);
            const toDelete = filtered.filter((x) => x.isDeleted);
            const remainder = filtered.filter((x) => !x.isDeleted);
            // handel delete
            if (toDelete.length > 0) {
                toDelete.forEach((x) => this.deleteBakIngredientItem(x));
            }
            if (remainder.length < 1) return false;
            // handel rest, partijen zonder partijid doen we niet
            const remainderClear = remainder.filter((x) => {
                // als nieuw dan moet partij id
                const nieuwCompleet = x.isNew
                    ? x.isNew && x.partijID != undefined
                    : true;
                if (x.isChanged && nieuwCompleet) return x;
            });
            const refreshedList = remainderClear.map(async (x) => {
                const res = x.isNew
                    ? await Promise.resolve(x.SetForAdd())
                    : await this.refreshBakIngredientItem(x);
                return res;
            });
            const func = this.$t;
            Promise.all(refreshedList).then((x) => {
                loadItem('production/addBatchingredients', x, func);
            });

            return true;
        },
        async handleBILijstAlt(lijst) {
            if (lijst == undefined) return;
            lijst.forEach((item) => {
                if (item.nouse) {
                    // doe niets
                } else {
                    if (item.isDeleted) {
                        this.deleteBIItem(item);
                    }
                }
            });
        },
        saveLijsten() {
            switch (this.displayNumber) {
                case window.constants.BI_SHOW_MELK: {
                    let milklist = [];
                    for (
                        let i = 0;
                        i < this.objectMelk.bakIngredientenMelk.length;
                        i++
                    ) {
                        // if (i == 0) {
                        //     const tijd = this.prodRecept.tijdVulWB
                        //         ? this.objectMelk.prodcalcFactor *
                        //           this.prodRecept.tijdVulWB
                        //         : -1;
                        //     if (tijd > -1) {
                        //         const start =
                        //             this.objectMelk.bakIngredientenMelk[i]
                        //                 .bakIngredienten[i].toegevoegdTijd ??
                        //             new Date();
                        //         this.prepareTimer(start, tijd);
                        //     }
                        // }
                        milklist = milklist.concat(
                            this.objectMelk.bakIngredientenMelk[i]
                                .bakIngredienten
                        );
                        // if (this.objectMelk.timerIngredient==this.objectMelk.bakIngredientenMelk[i].ingredientCategorieNaam) {
                        // }
                    }
                    this.handleBakIngredientenLijst(milklist, true);
                    for (
                        let i = 0;
                        i < this.objectMelk.bakIngredientenZuursel.length;
                        i++
                    ) {
                        this.handleBakIngredientenLijst(
                            this.objectMelk.bakIngredientenZuursel[i]
                                .bakIngredienten
                        );
                    }
                    for (
                        let i = 0;
                        i < this.objectMelk.bakIngredientenKleursel.length;
                        i++
                    ) {
                        this.handleBakIngredientenLijst(
                            this.objectMelk.bakIngredientenKleursel[i]
                                .bakIngredienten
                        );
                    }
                    for (
                        let i = 0;
                        i < this.objectMelk.bakIngredientenCalcium.length;
                        i++
                    ) {
                        this.handleBakIngredientenLijst(
                            this.objectMelk.bakIngredientenCalcium[i]
                                .bakIngredienten
                        );
                    }
                    for (
                        let i = 0;
                        i <
                        this.objectMelk.bakIngredientenConserveermiddel.length;
                        i++
                    ) {
                        this.handleBakIngredientenLijst(
                            this.objectMelk.bakIngredientenConserveermiddel[i]
                                .bakIngredienten
                        );
                    }
                    break;
                }
                case window.constants.BI_SHOW_STREM: {
                    for (
                        let i = 0;
                        i < this.objectStrem.bakIngredientenCalcium.length;
                        i++
                    ) {
                        this.handleBakIngredientenLijst(
                            this.objectStrem.bakIngredientenCalcium[i]
                                .bakIngredienten
                        );
                    }
                    for (
                        let i = 0;
                        i < this.objectStrem.bakIngredientenStremsel.length;
                        i++
                    ) {
                        if (i == 0) {
                            const tijd = this.prodRecept.tijdStremmen ?? -1;
                            if (tijd > -1) {
                                const start =
                                    this.objectStrem.bakIngredientenStremsel[i]
                                        .bakIngredienten[i].toegevoegdTijd ??
                                    new Date();
                                this.prepareTimer(start, tijd);
                            }
                        }
                        this.handleBakIngredientenLijst(
                            this.objectStrem.bakIngredientenStremsel[i]
                                .bakIngredienten
                        );
                    }
                    break;
                }
                case window.constants.BI_SHOW_WWW: {
                    for (
                        let i = 0;
                        i < this.objectWWW.bakIngredientenKruiden.length;
                        i++
                    ) {
                        this.handleBakIngredientenLijst(
                            this.objectWWW.bakIngredientenKruiden[i]
                                .bakIngredienten
                        );
                    }
                    for (
                        let i = 0;
                        i < this.objectWWW.bakIngredientenAromas.length;
                        i++
                    ) {
                        this.handleBakIngredientenLijst(
                            this.objectWWW.bakIngredientenAromas[i]
                                .bakIngredienten
                        );
                    }
                    for (
                        let i = 0;
                        i <
                        this.objectWWW.bakIngredientenConserveermiddel.length;
                        i++
                    ) {
                        this.handleBakIngredientenLijst(
                            this.objectWWW.bakIngredientenConserveermiddel[i]
                                .bakIngredienten
                        );
                    }
                    break;
                }
                case window.constants.BI_SHOW_OVERPOMP: {
                    for (
                        let i = 0;
                        i < this.objectOverpomp.bakIngredientenZout.length;
                        i++
                    ) {
                        this.handleBakIngredientenLijst(
                            this.objectOverpomp.bakIngredientenZout[i]
                                .bakIngredienten
                        );
                    }
                    for (
                        let i = 0;
                        i <
                        this.objectOverpomp.bakIngredientenAlternatief.length;
                        i++
                    ) {
                        this.handleBakIngredientenLijst(
                            this.objectOverpomp.bakIngredientenAlternatief[i]
                                .bakIngredienten
                        );
                        // this.handleBILijstAlt(
                        //     this.objectOverpomp.bakIngredientenAlternatief[i]
                        // );
                    }
                    break;
                }
                case window.constants.BI_SHOW_INPEKEL:
                case window.constants.BI_SHOW_MERKEN:
                case window.constants.BI_SHOW_INGREDIENT:
                default:
                    break;
            }
        },
        async saveThisBak() {
            // zou niet nodig moeten zijn omdat we bij openen scherm bak al geupdated hebben
            const refreshedItem = await this.getBakItem(this.bak);
            this.ostID = refreshedItem.wbId;
            if (this.bak.gewijzigd < refreshedItem.gewijzigd) {
                this.showAlert(
                    Helper.stringformat(
                        this.$t(prod_ingrnosave, { ns: 'production' }),
                        this.$t(prod_bak, { ns: 'production' }).toLowerCase(),
                        this.bak.nummer
                    )
                );
                return false;
            }
            this;
            let UpdateBatchModel = null;
            let isMilk = false;
            switch (this.displayNumber) {
                case window.constants.BI_SHOW_MELK: {
                    UpdateBatchModel = this.objectMelk.SetForUpdate();
                    this.bak.pastTemp = this.objectMelk.pastTemp;
                    this.bak.wbId = this.objectMelk.ostID;
                    this.bak.wbProgram = this.objectMelk.WBprogram;
                    isMilk = true;
                    //todo zuursel en strem
                    break;
                }
                case window.constants.BI_SHOW_STREM: {
                    UpdateBatchModel = this.objectStrem.SetForUpdate();
                    this.bak.stremTemp = this.objectStrem.stremTemp;
                    this.bak.stremTijdstip = this.objectStrem.stremTijdstip;
                    break;
                }
                case window.constants.BI_SHOW_WWW: {
                    UpdateBatchModel = this.objectWWW.SetForUpdate();
                    this.bak.nawarmTemp = this.objectWWW.NawarmTemp;
                    this.bak.wei1Kilos = this.objectWWW.Wei1;
                    this.bak.wasWater = this.objectWWW.Waswater;

                    const tijd = this.prodRecept.tijdWassen1 ?? -1;
                    if (tijd > -1) {
                        const start = new Date();
                        this.prepareTimer(start, tijd);
                    }
                    break;
                }
                case window.constants.BI_SHOW_OVERPOMP: {
                    UpdateBatchModel = this.objectOverpomp.SetForUpdate();
                    this.bak.overpompTijdstip =
                        this.objectOverpomp.overpompTijdstip;
                    this.bak.drainProgram = this.objectOverpomp.Drainprogram;

                    const tijd = this.prodRecept.tijdDrainenWei1 ?? -1;
                    if (tijd > -1) {
                        const start =
                            this.objectOverpomp.overpompTijdstip ?? new Date();
                        this.prepareTimer(start, tijd);
                    }
                    break;
                }
                case window.constants.BI_SHOW_MERKEN: {
                    // todo save merken alleen als gewijzigd of nieuw
                    UpdateBatchModel = this.objectMerkPers.SetForUpdate();
                    this.bak.pressProgram = this.objectMerkPers.Persprogram;
                    const batchCheeseMarksModel =
                        this.objectMerkPers.SetForUpdateCheeseMark(
                            this.merkSaveSubseries
                        );
                    if (batchCheeseMarksModel != undefined) {
                        await loadItem(
                            'production/updateBatchMarks',
                            batchCheeseMarksModel,
                            this.$t
                        );
                    }
                    const tijd = this.prodRecept.tijdPersen ?? -1;
                    if (tijd > -1) {
                        const start = new Date();
                        this.prepareTimer(start, tijd);
                    }
                    break;
                }
                case window.constants.BI_SHOW_INPEKEL: {
                    UpdateBatchModel = this.objectInpekel.SetForBatchUpdate();
                    const inbrineModel = this.objectInpekel.SetForUpdate();
                    await loadItem(
                        'production/updateBatchInBrine',
                        inbrineModel,
                        this.$t
                    );
                    break;
                }
                case window.constants.BI_SHOW_INGREDIENT:
                default:
                    break;
            }
            if (this.opmerkingChanged) {
                UpdateBatchModel.Remark = this.bakOpmerking;
                this.bak.opmerking = this.bakOpmerking;
            }
            if (this.personChanged) {
                UpdateBatchModel.ResponsiblePerson = this.selectedPerson;
                this.bak.verantwoordelijke = this.selectedPerson;
            }
            if (UpdateBatchModel) {
                const res = await loadItem(
                    'production/updateBatch',
                    UpdateBatchModel,
                    this.$t
                );
            }
            this.saveLijsten();
            return true;
        },
        prepareTimer(starttijd, minuten) {
            if (minuten > -1 && starttijd != undefined) {
                const start = DateHelper.convertedToDateTimeString(starttijd);
                const nextStep = new NextStepTimer();
                nextStep.bakID = this.bak.id;
                nextStep.bakNummer = this.bak.bakno;
                nextStep.alarmTime = new Date(
                    start.getTime() + minuten * 60000
                );
                this.setTimer(nextStep);
            }
        },
        setTimer(nextStep) {
            loadItem('production/saveBakTimer', nextStep, this.$t);
        }
    },
    mounted() {
        if (this.bak) {
            this.bakOpmerking = this.bak.opmerking;
            this.selectedPerson = this.bak.verantwoordelijke;
            switch (this.displayNumber) {
                case window.constants.BI_SHOW_MELK: {
                    this.objectMelk = loadSubObject(
                        this.bak,
                        this.displayNumber,
                        this.$t
                    );
                    break;
                }
                case window.constants.BI_SHOW_STREM: {
                    this.objectStrem = loadSubObject(
                        this.bak,
                        this.displayNumber,
                        this.$t
                    );
                    break;
                }
                case window.constants.BI_SHOW_WWW: {
                    this.objectWWW = loadSubObject(
                        this.bak,
                        this.displayNumber,
                        this.$t
                    );
                    break;
                }
                case window.constants.BI_SHOW_OVERPOMP: {
                    this.objectOverpomp = loadSubObject(
                        this.bak,
                        this.displayNumber,
                        this.$t
                    );
                    break;
                }
                case window.constants.BI_SHOW_INPEKEL: {
                    this.objectInpekel = loadSubObject(
                        this.bak,
                        this.displayNumber,
                        this.$t
                    );
                    break;
                }
                case window.constants.BI_SHOW_MERKEN: {
                    this.objectMerkPers = loadSubObject(
                        this.bak,
                        this.displayNumber,
                        this.$t
                    );
                    break;
                }
                case window.constants.BI_SHOW_INGREDIENT:
                default:
                    break;
            }
        }
    }
};
</script>

