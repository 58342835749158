<template>
    <div>
        <b-card
            no-body
            :header="
                $t('com_change', {
                    ns: 'common',
                    val: $t('com_ww', { ns: 'common' })
                })
            "
            class="mb-1"
        >
            <b-row>
                <b-col sm="3" />
                <b-col sm="6">
                    <b-alert
                        v-model="showDismissibleAlert"
                        variant="danger"
                        dismissible
                    >
                        {{ alertText }}
                    </b-alert>

                    <b-form-group
                        :label="
                            $t('com_newx', {
                                ns: 'common',
                                val: $t('com_ww', { ns: 'common' })
                            })
                        "
                        label-for="pass1"
                        :description="$t('com_wweis', { ns: 'common' })"
                    >
                        <b-form-input
                            id="pass1"
                            v-model="pass1"
                            type="password"
                            :state="pass1.length > 7"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col sm="3" />
            </b-row>
            <b-row>
                <b-col sm="3" />
                <b-col sm="6">
                    <b-form-group
                        inline
                        :label="
                            $t('com_repeat', {
                                ns: 'common',
                                val: $t('com_ww', { ns: 'common' })
                            })
                        "
                        label-for="pass2"
                    >
                        <b-form-input
                            id="pass2"
                            v-model="pass2"
                            type="password"
                            :state="pass2.length > 7 && pass2 == pass1"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-live-feedback">
                            {{ errorMessage1 }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
                <b-col sm="3" />
            </b-row>
            <div slot="footer" class="w-100">
                <b-button
                    class="float-right"
                    variant="success"
                    :disabled="!isValid"
                    @click="handleSubmit"
                    >OK</b-button
                >
            </div>
        </b-card>
    </div>
</template>

<script>
import { loadItem } from '../../models/DataHelper';
export default {
    name: 'ConfirmEmail',
    data: function () {
        return {
            errorMessage1: this.$t('com_wwgelijk', {
                ns: 'common'
            }),
            showDismissibleAlert: false,
            alertText: '',
            pass1: '',
            pass2: ''
        };
    },
    props: {
        userId: {
            type: String
        }
    },
    watch: {},
    computed: {
        formtitle() {
            return this.$t('com_change', {
                ns: 'common',
                val: this.$t('com_ww', { ns: 'common' })
            });
        },
        isValid: function () {
            const valid = this.pass2.length > 7 && this.pass2 == this.pass1;
            return valid;
        }
    },
    methods: {
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        checkFormValidity() {
            return this.isValid;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return;
            }
            this.doConfirmEmail();
        },
        async doConfirmEmail() {
            await loadItem(
                'gebruiker/confirmEmail',
                {
                    userid: this.$route.query.userId,
                    code: this.$route.query.code,
                    password: this.pass1
                },
                this.$t
            );
            this.$router.push('login');
        }
    },
    mounted() {},
    created() {}
};
</script>

<style scoped></style>

