import { api } from '../api';
import { showBadToast } from '../../errors/toast';
import logMessage from '../../errors/foutlog';


const actions = { 
    clearList(context, data) {
        context.commit('assignLijst', data.data);  
    },
    async loadTrackTrace(context, data) {
        let reportItemList = [];
        try {
            const response = await api.fetchTrackTrace(data.data);
            reportItemList = response.data.Items;
        } catch (error) {
            reportItemList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_reportdata', { ns: 'common' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('assignLijst', reportItemList);       
    }, 
    async loadTrackTraceMerk(context, data) {
        let reportItemList = [];
        try {
            const response = await api.fetchTrackTraceMerk(data.data);
            reportItemList = response.data.Items;
        } catch (error) {
            reportItemList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_reportdata', { ns: 'common' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('assignLijst', reportItemList);  
    }, 
    async loadTrackTraceMerk10plus(context, data) {
        let reportItemList = [];
        try {
            const response = await api.fetchTrackTraceMerk10plus(data.data);
            reportItemList = response.data.Items;
        } catch (error) {
            reportItemList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_reportdata', { ns: 'common' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('assignLijst', reportItemList);  
        return reportItemList;
    }, 
    
    async loadTrackTraceLot(context, data) {
        let reportItemList = [];
        try {
            const response = await api.fetchTrackTraceLot(data.data);
            reportItemList = response.data.Items;
        } catch (error) {
            reportItemList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_reportdata', { ns: 'common' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('assignLijst', reportItemList);  
    },
    async loadReport(context, data) {
        let reportItemList = [];
        try {
            const response = await api.fetchInfo(data.data.report, data.data.data);
            reportItemList = response.data.Items;
        } catch (error) {
            reportItemList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_reportdata', { ns: 'common' })
                }),
                error
            );
            logMessage(error, 'error');
            
        }
        context.commit('assignLijst', reportItemList);  
    },
    // functies
    async loadFunction(context, data) {
        let reportItemList = [];
        try {
            const response = await api.fetchInfo(data.data.report, data.data.data);
            reportItemList = response.data.Items ?? response.data;
        } catch (error) {
            reportItemList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_reportdata', { ns: 'common' })
                }),
                error
            );
            logMessage(error, 'error');
            
        }
        return reportItemList;
    }

    
};

export default actions;
