const getters = {
    getTemplateLijst: (state) => {
        return state.templateList;
    },   
    getTemplateByID: state => (id) => {
        return state.templateList.find(x => x.templateid == id);
    },   
    getTemplateByDescription: state => (description) => {
        return state.templateList.find(x => x.templatenaam.toLowerCase() === description.toLowerCase());
    },
    getQuantityLijst: state =>  {
        return state.quantityList;
    }, 
    getQunitLijst: state =>  {
        return state.qunitList;
    }, 
    getQualityLijst: state =>  {
        return state.qualityList;
    }, 
    getQlabelLijst: state =>  {
        return state.qlabelList;
    },     
    getEOTypeNamesLijst: state =>  {
        return state.eoTypeNamesList;
    }, 
    getEntityObjectLijst: state =>  {
        return state.entityObjectList;
    }, 
    getEntityObjectByTypeID: state => (typeid) => {
        return typeid ? state.entityObjectList.find(x => x.veldid == typeid) : null;
    }, 
    getIQLijst: state =>  {
        return state.iqList;
    }, 
    getMaesurableIQLijst: state =>  {
        return state.miqList;
    }, 
    getMaesurableIQByID: state => (id) => {
        return state.miqList.find(x => x.id == id);
    }, 
    getExecutorLijst: state =>  {
        return state.executorList;
    }, 
    getExecutorQuantityLijst: state =>  {
        return state.executorQuantityList;
    }, 
    getExecutorQualityLijst: state =>  {
        return state.executorQualityList;
    },
    getMaesurementLijst: state =>  {
        return state.maesurementList;
    }, 
    getAssesmentLijst: state =>  {
        return state.assesmentList;
    }, 
    getNormLijst: state =>  {
        return state.normList;
    }, 
    getMonsterLijst: state =>  {
        return state.sampleList;
    }, 
    getMonsterTypeLijst: state =>  {
        return state.sampleTypeList;
    },
    
    
    
};

export default getters;

