<template>
    <b-container fluid style="margin-top: 60px">
        <div class="form-inline">
            <h1 v-on:click="flipPrinted">
                {{ title }} &nbsp;
                <b-button>
                    {{ btnTekst }}
                </b-button>
            </h1>
            &nbsp;

            <b-button
                v-if="!geprint"
                @click="reloadData()"
                class="bgc_aux_normal"
                v-b-tooltip.hover
                :title="$t('com_showdata', { ns: 'common' })"
                ><font-awesome-icon icon="arrow-down" size="1x" />
            </b-button>
        </div>

        <div v-if="geprint">
            <b-row>
                <b-col sm="8">
                    <period-selector
                        v-model="selectDays"
                        v-bind:periodDays="periodeDagen"
                        @change="setNewDates"
                    />
                </b-col>
                <b-col sm="3">
                    <b-row>
                        <b-col sm="11">
                            <b-button
                                @click="reloadData()"
                                class="bgc_aux_normal"
                                v-b-tooltip.hover
                                :title="$t('com_showdata', { ns: 'common' })"
                                ><font-awesome-icon
                                    icon="arrow-down"
                                    size="1x"
                                />
                            </b-button>
                        </b-col>
                        <b-col sm="1"> </b-col>
                    </b-row>
                </b-col>
                <b-col sm="1">
                    <b-row class="float-right">
                        <b-col sm="11"> </b-col>
                        <b-col sm="1"> </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </div>
        <b-row>
            <b-col sm="12">
                <div v-if="showFilter">
                    <b-row no-gutters>
                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_filter', { ns: 'common' })"
                                label-for="filter-input"
                                label-cols-sm="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-input-group size="sm">
                                    <b-form-input
                                        id="filter-input"
                                        v-model="filter"
                                        type="search"
                                        :placeholder="
                                            $t('com_typetosearch', {
                                                ns: 'common'
                                            })
                                        "
                                    ></b-form-input>

                                    <b-input-group-append>
                                        <b-button
                                            :disabled="!filter"
                                            @click="filter = ''"
                                            >{{
                                                $t('com_wis', { ns: 'common' })
                                            }}</b-button
                                        >
                                    </b-input-group-append>
                                </b-input-group>

                                <b-input-group size="sm">
                                    <b-button
                                        v-if="
                                            !geprint &&
                                            checkRight('DeliveryCreate')
                                        "
                                        size="sm"
                                        @click="createNewItem"
                                        class="mr-1"
                                        ><font-awesome-icon
                                            icon="folder-plus"
                                            size="1x"
                                        />
                                        {{
                                            $t('com_newx', {
                                                ns: 'common',
                                                val: this.$t('prod_pakbon', {
                                                    ns: 'production'
                                                })
                                            })
                                        }}
                                    </b-button>
                                </b-input-group>
                            </b-form-group>
                        </b-col>

                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_perpagina', { ns: 'common' })"
                                label-for="per-page-select"
                                label-cols-sm="6"
                                label-cols-md="4"
                                label-cols-lg="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-form-select
                                    id="per-page-select"
                                    v-model="perPage"
                                    :options="pageOptions"
                                    size="sm"
                                ></b-form-select>
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                ></b-pagination>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div v-else>
                    <b-input-group>
                        <b-button
                            v-if="!geprint && checkRight('DeliveryCreate')"
                            size="sm"
                            @click="createNewItem"
                            class="mr-1"
                            ><font-awesome-icon icon="folder-plus" size="1x" />
                            {{
                                $t('com_newx', {
                                    ns: 'common',
                                    val: this.$t('prod_pakbon', {
                                        ns: 'production'
                                    })
                                })
                            }}
                        </b-button>
                    </b-input-group>
                </div>

                <b-table
                    :items="groupedDisplayList"
                    :fields="visibleFields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    stacked="md"
                    show-empty
                    :empty-text="$t('com_geen_data', { ns: 'common' })"
                    small
                    striped
                    @filtered="onFiltered"
                    @row-clicked="toonItems"
                >
                    <template v-slot:cell(actions)="row">
                        <b-button
                            v-if="checkRight('DeliveryUpdate')"
                            size="sm"
                            @click="editItem(row.item)"
                            class="bgc_aux_warning"
                            ><font-awesome-icon icon="edit" size="1x" />
                        </b-button>
                        <b-button
                            v-if="checkRight('PrintedNoteCreate')"
                            size="sm"
                            @click="printItem(row.item)"
                            class="bgc_auxzenze_gray"
                            ><font-awesome-icon icon="print" size="1x" />
                        </b-button>
                        <b-button
                            v-if="!geprint && checkRight('DeliveryDelete')"
                            size="sm"
                            @click="verwijderItem(row.item)"
                            class="bgc_aux_alarm"
                            ><font-awesome-icon icon="trash-alt" size="1x" />
                        </b-button>
                        <b-button
                            size="sm"
                            @click="toonItems(row.item)"
                            class="bgc_aux_unknown"
                            ><font-awesome-icon icon="info-circle" size="1x" />
                        </b-button>
                    </template>

                    <template v-slot:row-details>
                        <b-card no-body class="mb-1">
                            &nbsp;
                            <b-table
                                striped
                                responsive
                                :fields="visibleDetailFields"
                                :items="itemList"
                                primary-key
                            >
                            </b-table>
                        </b-card>
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <delivery-note-modal
            v-if="detailsVisible"
            :inID="deliveryNoteID"
            v-model="detailsVisible"
            v-bind:editAllowed="deliveryEditAllowed"
            @hide="reset"
        />
        <print-note-modal
            v-if="printVisible"
            :deliveryNoteID="deliveryNoteID"
            :printedNoteID="printedNoteID"
            v-model="printVisible"
            v-bind:editAllowed="printEditAllowed"
            v-bind:newPrintVersion="printNewVersion"
            @hide="reset"
            @printed="reloadData"
        />
    </b-container>
</template>

<script lang="ts">
import _ from 'lodash';
import DeliveryNoteModal from './DeliveryNoteModal.vue';
import PrintNoteModal from './PrintNoteModal.vue';
import { Permission } from '../../models/Permissions';
import { VueExtension } from '../../models/VueExtension';
import PeriodSelector from '../components/PeriodSelector.vue';
import { loadItem } from '../../models/DataHelper';
import { defineComponent } from 'vue';
import DateHelper from '@/models/DateHelper';
import { DeliveryNoteView } from '@/models/Delivery';
import Helper from '@/models/Helper';

export default defineComponent({
    name: 'DeliveryBase',
    data: function () {
        return {
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [10, 20, 40, { value: 100, text: '100' }],
            filter: null,
            filterOn: [],
            deliveryNoteID: -1,
            detailsVisible: false,
            printVisible: false,
            newPrintVersion: false,
            geprint: false,
            localItemslist: [],
            displayFilterCount: 20,
            itemNumber: null,
            showCsv: false,
            aangeraakt: 0,
            selectedItem: {} as DeliveryNoteView | null,

            van: null,
            tot: null,
            selectDays: null,
            periodeDagen: 170
        };
    },
    props: {},
    components: {
        DeliveryNoteModal,
        PrintNoteModal,
        PeriodSelector
    },
    computed: {
        title(): string {
            const s = this.geprint
                ? this.$t('com_printed', { ns: 'common' })
                : this.$t('com_open', { ns: 'common' });
            return Helper.stringformat(
                '{0} {1}',
                s,
                this.$t('prod_pakbonnen', { ns: 'production' }).toLowerCase()
            );
        },
        btnTekst(): string {
            const s = this.geprint
                ? this.$t('com_open', { ns: 'common' }).toLowerCase()
                : this.$t('com_printed', { ns: 'common' }).toLowerCase();
            return Helper.stringformat(
                '{0} {1}',
                this.$t('com_show', { ns: 'common' }),
                s
            );
        },
        showFilter(): boolean {
            return this.displayList
                ? this.displayList.length > this.displayFilterCount
                : false;
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                {
                    key: 'pakbonID',
                    label: this.$t('prod_pakbon', { ns: 'production' }) + '#',
                    sortable: false,
                    visible: true
                },
                {
                    key: 'versie',
                    label: this.$t('prod_version', { ns: 'production' }),
                    sortable: true,
                    visible: this.geprint
                },
                {
                    key: 'tijdstip',
                    label: this.$t('com_datum', { ns: 'common' }),
                    sortable: true,
                    visible: true,
                    formatter: 'displayDate'
                },
                {
                    key: 'referentie',
                    label: this.$t('com_reference', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'locatie',
                    label: this.$t('com_location', { ns: 'common' }),
                    sortable: true,
                    visible: !this.geprint
                },
                {
                    key: 'klant',
                    label: this.$t('com_klant', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'items.length',
                    label: this.$t('com_number', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'attcount',
                    label: this.$t('com_bijlagen', { ns: 'common' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        visibleDetailFields() {
            const fieldsInit = this.geprint
                ? [
                      {
                          key: 'regelinfocleared',
                          label: this.$t('prod_regeltekst', {
                              ns: 'production'
                          }),
                          sortable: true,
                          visible: true
                      },
                      {
                          key: 'aantal',
                          label: this.$t('com_number', { ns: 'common' }),
                          sortable: true,
                          visible: true
                      }
                  ]
                : [
                      {
                          key: 'partij',
                          label: this.$t('com_batch', { ns: 'common' }),
                          sortable: true,
                          visible: true
                      },
                      {
                          key: 'product',
                          label: this.$t('com_product', { ns: 'common' }),
                          sortable: true,
                          visible: true
                      },
                      {
                          key: 'aantal',
                          label: this.$t('com_number', { ns: 'common' }),
                          sortable: true,
                          visible: true
                      },
                      {
                          key: 'eenheid',
                          label: this.$t('com_unit', { ns: 'common' }),
                          sortable: true,
                          visible: true
                      },
                      {
                          key: 'vorm',
                          label: this.$t('prod_vorm', { ns: 'production' }),
                          sortable: true,
                          visible: true
                      }
                  ];
            return fieldsInit.filter((field) => field.visible);
        },
        displayList() {
            const a = this.aangeraakt;
            const tempList = this.geprint
                ? this.$store.getters['delivery/getDeliveryNotesPrinted']
                : this.$store.getters['delivery/getDeliveryNotes'];
            tempList.forEach((t) => {
                this.$set(t, '_showDetails', false);
            });
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.totalRows = tempList ? tempList.length : 0;
            return tempList;
        },
        groupedDisplayList() {
            const a = this.aangeraakt;
            const p = this.geprint;
            if (this.displayList == undefined) return null;
            const x = _(this.displayList.slice(0))
                .groupBy((x) => `"${x.pakbonID}+${x.versie}"`)
                .map((value, key) => {
                    return {
                        id: value[0].id,
                        pakbonID: value[0].pakbonID,
                        attcount: value[0].attcount,
                        opmerking: value[0].opmerking,
                        referentie: value[0].referentie,
                        klant: value[0].klant,
                        locatie: value[0].locatie,
                        versie: value[0].versie,
                        tijdstip: p
                            ? value[0].printtijdstip
                            : value[0].boektijdstip,
                        items: value
                    };
                })
                .value();
            return _.sortBy(x, ['boektijdstip', 'locatie']);
        },
        itemList() {
            return this.localItemslist;
        },
        printEditAllowed() {
            return !this.geprint || this.newPrintVersion;
        },
        deliveryEditAllowed() {
            return this.geprint ? false : true;
        },
        printNewVersion() {
            return this.newPrintVersion;
        },
        printedNoteID() {
            return this.geprint
                ? this.selectedItem
                    ? this.selectedItem.id
                    : -1
                : -1;
        }
    },
    methods: {
        flipPrinted() {
            this.geprint = !this.geprint;
            if (this.geprint) {
                this.$nextTick(() => {
                    this.reloadData();
                    this.displayList;
                });
            } else {
                this.displayList;
            }
        },
        createNewItem() {
            this.deliveryNoteID = -1;
            this.detailsVisible = true;
        },
        async printItem(item) {
            this.newPrintVersion = true;
            const pakbonID = this.geprint ? item.items[0].pakbonID : item.id;
            const bestaat = await loadItem(
                'delivery/existsPrintedNote',
                {
                    deliverynoteID: pakbonID
                },
                this.$t
            );
            if (bestaat) {
                if (this.geprint) {
                    const nieuweVersie = await (
                        this.$root as VueExtension
                    ).showConfirmationBox(
                        this.$t('prod_pakbonnieuweversie', { ns: 'production' })
                    );
                    this.newPrintVersion = nieuweVersie;
                } else {
                    // is al geprint door ander, melden en de geprinte weergeven
                    (this.$root as VueExtension).showMessageBox(
                        this.$t('prod_algeprint', { ns: 'production' })
                    );
                    return;
                }
            }
            this.selectedItem = item.items[0];
            this.deliveryNoteID = pakbonID;
            //this.printedNoteID = this.geprint ? item.id : -1;
            this.printVisible = true;
        },
        async editItem(item) {
            if (!this.geprint) {
                const bestaat = await loadItem(
                    'delivery/existsPrintedNote',
                    {
                        deliverynoteID: item.id
                    },
                    this.$t
                );
                if (bestaat) {
                    // is al geprint door ander, melden en de geprinte weergeven
                    (this.$root as VueExtension).showMessageBox(
                        this.$t('prod_algeprintrefresh', { ns: 'production' })
                    );
                    return;
                }
                this.deliveryNoteID = item ? parseInt(item.id) : -1;
            } else {
                // geprint dus de dn_id
                this.deliveryNoteID = item ? parseInt(item.pakbonID) : -1;

            }
            this.detailsVisible = true;
        },
        toonItems(row) {
            if (row._showDetails) {
                this.$set(row, '_showDetails', false);
            } else {
                this.groupedDisplayList.forEach((row) => {
                    this.$set(row, '_showDetails', false);
                });
                this.localItemslist = row.item ? row.item.items : row.items;
                this.$set(row, '_showDetails', true);
            }
        },
        async verwijderItem(item) {
            (this.$root as VueExtension)
                .showConfirmationBox(
                    this.$t('com_delete', {
                        ns: 'common',
                        val: this.$t('prod_pakbon', { ns: 'production' })
                    }) +
                        item.id +
                        ' : ' +
                        item.onzeRef
                )
                .then(async (res) => {
                    if (res == true) {
                        const refreshedItem = await loadItem(
                            'delivery/loadDeliveryNote',
                            {
                                deliverynoteID: item.id
                            },
                            this.$t
                        );
                        const selectionData = {
                            id: refreshedItem.id,
                            lastupdated: refreshedItem.gewijzigd
                        };
                        await loadItem(
                            'delivery/deleteDeliveryNote',
                            selectionData,
                            this.$t
                        );
                    }
                    this.reloadData();
                });
        },
        reset() {
            this.deliveryNoteID = -1;
            this.detailsVisible = false;
            this.printVisible = false;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        setNewDates(selDates) {
            this.van = selDates.van;
            this.tot = selDates.tot;
        },
        reloadData() {
            if (this.geprint) {
                const selectionData = {
                    from: DateHelper.localDateToUTC(this.van),
                    until: DateHelper.localDateToUTC(this.tot)
                };
                loadItem(
                    'delivery/loadDeliveryNotesPrinted',
                    selectionData,
                    this.$t
                );
            } else {
                loadItem('delivery/loadDeliveryNotes', null, this.$t);
            }
            this.aangeraakt += 1;
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return (this.$root as VueExtension).handleRight(id);
        },
        displayDate(value) {
            return DateHelper.convertedToDate(value, true);
        }
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.displayList ? this.displayList.length : 0;
    },
    created() {
        loadItem('delivery/loadDeliveryNotes', null, this.$t);
    }
});
</script>

<style scoped></style>

