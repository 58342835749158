<template>
    <div>{{ display }}</div>
</template>

<script>
export default {
    name: 'CheeseTimer',
    data() {
        return {
            timerOutput: null,
            counter: 0
        };
    },
    components: {},
    props: {
        countDownToTime: {
            type: Date
        },
        alarmText: {
            type: String
        }
    },
    computed: {
        display() {
            return this.timerOutput;
        }
    },
    methods: {
        startTimer() {
            if (this.countDownToTime) {
                const timeNow = new Date().getTime();
                const timeAlarm = new Date(this.countDownToTime).getTime();
                const timeDifference = timeAlarm - timeNow;
                const millisecondsInOneSecond = 1000;
                const millisecondsInOneMinute = millisecondsInOneSecond * 60;
                const millisecondsInOneHour = millisecondsInOneMinute * 60;
                const millisecondsInOneDay = millisecondsInOneHour * 24;
                //const differenceInDays = timeDifference / millisecondsInOneDay;
                const remainderDifferenceInHours =
                    (timeDifference % millisecondsInOneDay) /
                    millisecondsInOneHour;
                const remainderDifferenceInMinutes =
                    (timeDifference % millisecondsInOneHour) /
                    millisecondsInOneMinute;
                const remainderDifferenceInSeconds =
                    (timeDifference % millisecondsInOneMinute) /
                    millisecondsInOneSecond;
                //const remainingDays = Math.floor(differenceInDays);
                const remainingHours = Math.floor(remainderDifferenceInHours);
                let remainingMinutes = Math.floor(remainderDifferenceInMinutes);
                let remainingSeconds = Math.floor(remainderDifferenceInSeconds);
                if (remainingMinutes < 10)
                    remainingMinutes = '0' + remainingMinutes;
                if (remainingSeconds < 10)
                    remainingSeconds = '0' + remainingSeconds;
                if (timeDifference <= 0) {
                    const millisecondsInHalf = millisecondsInOneSecond * 31;  // 3 keer
                    if (millisecondsInHalf > Math.abs(timeDifference)) {
                        this.timeExpired();
                        this.counter+=1;
                        if (this.counter>2) this.reset();
                        this.timerOutput = this.alarmText;
                    } 
                } else {
                    this.timerOutput =
                        remainingHours +
                        ':' +
                        remainingMinutes +
                        ':' +
                        remainingSeconds;
                }
            } else {
                this.timerOutput = '--:--:--';
            }
        },
        timeExpired() {
            this.$emit('alarm', true);
        },
        reset() {
            this.$emit('reset', true);
        }
    },
    mounted() {
        setInterval(() => {
            this.startTimer();
        }, 10 * 1000); // iedere 10 seconden
    },
    created() {}
};
</script>
