<template>
    <b-modal
        id="modal-plusrecept"
        size="lg"
        ref="modal"
        :title="this.formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
        @change="change"
    >
        <b-card @submit.stop.prevent="handleSubmit" :header="strProduct">
            <b-tabs card>
                <b-tab :title="strHoofdProduct" v-bind:key="1" active>
                    <b-card-text>
                        <div>
                            <b-row>
                                <b-col sm="3">
                                    <label for="productnaam"
                                        >{{
                                            $t('com_product', { ns: 'common' })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <lookup-combo
                                        id="productnaam"
                                        v-model="localItem.id"
                                        v-bind:combotype="lctypeSimple"
                                        v-bind:discrimArray="theDiscrimList"
                                        :disabled="editProduct"
                                    ></lookup-combo>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col sm="3">
                                    <label for="input-itemremark"
                                        >{{
                                            $t('com_opmerking', {
                                                ns: 'common'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <b-form-textarea
                                        id="input-itemremark"
                                        :placeholder="
                                            $t('com_opmerking', {
                                                ns: 'common'
                                            })
                                        "
                                        v-model="localItem.opmerking"
                                        @change="setChanged"
                                        rows="3"
                                    ></b-form-textarea>
                                </b-col>
                            </b-row>
                        </div>
                    </b-card-text>
                </b-tab>
                <b-tab :title="strBijProduct" v-bind:key="2">
                    <b-card-text>
                        <b-button
                            size="sm"
                            @click="addBijItem"
                            class="bgc_aux_normal"
                            ><font-awesome-icon icon="arrow-down" size="1x" />
                        </b-button>
                        <b-alert
                            v-model="showDismissibleAlert"
                            variant="danger"
                            dismissible
                        >
                            {{ alertText }}
                        </b-alert>

                        <div
                            v-for="(bij, index) in localBijProdList"
                            v-bind:key="index"
                        >
                            <b-row>
                                <b-col sm="10">
                                    <plus-ingredient-combo
                                        v-model="localBijProdList[index]"
                                        v-bind:editAllowed="!disabled"
                                        v-bind:discrimArray="theItemArray"
                                        @input="acceptInputBij"
                                    />
                                </b-col>
                                <b-col sm="2">
                                    <b-button
                                        v-if="!disabled"
                                        @click="verwijderBijItem(bij.id)"
                                        class="bgc_aux_alarm"
                                        ><font-awesome-icon
                                            icon="trash-alt"
                                            size="1x"
                                        />
                                    </b-button>
                                </b-col>
                            </b-row></div
                    ></b-card-text>
                </b-tab>
            </b-tabs>
        </b-card>
        <b-card @submit.stop.prevent="handleSubmit" :header="strMengsel">
            <b-button size="sm" @click="addMixItem" class="bgc_aux_normal"
                ><font-awesome-icon icon="arrow-down" size="1x" />
            </b-button>
            <b-alert
                v-model="showDismissibleAlert"
                variant="danger"
                dismissible
            >
                {{ alertText }}
            </b-alert>

            <div v-for="(mix, index) in localMixList" v-bind:key="index">
                <b-row>
                    <b-col sm="10">
                        <plus-ingredient-combo
                            v-model="localMixList[index]"
                            v-bind:editAllowed="!disabled"
                            v-bind:discrimArray="theItemArray"
                            @input="acceptInput"
                        />
                    </b-col>
                    <b-col sm="2">
                        <b-button
                            v-if="!disabled"
                            size="sm"
                            @click="omlaag(mix.id)"
                            class="bgc_aux_unimportant"
                            ><font-awesome-icon icon="arrow-down" size="1x" />
                        </b-button>
                        <b-button
                            v-if="!disabled"
                            size="sm"
                            @click="omhoog(mix.id)"
                            class="bgc_aux_unimportant"
                            ><font-awesome-icon icon="arrow-up" size="1x" />
                        </b-button>
                        <b-button
                            v-if="!disabled"
                            @click="verwijderItem(mix.id)"
                            class="bgc_aux_alarm"
                            ><font-awesome-icon icon="trash-alt" size="1x" />
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </b-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { VueExtension } from '../../models/VueExtension';
import { LookupCombo } from '../components';
import PlusIngredientCombo from './PlusIngredientCombo.vue';
import { SimpleRecipe, SimpleRecipeElement } from '../../models/Recept';
import Helper from '@/models/Helper';
import { loadItem } from '@/models/DataHelper';

export default defineComponent({
    name: 'PlusReceptModal',
    data: function () {
        return {
            showDismissibleAlert: false,
            alertText: '',
            lctypeUnits: window.constants.LCPERUNITSBASEONLY, // product mag alleen in basiseenheid
            lctypeSimple: window.constants.LCSIMPLEPRODUCT,
            lctypeFatclass: window.constants.LCFATCLASS,
            perUnitsId: 0,
            localNaam: '',
            opmerking: '',
            lokaalItem: ({} as SimpleRecipe), // || null,
            nextID: -1,
            disabled: false,
            strNewSimpleRecipe: this.$t('com_makenew', {
                ns: 'common',
                val: this.$t('com_recipe', { ns: 'common' })
            }),
            strEditSimpleRecipe: this.$t('com_edit', {
                ns: 'common',
                val: this.$t('com_recipe', { ns: 'common' })
            }),
            strProduct: this.$t('com_product', { ns: 'common' }),
            strMengsel: this.$t('tien_mix', { ns: 'tien' }),

            strErrorDoubles: this.$t('com_doubleinlist', {
                ns: 'common',
                val: this.$t('com_product', { ns: 'common' })
            }),
            strBijProduct: this.$t('tien_byproducts', { ns: 'tien' }),
            strHoofdProduct: this.$t('tien_mainproduct', { ns: 'tien' })
        };
    },
    model: {
        prop: 'visible',
        event: 'change'
    },
    props: {
        inItem: {
            type: SimpleRecipe,
            required: true
        },
        discrimList: {
            type: Array<SimpleRecipe>,
            default: []
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    components: {
        LookupCombo,
        PlusIngredientCombo
    },
    watch: {},
    computed: {
        setDisabled() {
            return this.disabled;
        },
        formtitle() {
            return this.inItem.isNew
                ? this.strNewSimpleRecipe
                : this.strEditSimpleRecipe;
        },
        localItem(): SimpleRecipe {
            return this.inItem;
        },
        localMixList() {
            return this.inItem.elementen;
        },
        localBijProdList() {
            return this.inItem.bijproducten;
        },
        editProduct() {
            return !this.inItem.isNew;
        },
        theDiscrimList() {
            return this.inItem.isNew ? this.discrimList : [];
        },
        theItemArray() {
            const arr = new Array<SimpleRecipeElement>();
            const o = new SimpleRecipeElement({
                productID: this.inItem.id,
                productName: this.inItem.naam
            });
            arr.push(o);
            return arr;
        }
    },
    methods: {
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        hide() {
            this.$emit('hide', this.inItem);
        },
        change(visible) {
            this.setChanged();
            this.$emit('change', visible);
        },
        setChanged() {
            this.localItem.isChanged = true;
        },
        listHasDoubles() {
            // check of item niet dubbel voor komt
            const aantMixList = this.localMixList.length;
            const distinctList = Helper.distinctList(this.localMixList, 'id');
            const aantDistict = distinctList.length;
            return aantDistict != aantMixList;
        },
        listBijHasDoubles() {
            // check of item niet dubbel voor komt
            const aantMixList = this.localBijProdList.length;
            const distinctList = Helper.distinctList(
                this.localBijProdList,
                'id'
            );
            const aantDistict = distinctList.length;
            return aantDistict != aantMixList;
        },
        acceptInput(altered) {
            this.$nextTick(() => {
                if (this.listHasDoubles()) {
                    this.showAlert(this.strErrorDoubles);
                    return;
                }
            });
        },
        acceptInputBij(altered) {
            this.$nextTick(() => {
                if (this.listBijHasDoubles()) {
                    this.showAlert(this.strErrorDoubles);
                    return;
                }
            });
        },
        addMixItem() {
            const obj = new SimpleRecipeElement({ productID: this.nextID });
            this.inItem.elementen.push(obj);
            this.nextID = this.nextID - 1;
        },
        addBijItem() {
            const obj = new SimpleRecipeElement({ productID: this.nextID });
            this.inItem.bijproducten.push(obj);
            this.nextID = this.nextID - 1;
        },
        checkFormValidity() {
            if (this.listHasDoubles()) {
                this.showAlert(this.strErrorDoubles);
                return false;
            }
            const invalidBasis = this.localItem.CheckValidity(this.$t);
            if (invalidBasis) this.showAlert(invalidBasis);
            const valid = this.localItem != undefined && !invalidBasis;
            return valid;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return;
            }
            this.saveThisProduct();
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-plusrecept');
            });
        },
        async saveThisProduct() {
            this.localItem.isChanged = true;
            const model = this.localItem.SetForSave();
            const data = { data: model, func: this.$t };
            const terug = await loadItem('tienplus/saveSimpleRecipe', model, this.$t);
        },
        omlaag(theID) {
            const lijst = this.localMixList;
            const ind = lijst.findIndex((x) => x.id == theID);
            if (ind > -1) Helper.moveItem(lijst, ind, ind + 1);
            for (let i = 0; i < lijst.length; i++) {
                lijst[i].volgnummer = i + 1;
            }
            this.inItem.elementen = lijst;
        },
        omhoog(theID) {
            const lijst = this.localMixList;
            const ind = lijst.findIndex((x) => x.id == theID);
            if (ind > -1) Helper.moveItem(lijst, ind, ind - 1);
            for (let i = 0; i < lijst.length; i++) {
                lijst[i].volgnummer = i + 1;
            }
            this.inItem.elementen = lijst;
        },
        verwijderItem(theID) {
            for (let i = this.localMixList.length - 1; i >= 0; i--) {
                const id = this.localMixList[i].id;
                const test = id === theID;
                if (test) this.localMixList.splice(i, 1);
            }
        },
        verwijderBijItem(theID) {
            for (let i = this.localBijProdList.length - 1; i >= 0; i--) {
                const id = this.localBijProdList[i].id;
                const test = id === theID;
                if (test) this.localBijProdList.splice(i, 1);
            }
        }
    },
    mounted() {},
    created() {}
});
</script>

<style scoped></style>

