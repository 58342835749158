<template>
    <div @click="refresh()">
        <b-row class="form-inline">
            <b-form-input
                id="kaas"
                v-model="naamProduct"
                readonly
            ></b-form-input>
            <b-form-input
                id="kaasaantal"
                :placeholder="$t('com_number', { ns: 'common' })"
                type="number"
                step="1"
                v-model.number="alternatievenLijst.aantal"
                @change="set_inputKaasaantal"
                :disabled="!editAllowed"
                :state="alternatievenLijst.aantal > 0"
            ></b-form-input>

            <b-col sm="1" class="mx-0 px-0 my-1 py-0">
                <b-button
                    v-if="editAllowed"
                    @click="verwijderKaas(alternatievenLijst.productID)"
                    class="bgc_aux_alarm"
                    ><font-awesome-icon icon="trash-alt" size="1x" />
                </b-button>
            </b-col>
            <br />
        </b-row>

        <div
            v-for="(bakingredient, index) in altKaasIngredientenLijst"
            v-bind:key="bakingredient.id"
        >
            <b-row class="form-inline" v-if="showIngredientline">
                <b-col sm="11" class="mx-0 px-0 my-1 py-0">
                    <bak-ingredient-combo
                        v-bind:key="rerenderKey"
                        v-bind:baseUnit="baseUnit"
                        v-model="altKaasIngredientenLijst[index]"
                        v-bind:editAllowed="editAllowed"
                        v-bind:baseDateTime="baseDateTime"
                        v-bind:withPrepend="true"
                        @change="set_input"
                    />
                </b-col>
                <b-col sm="1" class="mx-0 px-0 my-1 py-0">
                    <b-button
                        v-if="editAllowed && stockAvailable"
                        @click="verwijderItem(bakingredient.id)"
                        class="bgc_aux_alarm"
                        ><font-awesome-icon icon="trash-alt" size="1x" />
                    </b-button>
                </b-col>
            </b-row>
        </div>

        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
            {{ alertText }}
        </b-alert>
        <b-alert v-model="showWarningAlert" variant="warning" dismissible>
            {{ reminder }}
        </b-alert>
        <hr />
    </div>
</template>

<script>
import { BakIngredientCombo } from '../components';
//import { BakIngredient, AlternativeCheese } from '../../models/BakInfo';
import { Alternativeslijst } from '../../models/BakInfo_helper';
import Helper from '../../models/Helper';
import { loadItem } from '../../models/DataHelper';

export default {
    name: 'CardAlternativeCheese',
    data() {
        return {
            lctypeIngredient: window.constants.LCINGREDIENT,
            lctypeCheeseAlternatives: window.constants.LCCHEESEALTERNATIVES,
            kilos: window.constants.KILOS, // todo
            newIngredientID: -1,
            newAlternativeID: -1,
            showDismissibleAlert: false,
            showWarningAlert: false,
            alertText: '',
            reminder: '',
            aangeraakt: 1,
            rerenderKey: 1,
            biValid: true,
            nostock: false,
            productnaam: null,
            laatstToegevoegd: null,
            strRemove: this.$t('prod_remove', {
                ns: 'production'
            })
        };
    },
    components: {
        BakIngredientCombo
    },
    model: {
        prop: 'alternatievenLijst',
        event: 'changedLijst'
    },
    props: {
        alternatievenLijst: {
            type: Alternativeslijst,
            required: true
        },
        // van de bak
        cheeseProductID: {
            type: Number,
            required: false
        },
        // van de alternatieve kaas
        altProductID: {
            type: Number,
            required: false
        },
        showIngredientline: {
            type: Boolean,
            default: true
        },
        editAllowed: {
            type: Boolean,
            default: false
        },
        baseDateTime: {
            type: String
        }
    },
    computed: {
        altKaasIngredientenLijst() {
            // eslint-disable-next-line no-unused-vars
            const tel = this.aangeraakt;
            if (
                this.alternatievenLijst == undefined ||
                this.alternatievenLijst.bakIngredienten == undefined
            )
                return;
            //return this.alternatievenLijst;
            const l = this.alternatievenLijst.bakIngredienten.filter(
                (x) =>
                    x.isDeleted == false &&
                    (x.nouse == undefined || x.nouse == false)
            );
            for (let i = 0; i < l.length; i++) {
                l[i].visible = this.showIngredientline;
            }
            return l;
        },
        baseUnit() {
            return this.kilos;
        },
        naamProduct() {
            return this.productnaam;
        },
        stockAvailable() {
            // eslint-disable-next-line no-unused-vars
            const tel = this.aangeraakt;
            return !this.nostock;
        }
    },
    methods: {
        maakString(item) {
            return String(item);
        },
        removeButtons(value) {
            this.nostock = value;
            this.aangeraakt += 1;
        },
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        showReminder(tekst) {
            this.reminder = tekst;
            this.showWarningAlert = true;
        },
        refresh() {
            this.aangeraakt += 1;
        },
        set_inputKaasaantal() {
            for (
                let i = 0;
                i < this.alternatievenLijst.bakIngredienten.length;
                i++
            ) {
                const item = this.alternatievenLijst.bakIngredienten[i];
                const altCheese = item.alternatieven.find(
                    (x) => (x.productID = this.alternatievenLijst.productID)
                );
                if (altCheese) {
                    // als hij onzichtbaar is dan moeten we eenheidID nog even opzoeken ivm opslaan
                    if (
                        !this.showIngredientline &&
                        item.eenheidID == undefined
                    ) {
                        item.eenheidID = this.$store.getters[
                            'components/getUnitIDbyName'
                        ](item.eenheid);
                    }
                    altCheese.aantal = this.alternatievenLijst.aantal;
                    altCheese.isChanged = true;
                    item.isChanged = true;
                }
            }
            this.set_input();
        },
        set_input() {
            this.$parent.$emit('validComponent', this.biValid);
            this.$parent.$emit('change', true);
        },
        async verwijderKaas() {
            // dit is gewoon alle bakingredienten verwijderen iig van alternatief kruid
            // in geval van alternatief naturel alleen alternatief verwijderen uit item
            const message = Helper.stringformat(
                this.strRemove,
                this.naamProduct
            );
            if (this.showIngredientline) {
                // kruiden als alternatief
                let asked = false;
                for (
                    let i = this.alternatievenLijst.bakIngredienten.length - 1;
                    i >= 0;
                    i--
                ) {
                    const item = this.alternatievenLijst.bakIngredienten[i];
                    if (item.isNew) {
                        // const index =
                        //     this.alternatievenLijst.bakIngredienten.findIndex(
                        //         (x) => x.id == item.id
                        //     );
                        if (i > -1) {
                            this.alternatievenLijst.bakIngredienten.splice(
                                i,
                                1
                            );
                            this.aangeraakt += 1;
                            this.showDismissibleAlert = false;
                        }
                    } else {
                        if (!asked) {
                            const doClose =
                                await this.$root.showConfirmationBox(message);
                            if (doClose) {
                                asked = true;
                            } else {
                                return;
                            }
                        }
                        item.isDeleted = true;
                        this.aangeraakt += 1;
                        this.set_input();
                    }
                }
            } else {
                // naturel als alternatief, array is maar 1 lang als het goed is
                for (let j = 0; j < this.altKaasIngredientenLijst.length; j++) {
                    const item = this.altKaasIngredientenLijst[j];
                    const altCheese = item.alternatieven.find(
                        (x) => (x.productID = this.alternatievenLijst.productID)
                    );
                    if (altCheese) {
                        const index = item.alternatieven.findIndex(
                            (x) =>
                                (x.productID =
                                    this.alternatievenLijst.productID)
                        );
                        if (index > -1) {
                            item.alternatieven.splice(index, 1);
                            this.aangeraakt += 1;
                            this.showDismissibleAlert = false;
                        }
                    }
                    if (
                        !this.showIngredientline &&
                        item.eenheidID == undefined
                    ) {
                        item.eenheidID = this.$store.getters[
                            'components/getUnitIDbyName'
                        ](item.eenheid);
                    }
                }
            }
            this.$emit('delete', this.alternatievenLijst.productID);
        },
        async geefNaam() {
            const product = await loadItem(
                'product/loadProduct',
                {
                    productID: this.alternatievenLijst.productID
                },
                this.$t
            );
            this.productnaam = product.naam;
        },
        async verwijderItem(bakingredientID) {
            const item = this.alternatievenLijst.bakIngredienten.find((x) => {
                return (
                    x.id === bakingredientID &&
                    (x.nouse == undefined || x.nouse == false)
                );
            });
            if (item) {
                if (item.isNew) {
                    const index =
                        this.alternatievenLijst.bakIngredienten.findIndex(
                            (x) => {
                                return (
                                    x.id === bakingredientID &&
                                    (x.nouse == undefined || x.nouse == false)
                                );
                            }
                        );
                    if (index > -1) {
                        this.alternatievenLijst.bakIngredienten.splice(
                            index,
                            1
                        );
                        this.aangeraakt += 1;
                        this.showDismissibleAlert = false;
                    }
                } else {
                    const message = Helper.stringformat(
                        this.strRemove,
                        bakingredientID
                    );
                    const doClose = await this.$root.showConfirmationBox(
                        message
                    );
                    if (doClose) {
                        item.isDeleted = true;
                        this.aangeraakt += 1;
                        this.set_input();
                    }
                }
            }
        },
        refresh_stocklist() {
            this.rerenderKey += 1;
        }
    },
    async mounted() {
        await this.geefNaam();
    }
};
</script>

