<template>
    <b-card no-body :header="$t('prod_overpompgeg', { ns: 'production' })">
        <div>
            <card-bakingredient-lijst
                v-if="theBakOverpomp.bakIngredientenZout.length > 0"
                v-model="theBakOverpomp.bakIngredientenZout"
                v-bind:editAllowed="editAllowed"
                v-bind:baseDateTime="theBakOverpomp.boekDatum"
                @change="setChanged"
                @validComponent="setZoutValid"
            />
            <card-alternative-cheese-lijst
                v-if="doShowAltenatives"
                v-model="theBakOverpomp.bakIngredientenAlternatief"
                v-bind:altBaseBI="theBakOverpomp.bakIngredientenAltBase"
                v-bind:editAllowed="editAllowed"
                v-bind:bakId="theBakOverpomp.bakId"
                v-bind:cheeseProductID="theBakOverpomp.productID"
                v-bind:baseDateTime="theBakOverpomp.boekDatum"
                @change="setChangedAlternatives"
                @validComponent="setAlternativesValid"
            />
            <b-card>
                <b-col sm="9" class="mx-0 px-0 my-1 py-0">
                    <b-row class="mx-0 px-0 my-1 py-0">
                        <b-col sm="3" class="mx-0 px-0 my-1 py-0">
                            <label
                                >{{
                                    $t('prod_xtijdstip', {
                                        ns: 'production',
                                        val: $t('prod_overpomp', {
                                            ns: 'production'
                                        })
                                    })
                                }}:</label
                            >
                        </b-col>
                        <b-col sm="9" class="mx-0 px-0 my-1 py-0">
                            <b-form-timepicker
                                v-model="overpomptijdstip"
                                locale="nl"
                                @input="set_inputTijd"
                                :disabled="!editAllowed"
                            ></b-form-timepicker>
                        </b-col>
                        <b-col sm="3" class="mx-0 px-0 my-1 py-0">
                            <label
                                >{{
                                    $t('prod_xprogramma', {
                                        ns: 'production',
                                        val: $t('prod_drainage', {
                                            ns: 'production'
                                        })
                                    })
                                }}:</label
                            >
                        </b-col>
                        <b-col sm="9" class="mx-0 px-0 my-1 py-0">
                            <b-form-input
                                id="input-program"
                                :placeholder="
                                    $t('prod_xprogramma', {
                                        ns: 'production',
                                        val: $t('prod_drainage', {
                                            ns: 'production'
                                        })
                                    })
                                "
                                v-model="programma"
                                @change="set_inputProgramma"
                                :disabled="!editAllowed"
                            ></b-form-input>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col sm="3" class="mx-0 px-0 my-1 py-0">
                    <b-row>
                        <b-col>
                            <b-button
                                @click="SetDefaults()"
                                class="bgc_aux_warning float-right "
                                :disabled="!editAllowed"
                                ><font-awesome-icon
                                    icon="edit"
                                    size="1x"
                                />&nbsp;{{
                                    $t('com_default', { ns: 'common' })
                                }}
                            </b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-card>
        </div>
    </b-card>
</template>

<script>
import { BakOverpomp } from '../../models/BakInfo_helper';
import CardBakingredientLijst from './CardBakingredientLijst';
import CardAlternativeCheeseLijst from './CardAlternativeCheeseLijst';
import { loadList } from '../../models/DataHelper';
import DateHelper from '../../models/DateHelper';

export default {
    name: 'CardOverpomp',
    data() {
        return {
            overpomptijdstip: null,
            programma: null,
            zoutValid: true,
            alternativesValid: true,
            showAlternatives: false
        };
    },
    components: {
        CardBakingredientLijst,
        CardAlternativeCheeseLijst
    },
    model: {
        prop: 'theBakOverpomp',
        event: 'changedRecipe'
    },
    props: {
        theBakOverpomp: {
            type: BakOverpomp,
            default: null
        },
        editAllowed: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        doShowAltenatives() {
            return this.showAlternatives;
        }
    },
    methods: {
        set_inputTijd: function () {
            this.theBakOverpomp.overpompTijdstip =
                DateHelper.convertedFromTimepicker(
                    this.theBakOverpomp.boekDatum,
                    this.overpomptijdstip
                );
            //this.theBakOverpomp.overpompTijdstip = this.overpomptijdstip;
            this.setChanged();
        },
        set_inputProgramma: function () {
            this.theBakOverpomp.Drainprogram = this.programma;
            this.setChanged();
        },
        setZoutValid: function (value) {
            this.zoutValid = value;
            this.setChanged();
        },
        setAlternativesValid: function (value) {
            this.alternativesValid = value;
            this.setChanged();
        },
        setChanged: function () {
            const result = this.zoutValid;
            this.$emit('validComponent', result);
            this.$emit('change', true);
        },
        setChangedAlternatives: function () {
            const result = this.alternativesValid;
            this.$emit('validAlternatives', result);
            this.$emit('change', true);
        },
        async SetDefaults() {
            this.overpomptijdstip = DateHelper.convertedToTime();
            this.set_inputTijd();
            const selectionData = { recipeid: this.theBakOverpomp.receptID };
            const recipe = await loadList(
                'recipe/getRecipe',
                'recipe/loadRecipeComplete',
                selectionData,
                true,
                this.$t
            );
            this.programma = recipe.recProduction.progDrain;
        }
    },
    mounted() {
        this.programma = this.theBakOverpomp.Drainprogram;
        if (this.theBakOverpomp.overpompTijdstip) {
            const dedate = new Date(this.theBakOverpomp.overpompTijdstip);
            this.overpomptijdstip = DateHelper.convertedToTime(dedate);
        } else {
            this.overpomptijdstip = DateHelper.convertedToTime(new Date());
        }
    },
    async created() {
        const lijst = await loadList(
            'components/getCheesesAlternativesComboLijst',
            'components/loadCheeseTypeAlternatives',
            null,
            false,
            this.$t
        );
        const alt1 = lijst.filter(
            (x) => x.baseproductid == this.theBakOverpomp.productID
        );
        const alt2 = lijst.filter(
            (x) => x.value == this.theBakOverpomp.productID
        );
        this.showAlternatives = alt1.length > 0 || alt2.length > 0;
    }
};
</script>

