import axios from 'axios';

export default (store, headers) => {

    let realHeaders = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };

    if (headers) {
        realHeaders = headers;
    }
    //if (store.getters.hasValidatedJWTToken) {
        realHeaders.Authorization = store.getters[
            'account/getBearerToken'
        ];
    //}

    return axios.create({
        baseURL: window.VUE_APP_WEBAPI,
        headers: realHeaders
    });

    
};
