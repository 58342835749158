import Api from '../../../api';
import store from '../../../store';

//#region Kaas
const fetchCheeseTypeInfo = async (data) => {
    return fetchInfo('/CheeseType/GetCheeseTypeInfo', data);
};

const fetchCheeseType = async (data) => {
    return fetchInfo('/CheeseType/GetCheeseType', data);
};

const fetchCheeseTypeIngredients = async (data) => {
    return fetchInfo('/CheeseType/GetCheeseTypeIngredients', data);
};

const saveCheeseType = async (data) => {
    return await Api(store).put('/CheeseType/StoreCheeseType', data);
};
//#endregion Kaas

//#region Merktype
const fetchCheeseMarkTypeInfo = async (data) => {
    return fetchInfo('/CheeseMark/GetCheeseMarkTypeInfo', data);
};

const fetchCheeseMarkType = async (data) => {
    return fetchInfo('/CheeseMark/GetCheeseMarkType', data);
};

const saveCheeseMarkType = async (data) => {
    return await Api(store).put('/CheeseMark/StoreCheeseMarkType', data);
};
//#endregion Merktype

//#region Coating
const fetchCheeseCoatingInfo = async (data) => {
    return fetchInfo('/CheeseCoating/GetCheeseCoatingsInfo', data);
};

const fetchCheeseCoating = async (data) => {
    return fetchInfo('/CheeseCoating/GetCheeseCoating', data);
};

const saveCheeseCoating = async (data) => {
    return await Api(store).put('/CheeseCoating/StoreCheeseCoating', data);
};
//#endregion Coating

//#region WheyType
const fetchWheyTypeInfo = async (data) => {
    return fetchInfo('/WheyType/GetWheyTypesInfo', data);
};

const fetchWheyType = async (data) => {
    return fetchInfo('/WheyType/GetWheyType', data);
};

const saveWheyType = async (data) => {
    return await Api(store).put('/WheyType/StoreWheyType', data);
};
//#endregion WheyType

//#region FailureCheese
const fetchFailureCheeseInfo = async (data) => {
    return fetchInfo('/FailureCheese/GetFailureCheeseProductInfo', data);
};

const fetchFailureCheese = async (data) => {
    return fetchInfo('/FailureCheese/GetFailureCheeseProduct', data);
};

const saveFailureCheese = async (data) => {
    return await Api(store).put(
        '/FailureCheese/StoreFailureCheeseProduct',
        data
    );
};
//#endregion FailureCheese

//#region Overige
const fetchOverigeInfo = async (data) => {
    return fetchInfo('/Product/GetProductInfo', data);
};

const fetchOverige = async (data) => {
    return fetchInfo('/Product/GetProduct', data);
};

const fetchAllProducts = async (data) => {
    return fetchInfo('/Product/GetProductsAndLinkedProducts', data);
};

const saveOverige = async (data) => {
    return await Api(store).put('/Product/StoreProduct', data);
};
//#endregion Overige

//#region Ingredients

const fetchStarterSubTypeInfo = async (data) => {
    return fetchInfo('/Ingredient/GetStarterSubtypeIngredients', data);
};

const fetchIngredientInfo = async (data) => {
    return fetchInfo('/Ingredient/GetIngredientInfo', data);
};

const fetchIngredient = async (data) => {
    return fetchInfo('/Ingredient/GetIngredient', data);
};

const fetchIngredientCheeseTypes = async (data) => {
    return fetchInfo('/Ingredient/GetIngredientCheeseTypes', data);
};

const fetchAlternativeIngredients = async (data) => {
    return fetchInfo('/Ingredient/GetAlternativeIngredients', data);
};

// int milkGroupTypeID
const fetchSeasonValues = async (data) => {
    return fetchInfo('/Ingredient/GetSeasonValues', data);
};

// UpdateSeasonValuesModel sv
const saveSeasonValues = async (data) => {
    return await Api(store).put('/Ingredient/StoreSeasonValues', data);
};

// IngredientModel
const saveIngredient = async (data) => {
    return await Api(store).put('/Ingredient/StoreIngredient', data);
};

// List<int> productIDs
const saveGroupStarterIngredients = async (data) => {
    return await Api(store).put('/Ingredient/GroupStarterIngredients', data);
};

// int productID
const saveRemoveStarterFromGroup = async (data) => {
    //return await Api(store).put('/Ingredient/RemoveStarterFromGroup', data);
    return putInfo('/Ingredient/RemoveStarterFromGroup', data);
};


//#endregion Ingredients

//#region Categories
const fetchProductCategories = async () => {
    return await Api(store).get('/Product/GetProductCategories', {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
};

const fetchIngredientCategories = async () => {
    return await Api(store).get('/Ingredient/GetIngredientCategories', {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
};

//#endregion Categories

// int productid
const fetchStockByProduct = async (data) => {
    return fetchInfo('/StockItem/GetStockItemsByProductID', data);
};

const deleteProduct = async (data) => {
    return await Api(store).delete('/Product/deleteProduct', { params: data });
};

const fetchProduct = async (data) => {
    return fetchInfo('/Product/GetProduct', data);
};

const linkProduct = async (data) => {
    return await Api(store).put('/Product/linkProducts', data);
};




const fetchInfo = async (apicall, data) => {
    if (data != undefined) {
        return await Api(store).get(
            apicall +
                '?' +
                Object.keys(data)
                    .map(
                        (k) =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    } else {
        return await Api(store).get(apicall, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    }
};
const putInfo = async (apicall, data) => {
    if (data != undefined) {
        return await Api(store).put(
            apicall +
                '?' +
                Object.keys(data)
                    .map(
                        (k) =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    } else {
        return await Api(store).get(apicall, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    }
};

export default {
    fetchCheeseTypeInfo,
    fetchCheeseType,
    fetchCheeseTypeIngredients,
    saveCheeseType,
    fetchCheeseMarkTypeInfo,
    fetchCheeseMarkType,
    saveCheeseMarkType,
    fetchCheeseCoatingInfo,
    fetchCheeseCoating,
    saveCheeseCoating,
    fetchWheyTypeInfo,
    fetchWheyType,
    saveWheyType,
    fetchFailureCheeseInfo,
    fetchFailureCheese,
    saveFailureCheese,
    fetchOverigeInfo,
    fetchOverige,
    fetchAllProducts,
    saveOverige,
    linkProduct,
    fetchStarterSubTypeInfo,
    fetchAlternativeIngredients,
    fetchIngredientInfo,
    fetchIngredient,
    fetchIngredientCheeseTypes,
    fetchSeasonValues,
    saveSeasonValues,
    saveIngredient,
    fetchProductCategories,
    fetchIngredientCategories,
    fetchStockByProduct,
    deleteProduct,
    fetchProduct,
    saveGroupStarterIngredients,
    saveRemoveStarterFromGroup
};


