const getters = {
    getRecipe: state =>  {
        return state.recipe;
    },    
    getOpenRecipeInfo: state =>  {
        return state.recipeInfoList;
    },    
    getClosedRecipeInfo: state =>  {
        return state.closedRecipeInfoList;
    },    
    getClosedRecipeInfoForKaas: state => (kaasnaam) => {
        return state.closedRecipeInfoList.filter(x => x.kaasnaam.toLowerCase() === kaasnaam.toLowerCase());
    }
    
};

export default getters;

