<template>
    <b-container fluid style="margin-top: 60px">
        <h1>
            {{ $t('com_special', { ns: 'common' }) }}
            {{ $t('com_acties', { ns: 'common' }).toLowerCase() }}
        </h1>
        <b-card>
            <b-tabs card>
                <b-tab
                    :title="$t('com_production', { ns: 'common' })"
                    v-bind:key="1"
                    active
                >
                    <b-row v-if="checkRight('BatchDestroy')">
                        <b-col sm="1" />
                        <b-col sm="3" class="form-inline mx-0 px-0 my-0 py-0">
                            {{ deleteBak }}
                        </b-col>
                        <b-col sm="8" class="form-inline mx-0 px-0 my-0 py-0">
                            <b-input-group
                                :prepend="
                                    $t('com_production', {
                                        ns: 'common'
                                    })
                                "
                            >
                                <b-form-datepicker
                                    id="dateVan"
                                    v-model="van"
                                    :date-format-options="{
                                        year: 'numeric',
                                        month: 'short',
                                        day: '2-digit'
                                    }"
                                    @input="loadProductionData"
                                ></b-form-datepicker>
                            </b-input-group>
                            <b-input-group
                                :prepend="
                                    $t('prod_bak', {
                                        ns: 'production'
                                    })
                                "
                            >
                                <b-form-select
                                    v-model="batchID"
                                    :options="bakkenLijst"
                                    @change="setBatchSelection"
                                >
                                    <template #first>
                                        <b-form-select-option value=""
                                            >--
                                            {{
                                                $t('com_selecteer', {
                                                    ns: 'common'
                                                })
                                            }}
                                            --</b-form-select-option
                                        >
                                    </template>
                                </b-form-select>
                            </b-input-group>
                            <b-button
                                @click="destroyBatch"
                                class="bgc_aux_alarm"
                                v-b-tooltip.hover
                                :title="$t('com_verwijder', { ns: 'common' })"
                                :disabled="!selected"
                                ><font-awesome-icon
                                    icon="trash-alt"
                                    size="1x"
                                />
                            </b-button>
                        </b-col>
                    </b-row>
                </b-tab>
                <b-tab
                    :title="$t('prod_zuursel', { ns: 'production' })"
                    v-bind:key="2"
                    ><card-zuursel v-if="checkRight('ProductGroupStarters')" />
                </b-tab>
            </b-tabs>
        </b-card>
    </b-container>
</template>

<script>
import DateHelper from '../../models/DateHelper';
import Helper from '../../models/Helper';
import { loadList, loadItem } from '../../models/DataHelper';
import { Permission } from '../../models/Permissions';
import CardZuursel from './CardZuursel.vue';

export default {
    name: 'RepairBase',
    data: function () {
        return {
            van: null,
            flexList: [],
            selected: false,
            batchID: null,
            deleteBak: this.$t('prod_removebatchstarted', {
                ns: 'production'
            }),
            messageDeleteBak:
                this.$t('com_delete', {
                    ns: 'common',
                    val: this.$t('prod_bak', { ns: 'production' }) + ' {0} '
                }) +
                this.$t('com_van', { ns: 'common' }).toLowerCase() +
                ' ' +
                this.$t('com_production', { ns: 'common' }).toLowerCase() +
                ' {1}? '
        };
    },
    components: {
        CardZuursel
    },
    computed: {
        bakkenLijst() {
            return this.flexList;
        }
    },
    methods: {
        async destroyBatch() {
            const bak = this.flexList.find((x) => x.value == this.batchID);
            const waarschuwing = Helper.stringformat(
                this.messageDeleteBak,
                bak.item.bakno,
                bak.item.productieDatum
            );
            const doClose = await this.$root.showConfirmationBox(waarschuwing);
            if (doClose) {
                await loadItem(
                    'production/destroyBatch',
                    {
                        batchid: bak.value,
                        productionStarted: false // voorlopig default
                    },
                    this.$t
                );
            }
        },
        async loadProductionData() {
            this.$nextTick(async () => {
                const selectionData = {
                    bookdate: DateHelper.localDateToUTC(this.van)
                };
                const templijst = await loadList(
                    'production/getBatchInfoLijst',
                    'production/loadBatchInfo',
                    selectionData,
                    true,
                    this.$t
                );
                const lijst = Object.values(
                    templijst.reduce(
                        (acc, obj) => ({ ...acc, [obj.id]: obj }),
                        {}
                    )
                );
                this.flexList = lijst.map((x) => ({
                    value: x.id,
                    text: x.bakno,
                    item: x
                }));
            });
        },
        setBatchSelection() {
            this.$nextTick(async () => {
                this.selected = true;
            });
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        }
    },
    mounted() {},
    created() {}
};
</script>

<style scoped></style>

