<template>
    <div class="form-inline">
        <b-input-group>
            <b-form-input
                ref="waarde"
                :placeholder="$t('an_hoeveelheid', { ns: 'analytics' })"
                type="number"
                step="0.1"
                v-model.number="localSelected.hoeveelheid"
                autofocus
                @change="set_input"
                :disabled="!editAllowed"
            ></b-form-input>

            <lookup-combo
                v-model="localSelected.grootheidid"
                v-bind:combotype="lctypeIQ"
                v-bind:discrim="filterIQs"
                @input="set_inputIQ"
                :disabled="!editAllowed"
            ></lookup-combo>

            <lookup-combo
                key="rerenderkey"
                v-model="localSelected.eenheidid"
                v-bind:combotype="lctypeQunit"
                v-bind:discrim="baseUnitString"
                @input="set_inputQUN"
                :disabled="!editAllowed"
            ></lookup-combo>
        </b-input-group>
        <b-alert v-model="showWarningAlert" variant="warning" dismissible>
            {{ reminder }}
        </b-alert>
    </div>
</template>
<script>
import LookupCombo from './LookupCombo.vue';
import { MaesureObject, NormObject } from '../../models/Anayltics';
import Helper from '../../models/Helper';
import { loadList } from './utils';
export default {
    name: 'MeasurementCombo',
    data: function () {
        return {
            lctypeQunit: window.constants.LCA_QUNIT,
            lctypeIQ: window.constants.LCA_INVQUANTITY,
            errorMessage: '',
            flexList: [],
            aangeraakt: 1,
            rerenderkey: 1,
            isInList: true,
            productNaam: '',
            localSelected: null,
            baseUnit: -1,
            showWarningAlert: false,
            baseString: '-2',
            reminder: '',
            message: this.$t('an_warnbuitennorm', { ns: 'analytics' })
        };
    },
    model: {
        prop: 'selected',
        event: 'input'
    },
    props: {
        selected: {
            type: MaesureObject,
            required: false
        },
        norm: {
            type: NormObject,
            required: false
        },
        IQ: {
            type: Number,
            required: false
        },
        filterIQs: {
            type: String,
            required: false
        },
        editAllowed: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        IQ() {
            this.localSelected.grootheidid = this.IQ;
            this.set_inputIQ();
        }
    },
    components: {
        LookupCombo
    },
    computed: {
        baseUnitString() {
            const baseu = this.baseUnit;
            return baseu > -1 ? baseu.toString() : '';
        },
        existing() {
            return this.selected;
        }
    },
    methods: {
        showReminder(tekst) {
            this.reminder = tekst;
            this.showWarningAlert = true;
        },
        set_input() {
            this.aangeraakt += 1;
            this.$emit('change', true);
            this.$emit('input', this.localSelected);
        },
        checkNorm() {
            if (this.norm) {
                const minNorm = this.norm.minwaarde;
                const maxNorm = this.norm.maxwaarde;
                const bericht = Helper.stringformat(
                    this.message,
                    minNorm,
                    maxNorm
                );
                this.showReminder(bericht);
            }
        },
        set_inputQUN(val) {
            this.localSelected.eenheidid = val;
            this.$emit('change', true);
            this.$emit('input', this.localSelected);
        },
        async set_inputIQ() {
            this.$nextTick(async () => {
                const newid = this.localSelected
                    ? this.localSelected.grootheidid
                    : -1;
                const lijst = await loadList(
                    'analytics/getMaesurableIQLijst',
                    'analytics/loadMaesQuantityLijst',
                    null,
                    this.forcerefresh,
                    this.$t
                );
                const iq = lijst.find((x) => x.id == newid);
                if (iq) {
                    if (this.baseString != iq.grootheid) {
                        const templijst = await loadList(
                            'analytics/getQuantityLijst',
                            'analytics/loadQuantityLijst',
                            null,
                            this.forcerefresh,
                            this.$t
                        );
                        const baseObject = templijst.find(
                            (x) => x.naam == iq.grootheid
                        );
                        const zoekBaseEenheidString = baseObject
                            ? baseObject.eenheid
                            : '';
                        const lijst =
                            this.$store.getters['analytics/getQunitLijst'];
                        const baseUnitObject = lijst.find(
                            (x) => x.naam == zoekBaseEenheidString
                        );
                        this.baseUnit = baseUnitObject ? baseUnitObject.id : -1;
                        if (this.localSelected.eenheidid < 0) {
                            this.localSelected.eenheidid = this.baseUnit;
                        }
                        this.rerenderkey += 1;
                    }
                }
                this.$refs['waarde'].focus();
            });
        }
    },
    mounted() {},
    created() {
        if (this.selected) {
            this.localSelected = this.selected;
            this.set_inputIQ();
        } else {
            this.localSelected = new MaesureObject();
        }
    }
};
</script>

<style scoped></style>

