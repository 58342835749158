import { render, staticRenderFns } from "./ModalMeasurement.vue?vue&type=template&id=f2ba7e20&scoped=true"
import script from "./ModalMeasurement.vue?vue&type=script&lang=js"
export * from "./ModalMeasurement.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2ba7e20",
  null
  
)

export default component.exports