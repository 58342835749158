export { ExternalDevice, DeviceType, InProduct };
import DateHelper from './DateHelper';
import Helper from './Helper';

class ExternalDevice {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.ec_id ?? data.id,
                writable: false
            },
            gewijzigd: {
                value: data.updated,
                writable: false
            },
            naam: {
                type: String,
                value: data.application,
                writable: true,
                enumerable: true
            },
            tijdstip: {
                type: Date,
                value: data.updated ?? data.created,
                writable: true,
                enumerable: true
            },
            verwijderd: {
                type: Date,
                value: data.deleted,
                writable: true,
                enumerable: true
            },
            token: {
                type: String,
                value: data.token,
                writable: true,
                enumerable: true
            },
            url: {
                type: String,
                value: data.baseUrl ?? data.baseurl,
                writable: true,
                enumerable: true
            },
            email: {
                type: String,
                value: data.notifyMail ?? data.notifymail,
                writable: true,
                enumerable: true
            },
            eigenid: {
                type: String,
                value: data.deviceID ?? data.deviceid,
                writable: true,
                enumerable: true
            },
            keynaam: {
                type: String,
                value: data.keyname ?? data.keyName,
                writable: true,
                enumerable: true
            },
            ondType: {
                type: DeviceType,
                value: data.type ? data.type : 0,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }

    SetForSave() {
        const APIKeyModel = {
            ID: this.id,
            Application: this.naam,
            Type: this.ondType,
            Token: this.token,
            BaseUrl: this.url,
            NotifyMail: this.email,
            DeviceID: this.eigenid,
            KeyName: this.keynaam
        };
        return APIKeyModel;
    }
    CheckValidity(func) {
        let warnText = '';
        if (this.ondType == window.constants.API_DEVICE) {
            if (!this.eigenid)
                warnText = Helper.concatString(
                    warnText,
                    func('com_isrequired', {
                        ns: 'common',
                        val: '',
                        prop: func('com_deviceid', { ns: 'common' })
                    })
                );
        } else {
            if (!this.token && this.isNew)
                warnText = Helper.concatString(
                    warnText,
                    func('com_isrequired', {
                        ns: 'common',
                        val: '',
                        prop: func('com_token', { ns: 'common' })
                    })
                );
            if (!this.url && this.ondType == window.constants.API_OUT)
                warnText = Helper.concatString(
                    warnText,
                    func('com_isrequired', {
                        ns: 'common',
                        val: '',
                        prop: func('com_url', { ns: 'common' })
                    })
                );
            if (!this.email && this.ondType == window.constants.API_IN)
                warnText = Helper.concatString(
                    warnText,
                    func('com_isrequired', {
                        ns: 'common',
                        val: '',
                        prop: func('com_mail', { ns: 'common' })
                    })
                );
            if (!this.keynaam && this.ondType == window.constants.API_OUT)
                warnText = Helper.concatString(
                    warnText,
                    func('com_isrequired', {
                        ns: 'common',
                        val: '',
                        prop: func('com_keynaam', { ns: 'common' })
                    })
                );
        }
        return warnText;
    }
}

class DeviceType {
    constructor(data, func) {
        this.id = -1;
        this.type = 'DeviceType';
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false,
                enumerable: true
            },
            naam: {
                type: String,
                value: DeviceType.giveName(data.id, func),
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }
    static giveName(id, func) {
        if (id == undefined) return undefined;
        const lookup = 'devtype_' + id.toString();
        return id >= 0 ? func(lookup, { ns: 'permissies' }) : undefined;
    }
}

class InProduct {
    constructor(data) {
        this.id = '-1';
        data = data ?? {};
        this.id = data.id ?? '-1';
        this.isChanged = false;
        this.extid = data.itemid;
        this.naam = data.itemname;
        this.type = data.itemtype; // kaas /ingredient /stremsel
        this.toelichting = data.descriptivetext;
        this.unit = data.unitofmeasure;
        this.error = data.error;
    }
}

