<template>
    <b-container fluid style="margin-top: 60px">
        <div class="form-inline">
            <h1>
                {{ title }}
            </h1>
            &nbsp;
            <b-button
                @click="changed()"
                class="bgc_aux_normal"
                v-b-tooltip.hover
                :title="$t('com_refresh', { ns: 'common' })"
                ><font-awesome-icon icon="arrow-down" size="1x" />
            </b-button>
            &nbsp;
            <b-button @click="scan()" class="bgc_aux_unimportant float-md-right"
                ><font-awesome-icon icon="barcode" size="1x" />
            </b-button>
        </div>
        <b-row>
            <b-col sm="12">
                <div v-if="showFilter">
                    <b-row no-gutters>
                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_filter', { ns: 'common' })"
                                label-for="filter-input"
                                label-cols-sm="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-input-group size="sm">
                                    <b-form-input
                                        id="filter-input"
                                        v-model="filter"
                                        type="search"
                                        :placeholder="
                                            $t('com_typetosearch', {
                                                ns: 'common'
                                            })
                                        "
                                    ></b-form-input>

                                    <b-input-group-append>
                                        <b-button
                                            :disabled="!filter"
                                            @click="filter = ''"
                                            >{{
                                                $t('com_wis', { ns: 'common' })
                                            }}</b-button
                                        >
                                    </b-input-group-append>
                                </b-input-group>

                                <b-button
                                    v-if="
                                        checkRight('AnalyticsEnityObjectCreate')
                                    "
                                    size="sm"
                                    @click="createNewItem"
                                    class="mr-1"
                                    ><font-awesome-icon
                                        icon="folder-plus"
                                        size="1x"
                                    />
                                    {{
                                        $t('com_newx', {
                                            ns: 'common',
                                            val: $t('com_item', {
                                                ns: 'common'
                                            })
                                        })
                                    }}
                                </b-button>
                            </b-form-group>
                        </b-col>

                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_perpagina', { ns: 'common' })"
                                label-for="per-page-select"
                                label-cols-sm="6"
                                label-cols-md="4"
                                label-cols-lg="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-form-select
                                    id="per-page-select"
                                    v-model="perPage"
                                    :options="pageOptions"
                                    size="sm"
                                ></b-form-select>
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                ></b-pagination>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div v-else>
                    <b-button
                        v-if="checkRight('AnalyticsEnityObjectCreate')"
                        size="sm"
                        @click="createNewItem"
                        class="mr-1"
                        ><font-awesome-icon icon="folder-plus" size="1x" />
                        {{
                            $t('com_newx', {
                                ns: 'common',
                                val: $t('com_item', {
                                    ns: 'common'
                                })
                            })
                        }}
                    </b-button>
                </div>
                <b-table
                    :items="groupedDisplayList"
                    :fields="visibleFields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    stacked="md"
                    show-empty
                    :empty-text="$t('com_geen_data', { ns: 'common' })"
                    small
                    striped
                    @filtered="onFiltered"
                    @row-clicked="toonDetail"
                >
                    <template v-slot:cell(actions)="row">
                        <div class="form-inline">
                            <b-button
                                size="sm"
                                @click="toonDetail(row.item)"
                                class="bgc_aux_unknown"
                                ><font-awesome-icon
                                    icon="info-circle"
                                    size="1x"
                                />
                            </b-button>
                        </div>
                    </template>

                    <template v-slot:row-details>
                        <executor-info
                            v-bind:inItems="localObjects"
                            @changed="changed"
                        >
                        </executor-info>
                    </template>
                </b-table>
                <div v-if="displayList.length == 0">
                    {{ $t('com_geen_data', { ns: 'common' }) }}
                </div>
            </b-col>
        </b-row>
        <modal-executor
            v-if="detailsVisible"
            :inID="selectedExecutor?.id"
            :inType="typeNaam"
            v-model="detailsVisible"
            @hide="reset"
            @forcerefresh="changed"
        />
        <scan-barcode
            v-if="showScanner"
            v-model="showScanner"
            @result="showExecutorDetail"
        />
    </b-container>
</template>

<script>
import _ from 'lodash';
import ModalExecutor from './ModalExecutor.vue';
import ExecutorInfo from './ExecutorInfo.vue';
import ScanBarcode from '../components/ScanBarcode.vue';
import { Permission } from '../../models/Permissions';
import { loadList, loadItem } from './utils';

export default {
    name: 'ExecutorBase',
    data: function () {
        return {
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [10, 20, 40, { value: 100, text: '100' }],
            filter: null,
            filterOn: [],
            forcerefresh: false,
            detailsVisible: false,
            localObjects: [],
            totaalLijst: [],
            objectNaam: '',
            showScanner: false,
            aangeraakt_teller: 1,
            selectedExecutor: null,
            theTypeName: 'device'
        };
    },
    components: {
        ModalExecutor,
        ScanBarcode,
        ExecutorInfo
    },
    computed: {
        title() {
            return (
                this.$t('an_uitvoerders', { ns: 'analytics' }) +
                ' ' +
                this.$t('an_onderzoek', { ns: 'analytics' }).toLowerCase()
            );
        },
        showFilter() {
            return this.displayList
                ? this.displayList.length > this.displayFilterCount
                : false;
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                {
                    key: 'categorie',
                    label: this.$t('com_categorie', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'uitvoerders.length',
                    label: this.$t('com_number', { ns: 'common' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        typeNaam() {
            return this.selectedExecutor
                ? this.selectedExecutor.typenaam
                : this.theTypeName;
        },
        displayList() {
            // eslint-disable-next-line no-unused-vars
            const veranderd = this.aangeraakt_teller;
            const lijst = this.totaalLijst;
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.totalRows = lijst ? lijst.length : 0;
            return lijst;
        },
        groupedDisplayList() {
            // eslint-disable-next-line no-unused-vars
            const veranderd = this.aangeraakt_teller;
            if (this.displayList == undefined) return null;
            const x = _(this.displayList.slice(0))
                .groupBy((x) => x.typenaam)
                .map((value, key) => {
                    //const quantity = _.sumBy(value, 'aantal');
                    return {
                        categorie: key,
                        uitvoerders: value
                    };
                })
                .value();
            return x;
        },
        allowEdit() {
            // todo: wat worden de regels hiervoor?
            return true;
        }
    },
    methods: {
        reset() {
            this.detailsVisible = false;
        },
        scan() {
            this.showScanner = !this.showScanner;
        },
        changed() {
            this.forcerefresh = true;
            this.loadData();
        },
        async toonDetail(row) {
            if (row._showDetails) {
                this.$set(row, '_showDetails', false);
            } else {
                this.groupedDisplayList.forEach((row) => {
                    this.$set(row, '_showDetails', false);
                });
                this.localObjects = row.item
                    ? row.item.uitvoerders
                    : row.uitvoerders;
                this.$set(row, '_showDetails', true);
            }
        },
        async createNewItem() {
            this.detailsVisible = !this.detailsVisible;
        },
        async verwijderItem(item) {
            const doClose = await this.$root.showConfirmationBox(
                this.$t('com_delete', {
                    ns: 'common',
                    val: this.$t('an_uitvoerder', { ns: 'analytics' })
                }) + item.naam
            );
            if (doClose) {
                await loadItem(
                    'analytics/deleteExecutor',
                    {
                        maintenanceID: item.id
                    },
                    this.$t
                );
            }
            this.loadData();
        },
        mergeLijst(...arrays) {
            const merged = {};
            arrays.forEach((data) =>
                data.forEach((o) => Object.assign((merged[o.id] ??= {}), o))
            );

            return Object.values(merged);
        },
        showExecutorDetail(value) {
            if (value == undefined) return;
            const barcodeEX = value;
            const tempEx = this.displayList.find((x) => x.barcode == barcodeEX);
            if (tempEx) {
                this.selectedExecutor = tempEx;
                this.detailsVisible = !this.detailsVisible;
            } else {
                const bericht =
                    this.$t('com_nodata', { ns: 'common' }) +
                    '? ' +
                    this.$t('com_xnotfound', {
                        ns: 'common',
                        val: this.$t('com_barcode', { ns: 'common' })
                    }) +
                    ': ' +
                    barcodeEX;
                this.$root.showMessageBox(bericht);
            }
        },
        async loadData() {
            const lijst3 = await loadList(
                'analytics/getExecutorLijst',
                'analytics/loadExecutorLijst',
                null,
                this.forcerefresh,
                this.$t
            );
            this.forcerefresh = false;
            this.totaalLijst = lijst3;
            this.aangeraakt_teller += 1;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        }
    },
    mounted: function () {
        this.forcerefresh = true;
        this.loadData();
        this.forcerefresh = false;
        this.totalRows = this.displayList ? this.displayList.length : 0;
    },
    created() {}
};
</script>

<style scoped></style>

