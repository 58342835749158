import { api } from '../api';
import { showBadToast, showGoodToast } from '../../errors/toast';
import logMessage from '../../errors/foutlog';
import { RecurrencyPattern, RecurrencyView } from '../../../models/Recurrency';

const actions = {
    // int rpID
    async loadRecurrencyPattern(context, data) {
        let item = null;
        try {
            const response = await api.fetchRecurrencyPattern(data.data);
            item = new RecurrencyPattern(response.data);
        } catch (error) {
            item = null;
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('rec_pattern', { ns: 'recurrency' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return item;
    },
    // int rpID
    async loadRecurrencyOverPeriod(context, data) {
        let tempList = [];
        try {
            await api.fetchRecurrencyOverPeriod(data.data).then((response) => {
                tempList = response.data.Items?.map((x) => new RecurrencyView(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('rec_pattern', { ns: 'recurrency' })
                }),
                error
            );
        }
        return tempList;
    }
};


export default actions;

