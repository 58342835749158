<template>
    <b-modal
        id="modal-investquantity"
        size="lg"
        ref="modal"
        :title="this.formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
    >
        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
            {{ alertText }}
        </b-alert>

        <div>
            <b-tabs card>
                <b-card>
                    <b-tab :title="$t('com_detail', { ns: 'common' })">
                        <b-card-text>
                            <b-row>
                                <b-col sm="3">
                                    <label
                                        >{{
                                            $t('com_naam', { ns: 'common' })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <b-form-input
                                        v-model="localItem.naam"
                                        :placeholder="
                                            $t('com_naam', { ns: 'common' })
                                        "
                                        @change="setChanged"
                                    ></b-form-input>
                                </b-col>
                                <b-col sm="3">
                                    <label
                                        >{{
                                            $t('an_grootheid', {
                                                ns: 'analytics'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <lookup-combo
                                        v-model="localItem.grootheidid"
                                        v-bind:combotype="lctypeQuantity"
                                        :disabled="!editAllowed"
                                        @change="setChanged"
                                    ></lookup-combo>
                                </b-col>

                                <b-col sm="3">
                                    <label
                                        >{{
                                            $t('prod_apparaat', {
                                                ns: 'production'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <lookup-combo
                                        v-model="localItem.uitvoerderID"
                                        v-bind:combotype="lctypeExecutor"
                                        :disabled="!editAllowed"
                                        @change="setChanged"
                                    ></lookup-combo>
                                </b-col>
                            </b-row>
                            <hr />
                            <b-row>
                                <b-col sm="3">
                                    <label
                                        >{{
                                            $t('com_actie', { ns: 'common' })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <b-form-input
                                        v-model="localItem.actie"
                                        @change="setChanged"
                                        :placeholder="
                                            $t('an_verwijsbeschrijving', {
                                                ns: 'analytics'
                                            })
                                        "
                                    ></b-form-input>
                                </b-col>
                                <b-form-textarea
                                    v-model="localItem.uitleg"
                                    :placeholder="
                                        $t('com_opmerking', { ns: 'common' })
                                    "
                                    rows="3"
                                    max-rows="6"
                                    @change="setChanged"
                                ></b-form-textarea>
                            </b-row>
                        </b-card-text>
                    </b-tab>
                    <b-tab v-if="showNorms" @click="loadNormen()" lazy>
                        <template #title>
                            <b-spinner
                                v-if="showSpinner"
                                type="border"
                                small
                            ></b-spinner>
                            {{ $t('an_normen', { ns: 'analytics' }) }}
                        </template>
                        <b-card-text>
                            <b-button
                                v-if="checkRight('AnalyticsConfigure')"
                                size="sm"
                                @click="createNewItem()"
                                class="bgc_aux_normal"
                                ><font-awesome-icon
                                    icon="folder-plus"
                                    size="1x"
                                />
                            </b-button>
                            <b-table
                                striped
                                responsive
                                :fields="detailNormFields"
                                :items="normenLijst"
                                stacked="md"
                                show-empty
                                :empty-text="
                                    $t('com_geen_data', { ns: 'common' })
                                "
                                small
                            >
                                <template v-slot:cell(actions)="row">
                                    <div class="form-inline">
                                        <b-button
                                            v-if="
                                                checkRight('AnalyticsConfigure')
                                            "
                                            size="sm"
                                            @click="bewerkItem(row.item)"
                                            class="bgc_aux_warning"
                                            ><font-awesome-icon
                                                icon="edit"
                                                size="1x"
                                            />
                                        </b-button>
                                        <b-button
                                            v-if="
                                                checkRight('AnalyticsConfigure')
                                            "
                                            size="sm"
                                            @click="verwijderItem(row.item)"
                                            class="bgc_aux_alarm"
                                            ><font-awesome-icon
                                                icon="trash-alt"
                                                size="1x"
                                            />
                                        </b-button>
                                    </div>
                                </template>
                            </b-table>
                        </b-card-text>
                    </b-tab>
                    
                    <b-tab
                        v-if="localItem.id > 0"
                        :title="$t('com_bijlagen', { ns: 'common' })"
                        v-bind:key="3"
                        @click="loadAttachments()"
                        lazy
                    >
                        <b-card-text>
                            <file-upload
                                :attID="localItem.id"
                                :attType="attachmentType"
                                @input="loadAttachments"
                                @queue="setQueuedAmount"
                            />
                            <b-table
                                :items="attachmentLijst"
                                :fields="visibleFields"
                                stacked="md"
                                show-empty
                                :empty-text="
                                    $t('com_geen_data', { ns: 'common' })
                                "
                                small
                                striped
                            >
                                <template v-slot:cell(actions)="row">
                                    <b-button
                                        size="sm"
                                        @click="showAttachment(row.item)"
                                        class="bgc_aux_normal"
                                        ><font-awesome-icon
                                            icon="edit"
                                            size="1x"
                                        />
                                    </b-button>
                                    <b-button
                                        size="sm"
                                        @click="verwijderAttachment(row.item)"
                                        class="bgc_aux_alarm"
                                        ><font-awesome-icon
                                            icon="trash-alt"
                                            size="1x"
                                        />
                                    </b-button>
                                </template>

                                <template v-slot:row-details> </template>
                            </b-table>
                        </b-card-text>
                    </b-tab>
                </b-card>
            </b-tabs>
        </div>
        <modal-norm
            v-if="detailsVisible"
            v-model="detailsVisible"
            v-bind:inItem="selectednorm"
            v-bind:idIQ="inItem.id"
            @forcerefresh="doRefresh"
            @hide="reset"
        />
    </b-modal>
</template>

<script>
import { InvestigationQuantity } from '../../models/Anayltics';
import { Permission } from '../../models/Permissions';
import Helper from '../../models/Helper';
import LookupCombo from './LookupCombo.vue';
import ModalNorm from './ModalNorm.vue';
import { loadList, loadItem } from './utils';
import { AttachmentInfo } from '@/models/PlusProduction';
import { FileUpload } from '../components';
export default {
    name: 'ModalInvestQuantity',
    data: function () {
        return {
            lctypeExecutor: window.constants.LCA_EXECUTOR,
            lctypeQuantity: window.constants.LCA_QUANTITY,
            attachmentType: window.constants.ATT_INVESTIGATIONQUANTITY,
            queuedAmount: 0,
            attachmentLijst: [], // as Array<AttachmentInfo>,
            strDeleteAttachment: this.$t('com_delete', {
                ns: 'common',
                val: this.$t('com_bijlage', { ns: 'common' })
            }),
            showDismissibleAlert: false,
            alertText: '',
            isChanged: false,
            locItem: null,
            showSpinner: false,
            normList: [],
            editAllowed: true,
            detailsVisible: false,
            forcerefresh: false,
            selectednorm: null,
            verwijderString: this.$t('an_warnverwijdernorm', { ns: 'analytics' })
        };
    },
    model: {
        prop: 'visible',
        event: 'changed'
    },
    props: {
        inItem: {
            type: InvestigationQuantity,
            required: false
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    components: {
        LookupCombo,
        FileUpload,
        ModalNorm
    },
    watch: {},
    computed: {
        detailNormFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                {
                    key: 'meetplekNaam',
                    label: this.$t('an_meetplaats', { ns: 'analytics' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'normwaarde',
                    label: this.$t('an_norm', { ns: 'analytics' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'eenheidNaam',
                    label: this.$t('com_unit', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'formuleDisplay',
                    label: this.$t('an_grenzen', { ns: 'analytics' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        formtitle() {
            const low = this.$t('an_meetpunt', {
                ns: 'analytics'
            }).toLowerCase();
            return this.showNorms
                ? this.$t('com_edit', {
                      ns: 'common',
                      val: low
                  })
                : this.$t('com_makenew', {
                      ns: 'common',
                      val: low
                  });
        },
        localItem() {
            return this.locItem;
        },
        magEdit() {
            return !(this.locItem.typenaam && this.locItem.veldid);
        },
        showNorms() {
            return !(this.inItem == undefined);
        },
        normenLijst() {
            return this.normList;
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                { key: 'naam', label: 'Naam', sortable: true, visible: true },
                {
                    key: 'mimetype',
                    label: 'Type',
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        }
    },
    methods: {
        reset() {
            this.detailsVisible = false;
        },
        async doRefresh() {
            this.forcerefresh = true;
            await this.loadNormen();
        },
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        hide() {
            this.$emit('hide', this.locItem);
        },
        setChanged() {
            this.isChanged = true;
        },
        async loadNormen() {
            this.showSpinner = true;
            const lijst = await loadList(
                'analytics/getNormLijst',
                'analytics/loadNormenLijst',
                null,
                this.forcerefresh,
                this.$t
            );
            this.normList = lijst.filter(
                (x) => x.onderzoeksgrootheidID == this.localItem.id
            );
            this.forcerefresh = false;
            this.showSpinner = false;
        },
        createNewItem() {
            this.selectednorm = null;
            this.detailsVisible = !this.detailsVisible;
        },
        async bewerkItem(row) {
            this.selectednorm = await loadItem(
                'analytics/loadNorm',
                {
                    entityObjectID: row.meetplekID,
                    invQuantityID: row.onderzoeksgrootheidID
                },
                this.$t
            );
            this.detailsVisible = !this.detailsVisible;
        },
        async verwijderItem(row) {
            const bericht = Helper.stringformat(
                this.verwijderString,
                row.meetplekNaam
            );
            const doClose = await this.$root.showConfirmationBox(bericht);
            if (doClose) {
                await loadItem(
                    'analytics/deleteNorm',
                    { eonormID: row.id },
                    this.$t
                );
                this.forcerefresh = true;
                await loadNormen() ;
            }
        },
        async checkFormValidity() {
            const invoerOK = true;
            const invalidBasis = this.locItem.CheckValidity(this.$t);
            if (invalidBasis) this.showAlert(invalidBasis);
            const valid =
                this.locItem != undefined && !invalidBasis && invoerOK;
            return valid;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        async handleSubmit() {
            // Exit when the form isn't valid
            if (this.isChanged) {
                if (!(await this.checkFormValidity())) {
                    return;
                }
                this.locItem = await this.saveThisInvQuantity();
            }
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-investquantity');
            });
        },
        async saveThisInvQuantity() {
            const addObject = this.locItem.SetForAdd();
            return loadItem(
                'analytics/saveInvestigationQuantity',
                addObject,
                this.$t
            );
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        },
        async loadAttachments() {
            this.attachmentLijst = await loadItem(
                'analytics/loadAttachmentInfo',
                {
                    data: {
                        id: this.locItem.id,
                        attachmentType: this.attachmentType
                    },
                    func: this.$t
                }
            );
        },
        setQueuedAmount(aant) {
            this.queuedAmount = aant;
        },
        async showAttachment(item) {
            return await loadItem(
                'analytics/loadAttachment',
                {
                    id: item.sourceID,
                    attachmentID: item.id,
                    attachmentType: this.attachmentType
                },
                this.$t
            ).then((response) => {
                const fileURL = window.URL.createObjectURL(
                    new Blob([response])
                );
                const fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', item.naam);
                document.body.appendChild(fileLink);

                fileLink.click();
            });
        },
        async verwijderAttachment(item) {
            const doClose = await this.$root.showConfirmationBox(
                this.strDeleteAttachment + item.id + ' : ' + item.naam
            );
            if (doClose) {
                await loadItem(
                    'analytics/deleteAttachment',
                    {
                        id: item.sourceID,
                        attachmentID: item.id,
                        attachmentType: this.attachmentType
                    },
                    this.$t
                );
                this.loadAttachments();
            }
        }
    },
    mounted() {},
    created() {
        if (this.inItem) {
            this.locItem = this.inItem;
        } else {
            this.locItem = new InvestigationQuantity();
        }
        this.forcerefresh = true;
    }
};
</script>

<style scoped></style>

