<template>
    <b-container fluid style="margin-top: 60px">
        <h1>
            {{
                $t('com_reportfor', {
                    ns: 'common',
                    val: $t('com_trackentrace', {
                        ns: 'common'
                    })
                })
            }}
        </h1>
        <b-row>
            <b-col sm="12">
                <b-form-radio-group
                    v-model="selectedRadio"
                    :options="optionsRadio"
                    value-field="item"
                    text-field="name"
                    @input="clearOutput()"
                ></b-form-radio-group>
            </b-col>
        </b-row>
        <b-row v-if="selectedRadio == 'periode'">
            <b-col sm="9">
                <period-selector
                    v-model="selectDays"
                    v-bind:periodDays="periodeDagen"
                    @change="setNewDates"
                />
            </b-col>
            <b-col sm="1">
                <b-row>
                    <b-col sm="12">
                        <b-button
                            @click="loadData()"
                            class="bgc_aux_normal"
                            v-b-tooltip.hover
                            :title="$t('com_showdata', { ns: 'common' })"
                            ><font-awesome-icon icon="arrow-down" size="1x" />
                        </b-button>
                    </b-col>
                </b-row>
            </b-col>
            <b-col sm="2">
                <b-row>
                    <b-col sm="11">
                        <div class="form-inline">
                            <print-to-pdf
                                v-bind:titel="reportTitle"
                                v-bind:filenaam="reportFileName"
                                v-bind:reportList="reportList"
                            />
                            <vue-json-to-csv
                                :json-data="reportList"
                                :csv-title="reportFileName"
                                :separator="';'"
                            >
                                <b-button
                                    class="bgc_aux_normal"
                                    v-b-tooltip.hover
                                    :title="
                                        $t('com_downloadas', {
                                            ns: 'common',
                                            val: 'CSV'
                                        })
                                    "
                                >
                                    <font-awesome-icon
                                        icon="file-csv"
                                        size="1x"
                                    />
                                </b-button>
                            </vue-json-to-csv>
                        </div>
                    </b-col>
                    <b-col sm="1"> </b-col>
                </b-row>
            </b-col>
        </b-row>

        <b-row v-if="selectedRadio == 'merknummer'">
            <b-col sm="12">
                <div class="form-inline">
                    <label>
                        {{
                            $t('com_search', {
                                ns: 'common'
                            })
                        }}&nbsp;
                        {{
                            $t('com_mark', {
                                ns: 'common'
                            })
                        }}:
                    </label>
                    <b-form-input
                        v-if="!isVersion10"
                        :placeholder="$t('com_letters', { ns: 'common' })"
                        v-model="selectLetters"
                    ></b-form-input>
                    <b-form-input
                        v-if="!isVersion10"
                        :placeholder="$t('com_nummer', { ns: 'common' })"
                        type="number"
                        step="1"
                        v-model.number="selectNummers"
                    ></b-form-input>
                    <b-form-input
                        v-if="isVersion10"
                        :placeholder="$t('tien_serial', { ns: 'tien' })"
                        v-model="selectSerial"
                    ></b-form-input>
                    <b-button @click="loadData()" class="bgc_aux_normal"
                        ><font-awesome-icon
                            icon="arrow-down"
                            size="1x"
                        />&nbsp;{{ $t('com_nummer', { ns: 'common' }) }}
                    </b-button>
                </div>
            </b-col>
        </b-row>
        <b-row v-if="selectedRadio == 'lot' || selectedRadio == 'bakgroep'">
            <b-col sm="12">
                <div class="form-inline">
                    <label>
                        {{
                            $t('com_search', {
                                ns: 'common'
                            })
                        }}&nbsp;
                        {{
                            $t('com_batchnumber', {
                                ns: 'common'
                            })
                        }}::
                    </label>
                    <b-form-input
                        :placeholder="
                            $t('com_batchnumber', {
                                ns: 'common'
                            })
                        "
                        v-model="lotNumber"
                    ></b-form-input>
                    <b-button
                        @click="loadData()"
                        class="bgc_aux_normal"
                        v-b-tooltip.hover
                        :title="$t('com_showdata', { ns: 'common' })"
                        ><font-awesome-icon icon="arrow-down" size="1x" />
                    </b-button>
                </div>
            </b-col>
        </b-row>
        <div slot="pdfdata">
            <b-row class="my-1">
                <b-col sm="12">
                    <b-row no-gutters v-if="selectedRadio == 'periode'">
                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_filter', { ns: 'common' })"
                                label-for="filter-input"
                                label-cols-sm="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-input-group size="sm">
                                    <b-form-input
                                        id="filter-input"
                                        v-model="filter"
                                        type="search"
                                        :placeholder="
                                            $t('com_typetosearch', {
                                                ns: 'common'
                                            })
                                        "
                                    ></b-form-input>

                                    <b-input-group-append>
                                        <b-button
                                            :disabled="!filter"
                                            @click="filter = ''"
                                            >{{
                                                $t('com_wis', { ns: 'common' })
                                            }}</b-button
                                        >
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>

                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_perpagina', { ns: 'common' })"
                                label-for="per-page-select"
                                label-cols-sm="6"
                                label-cols-md="4"
                                label-cols-lg="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-form-select
                                    id="per-page-select"
                                    v-model="perPage"
                                    :options="pageOptions"
                                    size="sm"
                                ></b-form-select>
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                ></b-pagination>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-table
                        striped
                        primary-key
                        :items="reportList"
                        :fields="tableFields"
                        :current-page="currentPage"
                        :per-page="perPage"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        stacked="md"
                        show-empty
                        :empty-text="$t('com_geen_data', { ns: 'common' })"
                        small
                        @filtered="onFiltered"
                    >
                    </b-table>
                    <div v-if="taskCount === 0">
                        {{ $t('com_geen_data', { ns: 'common' }) }}
                    </div>
                </b-col>
            </b-row>
        </div>
    </b-container>
</template>

<script>
import _ from 'lodash';
import PrintToPdf from './PrintToPdf.vue';
import VueJsonToCsv from 'vue-json-to-csv';
import PeriodSelector from '../components/PeriodSelector.vue';
import DateHelper from '../../models/DateHelper';
import { loadItem } from '../../models/DataHelper';
import Helper from '@/models/Helper';

export default {
    name: 'RapTrackTrace',
    data: function () {
        return {
            selectedRadio: 'periode',
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [10, 20, 40, { value: 100, text: '100' }],
            filter: null,
            filterOn: [],
            tableFields: [],
            van: null,
            tot: null,
            geraakt_teller: 1,
            selectDays: null,
            periodeDagen: 184,
            selectLetters: '',
            selectNummers: null,
            selectSerial: null,
            lotNumber: '',
            clearlist: false
            //isBusy: true
        };
    },
    components: {
        PrintToPdf,
        VueJsonToCsv,
        PeriodSelector
    },
    computed: {
        optionsRadio() {
            const fieldsInit = [
                {
                    item: 'periode',
                    name: this.$t('com_periode', { ns: 'common' }),
                    visible: true
                },
                {
                    item: 'merknummer',
                    name: this.$t('com_cheesemark', { ns: 'common' }),
                    visible: true
                },
                {
                    item: 'lot',
                    name: this.$t('com_batchnumber', { ns: 'common' }),
                    visible: true
                },
                {
                    item: 'bakgroep',
                    name: this.$t('prod_bakgroep', { ns: 'production' }),
                    visible: !this.isVersion10
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        isVersion10() {
            return (
                this.$store.getters['masterdata/getPackageVersion'] ==
                window.constants.VERSION10
            );
        },
        reportList() {
            // eslint-disable-next-line no-unused-vars
            const x = this.geraakt_teller;
            let list = [];
            if (this.clearlist) {
                this.clearOutput();
            } else {
                list = this.$store.getters['rapportage/getReportItems'];
                list = Helper.removeProperty(list, 'id');
                list = DateHelper.convertDateInListToDateTimeString(
                    list,
                    false
                );
                list = Helper.replaceValueInObject(list, null, '');
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.tableFields = Helper.giveHeaderFields(list, this.$t);
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.totalRows = list ? list.length : 0;
            }
            return list;
        },
        reportFileName: function () {
            let textVan = this.van
                ? new Date(this.van).toLocaleDateString()
                : new Date().toLocaleDateString();
            let textTot = this.tot
                ? new Date(this.tot).toLocaleDateString()
                : new Date().toLocaleDateString();
            textVan = textVan.replaceAll('-', '');
            textTot = textTot.replaceAll('-', '');
            const result = this.van
                ? 'TrackenTrace_' + textVan + '_tot_' + textTot
                : 'Onbekend';
            return result;
        },
        reportTitle: function () {
            const result = Helper.stringformat(
                this.$t('com_reportvantot', {
                    ns: 'common'
                }),
                this.$t('com_periode', { ns: 'common' }).toLowerCase(),
                this.van,
                this.tot
            );
            return result;
        }
    },
    methods: {
        taskCount() {
            let rijen = 0;
            if (this.reportList != undefined) {
                rijen = this.reportList.length;
            }
            return rijen;
        },
        setNewDates(selDates) {
            this.van = selDates.van;
            this.tot = selDates.tot;
            this.clearOutput();
        },
        async loadData() {
            loadItem('rapportage/clearList', [], this.$t);
            switch (this.selectedRadio) {
                case 'periode': {
                    const selectionData = {
                        from: DateHelper.localDateToUTC(this.van),
                        until: DateHelper.localDateToUTC(this.tot)
                    };
                    await loadItem(
                        'rapportage/loadTrackTrace',
                        selectionData,
                        this.$t
                    );
                    break;
                }
                case 'merknummer': {
                    if (this.isVersion10) {
                        const selectionData = {
                            serialnumber: this.selectSerial
                        };
                        await loadItem(
                            'rapportage/loadTrackTraceMerk10plus',
                            selectionData,
                            this.$t
                        );
                    } else {
                        const selectionData = {
                            text: this.selectLetters,
                            number: this.selectNummers
                        };
                        await loadItem(
                            'rapportage/loadTrackTraceMerk',
                            selectionData,
                            this.$t
                        );
                    }
                    break;
                }
                case 'lot': {
                    const selectionData = {
                        lotNumber: this.lotNumber
                    };
                    await loadItem(
                        'rapportage/loadTrackTraceLot',
                        selectionData,
                        this.$t
                    );
                    break;
                }
                case 'bakgroep': {
                    const selectionData = {
                        batchgroup: this.lotNumber
                    };
                    const refreshedMarks = await loadItem(
                        'stock/loadStockItemMarksInfo',
                        selectionData,
                        this.$t
                    );
                    const x = _(refreshedMarks.data.Items.slice(0))
                        .groupBy((x) => x.startcharacters)
                        .map((value, key) => {
                            return {
                                startchar: key,
                                merkobject: value
                            };
                        })
                        .value();
                    let selData = null;
                    if (x.length > 0) {
                        // weet niet, doe maar 1
                        selData = {
                            text: x[0].merkobject[0].startcharacters,
                            number: x[0].merkobject[0].startnumber
                        };
                        await loadItem(
                            'rapportage/loadTrackTraceMerk',
                            selData,
                            this.$t
                        );
                    }
                    break;
                }
                default:
                    break;
            }
            this.geraakt_teller += 1;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        clearOutput() {
            this.clearlist = !this.clearlist;
        }
    },
    mounted: function () {
        this.totalRows = this.reportList ? this.reportList.length : 0;
        this.clearOutput();
    }
};
</script>

<style scoped></style>

