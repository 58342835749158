import { api } from '../api';
import Operator from '../../../models/Operator';
import { showBadToast, showGoodToast } from '../../errors/toast';

const actions = {
    async changePassword(context, data) {
        try {
            // eslint-disable-next-line no-unused-vars
            const response = await api.changePassword(data.data);
            showGoodToast(
                context,
                data.func('com_bevestigd', {
                    ns: 'common'
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_afgewezen', {
                    ns: 'common'
                }),
                error
            );
        }
    },
    async resetPassword(context, data) {
        try {
            // eslint-disable-next-line no-unused-vars
            const response = await api.resetPassword(data.data);
            showGoodToast(
                context,
                data.func('com_bevestigd', {
                    ns: 'common'
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_afgewezen', {
                    ns: 'common'
                }),
                error
            );
        }
    },
    async confirmEmail(context, data) {
        try {
            // eslint-disable-next-line no-unused-vars
            const response = await api.confirmPassword(data.data);
            showGoodToast(
                context,
                data.func('com_bevestigd', {
                    ns: 'common'
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_afgewezen', {
                    ns: 'common'
                }),
                error
            );
        }
    },
    async deleteUser(context, data) {
        try {
            // eslint-disable-next-line no-unused-vars
            const response = await api.deleteUser(data.data);
            showGoodToast(
                context,
                data.func('com_verwijderd', {
                    ns: 'common',
                    val: data.func('com_user', { ns: 'common' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data.func('com_user', { ns: 'common' })
                }),
                error
            );
        }
    },
    async createUser(context, data) {
        try {
            const response = await api.createUser(data.data);
            const gebruiker = new Operator(response.data, data.func);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: gebruiker.displayName
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('com_user', { ns: 'common' })
                }),
                error
            );
        }
    },
    async createUserGroup(context, data) {
        try {
            await api.createUserGroup(data.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('com_groep', { ns: 'common' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('com_groep', { ns: 'common' })
                }),
                error
            );
        }
    },
    async deleteUserGroup(context, data) {
        try {
            await api.deleteUserGroup(data.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data.func('com_groep', { ns: 'common' })
                }),
                error
            );
        }
    },
    async deleteUserFromGroup(context, data) {
        try {
            await api.deleteUserFromGroup(data.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data.func('com_lid', { ns: 'common' })
                })
            );
        }
    },
    async saveUserGroupPermissions(context, data) {
        try {
            await api.saveUserGroupPermissions(data.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('com_permissies', { ns: 'common' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('com_permissies', { ns: 'common' })
                }),
                error
            );
        }
    },
    async addUserToGroup(context, data) {
        try {
            // eslint-disable-next-line no-unused-vars
            const response = await api.addUserToGroup(data.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('com_lid', { ns: 'common' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('com_lid', { ns: 'common' })
                }),
                error
            );
        }
    }
};

export default actions;

