<template>
    <div id="app">
        <div>
            <navigation />
            <router-view class="p-3" />
        </div>
    </div>
</template>

<script>
import { Navigation } from './features/navigation';
import { i18nextPromise } from './i18n.js';

export default {
    name: 'App',
    data: function () {
        return {};
    },
    components: {
        Navigation
    },
    watch: {
        notification() {
            if (this.notification) {
                this.handleToast(
                    this.notification.type,
                    this.notification.title,
                    this.notification.message
                );
            }
        }
    },
    computed: {
        notification() {
            return this.$store.getters.flash;
        }
    },
    methods: {
        handleToast(type, title, message) {
            this.$bvToast.toast(message, {
                title: title,
                variant: type == 'error' ? 'danger' : 'success'
            });
        }
    },
    // used in combination with Suspense.
    // useful when translations are not in-memory...
    async setup() {
        await i18nextPromise;
        return {};
    }
};
</script>

<style>
#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
</style>

