<template>
    <b-modal
        id="modal-treatmentsstep"
        size="xl"
        ref="modal"
        :title="this.formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
    >
        <b-card @submit.stop.prevent="handleSubmit">
            <b-alert
                v-model="showDismissibleAlert"
                variant="danger"
                dismissible
            >
                {{ alertText }}
            </b-alert>
            <b-row>
                <b-col sm="3">
                    <label for="input_naam"
                        >{{ $t('prod_treatmentstep', { ns: 'production' }) }}:
                    </label>
                </b-col>
                <b-col sm="9">
                    <b-form-input
                        id="input_naam"
                        :placeholder="
                            $t('prod_treatmentstep', { ns: 'production' })
                        "
                        v-model="naam"
                        @change="set_changed"
                        :disabled="allowEdit"
                    ></b-form-input>
                </b-col>
            </b-row>
            <b-row v-if="needsCoatingProduct">
                <b-col sm="3">
                    <label
                        >{{ $t('prod_coating', { ns: 'production' }) }}:
                    </label>
                </b-col>
                <b-col sm="9">
                    <lookup-combo
                        v-model="productID"
                        v-bind:combotype="lctypeProduct"
                        v-bind:discrim="discrimCoating"
                        :showfilter="true"
                        @change="set_changed"
                    ></lookup-combo>
                </b-col>
            </b-row>
            <b-row>
                <b-form-textarea
                    id="bakopmerking"
                    v-model="opmerking"
                    :placeholder="$t('com_opmerking', { ns: 'common' })"
                    rows="3"
                    max-rows="6"
                    @change="set_changed"
                ></b-form-textarea>
            </b-row>
        </b-card>
    </b-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { VueExtension } from '../../models/VueExtension';
import { TreatmentStep } from '@/models/CheeseTreatment';
import LookupCombo from '../components/LookupCombo.vue';
import PRODCAT_ENUM from '@/models/ProductCategorie';

export default defineComponent({
    name: 'TreatmentStepModal',
    data: function () {
        return {
            lctypeProduct: window.constants.LCPRODUCT,
            showDismissibleAlert: false,
            alertText: '',
            discrimCoating: PRODCAT_ENUM.Kaasdekmiddel.toString(),
            refreshedStep: {} as TreatmentStep,
            productID: -1,
            naam: null,
            opmerking: null,
            localChanged: false,
            strOpschuiven: this.$t('prod_warntreatmentnotintime', {
                ns: 'production'
            })
        };
    },
    model: {
        prop: 'visible',
        event: 'input'
    },
    props: {
        treatmentStepId: {
            type: Number,
            required: true
        },
        treatmentStep: {
            type: TreatmentStep,
            required: true
        },
        treatmentStepList: {
            type: Array<TreatmentStep>,
            required: false
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    components: { LookupCombo },
    watch: {},
    computed: {
        formtitle() {
            return (
                this.$t('com_detail', { ns: 'common' }) +
                ' ' +
                this.$t('prod_treatmentstep', {
                    ns: 'production'
                }).toLowerCase()
            );
        },
        needsCoatingProduct() {
            return (
                this.refreshedStep.isNew ||
                this.refreshedStep.id == window.constants.COATING_STEPID ||
                this.refreshedStep.id > window.constants.START_SEQ
            );
        },
        baseStep() {
            return this.treatmentStep;
        },
        allowEdit() {
            return !(this.treatmentStepId < 0);
        }
    },
    methods: {
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        hide() {
            this.$emit('hide');
        },
        set_input() {
            this.refreshedStep.productID =
                this.productID > -1 ? this.productID : null;
            this.refreshedStep.naam = this.naam;
            this.refreshedStep.opmerking = this.opmerking;
            this.$emit('change', this.refreshedStep);
        },
        set_changed() {
            this.localChanged = true;
        },
        checkFormValidity() {
            if (this.refreshedStep.id == -1) {
                // check of naam in lijst zit
                if (this.treatmentStepList) {
                    const o = this.treatmentStepList.find(
                        (x) => x.naam == this.naam
                    );
                    if (o) {
                        this.showAlert(
                            this.$t('com_doubleinlist', {
                                ns: 'common',
                                val: this.naam
                            })
                        );
                        return false;
                    }
                }
            }
            return true;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        async handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return;
            }
            if (this.localChanged) {
                this.set_input();
            }
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-treatmentsstep');
            });
        }
    },
    mounted() {},
    created() {
        this.refreshedStep =
            this.treatmentStepId < 0 ? new TreatmentStep() : this.treatmentStep;
        this.naam = this.treatmentStep.naam;
        this.productID = this.treatmentStep.productID;
        this.opmerking = this.treatmentStep.opmerking;
    }
});
</script>

<style scoped></style>

