<template>
    <b-card>
        <b-form-group>
            <b-form-radio
                v-model="selected"
                :value="constDaily"
                @change="setChanged"
            >
                <div class="form-inline">
                    {{ strOccurs1 }} &nbsp;
                    <b-form-input
                        id="input-interval"
                        type="number"
                        step="1"
                        style="width: 25%"
                        v-model.number="localInterval"
                        :disabled="!editAllowed"
                        @change="setChanged"
                    ></b-form-input>
                    &nbsp;{{ strOccurs2 }}
                </div>
            </b-form-radio>
            <b-form-radio
                v-model="selected"
                :value="constWeekDay"
                @change="setChanged"
                >{{ strOccurs3 }}</b-form-radio
            >
        </b-form-group>
    </b-card>
</template>

<script>
import { RECURRENCY_ENUM } from '../../models/Recurrency';
export default {
    name: 'CardDaily',
    data() {
        return {
            selected: RECURRENCY_ENUM.Daily,
            constWeekDay: RECURRENCY_ENUM.Weekday,
            constDaily: RECURRENCY_ENUM.Daily,
            localInterval: 1,
            strOccurs1: this.$t('rec_occursevery', { ns: 'recurrency' }),
            strOccurs2: this.$t('rec_occurs2day', { ns: 'recurrency' }),
            strOccurs3: this.$t('rec_occursweekday', { ns: 'recurrency' })
        };
    },
    components: {},
    props: {
        interval: {
            type: Number,
            default: 1
        },
        type: {
            type: Number
        },
        editAllowed: {
            type: Boolean,
            default: true
        }
    },
    computed: {},
    methods: {
        setChanged() {
            const o = {
                interval: this.localInterval,
                type: this.selected
            };
            this.$emit('changed', o);
        }
    },
    mounted() {},
    async created() {
        this.localInterval = this.interval;
        this.selected =
            this.type != RECURRENCY_ENUM.Weekday
                ? RECURRENCY_ENUM.Daily
                : this.type;
        this.selected = RECURRENCY_ENUM.Daily;
    }
};
</script>

