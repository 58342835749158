import Api from '../../../api';
import store from '../../../store';


const fetchBatchInfoOverPeriod = async data => {
    return fetchInfo('/batch/GetBatchInfoOverPeriod', data);
};

const fetchBatchInfo = async data => {
    return fetchInfo('/batch/GetBatchInfo', data);
};

const fetchBatchIngredients = async data => {
    return fetchInfo('/batch/GetBatchIngredienstByBatchID', data);
    //return fetchInfo('/batch/GetBatchIngredientsByBatchID', data);
};

const fetchBatchIngredient = async data => {
    return fetchInfo('/batch/GetBatchIngredient', data);
};

const fetchAlternativeCheeseTypes = async data => {
    return fetchInfo('/batch/GetAlternativeCheeseTypes', data);
};


const addBatchIngredients = async data => {
    return await Api(store).put('/batch/StoreBatchIngredients', data);
 };

 const deleteBatchIngredient = async data => {
    //return await Api(store).delete('/batch/RemoveBatchIngredient', data);
    return deleteInfo('/batch/RemoveBatchIngredient', data);
 };

const fetchBatch = async data => {
    return fetchInfo('/batch/GetBatch', data);
};


const deleteBatch = async data => {
    return deleteInfo('/batch/RemoveBatch', data);
};

// data = batchid, productionStarted
// output is float
const destroyBatch = async data => {
    return deleteInfo('/batch/DeleteBatch', data);
};

const addBatches = async data => {
   // return await Api(store).put('/batch/AddBatches', data);
    return storeInfo('/batch/AddBatches', data);
};

 // data = UpdateBatchModel
const updateBatch = async data => {
     return await Api(store).put('/batch/UpdateBatch', data);
 };
 
 // data = InBrineModel
 const updateBatchInBrine = async data => {
    return await Api(store).put('/batch/BatchInBrine', data);
};

const setCheeseMarks = async data => {
    return await Api(store).put('/batch/SetCheeseMarks', data);
};

// data = batchid
// output is InBrineModel
const fetchBatchInbrineAmounts = async data => {
    return fetchInfo('/batch/GetBatchInBrineAmounts', data);
};

// data = batchid
// output is InBrineModel
const fetchBatchDefaults = async data => {
    return fetchInfo('/batch/GetBatchDefaults', data);
};

// data = milkGroupTypeID,  date
// output is float
const fetchMilkusagefactor = async data => {
    return fetchInfo('/Ingredient/GetMilkusagefactor', data);
};

const fetchMilkInfoForDay = async data => {
    return fetchInfo('/batch/GetPlannedMilkTypes', data);
};

const fetchStarterSubtypesForDay = async data => {
    return fetchInfo('/batch/GetPlannedStarterSubTypes', data);
};

// data = batchID
// output is float
const fetchMilkusagefactoForBatch = async data => {
    return fetchInfo('/batch/GetMilkusagefactor', data);
};


// data = bookdate
// output is 
const fetchCheeseMarksForProdrun = async data => {
    return fetchInfo('/batch/GetBatchCheeseMarksInfo', data);
};

// data = batchID
// output is 
const fetchCheeseMarksForBatch = async data => {
    return fetchInfo('/batch/GetCheeseMarks', data);
};

// data = productID
// output is 
const fetchCheeseMarkType = async data => {
    return fetchInfo('/CheeseMark/GetCheeseMarkType', data);
};

// data = bookdate
// output is 
const fetchProdRun = async data => {
    return fetchInfo('/batch/GetProdrun', data);
};

// data =  prodrunID, starterSubtypeProductID
// output is 
const setStarterSubType = async data => {
    return storeInfo('/batch/StoreProdrunStarterSubtype', data);
};

// data = batchid
// output is 
const fetchBatchActivityLog = async data => {
    return fetchInfo('/batch/GetBatchActivityLog', data);
};

const fetchInfo = async (apicall, data) => {
    if (data != undefined) {
        return await Api(store).get(
            apicall +'?' +
                Object.keys(data)
                    .map(
                        k =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    } else {
        return await Api(store).get(apicall, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    }
};
const storeInfo = async (apicall, data) => {
    if (data != undefined) {
        return await Api(store).put(
            apicall +
                '?' +
                Object.keys(data)
                    .map(
                        (k) =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    } 
};
const deleteInfo = async (apicall, data) => {
    if (data != undefined) {
        return await Api(store).delete(
            apicall +
                '?' +
                Object.keys(data)
                    .map(
                        (k) =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    } 
};


export default {
    fetchBatch,
    deleteBatch,
    destroyBatch,
    updateBatch,
    addBatches,
    fetchProdRun,
    fetchStarterSubtypesForDay,
    setStarterSubType,
    fetchBatchInfo,
    fetchBatchInfoOverPeriod,
    fetchBatchIngredients,
    fetchBatchIngredient,
    fetchBatchDefaults,
    fetchAlternativeCheeseTypes,
    fetchMilkusagefactor,
    fetchMilkusagefactoForBatch,
    fetchMilkInfoForDay,
    addBatchIngredients,
    deleteBatchIngredient,
    updateBatchInBrine,
    fetchBatchInbrineAmounts,
    fetchCheeseMarksForProdrun,
    fetchCheeseMarksForBatch,
    fetchCheeseMarkType,
    setCheeseMarks,
    fetchBatchActivityLog
};
