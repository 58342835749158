import Helper from './Helper';
import DateHelper from './DateHelper';
import _ from 'lodash';
export {
    SimpleProduction,
    SimpleProductionElement,
    SimpleProductBatchElement,
    SimpleProductionValue,
    ProductionValueType,
    AttachmentInfo
};

class SimpleProduction {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        const tempid = data.id;
        this.id = tempid ?? -1;
        this.isNew = this.id < 0;
        this.gewijzigd = data.updated;
        this.naam = data.internalName ?? data.description;
        this.opmerking = data.remark;
        this.batch = data.batchnumber;
        this.productID = data.productID;
        this.productType = data.productType;
        this.eenheidID = data.unitID;
        this.vormID = data.shapeID;
        this.locatieID = data.stockLocationID;
        this.productiedatum = data.productionDate ?? data.bookdate;
        this.hoeveelheid = data.amount;
        this.elementcount = data.elements;
        this.elementen = data.productionElements
            ? data.productionElements.map((x) => new SimpleProductionElement(x))
            : [];
        this.bijproducten = data.productionByProducts
            ? data.productionByProducts.map(
                  (x) => new SimpleProductionElement(x)
              )
            : [];
        this.prodwaarden = data.productionValues
            ? data.productionValues.map((x) => new SimpleProductionValue(x))
            : [];
        this.attcount = data.attachments;
        this.bijlagen = data.productionValues
            ? data.productionValues.map((x) => new SimpleProductionValue(x))
            : [];
    }
    SetForSave() {
        const ProductionElements = [];
        const ProductionByProducts = [];
        const ProductionValues = [];
        for (let i = 0; i < this.elementen.length; i++) {
            const obj = this.elementen[i].SetForSave(i);
            ProductionElements.push(obj);
        }
        for (let i = 0; i < this.bijproducten.length; i++) {
            const obj = this.bijproducten[i].SetForSave(i);
            ProductionByProducts.push(obj);
        }
        for (let i = 0; i < this.prodwaarden.length; i++) {
            const obj = this.prodwaarden[i].SetForSave(i);
            if (obj) ProductionValues.push(obj);
        }

        const SimpleProduction = {
            ID: this.id,
            BatchNumber: this.batch,
            Remark: this.opmerking,
            ProductID: this.productID,
            UnitID: this.eenheidID,
            ShapeID: this.vormID,
            StockLocationID: this.locatieID,
            Amount: this.hoeveelheid,
            Updated: this.gewijzigd,
            ProductionDate: DateHelper.localDateToUTC(this.productiedatum),
            ProductionElements: ProductionElements,
            ProductionByProducts: ProductionByProducts,
            ProductionValues: ProductionValues
        };
        return SimpleProduction;
    }

    CheckValidity(func) {
        let warnText = '';
        if (this.productID < 0 && !this.naam) {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    prop: func('com_naam', { ns: 'common' }),
                    val: func('com_product', { ns: 'common' })
                })
            );
        }
        if (this.hoeveelheid == undefined) {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    prop: func('com_amount', { ns: 'common' }),
                    val: func('com_product', { ns: 'common' })
                })
            );
        }
        if (this.locatieID == undefined || this.locatieID < 0) {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('com_storagelocation', { ns: 'common' })
                })
            );
        }
        if (this.elementen.length > 0) {
            const newArr = [];
            for (let i = 0; i < this.elementen.length; i++) {
                const id = this.elementen[i].productID;
                const test = newArr.indexOf(id);
                if (test > -1) {
                    warnText = Helper.concatString(
                        warnText,
                        func('com_double', {
                            ns: 'common',
                            val: func('tien_element', { ns: 'tien' })
                        })
                    );
                    break;
                }
                newArr.push(id);
            }
        } else {
            warnText = Helper.concatString(
                warnText,
                func('tien_min1ingredient', { ns: 'tien' })
            );
        }
        if (this.bijproducten.length > 0) {
            const newArr = [];
            for (let i = 0; i < this.bijproducten.length; i++) {
                const id = this.bijproducten[i].productID;
                const test = newArr.indexOf(id);
                if (test > -1) {
                    warnText = Helper.concatString(
                        warnText,
                        func('com_double', {
                            ns: 'common',
                            val: func('tien_byproduct', { ns: 'tien' })
                        })
                    );
                    break;
                }
                newArr.push(id);
                if (this.bijproducten[i].eenheidID < 0) {
                    warnText = Helper.concatString(
                        warnText,
                        func('com_isrequired', {
                            ns: 'common',
                            val: func('tien_byproduct', { ns: 'tien' }),
                            prop: func('com_unit', { ns: 'common' })
                        })
                    );
                    break;
                }
                if (this.bijproducten[i].hoeveelheid == undefined) {
                    warnText = Helper.concatString(
                        warnText,
                        func('com_isrequired', {
                            ns: 'common',
                            val: func('tien_byproduct', { ns: 'tien' }),
                            prop: func('com_amount', { ns: 'common' })
                        })
                    );
                    break;
                }
            }
        }
        return warnText;
    }
}

class SimpleProductionElement {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        this.id = data.id;
        this.isNew = this.id == undefined || this.id < 0;
        this.productID = data.productID;
        this.productType = data.productType;
        this.voorraadID = data.stockItemID;
        this.gewijzigd = data.updated;
        this.naam = data.productName;
        this.opmerking = data.remark;
        this.batch = data.batchNumber;
        this.startmerk = data.startSerial;
        this.eindmerk = data.endSerial;
        this.eenheidID = data.unitID;
        this.vormID = data.shapeID;
        this.hoeveelheid = data.amount;
        if (this.startmerk || this.eindmerk) this.productType = -1; // workround
    }
    SetForSave(i) {
        const SimpleProduction = {
            ProductID: this.productID ?? this.id,
            BatchNumber: this.batch,
            StartSerial: this.startmerk,
            ShapeID: this.vormID,
            EndSerial: this.eindmerk,
            Remark: this.opmerking,
            UnitID: this.eenheidID,
            Amount: this.hoeveelheid
        };
        return SimpleProduction;
    }
}

class SimpleProductBatchElement {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        this.id = data.productID;
        this.isNew = this.id < 0;
        this.gewijzigd = data.lastused;
        this.naam = data.productname;
        this.batch = data.batchnumber;
        this.aantal = data.count;
    }
}

class SimpleProductionValue {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        this.id = data.id;
        this.isNew = this.id == undefined || this.id < 0;
        this.gewijzigd = data.lastused ?? data.updated;
        this.productieID = data.productionID;
        this.naam = data.description;
        this.type = data.valueType ?? data.type;
        this.janee = data.boolValue;
        this.getal = data.doubleValue;
        this.datumtijd = data.dateTimeValue;
    }
    CheckValidity(func) {
        let warnText = '';
        if (this.naam == undefined || this.naam.length < 2) {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('com_naam', { ns: 'common' })
                })
            );
        }
        return warnText;
    }
    SetForSave() {
        let convertedTijd = null;
        switch (this.type) {
            case window.constants.PV_BOOL: {
                if (this.janee == undefined) return null;
                this.janee = this.janee == 'true' || this.janee ? true : false;
                break;
            }
            case window.constants.PV_DOUBLE: {
                if (!this.getal) return null;
                break;
            }
            case window.constants.PV_DATETIME: {
                if (!this.datumtijd) this.datumtijd = new Date();
                convertedTijd = DateHelper.localDateToUTC(this.datumtijd);
                break;
            }
            default:
                return null;
        }
        const SimpleProductionValue = {
            ID: this.id,
            productionID: this.productieID,
            Description: this.naam,
            ValueType: this.type,
            BoolValue: this.janee,
            DoubleValue: this.getal,
            DateTimeValue: convertedTijd
        };
        return SimpleProductionValue;
    }
    insertNew() {
        const ProductionValueModel = {
            ID: this.id,
            Description: this.naam,
            ValueType: this.type
        };
        return ProductionValueModel;
    }
}

class ProductionValueType {
    constructor(data) {
        this.id = -1;
        this.type = 'ProductionValueType';
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        this.id = data.id;
        this.naam = data.description;
        this.isNew = this.id == undefined || this.id < 0;
    }
}

class AttachmentInfo {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        this.id = data.id;
        this.isNew = this.id == undefined || this.id < 0;
        this.gewijzigd = data.lastused;
        this.productieID = data.productionid ?? data.stockItemID; // 10plus??
        this.sourceID = data.it_id ?? data.pt_id ?? data.dn_id ?? data.iq_id ?? data.eo_id ?? data.mm_id ?? data.as_id; 
        this.naam = data.filename;
        this.mimetype = data.type;
        this.grootte = data.size;
        this.formdata = data.file;
        this.attachmentType = -1; // standaard voor 10 plus
    }

    SetForSave() {
        // todo juist invullen
        const FileUpLoadModel = new FormData();
        if (this.id > 0) {
            FileUpLoadModel.append('ID', this.id);
            if (this.attachmentType > 0)
                FileUpLoadModel.append('AttachmentType', this.attachmentType);
            FileUpLoadModel.append('File', this.formdata);
            return FileUpLoadModel;
        }
        return null;
    }
}
