<template>
    <b-modal
        id="modal-plusproduct"
        size="lg"
        ref="modal"
        :title="this.formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
        @change="change"
    >
        <b-card
            @submit.stop.prevent="handleSubmit"
            :header="$t('com_product', { ns: 'common' })"
        >
            <div>
                <b-row>
                    <b-col sm="3">
                        <label for="input-itemnaam"
                            >{{ $t('com_naam', { ns: 'common' }) }}:</label
                        >
                    </b-col>
                    <b-col sm="9">
                        <b-form-input
                            id="input-itemnaam"
                            :placeholder="$t('com_naam', { ns: 'common' })"
                            v-model="localItem.naam"
                            @change="setChanged"
                        ></b-form-input>
                        <b-form-checkbox
                            v-model="localItem.productType"
                            value="-2"
                            unchecked-value="0"
                        >
                            {{ strIsKaas }}
                        </b-form-checkbox>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="3">
                        <label for="input-itemiid"
                            >{{
                                $t('com_internalid', { ns: 'common' })
                            }}:</label
                        >
                    </b-col>
                    <b-col sm="9">
                        <b-form-input
                            id="input-itemiid"
                            :placeholder="
                                $t('com_internalid', { ns: 'common' })
                            "
                            v-model="localItem.internID"
                            @change="setChanged"
                        ></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="3">
                        <label for="input-itemremark"
                            >{{ $t('com_opmerking', { ns: 'common' }) }}:</label
                        >
                    </b-col>
                    <b-col sm="9">
                        <b-form-textarea
                            id="input-itemremark"
                            :placeholder="$t('com_opmerking', { ns: 'common' })"
                            v-model="localItem.opmerking"
                            @change="setChanged"
                            rows="3"
                        ></b-form-textarea>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="3">
                        <label>{{ $t('com_unit', { ns: 'common' }) }}: </label>
                    </b-col>
                    <b-col sm="9">
                        <lookup-combo
                            v-model="localItem.eenheid"
                            v-bind:combotype="lctypeUnits"
                            :disabled="setDisabled"
                        ></lookup-combo>
                        <b-form-checkbox
                            v-model="localItem.productType"
                            value="-1"
                            unchecked-value="0"
                        >
                            {{ $t('com_cheesemark', { ns: 'common' }) }}
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </div>
            <b-alert
                v-model="showDismissibleAlert"
                variant="danger"
                dismissible
            >
                {{ alertText }}
            </b-alert>
        </b-card>
    </b-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { VueExtension } from '../../models/VueExtension';
import { LookupCombo, SimpelChart } from '../components';
import { loadItem, loadList, loadGetItem } from '../../models/DataHelper';
import { Product } from '../../models/Product';

export default defineComponent({
    name: 'PLusProductModal',
    data: function () {
        return {
            showDismissibleAlert: false,
            alertText: '',
            lctypeUnits: window.constants.LCPERUNITS, // 10+ mag alles
            lctypeMarktype: window.constants.LCMARKTYPE,
            lctypeFatclass: window.constants.LCFATCLASS,
            perUnitsId: 0,
            localNaam: '',
            opmerking: '',
            lokaalItem: ({} as Product) , //s|| null,
            theID: -1,
            disabled: false,
            strNewSimpleProduct: this.$t('com_makenew', {
                ns: 'common',
                val: this.$t('com_product', { ns: 'common' })
            }),
            strEditSimpleProduct: this.$t('com_edit', {
                ns: 'common',
                val: this.$t('com_product', { ns: 'common' })
            }),
            strIsKaas: 'Is ' + this.$t('com_kaas', { ns: 'common' })
        };
    },
    model: {
        prop: 'visible',
        event: 'change'
    },
    props: {
        inItem: {
            type: Product,
            required: true
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    components: {
        LookupCombo
    },
    watch: {},
    computed: {
        setDisabled() {
            return this.disabled;
        },
        formtitle() {
            return this.inItem.isNew
                ? this.strNewSimpleProduct
                : this.strEditSimpleProduct;
        },
        localItem(): Product {
            return this.inItem;
        }
    },
    methods: {
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        hide() {
            this.$emit('hide');
        },
        change(visible) {
            this.setChanged();
            this.$emit('change', visible);
        },
        setChanged() {
            this.localItem.isChanged = true;
        },
        checkFormValidity() {
            const invalidBasis = this.localItem.CheckValidity(this.$t);
            if (invalidBasis) this.showAlert(invalidBasis);
            const valid = this.localItem != undefined && !invalidBasis;
            return valid;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return;
            }
            this.saveThisProduct();
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-plusproduct');
            });
        },
        async saveThisProduct() {
            this.localItem.isChanged = true;
            const model = this.localItem.SetForSave();
            await loadItem('tienplus/saveSimpleProduct', model, this.$t);
            loadItem('components/loadSimpleProducts', model);
        }
    },
    mounted() {},
    created() {}
});
</script>

<style scoped></style>
