import Helper from './Helper';
import DateHelper from './DateHelper';
import { INGRCAT_ENUM } from './IngredientCategorie';
import _ from 'lodash';
export {
    RecComposition,
    RecNorm,
    Recept,
    RecProduction,
    RecIngredient,
    SimpleRecipe,
    SimpleRecipeElement,
    SeasonValues,
    SeasonValue
};

class SeasonValues {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        const tempid = data.milkGroupTypeID;
        this.id = tempid;
        this.elementen = data.seasonValues
            ? data.seasonValues.map((x) => new SeasonValue(x))
            : [];
    }
    fillEmpty(data) {
        this.id = data.milkGroupTypeID;
        const arr = [];
        for (let i = 0; i < 12; i++) {
            const sv = new SeasonValue();
            sv.maand = i + 1;
            arr.push(sv);
        }
        this.elementen = arr;
    }
    SetForSave() {
        const UpdateSeasonValueElements = [];
        for (let i = 0; i < this.elementen.length; i++) {
            const obj = this.elementen[i].SetForSave(i);
            UpdateSeasonValueElements.push(obj);
        }

        const UpdateSeasonValuesModel = {
            MilkGroupTypeID: this.id,
            SeasonValues: UpdateSeasonValueElements
        };
        return UpdateSeasonValuesModel;
    }

    CheckValidity(func) {
        let warnText = '';
        if (this.id < 0) {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('com_naam', { ns: 'common' })
                })
            );
        }
        if (this.elementen.length > 0) {
            const newArr = [];
            for (let i = 0; i < this.elementen.length; i++) {
                const id = this.elementen[i].id;
                const test = newArr.indexOf(id);
                if (test > -1) {
                    warnText = Helper.concatString(
                        warnText,
                        func('com_double', {
                            ns: 'common',
                            val: func('tien_element', { ns: 'tien' })
                        })
                    );
                    break;
                }
                newArr.push(id);
            }
        }
        return warnText;
    }
}

class SeasonValue {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        this.id = data.productID;
        this.isNew = this.id < 0;
        this.factor = data.milkUsageFactor;
        this.eiwit = data.protein;
        this.vet = data.fat;
        this.maand = data.month;
    }
    MaandNaam(func) {
        switch (this.maand) {
            case 1:
                return func('rec_januari', { ns: 'recurrency' });
            case 2:
                return func('rec_februari', { ns: 'recurrency' });
            case 3:
                return func('rec_maart', { ns: 'recurrency' });
            case 4:
                return func('rec_april', { ns: 'recurrency' });
            case 5:
                return func('rec_mei', { ns: 'recurrency' });
            case 6:
                return func('rec_juni', { ns: 'recurrency' });
            case 7:
                return func('rec_juli', { ns: 'recurrency' });
            case 8:
                return func('rec_augustus', { ns: 'recurrency' });
            case 9:
                return func('rec_september', { ns: 'recurrency' });
            case 10:
                return func('rec_oktober', { ns: 'recurrency' });
            case 11:
                return func('rec_november', { ns: 'recurrency' });
            case 12:
                return func('rec_december', { ns: 'recurrency' });
            default:
                return func('prod_onbekend', { ns: 'production' });
        }
    }

    CheckValidity(func) {
        let warnText = '';
        if (this.factor)
            if (this.factor < 0.4 || this.factor > 1.5)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop: func('prod_melkfactor', { ns: 'production' }),
                        val: this.factor
                    })
                );
        if (this.eiwit)
            if (this.eiwit < 0.4 || this.eiwit > 8.5)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop: func('an_eiwit', { ns: 'analytics' }),
                        val: this.eiwit
                    })
                );
        if (this.vet)
            if (this.vet < 0.2 || this.vet > 1.5)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop: func('an_vet', { ns: 'analytics' }),
                        val: this.vet
                    })
                );
        return warnText;
    }
    SetForSave(i) {
        const UpdateSeasonValueModel = {
            MilkUsageFactor: this.factor,
            Protein: this.eiwit,
            Fat: this.vet,
            Month: this.maand
        };
        return UpdateSeasonValueModel;
    }
}
class SimpleRecipe {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        const tempid = data.productID ?? data.id;
        this.id = tempid ?? -1;
        this.isNew = this.id < 0;
        this.gewijzigd = data.updated;
        this.naam = data.internalName ?? data.description;
        this.opmerking = data.remark;
        this.elementcount = data.elements;
        this.elementen = data.recipeElements
            ? data.recipeElements.map((x) => new SimpleRecipeElement(x))
            : [];
        this.bijproducten = data.recipeByProducts
            ? data.recipeByProducts.map((x) => new SimpleRecipeElement(x))
            : [];
    }
    SetForSave() {
        const RecipeElements = [];
        const RecipeByProducts = [];
        for (let i = 0; i < this.elementen.length; i++) {
            const obj = this.elementen[i].SetForSave(i);
            RecipeElements.push(obj);
        }
        for (let i = 0; i < this.bijproducten.length; i++) {
            const obj = this.bijproducten[i].SetForSave(i);
            RecipeByProducts.push(obj);
        }

        const SimpleRecipe = {
            ID: this.id,
            InternalName: this.naam,
            Remark: this.opmerking,
            RecipeElements: RecipeElements,
            RecipeByProducts: RecipeByProducts
        };
        return SimpleRecipe;
    }

    CheckValidity(func) {
        let warnText = '';
        if (this.id < 0 && !this.naam) {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('com_naam', { ns: 'common' })
                })
            );
        }
        if (this.elementen.length > 0) {
            const newArr = [];
            for (let i = 0; i < this.elementen.length; i++) {
                const id = this.elementen[i].id;
                const test = newArr.indexOf(id);
                if (test > -1) {
                    warnText = Helper.concatString(
                        warnText,
                        func('com_double', {
                            ns: 'common',
                            val: func('tien_element', { ns: 'tien' })
                        })
                    );
                    break;
                }
                newArr.push(id);
            }
        }
        return warnText;
    }
}

class SimpleRecipeElement {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        this.id = data.productID;
        this.isNew = this.id < 0;
        this.gewijzigd = data.updated;
        this.productType = data.productType;
        this.naam = data.productName;
        this.opmerking = data.remark;
        this.volgorde = data.sortOrder;
        this.richting = data.direction;
    }
    SetForSave(i) {
        const SimpleRecipe = {
            ProductID: this.id,
            ProductName: this.naam,
            Remark: this.opmerking,
            SortOrder: i
        };
        return SimpleRecipe;
    }
}

class Recept {
    /**
     * Assign the data to a new instance of the Product
     *
     * @param data
     */
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false,
                enumerable: true
            },
            gewijzigd: {
                value: data.created,
                writable: true,
                enumerable: true
            },
            productID: {
                value: data.productID,
                writable: true,
                enumerable: true
            },
            reccompID: {
                value: data.drCompositionID,
                writable: true,
                enumerable: true
            },
            recnormID: {
                value: data.drNormsID,
                writable: true,
                enumerable: true
            },
            recproductionID: {
                value: data.drProductionID,
                writable: true,
                enumerable: true
            },
            kaasnaam: {
                value: data.description ?? data.internalName,
                writable: true,
                enumerable: true
            },
            kaasinternID: {
                value: data.internalid ?? data.internalID,
                writable: true,
                enumerable: true
            },
            alert: {
                value: data.alert,
                writable: true,
                enumerable: true
            },
            opmerking: {
                value: data.remark,
                writable: true,
                enumerable: true
            },
            vanaf: {
                value:
                    DateHelper.convertedToDate(
                        data.validfrom ?? data.validFrom
                    ) ?? new Date(),
                writable: true,
                enumerable: true
            },
            tot: {
                value: DateHelper.convertedToDate(
                    data.validuntil ?? data.validUntil
                ),
                writable: true,
                enumerable: true
            },
            restWeiWrongel1: {
                type: Number,
                value: data.firstWheyCurdLeft,
                writable: true,
                enumerable: true
            },
            restWeiWrongel1Perc: {
                type: Number,
                value: data.rww1perc,
                writable: true,
                enumerable: true
            },
            percentageWater1: {
                value: data.water1perc,
                writable: true,
                enumerable: true
            },
            restWeiWrongel2Perc: {
                type: Number,
                value: data.rww2perc,
                writable: true,
                enumerable: true
            },
            percentageWater2: {
                type: Number,
                value: data.water2perc,
                writable: true,
                enumerable: true
            },
            stremTemp: {
                type: Number,
                value: data.stremTemp,
                writable: true,
                enumerable: true
            },
            nawarmTemp: {
                type: Number,
                value: data.nawarmTemp,
                writable: true,
                enumerable: true
            },
            tussenTemp: {
                type: Number,
                value: data.tussenTemp,
                writable: true,
                enumerable: true
            },
            melkgebruik: {
                value: data.milkUsage,
                writable: true,
                enumerable: true
            },
            waterTemp: {
                value: data.waterTemp,
                writable: true,
                enumerable: true
            },
            minimumVulling: {
                value: data.minOstFill,
                writable: true,
                enumerable: true
            },
            // alleen de infoview
            pekelDuur: {
                value: data.hoursinbrine,
                writable: true,
                enumerable: true
            },
            progWB: {
                value: data.ostprogram,
                writable: true,
                enumerable: true
            },
            // lijsten
            recIngredienten: {
                value: data.recIngredients
                    ? data.recIngredients.map((x) => new RecIngredient(x))
                    : [],
                writable: true,
                enumerable: true
            },
            recNorm: {
                value: data.norms ? new RecNorm(data.norms) : null,
                writable: true,
                enumerable: true
            },
            recComposition: {
                value: data.composition
                    ? new RecComposition(data.composition)
                    : null,
                writable: true,
                enumerable: true
            },
            recProduction: {
                value: data.production
                    ? new RecProduction(data.production)
                    : null,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id < 0;
    }

    SetForSave() {
        // tijdelijk

        if (this.rww1perc != undefined) {
            let recMelk = 0;
            if (this.recIngredienten.length > 0) {
                const recMelkLijst = this.recIngredienten.filter(
                    (x) =>
                        x.ingredientCatID == INGRCAT_ENUM.Melk &&
                        x.isDeleted == false
                );
                const tempArr = recMelkLijst.map(
                    (x) => x.hoeveelheid * x.factor
                );
                recMelk = _.sum(tempArr);
            }
            if (recMelk > 0 && this.rww1perc != undefined) {
                this.firstWheyCurdLeft = (this.rww1perc / 100) * recMelk;
            }
        }
        // nieuw
        const norms = this.recNorm.SetForSave();
        const production = this.recProduction.SetForSave();
        const composition = this.recComposition.SetForSave();
        const recIngredients = [];
        for (let i = 0; i < this.recIngredienten.length; i++) {
            const obj = this.recIngredienten[i].SetForSave();
            recIngredients.push(obj);
        }

        const RecipeComplete = {
            ID: this.id,
            MilkUsage: this.melkgebruik,
            ProductID: this.productID,
            // Parentid:this.DrParentid,
            // ValidUntil:this.DrValiduntil,
            ValidFrom: this.vanaf,
            FirstWheyCurdLeft: this.restWeiWrongel1
                ? this.restWeiWrongel1
                : null,
            StremTemp: this.stremTemp,
            NawarmTemp: this.nawarmTemp,
            TussenTemp: this.tussenTemp,
            WaterTemp: this.waterTemp,
            Remark: this.opmerking ? this.opmerking : null,
            MinOstFill: this.minimumVulling ? this.minimumVulling : null,

            Rww1perc: this.restWeiWrongel1Perc
                ? this.restWeiWrongel1Perc
                : null,
            Water1perc: this.percentageWater1 ? this.percentageWater1 : null,

            Rww2perc: this.restWeiWrongel2Perc
                ? this.restWeiWrongel2Perc
                : null,
            Water2perc: this.percentageWater2 ? this.percentageWater2 : null,
            Norms: norms,
            Production: production,
            Composition: composition,
            RecIngredients: recIngredients
        };
        return RecipeComplete;
    }
    CheckValidity(func) {
        let warnText = '';
        if (!this.productID)
            warnText = Helper.concatString(
                warnText,
                func('prod_xselection', {
                    ns: 'production',
                    val: func('com_kaassoort', { ns: 'common' })
                })
            );
        if (this.stremTemp)
            if (
                this.stremTemp < window.constants.MIN_TEMP_STREM ||
                this.stremTemp > window.constants.MAX_TEMP_STREM
            )
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop: func('com_temperatuur', { ns: 'common' }),
                        val: this.stremTemp
                    })
                );
        if (this.nawarmTemp)
            if (
                this.nawarmTemp < window.constants.MIN_TEMP_NAWARM ||
                this.nawarmTemp > window.constants.MAX_TEMP_NAWARM
            )
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop: func('com_temperatuur', { ns: 'common' }),
                        val: this.nawarmTempTemp
                    })
                );
        if (this.restWeiWrongel1)
            if (this.restWeiWrongel1 < 5 || this.restWeiWrongel1 > 10000)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop: func('prod_rww', { ns: 'production' }),
                        val: this.restWeiWrongel1
                    })
                );
        if (this.restWeiWrongel1Perc)
            if (this.restWeiWrongel1Perc < 0 || this.restWeiWrongel1Perc > 90)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop: func('prod_rww', { ns: 'production' }) + '%',
                        val: this.restWeiWrongel1Perc
                    })
                );
        if (this.waterTemp)
            if (
                this.waterTemp < window.constants.MIN_TEMP_WASWATER ||
                this.waterTemp > window.constants.MAX_TEMP_WASWATER
            )
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop: func('prod_waswater', { ns: 'production' }),
                        val: this.waterTemp
                    })
                );
        if (this.percentageWater1)
            if (this.percentageWater1 < 0 || this.percentageWater1 > 99)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop: func('prod_waswater', { ns: 'production' }) + '%',
                        val: this.percentageWater1
                    })
                );
        return warnText;
    }
}

class RecNorm {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false,
                enumerable: true
            },
            indroogPerc: {
                value: data.penI,
                writable: true,
                enumerable: true
            },
            vetDSPerc: {
                value: data.fatInDryMatter,
                writable: true,
                enumerable: true
            },
            zoutDSPerc: {
                value: data.saltDs,
                writable: true,
                enumerable: true
            },
            vochtPerc: {
                value: data.moisture,
                writable: true,
                enumerable: true
            },
            pekelVerlies: {
                value: data.pekelVerlies,
                writable: true,
                enumerable: true
            },
            pekelDuur: {
                value: data.hoursInBrine,
                writable: true,
                enumerable: true
            },
            pasteurisatieTemp: {
                value: data.pastTemp,
                writable: true,
                enumerable: true
            },
            thermisatieTemp: {
                value: data.thermTemp,
                writable: true,
                enumerable: true
            },
            pHvoorpekel: {
                value: data.phVp,
                writable: true,
                enumerable: true
            },
            pHna1dag: {
                value: data.ph24Hours,
                writable: true,
                enumerable: true
            },
            pHna15dag: {
                value: data.ph15Day,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }

    SetForSave() {
        const RecipeNorms = {
            ID: this.id,
            PenI: this.indroogPerc,
            FatInDryMatter: this.vetDSPerc,
            Ph15Day: this.pHna15dag,
            SaltDs: this.zoutDSPerc,
            Moisture: this.vochtPerc,
            PekelVerlies: this.pekelVerlies,
            HoursInBrine: this.pekelDuur,
            PastTemp: Helper.checkEmptyString(this.pasteurisatieTemp),
            ThermTemp: Helper.checkEmptyString(this.thermisatieTemp),
            PhVp: Helper.checkEmptyString(this.pHvoorpekel),
            Ph24Hours: Helper.checkEmptyString(this.pHna1dag)
        };
        return RecipeNorms;
    }
    CheckValidity(func) {
        let warnText = '';
        if (this.vetDSPerc)
            if (this.vetDSPerc < 0 || this.vetDSPerc > 70)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop:
                            func('an_percentage', { ns: 'analytics' }) +
                            ' ' +
                            func('an_vetds', { ns: 'analytics' }).toLowerCase(),
                        val: this.vetDSPerc
                    })
                );
        if (this.zoutDSPerc)
            if (this.zoutDSPerc < 0 || this.zoutDSPerc > 10)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop:
                            func('an_percentage', { ns: 'analytics' }) +
                            ' ' +
                            func('an_zoutds', {
                                ns: 'analytics'
                            }).toLowerCase(),
                        val: this.zoutDSPerc
                    })
                );
        if (this.vochtPerc)
            if (this.vochtPerc < 20 || this.vochtPerc > 80)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop:
                            func('an_percentage', { ns: 'analytics' }) +
                            ' ' +
                            func('an_vocht', { ns: 'analytics' }).toLowerCase(),
                        val: this.vochtPerc
                    })
                );
        if (this.indroogPerc)
            if (this.indroogPerc < 0 || this.indroogPerc > 20)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop:
                            func('an_percentage', { ns: 'analytics' }) +
                            ' ' +
                            func('prod_indroog', {
                                ns: 'production'
                            }).toLowerCase(),
                        val: this.indroogPerc
                    })
                );
        if (this.pekelVerlies)
            if (this.pekelVerlies < 0 || this.pekelVerlies > 10)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop:
                            func('an_percentage', { ns: 'analytics' }) +
                            ' ' +
                            func('prod_pekelverlies', {
                                ns: 'production'
                            }).toLowerCase(),
                        val: this.pekelVerlies
                    })
                );
        if (this.pasteurisatieTemp)
            if (this.pasteurisatieTemp < 70 || this.pasteurisatieTemp > 80)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop: func('prod_xtemperatuur', {
                            ns: 'production',
                            val: func('prod_past', {
                                ns: 'production'
                            }).toLowerCase()
                        }),
                        val: this.pasteurisatieTemp
                    })
                );
        if (this.thermisatieTemp)
            if (this.thermisatieTemp < 50 || this.thermisatieTemp > 70)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop: func('prod_xtemperatuur', {
                            ns: 'production',
                            val: func('prod_therm', {
                                ns: 'production'
                            }).toLowerCase()
                        }),
                        val: this.thermisatieTemp
                    })
                );
        if (this.pekelDuur)
            if (this.pekelDuur < 2 || this.pekelDuur > 200)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop:
                            func('prod_pekelduur', { ns: 'production' }) +
                            ' ' +
                            func('an_uur', {
                                ns: 'analytics'
                            }).toLowerCase(),
                        val: this.pekelDuur
                    })
                );
        if (this.pHvoorpekel)
            if (this.pHvoorpekel < 4 || this.pHvoorpekel > 7)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop: func('an_phvp', { ns: 'analytics' }),
                        val: this.pHvoorpekel
                    })
                );
        if (this.pHna1dag)
            if (this.pHna1dag < 4 || this.pHna1dag > 7)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop: func('an_ph1dag', { ns: 'analytics' }),
                        val: this.pHna1dag
                    })
                );
        return warnText;
    }
}

class RecProduction {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false
            },
            tijdWBProductie: {
                type: Number,
                value: data.productionTime,
                writable: true,
                enumerable: true
            },
            tijdVulWB: {
                type: Number,
                value: data.timeFillOst,
                writable: true,
                enumerable: true
            },
            tijdSnijden: {
                type: Number,
                value: data.timeCutting,
                writable: true,
                enumerable: true
            },
            tijdStremmen: {
                type: Number,
                value: data.timeRenetting,
                writable: true,
                enumerable: true
            },
            tijdPersen: {
                type: Number,
                value: data.timeMoulding,
                writable: true,
                enumerable: true
            },
            tijdWassen1: {
                type: Number,
                value: data.timeWashing1,
                writable: true,
                enumerable: true
            },
            tijdWassen2: {
                type: Number,
                value: data.timeWashing2a,
                writable: true,
                enumerable: true
            },
            tijdDrainenWei1: {
                type: Number,
                value: data.timeDrainingWhey1,
                writable: true,
                enumerable: true
            },
            tijdDrainenWei2: {
                type: Number,
                value: data.timeDrainingWhey2a,
                writable: true,
                enumerable: true
            },
            progWB: {
                value: data.ostProgram,
                writable: true,
                enumerable: true
            },
            progDrain: {
                value: data.drainProgram,
                writable: true,
                enumerable: true
            },
            progPers: {
                value: data.pressProgram,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }

    SetForSave() {
        const RecipeProduction = {
            ID: this.id,
            TimeFillOst: this.tijdVulWB,
            OstProgram: this.progWB,
            DrainProgram: this.progDrain,
            ProductionTime: this.tijdWBProductie,
            PressProgram: this.progPers,

            TimeRenetting: this.tijdStremmen,
            TimeCutting: this.tijdSnijden,
            TimeDrainingWhey1: this.tijdDrainenWei1,
            TimeWashing1: this.tijdWassen1,
            TimeDrainingWhey2a: this.tijdDrainenWei2,
            TimeWashing2a: this.tijdWassen2,
            TimeMoulding: this.tijdPersen
        };
        return RecipeProduction;
    }
    CheckValidity(func) {
        let warnText = '';
        if (this.tijdVulWB)
            if (this.tijdVulWB < 10 || this.tijdVulWB > 90)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop: func('prod_vultijd', {
                            ns: 'production'
                        }),
                        val: this.thermisatieTemp
                    })
                );
        if (this.tijdWBProductie)
            if (this.tijdWBProductie < 60 || this.tijdWBProductie > 240)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop: func('prod_prodtimeost', {
                            ns: 'production'
                        }),
                        val: this.thermisatieTemp
                    })
                );
        return warnText;
    }
}

class RecComposition {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false,
                enumerable: true
            },
            correctieDens: {
                value: data.corrDens,
                writable: true,
                enumerable: true
            },
            correctieEiwit: {
                value: data.corrProtein,
                writable: true,
                enumerable: true
            },
            correctieVet: {
                value: data.corrFat,
                writable: true,
                enumerable: true
            },
            eiwitWei2: {
                value: data.secondWheyProtein,
                writable: true,
                enumerable: true
            },
            vetWei2: {
                value: data.secondWheyFat,
                writable: true,
                enumerable: true
            },
            densWei2: {
                value: data.secondWheyDensity,
                writable: true,
                enumerable: true
            },
            eiwitWei1: {
                value: data.firstWheyProtein,
                writable: true,
                enumerable: true
            },
            vetWei1: {
                value: data.firstWheyFat,
                writable: true,
                enumerable: true
            },
            densWei1: {
                value: data.firstWheyDensity,
                writable: true,
                enumerable: true
            },
            eiwitMelk: {
                value: data.milkPotein ?? data.milkProtein,
                writable: true,
                enumerable: true
            },
            vetMelk: {
                value: data.milkFat,
                writable: true,
                enumerable: true
            },
            densMelk: {
                value: data.milkDensity,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }

    SetForSave() {
        const RecipeComposition = {
            ID: this.id,
            CorrProtein: this.correctieEiwit,
            CorrFat: this.correctieVett,
            CorrDens: this.correctieDens,

            FirstWheyProtein: this.eiwitWei1,
            FirstWheyFat: this.vetWei1,
            FirstWheyDensity: this.densWei1,

            SecondWheyProtein: this.eiwitWei2,
            SecondWheyFat: this.vetWei2,
            SecondWheyDensity: this.densWei2,

            MilkProtein: this.eiwitMelk,
            MilkFat: this.vetMelk,
            MilkDensity: this.densMelk
        };
        return RecipeComposition;
    }
    CheckValidity(func) {
        let warnText = '';
        if (this.corrFat)
            if (this.corrFat < 0 || this.corrFat > 0.5)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop:
                            func('an_correctie', {
                                ns: 'analytics'
                            }) +
                            '' +
                            func('an_vet', {
                                ns: 'analytics'
                            }).toLowerCase(),
                        val: this.corrFat
                    })
                );
        if (this.corrProtein)
            if (this.corrProtein < 0 || this.corrProtein > 0.5)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop:
                            func('an_correctie', {
                                ns: 'analytics'
                            }) +
                            '' +
                            func('an_eiwit', {
                                ns: 'analytics'
                            }).toLowerCase(),
                        val: this.corrProtein
                    })
                );
        if (this.correctieDens)
            if (this.correctieDens < -0.01 || this.correctieDens > 0.01)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop:
                            func('an_correctie', {
                                ns: 'analytics'
                            }) +
                            '' +
                            func('an_density', {
                                ns: 'analytics'
                            }).toLowerCase(),
                        val: this.correctieDens
                    })
                );

        if (this.eiwitWei2)
            if (this.eiwitWei2 < 0 || this.eiwitWei2 > 2)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop:
                            func('an_percentage', {
                                ns: 'analytics'
                            }) +
                            '' +
                            func('an_xwei2', {
                                ns: 'analytics',
                                val: func('an_eiwit', {
                                    ns: 'analytics'
                                })
                            }).toLowerCase(),
                        val: this.eiwitWei2
                    })
                );
        if (this.vetWei2)
            if (this.vetWei2 < 0 || this.vetWei2 > 1)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop:
                            func('an_percentage', {
                                ns: 'analytics'
                            }) +
                            '' +
                            func('an_xwei2', {
                                ns: 'analytics',
                                val: func('an_vet', {
                                    ns: 'analytics'
                                })
                            }).toLowerCase(),
                        val: this.vetWei2
                    })
                );
        if (this.densWei2)
            if (this.densWei2 < 1.0 || this.densWei2 > 1.04)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop: func('an_xwei2', {
                            ns: 'analytics',
                            val: func('an_density', {
                                ns: 'analytics'
                            })
                        }).toLowerCase(),
                        val: this.densWei2
                    })
                );
        if (this.eiwitWei1)
            if (this.eiwitWei1 < 0 || this.eiwitWei1 > 2)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop:
                            func('an_percentage', {
                                ns: 'analytics'
                            }) +
                            '' +
                            func('an_xwei1', {
                                ns: 'analytics',
                                val: func('an_eiwit', {
                                    ns: 'analytics'
                                })
                            }).toLowerCase(),
                        val: this.eiwitWei1
                    })
                );
        if (this.vetWei1)
            if (this.vetWei1 < 0 || this.vetWei1 > 1)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop:
                            func('an_percentage', {
                                ns: 'analytics'
                            }) +
                            '' +
                            func('an_xwei1', {
                                ns: 'analytics',
                                val: func('an_vet', {
                                    ns: 'analytics'
                                })
                            }).toLowerCase(),
                        val: this.vetWei1
                    })
                );
        if (this.densWei1)
            if (this.densWei1 < 1.0 || this.densWei1 > 1.04)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop: func('an_xwei1', {
                            ns: 'analytics',
                            val: func('an_density', {
                                ns: 'analytics'
                            })
                        }).toLowerCase(),
                        val: this.densWei1
                    })
                );
        if (this.eiwitMelk)
            if (this.eiwitMelk < 3 || this.eiwitMelk > 5)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop:
                            func('an_percentage', {
                                ns: 'analytics'
                            }) +
                            '' +
                            func('an_xmelk', {
                                ns: 'analytics',
                                val: func('an_eiwit', {
                                    ns: 'analytics'
                                })
                            }).toLowerCase(),
                        val: this.eiwitMelk
                    })
                );
        if (this.vetMelk)
            if (this.vetMelk < 0 || this.vetMelk > 9)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop:
                            func('an_percentage', {
                                ns: 'analytics'
                            }) +
                            '' +
                            func('an_xmelk', {
                                ns: 'analytics',
                                val: func('an_vet', {
                                    ns: 'analytics'
                                })
                            }).toLowerCase(),
                        val: this.vetMelk
                    })
                );
        if (this.densMelk)
            if (this.densMelk < 1.0 || this.densMelk > 1.06)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop: func('an_xmelk', {
                            ns: 'analytics',
                            val: func('an_density', {
                                ns: 'analytics'
                            })
                        }).toLowerCase(),
                        val: this.densMelk
                    })
                );
        return warnText;
    }
}

class RecIngredient {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        this.recipeID = null;
        this.productID = null;
        this.igCategoryID = null;
        this.amount = null;
        this.perUnitID = null;
        this.remark = null;
        this.watertemp = null;
        this.wateramount = null;
        this.ranking = null;
        Object.assign(this, data);
        this.isNew = this.isNew ? this.isNew : this.id < 0;
        Object.defineProperties(this, {
            id: {
                value: this.id,
                writable: false
            },
            recipeID: {
                value: this.recipeID,
                writable: true
            },
            productID: {
                value: this.productID,
                writable: true
            },
            ingredientCatID: {
                type: Number,
                value: this.igCategoryID,
                writable: true,
                enumerable: true
            },
            hoeveelheid: {
                value: this.amount,
                writable: true,
                enumerable: true
            },
            eenheid: {
                value: this.perUnitID,
                writable: true,
                enumerable: true
            },
            factor: {
                value: this.basecalcfactor ?? 1, // todo deze is er nooit, kan weg
                writable: true
            },
            opmerking: {
                value: this.remark,
                writable: true
            },
            waterToevoeging: {
                value: this.wateramount,
                writable: true
            },
            waterTemperatuur: {
                value: this.watertemp,
                writable: true
            },
            ranking: {
                value: this.ranking,
                writable: true
            },
            heeftSubType: {
                value: this.starterSubtype ?? undefined,
                writable: true
            },
            doseerLocatie: {
                value: this.doseLocation,
                writable: true
            }
        });
    }
    SetForSave() {
        const RecipeIngredient = {
            ProductID: this.productID,
            IgCategoryID: this.ingredientCatID,
            StarterSubtype: this.heeftSubType,

            ID: this.id,
            RecipeID: this.recipeID,
            Amount: this.hoeveelheid,
            PerUnitID: this.eenheid,
            Remark: this.opmerking,
            Wateramount: this.waterToevoeging,
            Watertemp: this.waterTemperatuur,
            Ranking: this.ranking,
            DoseLocation: this.doseerLocatie
        };
        return RecipeIngredient;
    }
    CheckValidity(func) {
        let warnText = '';
        if (this.hoeveelheid) {
            if (this.hoeveelheid < 0 || this.hoeveelheid > 50000)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop:
                            func('an_hoeveelheid', { ns: 'analytics' }) +
                            ' ' +
                            func('prod_ingredient', {
                                ns: 'production'
                            }).toLowerCase(),
                        val: this.hoeveelheid
                    })
                );
        } else {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('prod_ingredient', { ns: 'production' })
                })
            );
        }
        return warnText;
    }
}

