<template>
    <b-modal
        id="modal-quicklist"
        size="lg"
        ref="modal"
        :title="formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
        ><b-row>
            <b-col sm="3">
                <label for="input-reference"
                    >{{ $t('com_reference', { ns: 'common' }) }}:</label
                >
            </b-col>
            <b-col sm="9">
                <b-form-input
                    id="input-reference"
                    :placeholder="$t('com_reference', { ns: 'common' })"
                    v-model="localItem.mutatieReferentie"
                ></b-form-input>
            </b-col> </b-row
        ><b-row>
            <b-col sm="3">
                <label for="input-customer"
                    >{{ $t('com_klant', { ns: 'common' }) }}:</label
                >
            </b-col>
            <b-col sm="9">
                <lookup-combo
                    id="input-customer"
                    v-model="localItem.klant"
                    v-bind:combotype="lctypeCompany"
                ></lookup-combo>
            </b-col>
        </b-row>
        <b-card @submit.stop.prevent="handleSubmit">
            <b-tabs card>
                <b-tab :active="!showSelect">
                    <b-card-text>
                        <div class="form-inline">
                            <b-button
                                @click="scan()"
                                class="bgc_aux_unimportant float-md-right"
                                ><font-awesome-icon icon="barcode" size="1x" />
                            </b-button>
                            <b-form-input
                                :placeholder="
                                    $t('log_serialorbatch', {
                                        ns: 'logistics'
                                    })
                                "
                                v-model="merkNumber"
                            ></b-form-input>
                            <b-form-input
                                :placeholder="
                                    $t('com_amount', { ns: 'common' })
                                "
                                type="number"
                                v-model.number="hoeveelheid"
                            ></b-form-input>
                            <b-button
                                @click="addLine()"
                                class="bgc_aux_normal"
                                v-b-tooltip.hover
                                :title="
                                    $t('prod_toevoegen', {
                                        ns: 'production'
                                    })
                                "
                                ><font-awesome-icon
                                    icon="arrow-down"
                                    size="1x"
                                />
                            </b-button>
                        </div>
                    </b-card-text>
                </b-tab>
                <b-tab :active="showSelect">
                    <b-card-text>
                        <b-alert v-model="alert" variant="warning" dismissible>
                            {{ alertTekst }}
                        </b-alert>
                        <div class="form-inline">
                            <b-form-select
                                v-model="aselected"
                                :options="selectionList"
                            >
                                <template #first>
                                    <b-form-select-option value=""
                                        >--
                                        {{
                                            $t('com_selecteer', {
                                                ns: 'common'
                                            })
                                        }}
                                        --</b-form-select-option
                                    >
                                </template>
                            </b-form-select>
                            <b-button
                                @click="addSelected()"
                                class="bgc_aux_normal"
                                v-b-tooltip.hover
                                :title="
                                    $t('prod_toevoegen', {
                                        ns: 'production'
                                    })
                                "
                                ><font-awesome-icon
                                    icon="arrow-down"
                                    size="1x"
                                />
                            </b-button>
                        </div>
                    </b-card-text>
                </b-tab>
            </b-tabs>

            <b-alert
                v-model="showDismissibleAlert"
                variant="danger"
                dismissible
            >
                {{ alertText }}
            </b-alert>
            <b-tabs card>
                <b-tab :title="strRegels">
                    <b-card-text>
                        <div
                            class="mx-0 px-0 my-0 py-0"
                            v-for="(pv, index) in localItem.regels"
                            v-bind:key="index"
                        >
                            <b-col
                                sm="11"
                                class="mx-0 px-0 my-0 py-0 form-inline"
                            >
                                <b-form-input
                                    v-model="pv.naam"
                                    readonly
                                ></b-form-input>
                                <b-form-input
                                    type="number"
                                    v-model.number="pv.hoeveelheid"
                                    readonly
                                ></b-form-input>
                            </b-col>
                            <b-col sm="1" class="mx-0 px-0 my-0 py-0">
                                <b-button
                                    @click="verwijderRegel(pv.id)"
                                    class="bgc_aux_alarm"
                                    ><font-awesome-icon
                                        icon="trash-alt"
                                        size="1x"
                                    />
                                </b-button>
                            </b-col>
                        </div>
                    </b-card-text>
                </b-tab>
            </b-tabs>
        </b-card>
        <scan-barcode
            v-if="showScanner"
            v-model="showScanner"
            @result="showDetailStock"
        />
    </b-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { VueExtension } from '../../models/VueExtension';
import {
    VoorraadItem,
    SoldStockItemsModel,
    StockItemsModel
} from '../../models/Voorraad';
import ScanBarcode from '../components/ScanBarcode.vue';
import Helper from '@/models/Helper';
import { loadList, loadItem } from '@/models/DataHelper';
import { LookupCombo } from '../components';
export default defineComponent({
    name: 'QuickListModal',
    data: function () {
        return {
            lctypeCompany: window.constants.LCCOMPANY,
            showDismissibleAlert: false,
            alertText: '',
            alert: false,
            alertTekst: '',
            showSelect: false,
            showScanner: false,
            merkNumber: '',
            hoeveelheid: 1,
            aselected: -1,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            selectionList: [] as Array<any>,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            product10LookupLijst: [] as Array<any>,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            productLookupLijst: [] as Array<any>,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            locationLookupLijst: [] as Array<any>,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            shapeLookupLijst: [] as Array<any>,
            localItem: new SoldStockItemsModel(),

            strRegels: this.$t('log_lines', { ns: 'logistics' }),
            stringOnleesbaarMerk: this.$t('prod_onleesbaarmerk', {
                ns: 'production'
            }),
            stringMeerderePartijen: this.$t('prod_meerderepartijen', {
                ns: 'production'
            }),
            stringGeenPartij: this.$t('prod_geenpartij', {
                ns: 'production'
            }),
            stringMeerDanVoorraad: this.$t('prod_meerdanvoorraad', {
                ns: 'production'
            })
        };
    },
    model: {
        prop: 'visible',
        event: 'change'
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    components: {
        LookupCombo,
        ScanBarcode
    },
    watch: {},
    computed: {
        formtitle() {
            return this.$t('log_maakuitlever', { ns: 'logistics' });
        }
    },
    methods: {
        scan() {
            this.showScanner = !this.showScanner;
        },
        async showDetailStock(value) {
            // voorlopig alleen bij 10plus dus bakgroep is nooit opgeknipt en heeft altijd maar 1 itemstock
            if (value == undefined) {
                (this.$root as VueExtension).showMessageBox(
                    Helper.stringformat(this.stringOnleesbaarMerk, value)
                );
                return;
            }
            let partij = '';
            if (Helper.containsChars(value)) {
                const selectionData = {
                    serialnumber: value
                };
                const partijlijst = await loadItem(
                    'rapportage/loadTrackTraceMerk10plus',
                    selectionData,
                    this.$t
                );
                const key = 'partijnummer';
                if (partijlijst.length > 0) {
                    if (partijlijst.length == 1) {
                        const o = partijlijst[0];
                        partij = o[key];
                    } else {
                        // todo als er meer zijn
                    }
                } else {
                    // misschien gewoon merk
                    const o = Helper.giveCharNumber(value);
                    const selectionData = {
                        text: o.letter,
                        number: o.nummer
                    };
                    const lijst = await loadItem(
                        'rapportage/loadTrackTraceMerk',
                        selectionData,
                        this.$t
                    );
                    if (lijst.length > 0) {
                        if (lijst.length == 1) {
                            const o = lijst[0];
                            partij = o[key];
                        } else {
                            // todo als er meer zijn
                        }
                    }
                }
            } else {
                // todo het is al een partijnummer in 10+
                partij = value;
            }
            if (partij.length > 0) this.showDetailStockCheese(partij);
        },
        async showDetailStockCheese(partijnummer) {
            this.showSelect = false;
            const itemNumber = partijnummer ?? this.merkNumber;
            // todo is nu alleen voo 10plus
            const resultLijst = await loadItem(
                'stock/loadStockItemByPartij',
                {
                    productionNumber: itemNumber
                },
                this.$t
            );
            if (resultLijst.length == 0) {
                const item = await loadItem(
                    'stock/loadStockItem',
                    {
                        stockitemid: itemNumber
                    },
                    this.$t
                );
                if (item && item.id > -1) {
                    resultLijst.push(item);
                } else {
                    // misschien een bakgroepnummer
                    const lijst = this.$store.getters['stock/getStockInfo'];
                    const filtLijst = lijst.filter(
                        (x) => x.batchID == itemNumber
                    );
                    if (filtLijst.length > 0) {
                        resultLijst.push(...filtLijst);
                    }
                }
            }
            if (resultLijst.length > 0) {
                if (resultLijst.length == 1) {
                    const voorraad: VoorraadItem = resultLijst[0];
                    let gaDoor: boolean = voorraad.aantal >= this.hoeveelheid;

                    if (!gaDoor) {
                        gaDoor = await (
                            this.$root as VueExtension
                        ).showConfirmationBox(
                            Helper.stringformat(
                                this.stringMeerDanVoorraad,
                                this.hoeveelheid,
                                voorraad.aantal
                            )
                        );
                    }
                    if (gaDoor) {
                        const product = this.zoekProduct(voorraad.productID);
                        const nieuwe = new StockItemsModel();
                        nieuwe.hoeveelheid = this.hoeveelheid;
                        nieuwe.id = voorraad.id;
                        nieuwe.naam = product?.text ?? voorraad.naam;

                        this.localItem.regels.push(nieuwe);
                        this.hoeveelheid = 1;
                    }
                } else {
                    // todo als er meer zijn
                    this.selectionList = resultLijst?.map((value) => {
                        const product = this.zoekProduct(value.productID);
                        const location = this.locationLookupLijst.find(
                            (x) => x.value == value.locationID
                        );
                        const vorm = this.shapeLookupLijst.find(
                            (x) => x.value == value.vormID
                        );
                        return {
                            value: value.id,
                            text: Helper.stringformat(
                                '{0}, {1}, ' +
                                    this.$t('prod_vorm', { ns: 'production' }) +
                                    ': {2} {3}',
                                product?.text ?? value.naam,
                                value.aantal,
                                vorm?.text ?? value.vorm,
                                location?.text ?? value.locatie
                            ),
                            item: value
                        };
                    });
                    this.showWarning(
                        Helper.stringformat(
                            this.stringMeerderePartijen,
                            partijnummer
                        )
                    );
                    this.showSelect = true;
                }
            } else {
                (this.$root as VueExtension).showMessageBox(
                    Helper.stringformat(this.stringGeenPartij, partijnummer)
                );
            }
        },
        zoekProduct(id) {
            let o = this.product10LookupLijst.find((x) => x.value == id);
            if (o == undefined)
                o = this.productLookupLijst.find((x) => x.value == id);
            return o;
        },
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        showWarning(tekst) {
            this.alertTekst = tekst;
            this.alert = true;
        },
        hide() {
            this.$emit('hide');
        },
        addLine() {
            this.showDetailStock(this.merkNumber);
        },
        verwijderRegel(id) {
            for (let i = this.localItem.regels.length - 1; i >= 0; i--) {
                const o = this.localItem.regels[i];
                if (o.id == id) this.localItem.regels.splice(i, 1);
            }
        },
        async addSelected() {
            const o = this.selectionList.find((x) => x.value == this.aselected);
            const voorraad: VoorraadItem = o.item;
            let gaDoor: boolean = voorraad.aantal >= this.hoeveelheid;
            if (!gaDoor) {
                gaDoor = await (this.$root as VueExtension).showConfirmationBox(
                    Helper.stringformat(
                        this.stringMeerDanVoorraad,
                        this.hoeveelheid,
                        voorraad.aantal
                    )
                );
            }
            if (gaDoor) {
                const product = this.zoekProduct(voorraad.productID);
                const nieuwe = new StockItemsModel();
                nieuwe.hoeveelheid = this.hoeveelheid;
                nieuwe.id = voorraad.id;
                nieuwe.naam = product?.text ?? voorraad.naam;

                this.localItem.regels.push(nieuwe);
                this.hoeveelheid = 1;
            }
            this.showSelect = false;
        },
        checkFormValidity() {
            const invalidBasis = this.localItem.CheckValidity(this.$t);
            if (invalidBasis) this.showAlert(invalidBasis);
            const valid = this.localItem != undefined && !invalidBasis;
            return valid;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return;
            }
            this.saveThisList();
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-quicklist');
            });
        },
        async saveThisList() {
            await loadItem(
                'stock/soldStockAmounts',
                this.localItem.SetForAdd(),
                this.$t
            );
        }
    },
    async mounted() {
        const lijst = await loadList(
            'components/getSimpleProdComboLijst',
            'components/loadSimpleProducts',
            null,
            false,
            this.$t
        );
        this.product10LookupLijst = lijst;
        const plijst = await loadList(
            'components/getProductsComboLijst',
            'components/loadProductsComboLijst',
            null,
            false,
            this.$t
        );
        this.productLookupLijst = plijst;
        const llijst = await loadList(
            'components/getLocationsComboLijst',
            'components/loadLocationsComboLijst',
            null,
            false,
            this.$t
        );
        this.locationLookupLijst = llijst;
        const slijst = await loadList(
            'components/getShapesComboLijst',
            'components/loadShapesComboLijst',
            null,
            false,
            this.$t
        );
        this.shapeLookupLijst = slijst;
    },
    created() {}
});
</script>

<style scoped></style>

