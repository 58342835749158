<template>
    <b-card no-body>
        <b-card-header class="container-fluid">
            {{ $t('prod_wwwkruidgeg', { ns: 'production' }) }}
            <b-form-checkbox
                class="float-right"
                v-if="configUseFahrenheit"
                v-model="showFahrenheit"
            >
                {{ $t('prod_usefahrenheit', { ns: 'production' }) }}
            </b-form-checkbox>
        </b-card-header>
        <div>
            <card-bakingredient-lijst
                v-if="theBakWWW.bakIngredientenConserveermiddel.length > 0"
                v-model="theBakWWW.bakIngredientenConserveermiddel"
                v-bind:editAllowed="editAllowed"
                v-bind:baseDateTime="theBakWWW.boekDatum"
                @change="setChanged"
                @validComponent="setConserveerValid"
            />
            <card-bakingredient-lijst
                v-if="theBakWWW.bakIngredientenKruiden.length > 0"
                v-model="theBakWWW.bakIngredientenKruiden"
                v-bind:editAllowed="editAllowed"
                v-bind:baseDateTime="theBakWWW.boekDatum"
                @change="setChanged"
                @validComponent="setKruidenValid"
            />
            <card-bakingredient-lijst
                v-if="theBakWWW.bakIngredientenAromas.length > 0"
                v-model="theBakWWW.bakIngredientenAromas"
                v-bind:editAllowed="editAllowed"
                v-bind:baseDateTime="theBakWWW.boekDatum"
                @change="setChanged"
                @validComponent="setAromasValid"
            />
            <b-card>
                <b-col sm="10"  class="mx-0 px-0 my-1 py-0">
                    <b-row class="mx-0 px-0 my-1 py-0">
                        <b-col sm="3" class="mx-0 px-0 my-1 py-0">
                            <label
                                >{{ $t('prod_wei1', { ns: 'production' }) }}:
                            </label>
                        </b-col>
                        <b-col sm="9" class="mx-0 px-0 my-1 py-0">
                            <b-form-input
                                id="input-wei1"
                                :placeholder="
                                    $t('prod_wei1', { ns: 'production' })
                                "
                                type="number"
                                step="10"
                                v-model.number="wei1"
                                @change="set_inputWei1"
                                :disabled="!editAllowed"
                            ></b-form-input>
                        </b-col>
                        <b-col sm="3" class="mx-0 px-0 my-1 py-0">
                            <label
                                >{{
                                    $t('prod_waswater', { ns: 'production' })
                                }}:
                            </label>
                        </b-col>
                        <b-col sm="9" class="mx-0 px-0 my-1 py-0">
                            <b-form-input
                                id="input-waswater"
                                :placeholder="
                                    $t('prod_waswater', { ns: 'production' })
                                "
                                type="number"
                                step="10"
                                v-model.number="www"
                                @change="set_inputWaswater"
                                :disabled="!editAllowed"
                            ></b-form-input>
                        </b-col>

                        <b-col sm="3" v-if="show2aWei" class="mx-0 px-0 my-1 py-0">
                            <label for="input_tussen"
                                >{{
                                    $t('prod_xtemp', {
                                        ns: 'production',
                                        val: $t('prod_tussentemp', {
                                            ns: 'production'
                                        })
                                    })
                                }}:
                            </label>
                        </b-col>
                        <b-col sm="9" v-if="show2aWei" class="mx-0 px-0 my-1 py-0">
                            <temperature-input
                                id="input_tussen"
                                v-model.number="tussentemperatuur"
                                @input="set_inputTussenTemperatuur"
                                @valid="set_validTempTussen"
                                :editAllowed="editAllowed"
                                :showFahrenheit="showFahrenheit"
                                :minvalue="minTemp"
                                :maxvalue="maxTemp"
                                :placeholderC="
                                    $t('com_temperatuur', { ns: 'common' })
                                "
                            />
                        </b-col>
                        <b-col sm="3" v-if="show2aWei" class="mx-0 px-0 my-1 py-0">
                            <label
                                >{{ $t('prod_wei2a', { ns: 'production' }) }}:
                            </label>
                        </b-col>
                        <b-col sm="9" v-if="show2aWei" class="mx-0 px-0 my-1 py-0">
                            <b-form-input
                                id="input-wei2a"
                                :placeholder="
                                    $t('prod_wei2a', { ns: 'production' })
                                "
                                type="number"
                                step="10"
                                v-model.number="wei2a"
                                @change="set_inputWei2"
                                :disabled="!editAllowed"
                            ></b-form-input>
                        </b-col>
                        <b-col sm="3" v-if="show2aWei" class="mx-0 px-0 my-1 py-0">
                            <label
                                >{{
                                    $t('prod_waswater', {
                                        ns: 'production'
                                    })
                                }}:
                            </label>
                        </b-col>
                        <b-col sm="9" v-if="show2aWei" class="mx-0 px-0 my-1 py-0">
                            <b-form-input
                                id="input-waswater2a"
                                :placeholder="
                                    $t('prod_waswater2a', {
                                        ns: 'production'
                                    })
                                "
                                type="number"
                                step="10"
                                v-model.number="www2a"
                                @change="set_inputWaswater2"
                                :disabled="!editAllowed"
                            ></b-form-input>
                        </b-col>
                        <b-col sm="3" class="mx-0 px-0 my-1 py-0">
                            <label for="input_nawarm"
                                >{{
                                    $t('prod_xtemp', {
                                        ns: 'production',
                                        val: $t('prod_nawarm', {
                                            ns: 'production'
                                        })
                                    })
                                }}:
                            </label>
                        </b-col>
                        <b-col sm="9" class="mx-0 px-0 my-1 py-0">
                            <b-col sm="6" class="mx-0 px-0 my-0 py-0">
                                <temperature-input
                                    id="input-nawarm"
                                    v-model.number="temperatuur"
                                    @input="set_inputTemperatuur"
                                    @valid="set_validTemp"
                                    :editAllowed="editAllowed"
                                    :showFahrenheit="showFahrenheit"
                                    :minvalue="minTemp"
                                    :maxvalue="maxTemp"
                                    :placeholderC="
                                        $t('com_temperatuur', { ns: 'common' })
                                    "
                                />
                            </b-col>
                            <b-col sm="6" class="mx-0 px-0 my-0 py-0">
                                <b-input-group>
                                    <b-form-input
                                        v-b-tooltip.hover
                                        :title="tempWWWtekst"
                                        v-model="tempWWWtekst"
                                        :disabled="!editAllowed"
                                    ></b-form-input>
                                    <b-input-group-append>
                                        <b-button
                                            v-if="!show2aWei"
                                            @click="setWaterTemperatuurInfo"
                                            :disabled="!editAllowed"
                                            ><font-awesome-icon
                                                icon="calculator"
                                                size="1x"
                                        /></b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col sm="2" class="mx-0 px-0 my-1 py-0">
                    <b-row>
                        <b-col>
                            <b-button
                                @click="SetDefaults()"
                                class="bgc_aux_warning float-right "
                                :disabled="!editAllowed"
                                ><font-awesome-icon
                                    icon="edit"
                                    size="1x"
                                />&nbsp;{{
                                    $t('com_default', { ns: 'common' })
                                }}
                            </b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-card>
        </div>
    </b-card>
</template>

<script>
import { BakWWW } from '../../models/BakInfo_helper';
import TechCalcHelper from '../../models/TechCalcHelper';
import { loadList } from '../../models/DataHelper';
import Helper from '../../models/Helper';
import { Feature } from '../../models/Features';
import { TemperatureInput } from '../components';
import CardBakingredientLijst from './CardBakingredientLijst';

export default {
    name: 'CardWww',
    data() {
        return {
            temperatuur: null,
            tussentemperatuur: null,
            minTemp: window.constants.MIN_TEMP_NAWARM,
            maxTemp: window.constants.MAX_TEMP_NAWARM,
            validTemperatuur: true,
            validTemperatuurTussen: true,
            showFahrenheit: true,
            tempWWWtekst: null,
            www: null,
            wei1: null,
            www2a: null,
            wei2a: null,
            kruidenValid: true,
            aromasValid: true,
            conserveerValid: true,
            aangeraakt: 1,
            recipe: null
        };
    },
    components: {
        CardBakingredientLijst,
        TemperatureInput
    },
    model: {
        prop: 'theBakWWW',
        event: 'changedRecipe'
    },
    props: {
        theBakWWW: {
            type: BakWWW,
            default: null
        },
        editAllowed: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        configUseFahrenheit() {
            const useF = this.checkFeature('conf_useFahrenheitinProduction');
            return useF;
        },
        show2aWei() {
            const a = this.aangeraakt;
            return this.recipe
                ? this.recipe.restWeiWrongel2Perc != undefined
                : false;
        }
    },
    methods: {
        checkFeature(feature) {
            const key = Feature.enum(feature);
            return this.$root.handleFeature(key);
        },
        set_validTemp(value) {
            this.validTemperatuur = value;
        },
        set_validTempTussen(value) {
            this.validTemperatuurTussen = value;
        },
        set_inputTemperatuur(val) {
            this.theBakWWW.nawarmTemp = val ? val : null;
            this.setChanged();
        },
        set_inputTussenTemperatuur(val) {
            this.theBakWWW.tussenTemp = val ? val : null;
            this.setChanged();
        },
        set_inputWei1() {
            this.theBakWWW.wei1 = this.wei1 ? this.wei1 : null;
            this.setChanged();
        },
        set_inputWaswater() {
            this.theBakWWW.wasWater = this.www ? this.www : null;
            this.setChanged();
        },
        set_inputWei2() {
            this.theBakWWW.wei2a = this.wei2a ? this.wei2a : null;
            this.setChanged();
        },
        set_inputWaswater2() {
            this.theBakWWW.wasWater2a = this.www2a ? this.www2a : null;
            this.setChanged();
        },
        setConserveerValid(value) {
            this.conserveerValid = value;
            this.setChanged();
        },
        setKruidenValid(value) {
            this.kruidenValid = value;
            this.setChanged();
        },
        setAromasValid(value) {
            this.aromasValid = value;
            this.setChanged();
        },
        setChanged() {
            const result =
                this.validTemperatuur &&
                this.validTemperatuurTussen &&
                this.kruidenValid &&
                this.aromasValid &&
                this.conserveerValid;
            this.$emit('validComponent', result);
            this.$emit('change', true);
        },
        async getRecipe() {
            if (this.recipe == undefined) {
                const selectionData = { recipeid: this.theBakWWW.receptID };
                this.recipe = await loadList(
                    'recipe/getRecipe',
                    'recipe/loadRecipeComplete',
                    selectionData,
                    true,
                    this.$t
                );
                this.aangeraakt += 1;
            }
        },
        async SetDefaults(update = true) {
            await this.getRecipe();
            this.temperatuur = this.recipe.nawarmTemp;
            this.tussentemperatuur = this.recipe.tussenTemp;
            let rww = this.recipe.restWeiWrongel1;
            if (this.recipe.restWeiWrongel1Perc == undefined) {
                this.wei1 = Math.round(
                    (this.theBakWWW.melk - this.recipe.restWeiWrongel1) *
                        this.theBakWWW.prodcalcFactor
                );
            } else {
                this.wei1 = Math.round(
                    this.theBakWWW.melk *
                        ((100 - this.recipe.restWeiWrongel1Perc) / 100) *
                        this.theBakWWW.prodcalcFactor
                );
                rww =
                    this.theBakWWW.melk * this.theBakWWW.prodcalcFactor -
                    this.wei1;
            }
            this.www = Math.round((rww * this.recipe.percentageWater1) / 100);
            if (this.show2aWei) {
                const rww2 = rww + this.www;
                this.wei2a = Math.round(
                    rww2 * ((100 - this.recipe.restWeiWrongel2Perc) / 100)
                );
                rww = rww2 - this.wei2a;
                this.www2a = Math.round(
                    (rww * this.recipe.percentageWater2) / 100
                );
            }
            if (update) {
                this.set_inputTemperatuur();
                this.set_inputTussenTemperatuur();
                this.set_inputWei1();
                this.set_inputWaswater();
                this.set_inputWei2();
                this.set_inputWaswater2();
            }
        },
        async setWaterTemperatuurInfo() {
            await this.getRecipe();
            const melk = this.theBakWWW.melk * this.theBakWWW.prodcalcFactor;
            const rww = melk - this.wei1;
            const rwwperc = (rww * 100) / melk;
            const wwwperc = (this.www * 100) / rww;
            const rww2 = rww + this.www;
            const rwwperc2a = (rww2 * 100) / rww;
            const waswaterperc2a = this.www2a / rww2;

            const temp = this.show2aWei
                ? TechCalcHelper.calcWWWtemperature2a(
                      melk,
                      rwwperc,
                      wwwperc,
                      rwwperc2a,
                      waswaterperc2a,
                      [],
                      this.theBakWWW.stremTemp ?? this.recipe.stremTemp,
                      this.temperatuur,
                      this.theBakWWW.tussenTemp ?? this.recipe.tussenTemp
                  )
                : TechCalcHelper.calcWWWtemperature(
                      melk,
                      rwwperc,
                      wwwperc,
                      [],
                      this.theBakWWW.stremTemp ?? this.recipe.stremTemp,
                      this.temperatuur
                  );

            const soll = isNaN(temp) ? '?' : Math.round(temp * 10) / 10;
            const ist = this.recipe.waterTemp ?? '?';
            this.tempWWWtekst = Helper.stringformat(
                this.$t('prod_recalcwatertemp', { ns: 'production' }),
                ist,
                soll
            );
        },
        setValues() {
            this.temperatuur = this.theBakWWW.nawarmTemp
                ? this.theBakWWW.nawarmTemp
                : this.temperatuur;
            this.tussentemperatuur = this.theBakWWW.tussenTemp
                ? this.theBakWWW.tussenTemp
                : this.temperatuur;
            this.wei1 = this.theBakWWW.wei1
                ? Math.round(
                      this.theBakWWW.wei1 * this.theBakWWW.prodcalcFactor
                  )
                : this.wei1;
            this.www = this.theBakWWW.wasWater
                ? Math.round(
                      this.theBakWWW.wasWater * this.theBakWWW.prodcalcFactor
                  )
                : this.www;
            this.www2a = this.theBakWWW.wasWater2a
                ? Math.round(
                      this.theBakWWW.wasWater2a * this.theBakWWW.prodcalcFactor
                  )
                : this.www2a;
            this.wei2a = this.theBakWWW.wei2a
                ? Math.round(
                      this.theBakWWW.wei2a * this.theBakWWW.prodcalcFactor
                  )
                : this.wei2a;
            if (!this.editAllowed) {
                this.setWaterTemperatuurInfo();
            }
            this.showFahrenheit = this.configUseFahrenheit;
            if (isNaN(this.www) || this.www == 0) this.SetDefaults();
        }
    },
    mounted() {},
    async created() {        
        await this.SetDefaults(false);
        this.setValues();
    }
};
</script>

