<template>
    <b-modal
        id="modal-itemOutbrine"
        size="xl"
        ref="modal"
        :title="this.formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
    >
        <b-card no-body :header="$t('prod_uitpekel', { ns: 'production' })">
            <div>
                <div
                    v-for="(inpekelitem, index) in localUitPekellijstVolwaardig"
                    v-bind:key="index"
                >
                    <b-col sm="10" class="mx-0 px-0 my-1 py-0">
                        <uitpekel-item-combo
                            v-bind:key="index"
                            v-model="localUitPekellijstVolwaardig[index]"
                            v-bind:baseDateTime="altDate"
                            v-bind:editAllowed="editAllowed"
                            @change="doCheck"
                            @validComponent="setUitpekelValid"
                        />
                    </b-col>
                    <b-col sm="2" class="mx-0 px-0 my-1 py-0">
                        <b-button
                            v-if="editAllowed"
                            @click="
                                copyItem(localUitPekellijstVolwaardig[index])
                            "
                            class="bgc_aux_normal"
                            ><font-awesome-icon icon="copy" size="1x" />
                        </b-button>
                        <b-button
                            v-if="editAllowed"
                            @click="
                                verwijderItem(
                                    localUitPekellijstVolwaardig[index]
                                )
                            "
                            class="bgc_aux_alarm"
                            ><font-awesome-icon icon="trash-alt" size="1x" />
                        </b-button>
                        <b-button
                            v-if="editAllowed"
                            @click="
                                printLabel(localUitPekellijstVolwaardig[index])
                            "
                            class="bgc_aux_unimportant"
                            ><font-awesome-icon icon="print" size="1x" />
                        </b-button>
                    </b-col>
                </div>
                <div v-if="printVisible">
                    <print-prod-label
                        v-bind:visible="printVisible"
                        v-bind:stockItemID="stockitemToPrint"
                    />
                </div>
                <div>
                    <div
                        v-for="item in alUitgepekeldOpAndereDag"
                        :key="item.id"
                    >
                        <b-form-textarea
                            id="textarea-plaintext"
                            plaintext
                            :value="item"
                            class="mx-0 px-0 my-1 py-0"
                        >
                            ></b-form-textarea
                        >
                    </div>
                    <div v-for="item in uitTePekelenOpAndereDag" :key="item.id">
                        <b-form-textarea
                            id="textarea-plaintext"
                            plaintext
                            :value="item"
                            class="mx-0 px-0 my-1 py-0"
                        >
                            ></b-form-textarea
                        >
                    </div>
                    <b-form-textarea
                        v-if="showEmpty"
                        id="textarea-plaintext"
                        plaintext
                        :value="lege_tekst"
                        class="mx-0 px-0 my-1 py-0"
                    >
                        ></b-form-textarea
                    >
                </div>
                <b-alert
                    v-model="showDismissibleAlert"
                    variant="danger"
                    dismissible
                >
                    {{ alertText }}
                </b-alert>
                <b-alert
                    v-model="showWarningAlert"
                    variant="warning"
                    dismissible
                >
                    {{ reminder }}
                </b-alert>
                <b-card
                    no-body
                    :header="$t('prod_onvolwaardig', { ns: 'production' })"
                >
                    <div>
                        <b-dropdown
                            id="dropdown-form"
                            text="Uitval"
                            ref="dropdown"
                        >
                            <b-dropdown-form style="width: 340px">
                                <b-form-group
                                    label="Uitval soort"
                                    @submit.stop.prevent
                                >
                                    <span>
                                        <lookup-combo
                                            :placeholder="
                                                $t('com_kaassoort', {
                                                    ns: 'common'
                                                })
                                            "
                                            v-model="selectedProductID"
                                            v-bind:combotype="
                                                lctypeFailureCheeses
                                            "
                                        ></lookup-combo>
                                    </span>
                                </b-form-group>
                                <b-button
                                    size="sm"
                                    @click="addUitvalAantal"
                                    class="mr-1"
                                    ><font-awesome-icon
                                        icon="folder-plus"
                                        size="1x"
                                    />
                                    {{
                                        $t('prod_toevoegen', {
                                            ns: 'production'
                                        })
                                    }}
                                </b-button>
                            </b-dropdown-form>
                        </b-dropdown>
                    </div>
                    <b-row
                        v-for="(
                            inpekelitem, index
                        ) in localUitPekellijstOnvolwaardig"
                        v-bind:key="index"
                    >
                        <b-col class="py-0" sm="11">
                            <uitpekel-item-combo
                                v-bind:key="10 + index"
                                v-model="localUitPekellijstOnvolwaardig[index]"
                                v-bind:editAllowed="editAllowed"
                                @change="doCheck"
                                @validComponent="setUitpekelValid"
                            />
                        </b-col>
                        <b-col class="p-0" sm="1">
                            <b-button
                                v-if="editAllowed"
                                @click="
                                    verwijderItemUitval(
                                        localUitPekellijstOnvolwaardig[index]
                                    )
                                "
                                class="bgc_aux_alarm"
                                ><font-awesome-icon
                                    icon="trash-alt"
                                    size="1x"
                                />
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </div>
        </b-card>
    </b-modal>
</template>

<script>
import _ from 'lodash';
import Helper from '../../models/Helper';
import DateHelper from '../../models/DateHelper';
import { UitpekelItemCombo } from '../components';
import { loadItem, loadList } from '../../models/DataHelper';
import { LookupCombo, PrintProdLabel } from '../components';
import { BakUitpekel, UitpekelItem } from '../../models/BakInfo_helper';

export default {
    name: 'OutbrinePopForm',
    data() {
        return {
            lctypeFailureCheeses: window.constants.LCFAILURECHEESE,
            selectedProductID: null,
            showDismissibleAlert: false,
            showWarningAlert: false,
            alertText: '',
            reminder: '',
            readonly: false,
            isChanged: false,
            objectUitpekel: null,
            uitpekelValid: true,
            bakOpmerking: '',
            opmerkingChanged: false,
            tellerID: -1,
            vandaagLijst: [],
            alUitgepekeldOpAndereDag: [],
            uitTePekelenOpAndereDag: [],
            lege_tekst: '',
            printid: -1,
            printVisible: false
        };
    },
    components: {
        LookupCombo,
        UitpekelItemCombo,
        PrintProdLabel
    },
    model: {
        prop: 'visible',
        event: 'change'
    },
    props: {
        inPekellijst: {
            type: Array,
            required: true
        },
        uitPekellijst: {
            type: Array,
            required: true
        },
        bakgroepLijst: {
            type: Array,
            required: true
        },
        bakgroep: {
            type: String,
            required: true
        },
        visible: {
            type: Boolean,
            default: false
        },
        editAllowed: {
            type: Boolean,
            default: false
        },
        baseDateTime: {
        }
    },
    computed: {
        formtitle() {
            return this.$t('prod_uitpekelbg', { ns: 'production' });
        },
        altDate() {
            const d = new Date();
            d.setDate(d.getDate() -1);
            const d1 = new Date(d.toDateString());
            const dbase = new Date(this.baseDateTime);
            return dbase < d1 ? dbase : new Date();
        },
        localUitPekellijstVolwaardig() {
            return this.vandaagLijst;
        },
        localUitPekellijstOnvolwaardig() {
            if (this.uitPekellijst == undefined) return null; // kan niet
            return this.uitPekellijst.filter(
                (x) => x.uitvalProductID != undefined
            );
        },
        stockitemToPrint() {
            return this.printid;
        },
        showEmpty() {
            // workaround voor het tekenen
            return (
                this.alUitgepekeldOpAndereDag.length == 0 &&
                this.uitTePekelenOpAndereDag.length == 0
            );
        }
    },
    methods: {
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        showReminder(tekst) {
            this.reminder = tekst;
            this.showWarningAlert = true;
        },
        hide() {
            this.opmerking = '';
            this.$emit('hide');
        },
        doCheck: function () {
            this.check_doubles();
            this.setChanged();
        },
        set_input: function () {
            this.setChanged();
        },
        setRemarkChanged() {
            this.opmerkingChanged = true;
            this.setChanged();
        },
        setChanged() {
            this.isChanged = true;
        },
        setUitpekelValid: function (valid) {
            this.uitpekelValid = valid;
        },
        addUitpekelAantal() {
            const nieuw = new UitpekelItem({
                bakgroep: this.bakgroep
            });
            nieuw.uitpekelTijd = DateHelper.convertedToTime();
            this.tellerID += -1;
            nieuw.id = this.tellerID;
            this.vandaagLijst.push(nieuw);
            this.set_input();
        },
        copyItem(uitpekelItem) {
            const nieuw = new UitpekelItem({
                bakgroep: uitpekelItem.bakgroep,
                cheeseAmount: uitpekelItem.amount,
                shapeID: uitpekelItem.vormID,
                productID: uitpekelItem.productID
            });
            nieuw.productNaam = uitpekelItem.productNaam;
            nieuw.uitpekelTijd = DateHelper.convertedToTime();
            this.tellerID += -1;
            nieuw.id = this.tellerID;
            this.vandaagLijst.push(nieuw);
            this.set_input();
        },
        addUitvalAantal() {
            const list =
                this.$store.getters['components/getFailureCheeseComboLijst'];
            const tempSelect = list.find(
                (x) => x.value == this.selectedProductID
            );
            const naam = tempSelect ? tempSelect.text : 'onbekend';

            const nieuw = new UitpekelItem({
                bakgroep: this.bakgroep
            });
            this.tellerID += -1;
            nieuw.id = this.tellerID;
            nieuw.uitvalProductID = this.selectedProductID;
            nieuw.uitvalProductNaam = naam;
            nieuw.uitpekelTijd = DateHelper.convertedToTime();
            this.uitPekellijst.push(nieuw);
            this.set_input();
        },
        async verwijderItem(uitpekelItem) {
            const item = this.vandaagLijst.find((x) => {
                return x.id === uitpekelItem.id && x.nouse == undefined;
            });
            if (item) {
                // const doClose = await this.$root.showConfirmationBox(
                //     'Zeker weten? Verwijderen uitpekel item: ' + uitpekelItem.id
                // );
                // if (doClose) {
                const index = this.vandaagLijst.findIndex((x) => {
                    return x.id === uitpekelItem.id && x.nouse == undefined;
                });
                if (index > -1) {
                    this.vandaagLijst.splice(index, 1);
                    this.showDismissibleAlert = false;
                }
                //}
                this.check_doubles();
                this.set_input();
            }
        },
        async verwijderItemUitval(uitpekelItem) {
            const item = this.uitPekellijst.find((x) => {
                return x.id === uitpekelItem.id && x.nouse == undefined;
            });
            if (item) {
                // const doClose = await this.$root.showConfirmationBox(
                //     'Zeker weten? Verwijderen uitpekel item: ' + uitpekelItem.id
                // );
                // if (doClose) {
                const index = this.uitPekellijst.findIndex((x) => {
                    return x.id === uitpekelItem.id && x.nouse == undefined;
                });
                if (index > -1) {
                    this.uitPekellijst.splice(index, 1);
                    this.showDismissibleAlert = false;
                }
                //}
                this.check_doubles();
                this.set_input();
            }
        },
        check_doubles() {
            const x = _(this.uitPekellijst.slice(0))
                .groupBy(
                    (x) =>
                        `"${x.productID}+${x.vormID}+${x.stocklocationID}+${x.uitvalProductID}"`
                )
                .map((value, key) => ({
                    categorie: key,
                    uitpekel: value
                }))
                .value();
            const y = x.filter((o) => o.uitpekel.length > 1);
            this.uitpekelValid = !(y.length > 0);
            if (y.length > 0) {
                this.showAlert(
                    this.$t('prod_warnuniekinlijst', { ns: 'production' })
                );
            }
            // zoek op hoeveel er de pekel in is gegaan. Meld als het minder is
            const checkList = _(this.vandaagLijst.slice(0))
                .groupBy((x) => `"${x.vormID}+${x.productID}"`)
                .map((value, key) => ({
                    vorm: key,
                    uitpekel: value
                }))
                .value();
            this.showWarningAlert = false;
            for (let i = 0; i < checkList.length; i++) {
                const a = this.inPekellijst.find(
                    (x) => x.vormID == checkList[i].vorm
                );
                if (a) {
                    const tempArr = checkList[i].uitpekel.map((x) => x.amount);
                    const uitpekelAmount = _.sum(tempArr);
                    const shape = this.$store.getters[
                        'components/getShapebyID'
                    ](a.vormID);
                    let message = '';
                    if (a.amount < uitpekelAmount) {
                        message = Helper.stringformat(
                            this.$t('prod_warnmoreoutbrine', {
                                ns: 'production'
                            }),
                            a.amount,
                            shape.text,
                            uitpekelAmount - a.amount
                        );
                    } else {
                        if (a.amount > uitpekelAmount) {
                            message = Helper.stringformat(
                                this.$t('prod_warnlessoutbrine', {
                                    ns: 'production'
                                }),
                                a.amount,
                                shape.text,
                                a.amount - uitpekelAmount
                            );
                        }
                    }
                    if (message) this.showReminder(message);
                }
            }
        },
        checkFormValidity() {
            let tekst = '';
            if (this.objectUitpekel)
                tekst += this.checkObjectValidity(this.objectUitpekel);
            const valid = !tekst;
            return valid;
        },
        checkObjectValidity(ob) {
            const invalidTekst = ob.CheckValidity(this.$t);
            if (invalidTekst) this.showAlert(invalidTekst);
            return invalidTekst;
        },
        printLabel(item) {
            this.printVisible = false;
            this.$nextTick(() => {
                if (!this.uitpekelValid) {
                    this.showAlert(
                        this.$t('com_ongeldigeinput', { ns: 'common' })
                    );
                    return;
                }
                if (item.id < 0) {
                    this.showAlert(this.$t('com_savefirst', { ns: 'common' }));
                    return;
                }
                this.printid = item.id;
                this.printVisible = true;
                if (item._showDetails) {
                    this.$set(item, '_showDetails', false);
                } else {
                    this.$set(item, '_showDetails', true);
                }
            });
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        handleSubmit() {
            if (this.isChanged) {
                if (!this.uitpekelValid) {
                    this.showAlert(
                        this.$t('com_ongeldigeinput', { ns: 'common' })
                    );
                    return;
                }
                this.uitPekellijst.push(...this.vandaagLijst);
                this.objectUitpekel.uitpekelAmounts = this.uitPekellijst;
                // Exit when the form isn't valid
                if (!this.checkFormValidity()) {
                    return;
                }
                this.saveUitpekelgegevensBakgroep();
            }
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-itemOutbrine');
            });
        },
        async saveUitpekelgegevensBakgroep() {
            const outBrineModel = this.objectUitpekel.SetForUpdate();
            await loadItem(
                'outbrine/updateBatchGroupOutBrine',
                outBrineModel,
                this.$t
            );
            return true;
        }
    },
    mounted() {
        const kaaslijst =
            this.$store.getters['components/getCheesesComboLijst'];
        const vormlijst = this.$store.getters['components/getShapesComboLijst'];

        const tempListVandaagUitpekel = [];
        const tempListNietVandaag = [];
        for (let i = 0; i < this.bakgroepLijst.length; i++) {
            const bakinfoItem = this.bakgroepLijst[i];
            if (
                bakinfoItem.uitpekelOri &&
                DateHelper.isValidDateTimeForDate(
                    bakinfoItem.uitpekelOri,
                    this.baseDateTime
                )
            ) {
                tempListVandaagUitpekel.push(bakinfoItem);
            } else {
                tempListNietVandaag.push(bakinfoItem);
            }
        }

        const groupedBGLijst = _(tempListVandaagUitpekel)
            .groupBy((x) => `${x.vorm}+${x.kaasnaam}`)
            .map((value, key) => ({
                categorie: key,
                uitpekel: value
            }))
            .value();

        for (let i = 0; i < groupedBGLijst.length; i++) {
            let added = false;
            let aantal = 0;
            let kaas = null;
            let vorm = null;

            for (let q = 0; q < groupedBGLijst[i].uitpekel.length; q++) {
                const itemBakInfo = groupedBGLijst[i].uitpekel[q];
                kaas = kaaslijst.find((x) => x.text == itemBakInfo.kaasnaam);
                vorm = vormlijst.find((x) => x.text == itemBakInfo.vorm);
                aantal += itemBakInfo.aantal;

                if (this.uitPekellijst.length > 0) {
                    const tempArr = this.uitPekellijst.filter(
                        (x) =>
                            x.vormID == vorm.value && x.productID == kaas.value
                    );

                    if (tempArr.length > 0) {
                        added = true;
                        for (let a = 0; a < tempArr.length; a += 1) {
                            tempArr[a].productNaam = kaas.text;
                            tempArr[a].vormNaam = vorm.text;
                        }
                        this.vandaagLijst.push(...tempArr);

                        this.uitPekellijst = this.uitPekellijst.filter(
                            (x) => !tempArr.includes(x)
                        );
                    }
                }
            }

            if (!added) {
                const tempArr = this.inPekellijst.filter(
                    (x) => x.vormID == vorm.value && x.productID == kaas.value
                );
                const a = new UitpekelItem({
                    bakgroep: this.bakgroep
                });
                a.vormID = vorm.value;
                a.amount = aantal;
                a.productID = kaas.value;
                if (tempArr.length > 0) {
                    a.kooi = tempArr[0].kooi;
                    a.laag = tempArr[0].laag;
                }
                a.productNaam = kaas.text;
                a.vormNaam = vorm.text;
                a.uitpekelTijd = DateHelper.convertedToTime();
                this.tellerID -= 1;
                a.id = this.tellerID;
                this.vandaagLijst.push(a);
            }
        }

        tempListNietVandaag.forEach((itemBakInfo) => {
            let nieuweString = '';
            if (itemBakInfo.uitgepekeld) {
                nieuweString = Helper.stringformat(
                    this.$t('prod_warnuitgepekeld', { ns: 'production' }),
                    itemBakInfo.kaasnaam,
                    itemBakInfo.vorm,
                    itemBakInfo.uitpekeltijd
                );
                this.alUitgepekeldOpAndereDag.push(nieuweString);
            } else if (itemBakInfo.ingepekeld) {
                nieuweString = Helper.stringformat(
                    this.$t('prod_warningepekeld', { ns: 'production' }),
                    itemBakInfo.kaasnaam,
                    itemBakInfo.vorm,
                    itemBakInfo.uitpekeltijdplan
                );
                this.uitTePekelenOpAndereDag.push(nieuweString);
            } else {
                nieuweString = Helper.stringformat(
                    this.$t('prod_warnnotinpekel', { ns: 'production' }),
                    itemBakInfo.kaasnaam,
                    itemBakInfo.vorm,
                    itemBakInfo.productieDatum,
                    itemBakInfo.bakno
                );
                this.uitTePekelenOpAndereDag.push(nieuweString);
            }
        });

        const nieuw = new BakUitpekel({ bakgroep: this.bakgroep });
        nieuw.uitpekelAmounts = this.vandaagLijst;
        nieuw.boekDatum = this.baseDateTime;
        this.objectUitpekel = nieuw;
    },
    created() {
        loadList(
            'product/getProductCategorieLijst',
            'product/loadProductCategories',
            null,
            false,
            this.$t
        );
    }
};
</script>
