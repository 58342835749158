const mutations = {
    insertSelectedBatch(state, item) {
        state.selectedBatch = item;
    },
    insertSelectedBatchIngredient(state, item) {
        state.selectedBatchIngredient = item;
    },
    insertProdrun(state, item) {
        state.selectedProdRun = item;
    },
    insertBatchInfoLijst(state, lijst) {
        state.batchInfoList = [];
        if (lijst != undefined) {
            state.batchInfoList = lijst;
        }
    },
    insertBatchInfoHistLijst(state, lijst) {
        state.batchInfoHistList = [];
        if (lijst != undefined) {
            state.batchInfoHistList = lijst;
        }
    },
    insertBatchIngredientenLijst(state, lijst) {
        state.batchIngredientenList = [];
        if (lijst != undefined) {
            state.batchIngredientenList = lijst;
        }
    },
    insertInpekelBatch(state, lijst) {
        state.batchInpekelList = [];
        if (lijst != undefined) {
            state.batchInpekelList = lijst;
        }
    },
    insertBatchdefaults(state, object) {
        state.batchDefaults = null;
        if (object != undefined) {
            state.batchDefaults = object;
        }
    },
    insertMilkusagefactor(state, object) {
        state.milkusagefactor = null;
        if (object != undefined) {
            state.milkusagefactor = object;
        }
    },
    insertMarksForBatch(state, object) {
        state.marksForBatch = null;
        if (object != undefined) {
            state.marksForBatch = object;
        }
    },
    insertMilkLijst(state, lijst) {
        state.milkList = [];
        if (lijst != undefined) {
            state.milkList = lijst;
        }
    },
    insertAlternativeCheese(state, lijst) {
        state.alternativeCheeseList = [];
        if (lijst != undefined) {
            state.alternativeCheeseList = lijst;
        }
    },
    insertSelectedBatchActivities(state, lijst) {
        state.batchActivityList = [];
        if (lijst != undefined) {
            state.batchActivityList = lijst;
        }
    },
    insertTimerLijst(state, lijst) {
        state.timerList = [];
        if (lijst != undefined) {
            state.timerList = lijst;
        }
    },
};

export default mutations;
