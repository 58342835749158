import store from '../../store';
import { PRODCAT_ENUM } from '../../models/ProductCategorie';
import { INGRCAT_ENUM } from '../../models/IngredientCategorie';
import { loadItem } from '../../models/DataHelper';

const loadData = async (to, func, obj) => {
    if (typeof to.params.cat !== 'undefined') {
        await loadProductData(obj.id, func);
        return giveExtraFieldsProduct(obj.id, func);
    } else {
        if (typeof to.params.ingcat !== 'undefined') {
            await loadIngredientData(obj, func);
            return giveExtraFieldsIngredient(obj.id, func);
        }
    }
};

const giveExtraFieldsProduct = (prodcatID, func) => {
    let ext = [];
    switch (prodcatID) {
        case PRODCAT_ENUM.Kaas:
            ext = [
                {
                    key: 'vetklasse',
                    label: func('prod_vetklasse', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'vorm',
                    label: func('prod_vorm', { ns: 'production' }),
                    sortable: true,
                    visible: true
                }
            ];
            break;
        case PRODCAT_ENUM.Kaasmerk:
            ext = [
                {
                    key: 'vetklasse',
                    label: func('prod_vetklasse', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'laatsteMerk',
                    label: func('prod_lastused', { ns: 'production' }),
                    sortable: true,
                    visible: true
                }
            ];
            break;
        case PRODCAT_ENUM.Onvolwaardig:
            ext = [
                {
                    key: 'vetklasse',
                    label: func('prod_vetklasse', { ns: 'production' }),
                    sortable: true,
                    visible: true
                }
            ];
            break;
        case PRODCAT_ENUM.Kaasdekmiddel:
        case PRODCAT_ENUM.Wei:
        case PRODCAT_ENUM.Ingrediënt:
        case PRODCAT_ENUM.Overige:
        default:
            break;
    }
    return ext;
};

const giveExtraFieldsIngredient = (ingcatID, func) => {
    let ext = [];
    switch (ingcatID) {
        case INGRCAT_ENUM.Melk:
            ext = [
                {
                    key: 'groepNaam',
                    label: func('prod_melktype', { ns: 'production' }),
                    sortable: true,
                    visible: true
                }
            ];
            break;
        case INGRCAT_ENUM.Zuursel:
            ext = [
                {
                    key: 'zuurselSubType',
                    label: func('prod_startergroup', {
                        ns: 'production'
                    }),
                    sortable: true,
                    visible: true,
                    formatter: (value, key, item) => {
                        const temp = item.zuurselSubType;
                        return temp ? func('com_groep', { ns: 'common' })+ temp: '';
                    }
                }
            ];
            break;
        case INGRCAT_ENUM.Stremsel:
        case INGRCAT_ENUM.Kleursel:
        case INGRCAT_ENUM.Conserveermiddel:
        case INGRCAT_ENUM.Calcium:
        case INGRCAT_ENUM.Zout:
        case INGRCAT_ENUM.Kruiden:
        case INGRCAT_ENUM.Aromas:
        case INGRCAT_ENUM.Vetten:
        case INGRCAT_ENUM.Grootzuursel:
        default:
            break;
    }
    return ext;
};

const loadProductData = async (prodcatID, func) => {
    switch (prodcatID) {
        case PRODCAT_ENUM.Kaas:
            await loadItem('product/loadCheeseTypeInfo', null, func);
            break;
        case PRODCAT_ENUM.Kaasmerk:
            await loadItem('product/loadMarkTypeInfo', null, func);
            break;
        case PRODCAT_ENUM.Kaasdekmiddel:
            await loadItem('product/loadCheeseCoatingInfo', null, func);
            break;
        case PRODCAT_ENUM.Wei:
            await loadItem('product/loadWheyTypeInfo', null, func);
            break;
        case PRODCAT_ENUM.Onvolwaardig:
            await loadItem('product/loadFailureCheeseInfo', null, func);
            break;
        case PRODCAT_ENUM.Overige:
            await loadItem('product/loadOverigeInfo', null, func);
            break;
        case PRODCAT_ENUM.Ingrediënt:
        default:
            break;
    }
    return 'done';
};

const loadIngredientData = async (ingrcat, func) => {
    switch (ingrcat.id) {
        case INGRCAT_ENUM.Melk:
        case INGRCAT_ENUM.Zuursel:
        case INGRCAT_ENUM.Stremsel:
        case INGRCAT_ENUM.Kleursel:
        case INGRCAT_ENUM.Conserveermiddel:
        case INGRCAT_ENUM.Calcium:
        case INGRCAT_ENUM.Zout:
        case INGRCAT_ENUM.Kruiden:
        case INGRCAT_ENUM.Aromas:
        case INGRCAT_ENUM.Vetten:
        case INGRCAT_ENUM.Grootzuursel:
            await loadItem(
                'product/loadIngredientInfo',
                {
                    category: ingrcat.naam
                },
                func
            );
            break;
        default:
            break;
    }
    return 'done';
};

export { loadData, loadProductData, loadIngredientData };

