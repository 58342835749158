const getters = {
    getTreatmentRecipes: (state) => {
        return state.treatmentRecipeLijst;
    },    
    getPlannedTreatments: (state) => {
        return state.treatmentActivityLijst;
    },    
    getTreatmentStatus: (state) => {
        return state.tacStatusLijst;
    },
    getTreatmentSteps: (state) => {
        return state.treatStepLijst;
    },
    getUitbehandeldLijst: (state) => {
        return state.uitbehandeldLijst;
    },
    
};

export default getters;
