var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticStyle:{"margin-top":"60px"},attrs:{"fluid":""}},[_c('h1',{on:{"click":_vm.flipPlan}},[_vm._v(" "+_vm._s(_vm.title)+"   "),_c('b-button',[_vm._v(" "+_vm._s(_vm.btnTekst)+" ")])],1),_c('b-row',[_c('b-col',{attrs:{"sm":"8"}},[_c('period-selector',{attrs:{"periodDays":_vm.periodeDagen,"slackTillDate":_vm.slackDagen},on:{"change":_vm.setNewDates},model:{value:(_vm.selectDays),callback:function ($$v) {_vm.selectDays=$$v},expression:"selectDays"}})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"11"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"bgc_aux_normal",attrs:{"title":_vm.$t('com_showdata', { ns: 'common' })},on:{"click":function($event){return _vm.loadData()}}},[_c('font-awesome-icon',{attrs:{"icon":"arrow-down","size":"1x"}})],1)],1),_c('b-col',{attrs:{"sm":"1"}})],1)],1),_c('b-col',{attrs:{"sm":"1"}},[_c('b-row',{staticClass:"float-right"},[_c('b-col',{attrs:{"sm":"11"}}),_c('b-col',{attrs:{"sm":"1"}})],1)],1)],1),_c('div',{attrs:{"slot":"pdfdata"},slot:"pdfdata"},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"sm":"12"}},[_c('b-col',{staticClass:"my-1",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":_vm.$t('com_filter', { ns: 'common' }),"label-for":"filter-input","label-cols-sm":"3","label-align-sm":"right","label-size":"sm"}},[_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-form-input',{attrs:{"id":"filter-input","type":"search","placeholder":_vm.$t('com_typetosearch', { ns: 'common' })},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.filter},on:{"click":function($event){_vm.filter = ''}}},[_vm._v(_vm._s(_vm.$t('com_wis', { ns: 'common' })))])],1)],1),(
                                _vm.allowEdit &&
                                _vm.checkRight('AnalyticsMeasurementCreate')
                            )?_c('b-button',{staticClass:"bgc_aux_normal",attrs:{"size":"sm"},on:{"click":function($event){return _vm.createNewItem()}}},[_c('font-awesome-icon',{attrs:{"icon":"folder-plus","size":"1x"}})],1):_vm._e(),_vm._v("   "),(_vm.checkRight('AnalyticsMeasurementCreate'))?_c('b-button',{staticClass:"bgc_aux_unimportant",attrs:{"size":"sm"},on:{"click":function($event){return _vm.scan()}}},[_c('font-awesome-icon',{attrs:{"icon":"barcode","size":"1x"}})],1):_vm._e()],1)],1),_c('b-col',{staticClass:"my-1",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":_vm.$t('com_perpagina', { ns: 'common' }),"label-for":"per-page-select","label-cols-sm":"6","label-cols-md":"4","label-cols-lg":"3","label-align-sm":"right","label-size":"sm"}},[_c('b-form-select',{attrs:{"id":"per-page-select","options":_vm.pageOptions,"size":"sm"},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"align":"fill","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1),_c('b-table',{attrs:{"items":_vm.displayList,"fields":_vm.visibleFields,"current-page":_vm.currentPage,"per-page":_vm.perPage,"filter":_vm.filter,"filter-included-fields":_vm.filterOn,"stacked":"md","show-empty":"","empty-text":_vm.$t('com_geen_data', { ns: 'common' }),"small":"","striped":""},on:{"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(row){return [_c('div',{staticClass:"form-inline"},[(
                                    _vm.checkRight('AnalyticsMeasurementCreate')
                                )?_c('b-button',{staticClass:"bgc_aux_normal",attrs:{"size":"sm"},on:{"click":function($event){return _vm.createNewItem(row.item)}}},[_c('font-awesome-icon',{attrs:{"icon":"folder-plus","size":"1x"}})],1):_vm._e(),(
                                    _vm.checkRight('AnalyticsMeasurementUpdate')
                                )?_c('b-button',{staticClass:"bgc_aux_warning",attrs:{"size":"sm"},on:{"click":function($event){return _vm.bewerkItem(row.item)}}},[_c('font-awesome-icon',{attrs:{"icon":"edit","size":"1x"}})],1):_vm._e(),(
                                    _vm.showPlan &&
                                    _vm.checkRight('AnalyticsMeasurementDelete')
                                )?_c('b-button',{staticClass:"bgc_aux_alarm",attrs:{"size":"sm"},on:{"click":function($event){return _vm.verwijderItem(row.item)}}},[_c('font-awesome-icon',{attrs:{"icon":"trash-alt","size":"1x"}})],1):_vm._e(),_c('b-button',{staticClass:"bgc_aux_unknown",attrs:{"size":"sm"},on:{"click":function($event){return _vm.infoItem(row.item)}}},[_c('font-awesome-icon',{attrs:{"icon":"info-circle","size":"1x"}})],1)],1)]}},{key:"row-details",fn:function(){return [_c('sample-info',{attrs:{"inItem":_vm.localItem}})]},proxy:true}])}),(_vm.displayList.length == 0)?_c('div',[_vm._v(" "+_vm._s(_vm.strNoData)+" ")]):_vm._e()],1)],1)],1),(_vm.showDetailSample)?_c('modal-sample',{attrs:{"inItem":_vm.sample,"isNew":_vm.isNew},on:{"forcerefresh":_vm.doRefresh,"hide":_vm.reset},model:{value:(_vm.showDetailSample),callback:function ($$v) {_vm.showDetailSample=$$v},expression:"showDetailSample"}}):_vm._e(),(_vm.showScanner)?_c('scan-barcode',{on:{"result":_vm.showSampleDetail},model:{value:(_vm.showScanner),callback:function ($$v) {_vm.showScanner=$$v},expression:"showScanner"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }