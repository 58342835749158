<template>
    <b-container fluid style="margin-top: 60px">
        <div class="form-inline">
            <h1 v-on:click="flipClosed">
                {{ title }} &nbsp;
                <b-button>
                    {{ btnTekst }}
                </b-button>
            </h1>
            &nbsp;
            <b-button @click="flipDevice()" class="bgc_aux_normal"
                >{{ btnFlipAPI }}
            </b-button>
            &nbsp;
            <b-button
                @click="scan()"
                class="bgc_aux_unimportant float-md-right"
                v-if="!showDevice"
                ><font-awesome-icon icon="barcode" size="1x" />
            </b-button>
            &nbsp;
        </div>
        <b-row>
            <b-col sm="12">
                <div v-if="showFilter">
                    <b-row no-gutters>
                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_filter', { ns: 'common' })"
                                label-for="filter-input"
                                label-cols-sm="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-input-group size="sm">
                                    <b-form-input
                                        id="filter-input"
                                        v-model="filter"
                                        type="search"
                                        :placeholder="
                                            $t('com_typetosearch', {
                                                ns: 'common'
                                            })
                                        "
                                    ></b-form-input>

                                    <b-input-group-append>
                                        <b-button
                                            :disabled="!filter"
                                            @click="filter = ''"
                                            >{{
                                                $t('com_wis', { ns: 'common' })
                                            }}</b-button
                                        >
                                    </b-input-group-append>
                                </b-input-group>

                                <b-input-group size="sm">
                                    <b-button
                                        v-if="
                                            !gesloten &&
                                            checkRight(
                                                'ChangeExternalCommnication'
                                            )
                                        "
                                        size="sm"
                                        @click="createNewItem"
                                        class="mr-1"
                                        ><font-awesome-icon
                                            icon="folder-plus"
                                            size="1x"
                                        />
                                        {{ strNieuw }}
                                    </b-button>
                                    &nbsp;
                                </b-input-group>
                            </b-form-group>
                        </b-col>

                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_perpagina', { ns: 'common' })"
                                label-for="per-page-select"
                                label-cols-sm="6"
                                label-cols-md="4"
                                label-cols-lg="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-form-select
                                    id="per-page-select"
                                    v-model="perPage"
                                    :options="pageOptions"
                                    size="sm"
                                ></b-form-select>
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                ></b-pagination>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div v-else>
                    <b-button
                        v-if="
                            !gesloten &&
                            checkRight('ChangeExternalCommnication')
                        "
                        size="sm"
                        @click="createNewItem"
                        class="mr-1"
                        ><font-awesome-icon icon="folder-plus" size="1x" />
                        {{ strNieuw }}
                    </b-button>
                </div>

                <b-table
                    :items="displayList"
                    :fields="visibleFields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    stacked="md"
                    show-empty
                    :empty-text="$t('com_geen_data', { ns: 'common' })"
                    small
                    striped
                    @filtered="onFiltered"
                >
                    <template v-slot:cell(actions)="row">
                        <b-button
                            v-if="
                                !gesloten &&
                                checkRight('ChangeExternalCommnication') &&
                                showDevice
                            "
                            size="sm"
                            @click="newToken(row.item)"
                            class="bgc_aux_normal"
                            ><font-awesome-icon icon="envelope" size="1x" />
                        </b-button>
                        <b-button
                            v-if="
                                !gesloten &&
                                checkRight('ChangeExternalCommnication')
                            "
                            size="sm"
                            @click="verwijderItem(row.item)"
                            class="bgc_aux_alarm"
                            ><font-awesome-icon icon="trash-alt" size="1x" />
                        </b-button>
                    </template>
                </b-table>
            </b-col>
        </b-row>

        <scan-barcode
            v-if="showScanner"
            v-model="showScanner"
            @result="showExtConnScan"
        />
        <external-connection-modal
            v-if="showDetails"
            :inItem="currentItem"
            v-model="showDetails"
            v-bind:editAllowed="true"
            @hide="reset"
            @saved="reloadData()"
        />
    </b-container>
</template>

<script>
import _ from 'lodash';
import { ExternalDevice, DeviceType } from '../../models/Extern';
import ExternalConnectionModal from './ExternalConnectionModal';
import ScanBarcode from '../components/ScanBarcode.vue';
import { Permission } from '../../models/Permissions';
import Helper from '@/models/Helper';
import DateHelper from '../../models/DateHelper';
import { loadItem, loadList } from '@/models/DataHelper';

export default {
    name: 'ExternalConnBase',
    data: function () {
        return {
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [10, 20, 40, { value: 100, text: '100' }],
            filter: null,
            filterOn: [],
            currentItem: null,
            gesloten: false,
            showDevice: false,
            showScanner: false,
            showDetails: false,
            localList: [],
            displayFilterCount: 10,
            itemNumber: null
        };
    },
    props: {},
    components: {
        ExternalConnectionModal,
        ScanBarcode
    },
    computed: {
        strNieuw() {
            return this.$t('com_register', {
                ns: 'common'
            });
        },
        title() {
            const typetekst = this.showDevice
                ? this.$t('com_extkoppelingen', {
                      ns: 'common'
                  }).toLowerCase()
                : this.$t('com_datacollectoren', {
                      ns: 'common'
                  }).toLowerCase();
            return this.gesloten
                ? this.$t('com_gesloten', {
                      ns: 'common',
                      val: typetekst
                  })
                : this.$t('com_beschikbaar', {
                      ns: 'common',
                      val: typetekst
                  });
        },
        btnTekst() {
            return Helper.stringformat(
                '{0} {1}',
                this.$t('com_show', { ns: 'common' }),
                this.gesloten
                    ? this.$t('com_open', { ns: 'common' }).toLowerCase()
                    : this.$t('com_gesloten', {
                          ns: 'common',
                          val: ''
                      }).toLowerCase()
            );
        },
        btnFlipAPI() {
            const toon = this.$t('com_show', { ns: 'common' });
            return Helper.stringformat(
                '{0} {1}',
                toon,
                this.showDevice
                    ? this.$t('com_datacollectoren', {
                          ns: 'common'
                      }).toLowerCase()
                    : this.$t('com_extkoppelingen', {
                          ns: 'common'
                      }).toLowerCase()
            );
        },
        showFilter() {
            return this.displayList
                ? this.displayList.length > this.displayFilterCount
                : false;
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                {
                    key: 'tijdstip',
                    label: this.$t('com_tijdstip', { ns: 'common' }),
                    sortable: true,
                    visible: true,
                    formatter: (value, key, item) => {
                        const temp = this.gesloten
                            ? DateHelper.convertedToDate(item.verwijderd)
                            : DateHelper.convertedToDate(item.tijdstip);
                        return temp;
                    }
                },
                {
                    key: 'naam',
                    label: this.$t('com_naam', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'keynaam',
                    label: this.$t('com_keynaam', { ns: 'common' }),
                    sortable: true,
                    visible: true
                }
            ];
            const fieldsInitAPI = [
                {
                    key: 'ondType',
                    label: this.$t('com_actie', { ns: 'common' }),
                    sortable: true,
                    visible: true,
                    formatter: (value, key, item) => {
                        const temp = new DeviceType(
                            { id: item.ondType },
                            this.$t
                        );
                        return temp.naam;
                    }
                },
                {
                    key: 'token',
                    label: this.$t('com_token', { ns: 'common' }),
                    sortable: true,
                    visible: false
                },
                {
                    key: 'email',
                    label: this.$t('com_mail', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'url',
                    label: this.$t('com_url', { ns: 'common' }),
                    sortable: true,
                    visible: true
                }
            ];
            const fieldsInitDevice = [
                {
                    key: 'eigenid',
                    label: this.$t('com_deviceid', { ns: 'common' }),
                    sortable: true,
                    visible: true
                }
            ];
            if (this.showDevice) {
                fieldsInit.push(...fieldsInitAPI);
            } else {
                fieldsInit.push(...fieldsInitDevice);
            }
            return fieldsInit.filter((field) => field.visible);
        },
        displayList() {
            const tempList = this.gesloten
                ? this.localList.filter(
                      (x) =>
                          x.verwijderd != undefined &&
                          x.ondType != window.constants.API_UNKNOWN
                  )
                : this.localList.filter(
                      (x) =>
                          x.verwijderd == undefined &&
                          x.ondType != window.constants.API_UNKNOWN
                  );
            let result = null;
            if (this.showDevice) {
                result = tempList.filter(
                    (x) => x.ondType != window.constants.API_DEVICE
                );
            } else {
                result = tempList.filter(
                    (x) => x.ondType == window.constants.API_DEVICE
                );
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.totalRows = result ? result.length : 0;
            return result;
        }
    },
    methods: {
        flipClosed() {
            this.gesloten = !this.gesloten;
            this.reloadData();
        },
        flipDevice() {
            this.showDevice = !this.showDevice;
            this.filter = null;
            this.reloadData();
        },
        scan() {
            this.showScanner = !this.showScanner;
        },
        showExtConnScan(value) {
            const itemKey = value;
            this.currentItem = new ExternalDevice();
            this.currentItem.ondType = window.constants.API_DEVICE;
            this.currentItem.eigenid = itemKey;
            this.showDetails = true;
        },
        async getDetailItem(item) {
            return await loadItem(
                'extern/loadExternalDevice',
                { id: item.id },
                this.$t
            );
        },
        createNewItem() {
            this.currentItem = new ExternalDevice();
            this.currentItem.ondType = window.constants.API_UNKNOWN;
            this.showDetails = true;
        },
        async newToken(item) {
            const localItem = await this.getDetailItem(item);
            if (localItem) {
                this.currentItem = localItem;
                this.showDetails = true;
            }
        },
        async verwijderItem(item) {
            const t = await this.getDetailItem(item);
            const doClose = await this.$root.showConfirmationBox(
                this.$t('com_delete', {
                    ns: 'common',
                    val: this.$t('com_extregistratie', { ns: 'common' })
                }) +
                    ' : ' +
                    item.naam
            );
            if (doClose) {
                await loadItem(
                    'extern/deleteExternalDevice',
                    { id: t.id },
                    this.$t
                );
                this.reloadData();
            }
        },
        reset() {
            this.currentItem = null;
            this.showDetails = false;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async reloadData() {
            this.localList = await loadList(
                'extern/getExtDevicesLijst',
                'extern/loadExternalDevices',
                null,
                true,
                this.$t
            );
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        }
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.displayList ? this.displayList.length : 0;
    },
    async created() {
        await this.reloadData();
    }
};
</script>

<style scoped></style>

