<template>
    <div>
        <b-button
            @click="generateReport()"
            v-b-tooltip.hover
            :title="
                $t('com_downloadas', {
                    ns: 'common',
                    val: 'PDF'
                })
            "
            class="bgc_aux_alarm"
            ><font-awesome-icon icon="file-pdf" size="1x" />
        </b-button>
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            :margin="[20, 0, 20, 0]"
            :filename="filenaam"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="a4"
            :pdf-orientation="orientation"
            pdf-content-width="100%"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
            ref="html2Pdf"
        >
            <section slot="pdf-content">
                <h2>{{ titel }}</h2>
                <hr />
                <b-table
                    striped
                    hover
                    responsive
                    :items="reportList"
                    primary-key
                    small
                    stacked="md"
                >
                </b-table>
                <div v-if="reportList.length === 0">
                    {{ $t('com_geen_data', { ns: 'common' }) }}
                </div>
            </section>
        </vue-html2pdf>
    </div>
</template>
<script>
import VueHtml2pdf from 'vue-html2pdf';

export default {
    name: 'PrintToPdf',
    data: function () {
        return {
            errorMessage: ''
        };
    },
    components: {
        VueHtml2pdf
    },
    props: {
        titel: {
            type: String,
            required: true
        },
        filenaam: {
            type: String,
            required: false
        },
        reportList: {
            type: Array,
            required: true
        },
        orientation: {
            type: String,
            required: false,
            default: 'portrait'
        }
    },
    computed: {
        reportFileName: function () {
            const result = this.filenaam;
            return result;
        }
    },
    methods: {
        generateReport() {
            this.$refs.html2Pdf.generatePdf();
        }
    }
};
</script>

<style scoped>
.small {
    size: 'sm';
}
</style>

