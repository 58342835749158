const getters = {
    getUnitsComboLijst: state => {
        return state.unitsComboList;
    },
    Oud: state => (id) => {
        if (id) {
            return state.unitsComboList.filter(x => x.value == id || x.base == id);
        } else {
            return state.unitsComboList;
        }
    },
    getUnitsComboLijstBaseOnly: state  => (id) => {
        if (id) {
            return state.unitsComboList.filter(x => x.value == id );
        } else {
            return state.unitsComboList.filter(x => x.base == undefined);
        }
    },
    getUnitIDbyName: state => (text) => {
          let id =-1;
        if (text) {
            const unit = state.unitsComboList.find(x => x.naam.toLowerCase() === text.toLowerCase());
            if (unit) id = unit.value;
        }
        return id;
    },
    getUnitbyID: state => (id) => {
        const unit = state.unitsComboList.find(x => x.value === id);
        return unit;
    },
    getShapesComboLijst: state => {
        return state.shapesComboList;
    },
    getShapebyID: state => (id) => {
        return state.shapesComboList.find(x => x.value == id);
    },
    getCheeseMarksComboLijst: state => {
        return state.marktypesComboList;
    },
    getMilkTypeIGComboLijst: state => {
        return state.milktypeIGComboList;
    },
    getIGComboLijst: state => {
        return state.IGComboList;
    },
    getIngredientComboLijst: state => {
        return state.IngredientComboList;
    },
    getIngredientComboLijstbyID: state => (id) => {
        return state.IngredientComboList.find(x => x.value == id);
    },   
    getFatClassComboLijst: state => {
        return state.fatclassComboList;
    },
    getCheesesComboLijst: state => {
            return state.cheesesComboList;
    }, 
    getCheesesWithoutRecipeComboLijst: state => {
        return state.cheesesWithoutRecipeComboList;
    },
    getCheesesAlternativesComboLijst: state => {
        return state.cheeseAlternativesComboList;
    },   
    getCheesesAlternativesbyID: state => (id) => {
        const iets = state.cheeseAlternativesComboList.find(x => x.value == id);
        return iets;
    }, 
    getFailureCheeseComboLijst: state => {
        return state.FailureCheeseComboList;
    },
    getCompaniesComboLijst: state => {
        return state.companiesComboList;
    },
    getProductsComboLijst: state => {
        return state.productsComboList;
    },
    getBrineIngrComboLijst: state => {
        return state.brineIngrComboList;
    },    
    getProductsbyID: state => (id) => {
        return state.productsComboList.find(x => x.value == id);
    },
    getLocationsComboLijst: state => {
        return state.locationsComboList;
    },
    getSimpleProdComboLijst: state => {
        return state.simpleProdComboList;
    }
    
};

export default getters;
