import { render, staticRenderFns } from "./ItemDetailsModal.vue?vue&type=template&id=8d889b34&scoped=true"
import script from "./ItemDetailsModal.vue?vue&type=script&lang=js"
export * from "./ItemDetailsModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d889b34",
  null
  
)

export default component.exports