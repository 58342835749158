<template>
    <div style="margin-top: 60px">
        <b-form @submit.prevent="handleSubmit">
            <b-img center fluid-grow src="logo.png" alt="Logo"></b-img>

            <b-row>
                <b-col lg="4" class="pb-2"></b-col>
                <b-col lg="4" class="pb-2"
                    ><b-button type="submit" variant="primary"
                        >{{$t('nav_aanmelden', { ns: 'nav' })}}</b-button
                    ></b-col
                >
                <b-col lg="4" class="pb-2"></b-col>
            </b-row>
            <b-row>
                <b-col lg="2" class="pb-2"></b-col>
                <b-col lg="8" class="pb-2">
                    <b-link center @click="showResetPass()"
                        >{{$t('nav_forgotpass', { ns: 'nav' })}}</b-link
                    ></b-col
                >
                <b-col lg="2" class="pb-2"></b-col>
            </b-row>
        </b-form>
        <reset-pass-modal
            v-if="resetVisible"
            v-model="resetVisible"
            @hide="reset"
        />
    </div>
</template>

<script>
import { ResetPassModal } from '../gebruiker';
export default {
    name: 'Login',
    data: function () {
        return {
            errorMessage: '',
            zichtbaar: false,
            resetVisible: false
        };
    },
    components: {
        ResetPassModal
    },
    props: {
        navigeerUrl: {
            type: String,
            required: false
        }
    },
    computed: {
        selectData: function () {
            const selectionData = {
                us_id: this.$store.getters['account/getClaim']('km')
            };
            return selectionData;
        }
    },
    methods: {
        reset() {
            this.resetVisible = false;
        },
        showResetPass() {
            this.resetVisible = true;
        },
        async handleSubmit() {
            // ga naar identity
            //     this.$router.push({ name: 'productie' });
            this.$router.push({ name: 'empty' });
        }
    }
};
</script>

<style scoped></style>

