<template>
    <div>
        <p>Sign-in in progress</p>
    </div>
</template>

<script>
import { SecurityService } from './security';
import logMessage from '../errors/foutlog';
import { loadItem } from '@/models/DataHelper';

export default {
    name: 'signin-oidc',
    async created() {
        try {
            const manager = new SecurityService();
            const result = await manager.signinRedirectCallback();

            const jwtToken = await manager.getAccessToken();
            loadItem('account/loadUserManager', manager);
            await loadItem('account/loadUser', manager);

            logMessage('callback :' + jwtToken, 'info');
            this.$store.commit('account/setJWTToken', jwtToken);

            const usid = this.$store.getters['account/getClaim']('km');
            loadItem(
                'account/reloadLoggedInUser',
                {
                    userid: usid
                },
                this.$t
            );
            logMessage('user loaded :' + usid, 'info');
            // haal data op voor instellingen
            await loadItem('masterdata/loadDeployedPackageInfo', null, this.$t);
            loadItem('masterdata/loadApplicationConstants', null, this.$t);
            const is10 =
                this.$store.getters['masterdata/getPackageVersion'] ==
                window.constants.VERSION10;

            let returnToUrl = '/';
            if (result.state !== undefined) {
                returnToUrl = result.state;
                if (returnToUrl == '/startpagina') {
                    if (is10) {
                        returnToUrl = '/10plus/productie';
                    } else {
                        await loadItem('masterdata/loadMessages', null, this.$t);
                        returnToUrl = '/productie/productie';
                    }
                }
            }
            this.$router.push({ path: returnToUrl });
            logMessage('callback : hier alles goed', 'info');
        } catch (e) {
            logMessage('callback : error', 'error');
            logMessage(e, 'error');
            await this.$router.push({ name: 'Unauthorized' });
        }
    }
};
</script>
