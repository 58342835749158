const getters = {
    getReportItems: state =>  {
        return state.reportItemList;
    },
    getReportItemsPaged: (state) => (ctx) => {
        const pag = ctx.currentPage;
        const pagcount = ctx.perPage;
        const start = (pag * pagcount) -1; 
        return state.reportItemList.slice(start,pagcount);
    },
};

export default getters;

