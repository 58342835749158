<template>
    <b-container fluid class="bs-example">
        <b-card no-body class="mb-1">
            &nbsp;
            <b-table
                striped
                responsive
                :fields="visibleFields"
                :items="sortedList"
                multi
                selectable
                ref="selectableTable"
                @row-selected="onRowSelected"
            >
                <template v-slot:cell(actions)="row">
                    <b-button
                        v-if="!isVersion10"
                        size="sm"
                        @click="editItem(row.item)"
                        class="bgc_aux_warning"
                        ><font-awesome-icon icon="edit" size="1x" />
                    </b-button>
                    <b-button
                        v-if="
                            !gesloten &&
                            isNotCheese(row.item) &&
                            checkRight('StockDelete') &&
                            !isVersion10
                        "
                        :disabled="inGebruik(row.item)"
                        size="sm"
                        @click="verwijderItem(row.item)"
                        class="bgc_aux_alarm"
                        ><font-awesome-icon icon="trash-alt" size="1x" />
                    </b-button>
                    <b-button
                        v-if="!gesloten"
                        size="sm"
                        @click="printLabel(row.item)"
                        class="bgc_aux_unimportant"
                        ><font-awesome-icon icon="print" size="1x" />
                    </b-button>
                    <b-dropdown
                        v-if="!gesloten && externVoorraad(row.item)"
                        id="dropdown-form"
                        text="..."
                        ref="dropdown"
                        size="sm"
                        boundary="viewport"
                        @show="setStartValues(row.item)"
                    >
                        <div class="fluid-container">
                            <b-dropdown-form>
                                <div
                                    v-if="
                                        !erisselected &&
                                        checkRight('StockOut') 
                                    "
                                >
                                    <b-form-group
                                        :label="
                                            $t('log_verkoop', {
                                                ns: 'logistics'
                                            })
                                        "
                                        label-for="dropdown-form-correct"
                                        @submit.stop.prevent
                                    >
                                        <b-form-input
                                            id="dropdown-form-correct"
                                            v-model.number="verkoopAmount"
                                            :placeholder="
                                                $t('log_verkoopaantal', {
                                                    ns: 'logistics'
                                                })
                                            "
                                        ></b-form-input>
                                        <b-form-input
                                            v-model="verkoopID"
                                            :placeholder="
                                                $t('log_verkoopref', {
                                                    ns: 'logistics'
                                                })
                                            "
                                        ></b-form-input>
                                        <lookup-combo
                                            v-model="klantID"
                                            v-bind:combotype="lctypeCompany"
                                        ></lookup-combo>
                                        <b-button
                                            class="float-right"
                                            variant="primary"
                                            @click="saveVerkoop(row.item)"
                                            >Ok</b-button
                                        >
                                    </b-form-group>
                                </div>
                                <b-dropdown-divider></b-dropdown-divider>
                            </b-dropdown-form>
                            <b-dropdown-form>
                                <div
                                    v-if="
                                        !erisselected && checkRight('StockMove')
                                    "
                                >
                                    <b-form-group
                                        :label="
                                            $t('log_verplaats', {
                                                ns: 'logistics'
                                            })
                                        "
                                        label-for="dropdown-form-move"
                                        @submit.stop.prevent
                                    >
                                        <div class="form-inline">
                                            <b-form-input
                                                id="dropdown-form-correct"
                                                v-model.number="verplaatsAmount"
                                                :placeholder="
                                                    $t('log_correctieaantal', {
                                                        ns: 'logistics'
                                                    })
                                                "
                                            ></b-form-input>
                                            <lookup-combo
                                                id="dropdown-form-move"
                                                v-model="newlocationID"
                                                v-bind:combotype="
                                                    lctypeLocation
                                                "
                                            ></lookup-combo>
                                        </div>
                                        <b-form-input
                                            v-model="verplaatsExternalID"
                                            :placeholder="
                                                $t('log_externalref', {
                                                    ns: 'logistics'
                                                })
                                            "
                                        ></b-form-input>
                                        <b-button
                                            class="float-right"
                                            variant="primary"
                                            @click="saveLocation(row.item)"
                                            >Ok</b-button
                                        >
                                    </b-form-group>
                                </div>
                                <b-dropdown-divider></b-dropdown-divider>
                            </b-dropdown-form>
                            <b-dropdown-form>
                                <div
                                    v-if="
                                        !erisselected &&
                                        checkRight('StockCorrect')
                                    "
                                >
                                    <b-form-group
                                        :label="
                                            $t('log_corrigeer', {
                                                ns: 'logistics'
                                            })
                                        "
                                        label-for="dropdown-form-correct"
                                        @submit.stop.prevent
                                    >
                                        <b-form-input
                                            id="dropdown-form-correct"
                                            v-model.number="newAmount"
                                            :placeholder="
                                                $t('com_newx', {
                                                    ns: 'common',
                                                    val: $t('com_number', {
                                                        ns: 'common'
                                                    })
                                                })
                                            "
                                        ></b-form-input>
                                        <b-button
                                            class="float-right"
                                            variant="primary"
                                            @click="saveAmount(row.item)"
                                            >Ok</b-button
                                        >
                                    </b-form-group>
                                </div>
                                <b-dropdown-divider></b-dropdown-divider>
                                <b-dropdown-item
                                    v-if="checkRight('StockClose')"
                                    @click="saveStockZero(row.item)"
                                >
                                    {{
                                        $t('log_closenostock', {
                                            ns: 'logistics'
                                        })
                                    }}</b-dropdown-item
                                >
                                <b-dropdown-item
                                    v-if="checkRight('StockClose')"
                                    @click="saveStockClose(row.item)"
                                    >{{
                                        $t('log_closenouse', {
                                            ns: 'logistics'
                                        })
                                    }}</b-dropdown-item
                                >
                            </b-dropdown-form>
                        </div>
                    </b-dropdown>
                </template>
                <template v-slot:row-details>
                    <print-prod-label
                        v-bind:visible="printVisible"
                        v-bind:stockItemID="stockitemToPrint"
                        v-bind:landscape="landscape"
                    />
                </template>
                <template v-slot:cell(age)="{ item }">
                    <span
                        :class="{
                            'text-green': item.age >= item.id,
                            'text-red': item.age < item.id
                        }"
                    >
                        {{ item.age }}
                    </span>
                </template>
            </b-table>
            <p>
                <b-button
                    size="sm"
                    v-if="erisselected"
                    @click="clearSelected"
                    >{{
                        $t('com_wisselectie', {
                            ns: 'common'
                        })
                    }}</b-button
                >
            </p>
        </b-card>
        <item-stock-modal
            v-if="detailsVisible"
            :inItem="currentItem"
            :tienplus="tienplus"
            v-model="detailsVisible"
            v-bind:editAllowed="editAllowed"
            @hide="reset"
        />
    </b-container>
</template>

<script>
import _ from 'lodash';
import { PRODCAT_ENUM } from '../../models/ProductCategorie';
import ItemStockModal from './ItemStockModal';
import { LookupCombo, PrintProdLabel } from '../components';
import DateHelper from '../../models/DateHelper';
import { Permission } from '../../models/Permissions';
import { Feature } from '@/models/Features';
import { loadItem, loadList } from '@/models/DataHelper';
export default {
    name: 'StockItems',
    data() {
        return {
            lctypeLocation: window.constants.LCLOCATION,
            lctypeCompany: window.constants.LCCOMPANY,
            currentItem: null,
            detailsVisible: false,
            printid: -1,
            printVisible: false,
            editAllowed: false,
            newlocationID: null,
            verkoopAmount: null,
            verkoopID: null,
            klantID: -1,
            verplaatsAmount: null,
            verplaatsExternalID: null,
            newAmount: null,
            selected: []
        };
    },
    props: {
        stocklist: {
            type: Array,
            required: true
        },
        gesloten: {
            type: Boolean,
            default: false
        },
        pekel: {
            type: Boolean,
            default: false
        },
        tienplus: {
            type: Boolean,
            default: false
        },
        tienpluskaas: {
            type: Boolean,
            default: false
        },
        landscape: {
            type: Boolean,
            default: false
        }
    },
    components: {
        LookupCombo,
        ItemStockModal,
        PrintProdLabel
    },
    computed: {
        erisselected() {
            return this.selected.length > 1;
        },
        stockitemToPrint() {
            return this.printid;
        },
        isVersion10() {
            return (
                this.$store.getters['masterdata/getPackageVersion'] ==
                window.constants.VERSION10
            );
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: this.pekel == false },
                {
                    key: 'id',
                    label: this.$t('com_barcode', { ns: 'common' }),
                    sortable: false,
                    visible: true
                },
                {
                    key: 'batchID',
                    label: this.$t('com_batch', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'status',
                    label: this.$t('com_status', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'aantal',
                    label: this.$t('com_amount', { ns: 'common' }),
                    sortable: true,
                    visible: true,
                    tdClass: (value) =>
                        value < 0 ? 'text-danger' : 'text-success'
                },
                {
                    key: 'eenheid',
                    label: this.$t('com_unit', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'locatie',
                    label: this.$t('com_location', { ns: 'common' }),
                    sortable: true,
                    visible: this.pekel == false
                }
            ];
            let fieldsExtra = null;
            if (this.checkNotCheese()) {
                if (this.pekel == false) {
                    if (this.tienplus == false) {
                        fieldsExtra = [
                            {
                                key: 'datum',
                                label: this.$t('com_tht', { ns: 'common' }),
                                sortable: true,
                                visible: true,
                                formatter: (value, key, item) => {
                                    const temp = DateHelper.convertedToDate(
                                        item.datum
                                    );
                                    return temp;
                                },
                                tdClass: (value) => this.calcTHTalarm(value)
                            },
                            {
                                key: 'vanaf',
                                label: this.$t('log_inuse', {
                                    ns: 'logistics'
                                }),
                                sortable: true,
                                visible: true
                            },
                            {
                                key: 'supplierName',
                                label: this.$t('log_leverancier', {
                                    ns: 'logistics'
                                }),
                                sortable: true,
                                visible: this.useExternals
                            },
                    {
                        key: 'externalID',
                        label: this.$t('com_externalid', { ns: 'common' }),
                        sortable: true,
                        visible: true
                    }
                        ];
                    } else {
                        const iskaas = this.tienpluskaas;
                        fieldsExtra = [
                            {
                                key: 'productiedatum',
                                label: this.$t('com_production', {
                                    ns: 'common'
                                }),
                                sortable: true,
                                visible: true,
                                formatter: (value, key, item) => {
                                    const temp = DateHelper.convertedToDate(
                                        item.productiedatum
                                    );
                                    return temp;
                                }
                            },
                            {
                                key: 'vanaf',
                                label: this.$t('log_inuse', {
                                    ns: 'logistics'
                                }),
                                sortable: true,
                                visible: !iskaas
                            },
                            {
                                key: 'supplierName',
                                label: this.$t('log_leverancier', {
                                    ns: 'logistics'
                                }),
                                sortable: true,
                                visible: !iskaas
                            },
                            {
                                key: 'cheeseage',
                                label: this.$t('prod_leeftijd', {
                                    ns: 'production'
                                }),
                                sortable: true,
                                visible: iskaas,
                                formatter: (value, key, item) => {
                                    const temp = item.leeftijdKlasse;
                                    return temp
                                        ? item.leeftijdKlasse +
                                              ' (' +
                                              item.leeftijdDagen +
                                              ')'
                                        : '';
                                }
                            }
                        ];
                    }
                } else {
                    fieldsExtra = [
                        {
                            key: 'naam',
                            label: this.$t('com_product', { ns: 'common' }),
                            sortable: true,
                            visible: true
                        },
                        {
                            key: 'productiedatum',
                            label: this.$t('com_production', {
                                ns: 'common'
                            }),
                            sortable: true,
                            visible: true,
                            formatter: (value, key, item) => {
                                const temp = DateHelper.convertedToDate(
                                    item.productiedatum
                                );
                                return temp;
                            }
                        },
                        {
                            key: 'vorm',
                            label: this.$t('prod_vorm', {
                                ns: 'production'
                            }),
                            sortable: true,
                            visible: true
                        },
                        {
                            key: 'melkBron',
                            label: this.$t('log_source', {
                                ns: 'logistics'
                            }),
                            sortable: true,
                            visible: this.showBron
                        }
                    ];
                }
            } else {
                fieldsExtra = [
                    {
                        key: 'cheeseage',
                        label: this.$t('prod_leeftijd', {
                            ns: 'production'
                        }),
                        sortable: true,
                        visible: true,
                        formatter: (value, key, item) => {
                            const temp = item.leeftijdKlasse;
                            return temp
                                ? item.leeftijdKlasse +
                                      ' (' +
                                      item.leeftijdDagen +
                                      ')'
                                : '';
                        }
                    },
                    {
                        key: 'externalID',
                        label: this.$t('com_externalid', { ns: 'common' }),
                        sortable: true,
                        visible: true
                    },
                    {
                        key: 'pakbonID',
                        label:
                            this.$t('prod_pakbon', {
                                ns: 'production'
                            }) + '#',
                        sortable: true,
                        visible: this.isVersion30
                    },
                    {
                        key: 'melkBron',
                        label: this.$t('log_source', {
                            ns: 'logistics'
                        }),
                        sortable: true,
                        visible: this.showBron
                    }
                ];
            }
            fieldsInit.push(...fieldsExtra);
            fieldsInit.push({
                key: 'attcount',
                label: this.$t('com_bijlagen', { ns: 'common' }),
                sortable: true,
                visible: true
            });
            return fieldsInit.filter((field) => field.visible);
        },
        sortedList() {
            return _.sortBy(this.stocklist, ['batchID']);
        },
        isVersion30() {
            return (
                this.$store.getters['masterdata/getPackageVersion'] >=
                window.constants.VERSION30
            );
        },
        showBron() {
            return this.checkFeature('conf_useMilkSupplier');
        },
        useExternals() {
            return this.checkFeature('conf_useExternalConnections');
        }
    },
    methods: {
        externVoorraad(item) {
            if (this.useExternals) {
                return item.externalID == undefined;
            } else {
                return true;
            }
        },
        checkFeature(feature) {
            const key = Feature.enum(feature);
            return this.$root.handleFeature(key);
        },
        calcTHTalarm(value) {
            const vandaag = new Date();
            const dagen = DateHelper.dateDiffinDays(value, vandaag);
            // eslint-disable-next-line no-unused-vars
            const normaal =
                dagen > window.constants.ALARM_THTDATE ? true : false;
            const waarschuw =
                dagen < window.constants.ALARM_THTDATE ? true : false;
            const alarm =
                dagen < window.constants.ALARM_THTDATE / 2 ? true : false;
            return alarm
                ? 'text-danger'
                : waarschuw
                ? 'text-warning'
                : 'text-normal';
            //     return "bg-warning text-white";
        },
        onRowSelected(items) {
            this.selected = items;
        },
        clearSelected() {
            this.$refs.selectableTable.clearSelected();
        },
        reset() {
            this.detailsVisible = false;
            this.currentItem = null;
        },
        resetPrint() {
            this.printVisible = false;
            this.currentItem = null;
        },
        async getDetailItem(item) {
            return await loadItem(
                'stock/loadStockItem',
                {
                    stockitemid: item.id
                },
                this.$t
            );
        },
        async editItem(item) {
            const refreshedItem = await this.getDetailItem(item);
            this.editAllowed =
                this.checkRight('StockUpdate') &&
                refreshedItem.status < window.constants.STOCKCLOSED &&
                this.isNotCheese(item) &&
                !this.tienplus;
            this.currentItem = refreshedItem;
            this.detailsVisible = true;
        },
        printLabel(item) {
            this.printid = item.id;
            this.printVisible = true;
            if (item._showDetails) {
                this.$set(item, '_showDetails', false);
            } else {
                this.stocklist.forEach((stockListItem) => {
                    this.$set(stockListItem, '_showDetails', false);
                });
                this.$set(item, '_showDetails', true);
            }
        },
        isNotCheese(item) {
            const isKaas =
                item.prodcatID == PRODCAT_ENUM.Kaas ||
                item.prodcatID == PRODCAT_ENUM.Onvolwaardig;
            return !isKaas;
        },
        inGebruik(item) {
            return !item.bewerkbaar;
        },
        checkNotCheese() {
            const item = this.stocklist.length > 0 ? this.stocklist[0] : null;
            return item ? this.isNotCheese(item) : true;
        },
        async setStartValues(item) {
            const refreshedItem = await this.getDetailItem(item);
            this.verplaatsAmount = refreshedItem.aantal;
            this.newAmount = refreshedItem.aantal;
        },
        async verwijderItem(item) {
            this.getDetailItem(item).then((value) => {
                const refreshedItem = value;
                if (refreshedItem.status == window.constants.STOCKUNUSED) {
                    this.$root
                        .showConfirmationBox(
                            this.$t('com_delete', {
                                ns: 'common',
                                val: this.$t('prod_stockitem', {
                                    ns: 'production'
                                })
                            }) +
                                item.id +
                                ' : ' +
                                item.batchID
                        )
                        .then((res) => {
                            if (res == true) {
                                //const selectionData = ;
                                loadItem(
                                    'stock/deleteStockItem',
                                    {
                                        ID: refreshedItem.id,
                                        LastUpdated: refreshedItem.gewijzigd
                                    },
                                    this.$t
                                );
                            }
                        });
                } else {
                    this.$root.showMessageBox(
                        this.$t('prod_nodelete', {
                            ns: 'production'
                        })
                    );
                }
            });
        },
        async saveLocation(item) {
            const refreshedItem = await this.getDetailItem(item);
            const selectionData = {
                StockItemID: refreshedItem.id,
                ToStockLocationID: this.newlocationID,
                Amount: this.verplaatsAmount,
                ExternalID: this.verplaatsExternalID
            };
            loadItem('stock/moveStockItem', selectionData, this.$t);
        },
        async saveAmount(item) {
            const refreshedItem = await this.getDetailItem(item);
            if (refreshedItem.aantal == this.newAmount) {
                this.$root.showMessageBox(
                    this.$t('log_aantalongewijzigd', {
                        ns: 'logistics'
                    })
                );
                return;
            }
            const amount = refreshedItem.aantal - this.newAmount;
            const direction = amount > 0 ? window.constants.DIRECTION_OUT : window.constants.DIRECTION_IN ; 
            const selectionData = {
                StockItemID: refreshedItem.id,
                MutationAmount: Math.abs(amount),
                Direction: direction
            };
            loadItem('stock/correctStockAmount', selectionData, this.$t);
        },
        async saveVerkoop(item) {
            const refreshedItem = await this.getDetailItem(item);
            const selectionData = {
                StockItemID: refreshedItem.id,
                MutationAmount: Math.abs(this.verkoopAmount),
                MutationRef: this.verkoopID,
                CustomerID: this.klantID
            };
            loadItem('stock/solledStockAmount', selectionData, this.$t); 
        },
        async saveStockClose(item) {
            if (this.erisselected) {
                const doClose = await this.$root.showConfirmationBox(
                    this.$t('log_stocksclose', {
                        ns: 'logistics',
                        val: this.$t('log_close', {
                            ns: 'logistics'
                        }).toLowerCase()
                    }) +
                        this.selected.length +
                        ' ) '
                );
                if (doClose) {
                    for (let i = 0; i < this.selected.length; i++) {
                        const refreshedItem = await this.getDetailItem(
                            this.selected[i]
                        );
                        const selectionData = {
                            ID: refreshedItem.id,
                            LastUpdated: refreshedItem.gewijzigd,
                            IsEmpty: false,
                            NeverUseAgain: true
                        };
                        loadItem(
                            'stock/closeStockItem',
                            selectionData,
                            this.$t
                        );
                    }
                }
            } else {
                const doClose = await this.$root.showConfirmationBox(
                    this.$t('log_stockclose', {
                        ns: 'logistics',
                        val: this.$t('log_close', {
                            ns: 'logistics'
                        }).toLowerCase()
                    }) +
                        item.id +
                        ' : ' +
                        item.batchID
                );
                if (doClose) {
                    const refreshedItem = await this.getDetailItem(item);
                    const selectionData = {
                        ID: refreshedItem.id,
                        LastUpdated: refreshedItem.gewijzigd,
                        IsEmpty: false,
                        NeverUseAgain: true
                    };
                    loadItem('stock/closeStockItem', selectionData, this.$t);
                }
            }
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        },
        async saveStockZero(item) {
            if (this.erisselected) {
                const doClose = await this.$root.showConfirmationBox(
                    this.$t('log_stocksclose', {
                        ns: 'logistics',
                        val: this.$t('log_changeto', {
                            ns: 'logistics'
                        }).toLowerCase()
                    }) +
                        ' 0: (' +
                        this.selected.length +
                        ' ) '
                );
                if (doClose) {
                    for (let i = 0; i < this.selected.length; i++) {
                        const refreshedItem = await this.getDetailItem(
                            this.selected[i]
                        );
                        const selectionData = {
                            ID: refreshedItem.id,
                            LastUpdated: refreshedItem.gewijzigd,
                            IsEmpty: true,
                            NeverUseAgain: false
                        };
                        loadItem(
                            'stock/closeStockItem',
                            selectionData,
                            this.$t
                        );
                    }
                }
            } else {
                const doClose = await this.$root.showConfirmationBox(
                    this.$t('log_stockclose', {
                        ns: 'logistics',
                        val: this.$t('log_changeto', {
                            ns: 'logistics'
                        }).toLowerCase()
                    }) +
                        ' 0: ' +
                        item.id +
                        ' : ' +
                        item.batchID
                );
                if (doClose) {
                    const refreshedItem = await this.getDetailItem(item);
                    const selectionData = {
                        ID: refreshedItem.id,
                        LastUpdated: refreshedItem.gewijzigd,
                        IsEmpty: true,
                        NeverUseAgain: false
                    };
                    loadItem('stock/closeStockItem', selectionData, this.$t);
                }
            }
        }
    },
    mounted() {},
    created() {
        // egenlijk async maar fout bij product komt bijna niet voor
        const lijst = loadList(
            'product/getProductCategorieLijst',
            'product/loadProductCategories',
            null,
            false,
            this.$t
        );
    }
};
</script>

<style scoped>
::v-deep .table > tbody > tr.b-table-row-selected,
::v-deep .table > tbody > tr.b-table-row-selected > td,
::v-deep .table > tbody > tr.b-table-row-selected > th {
    background-color: #00a1de;
    color: ghostwhite !important;
}
.overflow-class /deep/ .dropdown-menu {
    max-height: 200px;
    overflow-y: auto;
}
</style>

