0
<template>
    <b-modal
        id="modal-assesment"
        size="xl"
        ref="modal"
        :title="this.formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
    >
        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
            {{ alertText }}
        </b-alert>

        <div>
            <b-card>
                <b-tabs card>
                    <b-tab
                        :title="$t('an_test', { ns: 'analytics' })"
                        v-bind:key="1"
                        active
                    >
                        <b-card-text>
                            <b-row>
                                <b-col sm="3">
                                    <label
                                        >{{
                                            $t('an_subject', {
                                                ns: 'analytics'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <entity-object-selector
                                        v-model="locItem.meetplekID"
                                        :disabled="!editAllowed"
                                        @input="setChanged"
                                        @eobject="setChangedEO"
                                    ></entity-object-selector>
                                </b-col>

                                <b-col sm="3">
                                    <label
                                        >{{
                                            $t('an_uitvoerder', {
                                                ns: 'analytics'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <lookup-combo
                                        v-model="locItem.uitvoerderID"
                                        v-bind:combotype="lctypeExecutor"
                                        :disabled="!editAllowed"
                                        :showfilter="true"
                                        @change="setChanged"
                                    ></lookup-combo>
                                </b-col>

                                <b-col sm="3">
                                    <label
                                        >{{
                                            $t('an_sample', {
                                                ns: 'analytics'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <lookup-combo
                                        v-model="locItem.monsterID"
                                        v-bind:combotype="lctypeSamples"
                                        :disabled="!editAllowed"
                                        :showfilter="true"
                                        @input="setChangedSample"
                                    ></lookup-combo>
                                </b-col>
                            </b-row>
                            <hr />
                            <b-row>
                                <b-col sm="3">
                                    <label
                                        >{{
                                            $t('an_meetdatum', {
                                                ns: 'analytics'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <analytics-date-time
                                        id="dateOnderzoek"
                                        v-model="datumOnderzoek"
                                        v-bind:editAllowed="editAllowed"
                                        v-bind:startNull="false"
                                        @change="setDatumOnderzoek"
                                    >
                                    </analytics-date-time>
                                </b-col>
                                <b-col sm="3">
                                    <label
                                        >{{
                                            $t('an_plandatum', {
                                                ns: 'analytics'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <analytics-date-time
                                        id="dateBoeking"
                                        v-model="datumBoeking"
                                        v-bind:editAllowed="editAllowed"
                                        v-bind:startNull="true"
                                        @change="setDatumBoeking"
                                    >
                                    </analytics-date-time>
                                </b-col>
                                <b-col sm="3">
                                    <label
                                        >{{
                                            $t('an_test', { ns: 'analytics' })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <assesment-combo
                                        v-model="locAssesmentObject"
                                        v-bind:editAllowed="editAllowed"
                                        @change="setChanged"
                                        @input="setAssesment"
                                    ></assesment-combo>
                                </b-col>
                                <b-form-textarea
                                    v-model="locItem.opmerking"
                                    :placeholder="
                                        $t('com_opmerking', { ns: 'common' })
                                    "
                                    rows="3"
                                    max-rows="6"
                                    @change="setChanged"
                                ></b-form-textarea>
                            </b-row>
                        </b-card-text>
                    </b-tab>
                    <b-tab
                        v-if="locItem.id > 0"
                        :title="$t('com_bijlagen', { ns: 'common' })"
                        v-bind:key="3"
                        @click="loadAttachments()"
                        lazy
                    >
                        <b-card-text>
                            <file-upload
                                :attID="locItem.id"
                                :attType="attachmentType"
                                @input="loadAttachments"
                                @queue="setQueuedAmount"
                            />
                            <b-table
                                :items="attachmentLijst"
                                :fields="visibleFields"
                                stacked="md"
                                show-empty
                                :empty-text="
                                    $t('com_geen_data', { ns: 'common' })
                                "
                                small
                                striped
                            >
                                <template v-slot:cell(actions)="row">
                                    <b-button
                                        size="sm"
                                        @click="showAttachment(row.item)"
                                        class="bgc_aux_normal"
                                        ><font-awesome-icon
                                            icon="edit"
                                            size="1x"
                                        />
                                    </b-button>
                                    <b-button
                                        size="sm"
                                        @click="verwijderAttachment(row.item)"
                                        class="bgc_aux_alarm"
                                        ><font-awesome-icon
                                            icon="trash-alt"
                                            size="1x"
                                        />
                                    </b-button>
                                </template>

                                <template v-slot:row-details> </template>
                            </b-table>
                        </b-card-text>
                    </b-tab>
                    <b-tab :title="$t('rec_pattern', { ns: 'recurrency' })">
                        <b-card-text>
                            <recurrency-base
                                :editAllowed="magOpslaan"
                                :pattern="herhaling"
                                @changed="setChangePattern"
                                @deleted="setDeletePattern"
                            />
                        </b-card-text>
                    </b-tab>
                </b-tabs>
            </b-card>
        </div>
    </b-modal>
</template>

<script>
import { AssesmentObject, Assesment } from '../../models/Anayltics';
import LookupCombo from './LookupCombo.vue';
import DateHelper from '../../models/DateHelper';
import { loadList, loadItem } from './utils';
import AnalyticsDateTime from './AnalyticsDateTime.vue';
import EntityObjectSelector from './EntityObjectSelector.vue';
import AssesmentCombo from './AssesmentCombo.vue';
import { RecurrencyBase } from '../recurrency';
import { AttachmentInfo } from '@/models/PlusProduction';
import { FileUpload } from '../components';
export default {
    name: 'ModalAssesment',
    data: function () {
        return {
            lctypeExecutor: window.constants.LCA_EXECUTOR,
            lctypeEO: window.constants.LCA_ENTITYOBJECT,
            lctypeSamples: window.constants.LCA_SAMPLES,
            attachmentType: window.constants.ATT_ASSESMENT,
            queuedAmount: 0,
            herhaling: null,
            attachmentLijst: [], // as Array<AttachmentInfo>,
            strDeleteAttachment: this.$t('com_delete', {
                ns: 'common',
                val: this.$t('com_bijlage', { ns: 'common' })
            }),
            showDismissibleAlert: false,
            forcerefresh: false,
            alertText: '',
            isChanged: false,
            naam: null,
            locItem: null,
            savePattern: null,
            deletePattern: null,
            //meetplekID: -1,
            locAssesmentObject: null,
            returnObject: null,
            editAllowed: true,
            datumBoeking: null,
            datumOnderzoek: null,
            buitenNormString: this.$t('an_warnbuitennormwaarde', {
                ns: 'analytics'
            })
        };
    },
    model: {
        prop: 'visible',
        event: 'changed'
    },
    props: {
        inItem: {
            type: Assesment,
            required: false
        },
        isNew: {
            type: Boolean,
            default: true
        },
        barcodeExecutor: {
            type: String,
            required: false
        },
        barcodeEntityObject: {
            type: String,
            required: false
        },
        barcodeSample: {
            type: String,
            required: false
        },
        idExecutor: {
            type: Number,
            required: false
        },
        idSample: {
            type: Number,
            required: false
        },
        idEntityObject: {
            type: Number,
            required: false
        },
        idQl: {
            type: Number,
            required: false
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    components: {
        LookupCombo,
        AssesmentCombo,
        EntityObjectSelector,
        FileUpload,
        RecurrencyBase,
        AnalyticsDateTime
    },
    watch: {
        barcodeExecutor() {
            this.checkBarcodeEX(this.barcodeExecutor);
        },
        barcodeEntityObject() {
            this.checkBarcodeEO(this.barcodeEntityObject);
        },
        barcodeSample() {
            this.checkBarcodeSample(this.barcodeSample);
        }
    },
    computed: {
        formtitle() {
            const lowtest = this.$t('an_test', {
                ns: 'analytics'
            }).toLowerCase();
            if (this.locItem == undefined)
                return this.$t('com_newx', {
                    ns: 'common',
                    val: lowtest
                });

            return this.locItem.isNew
                ? this.$t('com_makenew', {
                      ns: 'common',
                      val: lowtest
                  })
                : this.$t('com_edit', {
                      ns: 'common',
                      val: lowtest
                  });
        },
        magOpslaan() {
            const test = this.locItem != undefined || this.magEdit;
            return test;
        },
        magEdit() {
            return !(this.locItem.typenaam && this.locItem.veldid);
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                { key: 'naam', label: 'Naam', sortable: true, visible: true },
                {
                    key: 'mimetype',
                    label: 'Type',
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        }
    },
    methods: {
        printBarcode() {
            this.$htmlToPaper('targetDiv');
        },
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        hide() {
            this.$emit('hide', this.returnObject);
        },
        setDatumOnderzoek(value) {
            this.datumOnderzoek = value;
            this.setChanged();
        },
        setDatumBoeking(value) {
            this.datumBoeking = value;
            this.setChanged();
        },
        setChanged() {
            this.isChanged = true;
        },
        setChangedEO(value) {
            this.locItem.meetplekEO = value;
            this.setChanged();
        },
        setChangePattern(data) {
            if (data) {
                this.savePattern = data;
                this.isChanged = true;
            }
        },
        async setDeletePattern(data) {
            if (data) {
                const bericht = this.$t('com_delete', {
                    ns: 'common',
                    val: this.$t('rec_pattern', { ns: 'recurrency' })
                });
                const doClose = await this.$root.showConfirmationBox(bericht);
                if (doClose) {
                    this.deletePattern = data;
                    this.isChanged = true;
                } else {
                    this.deletePattern = null;
                    this.isChanged = true;
                }
            }
        },
        async setChangedSample(value) {
            // zonder () krijg je de nieuwe waarde
            const sample = await loadItem(
                'analytics/loadSample',
                { sampleID: value },
                this.$t
            );
            // check met de gegeven bij eo
            if (this.locItem.meetplekID > 0 && sample) {
                if (this.locItem.meetplekID != sample.meetplekID) {
                    const bericht = this.$t('an_deltaeosample', {
                        ns: 'analytics'
                    });
                    const gadoor = await this.$root.showConfirmationBox(
                        bericht
                    );
                    if (gadoor) {
                        this.locItem.meetplekID = sample.meetplekID;
                        //this.meetplekID = sample.meetplekID;
                        this.setChanged();
                    } else {
                        this.locItem.sampleID = undefined;
                    }
                }
            }
        },
        setAssesment(item) {
            if (item) {
                this.locItem.linkedlabels = item.linkedlabels;
                this.locItem.grootheidID = item.qualityid;
                this.locItem.labelID = item.qlabelid;
                this.locItem.waarde = item.uitslag;
            }
        },
        async checkFormValidity() {
            const invoerOK = true;
            const invalidBasis = this.locItem.CheckValidity(this.$t);
            if (invalidBasis) this.showAlert(invalidBasis);
            const valid =
                this.locItem != undefined && !invalidBasis && invoerOK;
            return valid;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        async handleSubmit() {
            this.locItem.meettijdstip = this.datumOnderzoek;
            this.locItem.boektijdstip = this.datumBoeking;
            // Exit when the form isn't valid
            if (this.isChanged) {
                if (!(await this.checkFormValidity())) {
                    return;
                }
                this.returnObject = await this.saveThisAssesment();
                this.$emit('forcerefresh', true);
            }
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-assesment');
            });
        },
        async saveThisAssesment() {
            if (this.savePattern) this.locItem.herhaling = this.savePattern;
            if (this.deletePattern) this.locItem.herhaling = this.deletePattern;
            const addObject = this.locItem.SetForAdd();
            return loadItem('analytics/saveAssesment', addObject, this.$t);
        },
        async checkBarcodeEO(value) {
            if (this.idEntityObject == undefined) {
                const lijst = await loadList(
                    'analytics/getEntityObjectLijst',
                    'analytics/loadEntityObjectByTypename',
                    null,
                    this.forcerefresh,
                    this.$t
                );
                const o = lijst.find((x) => x.barcode == value);
                if (o) {
                    this.idEntityObject = o.id;
                    this.locItem.meetplekID = o.id;
                }
            }
        },
        async checkBarcodeSample(value) {
            if (this.idSample == undefined) {
                const nu = new Date();
                const toen = DateHelper.SubstractYear(nu);
                const selectionData = {
                    from: DateHelper.localDateToUTC(toen),
                    until: DateHelper.localDateToUTC(nu)
                };
                const lijst = await loadList(
                    'analytics/getMonsterLijst',
                    'analytics/loadSampleLijst',
                    selectionData,
                    this.forcerefresh,
                    this.$t
                );
                const o = lijst.find((x) => x.barcode == value);
                if (o) {
                    this.idSample = o.id;
                    this.locItem.monsterID = o.id;
                    this.locItem.meetplekID = o.meetplekID;
                }
            }
        },
        async checkBarcodeEX(value) {
            // todo, nog geen barcode
            if (this.idExecutor == undefined) {
                const lijst = await loadList(
                    'analytics/getExecutorLijst',
                    'analytics/loadExecutorLijst',
                    null,
                    this.forcerefresh,
                    this.$t
                );
                const o = lijst.find((x) => (x.barcode = value));
                if (o) {
                    this.idExecutor = o.id;
                    this.locItem.uitvoerderID = o.id;
                }
            }
        },
        async loadAttachments() {
            this.attachmentLijst = await loadItem(
                'analytics/loadAttachmentInfo',
                {
                    data: {
                        id: this.locItem.id,
                        attachmentType: this.attachmentType
                    },
                    func: this.$t
                }
            );
        },
        setQueuedAmount(aant) {
            this.queuedAmount = aant;
        },
        async showAttachment(item) {
            return await loadItem(
                'analytics/loadAttachment',
                {
                    id: item.sourceID,
                    attachmentID: item.id,
                    attachmentType: this.attachmentType
                },
                this.$t
            ).then((response) => {
                const fileURL = window.URL.createObjectURL(
                    new Blob([response])
                );
                const fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', item.naam);
                document.body.appendChild(fileLink);

                fileLink.click();
            });
        },
        async verwijderAttachment(item) {
            const doClose = await this.$root.showConfirmationBox(
                this.strDeleteAttachment + item.id + ' : ' + item.naam
            );
            if (doClose) {
                await loadItem(
                    'analytics/deleteAttachment',
                    {
                        id: item.sourceID,
                        attachmentID: item.id,
                        attachmentType: this.attachmentType
                    },
                    this.$t
                );
                this.loadAttachments();
            }
        }
    },
    async mounted() {
        if (this.barcodeExecutor) {
            await this.checkBarcodeEX(this.barcodeExecutor);
        }
        if (this.barcodeEntityObject) {
            await this.checkBarcodeEO(this.barcodeEntityObject);
        }
        if (this.barcodeSample) {
            await this.checkBarcodeSample(this.barcodeSample);
        }
    },
    created() {
        if (this.inItem) {
            this.locItem = this.inItem;
            this.datumOnderzoek = this.locItem.meettijdstip;
            this.datumBoeking = this.locItem.boektijdstip;
            //this.meetplekID = this.locItem.meetplekID;
            this.locAssesmentObject = new AssesmentObject();
            this.locAssesmentObject.linkedlabels = this.locItem.linkedlabels;
            this.locAssesmentObject.qualityid = this.locItem.grootheidID;
            this.locAssesmentObject.qlabelid = this.locItem.labelID;
            this.locAssesmentObject.uitslag = this.locItem.waarde;
            this.herhaling = this.locItem.herhaling;
            this.naam = this.locItem.naam;
            if (this.isNew) {
                this.locItem.clearID();
                this.datumOnderzoek = new Date();
            }
        } else {
            this.datumOnderzoek = new Date();
            this.datumBoeking = new Date();
            this.locItem = new Assesment();
            this.locItem.onderzoeksgrootheidID = this.idQl;
            this.locItem.uitvoerderID = this.idExecutor;
            this.locItem.meetplekID = this.idEntityObject;
            this.locItem.monsterID = this.idSample;
            if (this.idQl) {
                this.locAssesmentObject = new AssesmentObject();
                this.locAssesmentObject.grootheidid = this.idQl;
            }
        }
    }
};
</script>

<style scoped></style>

