const mutations = {

    insertOutbrineInfoLijst(state, lijst) {
        state.outbrineInfoLijst = [];
        if (lijst != undefined) {
            state.outbrineInfoLijst = lijst;
        }
    },
    insertOutbrineInfoHistLijst(state, lijst) {
        state.outbrineInfoHistLijst = [];
        if (lijst != undefined) {
            state.outbrineInfoHistLijst = lijst;
        }
    },
    insertOutbrineForBGLijst(state, lijst) {
        state.outbrineForBGLijst = [];
        if (lijst != undefined) {
            state.outbrineForBGLijst = lijst;
        }
    },
    insertInbrineForBGLijst(state, lijst) {
        state.inbrineForBGLijst = [];
        if (lijst != undefined) {
            state.inbrineForBGLijst = lijst;
        }
    },
    insertBGOutbrineInfoLijst(state, lijst) {
        state.outbrineInfoBGLijst = [];
        if (lijst != undefined) {
            state.outbrineInfoBGLijst = lijst;
        }
    },
    
};

export default mutations;
