<template>
    <div class="form-inline">
            <h1 v-on:click="stopAlarm">
            <b>
                <cheese-timer
                    v-if="existing"
                    v-bind:countDownToTime="alarmTime"
                    v-bind:alarmText="alarmText"
                    @alarm="doIets()"
            /></b>
        </h1>
        <b-dropdown
            v-if="existing"
            right
            id="dropdown-form"
            text="Timers"
            ref="dropdown"
        >
            <b-dropdown-form style="width: 340px">
                <b-form-group label="Lopend" @submit.stop.prevent>
                    <span>
                        <div
                            v-for="nextsteptimer in timerList"
                            v-bind:key="nextsteptimer.bakID"
                        >
                            <b-row>
                                <b-col>
                                    Bak {{ nextsteptimer.bakNummer }}
                                </b-col>
                                <b-col>
                                    <cheese-timer
                                        v-bind:countDownToTime="
                                            nextsteptimer.alarmTime
                                        "
                                        @alarm="doIets()"
                                        @reset="reset()"
                                    />
                                </b-col>
                            </b-row>
                        </div>
                    </span>
                </b-form-group>
            </b-dropdown-form>
        </b-dropdown>
    </div>
</template>

<script>
//import { NextStepTimer } from '../../models/BakInfo_helper';
import { CheeseTimer } from '../components/index';

export default {
    name: 'ProductionTimer',
    data() {
        return {
            flexList: [],
            audio: null,
            mainNextStep: null
        };
    },
    components: {
        CheeseTimer
    },
    props: {},
    computed: {
        existing() {
            return this.timerList.length > 0;
        },
        timerList() {
            const tempList = this.$store.getters['production/getTimerList'];
            return tempList;
        },
        alarmTime() {
            return this.mainNextStep ? this.mainNextStep.alarmTime : null;
        },
        alarmText() {
            return this.mainNextStep
                ? 'Bak ' + this.mainNextStep.bakNummer
                : null;
        }
    },
    methods: {
        displayTimer() {
            const activeList = this.timerList.filter((x) => x.active == 1);
            if (activeList.length>0) {
                const earliestNextStepTimer = activeList.reduce((r, o) =>
                    o.alarmTime < r.alarmTime ? o : r
                );
                this.mainNextStep = earliestNextStepTimer;
            }
        },
        doIets() {
            // kijk welke is afgegaan
            const activeList = this.timerList.filter((x) => x.active == 1);
            if (activeList.length>0) {
                const earliestNextStepTimer = activeList.reduce((r, o) =>
                    o.alarmTime < r.alarmTime ? o : r
                );
                // eslint-disable-next-line no-unused-vars
                const bakID = earliestNextStepTimer
                    ? earliestNextStepTimer.bakID
                    : -1;
            }
            this.play();
        },
        reset() {
            // kijk welke is afgegaan
            const activeList = this.timerList.filter((x) => x.active == 1);
            if (activeList.length>0) {
                const earliestNextStepTimer = activeList.reduce((r, o) =>
                    o.alarmTime < r.alarmTime ? o : r
                );
                earliestNextStepTimer.active = 0;
            }
        },
        play() {
            //if (sound) {
            this.audio = new Audio(require('@/assets/Old-phone-ring.mp3'));
            this.audio.play();
            //}
        },
        stopAlarm() {
            const activeList = this.timerList.filter((x) => x.active == 1);
            if (activeList.length>0) {
                const earliestNextStepTimer = activeList.reduce((r, o) =>
                    o.alarmTime < r.alarmTime ? o : r
                );
                earliestNextStepTimer.active = 0;
            }
            this.audio.pause();
            this.audio.currentTime = 0;
        }
    },
    mounted() {
        setInterval(() => {
            this.displayTimer();
        }, 60 * 1000); // iedere 60 seconden
    },
    created() {}
};
</script>
