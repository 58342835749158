<template>
    <div style="margin-top: 60px">
        <b-row>
            <b-col sm="8">
                <period-selector
                    v-model="selectDays"
                    v-bind:periodDays="periodeDagen"
                    @change="setNewDates"
                />
            </b-col>
            <b-col sm="3">
                <b-row>
                    <b-col sm="12">
                        <lookup-combo
                            v-model="productID"
                            v-bind:combotype="lctypeCheeses"
                            @change="setChangedProduct"
                        ></lookup-combo>
                    </b-col>
                </b-row>
            </b-col>
            <b-col sm="1">
                <b-row>
                    <b-col sm="12">
                        <b-button
                            @click="loadData()"
                            class="bgc_aux_normal"
                            v-b-tooltip.hover
                            :title="$t('com_showdata', { ns: 'common' })"
                            ><font-awesome-icon icon="arrow-down" size="1x" />
                        </b-button>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card no-body :header="strInUit">
                    <CardChart
                        style="width: 100%; max-width: 700px"
                        :key="componentKey4"
                        v-bind:displayNumber="showbar"
                        v-bind:van="chartVan"
                        v-bind:tot="chartTot"
                        v-bind:productID="chartProductID"
                        v-bind:dataSource="graphDataSource(4)"
                    />
                </b-card>
            </b-col>
            <b-col>
                <b-card
                    no-body
                    :header="
                        $t('log_verdeling', {
                            ns: 'logistics',
                            val: $t('com_kaassoorten', {
                                ns: 'common'
                            })
                        })
                    "
                >
                    <CardChart
                        style="width: 100%; max-width: 700px"
                        :key="componentKey3"
                        v-bind:displayNumber="showdoughnut"
                        v-bind:van="chartVan"
                        v-bind:tot="chartTot"
                        v-bind:productID="chartProductID"
                        v-bind:dataSource="graphDataSource(3)"
                    />
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card
                    no-body
                    :header="
                        $t('log_verdeling', {
                            ns: 'logistics',
                            val: $t('prod_xtemperatuur', {
                                ns: 'production',
                                val: $t('prod_strem', {
                                    ns: 'production'
                                })
                            })
                        })
                    "
                >
                    <CardChart
                        style="width: 100%; max-width: 700px"
                        :key="componentKey1"
                        v-bind:displayNumber="showbar"
                        v-bind:van="chartVan"
                        v-bind:tot="chartTot"
                        v-bind:productID="chartProductID"
                        v-bind:dataSource="graphDataSource(1)"
                    />
                </b-card>
            </b-col>
            <b-col>
                <b-card
                    no-body
                    :header="
                        $t('log_verdeling', {
                            ns: 'logistics',
                            val: $t('prod_xtemperatuur', {
                                ns: 'production',
                                val: $t('prod_nawarm', {
                                    ns: 'production'
                                })
                            })
                        })
                    "
                >
                    <CardChart
                        style="width: 100%; max-width: 700px"
                        :key="componentKey2"
                        v-bind:displayNumber="showbar"
                        v-bind:van="chartVan"
                        v-bind:tot="chartTot"
                        v-bind:productID="chartProductID"
                        v-bind:dataSource="graphDataSource(2)"
                    />
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import CardChart from './CardChart.vue';
import PeriodSelector from '../components/PeriodSelector.vue';
import LookupCombo from '../components/LookupCombo.vue';

export default {
    name: 'DashboardChart',
    data: function () {
        return {
            showline: window.constants.DB_SHOW_LINE,
            showdoughnut: window.constants.DB_SHOW_DOUGHNUT,
            showbar: window.constants.DB_SHOW_BAR,
            lctypeCheeses: window.constants.LCCHEESE,
            errorMessage: '',
            periodeDagen: 365,
            van: null,
            tot: null,
            newperiod: false,
            productID: null,
            selectDays: null,
            componentKey1: 0,
            componentKey2: 1,
            componentKey3: 2,
            componentKey4: 3
        };
    },

    components: {
        CardChart,
        PeriodSelector,
        LookupCombo
    },
    computed: {
        strInUit() {
            return (
                this.$t('log_inslag', { ns: 'logistics' }) +
                '/' +
                this.$t('log_uitslag', { ns: 'logistics' })
            );
        },
        chartVan() {
            return this.van;
        },
        chartTot() {
            return this.tot;
        },
        chartProductID() {
            return this.productID;
        }
    },
    methods: {
        setNewDates(selDates) {
            this.van = selDates.van;
            this.tot = selDates.tot;
            this.newperiod = true;
            this.loadData();
        },
        graphDataSource(nummer) {
            if (nummer == 1) return 'histoStremTemp';
            if (nummer == 2) return 'histoNawarmTemp';
            if (nummer == 3) return 'kaassoortVerdeling';
            if (nummer == 4) return 'inslagUitslag';
            return '';
        },
        async loadData() {
            this.componentKey1 += 10;
            this.componentKey2 += 10;
            this.componentKey3 += 10;
            this.componentKey4 += 10;
        },
        async setChangedProduct() {
            this.$nextTick(() => {
                this.loadData();
            });
        }
    },

    mounted() {
        this.loadData();
    }
};
</script>

<style scoped></style>

