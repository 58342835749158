<template>
    <b-modal
        id="modal-wijzigpass"
        size="lg"
        ref="modal"
        :title="this.formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
    >
        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
            {{ alertText }}
        </b-alert>
        <b-form-group
            :label="
                $t('com_old', {
                    ns: 'common',
                    val: $t('com_ww', { ns: 'common' })
                })
            "
            label-for="passold"
            class="mb-0"
        >
            <b-form-input
                id="passold"
                v-model="oldpass"
                type="password"
                :state="oldpass.length > 7"
            ></b-form-input>
        </b-form-group>
        <b-form-group
            :label="
                $t('com_newx', {
                    ns: 'common',
                    val: $t('com_ww', { ns: 'common' })
                })
            "
            label-for="pass1"
            :description="$t('com_wweis', { ns: 'common' })"
            class="mb-0"
        >
            <b-form-input
                id="pass1"
                v-model="pass1"
                type="password"
                :state="pass1.length > 7"
            ></b-form-input>
        </b-form-group>
        <b-form-group
            inline
            :label="
                $t('com_repeat', {
                    ns: 'common',
                    val: $t('com_ww', { ns: 'common' })
                })
            "
            label-for="pass2"
            class="mb-0"
        >
            <b-form-input
                id="pass2"
                v-model="pass2"
                type="password"
                :state="pass2.length > 7 && pass2 == pass1"
            ></b-form-input>
            <b-form-invalid-feedback id="input-live-feedback">
                {{ errorMessage1 }}
            </b-form-invalid-feedback>
        </b-form-group>
    </b-modal>
</template>

<script>
import { loadItem } from '@/models/DataHelper';
export default {
    name: 'ChangePassModal',
    data: function () {
        return {
            errorMessage1: this.$t('com_wwgelijk', {
                ns: 'common'
            }),
            errorMessage2:this.$t('com_wwanders', {
                ns: 'common'
            }),
            showDismissibleAlert: false,
            alertText: '',
            pass1: '',
            pass2: '',
            oldpass: ''
        };
    },
    model: {
        prop: 'visible',
        event: 'hide'
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        formtitle() {
            return this.$t('com_change', {
                ns: 'common',
                val: this.$t('com_ww', { ns: 'common' })
            });
        }
    },
    methods: {
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        isValid() {
            let valid =
                this.pass2.length > 7 &&
                this.pass2 == this.pass1 &&
                this.oldpass.length > 7;
            if (valid) {
                valid = this.pass1 != this.oldpass;
                if (!valid) this.showAlert(this.errorMessage2);
            } else {
                this.showAlert(this.errorMessage1);
            }
            return valid;
        },
        hide() {
            this.$emit('hide');
        },
        checkFormValidity() {
            return this.isValid;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return;
            }
            this.doChangePass();
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-wijzigpass');
            });
        },
        async doChangePass() {
            const ChangePasswordModel = {
                OldPassword: this.oldpass,
                NewPassword: this.pass1
            };
            loadItem('gebruiker/changePassword', ChangePasswordModel, this.$t);
        }
    }
};
</script>

<style scoped></style>

