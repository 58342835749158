<template>
    <b-modal
        id="modal-trmodal"
        size="xl"
        ref="modal"
        :title="this.formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
    >
        <b-card @submit.stop.prevent="handleSubmit">
            <b-input-group
                :prepend="$t('prod_schema', { ns: 'production' })"
                :label="$t('prod_schema', { ns: 'production' })"
            >
                <b-form-input
                    :placeholder="$t('prod_schema', { ns: 'production' })"
                    v-model="refreshedRecipe.naam"
                    @change="set_changed"
                ></b-form-input>
            </b-input-group>
            <b-form-textarea
                id="opmerking"
                v-model="refreshedRecipe.opmerking"
                :placeholder="$t('com_opmerking', { ns: 'common' })"
                rows="3"
                max-rows="6"
                @change="set_changed"
            ></b-form-textarea>
            <div>
                <b-col sm="6" class="mx-0 px-0 my-1 py-0">
                    <b-form-checkbox
                        v-model="refreshedRecipe.standaard"
                        value="true"
                        unchecked-value="false"
                    >
                        {{ standaard }}
                    </b-form-checkbox></b-col
                >
                <b-col sm="6" class="mx-0 px-0 my-1 py-0">
                    <b-form-input v-model="inGebruik" readonly></b-form-input>
                </b-col>
            </div>
            <div>
                <b-col sm="10" class="mx-0 px-0 my-1 py-0">
                    <treatment-step-combo
                        v-model="newStep"
                        :disabled="checkInUse"
                        @change="set_newStep"
                    />
                </b-col>
                <b-col sm="2" class="mx-0 px-0 my-1 py-0">
                    <b-button
                        @click="addItem()"
                        class="bgc_aux_normal"
                        :disabled="checkInUse"
                        ><font-awesome-icon icon="arrow-down" size="1x" />
                    </b-button>
                </b-col>
            </div>
            <b-tabs card>
                <b-tab :title="receptMakeTitle" v-bind:key="1" active lazy>
                    <b-card-text>
                        <b-table
                            :items="displayList"
                            :fields="visibleFields"
                            stacked="md"
                            show-empty
                            :empty-text="$t('com_geen_data', { ns: 'common' })"
                            small
                            striped
                        >
                            <template v-slot:cell(actions)="row">
                                <b-button
                                    size="sm"
                                    @click="omlaag(row.item)"
                                    class="bgc_aux_unimportant"
                                    :disabled="checkInUse"
                                    ><font-awesome-icon
                                        icon="arrow-down"
                                        size="1x"
                                    />
                                </b-button>
                                <b-button
                                    size="sm"
                                    @click="omhoog(row.item)"
                                    class="bgc_aux_unimportant"
                                    :disabled="checkInUse"
                                    ><font-awesome-icon
                                        icon="arrow-up"
                                        size="1x"
                                    />
                                </b-button>
                                <b-button
                                    size="sm"
                                    @click="verwijderItem(row.item)"
                                    class="bgc_aux_alarm"
                                    :disabled="checkInUse"
                                    ><font-awesome-icon
                                        icon="trash-alt"
                                        size="1x"
                                    />
                                </b-button>
                            </template>
                        </b-table>
                    </b-card-text>
                </b-tab>
                <b-tab :title="receptTitle" @click="vulRecipeMatrix" lazy>
                    <b-card-text>
                        <b-form-textarea
                            id="opmerking"
                            v-model="receptOpmerking"
                            :placeholder="$t('com_opmerking', { ns: 'common' })"
                            rows="3"
                            max-rows="6"
                        ></b-form-textarea>

                        <b-table
                            bordered
                            fixed
                            responsive
                            :sticky-header="stickyHeader"
                            :fields="fieldsPlanner"
                            :items="recipeList"
                        >
                            <template #cell()="data">
                                <span :class="giveBGclass(data.value)">
                                    {{ displayTekst(data.value) }}
                                </span>
                            </template>
                        </b-table>
                    </b-card-text>
                </b-tab>
            </b-tabs>
        </b-card>
    </b-modal>
</template>

<script lang="ts">
import DateHelper from '../../models/DateHelper';
import Helper from '../../models/Helper';
import { defineComponent } from 'vue';
import { VueExtension } from '../../models/VueExtension';
import { loadList, loadItem } from '../../models/DataHelper';
import TreatmentStepCombo from './TreatmentStepCombo.vue';
import {
    TreatmentActivity,
    TreatmentRecipe,
    TreatmentRecipeStep,
    TreatmentStep,
    TreatmentWeekPlan
} from '@/models/CheeseTreatment';

export default defineComponent({
    name: 'TreatmentRecipeModal',
    data: function () {
        return {
            showSpinner: false,
            stickyHeader: '70vh',
            fieldsPlanner: [
                { key: 'week', label: this.$t('com_week', { ns: 'common' }) },
                {
                    key: 'Day10',
                    label: this.$t('com_dag', { ns: 'common' }) + ' 1 12'
                },
                {
                    key: 'Day15',
                    label: this.$t('com_dag', { ns: 'common' }) + ' 1 24'
                },
                {
                    key: 'Day20',
                    label: this.$t('com_dag', { ns: 'common' }) + ' 2 12'
                },
                {
                    key: 'Day25',
                    label: this.$t('com_dag', { ns: 'common' }) + ' 2 24'
                },
                {
                    key: 'Day30',
                    label: this.$t('com_dag', { ns: 'common' }) + ' 3 12'
                },
                {
                    key: 'Day35',
                    label: this.$t('com_dag', { ns: 'common' }) + ' 3 24'
                },
                {
                    key: 'Day40',
                    label: this.$t('com_dag', { ns: 'common' }) + ' 4 12'
                },
                {
                    key: 'Day45',
                    label: this.$t('com_dag', { ns: 'common' }) + ' 4 24'
                },
                {
                    key: 'Day50',
                    label: this.$t('com_dag', { ns: 'common' }) + ' 5 12'
                },
                {
                    key: 'Day55',
                    label: this.$t('com_dag', { ns: 'common' }) + ' 5 24'
                },
                {
                    key: 'Day60',
                    label: this.$t('com_dag', { ns: 'common' }) + ' 6 12'
                },
                {
                    key: 'Day65',
                    label: this.$t('com_dag', { ns: 'common' }) + ' 6 24'
                },
                {
                    key: 'Day70',
                    label: this.$t('com_dag', { ns: 'common' }) + ' 7 12'
                },
                {
                    key: 'Day75',
                    label: this.$t('com_dag', { ns: 'common' }) + ' 7 24'
                }
            ],
            recipeList: [] as Array<TreatmentWeekPlan>,
            treatmentList: [] as Array<TreatmentRecipeStep>,
            refreshedRecipe: {} as TreatmentRecipe,
            receptOpmerking: null,

            aangeraakt_teller: 1,
            newStep: {} as TreatmentRecipeStep,
            localChanged: true,
            showBakDetails: false,
            editAllowed: true,
            warnAmount: false,
            forceRefresh: false,
            inGebruik: '',
            uitvoerTijdstip: DateHelper.convertedToTime(new Date()),
            partijID: -1,
            firstTimeWait: 24,
            rerenderkey: 1,
            strReceptCaption: this.$t('com_recipe', { ns: 'common' }) + ' {0}',
            strBaseCaption:
                '{0} ' +
                this.$t('prod_treatmentschema', {
                    ns: 'production'
                }).toLowerCase(),
            strReceptBase:
                ', ' +
                this.$t('prod_basedon', { ns: 'production' }).toLowerCase() +
                ' {0}',
            strStandaard: this.$t('prod_defaultbyabsence', { ns: 'production' })
        };
    },
    model: {
        prop: 'visible',
        event: 'change'
    },
    props: {
        treatmentRecipeId: {
            type: Number,
            required: true
        },
        treatmentRecipe: {
            type: TreatmentRecipe,
            required: false
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    components: {
        TreatmentStepCombo
    },
    watch: {},
    computed: {
        displayList() {
            return this.treatmentList;
        },
        checkInUse() {
            return this.inGebruik.length > 1 ? true : false;
        },
        standaard() {
            return this.strStandaard;
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                {
                    key: 'volgnummer',
                    label: this.$t('prod_step', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'behandelstap',
                    label: this.$t('prod_treatment', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'wachttijd',
                    label: this.$t('prod_wachttijd', { ns: 'production' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        formtitle() {
            return (
                this.$t('com_detail', { ns: 'common' }) +
                ' ' +
                this.$t('prod_treatment', { ns: 'production' }).toLowerCase()
            );
        },
        receptTitle() {
            const title = Helper.stringformat(
                this.strReceptCaption,
                this.treatmentRecipeId < 0 ? this.$t('com_new', { ns: 'common' }) : this.refreshedRecipe.naam
            );
            return title;
        },
        receptMakeTitle() {
            let title = Helper.stringformat(
                this.strBaseCaption,
                this.treatmentRecipeId < 0 ? this.$t('com_new', { ns: 'common' }) : this.$t('com_edit', { ns: 'common', val: null })
            );
            if (
                this.treatmentRecipeId < 0 &&
                this.treatmentRecipe != undefined
            ) {
                const plus = Helper.stringformat(
                    this.strReceptBase,
                    this.treatmentRecipe.naam
                );
                title = title + plus;
            }
            return title;
        }
    },
    methods: {
        hide() {
            this.$emit('hide');
        },
        showDate(aDate) {
            return DateHelper.convertedToDateShort(aDate);
        },
        displayTekst(value) {
            if (typeof value === 'object' && value !== null) {
                return value.Display();
            }
        },
        giveBGclass(value) {
            if (value !== null) {
                if (value.recept) {
                    if (value.behandelstapID == 2) return 'bg-coat';
                    if (value.behandelstapID == 1) return 'bg-keer';
                }
            }
        },
        addItem() {
            this.treatmentList.push(this.newStep);
            for (let i = 0; i < this.treatmentList.length; i++) {
                this.treatmentList[i].volgnummer = i + 1;
            }
            this.newStep = this.makeNewStep();
            this.set_changed();
        },
        set_input() {
            this.$emit('change', this.refreshedRecipe);
        },
        set_changed() {
            this.localChanged = true;
        },
        set_newStep(item) {
            this.newStep = item;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        async handleSubmit() {
            if (this.localChanged) {
                this.set_input();
            }
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-trmodal');
            });
        },
        verwijderItem(item) {
            const lijst = this.treatmentList;
            for (let i = lijst.length - 1; i >= 0; i--) {
                if (lijst[i].volgnummer == item.volgnummer) lijst.splice(i, 1);
            }
            for (let i = 0; i < lijst.length; i++) {
                lijst[i].volgnummer = i + 1;
            }
            this.treatmentList = lijst;
        },
        omlaag(item) {
            const lijst = this.treatmentList;
            const ind = lijst.findIndex((x) => x.volgnummer == item.volgnummer);
            if (ind > -1) Helper.moveItem(lijst, ind, ind + 1);
            for (let i = 0; i < lijst.length; i++) {
                lijst[i].volgnummer = i + 1;
            }
            this.treatmentList = lijst;
        },
        omhoog(item) {
            const lijst = this.treatmentList;
            const ind = lijst.findIndex((x) => x.volgnummer == item.volgnummer);
            if (ind > -1) Helper.moveItem(lijst, ind, ind - 1);
            for (let i = 0; i < lijst.length; i++) {
                lijst[i].volgnummer = i + 1;
            }
            this.treatmentList = lijst;
        },
        vulRecipeMatrix() {
            const recept = this.refreshedRecipe;
            recept.weekplannen = [];
            this.receptOpmerking = recept.opmerking;
            let runningWeek = 1;
            let weekPlan = new TreatmentWeekPlan({ week: runningWeek });
            const lijst = recept.stappen.filter(
                (x) => x.isDeleted == undefined || x.isDeleted == false
            );
            let totHours = this.firstTimeWait;
            for (let i = 0; i < lijst.length; i++) {
                const realdays = Math.round((totHours * 10) / 24) / 10;
                const days = Math.round((totHours * 10) / 24);
                const remainder = days % 5; //
                // klopt de week nog

                const numberOfWeeks = Math.floor((realdays - 1) / 7) + 1;
                if (numberOfWeeks > runningWeek) {
                    while (runningWeek < numberOfWeeks) {
                        recept.weekplannen.push(weekPlan);
                        runningWeek += 1;
                        weekPlan = new TreatmentWeekPlan({ week: runningWeek });
                    }
                }
                const dayNumber = days - (runningWeek - 1) * 70 + remainder * 5;
                lijst[i].dagnummer = dayNumber;
                lijst[i].dagnummerabsoluut = realdays;
                weekPlan.SetDay(dayNumber, lijst[i]);
                const hoursAfterStep = lijst[i].wachttijd;
                totHours += hoursAfterStep;
            }
            this.treatmentList = lijst;
            recept.weekplannen.push(weekPlan);
            this.recipeList = recept.weekplannen;
            return recept;
        },
        async getData() {
            this.recipeList = [];
            if (this.treatmentRecipeId > -1) {
                this.refreshedRecipe = await loadItem(
                    'treatment/loadTreatmentRecipe',
                    {
                        treatmentRecipeID: this.treatmentRecipeId
                    },
                    this.$t
                );
            } else {
                if (this.treatmentRecipe != undefined) {
                    this.refreshedRecipe = await loadItem(
                        'treatment/loadTreatmentRecipe',
                        {
                            treatmentRecipeID: this.treatmentRecipe.id
                        },
                    this.$t
                    );
                    this.refreshedRecipe.id = -1;
                } else {
                    this.refreshedRecipe = new TreatmentRecipe(
                        this.treatmentRecipe
                    );
                }
            }
            if (this.refreshedRecipe.inGebruik == undefined) {
                this.inGebruik = '';
            } else {
                this.inGebruik =
                    this.refreshedRecipe.inGebruik == 0 ? '' : this.$t('log_inuse', { ns: 'logistics' });
            }
            this.treatmentList = this.refreshedRecipe.stappen;
        },
        makeNewStep() {
            const newStep = new TreatmentRecipeStep();
            newStep.behandelstapID = this.newStep
                ? this.newStep.behandelstapID
                : 2;
            newStep.behandelstap = this.newStep
                ? this.newStep.behandelstap
                : '';
            newStep.wachttijd = this.newStep ? this.newStep.wachttijd : 48;
            return newStep;
        }
    },
    mounted() {
        this.getData();
    },
    async created() {
        const lijst = await loadList(
            'masterdata/getConstantsLijst',
            'masterdata/loadApplicationConstants',
            null,
            this.forceRefresh,
            this.$t
        );
        const con = lijst
            ? lijst.find(
                  (x) => x.text == window.constants.APPCONST_HOURSFIRSTTREATMENT
              )
            : null;
        this.firstTimeWait = con ? con.value : null;
        this.newStep = this.makeNewStep();
    }
});
</script>

<style scoped>
.bg-coat {
    background-color: #c1e881 !important;
}
.bg-keer {
    background-color: hwb(203 55% 7%) !important;
}
</style>

