<template>
    <b-container fluid style="margin-top: 60px">
        <div class="form-inline">
            <h1 v-on:click="flipPlace">
                {{ title }} &nbsp;
                <b-button>
                    {{ btnTekst }}
                </b-button>
            </h1>
            &nbsp;
            <b-button @click="scan()" class="bgc_aux_unimportant float-md-right"
                ><font-awesome-icon icon="barcode" size="1x" />
            </b-button>
        </div>
        <b-row>
            <b-col sm="12">
                <div v-if="showFilter">
                    <b-row no-gutters>
                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_filter', { ns: 'common' })"
                                label-for="filter-input"
                                label-cols-sm="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-input-group size="sm">
                                    <b-form-input
                                        id="filter-input"
                                        v-model="filter"
                                        type="search"
                                        :placeholder="
                                            $t('com_typetosearch', {
                                                ns: 'common'
                                            })
                                        "
                                    ></b-form-input>

                                    <b-input-group-append>
                                        <b-button
                                            :disabled="!filter"
                                            @click="filter = ''"
                                            >{{
                                                $t('com_wis', { ns: 'common' })
                                            }}</b-button
                                        >
                                    </b-input-group-append>
                                </b-input-group>

                                <b-button
                                    v-if="
                                        checkRight('AnalyticsEnityObjectCreate')
                                    "
                                    size="sm"
                                    @click="createNewItem"
                                    class="mr-1"
                                    ><font-awesome-icon
                                        icon="folder-plus"
                                        size="1x"
                                    />
                                    {{
                                        $t('com_newx', {
                                            ns: 'common',
                                            val: $t('com_item', {
                                                ns: 'common'
                                            })
                                        })
                                    }}
                                </b-button>
                            </b-form-group>
                        </b-col>

                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_perpagina', { ns: 'common' })"
                                label-for="per-page-select"
                                label-cols-sm="6"
                                label-cols-md="4"
                                label-cols-lg="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-form-select
                                    id="per-page-select"
                                    v-model="perPage"
                                    :options="pageOptions"
                                    size="sm"
                                ></b-form-select>
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                ></b-pagination>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div v-else>
                    <b-button
                        v-if="checkRight('AnalyticsEnityObjectCreate')"
                        size="sm"
                        @click="createNewItem"
                        class="mr-1"
                        ><font-awesome-icon icon="folder-plus" size="1x" />
                        {{
                            $t('com_newx', {
                                ns: 'common',
                                val: $t('com_item', {
                                    ns: 'common'
                                })
                            })
                        }}
                    </b-button>
                </div>
                <b-table
                    :items="groupedDisplayList"
                    :fields="visibleFields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    stacked="md"
                    show-empty
                    :empty-text="$t('com_geen_data', { ns: 'common' })"
                    small
                    striped
                    @filtered="onFiltered"
                    @row-clicked="toonDetail"
                >
                    <template v-slot:cell(actions)="row">
                        <div class="form-inline">
                            <b-button
                                size="sm"
                                @click="toonDetail(row.item)"
                                class="bgc_aux_unknown"
                                ><font-awesome-icon
                                    icon="info-circle"
                                    size="1x"
                                />
                            </b-button>
                        </div>
                    </template>

                    <template v-slot:row-details>
                        <entity-object-info v-bind:inItems="localObjects">
                        </entity-object-info>
                    </template>
                </b-table>
                <div v-if="displayList.length == 0">
                    {{ $t('com_geen_data', { ns: 'common' }) }}
                </div>
            </b-col>
        </b-row>
        <modal-entity-object
            v-if="detailsVisible"
            :inItem="eo"
            v-model="detailsVisible"
            v-bind:typeName="theTypeName"
            @forcerefresh="doRefresh"
            @hide="reset"
        />
        <scan-barcode
            v-if="showScanner"
            v-model="showScanner"
            @result="showEntityObjectDetail"
        />
    </b-container>
</template>

<script>
import _ from 'lodash';
import ModalEntityObject from './ModalEntityObject.vue';
import ScanBarcode from '../components/ScanBarcode.vue';
import EntityObjectInfo from './EntityObjectInfo.vue';
import { Permission } from '../../models/Permissions';
import { loadList, loadItem } from './utils';
import Helper from '@/models/Helper';

export default {
    name: 'EntityObjectBase',
    data: function () {
        return {
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [10, 20, 40, { value: 100, text: '100' }],
            filter: null,
            filterOn: [],
            van: null,
            tot: null,
            detailsVisible: false,
            localObjects: [],
            objectNaam: '',
            aangeraakt_teller: 1,
            selectDays: null,
            periodeDagen: 60,
            showScanner: false,
            isPlace: false,
            barcode: '',
            theTypeName: window.constants.ADATA_STOCKLOCATION,
            eo: null,
            displayFilterCount:20
        };
    },
    components: {
        ModalEntityObject,
        ScanBarcode,
        EntityObjectInfo
    },
    computed: {
        title() {
            return Helper.stringformat(
                '{0} {1} {2}',
                this.$t('an_onderzoek', { ns: 'analytics' }),
                this.$t('com_voor', { ns: 'common' }).toLowerCase(),
                this.$t('an_subjects', { ns: 'analytics' }).toLowerCase()
            );
        },
        btnTekst() {
            return this.isPlace
                ? this.$t('an_roerend', { ns: 'analytics' })
                : this.$t('an_onroerend', { ns: 'analytics' });
        },
        showFilter() {
            return this.displayList
                ? this.displayList.length > this.displayFilterCount
                : false;
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                {
                    key: 'categorie',
                    label: this.$t('com_categorie', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'entobjects.length',
                    label: this.$t('com_number', { ns: 'common' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        displayList() {
            // eslint-disable-next-line no-unused-vars
            const veranderd = this.aangeraakt_teller;
            const lijst = this.$store.getters['analytics/getEntityObjectLijst'];
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.totalRows = lijst ? lijst.length : 0;
            return lijst;
        },
        groupedDisplayList() {
            // eslint-disable-next-line no-unused-vars
            const veranderd = this.aangeraakt_teller;
            if (this.displayList == undefined) return null;
            const x = _(this.displayList.slice(0))
                .groupBy((x) => x.typenaam)
                .map((value, key) => {
                    //const quantity = _.sumBy(value, 'aantal');
                    return {
                        categorie: key,
                        entobjects: value
                    };
                })
                .value();
            return x;
        },
        allowEdit() {
            // todo: wat worden de regels hiervoor?
            return true;
        }
    },
    methods: {
        doRefresh() {
            this.forcerefresh = true;
            this.loadData();
        },
        scan() {
            this.showScanner = !this.showScanner;
        },
        flipPlace() {
            this.isPlace = !this.isPlace;
            this.loadData();
        },
        reset() {
            this.detailsVisible = false;
        },
        async toonDetail(row) {
            if (row._showDetails) {
                this.$set(row, '_showDetails', false);
            } else {
                this.groupedDisplayList.forEach((row) => {
                    this.$set(row, '_showDetails', false);
                });
                this.localObjects = row.item
                    ? row.item.entobjects
                    : row.entobjects;
                this.$set(row, '_showDetails', true);
            }
        },
        async showEntityObjectDetail(value) {
            if (value == undefined) return;
            const barcodeEO = value;
            const tempEO = this.displayList.find((x) => x.barcode == barcodeEO);
            if (tempEO) {
                this.eo = tempEO;
                this.detailsVisible = !this.detailsVisible;
            } else {
                const bericht =
                    this.$t('com_nodata', { ns: 'common' }) +
                    '? ' +
                    this.$t('com_xnotfound', {
                        ns: 'common',
                        val: this.$t('com_barcode', { ns: 'common' })
                    }) +
                    ': ' +
                    barcodeEO;
                this.$root.showMessageBox(bericht);
            }
        },
        async createNewItem() {
            this.detailsVisible = !this.detailsVisible;
        },
        async verwijderItem(item) {
            const doClose = await this.$root.showConfirmationBox(
                this.$t('com_delete', {
                    ns: 'common',
                    val: this.$t('prod_onderzoekslaats', {
                        ns: 'production'
                    }).toLowerCase()
                }) +
                    ' : ' +
                    item.tijd
            );
            if (doClose) {
                await loadItem(
                    'maintenance/deleteMaintenance',
                    {
                        maintenanceID: item.id
                    },
                    this.$t
                );
            }
            this.loadData();
        },
        setNewDates(selDates) {
            this.van = selDates.van;
            this.tot = selDates.tot;
            this.loadData();
        },
        async loadData() {
            // eslint-disable-next-line no-unused-vars
            const lijst = await loadList(
                'analytics/getEntityObjectLijst',
                'analytics/loadEntityObjectByTypename',
                null,
                this.forcerefresh,
                this.$t
            );
            this.forcerefresh = false;
            this.aangeraakt_teller += 1;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        },
        newSample(value) {
            if (value == undefined) return;
            this.barcode = value;
            this.showDetails = !this.showDetails;
        }
    },
    mounted: function () {
        this.forcerefresh = true;
        this.loadData();
        this.forcerefresh = false;
        this.totalRows = this.displayList ? this.displayList.length : 0;
    },
    created() {
        // laad de activiteiten en types als al niet gedaan
    }
};
</script>

<style scoped></style>

