import Api from '../../../api';
import store from '../../../store';

//#region Recept

const fetchOpenRecipeComplete = async (data) => {    
    return fetchInfo('/Recipe/GetOpenRecipeComplete', data);
};

const fetchOpenRecipeInfo = async (data) => {    
    return fetchInfo('/Recipe/GetOpenRecipeInfo', data);
};

const fetchClosedRecipeInfo = async (data) => {    
    return fetchInfo('/Recipe/GetClosedRecipeInfo', data);
};

const fetchRecipeComplete = async (data) => {    
    return fetchInfo('/Recipe/GetRecipeComplete', data);
};


const storeRecipe = async (data) => {
    return await Api(store).put('/Recipe/StoreRecipe', data);
};

const closeRecipe = async (data) => {
    //return await Api(store).put('/Recipe/CloseRecipe', data );
    return Api(store).put(
        '/Recipe/CloseRecipe?' +
            Object.keys(data)
                .map(
                    (k) =>
                        encodeURIComponent(k) +
                        '=' +
                        encodeURIComponent(data[k] ?? '')
                )
                .join('&'),
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
    );
};
//#endregion Recept

//#region Merktype
//#endregion Merktype

//#region Coating
//#endregion Coating

//#region WheyType
//#endregion WheyType

//#region Ingredients

//#endregion Ingredients

//#region Categories
//#endregion Categories

const fetchInfo = async (apicall, data) => {
    if (data != undefined) {
        return await Api(store).get(
            apicall +'?' +
                Object.keys(data)
                    .map(
                        k =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    } else {
        return await Api(store).get(apicall, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    }
};

export default {
    fetchOpenRecipeComplete,
    fetchOpenRecipeInfo,
    fetchClosedRecipeInfo,
    fetchRecipeComplete,
    storeRecipe,
    closeRecipe
};
