var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-container',{staticStyle:{"margin-top":"60px"},attrs:{"fluid":""}},[_c('div',[(_vm.checkRight('CheeseTreatmentCreate'))?_c('b-dropdown',{ref:"dropdown",attrs:{"id":"dropdown-form","text":_vm.$t('com_newx', {
                    ns: 'common',
                    val: _vm.$t('prod_treatment', {
                        ns: 'production'
                    }).toLowerCase()
                }),"size":"sm"}},[_c('b-dropdown-form',{staticStyle:{"width":"340px"}},[_c('b-form-group',{attrs:{"label":_vm.strTreamentFor},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('span',[_c('label',{attrs:{"for":"input-vatvorm"}},[_vm._v(_vm._s(_vm.$t('com_kaasvorm', { ns: 'common' }))+": ")]),_c('lookup-combo',{attrs:{"id":"input-vatvorm","placeholder":_vm.$t('com_kaasvorm', { ns: 'common' }),"forcerefresh":_vm.forcerefresh,"combotype":_vm.lctypeShapes},model:{value:(_vm.selectedVormID),callback:function ($$v) {_vm.selectedVormID=$$v},expression:"selectedVormID"}})],1),_c('span',[_c('label',{attrs:{"for":"input-behandelrecept"}},[_vm._v(_vm._s(_vm.$t('prod_treatmentschema', { ns: 'production' }))+": ")]),_c('lookup-combo',{attrs:{"id":"input-behandelrecept","placeholder":_vm.$t('prod_treatmentschema', {
                                    ns: 'production'
                                }),"forcerefresh":_vm.forcerefresh,"combotype":_vm.lctypeTreatmentRecipe},model:{value:(_vm.selectedBehandelID),callback:function ($$v) {_vm.selectedBehandelID=$$v},expression:"selectedBehandelID"}})],1),_c('b-form-checkbox',{attrs:{"id":"cbPrefered"},model:{value:(_vm.preferedTreatment),callback:function ($$v) {_vm.preferedTreatment=$$v},expression:"preferedTreatment"}},[_vm._v(" "+_vm._s(_vm.$t('com_voorkeuze', { ns: 'common' }))+" ")])],1),_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm"},on:{"click":_vm.createNewItem}},[_c('font-awesome-icon',{attrs:{"icon":"folder-plus","size":"1x"}}),_vm._v(" "+_vm._s(_vm.$t('prod_toevoegen', { ns: 'production' }))+" ")],1)],1)],1):_vm._e()],1),_c('b-table',{attrs:{"items":_vm.groupedDisplayList,"fields":_vm.visibleFields,"current-page":_vm.currentPage,"per-page":_vm.perPage,"filter":_vm.filter,"filter-included-fields":_vm.filterOn,"stacked":"md","show-empty":"","empty-text":_vm.$t('com_geen_data', { ns: 'common' }),"small":"","striped":""},on:{"filtered":_vm.onFiltered,"row-clicked":_vm.toonBehandelingen},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(row){return [_c('b-button',{staticClass:"bgc_aux_unknown",attrs:{"size":"sm"},on:{"click":function($event){return _vm.toonBehandelingen(row.item)}}},[_c('font-awesome-icon',{attrs:{"icon":"info-circle","size":"1x"}})],1)]}},{key:"row-details",fn:function(){return [_c('cheese-treatment-items',{attrs:{"inList":_vm.itemList},on:{"delete":_vm.deleteItem}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }