<template>
    <b-card no-body class="mb-1">
        <div>
            <b-col sm="3">
                <label>{{ title }}:</label>
            </b-col>
            <b-col sm="9">
                <b-input-group>
                    <b-form-input v-model="names" readonly></b-form-input>
                    <b-button
                        v-if="
                            checkRight('AnalyticsEnityObjectCreate') && !bewerk
                        "
                        size="sm"
                        @click="bewerkLijst()"
                        class="bgc_aux_warning"
                        ><font-awesome-icon icon="edit" size="1x" />
                    </b-button>
                    <b-button
                        v-if="
                            checkRight('AnalyticsEnityObjectCreate') && bewerk
                        "
                        size="sm"
                        @click="emitLijst()"
                        class="bgc_aux_normal"
                        ><font-awesome-icon icon="arrow-left" size="1x" />
                    </b-button>
                </b-input-group>
            </b-col>
        </div>
        <b-card no-body class="mb-1" v-if="bewerk">
            <div class="groups">
                <div class="group">
                    <b-card
                        :header="
                            $t('com_beschikbaar', { ns: 'common', val: '' })
                        "
                    >
                        <b-input-group size="sm">
                            <b-form-input
                                id="filter-input"
                                v-model="filter"
                                type="search"
                                :placeholder="
                                    $t('com_typetosearch', { ns: 'common' })
                                "
                                @input="filterList"
                            ></b-form-input>

                            <b-input-group-append>
                                <b-button
                                    :disabled="!filter"
                                    @click="filter = ''"
                                    >{{
                                        $t('com_wis', { ns: 'common' })
                                    }}</b-button
                                >
                            </b-input-group-append>
                        </b-input-group>

                        <b-col sm="1" />
                        <b-col sm="10">
                            <Container
                                group-name="BewerkLijst"
                                :get-child-payload="getChildPayload1"
                                @drop="onDrop('availableListFiltered', $event)"
                            >
                                <Draggable
                                    v-for="item in availableListFiltered"
                                    :key="item.id"
                                >
                                    <div class="draggable-item">
                                        {{ item.naam }}
                                    </div>
                                </Draggable>
                            </Container>
                        </b-col>
                    </b-card>
                </div>
                <div class="group">
                    <b-card :header="$t('an_selected', { ns: 'analytics'})">
                        <Container
                            group-name="BewerkLijst"
                            :get-child-payload="getChildPayload2"
                            @drop="onDrop('selectedList', $event)"
                        >
                            <Draggable
                                v-for="item in selectedList"
                                :key="item.id"
                            >
                                <div class="draggable-item">
                                    {{ item.naam }}
                                </div>
                            </Draggable>
                        </Container>
                    </b-card>
                </div>
            </div>
        </b-card>
    </b-card>
</template>

<script>
import { Container, Draggable } from 'vue-dndrop';
import Helper from '../../models/Helper';
import { Permission } from '../../models/Permissions';
import { loadList } from './utils';
export default {
    name: 'ListSelector',
    data: function () {
        return {
            errorMessage: '',
            availableList: [],
            selectedList: [],
            baseList: null,
            bewerk: false,
            forcerefresh: false,
            touched: false,
            filter: '',
            aangeraakt: 1,
            berichtWijziging: this.$t('an_warnwijzigopslaan', {
                ns: 'analytics'
            })
        };
    },
    model: {
        prop: 'selected',
        event: 'input'
    },
    props: {
        selected: {
            type: Array
        },
        tekst: {
            type: String
        },
        listtype: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    components: { Container, Draggable },
    watch: {},
    computed: {
        title() {
            switch (this.listtype) {
                case window.constants.LISTTYPE_QUALITY:
                    return this.$t('an_tests', { ns: 'analytics' });
                case window.constants.LISTTYPE_QUANTITY:
                    return this.$t('an_metingen', { ns: 'analytics' });
                default:
                    return this.$t('com_onbekend', { ns: 'common' });
            }
        },
        availableListFiltered: {
            // getter
            get() {
                // eslint-disable-next-line no-unused-vars
                const nep = this.aangeraakt;
                const list = this.availableList.filter((x) => !x.hide);
                return list;
            },
            // setter
            set(newValue) {
                this.availableList = newValue;
            }
        },
        names() {
            // eslint-disable-next-line no-unused-vars
            const nep = this.aangeraakt;
            return this.selectedList.length > 0
                ? this.selectedList.map((x) => x.naam).join()
                : this.tekst;
        }
    },
    methods: {
        async fillLists() {
            const sel = this.loadSelectedList();
            let avail = await this.loadAvailableList();
            // haal de sel uit avail
            const lists = Helper.reduceListWithList(avail, sel);
            avail = lists.list1;
            this.availableList = avail;
            this.selectedList = sel;
            this.filterList();
            this.aangeraakt += 1;
            return avail;
        },
        applyDrag(arr, dragResult) {
            const { removedIndex, addedIndex, payload } = dragResult;
            if (removedIndex === null && addedIndex === null) return arr;

            const result = [...arr];
            let itemToAdd = payload;

            if (removedIndex !== null) {
                itemToAdd = result.splice(removedIndex, 1)[0];
            }

            if (addedIndex !== null) {
                result.splice(addedIndex, 0, itemToAdd);
            }

            return result;
        },
        onDrop(collection, dropResult) {
            this.touched = true;
            this[collection] = this.applyDrag(this[collection], dropResult);
            this.aangeraakt += 1;
            this.$emit('touched', this.touched);
        },

        getChildPayload1(index) {
            return this.availableListFiltered[index];
        },

        getChildPayload2(index) {
            return this.selectedList[index];
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        },
        async bewerkLijst() {
            this.fillLists();
            this.bewerk = !this.bewerk;
        },
        async emitLijst() {
            if (this.touched) {
                this.forcerefresh = true;
                this.$emit('touched', false);
            }
            this.$emit('change', true);
            const returnlijst = this.selectedList.map((x) => x.obj);
            this.baseList = returnlijst;
            this.$emit('input', returnlijst);
            this.bewerk = !this.bewerk;
            this.touched = false;
        },
        filterList() {
            for (let i = 0; i < this.availableList.length; i++) {
                const item = this.availableList[i];
                item.hide = true;
                const test = item.naam.toLowerCase();
                const filt = this.filter.toLowerCase();
                if (filt.length > 0) {
                    if (test.indexOf(filt) > -1) item.hide = false;
                } else {
                    item.hide = false;
                }
            }
            this.aangeraakt += 1;
        },
        async loadBaseList() {
            let templijst = [];
            switch (this.listtype) {
                case window.constants.LISTTYPE_QUALITY: {
                    templijst = await loadList(
                        'analytics/getQualityLijst',
                        'analytics/loadQualityLijst',
                        null,
                        this.forcerefresh,
                        this.$t
                    );
                    break;
                }
                case window.constants.LISTTYPE_QUANTITY: {
                    templijst = await loadList(
                        'analytics/getQuantityLijst',
                        'analytics/loadQuantityLijst',
                        null,
                        this.forcerefresh,
                        this.$t
                    );
                    break;
                }
                default: {
                    templijst = [];
                    break;
                }
            }
            return templijst;
        },
        loadSelectedList() {
            const complijst = this.baseList ?? this.selected;
            return complijst
                ? complijst.map((x) => ({
                      id: x.id,
                      naam: x.naam,
                      obj: x
                  }))
                : [];
        },
        async loadAvailableList() {
            const templijst = await this.loadBaseList();
            return templijst
                ? templijst.map((x) => ({
                      id: x.id,
                      naam: x.naam,
                      obj: x
                  }))
                : [];
        }
    },
    mounted() {
        this.fillLists();
        this.aangeraakt += 1;
    },
    created() {}
};
</script>

<style lang="css" scoped>
.groups {
    display: flex;
    justify-content: stretch;
}

.group {
    flex: 1;
}
</style>

