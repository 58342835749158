<template>
    <b-container fluid class="bs-example">
            <b-table
                striped
                responsive
                :fields="visibleFields"
                :items="sortededList"
                primary-key
            >
            </b-table>
    </b-container>
</template>

<script>
export default {
    name: 'MaintenanceItems',
    data() {
        return {
        };
    },
    props: {
        maintenanceItemlist: {
            type: Array,
            required: true
        }
    },
    components: {
    },
    computed: {
        visibleFields() {
            const fieldsInit = [
                { key: 'id', label: 'Id', sortable: false, visible: false },
                {
                    key: 'ondType.naam',
                    label: this.$t('prod_type', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'ondActiviteit.naam',
                    label: this.$t('prod_activity', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'opmerking',
                    label: this.$t('com_opmerking', { ns: 'common' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter(field => field.visible);
        },
        sortededList() {
            return this.maintenanceItemlist;
        }
    },
    mounted() {}
};
</script>

<style scoped>

</style>
