<template>
    <b-badge
        @click="showEvents"
        class="bgc_aux_warning float-right"
        :disabled="!editAllowed"
        ><h3>{{ giveEvents }}</h3>
    </b-badge>
</template>

<script>
import DateHelper from '../../models/DateHelper';
import { loadItem } from '../../models/DataHelper';
import { loadData } from '../masterdata/loaddata';
export default {
    name: 'RecurringEventsButton',
    data() {
        return {
            numberEventsToday: 0,
            numberEventsWeek: 0,
            eventList: [],
            interval: null,
            tijd: 1000 * 60 * 10
            
        };
    },
    components: {},
    model: {},
    props: {
        editAllowed: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        giveEvents() {
            return this.numberEventsToday + ' / ' + this.numberEventsWeek;
        }
    },
    methods: {
        showEvents() {
            this.$emit('show', this.eventList);
        },
        async loadRCData() {
            const week = DateHelper.determineVanTot(new Date(), 7);
            const selectionDataWeek = {
                from: DateHelper.localDateToUTC(week.van),
                until: DateHelper.localDateToUTC(week.tot)
            };
            const lijst = await loadItem(
                'recurrency/loadRecurrencyOverPeriod',
                selectionDataWeek,
                this.$t
            );
            this.numberEventsWeek = lijst.length;

            const today = DateHelper.determineVanTot();
            const selectionData = {
                from: DateHelper.localDateToUTC(today.van),
                until: DateHelper.localDateToUTC(today.tot)
            };
            this.eventList = await loadItem(
                'recurrency/loadRecurrencyOverPeriod',
                selectionData,
                this.$t
            );
            this.numberEventsToday = this.eventList.length;
        }
    },
    async mounted() {
        await this.loadRCData();
    },
    async created() {
        await this.loadRCData();
        this.interval = setInterval(() => this.loadRCData(), this.tijd);
    }
};
</script>

<style scoped></style>
