<template>
    <b-card>
        <b-tabs card>
            <b-tab :title="$t('an_result', { ns: 'analytics' })">
                <b-card-text>
                    <b-row>
                        <b-col sm="6">
                            <b-row>
                                <b-col sm="3">
                                    <label
                                        >{{
                                            $t('com_barcode', { ns: 'common' })
                                        }}:</label
                                    >
                                </b-col>
                                <div
                                    v-if="localItem.barcode"
                                    class="form-inline"
                                >
                                    <b-col>
                                        <barcode
                                            v-bind:value="localItem.barcode"
                                            v-bind:height="heightBC"
                                            v-bind:width="widthBC"
                                            id="targetDiv"
                                        >
                                            {{ nodisplay }}
                                        </barcode>
                                    </b-col>
                                    <b-col cols="2">
                                        <b-button
                                            @click="PrintElem()"
                                            class="bgc_aux_unimportant"
                                            ><font-awesome-icon
                                                icon="print"
                                                size="1x"
                                            /> </b-button
                                    ></b-col>
                                </div>
                            </b-row>
                        </b-col>
                        <b-col sm="6">
                            <b-row>
                                <b-col sm="3">
                                    <label for="dateOnderzoek"
                                        >{{
                                            $t('an_sampledatum', {
                                                ns: 'analytics'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <analytics-date-time
                                        id="dateOnderzoek"
                                        v-model="datumMonster"
                                        v-bind:editAllowed="editAllowed"
                                    >
                                    </analytics-date-time>
                                </b-col>
                                <b-col sm="3">
                                    <label for="dateBoeking"
                                        >{{
                                            $t('an_plandatum', {
                                                ns: 'analytics'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <analytics-date-time
                                        id="dateBoeking"
                                        v-model="datumPlan"
                                        v-bind:editAllowed="editAllowed"
                                    >
                                    </analytics-date-time>
                                </b-col>
                                <b-col sm="3">
                                    <label for="input-itemnaam"
                                        >{{
                                            $t('an_subject', {
                                                ns: 'analytics'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <entity-object-selector
                                        id="input-itemnaam"
                                        v-model="localItem.meetplekID"
                                        :editAllowed="editAllowed"
                                    ></entity-object-selector>
                                </b-col>
                                <b-col sm="3">
                                    <label
                                        >{{
                                            $t('an_omschrijving', {
                                                ns: 'analytics'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <b-form-input
                                        v-model="localItem.naam"
                                        :disabled="!editAllowed"
                                    ></b-form-input>
                                </b-col>
                                <b-col sm="3">
                                    <label
                                        >{{
                                            $t('prod_type', {
                                                ns: 'production'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <lookup-combo
                                        v-model="localItem.monstertype"
                                        v-bind:combotype="lctypeSample"
                                        :disabled="!editAllowed"
                                    ></lookup-combo>
                                </b-col>
                                <b-col sm="3">
                                    <label for="input-omschrijvingeenheid"
                                        >{{
                                            $t('com_unit', { ns: 'common' })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <b-form-input
                                        id="input-omschrijvingeenheid"
                                        v-model="localItem.eenheidBeschrijving"
                                        :disabled="!editAllowed"
                                    ></b-form-input>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-tab>
            <activity-log
                paramName="sampleID"
                action="analytics/loadSampleActiviteitenLijst"
                :id="localItem.id"
            />
        </b-tabs>
    </b-card>
</template>

<script>
import { SampleObject } from '../../models/Anayltics';
import LookupCombo from './LookupCombo.vue';
import EntityObjectSelector from './EntityObjectSelector.vue';
import ActivityLog from '../components/ActivityLog.vue';
import AnalyticsDateTime from './AnalyticsDateTime.vue';
import VueBarcode from 'vue-barcode';
export default {
    name: 'SampleInfo',
    data: function () {
        return {
            heightBC: 50,
            widthBC: 2,
            lctypeSample: window.constants.LCA_SAMPLETYPE,
            isChanged: false,
            naam: null,
            locItem: null,
            editAllowed: false,
            datumPlan: null,
            datumMonster: null,
            barcodeValue: null
        };
    },
    model: {
        prop: 'visible',
        event: 'changed'
    },
    props: {
        inItem: {
            type: SampleObject,
            required: false
        }
    },
    components: {
        LookupCombo,
        EntityObjectSelector,
        AnalyticsDateTime,
        ActivityLog,
        barcode: VueBarcode
    },
    watch: {},
    computed: {
        localItem() {
            return this.locItem;
        }
    },
    methods: {
        PrintElem() {
            const mywindow = window.open('', 'PRINT', 'height=400,width=600');

            mywindow.document.write(
                '<html><head><title>' + document.title + '</title>'
            );
            mywindow.document.write('</head><body >');
            mywindow.document.write(
                document.getElementById('targetDiv').innerHTML
            );
            mywindow.document.write('</body></html>');

            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/

            mywindow.print();
            mywindow.close();

            return true;
        },
        setdatumMonster(value) {
            this.datumMonster = value;
            this.setChanged();
        },
        setdatumPlan(value) {
            this.datumPlan = value;
            this.setChanged();
        },
        setChanged() {
            this.isChanged = true;
        }
    },
    mounted() {},
    created() {
        if (this.inItem) {
            this.locItem = this.inItem;
            this.datumMonster = this.locItem.neemtijdstip;
            this.datumPlan = this.locItem.plantijdstip;
            this.naam = this.locItem.naam;
        }
    }
};
</script>

<style scoped></style>

