<template>
    <b-dropdown
        id="dropdown-form"
        :disabled="disabled"
        :text="$t('prod_mymelk', { ns: 'production' })"
        ref="dropdown"
        :size="size"
    >
        <b-dropdown-form style="width: 340px">
            <b-form-group
                :label="$t('prod_melk', { ns: 'production' })"
                @submit.stop.prevent
            >
                <span>
                    <b-form-select
                        v-if="!existing"
                        v-model="productID"
                        :options="productList"
                    >
                        <template #first>
                            <b-form-select-option value=""
                                >--
                                {{
                                    $t('com_selecteer', {
                                        ns: 'common'
                                    })
                                }}
                                --</b-form-select-option
                            >
                        </template>
                    </b-form-select>
                    <b-form-input
                        id="dropdown-form-aantal"
                        v-model.number="selectedAmount"
                        size="sm"
                        :state="selectedAmount > 0"
                    ></b-form-input>
                </span>
            </b-form-group>
            <b-button
                size="sm"
                @click="createNewItem"
                class="mr-1"
                :disabled="!checkState()"
                ><font-awesome-icon icon="folder-plus" size="1x" />
                {{ $t('com_makenew', { ns: 'common', val: null }) }}
            </b-button>
        </b-dropdown-form>
    </b-dropdown>
</template>

<script>
import { VoorraadItem } from '../../models/Voorraad';
import Helper from '../../models/Helper';
import { loadItem } from '../../models/DataHelper';
import DateHelper from '../../models/DateHelper';

export default {
    name: 'InstantMilkStock',
    data() {
        return {
            flexList: [],
            productID: null,
            selectedAmount: 0
        };
    },
    components: {},
    props: {
        productIn: {
            type: Number,
            default: 0
        },
        productListIn: {
            type: Array
        },
        disabled: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: ''
        }
    },
    computed: {
        existing() {
            return this.productIn > 0;
        },
        productList() {
            const tempList = this.productListIn.map((x) => ({
                value: x.id,
                text: x.naam ?? x.internalname
            }));
            return tempList;
        }
    },
    methods: {
        checkState() {
            return this.selectedAmount > 0 && this.productID;
        },
        async createNewItem() {
            const lijst = this.$store.getters['production/getMilkList'];
            // zoek in lijst op en overrule eventueel de eenheid
            const product = lijst?.find((x) => x.id == this.productID);
            const unit = product ? product.eenheid : window.constants.KILOS;

            this.currentItem = new VoorraadItem();
            this.currentItem.batchID = Helper.stringformat(
                '{0}-{1}',
                DateHelper.DateToYMD(),
                DateHelper.DateToHM()
            );
            this.currentItem.opmerking = 'On the fly';
            this.currentItem.aantal = this.selectedAmount;
            this.currentItem.productID = this.productID;
            this.currentItem.unitID = unit;
            this.currentItem.locationID = window.constants.OWNMILKSTOCKLOCATION;
            this.currentItem.supplierID = window.constants.THISCOMPANY;
            const stock = this.currentItem.SetForAdd();
            await loadItem('stock/addStockItem', stock, this.$t);
            this.$emit('batchID', this.currentItem.batchID);
            this.$nextTick(() => {
                this.$refs.dropdown.hide();
            });
        }
    },
    mounted() {
        if (this.productIn > 0) {
            this.productID = this.productIn;
        }
    },
    created() {}
};
</script>

