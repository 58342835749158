const getters = {
    getBatchInfoLijst: (state) => {
        return state.batchInfoList;
    },
    getBatchInfoHistLijst: (state) => {
        return state.batchInfoHistList;
    }, 
    getSelectedBatch: state =>  {
        return state.selectedBatch;
    },
    getSelectedProdrun: state =>  {
        return state.selectedProdRun;
    },
    getSelectedBatchIngredient: state =>  {
        return state.getSelectedBatchIngredient;
    },
    getBatchIngredientenLijst: (state) => {
        return state.batchIngredientenList;
    },
    getBatchInpekelList: (state) =>{
        return state.batchInpekelList;
    } ,
    getAlternativeCheeseList: (state) =>{
        return state.alternativeCheeseList;
    } ,
    getBatchDefaults: (state) =>{
        return state.batchDefaults;
    } ,
    getMarksForBatch: (state) =>{
        return state.marksForBatch;
    } ,
    getMilkusagefactor: (state) =>{
        return state.milkusagefactor;
    } ,
    getMilkList: (state) =>{
        return state.milkList;
    } ,
    getBatchActivityList: (state) =>{
        return state.batchActivityList;
    } ,
    getTimerForBatch: (state) => (bakid) => {
        return state.timerList.find((x) => x.bakID == bakid);
    },
    getTimerList: (state) => {
        return state.timerList;
    },
    
    
};

export default getters;

