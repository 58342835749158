import Helper from './Helper';
import DateHelper from './DateHelper';
export {
    VoorraadItem,
    MerkVoorraadItem,
    StockLocationType,
    SoldStockItemsModel,
    StockItemsModel
};

class SoldStockItemsModel {
    constructor(data) {
        data = data ?? {};
        this.mutatieReferentie = data.ref;
        this.klant = data.customerID;
        this.regels = data.stockItems
            ? data.stockItems.map((x) => new StockItemsModel(x))
            : [];
    }
    CheckValidity(func) {
        let warnText = '';
        if (!this.mutatieReferentie)
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('com_reference', { ns: 'common' })
                })
            );
        return warnText;
    }
    SetForAdd() {
        const StockItems = [];
        for (let i = 0; i < this.regels.length; i++) {
            const obj = this.regels[i].SetForSave(i);
            StockItems.push(obj);
        }
        const SoldStockItemsModel = {
            MutationRef: this.mutatieReferentie,
            CustomerID: this.klant,
            StockItems: StockItems
        };
        return SoldStockItemsModel;
    }
}
class StockItemsModel {
    constructor(data) {
        data = data ?? {};
        this.id = data.stockItemID;
        this.hoeveelheid = data.mutationAmount;
        this.naam = '';
    }
    SetForSave() {
        const StockItem = {
            StockItemID: this.id,
            MutationAmount: this.hoeveelheid
        };
        return StockItem;
    }
}

class VoorraadItem {
    constructor(data) {
        this.id = -1;
        this.type = 'VoorraadItem';
        this.isDeleted = false;
        this.productiedatum = null; // voor typescript
        this.batchID = null; // voor typescript
        this.productID = null; // voor typescript
        this.naam = null; // voor typescript
        this.aantal = null; // voor typescript
        this.leeftijdDagen = null; // voor typescript
        this.leeftijdKlasse = null; // voor typescript
        this.datum = null; // voor typescript
        this.vormID = null; // voor typescript
        this.unitID = null; // voor typescript
        this.prodcatID = null; // voor typescript
        data = data ?? {};
        this.attcount = data.attachments;
        Object.defineProperties(this, {
            id: {
                value: data.id ?? -1,
                writable: false
            },
            gewijzigd: {
                value: data.updated,
                writable: false
            },
            productID: {
                type: Number,
                value: data.productId ?? data.pt_id,
                writable: true,
                enumerable: true
            },
            unitID: {
                value: data.perUnitId,
                writable: true,
                enumerable: true
            },
            baseUnit: {
                value: data.perUnitId, // klopt niet
                writable: true,
                enumerable: true
            },
            locationID: {
                value: data.stockLocationID,
                writable: true,
                enumerable: true
            },
            supplierID: {
                value: data.cpnSupplierID,
                writable: true,
                enumerable: true
            },
            supplierName: {
                value: data.suppliername,
                writable: true,
                enumerable: true
            },
            melkBron: {
                type: String,
                value: data.milksupplier,
                writable: true,
                enumerable: true
            },
            batchID: {
                type: String,
                value: data.batchNumber ?? data.batchnumber ?? data.batchgroup,
                writable: true,
                enumerable: true
            },
            locatie: {
                value: data.location,
                writable: true
            },
            aantal: {
                value: data.amount,
                writable: true,
                enumerable: true
            },
            eenheid: {
                value: data.unit,
                writable: true,
                enumerable: true
            },
            datum: {
                value: data.tht,
                writable: true,
                enumerable: true
            },
            productiedatum: {
                value:
                    data.proddate ?? data.productiondate ?? data.productionDate,
                writable: true,
                enumerable: true
            },
            leverancier: {
                value: data.manufacturer,
                writable: true,
                enumerable: true
            },
            externNaam: {
                value: data.supplieritemname ?? data.supplierItemName,
                writable: true,
                enumerable: true
            },
            externID: {
                value: data.supplieritemcode ?? data.supplierItemCode,
                writable: true,
                enumerable: true
            },
            status: {
                value: data.status,
                writable: true,
                enumerable: true
            },
            statusNummer: {
                type: Number,
                value: data.statuscode,
                writable: true,
                enumerable: true
            },
            externalID: {
                type: String,
                value: data.externalid,
                writeble: true,
                enumerable: true
            },
            leeftijdDagen: {
                type: Number,
                value: data.days,
                writeble: true,
                enumerable: true
            },
            leeftijdKlasse: {
                type: String,
                value: data.ageclass,
                writeble: true,
                enumerable: true
            },
            // info
            opmerking: {
                value: data.remark,
                writable: true,
                enumerable: true
            },
            vanaf: {
                value: DateHelper.convertedToDate(data.started),
                writable: true,
                enumerable: true
            },
            tot: {
                value: DateHelper.convertedToDate(data.ended),
                writable: true,
                enumerable: true
            },
            vandatum: {
                value: data.started,
                writable: true
            },
            totdatum: {
                value: data.ended,
                writable: true
            },
            naam: {
                value: data.internalname ?? data.description ?? data.product,
                writable: true,
                enumerable: true
            },
            internID: {
                value: data.internalid,
                writable: true,
                enumerable: true
            },
            prodcatNaam: {
                type: String,
                value: data.productcategory,
                writable: true,
                enumerable: true
            },
            prodcatID: {
                value: data.pt_ptc_id ?? data.ptc_id,
                writable: true,
                enumerable: true
            },
            productType: {
                value: data.pt_type,
                writable: true,
                enumerable: true
            },
            ingrcatNaam: {
                value: data.category,
                writable: true,
                enumerable: true
            },
            categorie: {
                value: data.category ?? data.productcategory,
                writable: true,
                enumerable: true
            },
            pakbonID: {
                value: data.dn_id,
                writable: true,
                enumerable: true
            },
            vorm: {
                type: String,
                value: data.shape,
                writable: true,
                enumerable: true
            },
            vormID: {
                type: Number,
                value: data.shapeId,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
        this.waarschuwen = this.aantal < 0;
        this.bewerkbaar =
            this.statusNummer == undefined || this.statusNummer < 2;
    }

    SetForAdd() {
        const AddStockItemModel = {
            Batchnumber: this.batchID,
            Remark: this.opmerking,
            CpnSupplierID: this.supplierID,
            AmountInitial: this.aantal,
            ProductId: this.productID,
            PerUnitId: this.unitID,
            THT: DateHelper.localDateToUTC(this.datum),
            ProductionDate: DateHelper.localDateToUTC(this.productiedatum),
            StockLocationID: this.locationID,
            SupplierItemName: this.externNaam,
            SupplierItemCode: this.externID
        };
        return AddStockItemModel;
    }

    SetForUpdate() {
        const StockItemModel = {
            ID: this.id,
            Updated: this.gewijzigd,
            Batchnumber: this.batchID,
            Remark: this.opmerking,
            CpnSupplierID: this.supplierID,
            AmountInitial: this.aantal,
            ProductId: this.productID,
            PerUnitId: this.unitID,
            THT: DateHelper.localDateToUTC(this.datum),
            ProductionDate: DateHelper.localDateToUTC(this.productiedatum),
            StockLocationID: this.locationID,
            SupplierItemName: this.externNaam,
            SupplierItemCode: this.externID
        };
        return StockItemModel;
    }

    CheckValidity(func) {
        let warnText = '';
        if (this.aantal) {
            if (this.aantal < 0 || this.aantal > 50000)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop: func('com_amount', { ns: 'common' }),
                        val: this.amount
                    })
                );
        } else {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('com_amount', { ns: 'common' })
                })
            );
        }
        if (!this.batchID)
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('com_batch', { ns: 'common' })
                })
            );
        if (!this.locationID)
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('com_storagelocation', { ns: 'common' })
                })
            );
        return warnText;
    }
}

class MerkVoorraadItem {
    constructor(data) {
        this.id = -1;
        this.type = 'MerkVoorraadItem';
        data = data ?? {};
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        this.attcount = data.attachments;
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false
            },
            gewijzigd: {
                value: data.updated,
                writable: false
            },
            batchID: {
                type: String,
                value: data.batchNumber ?? data.batchnumber,
                writable: true,
                enumerable: true
            },
            unitID: {
                type: Number,
                value: data.perUnitId ?? 3,
                writable: true,
                enumerable: true
            },
            locationID: {
                type: Number,
                value: data.stockLocationID,
                writable: true,
                enumerable: true
            },
            supplierID: {
                value: data.cpnSupplierID,
                writable: true,
                enumerable: true
            },
            locatie: {
                value: data.location,
                writable: true,
                enumerable: true
            },
            productID: {
                type: Number,
                value: data.productId ?? data.pt_id,
                writable: true,
                enumerable: true
            },
            vetklasse: {
                type: String,
                value: data.fatClass ?? data.fatclass,
                writable: true,
                enumerable: true
            },
            aantal: {
                value: data.amount,
                writable: true,
                enumerable: true
            },
            baseUnit: {
                value: data.perUnitId, // klopt niet
                writable: true,
                enumerable: true
            },
            eenheid: {
                value: data.unit,
                writable: true,
                enumerable: true
            },
            leverancier: {
                value: data.manufacturer,
                writable: true,
                enumerable: true
            },
            externNaam: {
                value: data.supplieritemname,
                writable: true,
                enumerable: true
            },
            externID: {
                value: data.supplieritemcode,
                writable: true,
                enumerable: true
            },
            status: {
                value: data.status,
                writable: true,
                enumerable: true
            },
            statusNummer: {
                type: Number,
                value: data.statuscode,
                writable: true,
                enumerable: true
            },
            letters: {
                type: String,
                value: data.letter,
                writable: true,
                enumerable: true
            },
            startnummer: {
                type: Number,
                value: data.startNumber ?? data.startnumber,
                writable: true,
                enumerable: true
            },
            eindnummer: {
                type: Number,
                value: data.endNumber ?? data.endnumber,
                writable: true,
                enumerable: true
            },
            // info
            opmerking: {
                value: data.remark,
                writable: true
            },
            vanaf: {
                value: DateHelper.convertedToDate(data.started),
                writable: true
            },
            tot: {
                value: DateHelper.convertedToDate(data.ended),
                writable: true
            },
            vandatum: {
                value: data.started,
                writable: true
            },
            totdatum: {
                value: data.ended,
                writable: true
            },
            naam: {
                value: data.internalname ?? data.description,
                writable: true
            },
            internID: {
                value: data.internalid,
                writable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }

    SetForAdd() {
        const locBatchID = Helper.stringformat(
            '{0}-{1}-{2}-{3}',
            this.vetklasse,
            this.letters,
            this.startnummer,
            this.eindnummer
        );
        const AddStockItemModel = {
            Batchnumber: locBatchID,
            Remark: this.opmerking,
            CpnSupplierID: this.supplierID,
            AmountInitial: this.aantal,
            ProductId: this.productID,
            PerUnitId: this.unitID,
            THT: DateHelper.localDateToUTC(this.datum),
            StockLocationID: this.locationID,
            SupplierItemName: this.externNaam,
            SupplierItemCode: this.externID,
            Letter: this.letters,
            StartNumber: this.startnummer,
            EndNumber: this.eindnummer
        };
        return AddStockItemModel;
    }

    SetForUpdate() {
        const StockItemModel = {
            ID: this.id,
            Updated: this.gewijzigd,
            Batchnumber: this.batchID,
            Remark: this.opmerking,
            CpnSupplierID: this.supplierID,
            AmountInitial: this.aantal,
            ProductId: this.productID,
            PerUnitId: this.unitID,
            THT: DateHelper.localDateToUTC(this.datum),
            StockLocationID: this.locationID,
            SupplierItemName: this.externNaam,
            SupplierItemCode: this.externID,
            Letter: this.letters,
            StartNumber: this.startnummer,
            EndNumber: this.eindnummer
        };
        return StockItemModel;
    }

    CheckValidity(func) {
        let warnText = '';
        if (this.aantal) {
            if (this.aantal < 0 || this.aantal > 100000)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop: func('com_amount', { ns: 'common' }),
                        val: this.aantal
                    })
                );
        } else {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('com_amount', { ns: 'common' })
                })
            );
        }
        // if (!this.batchID)
        //     warnText = Helper.concatString(warnText, 'Partij-ID is verplicht ');
        if (!this.locationID)
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('com_storagelocation', { ns: 'common' })
                })
            );
        if (!this.startnummer)
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('prod_startnummer', { ns: 'production' })
                })
            );
        if (!this.eindnummer)
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('prod_eindnummer', { ns: 'production' })
                })
            );
        return warnText;
    }
}


class StockLocationType {
    constructor(data) {
        this.id = -1;
        this.type = 'StockLocationType';
        data = data ?? {};
        this.isDeleted = false;
        this.isChanged = false;
        Object.defineProperties(this, {
            id: {
                value: data.id ?? data.ID,
                writable: false,
                enumerable: true
            },
            naam: {
                value: data.description,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }
}

