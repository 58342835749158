const mutations = {

    insertOstLijst(state, lijst) {
        state.ostLijst = [];
        if (lijst != undefined) {
            state.ostLijst = lijst;
        }
    },    
    insertVersionInfo(state, item) {
        state.versionInfo = null;
        if (item != undefined) {
            state.versionInfo = item;
        }
    },
    insertPackageVersion(state, item) {
        state.packageVersion = null;
        if (item != undefined) {
            state.packageVersion = item;
        }
    },
    insertLocationLijst(state, lijst) {
        state.locationLijst = [];
        if (lijst != undefined) {
            state.locationLijst = lijst;
        }
    },
    insertCompaniesLijst(state, lijst) {
        state.companyLijst = [];
        if (lijst != undefined) {
            state.companyLijst = lijst;
        }
    },
    insertShapesLijst(state, lijst) {
        state.shapesLijst = [];
        if (lijst != undefined) {
            state.shapesLijst = lijst;
        }
    },      
    insertConstantsLijst(state, lijst) {
        state.constantsLijst = [];
        if (lijst != undefined) {
            state.constantsLijst = lijst;
        }
    },
    insertStockLocationTypes(state, lijst) {
        state.stockLocationTypesLijst = [];
        if (lijst != undefined) {
            state.stockLocationTypesLijst = lijst;
        }
    },
    insertEquipmentsLijst(state, lijst) {
        state.equipmentLijst = [];
        if (lijst != undefined) {
            state.equipmentLijst = lijst;
        }
    },
    insertMessagesLijst(state, lijst) {
        state.berichtenLijst = [];
        if (lijst != undefined) {
            state.berichtenLijst = lijst;
        }
    },
    insertReportGroupLijst(state, lijst) {
        state.reportgroupLijst = [];
        if (lijst != undefined) {
            state.reportgroupLijst = lijst;
        }
    },
    
    
};

export default mutations;
