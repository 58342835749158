import Api from '../../../api';
import store from '../../../store';

const fetchOutbrineInfoOverPeriod = async data => {
    return await fetchInfo( '/batch/GetBatchOutBrineInfoOverPeriod', data) ;
};

const fetchOutbrineInfo = async data => {
    return await fetchInfo( '/batch/GetBatchOutBrineInfo', data) ;
};

const fetchBGOutbrineInfo = async data => {
    return await fetchInfo( '/batch/GetBatchGroupOutBrineInfo', data) ;
};

const fetchBGoutbrineAmounts = async data => {
    return await fetchInfo( '/batch/GetBatchGroupOutBrineAmounts', data) ;
};

const fetchBGinbrineAmounts = async data => {
    return await fetchInfo( '/batch/GetBatchGroupInBrineAmounts', data) ;
};

// int stockLocationID
const fetchStockLocationActivities = async (data) => {
    return fetchInfo('/StockLocationProduction/GetStockLocationActivityLog', data);
};
// DateTime from, DateTime until
const fetchStockLocationIngredientInfo = async (data) => {
    return fetchInfo('/StockLocationProduction/GetStockLocationIngredientInfo', data);
};
//int stocklocationingredientid
const fetchStockLocationIngredient = async (data) => {
    return fetchInfo('/StockLocationProduction/GetStockLocationIngredient', data);
};



// array 
const addStockLocIngredients = async data => {     
    return await Api(store).put('/StockLocationProduction/StoreStockLocationIngredients', data);
};

const updateBatchGroupOutBrine = async data => {
    return await Api(store).put('/batch/BatchGroupOutBrine', data);
 };

const deleteBatchIngredient = async data => {
    return await Api(store).delete('/batch/RemoveBatchIngredient', data);
 };
 //int id, int stocklocationid, DateTime lastupdated
 const deleteStockLocationIngredient = async data => {
     //return await Api(store).delete('/StockLocationProduction/RemoveStockLocationIngredient', data);
     return await Api(store).delete(
         '/StockLocationProduction/RemoveStockLocationIngredient?' +
             Object.keys(data)
                 .map(
                     k =>
                         encodeURIComponent(k) +
                         '=' +
                         encodeURIComponent(data[k] ?? '')
                 )
                 .join('&'),
         {
             headers: {
                 'Content-Type': 'application/x-www-form-urlencoded'
             }
         }
     );
  };
 


 const fetchInfo = async (apicall, data) => {
    if (data != undefined) {
        return await Api(store).get(
            apicall +'?' +
                Object.keys(data)
                    .map(
                        k =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    } else {
        return await Api(store).get(apicall, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    }
};

export default {
    fetchOutbrineInfoOverPeriod,
    fetchOutbrineInfo,
    fetchBGoutbrineAmounts,
    fetchBGOutbrineInfo,
    fetchBGinbrineAmounts,
    fetchStockLocationActivities,
    fetchStockLocationIngredientInfo,
    fetchStockLocationIngredient,
    updateBatchGroupOutBrine,
    deleteBatchIngredient,
    deleteStockLocationIngredient,
    addStockLocIngredients
};
