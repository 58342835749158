const mutations = {
    insertSelectedMaintenance(state, item) {
        state.selectedMaintenance = item;
    },
    insertMaintenanceInfoLijst(state, lijst) {
        state.maintenanceInfoList = [];
        if (lijst != undefined) {
            state.maintenanceInfoList = lijst;
        }
    },
    insertMaintenanceLijst(state, lijst) {
        state.maintenanceList = [];
        if (lijst != undefined) {
            state.maintenanceList = lijst;
        }
    },
    insertOnderhoudsActivitiesLijst(state, lijst) {
        state.onderhoudsActivitiesLijst = [];
        if (lijst != undefined) {
            state.onderhoudsActivitiesLijst = lijst;
        }
    },
    insertOnderhoudsTypesLijst(state, lijst) {
        state.onderhoudsTypesLijst = [];
        if (lijst != undefined) {
            state.onderhoudsTypesLijst = lijst;
        }
    }   
};

export default mutations;
