import Api from '../../../api';
import store from '../../../store';

//#region Voorraad
const fetchStockInfo = async (data) => {
    return fetchInfo('/StockItem/GetStockItems', data);
};

const fetchInBrineInfo = async (data) => {
    return fetchInfo('/StockItem/GetInBrineInfo', data);
};

const fetchStockInfoArchived = async (data) => {
    return fetchInfo('/StockItem/GetArchivedStockItems', data);
};

const fetchStockByProduct = async (data) => {
    return fetchInfo('/StockItem/GetStockItemsByProductID', data);
};

// int stockitemid
const fetchStockItem = async (data) => {
    return fetchInfo('/StockItem/GetStockItem', data);
};

// int stockitemid
const fetchStockItemInfo = async (data) => {
    return fetchInfo('/StockItem/GetStockItemInfo', data);
};
// int productionNumber
const fetchStockItemByPartij = async (data) => {
    return fetchInfo('/StockItem/FindProductionStockItems', data);
};

const fetchStockItemMarksInfo = async (data) => {
    return fetchInfo('/Batch/GetBatchGroupCheeseMarksInfo', data);
};
// data= AddStockItemModel
const addStockItem = async (data) => {
    return await Api(store).put('/StockItem/AddStockItem', data);
};
// data= StockItemModel
const updateStockItem = async (data) => {
    return await Api(store).put('/StockItem/UpdateStockItem', data);
};
// data= CloseStockItemModel
const closeStockItem = async (data) => {
    return await Api(store).put('/StockItem/CloseStockItem', data);
};
// data= DeleteStockItemModel
const deleteStockItem = async (data) => {
    //return await Api(store).delete('/StockItem/DeleteStockItem', data);
    return await Api(store).delete(
        '/StockItem/DeleteStockItem?' +
            Object.keys(data)
                .map(
                    (k) =>
                        encodeURIComponent(k) +
                        '=' +
                        encodeURIComponent(data[k] ?? '')
                )
                .join('&'),
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
    );
};
// data= MoveStockItemModel
const moveStockItem = async (data) => {
    return await Api(store).put('/StockItem/MoveStockItem', data);
};
// data= ChangeStockItemModel
const correctStockAmount = async (data) => {
    return await Api(store).put('/StockItem/CorrectStockAmount', data);
};
// data= ChangeStockItemModel
const solledStockAmount = async (data) => {
    return await Api(store).put('/StockItem/SoldStockAmount', data);
};
// data= SoldStockItemsModel
const soldStockAmounts = async (data) => {
    return await Api(store).put('/StockItem/soldStockAmounts', data);
};

//#endregion Voorraad

//#region Merktype

const fetchMarkStockInfo = async (data) => {
    return fetchInfo('/StockItem/GetMarkStockItems', data);
};

const fetchMarkStockInfoArchived = async (data) => {
    return fetchInfo('/StockItem/GetArchivedMarkStockItems', data);
};

const fetchMarkStockByProduct = async (data) => {
    return fetchInfo('/StockItem/GetMarkStockItemsByProductID', data);
};

// data = AddMarkStockItemModel
const addMarkStockItem = async (data) => {
    return await Api(store).put('/StockItem/AddMarkStockItem', data);
};
// data = MarkStockItemModel
const updateMarkStockItem = async (data) => {
    return await Api(store).put('/StockItem/UpdateMarksStockItem', data);
};

//#endregion Merktype
const fetchStockLocationTypes = async (data) => {
    return fetchInfo('/MasterData/GetStockLocationTypes', data);
};

const fetchInfo = async (apicall, data) => {
    if (data != undefined) {
        return await Api(store).get(
            apicall +
                '?' +
                Object.keys(data)
                    .map(
                        (k) =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    } else {
        return await Api(store).get(apicall, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    }
};

export default {
    fetchStockItem,
    fetchInBrineInfo,
    fetchStockItemInfo,
    fetchStockItemMarksInfo,
    fetchStockItemByPartij,
    fetchStockInfo,
    fetchStockByProduct,
    fetchStockInfoArchived,
    addStockItem,
    updateStockItem,
    deleteStockItem,
    closeStockItem,
    moveStockItem,
    correctStockAmount,
    solledStockAmount,
    soldStockAmounts,
    fetchMarkStockInfo,
    fetchMarkStockInfoArchived,
    fetchMarkStockByProduct,
    addMarkStockItem,
    updateMarkStockItem,
    fetchStockLocationTypes
};

