 import store from '../../store';
 import { SecurityService } from '../account/security';

const authenticate = async (returnPath) => {
    const manager = store.getters['account/getLogInManager'] ?? new SecurityService();
    let user = store.getters['account/getLoggedInOpenIDUser']; //see if the user details are in local storage
    if (user == undefined) {
      user = await store.dispatch('account/loadUser', manager);          
    }
    if (user == undefined) {
      await manager.signIn(returnPath);

    }
};

export default authenticate;
