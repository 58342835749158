<template>
    <b-card no-body :header="$t('prod_vormen', { ns: 'production' })">
        <div>
            <b-card>
                <b-row>
                    <b-col sm="3">
                        <label for="input-itemnaam"
                            >{{ $t('com_naam', { ns: 'common' }) }}:</label
                        >
                    </b-col>
                    <b-col sm="9">
                        <b-form-input
                            id="input-itemnaam"
                            :placeholder="$t('com_naam', { ns: 'common' })"
                            v-model="naam"
                            :disabled="readonly"
                            @change="setChangedNaam"
                            :state="naam != undefined && naam.length > 2"
                            autofocus
                        ></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="3">
                        <label for="input-locatieType"
                            >{{ $t('prod_type', { ns: 'production' }) }}:</label
                        >
                    </b-col>
                    <b-col sm="9">
                        <b-form-select
                            v-model="reportType"
                            :options="flexList"
                            @change="setType"
                        >
                            <template #first>
                                <b-form-select-option value=""
                                    >--
                                    {{
                                        $t('com_selecteer', {
                                            ns: 'common'
                                        })
                                    }}
                                    --</b-form-select-option
                                >
                            </template>
                        </b-form-select>
                    </b-col>

                    <b-col sm="3">
                        <label
                            >{{ $t('com_opmerking', { ns: 'common' }) }}:</label
                        >
                    </b-col>
                    <b-col sm="9">
                        <b-form-textarea
                            v-model="opmerking"
                            :placeholder="$t('com_opmerking', { ns: 'common' })"
                            rows="3"
                            max-rows="6"
                            @change="setChangedOpm"
                        ></b-form-textarea>
                    </b-col>
                </b-row>
            </b-card>
        </div>
    </b-card>
</template>

<script>
import { ReportGroup } from '../../models/Anayltics';

export default {
    name: 'CardReportGroups',
    data() {
        return {
            flexList: [
                { value: 'CERT', text: 'Certificering' },
                { value: 'WET', text: 'Wetgeving' },
                { value: 'TECH', text: 'Technologie' }
            ],
            naam: '',
            opmerking: '',
            reportType: ''
        };
    },
    components: {},
    model: {
        prop: 'theReportGroup',
        event: 'changed'
    },
    props: {
        theReportGroup: {
            type: ReportGroup,
            required: true
        },
        editAllowed: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        readonly() {
            return !this.editAllowed;
        }
    },
    methods: {
        setType: function () {
            this.theReportGroup.typeNaam = this.reportType;
            this.setChanged();
        },
        setChangedNaam: function () {
            this.theReportGroup.naam = this.naam;
            this.setChanged();
        },
        setChangedOpm: function () {
            this.theReportGroup.opmerking = this.opmerking;
            this.setChanged();
        },
        setChanged: function () {
            this.$emit('validComponent', this.theReportGroup.naam != undefined);
            this.$emit('change', true);
        }
    },
    mounted() {
        this.opmerking = this.theReportGroup.opmerking;
        this.naam = this.theReportGroup.naam;
        this.reportType = this.theReportGroup.typeNaam;
    },
    created() {}
};
</script>

