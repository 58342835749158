const getters = {
    getDeliveryNotes: (state) => {
        return state.deliveryNoteLijst;
    },
    getDeliveryNotesPrinted: (state) => {
        return state.deliveryNoteLijstPrinted;
    },
    getPrintOptions: (state) => {
        return state.printOptionsLijst;
    },
    getDeliveryNote: (state) => {
        return state.selectedDeliveryNote;
    },
    getOwnCompany: (state) => {
        return state.ownCompany;
    }
};

export default getters;
