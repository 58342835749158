<template>
    <b-container fluid class="bs-example">
        <b-card no-body class="mb-1">
            &nbsp;
            <b-table
                striped
                responsive
                :fields="visibleFields"
                :items="sortededList"
                primary-key
            >
                <template v-if="!gesloten" v-slot:cell(actions)="row">
                    <b-button
                        size="sm"
                        @click="editItem(row.item)"
                        class="bgc_aux_warning"
                        ><font-awesome-icon icon="edit" size="1x" />
                    </b-button>
                    <b-button
                        size="sm"
                        @click="verwijderItem(row.item)"
                        class="bgc_aux_alarm"
                        ><font-awesome-icon icon="trash-alt" size="1x" />
                    </b-button>
                    <b-dropdown
                        id="dropdown-form"
                        text="..."
                        ref="dropdown"
                        size="sm"
                        boundary="viewport"
                        @show="setStartValues(row.item)"
                    >
                        <div class="fluid-container">
                            <b-dropdown-form>
                                <div>
                                    <b-form-group
                                        :label="
                                            $t('log_verplaats', {
                                                ns: 'logistics'
                                            })
                                        "
                                        label-for="dropdown-form-move"
                                        @submit.stop.prevent
                                    >
                                        <div class="form-inline">
                                            <b-form-input
                                                id="dropdown-form-correct"
                                                v-model.number="verplaatsAmount"
                                                :placeholder="
                                                    $t('log_correctieaantal', {
                                                        ns: 'logistics'
                                                    })
                                                "
                                            ></b-form-input>
                                            <lookup-combo
                                                id="dropdown-form-move"
                                                v-model="newlocationID"
                                                v-bind:combotype="
                                                    lctypeLocation
                                                "
                                            ></lookup-combo>
                                        </div>
                                        <b-button
                                            class="float-right"
                                            variant="primary"
                                            @click="saveLocation(row.item)"
                                            >Ok</b-button
                                        >
                                    </b-form-group>
                                </div>
                                <b-dropdown-divider></b-dropdown-divider>
                            </b-dropdown-form>
                            <b-dropdown-form>
                                <div>
                                    <b-form-group
                                        :label="
                                            $t('log_corrigeer', {
                                                ns: 'logistics'
                                            })
                                        "
                                        label-for="dropdown-form-correct"
                                        @submit.stop.prevent
                                    >
                                        <b-form-input
                                            id="dropdown-form-correct"
                                            v-model.number="newAmount"
                                            :placeholder="
                                                $t('log_correctieaantal', {
                                                    ns: 'logistics'
                                                })
                                            "
                                        ></b-form-input>
                                        <b-button
                                            class="float-right"
                                            variant="primary"
                                            @click="saveAmount(row.item)"
                                            >Ok</b-button
                                        >
                                    </b-form-group>
                                </div>
                                <b-dropdown-divider></b-dropdown-divider>
                                <b-dropdown-item
                                    @click="saveStockZero(row.item)"
                                    >{{
                                        $t('log_closenostock', {
                                            ns: 'logistics'
                                        })
                                    }}</b-dropdown-item
                                >
                                <b-dropdown-item
                                    @click="saveStockClose(row.item)"
                                    >{{
                                        $t('log_closenouse', {
                                            ns: 'logistics'
                                        })
                                    }}</b-dropdown-item
                                >
                            </b-dropdown-form>
                        </div>
                    </b-dropdown>
                </template>
            </b-table>
        </b-card>
        <item-stock-modal
            v-if="detailsVisible"
            :inItem="currentItem"
            v-model="detailsVisible"
            v-bind:editAllowed="true"
            @hide="reset"
        />
    </b-container>
</template>

<script>
import _ from 'lodash';
import ItemStockModal from './ItemStockModal';
import { LookupCombo } from '../components';
import { loadItem } from '@/models/DataHelper';
export default {
    name: 'MarkStockItems',
    data() {
        return {
            lctypeLocation: window.constants.LCLOCATION,
            currentItem: null,
            detailsVisible: false,
            newlocationID: null,
            verkoopAmount: null,
            verplaatsAmount: null,
            newAmount: null
        };
    },
    props: {
        stocklist: {
            type: Array,
            required: true
        },
        gesloten: {
            type: Boolean,
            default: false
        }
    },
    components: {
        LookupCombo,
        ItemStockModal
    },
    computed: {
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                {
                    key: 'status',
                    label: this.$t('com_status', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'aantal',
                    label: this.$t('com_amount', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'letters',
                    label: this.$t('com_letters', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'startnummer',
                    label: this.$t('com_start', { ns: 'common' }) + '#',
                    sortable: true,
                    visible: true
                },
                {
                    key: 'eindnummer',
                    label: this.$t('com_eind', { ns: 'common' }) + '#',
                    sortable: true,
                    visible: true
                },
                {
                    key: 'vanaf',
                    label: this.$t('log_inuse', {
                        ns: 'logistics'
                    }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'locatie',
                    label: this.$t('com_location', { ns: 'common' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        sortededList() {
            return _.sortBy(this.stocklist, ['batchID']);
        }
    },
    methods: {
        reset() {
            this.detailsVisible = false;
            this.currentItem = null;
        },
        async getDetailItem(item) {
            return await loadItem(
                'stock/loadMarkStockItem',
                {
                    stockitemid: item.id
                },
                this.$t
            );
        },
        async editItem(item) {
            const refreshedItem = await this.getDetailItem(item);
            this.currentItem = refreshedItem;
            this.detailsVisible = true;
        },
        async setStartValues(item) {
            const refreshedItem = await this.getDetailItem(item);
            this.verplaatsAmount = refreshedItem.aantal;
            this.newAmount = refreshedItem.aantal;
        },
        async verwijderItem(item) {
            this.getDetailItem(item).then((value) => {
                const refreshedItem = value;
                if (refreshedItem.status == window.constants.STOCKUNUSED) {
                    this.$root
                        .showConfirmationBox(
                            this.$t('com_delete', {
                                ns: 'common',
                                val: this.$t('prod_stockitem', {
                                    ns: 'production'
                                })
                            }) +
                                item.id +
                                ' : ' +
                                item.batchID
                        )
                        .then((res) => {
                            if (res == true) {
                                //const selectionData = ;
                                loadItem(
                                    'stock/deleteStockItem',
                                    {
                                        ID: refreshedItem.id,
                                        LastUpdated: refreshedItem.gewijzigd
                                    },
                                    this.$t
                                );
                            }
                        });
                } else {
                    this.$root.showMessageBox(
                        this.$t('prod_nodelete', {
                            ns: 'production'
                        })
                    );
                }
            });
        },
        async saveLocation(item) {
            const refreshedItem = await this.getDetailItem(item);
            const selectionData = {
                StockItemID: refreshedItem.id,
                ToStockLocationID: this.newlocationID,
                Amount: this.verplaatsAmount
            };
            loadItem('stock/moveStockItem', selectionData, this.$t);
        },
        async saveAmount(item) {
            const refreshedItem = await this.getDetailItem(item);
            if (refreshedItem.aantal == this.newAmount) {
                this.$root.showMessageBox(
                    this.$t('log_aantalongewijzigd', {
                        ns: 'logistics'
                    })
                );
                return;
            }
            const amount = refreshedItem.aantal - this.newAmount;
            const direction = amount < 0 ? 1 : 0; // StockItemDirection.Out : StockItemDirection.In
            const selectionData = {
                StockItemID: refreshedItem.id,
                MutationAmount: Math.abs(amount),
                Direction: direction
            };
            loadItem('stock/correctStockAmount', selectionData, this.$t);
        },
        async saveVerkoop(item) {
            const refreshedItem = await this.getDetailItem(item);
            const selectionData = {
                StockItemID: refreshedItem.id,
                MutationAmount: Math.abs(this.verkoopAmount)
            };
            loadItem('stock/solledStockAmount', selectionData, this.$t); // todo
        },
        async saveStockClose(item) {
            const doClose = await this.$root.showConfirmationBox(
                this.$t('log_stockclose', {
                    ns: 'logistics'
                }) +
                    item.id +
                    ' : ' +
                    item.batchID
            );
            if (doClose) {
                const refreshedItem = await this.getDetailItem(item);
                const selectionData = {
                    ID: refreshedItem.id,
                    LastUpdated: refreshedItem.gewijzigd,
                    IsEmpty: false,
                    NeverUseAgain: true
                };
                loadItem('stock/closeStockItem', selectionData, this.$t);
            }
        },
        async saveStockZero(item) {
            const doClose = await this.$root.showConfirmationBox(
                this.$t('log_stocktozero', {
                    ns: 'logistics'
                }) +
                    item.id +
                    ' : ' +
                    item.batchID
            );
            if (doClose) {
                const refreshedItem = await this.getDetailItem(item);
                const selectionData = {
                    ID: refreshedItem.id,
                    LastUpdated: refreshedItem.gewijzigd,
                    IsEmpty: true,
                    NeverUseAgain: false
                };
                loadItem('stock/closeStockItem', selectionData, this.$t);
            }
        }
    },
    mounted() {}
};
</script>

<style scoped></style>

