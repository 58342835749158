var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticStyle:{"margin-top":"60px"},attrs:{"fluid":""}},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('com_reportfor', { ns: 'common', val: _vm.$t('com_trackentrace', { ns: 'common' }) }))+" ")]),_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-radio-group',{attrs:{"options":_vm.optionsRadio,"value-field":"item","text-field":"name"},on:{"input":function($event){return _vm.clearOutput()}},model:{value:(_vm.selectedRadio),callback:function ($$v) {_vm.selectedRadio=$$v},expression:"selectedRadio"}})],1)],1),(_vm.selectedRadio == 'periode')?_c('b-row',[_c('b-col',{attrs:{"sm":"9"}},[_c('period-selector',{attrs:{"periodDays":_vm.periodeDagen},on:{"change":_vm.setNewDates},model:{value:(_vm.selectDays),callback:function ($$v) {_vm.selectDays=$$v},expression:"selectDays"}})],1),_c('b-col',{attrs:{"sm":"1"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"bgc_aux_normal",attrs:{"title":_vm.$t('com_showdata', { ns: 'common' })},on:{"click":function($event){return _vm.loadData()}}},[_c('font-awesome-icon',{attrs:{"icon":"arrow-down","size":"1x"}})],1)],1)],1)],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"11"}},[_c('div',{staticClass:"form-inline"},[_c('print-to-pdf',{attrs:{"titel":_vm.reportTitle,"filenaam":_vm.reportFileName,"reportList":_vm.reportList}}),_c('vue-json-to-csv',{attrs:{"json-data":_vm.reportList,"csv-title":_vm.reportFileName,"separator":';'}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"bgc_aux_normal",attrs:{"title":_vm.$t('com_downloadas', {
                                        ns: 'common',
                                        val: 'CSV'
                                    })}},[_c('font-awesome-icon',{attrs:{"icon":"file-csv","size":"1x"}})],1)],1)],1)]),_c('b-col',{attrs:{"sm":"1"}})],1)],1)],1):_vm._e(),(_vm.selectedRadio == 'merknummer')?_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('div',{staticClass:"form-inline"},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('com_search', { ns: 'common' }))+"  "+_vm._s(_vm.$t('com_mark', { ns: 'common' }))+": ")]),(!_vm.isVersion10)?_c('b-form-input',{attrs:{"placeholder":_vm.$t('com_letters', { ns: 'common' })},model:{value:(_vm.selectLetters),callback:function ($$v) {_vm.selectLetters=$$v},expression:"selectLetters"}}):_vm._e(),(!_vm.isVersion10)?_c('b-form-input',{attrs:{"placeholder":_vm.$t('com_nummer', { ns: 'common' }),"type":"number","step":"1"},model:{value:(_vm.selectNummers),callback:function ($$v) {_vm.selectNummers=_vm._n($$v)},expression:"selectNummers"}}):_vm._e(),(_vm.isVersion10)?_c('b-form-input',{attrs:{"placeholder":_vm.$t('tien_serial', { ns: 'tien' })},model:{value:(_vm.selectSerial),callback:function ($$v) {_vm.selectSerial=$$v},expression:"selectSerial"}}):_vm._e(),_c('b-button',{staticClass:"bgc_aux_normal",on:{"click":function($event){return _vm.loadData()}}},[_c('font-awesome-icon',{attrs:{"icon":"arrow-down","size":"1x"}}),_vm._v(" "+_vm._s(_vm.$t('com_nummer', { ns: 'common' }))+" ")],1)],1)])],1):_vm._e(),(_vm.selectedRadio == 'lot' || _vm.selectedRadio == 'bakgroep')?_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('div',{staticClass:"form-inline"},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('com_search', { ns: 'common' }))+"  "+_vm._s(_vm.$t('com_batchnumber', { ns: 'common' }))+":: ")]),_c('b-form-input',{attrs:{"placeholder":_vm.$t('com_batchnumber', {
                            ns: 'common'
                        })},model:{value:(_vm.lotNumber),callback:function ($$v) {_vm.lotNumber=$$v},expression:"lotNumber"}}),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"bgc_aux_normal",attrs:{"title":_vm.$t('com_showdata', { ns: 'common' })},on:{"click":function($event){return _vm.loadData()}}},[_c('font-awesome-icon',{attrs:{"icon":"arrow-down","size":"1x"}})],1)],1)])],1):_vm._e(),_c('div',{attrs:{"slot":"pdfdata"},slot:"pdfdata"},[_c('b-row',{staticClass:"my-1"},[_c('b-col',{attrs:{"sm":"12"}},[(_vm.selectedRadio == 'periode')?_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{staticClass:"my-1",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":_vm.$t('com_filter', { ns: 'common' }),"label-for":"filter-input","label-cols-sm":"3","label-align-sm":"right","label-size":"sm"}},[_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-form-input',{attrs:{"id":"filter-input","type":"search","placeholder":_vm.$t('com_typetosearch', {
                                            ns: 'common'
                                        })},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.filter},on:{"click":function($event){_vm.filter = ''}}},[_vm._v(_vm._s(_vm.$t('com_wis', { ns: 'common' })))])],1)],1)],1)],1),_c('b-col',{staticClass:"my-1",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":_vm.$t('com_perpagina', { ns: 'common' }),"label-for":"per-page-select","label-cols-sm":"6","label-cols-md":"4","label-cols-lg":"3","label-align-sm":"right","label-size":"sm"}},[_c('b-form-select',{attrs:{"id":"per-page-select","options":_vm.pageOptions,"size":"sm"},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"align":"fill","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1):_vm._e(),_c('b-table',{attrs:{"striped":"","primary-key":"","items":_vm.reportList,"fields":_vm.tableFields,"current-page":_vm.currentPage,"per-page":_vm.perPage,"filter":_vm.filter,"filter-included-fields":_vm.filterOn,"stacked":"md","show-empty":"","empty-text":_vm.$t('com_geen_data', { ns: 'common' }),"small":""},on:{"filtered":_vm.onFiltered}}),(_vm.taskCount === 0)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('com_geen_data', { ns: 'common' }))+" ")]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }