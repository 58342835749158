<template>
    <!-- :style="{'background-color':categorieColor}" -->
    <b-card :header="ingredientCatString">
        <div :id="'cat-' + ingredientCatString">
            <div
                class="form-inline"
                v-for="(recingredient, index) in rilist"
                v-bind:key="index"
            >
                <ingredient-combo
                    v-bind:baseUnit="ingredientEenheid"
                    v-model="rilist[index]"
                    v-bind:editAllowed="editAllowed"
                />
                <b-button
                    v-if="editAllowed"
                    @click="verwijderItem(recingredient.id)"
                    class="bgc_aux_alarm"
                    ><font-awesome-icon icon="trash-alt" size="1x" />
                </b-button>
            </div>
        </div>
    </b-card>
</template>

<script>
import { IngredientCombo } from '../components';
import { loadIngredientData } from '../product/loaddata';
export default {
    name: 'RecIngredientCard',
    data() {
        return {};
    },
    props: {
        ingredientCat: {
            required: true,
            default: -1
        },
        rilist: {
            type: Array,
            required: true
        },
        editAllowed: {
            type: Boolean,
            default: false
        }
    },
    components: {
        IngredientCombo
    },
    computed: {
        ingredientCatString() {
            return this.ingredientcategory ? this.ingredientcategory.naam :  '';
        },
        ingredientEenheid() {
            return this.ingredientcategory ? this.ingredientcategory.eenheid :  null;
        },
        ingredientcategory() {
            return this.$store.getters['product/getIngredientCategorieByID'](
                this.ingredientCat
            );
        },
        taskCount() {
            return this.rilist.length;
        },
        categorieColor() {
            return this.ingredientcategory ? this.ingredientcategory.categorieColor() :  '';
        }
    },
    methods: {
        setIngrCat() {
            if (this.$store.getters['product/getIngredientsInfo'].length == 0)
                loadIngredientData(this.ingredientCat, this.$t);
        },
        verwijderItem(recingredientID) {
            this.$emit('deleteRI', recingredientID);
        }
    },
    created() {
        this.setIngrCat();
    }
};
</script>

<style scoped>
.ingredient-type-kleur {
    background-color: darkblue;
}
</style>
