const mutations = {
    insertUnitsComboLijst(state, lijst) {
        state.unitsComboList = [];
        if (lijst != undefined) {
            state.unitsComboList=lijst;
        }
    },
    insertShapesComboLijst(state, lijst) {
        state.shapesComboList = [];
        if (lijst != undefined) {
            state.shapesComboList=lijst;
        }
    },
    insertMarkTypesComboLijst(state, lijst) {
        state.marktypesComboList = [];
        if (lijst != undefined) {
            state.marktypesComboList=lijst;
        }
    },
    insertMilkTypeIGComboLijst(state, lijst) {
        state.milktypeIGComboList = [];
        if (lijst != undefined) {
            state.milktypeIGComboList=lijst;
        }
    },
    insertFatClassComboLijst(state, lijst) {
        state.fatclassComboList = [];
        if (lijst != undefined) {
            state.fatclassComboList=lijst;
        }
    },
    insertCheesesComboLijst(state, lijst) {
        state.cheesesComboList = [];
        if (lijst != undefined) {
            state.cheesesComboList=lijst;
        }
    },
    insertCheeseWithoutRecipeComboLijst(state, lijst) {
        state.cheesesWithoutRecipeComboList = [];
        if (lijst != undefined) {
            state.cheesesWithoutRecipeComboList=lijst;
        }
    },
    insertCheeseTypeAlternatives(state, lijst) {
        state.cheeseAlternativesComboList = [];
        if (lijst != undefined) {
            state.cheeseAlternativesComboList=lijst;
        }
    },
    insertFailureCheeseComboLijst(state, lijst) {
        state.FailureCheeseComboList = [];
        if (lijst != undefined) {
            state.FailureCheeseComboList=lijst;
        }
    },
    insertIGComboLijst(state, lijst) {
        state.IGComboList = [];
        if (lijst != undefined) {
            state.IGComboList=lijst;
        }
    },
    insertIngredientComboLijst(state, lijst) {
        state.IngredientComboList = [];
        if (lijst != undefined) {
            state.IngredientComboList=lijst;
        }
    },
    insertCompaniesComboLijst(state, lijst) {
        state.companiesComboList = [];
        if (lijst != undefined) {
            state.companiesComboList=lijst;
        }
    },
    insertProductsComboLijst(state, lijst) {
        state.productsComboList = [];
        if (lijst != undefined) {
            state.productsComboList=lijst;
        }
    },
    insertLocationsComboLijst(state, lijst) {
        state.locationsComboList = [];
        if (lijst != undefined) {
            state.locationsComboList=lijst;
        }
    },
    insertPIComboLijst(state, lijst) {
        state.brineIngrComboList = [];
        if (lijst != undefined) {
            state.brineIngrComboList=lijst;
        }
    },
    insertSimpleProductsLijst(state, lijst) {
        state.simpleProdComboList = [];
        if (lijst != undefined) {
            state.simpleProdComboList=lijst;
        }
    },
    
    
};

export default mutations;
