<template>
    <b-container fluid style="margin-top: 60px">
        <h1 v-on:click="loadData">
            {{ strProductionTitle }}
        </h1>
        <b-row>
            <b-col sm="8">
                <period-selector
                    v-model="selectDays"
                    v-bind:periodDays="periodeDagen"
                    v-bind:showBakgroepOption="true"
                    @change="setNewDates"
                />
            </b-col>
            <b-col sm="2">
                <b-row v-if="!isDagInfo">
                    <b-col sm="11">
                        <b-button @click="loadData()" class="bgc_aux_normal"
                            ><font-awesome-icon
                                icon="arrow-down"
                                size="1x"
                            />&nbsp;Toon
                        </b-button>
                    </b-col>
                    <b-col sm="1"> </b-col>
                </b-row>
                <b-row v-else>
                    <b-col sm="11">
                        <b-button @click="loadData()" class="bgc_aux_normal"
                            ><font-awesome-icon
                                icon="sync"
                                size="1x"
                            />&nbsp;Ververs
                        </b-button>
                    </b-col>
                    <b-col sm="1"> </b-col>
                </b-row>
            </b-col>
            <b-col sm="2"> </b-col>
        </b-row>

        <b-row>
            <b-col sm="12">
                <div v-if="showFilter">
                    <b-row no-gutters>
                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_filter', { ns: 'common' })"
                                label-for="filter-input"
                                label-cols-sm="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-input-group size="sm">
                                    <b-form-input
                                        id="filter-input"
                                        v-model="filter"
                                        type="search"
                                        :placeholder="
                                            $t('com_typetosearch', {
                                                ns: 'common'
                                            })
                                        "
                                    ></b-form-input>

                                    <b-input-group-append>
                                        <b-button
                                            :disabled="!filter"
                                            @click="filter = ''"
                                            >{{ $t('com_wis', { ns: 'common' }) }}</b-button
                                        >
                                    </b-input-group-append>
                                </b-input-group>

                                <b-button
                                    v-if="isDagInfo"
                                    size="sm"
                                    @click="createNewItem"
                                    class="mr-1"
                                    ><font-awesome-icon
                                        icon="folder-plus"
                                        size="1x"
                                    />
                                    {{ strNewSimpleProduction }}
                                </b-button>
                            </b-form-group>
                        </b-col>

                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_perpagina', { ns: 'common' })"
                                label-for="per-page-select"
                                label-cols-sm="6"
                                label-cols-md="4"
                                label-cols-lg="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-form-select
                                    id="per-page-select"
                                    v-model="perPage"
                                    :options="pageOptions"
                                    size="sm"
                                ></b-form-select>
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                ></b-pagination>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div v-else>
                    <b-button
                        v-if="isDagInfo"
                        size="sm"
                        @click="createNewItem"
                        class="mr-1"
                        ><font-awesome-icon icon="folder-plus" size="1x" />
                        {{ strNewSimpleProduction }}
                    </b-button>
                </div>

                <b-table
                    :items="displayList"
                    :fields="visibleFields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    stacked="md"
                    show-empty
                    :empty-text="$t('com_geen_data', { ns: 'common' })"
                    small
                    striped
                    @filtered="onFiltered"
                    @row-clicked="toonItem"
                >
                    <template v-slot:cell(actions)="row">
                        <b-button
                            size="sm"
                            v-if="useVersion10"
                            @click="editItem(row.item)"
                            class="bgc_aux_warning"
                            :disabled="!allowEdit(row.item)"
                            ><font-awesome-icon icon="edit" size="1x" />
                        </b-button>
                        <b-button
                            v-if="useVersion10"
                            size="sm"
                            @click="verwijderItem(row.item)"
                            class="bgc_aux_alarm"
                            :disabled="!allowEdit(row.item)"
                            ><font-awesome-icon icon="trash-alt" size="1x" />
                        </b-button>
                    </template>

                    <template v-slot:row-details> </template>
                </b-table>
            </b-col>
        </b-row>

        <plus-production-modal
            v-if="detailsVisible"
            :inItem="currentItem"
            :discrimList="discrimList"
            :readonly="readonly"
            v-model="detailsVisible"
            @hide="reset"
        />
    </b-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { VueExtension } from '../../models/VueExtension';
import { loadItem, loadList, loadGetItem } from '../../models/DataHelper';
import { Feature } from '../../models/Features';
import { Permission } from '../../models/Permissions';
import PeriodSelector from '../components/PeriodSelector.vue';
import { SimpleProduction } from '../../models/PlusProduction';
import PlusProductionModal from './PlusProductionModal.vue';
import Helper from '@/models/Helper';
import DateHelper from '@/models/DateHelper';

export default defineComponent({
    name: 'PlusProductionBase',
    data: function () {
        return {
            selectDays: null,
            periodeDagen: 1,
            editDagen: -1,
            van: new Date(),
            tot: null,
            forceRefresh: false,
            readonly: false,
            productionLijst: [] as Array<SimpleProduction>,
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [10, 20, 40, { value: 100, text: '100' }],
            filter: null,
            filterOn: [],
            discrimList: [] as Array<SimpleProduction>,
            currentItem: ({} as SimpleProduction) , //|| null,
            detailsVisible: false,
            displayFilterCount: 20,
            strProductionTitle: this.$t('tien_title', {
                ns: 'tien',
                val: this.$t('com_production', { ns: 'common' })
            }),
            strNewSimpleProduction: this.$t('com_newx', {
                ns: 'common',
                val: this.$t('com_production', { ns: 'common' })
            }),
            strDeleteProduction: this.$t('com_delete', {
                ns: 'common',
                val: this.$t('com_production', { ns: 'common' })
            })
        };
    },
    props: {},
    components: {
        PeriodSelector,
        PlusProductionModal
    },
    computed: {
        displayList() {
            return this.productionLijst;
        },
        useVersion10() {
            return this.checkFeature('conf_CH10Enabled');
        },
        isDagInfo() {
            return this.van == this.tot;
        },
        showFilter() {
            return this.displayList
                ? this.displayList.length > this.displayFilterCount
                : false;
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                {
                    key: 'naam',
                    label: this.$t('com_naam', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'productiedatum',
                    label: this.$t('com_production', { ns: 'common' }),
                    sortable: true,
                    visible: true,
                    formatter: (value, key, item) => {
                        const temp = DateHelper.convertedToDate(
                            item.productiedatum
                        );
                        return temp;
                    }
                },
                {
                    key: 'batch',
                    label: 'Partij',
                    sortable: true,
                    visible: true
                },
                {
                    key: 'opmerking',
                    label: this.$t('com_opmerking', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'elementcount',
                    label: this.$t('com_onderdelen', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'attcount',
                    label: this.$t('com_bijlagen', { ns: 'common' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        }
    },
    methods: {
        allowEdit(item) {
            const o = DateHelper.determineVanTot(new Date(), this.editDagen);
            const datum = item ? item.productiedatum : this.van;
            const magNog = o.van < datum;
            return magNog;
        },
        async createNewItem() {
            this.currentItem = new SimpleProduction({});
            const prodvaluelijst = await loadList(
                'tienplus/getProductValueLijst',
                'tienplus/loadProductionValues',
                null,
                true,
                this.$t
            );
            this.currentItem.prodwaarden = prodvaluelijst;
            this.currentItem.productiedatum = this.van;
            this.detailsVisible = true;
        },
        async giveDiscrimList() {
            const lijstAll = await loadList(
                'components/getSimpleProdComboLijst',
                'components/loadSimpleProducts',
                null,
                false,
                this.$t
            );
            const copyList = lijstAll.slice();
            const lijstMetRecept = await loadList(
                'tienplus/getSimpleRecipeLijst',
                'tienplus/loadSimpleRecipes',
                null,
                true,
                this.$t
            );
            const lists = Helper.reduceListWithList(copyList, lijstMetRecept);
            //console.log(JSON.stringify(lists));
            return lists ? lists.list1 : [];
        },
        async toonItem(row) {
            // haal item op met details
            const o = DateHelper.determineVanTot(new Date(), this.editDagen);
            const magNog = o.van < row.productiedatum;
            this.readonly = !magNog;
            this.discrimList = []; // alles moet er in zitten
            this.currentItem = await loadItem(
                'tienplus/loadSimpleProduction',
                { productionID: row.id },
                this.$t
            );
            this.detailsVisible = true;
        },
        async editItem(item) {
            // haal item op met details
            this.discrimList = []; // alles moet er in zitten
            this.currentItem = await loadItem(
                'tienplus/loadSimpleProduction',
                { productionID: item.id },
                this.$t
            );
            this.detailsVisible = true;
        },
        async verwijderItem(item) {
            const doClose = await (
                this.$root as VueExtension
            ).showConfirmationBox(this.strDeleteProduction + item.naam);
            if (doClose) {
                const selData = {
                    data: { productionID: item.id },
                    func: this.$t
                };
                const refreshed = await loadItem(
                    'tienplus/loadSimpleProduction',
                    { productionID: item.id },
                    this.$t
                );
                const selectionData = {
                    id: refreshed.id,
                    lastupdated: refreshed.gewijzigd
                };
                await loadItem(
                    'tienplus/deleteSimpleProduction',
                    selectionData,
                    this.$t
                );
            }
        },
        reset(item: SimpleProduction) {
            if (item) {
                if (item.isChanged) this.loadData();
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        setNewDates(selDates) {
            this.van = selDates.van;
            this.tot = selDates.tot;
            if (this.isDagInfo) this.loadData();
        },
        async loadData() {
            if (this.isDagInfo) {
                const selectionData = {
                    productiondate: DateHelper.localDateToUTC(this.van)
                };
                this.productionLijst = await loadList(
                    'tienplus/getSimpleProductionLijst',
                    'tienplus/loadSimpleProductionInfo',
                    selectionData,
                    true,
                    this.$t
                );
            } else {
                const selectionData = {
                    from: DateHelper.localDateToUTC(this.van),
                    until: DateHelper.localDateToUTC(this.tot)
                };
                this.productionLijst = await loadList(
                    'tienplus/getSimpleProductionLijst',
                    'tienplus/loadSimpleProductionInfoOverPeriod',
                    selectionData,
                    true,
                    this.$t
                );
            }
            this.totalRows = this.productionLijst
                ? this.productionLijst.length
                : 0;
        },
        checkFeature(feature) {
            const key = Feature.enum(feature);
            return (this.$root as VueExtension).handleFeature(key);
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return (this.$root as VueExtension).handleRight(id);
        }
    },
    async mounted() {
        const a = await this.giveDiscrimList();
        this.discrimList = a;
        await this.loadData();
    },
    async created() {        
        const lijst = await loadList(
            'masterdata/getConstantsLijst',
            'masterdata/loadApplicationConstants',
            null,
            this.forceRefresh,
            this.$t
        );
        const con = lijst
            ? lijst.find((x) => x.text == window.constants.APPCONST_EDITDAYS)
            : null;
        this.editDagen = con ? con.value : 30;
    }
});
</script>

<style scoped></style>

