var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"bs-example",attrs:{"fluid":""}},[_c('b-card',{staticClass:"mb-1",attrs:{"no-body":""}},[_vm._v("   "),_c('b-table',{attrs:{"striped":"","responsive":"","fields":_vm.visibleFields,"items":_vm.sortededList,"primary-key":""},scopedSlots:_vm._u([(!_vm.gesloten)?{key:"cell(actions)",fn:function(row){return [_c('b-button',{staticClass:"bgc_aux_warning",attrs:{"size":"sm"},on:{"click":function($event){return _vm.editItem(row.item)}}},[_c('font-awesome-icon',{attrs:{"icon":"edit","size":"1x"}})],1),_c('b-button',{staticClass:"bgc_aux_alarm",attrs:{"size":"sm"},on:{"click":function($event){return _vm.verwijderItem(row.item)}}},[_c('font-awesome-icon',{attrs:{"icon":"trash-alt","size":"1x"}})],1),_c('b-dropdown',{ref:"dropdown",attrs:{"id":"dropdown-form","text":"...","size":"sm","boundary":"viewport"},on:{"show":function($event){return _vm.setStartValues(row.item)}}},[_c('div',{staticClass:"fluid-container"},[_c('b-dropdown-form',[_c('div',[_c('b-form-group',{attrs:{"label":_vm.$t('log_verplaats', {
                                            ns: 'logistics'
                                        }),"label-for":"dropdown-form-move"},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('div',{staticClass:"form-inline"},[_c('b-form-input',{attrs:{"id":"dropdown-form-correct","placeholder":_vm.$t('log_correctieaantal', {
                                                    ns: 'logistics'
                                                })},model:{value:(_vm.verplaatsAmount),callback:function ($$v) {_vm.verplaatsAmount=_vm._n($$v)},expression:"verplaatsAmount"}}),_c('lookup-combo',{attrs:{"id":"dropdown-form-move","combotype":_vm.lctypeLocation},model:{value:(_vm.newlocationID),callback:function ($$v) {_vm.newlocationID=$$v},expression:"newlocationID"}})],1),_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.saveLocation(row.item)}}},[_vm._v("Ok")])],1)],1),_c('b-dropdown-divider')],1),_c('b-dropdown-form',[_c('div',[_c('b-form-group',{attrs:{"label":_vm.$t('log_corrigeer', {
                                            ns: 'logistics'
                                        }),"label-for":"dropdown-form-correct"},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('b-form-input',{attrs:{"id":"dropdown-form-correct","placeholder":_vm.$t('log_correctieaantal', {
                                                ns: 'logistics'
                                            })},model:{value:(_vm.newAmount),callback:function ($$v) {_vm.newAmount=_vm._n($$v)},expression:"newAmount"}}),_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.saveAmount(row.item)}}},[_vm._v("Ok")])],1)],1),_c('b-dropdown-divider'),_c('b-dropdown-item',{on:{"click":function($event){return _vm.saveStockZero(row.item)}}},[_vm._v(_vm._s(_vm.$t('log_closenostock', { ns: 'logistics' })))]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.saveStockClose(row.item)}}},[_vm._v(_vm._s(_vm.$t('log_closenouse', { ns: 'logistics' })))])],1)],1)])]}}:null],null,true)})],1),(_vm.detailsVisible)?_c('item-stock-modal',{attrs:{"inItem":_vm.currentItem,"editAllowed":true},on:{"hide":_vm.reset},model:{value:(_vm.detailsVisible),callback:function ($$v) {_vm.detailsVisible=$$v},expression:"detailsVisible"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }