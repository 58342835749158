import Api from '../../../api';
import store from '../../../store';

const changePassword = async (data) => {
    return await Api(store).post('account/ChangePassword', data);
};

const resetPassword = async (data) => {
    return postQueryString('account/ResetPassword', data);
};

const confirmPassword = async (data) => {
    return postQueryString('account/ConfirmPassword', data);
};

const deleteUser = async (data) => {
    //return await Api(store).delete('account/unregister', data);
    return deleteQueryString('account/unregister', data);
};

const createUser = async (data) => {
    return await Api(store).put('account/register', data);
};

const updateUser = async (data) => {
    return await Api(store).put('gebruiker/updateGebruiker', data);
};

const createUserGroup = async (data) => {
    return postQueryString('Authorize/CreateUserGroup', data);
};

const deleteUserGroup = async (data) => {
    return deleteQueryString('Authorize/DeleteUserGroup', data);
};

const deleteUserFromGroup = async (data) => {
    return deleteQueryString('Authorize/RemoveUserFromGroup', data);
};

const saveUserGroupPermissions  = async (data) => {
    return await Api(store).put('Authorize/SetUserGroupPermissions', data);
};
const addUserToGroup = async (data) => {
    return postQueryString('Authorize/AddUserToGroup', data);
};


const deleteQueryString = async (apicall, data) => {
    return await Api(store).delete(
        apicall +
            '?' +
            Object.keys(data)
                .map(
                    (k) =>
                        encodeURIComponent(k) +
                        '=' +
                        encodeURIComponent(data[k] ?? '')
                )
                .join('&'),
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
    );
};

const postQueryString = async (apicall, data) => {
    return await Api(store).post(
        apicall +
            '?' +
            Object.keys(data)
                .map(
                    (k) =>
                        encodeURIComponent(k) +
                        '=' +
                        encodeURIComponent(data[k] ?? '')
                )
                .join('&'),
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
    );
};

// eslint-disable-next-line no-unused-vars
const fetchInfo = async (apicall, data) => {
    if (data != undefined) {
        return await Api(store).get(
            apicall +
                '?' +
                Object.keys(data)
                    .map(
                        (k) =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    } else {
        return await Api(store).get(apicall, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    }
};

export default {
    changePassword,
    confirmPassword,
    resetPassword,
    deleteUser,
    updateUser,
    createUser,
    createUserGroup,
    deleteUserGroup,
    deleteUserFromGroup,
    saveUserGroupPermissions,
    addUserToGroup
};
