const mutations = {

    insertSelectedItem(state, item ) {
        state.selectedVoorraadItem = item;
    },
    insertSelectedItemInfo(state, item ) {
        state.selectedVoorraadItemInfo = item;
    },
    insertVoorraadItemLijst(state, lijst) {
        state.voorraadItemLijst = [];
        if (lijst != undefined) {
            state.voorraadItemLijst = lijst;
        }
    },
    insertVoorraadInfoLijst(state, lijst) {
        state.voorraadInfoList = [];
        if (lijst != undefined) {
            state.voorraadInfoList = lijst;
        }
    },
    insertVoorraadInfoLijstArchived(state, lijst) {
        state.voorraadInfoListArchived = [];
        if (lijst != undefined) {
            state.voorraadInfoListArchived = lijst;
        }
    },
    insertMarkVoorraadItemLijst(state, lijst) {
        state.voorraadMarkItemLijst = [];
        if (lijst != undefined) {
            state.voorraadMarkItemLijst = lijst;
        }
    },
    insertMarkVoorraadInfoLijst(state, lijst) {
        state.voorraadMarkInfoList = [];
        if (lijst != undefined) {
            state.voorraadMarkInfoList = lijst;
        }
    },
    insertMarkVoorraadInfoLijstArchived(state, lijst) {
        state.voorraadMarkInfoListArchived = [];
        if (lijst != undefined) {
            state.voorraadMarkInfoListArchived = lijst;
        }
    },
    insertStockLocationTypesLijst(state, lijst) {
        state.stockLocationTypesLijst = [];
        if (lijst != undefined) {
            state.stockLocationTypesLijst = lijst;
        }
    },
    insertStockItemMarksInfoLijst(state, lijst) {
        state.stockItemMarksInfoLijst = [];
        if (lijst != undefined) {
            state.stockItemMarksInfoLijst = lijst;
        }
    },
};

export default mutations;
