<template>
    <b-modal
        id="modal-assigntreatment"
        size="xl"
        ref="modal"
        :title="this.formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
    >
        <b-card @submit.stop.prevent="handleSubmit">
            <b-input-group :prepend="$t('prod_stockitem', { ns: 'production' })" :label="$t('prod_stockitem', { ns: 'production' })">
                <b-form-input
                    v-model="voorraadItem.batchID"
                    readonly
                ></b-form-input>
                <b-form-input
                    v-model="voorraadItem.naam"
                    readonly
                ></b-form-input>
                <b-form-input
                    v-model="voorraadItem.aantal"
                    readonly
                ></b-form-input>
            </b-input-group>
            <label for="input-behandelrecept"
                >{{ $t('prod_treatmentschema', { ns: 'production' }) }}:
            </label>
            <lookup-combo
                id="input-behandelrecept"
                :placeholder="$t('prod_treatmentschema', { ns: 'production' })"
                v-model="selectedSchemaID"
                v-bind:forcerefresh="forcerefresh"
                v-bind:combotype="lctypeTreatmentRecipe"
                @input="showSchema()"
            ></lookup-combo>
            <b-input-group :prepend="$t('prod_step', { ns: 'production' })" :label="$t('prod_step', { ns: 'production' })">
                <b-form-input v-model="selectedStepNumber"></b-form-input>
                <b-form-select v-model="wachtDag" :options="wachtdagList">
                    <template #first>
                        <b-form-select-option value=""
                            >--
                            {{ $t('com_selecteer', { ns: 'common' }) }}
                            --</b-form-select-option
                        >
                    </template>
                </b-form-select>
            </b-input-group>
            <b-tabs card>
                <b-tab :title="receptTitle" v-bind:key="1" active lazy>
                    <b-card-text>
                        <b-table
                            :items="displayList"
                            :fields="visibleFields"
                            stacked="md"
                            show-empty
                            :empty-text="$t('com_geen_data', { ns: 'common' })"
                            small
                            striped
                            :tbody-tr-class="rowClass"
                            @row-clicked="selectStep"
                        >
                        </b-table>
                    </b-card-text>
                </b-tab>
            </b-tabs>
        </b-card>
    </b-modal>
</template>

<script lang="ts">
import DateHelper from '../../models/DateHelper';
import Helper from '../../models/Helper';
import { defineComponent } from 'vue';
import { VueExtension } from '../../models/VueExtension';
import { loadList, loadItem } from '../../models/DataHelper';
import { VoorraadItem } from '@/models/Voorraad';
import {
    TreatmentActivity,
    TreatmentRecipe,
    TreatmentRecipeStep,
    TreatmentWeekPlan
} from '@/models/CheeseTreatment';
import { LookupCombo } from '../components';

export default defineComponent({
    name: 'AssignTreatmentModal',
    data: function () {
        return {
            lctypeTreatmentRecipe: window.constants.LCTRR,
            showSpinner: false,
            stickyHeader: '70vh',
            recipeList: [] as Array<TreatmentWeekPlan>,
            treatedList: [] as Array<TreatmentWeekPlan>,
            treatmentList: [] as Array<TreatmentActivity>,
            refreshedRecipe: {} as TreatmentRecipe,
            selectedSchemaID: -1,
            selectedStepNumber: -1,
            forcerefresh: true,
            wachtdagList: [
                { value: 0, text: this.$t('com_vandaag', { ns: 'common' }) },
                { value: 1, text: this.$t('com_morgen', { ns: 'common' }) },
                { value: 2, text: this.$t('com_overmorgen', { ns: 'common' }) }
            ],
            wachtDag: 0,
            editAllowed: true,
            uitvoerTijdstip: DateHelper.convertedToTime(new Date()),
            partijID: -1,
            firstTimeWait: 24,
            forceRefresh: false,
            strCaption: this.$t('prod_toewijzen', {
                ns: 'production',
                val: this.$t('prod_treatmentschema', {
                    ns: 'production'
                }).toLowerCase()
            }),
            strReceptCaption:
                this.$t('prod_treatmentschema', {
                    ns: 'production'
                }) + ' {0}'
        };
    },
    model: {
        prop: 'visible',
        event: 'change'
    },
    props: {
        voorraadItem: {
            type: VoorraadItem,
            required: true
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    components: { LookupCombo },
    watch: {},
    computed: {
        formtitle() {
            return this.strCaption;
        },
        receptTitle() {
            const title = Helper.stringformat(
                this.strReceptCaption,
                this.refreshedRecipe.naam
            );
            return title;
        },
        displayList() {
            return this.treatmentList;
        },
        visibleFields() {
            const fieldsInit = [
                {
                    key: 'volgnummer',
                    label: this.$t('prod_step', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'behandelstap',
                    label: this.$t('prod_treatment', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'wachttijd',
                    label: this.$t('prod_wachttijd', { ns: 'production' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        }
    },
    methods: {
        hide() {
            this.$emit('hide');
        },
        showDate(aDate) {
            return DateHelper.convertedToDateShort(aDate);
        },
        displayTekst(value) {
            if (typeof value === 'object' && value !== null) {
                return value.Display();
            }
        },
        giveBGclass(value) {
            if (value !== null) {
                if (value.recept) {
                    if (value.behandelstapID == 2) return 'bg-coat';
                    if (value.behandelstapID == 1) return 'bg-keer';
                }
            }
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            this.assignSchema();
            // Trigger submit handler
            this.handleSubmit();
        },
        async handleSubmit() {
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-assigntreatment');
            });
        },
        assignSchema() {
            const uren = this.wachtDag * 24;
            const start = DateHelper.addHoursToDate(new Date(), uren);
            const selectionData = {
                startAt: start.toUTC(),
                treatmentRecipeID: this.selectedSchemaID,
                stockItemID: this.voorraadItem.id,
                startStepNumber: this.selectedStepNumber
            };
            loadItem('treatment/assignSchema', selectionData, this.$t);
        },
        calcStepNumber() {
            if (this.voorraadItem.productiedatum != undefined) {
                const dagen = DateHelper.dateDiffinDays(
                    this.voorraadItem.productiedatum,
                    new Date()
                );
                const uren = Math.abs(dagen) * 24;
                let selStep = new TreatmentRecipeStep();
                let wacht = this.firstTimeWait;
                for (let i = 0; i < this.refreshedRecipe.stappen.length; i++) {
                    const stap = this.refreshedRecipe.stappen[i];
                    if (selStep.id < 0) selStep = stap;
                    if (wacht < uren) {
                        selStep = stap;
                        wacht += stap.wachttijd;
                    }
                }
                this.selectedStepNumber = selStep ? selStep.volgnummer : 1;
            } else {
                this.selectedStepNumber = 1;
            }
        },
        selectStep(row) {
            const item = row.item ? row.item : row;
            this.selectedStepNumber = item.volgnummer;
        },
        async showSchema() {
            this.refreshedRecipe = await loadItem(
                'treatment/loadTreatmentRecipe',
                {
                    treatmentRecipeID: this.selectedSchemaID
                },
                this.$t
            );
            this.treatmentList = this.refreshedRecipe.stappen;
            this.calcStepNumber();
            this.vulBehandelMatrix();
        },
        vulBehandelMatrix() {
            this.treatedList = [];
            let runningWeek = 1;
            let weekPlan = new TreatmentWeekPlan({ week: runningWeek });
            const lijst = this.treatmentList;
            // moet tov tijdstip uit pekel, fromplanned van de eerste is uitpekel inclusief firsttimewait
            const out = lijst[0].starttijdplan;
            const outbrine = DateHelper.addHoursToDate(
                out,
                -this.firstTimeWait
            );
            for (let i = 0; i < lijst.length; i++) {
                if (lijst[i].starttijd != undefined) {
                    const totHours = DateHelper.dateDiffinHours(
                        lijst[i].starttijd,
                        outbrine
                    );
                    const days = Math.round((totHours * 10) / 24);
                    const realdays = days / 10;
                    const remainder = days % 5; //
                    // klopt de week nog

                    const numberOfWeeks = Math.floor((realdays - 1) / 7) + 1;
                    if (numberOfWeeks > runningWeek) {
                        while (runningWeek < numberOfWeeks) {
                            this.treatedList.push(weekPlan);
                            runningWeek += 1;
                            weekPlan = new TreatmentWeekPlan({
                                week: runningWeek
                            });
                        }
                    }
                    const dayNumber =
                        Math.round(realdays) * 10 -
                        (runningWeek - 1) * 70 +
                        remainder * 5;
                    const index = lijst[i].stapnummer - 1;
                    const receptStap = this.refreshedRecipe.stappen[index];
                    const ts = new TreatmentRecipeStep();
                    ts.volgnummer = lijst[i].stapnummer;
                    ts.behandelstap = receptStap.behandelstap;
                    ts.behandelstapID = receptStap.behandelstapID;
                    ts.dagnummer = dayNumber;
                    ts.dagnummerabsoluut = realdays;
                    ts.recept = false;
                    weekPlan.SetDay(dayNumber, ts);
                }
            }
            this.treatedList.push(weekPlan);
        },
        displayDate(value) {
            return DateHelper.convertedToDate(value, true);
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return;
            if (item.volgnummer === this.selectedStepNumber)
                return 'table-success';
        }
    },
    mounted() {},
    async created() {
        const lijst = await loadList(
            'masterdata/getConstantsLijst',
            'masterdata/loadApplicationConstants',
            null,
            this.forceRefresh,
            this.$t
        );
        const con = lijst
            ? lijst.find(
                  (x) => x.text == window.constants.APPCONST_HOURSFIRSTTREATMENT
              )
            : null;
        this.firstTimeWait = con ? con.value : null;
    }
});
</script>

<style scoped>
.bg-coat {
    background-color: #c1e881 !important;
}
.bg-keer {
    background-color: hwb(203 55% 7%) !important;
}
.bg-coatBehandel {
    background-color: #506135 !important;
    color: ghostwhite !important;
}
.bg-keerBehandel {
    background-color: hwb(203 28% 52%) !important;
    color: ghostwhite !important;
}
</style>

