import Operator from './Operator';

export default class UserGroup {
    /**
     * Assign the data to a new UserGroup instance
     *
     * @param data
     */
    constructor(data, func) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        this.updated = null; 
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.ug_id,
                writable: false,
                enumerable: true
            },
            gewijzigd: {
                value: data.updated,
                writable: false,
                enumerable: true
            },
            naam: {
                value: data.displayName,
                writable: true,
                enumerable: true
            },
            isAdmin: {
                value: data.isAdminGroup,
                writable: true,
                enumerable: true
            },
            isSkipper: {
                value: data.isAllowedToSkip,
                writable: true,
                enumerable: true
            },
            membercount: {
                value: data.userPersons ? data.userPersons.length : 0 ,
                writable: false,
                enumerable: true
            },
            userInfo: {
                value:  (data.userPersons ? data.userPersons.map(x => new Operator(x, func)) : []), 
                writable: true,
                enumerable: true
            }
            
        });
        this.isNew = (this.id == undefined || this.id < 0);
    }


    getMemberInfo() {
        return this.userInfo == undefined ? [] : this.userInfo ;
    }
    SetForSave() {
        this.displayName = this.naam;
        this.userPersons = this.userInfo != undefined ? this.userInfo : [] ;
    }
    updateGroup(item) {
        this.displayName =item.displayName;
        this.userPersons = item.userPersons;
        this.naam =item.naam;
        this.userInfo = item.userInfo;
        this.created = item.created;
        this.updated = item.updated;
        this.deleted = item.deleted;
    }


}
