<template>
    <div>
        <LineChartGenerator
            v-if="showline === displayNumber && loaded"
            :chart-options="chartOptions"
            :chart-data="chartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="localWidth"
            :height="localHeight"
        />
        <Doughnut
            v-if="showdoughnut === displayNumber && loaded"
            :chart-options="chartOptions"
            :chart-data="chartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="localWidth"
            :height="localHeight"
        />
        <Bar
            v-if="showbar === displayNumber && loaded"
            :chart-options="chartOptions"
            :chart-data="chartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="localWidth"
            :height="localHeight"
            
        />
    </div>
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy';
import { Doughnut } from 'vue-chartjs/legacy';
import { Bar } from 'vue-chartjs/legacy';
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    ArcElement,
    PointElement,
    BarElement
} from 'chart.js';
ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    ArcElement,
    CategoryScale,
    PointElement,
    BarElement
);
import { loadSubObject } from './graphrapportageservice';

export default {
    name: 'CardChart',
    data() {
        return {
            showline: window.constants.DB_SHOW_LINE,
            showdoughnut: window.constants.DB_SHOW_DOUGHNUT,
            showbar: window.constants.DB_SHOW_BAR,
            currentOptions: {
                responsive: true,
                maintainAspectRatio: false
            },
            cssClasses: '',
            styles: {},
            plugins: [],
            width: 400,
            height: 400,
            currentData: {},
            currentChartId: 'Lijn',
            currentDatasetIdKey: 'label',
            loaded : false
        };
    },
    components: {
        LineChartGenerator,
        Doughnut,
        Bar
    },
    props: {
        displayNumber: {
            type: Number,
            default: 1
        },
        van: {
            type: String
        },
        tot: {
            type: String
        },
        productID: {
            type: Number
        },
        dataSource: {
            type: String,
            required: true
        }
    },
    computed: {
        chartData() {
            return this.currentData ;
        },
        chartOptions() {
            return this.currentOptions ;
        },
        chartId() {
            return this.currentChartId;
        },
        datasetIdKey() {
            return this.currentDatasetIdKey;
        },
        localWidth() {
            return this.width;
        },
        localHeight() {
            return this.height;
        }
    },
    async mounted() {
        if (this.van == undefined) return;
        this.loaded = false;
        const objectChart = await loadSubObject(
            this.van,
            this.tot,
            this.productID,
            this.dataSource,
            this.$t
        );
        this.currentChartId = objectChart.chartID;
        this.currentDatasetIdKey = objectChart.datasetIdKey;
        this.currentData = objectChart.chartDataDef();
        this.currentOptions= objectChart.chartOptions;
        this.loaded = true;
    }
};
</script>
