import { api } from '../api';
import { showBadToast, showGoodToast } from '../../errors/toast';
import { Recept } from '../../../models/Recept';
import { loadItem } from '@/models/DataHelper';

const actions = {
    //#region Recept
    async loadOpenRecipeInfo(context, data) {
        let tempList = [];
        try {
            await api.fetchOpenRecipeInfo(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new Recept(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_beschikbaar', {
                        ns: 'common',
                        val: data.func('com_recipes', { ns: 'common' })
                    })
                }),
                error
            );
        }
        context.commit('insertRecipeInfoLijst', tempList);
    },
    async loadClosedRecipeInfo(context, data) {
        let tempList = [];
        try {
            await api.fetchClosedRecipeInfo(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new Recept(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_gesloten', {
                        ns: 'common',
                        val: data.func('com_recipes', { ns: 'common' })
                    })
                }),
                error
            );
        }
        context.commit('insertClosedRecipeInfoLijst', tempList);
    },
    async loadRecipeComplete(context, data) {
        let item = null;
        try {
            const response = await api.fetchRecipeComplete(data.data);
            item = new Recept(response.data);
        } catch (error) {
            item = null;
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_recipe', { ns: 'common' })
                }),
                error
            );
        }
        context.commit('insertRecipe', item);
        return item;
    },
    async loadOpenRecipeComplete(context, data) {
        let item = null;
        try {
            const response = await api.fetchOpenRecipeComplete(data.data);
            item = new Recept(response.data);
        } catch (error) {
            item = null;
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_recipe', { ns: 'common' })
                }),
                error
            );
        }
        return item;
    },
    async saveRecipe(context, data) {
        try {
            await api.storeRecipe(data.data);
            loadItem('recipe/loadOpenRecipeInfo', null, data.func);
            context.commit('insertRecipe', data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('com_recipe', { ns: 'common' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('com_recipe', { ns: 'common' }).toLowerCase()
                }),
                error
            );
        }
    },
    async deleteRecipe(context, data) {
        try {
            await api.closeRecipe(data.data);
            context.commit('deleteRecipe', data);
            showGoodToast(
                context,
                data.func('com_verwijderd', {
                    ns: 'common',
                    val: data.func('com_recipe', { ns: 'common' })
                })
            );
            showGoodToast(context, 'Recept verwijderd ');
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data.func('com_recipe', { ns: 'common' })
                }),
                error
            );
        }
    }
    //#endregion Recept

    //#region Merktype
    //#endregion Merktype

    //#region Coating
    //#endregion Coating

    //#region WheyType
    //#endregion WheyType

    //#region Ingredient
    //#endregion Ingredient
};

export default actions;

