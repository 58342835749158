const mutations = {

    insertDelveryNote(state, item ) {
        state.selectedDeliveryNote = item;
    },
    insertDeliveryNoteLijst(state, lijst) {
        state.deliveryNoteLijst = [];
        if (lijst != undefined) {
            state.deliveryNoteLijst = lijst;
        }
    },
    insertDeliveryNotePrintedLijst(state, lijst) {
        state.deliveryNoteLijstPrinted = [];
        if (lijst != undefined) {
            state.deliveryNoteLijstPrinted = lijst;
        }
    },
    insertPrintOptionsLijst(state, lijst) {
        state.printOptionsLijst = [];
        if (lijst != undefined) {
            state.printOptionsLijst = lijst;
        }
    },
    insertOwnCompany(state, item ) {
        state.ownCompany = item;
    },
};

export default mutations;
