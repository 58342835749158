import DateHelper from './DateHelper';
export { ActiviteitenLog };
class ActiviteitenLog {
    constructor(data) {
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.key,
                writable: false
            },
            gemaakt: {
                value: 
                DateHelper.convertedToDateTimeShort(data.created),
                writable: false,
                enumerable: true
            },
            actie: {
                type: String,
                value: data.activity,
                writable: true,
                enumerable: true
            },
            persoon: {
                type: String,
                value: data.user,
                writable: true,
                enumerable: true
            }
        });
    }
}
