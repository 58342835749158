const mutations = {
    setJWTToken(state, jwtToken) {
        state.jwtToken = jwtToken;
        state.isAuthenticated = (jwtToken != undefined);
    },
    setUser(state, loggedInUser) {
        state.loggedInUser = loggedInUser;
    },
    setUserName(state, userName) {
        state.userName = userName;
    },
    setUserManager(state, userManager) {
        state.mgr = userManager;
    },
    setAuthUser(state, authUser) {
        state.authUser = authUser;
        state.isAuthenticated = (authUser != undefined);
    },
    setUsersAndGroup(state, lijst) {
        state.usersInGroupsList = [];
        if (lijst != undefined) {
            state.usersInGroupsList=lijst;
        }
    },
    setPermissionsLijst(state, lijst) {
        state.permissionsList = [];
        if (lijst != undefined) {
            state.permissionsList=lijst;
        }
    },
    setPackagePermissionsLijst(state, lijst) {
        state.packagePermissionsList = [];
        if (lijst != undefined) {
            state.packagePermissionsList=lijst;
        }
    },
    setFeaturesLijst(state, lijst) {
        state.featuresList = [];
        if (lijst != undefined) {
            state.featuresList=lijst;
        }
    },    
    setGroupPermissionsLijst(state, lijst) {
        state.groupPermissionsList = [];
        if (lijst != undefined) {
            state.groupPermissionsList=lijst;
        }
    },
    setGroupsLijst(state, lijst) {
        state.groupsList = [];
        if (lijst != undefined) {
            state.groupsList=lijst;
        }
    },
    setOperatorsLijst(state, lijst) {
        state.operatorList = [];
        if (lijst != undefined) {
            state.operatorList=lijst;
        }
    }
    

};

export default mutations;
