import jwt_decode from 'jwt-decode';

const getters = {
    hasValidatedJWTToken: (state) => {
        return state.jwtToken !== undefined && state.jwtToken !== null;
    },
    getBearerToken: (state) => {
        return `Bearer ${state.jwtToken}`;
    },
    getClaim: (state) => (claim) => {
        const decoded = jwt_decode(state.jwtToken);
        const claimValue = decoded[claim];
        return claimValue;
    },
    getLoggedInUser: (state) => {
        return state.loggedInUser;
    },
    getLoggedInOpenIDUser: (state) => {
        return state.authUser;
    },
    getLogInManager: (state) => {
        return state.mgr;
    },
    getAuthenticated: (state) => {
        return state.isAuthenticated;
    },
    getPermissions: (state) => {
        return state.permissionsList;
    },
    getPackagePermissions: (state) => {
        return state.packagePermissionsList;
    },
    getFeatures: (state) => {
        return state.featuresList;
    },
    getGroupPermissions: (state) => {
        return state.groupPermissionsList;
    },
    getUsersInGroups: (state) => {
        return state.usersInGroupsList;
    },
    getUserInGroups: (state) => (userid) => {
        return state.usersInGroupsList.filter((x) => x.userid === userid);
    },
    getGroupUsers: (state) => (groupname) => {
        return state.usersInGroupsList.filter((x) => x.groupname == groupname);
    },
    getGroups: (state) => {
        return state.groupsList;
    },
    getOperators: (state) => {
        return state.operatorList;
    },
};

export default getters;
