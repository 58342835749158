<template>
    <div class="form-inline mx-0 px-0 my-0 py-0">
        <b-col sm="5" class="mx-0 px-0 my-0 py-0">
            <b-form-input
                id="input-naam"
                v-model="localSelected.naam"
                @change="set_input"
                readonly
            ></b-form-input>
        </b-col>
        <b-col sm="7" v-if="localSelected.type == pvbool" class="mx-0 px-0 my-0 py-0">
            <!--             <b-form-radio-group
                id="radio-group-1"
                v-model="localSelected.janee"
                :options="options"
                name="radio-options"
                @change="set_input"
                :disabled="setDisabled"
            ></b-form-radio-group> -->
            <b-form-checkbox
                v-model="localSelected.janee"
                value=true
                unchecked-value=false
                @change="set_input"
                :disabled="setDisabled"
            />
        </b-col>
        <b-col sm="7" v-if="localSelected.type == pvgetal" class="mx-0 px-0 my-0 py-0">
            <b-input-group append="getal" label="Getal">
                <b-form-input
                    id="input-value"
                    type="number"
                    step="1"
                    v-model.number="localSelected.getal"
                    @change="set_input"
                    :disabled="setDisabled"
                ></b-form-input>
            </b-input-group>
        </b-col>
        <b-col sm="7" v-if="localSelected.type == pvdatumtijd" class="mx-0 px-0 my-0 py-0">
            <b-input-group append="tijd" label="Tijd">
                <b-form-timepicker
                    id="input-value"
                    v-model="tijd"
                    locale="nl"
                    @input="set_inputTijd"
                    :disabled="setDisabled"
                ></b-form-timepicker>
                <cheese-tijd
                    v-model="localSelected.datumtijd"
                    @change="set_inputdatumtijd"
                    :disabled="setDisabled"
                >
                </cheese-tijd>
            </b-input-group>
        </b-col>
    </div>
</template>
<script lang="ts">
import DateHelper from '@/models/DateHelper';
import { defineComponent } from 'vue';
import {
    SimpleProductionValue,
    ProductionValueType
} from '../../models/PlusProduction';
import { CheeseTijd } from '../components';
export default defineComponent({
    name: 'ProductionValueCombo',
    data: function () {
        return {
            lctypeSimple: window.constants.LCSIMPLEPRODUCT,
            pvbool: window.constants.PV_BOOL,
            pvgetal: window.constants.PV_DOUBLE,
            pvdatumtijd: window.constants.PV_DATETIME,
            strRemarkAdditive: this.$t('tien_remarkadditive', { ns: 'tien'}),
            tijd: '',
            options: [
                { text: this.$t('com_ja', { ns: 'common' }), value: true },
                { text: this.$t('com_nee', { ns: 'common' }), value: false }
            ]
        };
    },
    model: {
        prop: 'selected',
        event: 'input'
    },
    props: {
        selected: {
            type: SimpleProductionValue,
            required: true
        },
        editAllowed: {
            type: Boolean,
            default: true
        }
    },
    components: {
        CheeseTijd
    },
    computed: {
        localSelected() {
            return this.selected;
        },
        setDisabled() {
            return !this.editAllowed;
        }
    },
    methods: {
        set_input: function () {
            this.localSelected.isChanged = true;
            this.$emit('change', true);
            this.$emit('input', this.localSelected);
        },
        set_inputTijd(value) {
            this.localSelected.datumtijd =
                this.localSelected.datumtijd ?? new Date();
            this.localSelected.datumtijd = DateHelper.convertedFromTimepicker(
                this.localSelected.datumtijd,
                value
            );
            this.tijd = DateHelper.convertedToTime(
                this.localSelected.datumtijd
            );
            this.set_input();
        },
        set_inputdatumtijd(value) {
            if (DateHelper.dateIsValid(value)) {
                this.localSelected.datumtijd = value;
                this.tijd = DateHelper.convertedToTime(value);
                this.set_input();
            }
        }
    },
    mounted() {
        if (this.localSelected.type == 3) {
            this.tijd = DateHelper.convertedToTime(new Date());
            if (this.localSelected.datumtijd)
                this.tijd = DateHelper.convertedToTime(
                    this.localSelected.datumtijd
                );
        }
    },
    created() {}
});
</script>

<style scoped></style>
