<template>
    <div>
        <div slot="pdfdata">
            <b-card @submit.stop.prevent="handleSubmit">
                <b-card-group deck>
                    <b-card :header="$t('log_afzender', { ns: 'logistics' })">
                        <div>
                            <pre>{{ localItem.eigenadres }}</pre>
                        </div>
                    </b-card>
                    <b-card :header="$t('log_ontvanger', { ns: 'logistics' })">
                        <div>
                            <pre>{{ localItem.klantadres }}</pre>
                        </div>
                    </b-card>
                </b-card-group>
                <b-card>
                    <b-col class="mx-0 px-0 my-0 py-0">
                        <div>
                            <pre>{{ localItem.opmerking }}</pre>
                        </div>
                    </b-col>
                </b-card>
            </b-card>
            <b-card :header="$t('log_lines', { ns: 'logistics' })">
                <div v-if="showFilter">
                    <b-row no-gutters>
                        <b-col lg="6" class="my-1"> </b-col>
                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_perpagina', { ns: 'common' })"
                                label-for="per-page-select"
                                label-cols-sm="6"
                                label-cols-md="4"
                                label-cols-lg="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-form-select
                                    id="per-page-select"
                                    v-model="perPage"
                                    :options="pageOptions"
                                    size="sm"
                                ></b-form-select>
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="regelCount"
                                    :per-page="perPage"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                ></b-pagination>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div>
                    <b-table
                        key="rerenderkey"
                        :items="regelList"
                        :fields="visibleFields"
                        :current-page="currentPage"
                        :per-page="perPage"
                        stacked="md"
                        show-empty
                        :empty-text="$t('com_geen_data', { ns: 'common' })"
                        small
                        striped
                    >
                        <template #cell(regeltekst)="data">
                            <span v-html="data.value"></span>
                        </template>
                    </b-table>
                </div>
            </b-card>

            <b-card class="pdf-item">
                <b-card-group deck>
                    <b-card
                        :header="$t('log_samenvatting', { ns: 'logistics' })"
                    >
                        <div>
                            <show-row-col
                                :showLabel="strOnzeRef"
                                :showObject="localItem.onzeRef"
                                :size="5"
                            />
                            <show-row-col
                                :showLabel="strUwRef"
                                :showObject="referentie"
                                :size="5"
                            />
                            <show-row-col
                                :showLabel="
                                    $t('log_lines', { ns: 'logistics' }) + ':'
                                "
                                :showObject="showRegelItems"
                                :size="5"
                            />
                            <show-row-col
                                :showLabel="
                                    $t('com_datum', { ns: 'common' }) + ':'
                                "
                                :showObject="printdatum"
                                :size="5"
                            />
                        </div>
                    </b-card>

                    <b-card
                        :header="
                            $t('log_handtekening', { ns: 'logistics' }) + ':'
                        "
                    >
                        <b-img
                            v-if="showImage"
                            src="../../../public/Concept.jpg"
                            fluid
                            alt="Preview"
                        ></b-img>
                    </b-card>
                </b-card-group>
            </b-card>
        </div>
    </div>
</template>

<script lang="ts">
import { DeliveryNote, MergeType, PrintedNote } from '../../models/Delivery';
import DateHelper from '@/models/DateHelper';
import Helper from '@/models/Helper';
import { ShowRowCol } from '../components';
import { defineComponent } from 'vue';
import { Feature } from '@/models/Features';
import { VueExtension } from '@/models/VueExtension';

export default defineComponent({
    name: 'PrintNoteContent',
    data: function () {
        return {
            currentPage: 1,
            perPage: 100,
            pageOptions: [10, 15, 20],
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            fieldsKolommen: [] as Array<any>,
            opmerking: '',
            rerenderkey: 1,
            strRegelItems: '{0} / ({1})',
            strOnzeRef:
                this.$t('log_onze', { ns: 'logistics' }) +
                ' ' +
                this.$t('com_reference', { ns: 'common' }).toLowerCase(),
            strUwRef:
                this.$t('log_uw', { ns: 'logistics' }) +
                ' ' +
                this.$t('com_reference', { ns: 'common' }).toLowerCase()
        };
    },
    props: {
        printBon: {
            type: Object,
            required: true
        },
        showPagination: {
            type: Boolean,
            default: false
        },
        pagina: {
            type: Number,
            default: 1
        },
        sortable: {
            type: Boolean,
            default: true
        },
        count: {
            type: Number,
            default: 0
        }
    },
    components: {
        ShowRowCol
    },
    watch: {
        count: {
            handler(newVal, oldVal) {
                if (newVal > 0) this.determineFields();
            }
        },
        printBon: {
            handler(newVal, oldVal) {
                if (newVal) this.determineFields();
            }
        }
    },
    computed: {
        visibleFields() {
            const fieldsInit = this.fieldsKolommen;
            return fieldsInit.filter((field) => field.visible);
        },
        localItem() {
            return this.printBon;
        },
        regelList() {
            return this.localItem.regels;
        },
        pageToPrint() {
            return this.showPagination ? this.currentPage : this.pagina;
        },
        printdatum() {
            return this.localItem.boektijdstip
                ? DateHelper.convertedToDateShort(this.localItem.boektijdstip)
                : DateHelper.convertedToDateShort(
                      DateHelper.MakeDateTime(new Date())
                  );
        },
        regelCount() {
            return this.localItem.regels ? this.localItem.regels.length : 0;
        },
        hasWeight() {
            const a = this.rerenderkey;
            const lijst = this.regelList
                ? this.regelList.filter((x) => x.gewicht)
                : [];
            return lijst.length > 0;
        },
        useWeight() {
            return this.checkFeature('conf_UseCheeseWeights');
        },
        referentie() {
            return this.localItem.referentie ? this.localItem.referentie : '?';
        },
        itemCount() {
            if (this.localItem.regels == undefined) return 0;
            const lijst = this.localItem.regels.map((x) => x.aantal);
            return Helper.sumList(lijst);
        },
        showFilter() {
            return this.regelList
                ? this.showPagination
                    ? this.regelList.length > this.perPage
                    : false
                : this.showPagination;
        },
        showRegelItems() {
            return Helper.stringformat(
                this.strRegelItems,
                this.regelCount,
                this.itemCount
            );
        },
        showImage() {
            return (
                this.localItem.versie == undefined || this.localItem.versie < 1
            );
        }
    },
    methods: {
        checkFeature(feature) {
            const key = Feature.enum(feature);
            return (this.$root as VueExtension).handleFeature(key);
        },
        determineFields() {
            const fieldsInit = [
                { key: 'id', label: 'Id', sortable: false, visible: false }
            ];
            if (this.regelCount > 0) {
                const regel = this.regelList[0];
                const a = regel.itemarr;
                let label = this.$t('com_product', { ns: 'common' });
                if (a) {
                    for (let i = 0; i < a.length; i++) {
                        if (i > 0) {
                            const obj = a[i];
                            const arr = obj.split(':');
                            label = arr.length > 1 ? arr[0] : '';
                        }
                        const field = {
                            key: Helper.stringformat('clearedarr[{0}]', i),
                            label: label,
                            sortable: false,
                            visible: true
                        };

                        fieldsInit.push(field);
                    }
                }
                fieldsInit.push({
                    key: 'aantal',
                    label: this.$t('com_number', { ns: 'common' }),
                    sortable: false, //this.sortable,
                    visible: true
                });
                fieldsInit.push({
                    key: 'gewicht',
                    label: this.$t('com_weight', { ns: 'common' }),
                    sortable: false, //this.sortable,
                    visible: this.useWeight // this.hasWeight
                });
            } else {
                fieldsInit.push(
                    ...[
                        {
                            key: 'regeltekst',
                            label: this.$t('prod_regeltekst', {
                                ns: 'production'
                            }),
                            sortable: false, //this.sortable,
                            visible: true
                        },
                        {
                            key: 'aantal',
                            label: this.$t('com_number', { ns: 'common' }),
                            sortable: false, //this.sortable,
                            visible: true
                        },
                        {
                            key: 'gewicht',
                            label: this.$t('com_weight', { ns: 'common' }),
                            sortable: false, //this.sortable,
                            visible: this.useWeight //this.hasWeight
                        }
                    ]
                );
            }
            this.fieldsKolommen = fieldsInit;
            this.rerenderkey += 1;
        }
    },
    mounted() {
        this.determineFields();
    },
    created() {}
});
</script>

<style scoped>
pre {
    background: #fff;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    line-height: 22px;
    color: #404040;
    white-space: pre-wrap; /* <-- THIS PRESERVES THE LINE BREAKS */
}
.pdf-item {
    page-break-inside: avoid;
}
</style>

