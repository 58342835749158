var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-alert',{attrs:{"variant":"warning","dismissible":""},model:{value:(_vm.showWarningAlert),callback:function ($$v) {_vm.showWarningAlert=$$v},expression:"showWarningAlert"}},[_vm._v(" "+_vm._s(_vm.reminder)+" ")]),_c('lookup-combo',{attrs:{"combotype":_vm.lctypeQuality,"disabled":!_vm.editAllowed},on:{"input":_vm.set_inputQ},model:{value:(_vm.localSelected.qualityid),callback:function ($$v) {_vm.$set(_vm.localSelected, "qualityid", $$v)},expression:"localSelected.qualityid"}}),_c('b-form-group',[_c('b-form-radio-group',{key:"rerenderkey",attrs:{"options":_vm.selOptions,"disabled":!_vm.editAllowed},on:{"change":_vm.set_input},model:{value:(_vm.localSelected.qlabelid),callback:function ($$v) {_vm.$set(_vm.localSelected, "qlabelid", $$v)},expression:"localSelected.qlabelid"}})],1),(_vm.subtypesAvailable)?_c('b-card',{staticClass:"mb-1",attrs:{"no-body":""}},[(_vm.editAllowed)?_c('div',{staticClass:"groups"},[_c('div',{staticClass:"group"},[_c('b-card',{attrs:{"header":_vm.$t('com_beschikbaar', {
                            ns: 'common',
                            val: _vm.$t('an_labels', {
                                ns: 'analytics'
                            }).toLowerCase()
                        })}},[_c('Container',{attrs:{"group-name":"LinkedLabels","get-child-payload":_vm.getChildPayload1},on:{"drop":function($event){return _vm.onDrop('availableList', $event)}}},_vm._l((_vm.availableList),function(item){return _c('Draggable',{key:item.id},[_c('div',{staticClass:"draggable-item"},[_vm._v(" "+_vm._s(item.naam)+" ")])])}),1)],1)],1),_c('div',{staticClass:"group"},[_c('b-card',{attrs:{"header":_vm.$t('an_vantoepassing', {
                            ns: 'analytics'
                        })}},[_c('Container',{attrs:{"group-name":"LinkedLabels","get-child-payload":_vm.getChildPayload2},on:{"drop":function($event){return _vm.onDrop('selectedList', $event)}}},_vm._l((_vm.selectedList),function(item){return _c('Draggable',{key:item.id},[_c('div',{staticClass:"draggable-item"},[_vm._v(" "+_vm._s(item.naam)+" ")])])}),1)],1)],1)]):_c('div',_vm._l((_vm.selectedList),function(item){return _c('div',{key:item.id},[_c('b-form-input',{attrs:{"readonly":""},model:{value:(item.naam),callback:function ($$v) {_vm.$set(item, "naam", $$v)},expression:"item.naam"}})],1)}),0)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }