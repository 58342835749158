var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',{staticClass:"mb-1",attrs:{"no-body":"","header":_vm.$t('com_change', {
                ns: 'common',
                val: _vm.$t('com_ww', { ns: 'common' })
            })}},[_c('b-row',[_c('b-col',{attrs:{"sm":"3"}}),_c('b-col',{attrs:{"sm":"6"}},[_c('b-alert',{attrs:{"variant":"danger","dismissible":""},model:{value:(_vm.showDismissibleAlert),callback:function ($$v) {_vm.showDismissibleAlert=$$v},expression:"showDismissibleAlert"}},[_vm._v(" "+_vm._s(_vm.alertText)+" ")]),_c('b-form-group',{attrs:{"label":_vm.$t('com_newx', {
                            ns: 'common',
                            val: _vm.$t('com_ww', { ns: 'common' })
                        }),"label-for":"pass1","description":_vm.$t('com_wweis', { ns: 'common' })}},[_c('b-form-input',{attrs:{"id":"pass1","type":"password","state":_vm.pass1.length > 7},model:{value:(_vm.pass1),callback:function ($$v) {_vm.pass1=$$v},expression:"pass1"}})],1)],1),_c('b-col',{attrs:{"sm":"3"}})],1),_c('b-row',[_c('b-col',{attrs:{"sm":"3"}}),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"inline":"","label":_vm.$t('com_repeat', {
                            ns: 'common',
                            val: _vm.$t('com_ww', { ns: 'common' })
                        }),"label-for":"pass2"}},[_c('b-form-input',{attrs:{"id":"pass2","type":"password","state":_vm.pass2.length > 7 && _vm.pass2 == _vm.pass1},model:{value:(_vm.pass2),callback:function ($$v) {_vm.pass2=$$v},expression:"pass2"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-live-feedback"}},[_vm._v(" "+_vm._s(_vm.errorMessage1)+" ")])],1)],1),_c('b-col',{attrs:{"sm":"3"}})],1),_c('div',{staticClass:"w-100",attrs:{"slot":"footer"},slot:"footer"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"success","disabled":!_vm.isValid},on:{"click":_vm.handleSubmit}},[_vm._v("OK")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }