<template>
    <div>
        <b-col sm="6">
            <b-input-group class="mb-2" :prepend="showVanOp">
                <b-input-group-prepend is-text>
                    <b-form-checkbox
                        v-if="showDag"
                        class="mr-n2"
                        v-model="status"
                        name="checkbox-dag"
                        value="day"
                        unchecked-value="period"
                        @input="emitValues"
                    >
                        <span class="sr-only">{{
                            $t('com_dag', { ns: 'common' })
                        }}</span>
                    </b-form-checkbox>
                </b-input-group-prepend>
                <b-form-datepicker
                    v-model="van"
                    :date-format-options="{
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit'
                    }"
                    @input="emitValues"
                ></b-form-datepicker>
            </b-input-group>
        </b-col>
        <b-col sm="6" v-if="!isDagSelectie">
            <b-input-group
                class="mb-2"
                :prepend="$t('com_tot', { ns: 'common' })"
                :label="$t('com_tot', { ns: 'common' })"
            >
                <b-form-datepicker
                    v-model="tot"
                    :date-format-options="{
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit'
                    }"
                    @input="emitValues"
                ></b-form-datepicker>
            </b-input-group>
        </b-col>
        <b-col sm="6" v-else>
            <div v-if="showBakgroepOption">
                <b-input-group
                    class="mb-2 form-inline"
                    :label="strBakgroepPartij"
                >
                    <b-form-input
                        :placeholder="strBakgroepPartij"
                        v-model="bgnummer"
                    ></b-form-input>
                    <b-input-group-append>
                        <b-button
                            @click="getDateForBatchGroup()"
                            class="bgc_aux_normal"
                            >Ok</b-button
                        >
                    </b-input-group-append>
                </b-input-group>
            </div>
        </b-col>
    </div>
</template>
<script>
import DateHelper from '../../models/DateHelper';
import { loadItem } from '../../models/DataHelper';
export default {
    name: 'PeriodSelector',
    data: function () {
        return {
            errorMessage: '',
            status: 'day',
            showDag: true,
            van: null,
            tot: null,
            bgnummer: null,
            startDateObject: {
                van: null,
                tot: null
            }
        };
    },
    model: {
        prop: 'selected',
        event: 'input'
    },
    props: {
        periodDays: {
            type: Number,
            default: 1
        },
        selected: {
            type: Object
        },
        showBakgroepOption: {
            type: Boolean,
            default: false
        },
        slackTillDate: {
            type: Number,
            default: -1
        },
        forceToday: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isVersion10() {
            return (
                this.$store.getters['masterdata/getPackageVersion'] ==
                window.constants.VERSION10
            );
        },
        strBakgroepPartij() {
            return this.isVersion10
                ? this.$t('com_batchnumber', { ns: 'common' })
                : this.$t('prod_bakgroep', { ns: 'production' });
        },
        groupingPeriods() {
            return [
                { value: 'day', text: this.$t('com_dag', { ns: 'common' }) },
                { value: 'week', text: this.$t('com_week', { ns: 'common' }) },
                {
                    value: 'month',
                    text: this.$t('com_maand', { ns: 'common' })
                },
                { value: 'year', text: this.$t('com_jaar', { ns: 'common' }) }
            ];
        },
        isDagSelectie() {
            return this.status == 'day';
        },
        showVanOp() {
            return this.isDagSelectie
                ? this.$t('com_optijdstip', { ns: 'common' })
                : this.$t('com_van', { ns: 'common' });
        }
    },
    methods: {
        set_input() {
            localStorage.setItem('van', this.van);
            localStorage.setItem('tot', this.tot);
            this.$emit('change', this.startDateObject);
        },
        emitValues() {
            this.startDateObject.van = this.van;
            this.startDateObject.tot = this.isDagSelectie ? this.van : this.tot;
            this.bgnummer = null;
            this.set_input();
        },
        addDays(date, days) {
            const result = new Date(date);
            result.setDate(result.getDate() + days);
            return result;
        },
        async getDateForBatchGroup() {
            if (this.bgnummer != undefined) {
                const date = await loadItem(
                    'components/loadBatchGroupProductionDate',
                    { productionnumber: this.bgnummer },
                    this.$t
                );
                if (date != undefined) {
                    this.van = DateHelper.MakeDateTime(date).toJSDate();
                    this.startDateObject.van = this.van;
                    this.startDateObject.tot = this.van;
                    this.set_input();
                }
            }
        }
    },
    mounted() {
        const van = localStorage.getItem('van');
        const tot = localStorage.getItem('tot');
        if (van) {
            this.van = this.forceToday ? new Date() : van;
            this.tot = this.forceToday ? new Date() : tot;
            this.startDateObject.tot = this.tot;
            this.startDateObject.van = this.van;
            this.status = this.periodDays == 1 ? 'day' : 'period';
            this.showDag = this.periodDays == 1;
        } else {
            this.status = this.periodDays == 1 ? 'day' : 'period';
            this.showDag = this.periodDays == 1;
            const dagen = this.periodDays == 1 ? 0 : this.periodDays;
            const newDate = new Date();
            this.tot = this.addDays(
                newDate,
                dagen == 0 ? 0 : this.slackTillDate
            );
            this.tot = this.tot.toISOString().substring(0, 10);
            this.startDateObject.tot = this.tot;
            const test = this.addDays(newDate, -dagen);
            this.van = new Date(test);
            this.van = this.van.toISOString().substring(0, 10);
            this.startDateObject.van = this.van;
        }
        this.set_input();
    },
    created() {}
};
</script>

<style scoped></style>
