const getters = {
    getUnitsComboLijst: state => {
        return state.unitsComboList;
    },
    getSimpleProductLijst: state => {
        return state.simpleProductList;
    },
    getSimpleRecipeLijst: state => {
        return state.simpleRecipeList;
    },
    getSimpleProductionLijst: state => {
        return state.simpleProductionList;
    } ,
    getProductValueLijst: state => {
        return state.productionValueList;
    } ,
    
    
};

export default getters;
