<template>
    <b-card no-body>
        <b-card-header class="container-fluid">
            {{ $t('prod_stremgeg', { ns: 'production' }) }}
            <b-form-checkbox
                class="float-right"
                v-if="configUseFahrenheit"
                v-model="showFahrenheit"
            >
                {{ $t('prod_usefahrenheit', { ns: 'production' }) }}
            </b-form-checkbox>
        </b-card-header>
        <div>
            <card-bakingredient-lijst
                v-if="theBakStrem.bakIngredientenCalcium.length > 0"
                v-model="theBakStrem.bakIngredientenCalcium"
                v-bind:editAllowed="editAllowed"
                v-bind:baseDateTime="theBakStrem.boekDatum"
                @change="setChanged"
                @validComponent="setCalciumValid"
            />
            <card-bakingredient-lijst
                v-if="theBakStrem.bakIngredientenStremsel.length > 0"
                v-model="theBakStrem.bakIngredientenStremsel"
                v-bind:editAllowed="editAllowed"
                v-bind:baseDateTime="theBakStrem.boekDatum"
                @change="setChanged"
                @validComponent="setStremselValid"
                @stremtijd="set_inputStremTijd"
            />
            <b-card>
                <b-col sm="9" class="mx-0 px-0 my-1 py-0">
                    <b-row class="mx-0 px-0 my-1 py-0">
                        <b-col sm="3" class="mx-0 px-0 my-1 py-0">
                            <label for="input_strem"
                                >{{
                                    $t('prod_xtemp', {
                                        ns: 'production',
                                        val: $t('prod_strem', {
                                            ns: 'production'
                                        })
                                    })
                                }}:</label
                            >
                        </b-col>
                        <b-col sm="9" class="mx-0 px-0 my-1 py-0">
                            <temperature-input
                                id="input-strem"
                                v-model.number="temperatuur"
                                @input="set_inputTemperatuur"
                                @valid="set_validTemp"
                                :editAllowed="editAllowed"
                                :showFahrenheit="showFahrenheit"
                                :minvalue="minTemp"
                                :maxvalue="maxTemp"
                                :placeholderC="
                                    $t('com_temperatuur', { ns: 'common' })
                                "
                            />
                        </b-col>
                    </b-row>
                </b-col>
                <b-col sm="3" class="mx-0 px-0 my-1 py-0">
                    <b-row>
                        <b-col>
                            <b-button
                                @click="SetDefaults()"
                                class="bgc_aux_warning float-right "
                                :disabled="!editAllowed"
                                ><font-awesome-icon
                                    icon="edit"
                                    size="1x"
                                />&nbsp;{{
                                    $t('com_default', { ns: 'common' })
                                }}
                            </b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-card>
        </div>
    </b-card>
</template>

<script>
import { BakStrem } from '../../models/BakInfo_helper';
import { loadList } from '../../models/DataHelper';
import { Feature } from '../../models/Features';
import { TemperatureInput } from '../components';
import CardBakingredientLijst from './CardBakingredientLijst';

export default {
    name: 'CardStrem',
    data() {
        return {
            minTemp: window.constants.MIN_TEMP_STREM,
            maxTemp: window.constants.MAX_TEMP_STREM,
            validTemperatuur: true,
            showFahrenheit: true,
            temperatuur: null,
            stremtijdstip: null,
            biValid: true,
            calciumValid: true,
            componentKey: 0
        };
    },
    components: {
        CardBakingredientLijst,
        TemperatureInput
    },
    model: {
        prop: 'theBakStrem',
        event: 'changedRecipe'
    },
    props: {
        theBakStrem: {
            type: BakStrem,
            default: null
        },
        editAllowed: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        configUseFahrenheit() {
            const useF = this.checkFeature('conf_useFahrenheitinProduction');
            return useF;
        }
    },
    methods: {
        checkFeature(feature) {
            const key = Feature.enum(feature);
            return this.$root.handleFeature(key);
        },
        set_validTemp(value) {
            this.validTemperatuur = value;
        },
        set_inputStremTijd(value) {
            this.theBakStrem.stremTijdstip = value;
            this.setChanged();
        },
        set_inputTemperatuur(val) {
            this.theBakStrem.stremTemp = val ? val : null;
            this.setChanged();
        },
        setChanged() {
            this.$emit(
                'validComponent',
                this.validTemperatuur && this.calciumValid && this.biValid
            );
            this.$emit('change', true);
        },
        setCalciumValid(value) {
            this.calciumValid = value;
            this.setChanged();
        },
        setStremselValid(valid) {
            this.biValid = valid;
            this.setChanged();
        },
        SetDefaults() {
            this.setDefaultStremTemp();
        },
        async setDefaultStremTemp() {
            const selectionData = { recipeid: this.theBakStrem.receptID };
            const recipe = await loadList(
                'recipe/getRecipe',
                'recipe/loadRecipeComplete',
                selectionData,
                true,
                this.$t
            );
            this.temperatuur = recipe.stremTemp;
            this.set_inputTemperatuur();
        }
    },
    mounted() {},
    created() {
        this.temperatuur = this.theBakStrem.stremTemp;
        this.showFahrenheit = this.configUseFahrenheit;
    }
};
</script>

