declare global {
    interface Window {
        nonce: string;
    }
}
declare global {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    interface Window {
        constants: any;
    }
}
window.constants = window.constants || {};

// eslint-disable-next-line no-undef
__webpack_nonce__ = window.nonce;

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'ol/ol.css';
import '../public/css/main.css';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

import Vue from 'vue';
import App from './App.vue';
//import App from './suspenser.vue'
//import i18n from './i18n'
import I18NextVue from 'i18next-vue';
import i18next from 'i18next';

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import { library, config } from '@fortawesome/fontawesome-svg-core';
config.autoAddCss = false;

import VueHtmlToPaper from 'vue-html-to-paper';
const options = {
    name: '_blank',
    specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
    styles: [
        '/css/bootstrap.min.css'
    ],
    timeout: 1000,
    autoClose: true,
    windowTitle: 'Cheeseit'
};
Vue.use(VueHtmlToPaper, options);

import {
    faTrashAlt,
    faUserPlus,
    faEdit,
    faInfoCircle,
    faFolderPlus,
    faCamera,
    faVideo,
    faCheck,
    faArrowDown,
    faArrowUp,
    faArrowLeft,
    faSync,
    faFileCsv,
    faFilePdf,
    faEnvelope,
    faPhone,
    faCopy,
    faPrint,
    faBarcode,
    faCalculator,
    faSearch,
    faMicroscope,
    faChartLine,
    faCogs,
    faLink,
    faUnlink
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

Vue.use(I18NextVue, { i18next });
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
import router from './router';
import store from './store';
import Operator from './models/Operator';

library.add(
    faTrashAlt,
    faUserPlus,
    faEdit,
    faInfoCircle,
    faFolderPlus,
    faCamera,
    faVideo,
    faCheck,
    faArrowDown,
    faArrowUp,
    faArrowLeft,
    faSync,
    faFileCsv,
    faFilePdf,
    faEnvelope,
    faPhone,
    faCopy,
    faPrint,
    faBarcode,
    faCalculator,
    faSearch,
    faMicroscope,
    faChartLine,
    faCogs,
    faLink,
    faUnlink
);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
    data() {
        return {
            localLoggedInUser: {} as Operator
        };
    },
    methods: {
        handleRight(recht): boolean {
            if (this.localLoggedInUser.id == undefined) {
                const operator = this.$store.getters['account/getLoggedInUser'];
                if (operator) this.localLoggedInUser = operator;
            }
            if (this.localLoggedInUser.id) {
                const version =
                    this.$store.getters['masterdata/getPackageVersion'];
                if (version > 20) {
                    return this.localLoggedInUser.listContainsRight(recht);
                } else {
                    return true;
                }
            }
            return false;
        },
        handleFeature(feature) {
            if (this.localLoggedInUser.id == undefined) {
                const operator = this.$store.getters['account/getLoggedInUser'];
                if (operator) this.localLoggedInUser = operator;
            }
            if (this.localLoggedInUser.id) {
                return this.localLoggedInUser.listContainsFeature(feature);
            }
            return false;
        },
        async showConfirmationBox(message) {
            const result = await this.$bvModal.msgBoxConfirm(message, {
                title: this.$t('com_bevestiging', { ns: 'common' }) +':',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: this.$t('com_ja', { ns: 'common' }).toUpperCase(),
                cancelTitle: this.$t('com_nee', { ns: 'common' }).toUpperCase(),
                footerClass: 'p-2',
                hideHeaderClose: false
            });
            return result;
        },
        async showMessageBox(message) {
            const result = await this.$bvModal.msgBoxOk(message, {
                title: this.$t('com_melding', { ns: 'common' }) +':',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'OK',
                footerClass: 'p-2',
                hideHeaderClose: false
            });
            return result;
        }
    }
}).$mount('#app');
