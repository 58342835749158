<template>
    <b-modal
        id="modal-printnote"
        size="xl"
        ref="modal"
        :title="headertitle"
        :visible="visible"
        ok-only
        :okTitle="$t('com_close', { ns: 'common' })"
        @hidden="hide"
        @ok="handleOk"
    >
        <div>
            <b-card
                @submit.stop.prevent="handleSubmit"
                :header="$t('com_configuratie', { ns: 'common' })"
            >
                <div>
                    <b-col sm="5" class="mx-0 px-0 my-0 py-0">
                        <b-form-group
                            :label="
                                $t('log_kiesoptvelden', { ns: 'logistics' })
                            "
                            v-if="editAllowed"
                        >
                            <b-form-checkbox-group
                                v-model="selectedOpties"
                                :options="printopties"
                            ></b-form-checkbox-group> </b-form-group></b-col
                    ><b-col
                        sm="5"
                        class="mx-0 px-0 my-0 py-0"
                        v-if="editAllowed"
                    >
                        <label
                            >{{
                                $t('log_samenvoeging', { ns: 'logistics' })
                            }}:</label
                        >
                        <b-form-select
                            v-model="selectedMergetype"
                            :options="mergetypes"
                            :disabled="!editAllowed"
                        >
                            <template #first>
                                <b-form-select-option value=""
                                    >--
                                    {{ $t('com_selecteer', { ns: 'common' }) }}
                                    --</b-form-select-option
                                >
                            </template>
                        </b-form-select>
                    </b-col>
                    <b-col sm="2" class="mx-0 px-0 my-0 py-0">
                        &nbsp;
                        <b-button
                            v-if="editAllowed"
                            @click="refeshData()"
                            class="bgc_aux_normal"
                            ><font-awesome-icon
                                icon="arrow-down"
                                size="1x"
                            />&nbsp;</b-button
                        >&nbsp;
                        <span
                            id="disabled-wrapper"
                            class="d-inline-block"
                            tabindex="0"
                        >
                            <b-button
                                :disabled="!magPrint"
                                @click="printThisPakbon()"
                                v-b-tooltip.hover
                                :title="
                                    $t('com_downloadas', {
                                        ns: 'common',
                                        val: 'PDF'
                                    })
                                "
                                class="bgc_aux_alarm"
                            >
                                <font-awesome-icon icon="file-pdf" size="1x" />
                            </b-button>
                        </span>
                        <b-tooltip
                            v-if="!magPrint"
                            target="disabled-wrapper"
                            triggers="hover"
                        >
                            {{ warnText }}
                        </b-tooltip>
                        <!--            <b-button
                            @click="printThisPakbon()"
                            v-b-tooltip.hover
                            title="Download als PDF"
                            class="bgc_aux_alarm"
                            ><font-awesome-icon icon="file-pdf" size="1x" />
                        </b-button> -->
                    </b-col>
                </div>
                <b-alert
                    v-model="showDismissibleAlert"
                    variant="danger"
                    dismissible
                >
                    {{ alertText }}
                </b-alert>
            </b-card>
            <print-note-content
                id="targetDiv"
                key="rerenderKey"
                :printBon="localItem"
                :showPagination="true"
                :count="localCount"
                :sortable="isSortable"
            />
        </div>
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :preview-modal="false"
            :enable-download="false"
            :paginate-elements-by-height="1400"
            :filename="headertitle"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            :pdf-margin="20"
            pdf-orientation="portrait"
            pdf-content-width="97%"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
            @beforeDownload="beforeDownload($event)"
            ref="html2Pdf"
        >
            <section slot="pdf-content">
                <print-note-content
                    :printBon="localItem"
                    :count="localCount"
                    :pagina="currentPage"
                    :sortable="isSortable"
                />
            </section>
        </vue-html2pdf>
    </b-modal>
</template>

<script lang="ts">
import { DeliveryNote, MergeType, PrintedNote } from '../../models/Delivery';
import { loadItem, loadList, loadGetItem } from '../../models/DataHelper';
import Helper from '@/models/Helper';
import { defineComponent } from 'vue';
import { VueExtension } from '../../models/VueExtension';
import VueHtml2pdf from 'vue-html2pdf';
import PrintNoteContent from './PrintNoteContent.vue';
import DateHelper from '@/models/DateHelper';

export default defineComponent({
    name: 'PrintNoteModal',
    data: function () {
        return {
            showDismissibleAlert: false,
            alertText: '',
            warnText: this.$t('prod_klantrefverplicht', { ns: 'production' }),

            isDeliveryNote: false,
            pakbon: {} as DeliveryNote,
            printbon: {} as PrintedNote,
            printopties: [],
            mergetypes: [] as Array<{ value: number; text: string }>,
            selectedOpties: [],
            selectedMergetype: 0,
            count: 0,
            rerenderKey: 1,

            opmerking: '',
            currentPagina: 1,
            perPagina: 10,
            sortable: true,
            eigenBedrijf: ''
        };
    },
    model: {
        prop: 'visible',
        event: 'change'
    },
    props: {
        deliveryNoteID: {
            type: Number,
            required: false
        },
        printedNoteID: {
            type: Number,
            required: false
        },
        visible: {
            type: Boolean,
            default: false
        },
        editAllowed: {
            type: Boolean,
            default: false
        },
        newPrintVersion: {
            type: Boolean,
            default: false
        }
    },
    components: {
        VueHtml2pdf,
        PrintNoteContent
    },
    watch: {},
    computed: {
        headertitle(): string {
            const pakbonID = this.isDeliveryNote
                ? this.pakbon.id
                : this.printbon.pakbonID;
            return (
                this.$t('prod_printpakbon', { ns: 'production' }) +
                ' #:  ' +
                pakbonID
            );
        },
        localItem() {
            const a = this.rerenderKey;
            return this.isDeliveryNote ? this.pakbon : this.printbon;
        },
        localCount() {
            return this.count;
        },
        currentPage() {
            return this.currentPagina;
        },
        isSortable() {
            return this.sortable;
        },
        magPrint() {
            return this.localItem.referentie != undefined;
        }
    },
    methods: {
        printPakbon() {
            const mywindow = window.open('', 'PRINT', 'height=400,width=600');
            const element = document.getElementById('targetDiv');
            if (mywindow && element) {
                mywindow.document.write(
                    '<html><head><title>' + document.title + '</title>'
                );
                mywindow.document.write('</head><body >');
                mywindow.document.write(element.innerHTML);
                mywindow.document.write('</body></html>');

                mywindow.document.close(); // necessary for IE >= 10
                mywindow.focus(); // necessary for IE >= 10*/

                mywindow.print();
                mywindow.close();
            }

            return true;
            //this.$htmlToPaper('targetDiv');
        },
        showAlert(tekst: string): void {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        hide(): void {
            this.$emit('hide');
        },
        async refeshData() {
            if (await this.printAllowed()) {
                const som = Helper.sumList(this.selectedOpties);
                const obj = {
                    deliverynoteID: this.isDeliveryNote
                        ? this.pakbon.id
                        : this.printbon.pakbonID,
                    mergeOption: this.selectedMergetype,
                    printOption: som
                };
                const item = await loadItem(
                    'delivery/viewPrintedNote',
                    obj,
                    this.$t
                );
                this.isDeliveryNote = false;
                this.printbon = item;
                this.printbon.replaceTab();
                this.printbon.replaceLineFeed();
                this.printbon.translateString(this.$t);
                this.printbon.eigenadres = Helper.createCompanyAdres(
                    this.eigenBedrijf
                );
                this.count += 1;
                this.rerenderKey += 1;
            }
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        handleSubmit() {
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-printnote');
            });
        },
        async printAllowed(): Promise<boolean> {
            const bestaat: boolean = await loadItem(
                'delivery/existsPrintedNote',
                {
                    deliverynoteID: this.deliveryNoteID
                },
                this.$t
            );
            if (bestaat && !this.newPrintVersion) {
                // is al geprint door ander, melden en de geprinte weergeven
                (this.$root as VueExtension).showMessageBox(
                    this.$t('prod_algeprint', { ns: 'production' })
                );
                return false;
            } else {
                return true;
            }
        },
        async storePrintBon(): Promise<PrintedNote> {
            const som = Helper.sumList(this.selectedOpties);
            const obj = {
                deliverynoteID: this.isDeliveryNote
                    ? this.pakbon.id
                    : this.printbon.pakbonID,
                mergeOption: this.selectedMergetype,
                printOption: som
            };
            const item = await loadItem(
                'delivery/savePrintedNote',
                obj,
                this.$t
            );
            return item;
        },
        async printThisPakbon(): Promise<void> {
            if (this.newPrintVersion) {
                let gaDoor = this.isDeliveryNote ? false : true;
                if (this.isDeliveryNote) gaDoor = await this.printAllowed();
                if (gaDoor) {
                    const item = await this.storePrintBon();
                    this.isDeliveryNote = false;
                    this.printbon = item;
                    this.printbon.replaceTab();
                    this.printbon.replaceLineFeed();
                    this.printbon.eigenadres = Helper.createCompanyAdres(
                        this.eigenBedrijf
                    );
                    this.$nextTick(() => {
                        this.generateReport();
                        this.$emit('printed');
                    });
                }
            } else {
                this.printbon.replaceTab();
                this.printbon.replaceLineFeed();
                this.generateReport();
                this.$emit('printed');
                loadItem(
                    'delivery/savePrintedNotePrinted',
                    {
                        printednoteID: this.printbon.id
                    },
                    this.$t
                );
            }
        },
        generateReport() {
            // bepaal hoeveel pagina's
            // print per pagIna
            (this.$refs as VueExtension).html2Pdf.generatePdf();
        },
        async beforeDownload({ html2pdf, options, pdfContent }) {
            await html2pdf()
                .set(options)
                .from(pdfContent)
                .toPdf()
                .get('pdf')
                .then((pdf) => {
                    const printDate = DateHelper.convertedToDate(
                        DateHelper.MakeDateTime(new Date()),
                        true
                    );
                    const totalPages = pdf.internal.getNumberOfPages();
                    for (let i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.setFontSize(10);
                        pdf.setTextColor(150);
                        pdf.text(
                            printDate + ', pagina ' + i + ' van ' + totalPages,
                            pdf.internal.pageSize.getWidth() * 0.65,
                            pdf.internal.pageSize.getHeight() - 0.3
                        );
                    }
                })
                .save();
        }
    },
    async mounted() {
        this.eigenBedrijf = await loadGetItem(
            'delivery/getOwnCompany',
            'delivery/loadOwnCompany',
            null,
            false,
            this.$t
        );
        this.printopties = await loadList(
            'delivery/getPrintOptions',
            'delivery/printOptions',
            null,
            true,
            this.$t
        );
        const lijst = new MergeType().list(this.$t) as Array<MergeType>;
        this.mergetypes = lijst.map((x) => ({
            value: x.value,
            text: x.text
        })) as Array<{ value: number; text: string }>;
        if (this.printedNoteID == undefined || this.printedNoteID < 0) {
            const item = await loadItem(
                'delivery/loadDeliveryNote',
                {
                    deliverynoteID: this.deliveryNoteID
                },
                this.$t
            );
            this.isDeliveryNote = true;
            this.pakbon = item;
            if (this.pakbon.klanttekst == undefined) {
                if (this.pakbon.klant) {
                    const klant = await loadItem(
                        'masterdata/loadCompany',
                        {
                            companyid: this.pakbon.klant
                        },
                        this.$t
                    );
                    this.pakbon.klantnaam = Helper.createCompanyAdres(klant);
                }
            }
        } else {
            const item = await loadItem(
                'delivery/loadDeliveryNotePrinted',
                {
                    printednoteID: this.printedNoteID
                },
                this.$t
            );
            let datum = item.boektijdstip;
            if (this.newPrintVersion) {
                this.pakbon = await loadItem(
                    'delivery/loadDeliveryNote',
                    {
                        deliverynoteID: item.pakbonID
                    },
                    this.$t
                );
                datum = DateHelper.MakeDateTime(new Date());
            } else {
                this.count += 1;
            }
            this.isDeliveryNote = false;
            item.replaceTab();
            this.printbon = item;
        }
        this.localItem.eigenadres = Helper.createCompanyAdres(
            this.eigenBedrijf
        );
        this.rerenderKey += 1;
    }
});
</script>

<style scoped></style>
