import { render, staticRenderFns } from "./ActivityLog.vue?vue&type=template&id=47e72a92&scoped=true"
import script from "./ActivityLog.vue?vue&type=script&lang=js"
export * from "./ActivityLog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47e72a92",
  null
  
)

export default component.exports