import Vue from 'vue';
import Router from 'vue-router';

import { Login, CallbackSigninOidc } from './features/account';
import NotFoundPage from './features/errors/NotFoundPage';
import Unauthorized from './features/errors/Unauthorized';
import { NavigationRoutes } from './features/navigation';

Vue.use(Router);

const baseRoutes = [
    {
        path: '/',
        redirect: {
            name: 'login'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/magniet',
        name: 'Unauthorized',
        component: Unauthorized
    },
    {
        path: '/404',
        name: 'page_not_found',
        component: NotFoundPage
    },
    {
        path: '*',
        redirect: {
            name: 'page_not_found'
        }
    },
    {
        path: '/signin-oidc',
        name: 'signin-oidc',
        component: CallbackSigninOidc
    },
    {
        path: '/.well-known/security.txt',
        name: 'securitytxt'
    }
];

const routes = baseRoutes.concat(NavigationRoutes);

export default new Router({
    routes: routes,
    mode: 'history'
});
