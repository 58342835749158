import { Permission } from './Permissions';
import { Feature } from './Features';
import UserGroup from './UserGroup';

export default class Operator {
    /**
     * Assign the data to a new Operator instance
     *
     * @param data
     */
    constructor(data, func) {
        this.id = -1;
        data = data ?? {};
        this.isDeleted = false;
        this.isChanged = false;
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false
            },
            gewijzigd: {
                value: data.updated,
                writable: false
            },
            naam: {
                value: data.displayname ?? data.name,
                writable: true,
                enumerable: true
            },
            mail: {
                value: data.email,
                writable: true,
                enumerable: true
            },
            rechten: {
                type: Array(Permission),
                value: data.permissions
                    ? data.permissions.map((x) => {
                          const data = { id: x };
                          const container = new Permission(data, func);
                          return container;
                      })
                    : [],
                writable: true,
                enumerable: true
            },
            features: {
                type: Array(Feature),
                value: data.features
                    ? data.features.map((x) => new Feature(x))
                    : [],
                writable: true,
                enumerable: true
            },
            groepen: {
                type: Array(UserGroup),
                writable: true,
                enumerable: true
            },
            isAdmin: {
                value: true,
                writable: true
            }
        });
        this.isNew = (this.id == undefined || this.id < 0);
    }

    get login(){
        const s = this.naam;
        const arr = s.split("(");
        if (arr.length<=1) return this.naam;
        const arr2 = arr[1].split(")");
        return arr2[0]=='' ? this.mail : arr2[0];

    }

    SetForSave() {
        this.email = this.mail;
        this.displayName = this.naam;
    }

    listContainsRight(rightToCheck) {
        if (this.rechten != undefined) {
            const right = this.rechten.find((x) => x.id == rightToCheck);
            return right == undefined ? false : true;
        }
        return false;
    }

    listContainsFeature(featureToCheck) {
        if (this.features != undefined) {
            const right = this.features.find((x) => x.id == featureToCheck);
            return right == undefined ? false : true;
        }
        return false;
    }
    

}
