<template>
    <b-modal
        id="modal-scanbarcode"
        ref="modal"
        :title="$t('com_scanner', { ns: 'common' })"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
    >
        <div @submit.stop.prevent="handleSubmit">
            <div v-if="hasUserMedia">
                <stream-barcode-reader
                    @decode="(a, b, c) => onDecode(a, b, c)"
                    @loaded="() => onLoaded()"
                ></stream-barcode-reader>
            </div>
            <div v-else>{{ $t('com_nocamera', { ns: 'common' }) }}</div>

            <b-input-group>
                <b-form-input
                    :placeholder="$t('com_typeanumber', { ns: 'common' })"
                    v-model="textBarcode"
                    autofocus
                ></b-form-input>
                <b-input-group-append>
                    <!--<b-button @click="handleSubmit()" class="bgc_aux_normal"
                        >Ok</b-button
                    >-->
                </b-input-group-append>
            </b-input-group>
        </div>
    </b-modal>
</template>
<script>
import { StreamBarcodeReader } from 'vue-barcode-reader';
export default {
    name: 'ScanBarcode',
    data: function () {
        return {
            errorMessage: '',
            id: null,
            textBarcode: ''
        };
    },
    components: {
        StreamBarcodeReader
    },
    model: {
        prop: 'visible',
        event: 'hide'
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        printArea() {
            return this.isKaas ? 'printKaas' : 'printStock';
        }
    },
    methods: {
        async hasUserMedia() {
            let result = false;
            navigator.mediaDevices
                .getUserMedia({ audio: false, video: true })
                .then((result = true))
                .catch((result = false));
            return result;
        },
        hide() {
            this.opmerking = '';
            this.$emit('hide');
        },
        onDecode(a, b, c) {
            console.log(a, b, c);
            this.textBarcode = a;
            if (this.id) clearTimeout(this.id);
            this.id = setTimeout(() => {
                if (this.text === a) {
                    this.text = '';
                }
            }, 5000);
            this.handleSubmit();
        },
        onLoaded() {
            console.log('load');
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.textBarcode == undefined) {
                return;
            }
            this.$emit('result', this.textBarcode);
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-scanbarcode');
            });
        }
    },
    async mounted() {},
    created() {}
};
</script>

<style scoped></style>

