<template>
    <div>
        {{
            $t('com_xnotfound', {
                ns: 'common',
                val: $t('com_page', {
                    ns: 'common'
                })
            })
        }}!
    </div>
</template>

<script>
export default {
    name: 'NotFoundPage'
};
</script>

<style scoped></style>
