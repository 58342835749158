<template>
    <b-container fluid style="margin-top: 60px">
        <h1>{{ formtitle }}</h1>
        <b-row>
            <b-col sm="8">
                <period-selector
                    v-model="selectDays"
                    v-bind:periodDays="periodeDagen"
                    v-bind:slackTillDate="slackDagen"
                    @change="setNewDates"
                />
            </b-col>
            <b-col sm="3">
                <b-row>
                    <b-col sm="11">
                        <b-button
                            @click="loadData()"
                            class="bgc_aux_normal"
                            v-b-tooltip.hover
                            :title="$t('com_showdata', { ns: 'common' })"
                            ><font-awesome-icon icon="arrow-down" size="1x" />
                        </b-button>
                    </b-col>
                    <b-col sm="1"> </b-col>
                </b-row>
            </b-col>
            <b-col sm="1">
                <b-row class="float-right">
                    <b-col sm="11"> </b-col>
                    <b-col sm="1"> </b-col>
                </b-row>
            </b-col>
        </b-row>

        <div slot="pdfdata">
            <b-row no-gutters>
                <b-col sm="12">
                    <b-col lg="6" class="my-1">
                        <b-form-group
                            :label="$t('com_filter', { ns: 'common' })"
                            label-for="filter-input"
                            label-cols-sm="3"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-0"
                        >
                            <b-input-group size="sm">
                                <b-form-input
                                    id="filter-input"
                                    v-model="filter"
                                    type="search"
                                    :placeholder="
                                        $t('com_typetosearch', { ns: 'common' })
                                    "
                                ></b-form-input>

                                <b-input-group-append>
                                    <b-button
                                        :disabled="!filter"
                                        @click="filter = ''"
                                        >{{
                                            $t('com_wis', { ns: 'common' })
                                        }}</b-button
                                    >
                                </b-input-group-append>
                            </b-input-group>

                            <b-dropdown
                                id="dropdown-form"
                                :text="
                                    $t('com_newx', {
                                        ns: 'common',
                                        val: $t('com_actie', { ns: 'common' })
                                    })
                                "
                                ref="dropdown"
                                size="sm"
                            >
                                <b-dropdown-form style="width: 340px">
                                    <b-form-group
                                        :label="
                                            $t('prod_voorobject', {
                                                ns: 'production',
                                                val: $t('prod_object', {
                                                    ns: 'production'
                                                }).toLowerCase()
                                            })
                                        "
                                        label-for="dropdown-form"
                                        @submit.stop.prevent
                                    >
                                        <b-form-radio-group
                                            v-model="selectedRadio"
                                            :options="optionsRadio"
                                            value-field="item"
                                            text-field="name"
                                        ></b-form-radio-group>
                                    </b-form-group>
                                    <b-button
                                        v-if="
                                            checkRight(
                                                'MaintenanceCleaningCreate'
                                            )
                                        "
                                        size="sm"
                                        @click="createNewItem"
                                        class="mr-1"
                                        ><font-awesome-icon
                                            icon="folder-plus"
                                            size="1x"
                                        />
                                        {{
                                            $t('prod_toevoegen', {
                                                ns: 'production'
                                            })
                                        }}
                                    </b-button>
                                </b-dropdown-form>
                            </b-dropdown>
                        </b-form-group>
                    </b-col>

                    <b-col lg="6" class="my-1">
                        <b-form-group
                            :label="$t('com_perpagina', { ns: 'common' })"
                            label-for="per-page-select"
                            label-cols-sm="6"
                            label-cols-md="4"
                            label-cols-lg="3"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-0"
                        >
                            <b-form-select
                                id="per-page-select"
                                v-model="perPage"
                                :options="pageOptions"
                                size="sm"
                            ></b-form-select>
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                align="fill"
                                size="sm"
                                class="my-0"
                            ></b-pagination>
                        </b-form-group>
                    </b-col>
                    <b-table
                        :items="groupedDisplayList"
                        :fields="visibleFields"
                        :current-page="currentPage"
                        :per-page="perPage"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        stacked="md"
                        show-empty
                        :empty-text="$t('com_geen_data', { ns: 'common' })"
                        small
                        striped
                        @filtered="onFiltered"
                        @row-clicked="toonDetail"
                    >
                        <template v-slot:cell(actions)="row">
                            <div class="form-inline">
                                <b-button
                                    size="sm"
                                    @click="toonItem(row.item)"
                                    class="bgc_aux_warning"
                                    ><font-awesome-icon
                                        icon="search"
                                        size="1x"
                                    />
                                </b-button>
                                <b-button
                                    v-if="
                                        checkRight('MaintenanceCleaningDelete')
                                    "
                                    size="sm"
                                    @click="verwijderItem(row.item)"
                                    class="bgc_aux_alarm"
                                    ><font-awesome-icon
                                        icon="trash-alt"
                                        size="1x"
                                    />
                                </b-button>
                                <b-button
                                    size="sm"
                                    @click="toonDetail(row.item)"
                                    class="bgc_aux_unknown"
                                    ><font-awesome-icon
                                        icon="info-circle"
                                        size="1x"
                                    />
                                </b-button>
                            </div>
                        </template>

                        <template v-slot:cell(herhalingIDs)="row">
                            <font-awesome-icon
                                v-if="row.item.actief > 0"
                                icon="check"
                                class="tc_aux_checked"
                                size="1x"
                            />
                            <font-awesome-icon
                                v-if="row.item.actief == 0 && row.item.herhalingIDs > 0"
                                icon="check"
                                class="tc_aux_unimportant "
                                size="1x"
                            />
                        </template>

                        <template v-slot:row-details>
                            <b-card no-body class="mb-1">
                                &nbsp;
                                <b-table
                                    striped
                                    responsive
                                    :fields="detailFields"
                                    :items="localActivities"
                                    primary-key
                                >
                                </b-table>
                            </b-card>
                        </template>
                    </b-table>
                    <div v-if="mLijst.length == 0">
                        {{ strNoData }}
                    </div>
                </b-col>
            </b-row>
        </div>
        <maintenance-pop-form
            v-if="detailsVisible"
            :displayNumber="selectedRadio"
            v-model="detailsVisible"
            v-bind:editAllowed="allowEdit"
            v-bind:inItem="reiniging"
            @hide="reset"
            @reiniging_refresh="loadData"
        />
    </b-container>
</template>

<script>
import _ from 'lodash';
import PeriodSelector from '../components/PeriodSelector.vue';
import MaintenancePopForm from './MaintenancePopForm.vue';
import DateHelper from '../../models/DateHelper';
import Helper from '../../models/Helper';
import { Onderhoud } from '../../models/Onderhoud';
import { loadItem, loadList } from '../../models/DataHelper';
import { Permission } from '../../models/Permissions';

export default {
    name: 'MaintenanceBase',
    data: function () {
        return {
            selectedRadio: 'silo',
            optionsRadio: [
                {
                    item: 'ost',
                    name: this.$t('prod_ost', { ns: 'production' })
                },
                {
                    item: 'loc',
                    name: this.$t('com_location', { ns: 'common' })
                },
                {
                    item: 'silo',
                    name: this.$t('prod_silo', { ns: 'production' })
                },
                {
                    item: 'eq',
                    name: this.$t('prod_apparaat', { ns: 'production' })
                }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [10, 20, 40, { value: 100, text: '100' }],
            filter: null,
            filterOn: [],
            slackDagen: 0,
            van: null,
            tot: null,
            allowEdit: false,
            maintenanceList: [],
            reiniging: null,
            detailsVisible: false,
            localActivities: [],
            mLijst: [],
            objectNaam: '',
            aangeraakt_teller: 1,
            selectDays: null,
            periodeDagen: 60
        };
    },
    components: {
        PeriodSelector,
        MaintenancePopForm
    },
    computed: {
        formtitle() {
            return Helper.stringformat(
                '{0} / {1}',
                this.$t('prod_onderhoud', {
                    ns: 'production'
                }),
                this.$t('prod_reiniging', {
                    ns: 'production'
                }).toLowerCase()
            );
        },
        strNoData() {
            return Helper.stringformat(
                this.$t('prod_nomaintenancedata', {
                    ns: 'production'
                }),
                this.van,
                this.tot
            );
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                {
                    key: 'tijd',
                    label: this.$t('com_tijdstip', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'naam',
                    label: this.$t('prod_object', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'activities.length',
                    label: this.$t('com_number', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'herhalingIDs',
                    label: this.$t('rec_pattern', { ns: 'recurrency' }),
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        detailFields() {
            const fieldsInit = [
                { key: 'id', label: 'Id', sortable: false, visible: false },
                {
                    key: 'typeTekst',
                    label: this.$t('prod_type', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'activiteitTekst',
                    label: this.$t('prod_activity', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'opmerking',
                    label: this.$t('com_opmerking', { ns: 'common' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        groupedDisplayList() {
            // eslint-disable-next-line no-unused-vars
            const veranderd = this.aangeraakt_teller;
            if (this.mLijst == undefined) return null;
            const x = _(this.mLijst.slice(0))
                .groupBy((x) => `"${x.tijdstip}+${x.locatie}"`)
                // eslint-disable-next-line no-unused-vars
                .map((value, key) => {
                    const maid = value[0].id;
                    const locatie = value[0].locatie;
                    const oritijd = value[0].tijdstip;
                    const tijd = DateHelper.convertedToDateTimeShort(
                        value[0].tijdstip
                    );
                    return {
                        naam: locatie,
                        tijd: tijd,
                        oritijd: oritijd,
                        aantal: value.length,
                        id: maid,
                        activities: value,
                        herhalingIDs: value.reduce(
                            (accumulator, x) => accumulator + x.herhalingID ?? 0,
                            0
                        ),
                        actief: value.reduce(
                            (accumulator, x) => accumulator + x.herhalingActief ?? 0,
                            0
                        )
                    };
                })
                .value();
            return x;
        }
    },
    methods: {
        reset() {
            this.detailsVisible = false;
        },
        async toonDetail(row) {
            if (row._showDetails) {
                this.$set(row, '_showDetails', false);
            } else {
                this.groupedDisplayList.forEach((row) => {
                    this.$set(row, '_showDetails', false);
                });
                this.localActivities = row.item
                    ? row.item.activities
                    : row.activities;
                this.$set(row, '_showDetails', true);
            }
        },
        async toonItem(item) {
            this.allowEdit = false;
            // zoek de individuele regels
            const lijst = this.mLijst.filter(
                (x) => x.tijdstip === item.oritijd
            );
            for (let i = 0; i < lijst.length; i++) {
                const element = lijst[i];
                if (element.herhalingID > 0) {
                    const rp = await loadItem(
                        'recurrency/loadRecurrencyPattern',
                        { rpID: element.herhalingID },
                        this.$t
                    );
                    element.herhaling = rp;
                }
            }
            this.maintenanceList = lijst;
            this.reiniging = await loadItem(
                'maintenance/loadMaintenance',
                { maintenanceID: lijst[0].id },
                this.$t
            );
            this.reiniging.editAllowed = false;
            this.detailsVisible = !this.detailsVisible;
            this.localActivities = item.activities;
        },
        async createNewItem() {
            this.allowEdit = true;
            this.maintenanceList = [];
            this.reiniging = null;
            this.detailsVisible = !this.detailsVisible;
        },
        async verwijderItem(item) {
            const doClose = await this.$root.showConfirmationBox(
                this.$t('com_delete', {
                    ns: 'common',
                    val: this.$t('prod_reinigingsitem', {
                        ns: 'production'
                    }).toLowerCase()
                }) +
                    ' : ' +
                    item.tijd
            );
            if (doClose) {
                await loadItem(
                    'maintenance/deleteMaintenance',
                    {
                        maintenanceID: item.id
                    },
                    this.$t
                );
            }
            this.loadData();
        },
        setNewDates(selDates) {
            this.van = selDates.van;
            this.tot = selDates.tot;
            this.loadData();
        },
        async loadData() {
            const selectionData = {
                from: DateHelper.localDateToUTC(this.van),
                until: DateHelper.localDateToUTC(this.tot)
            };
            this.mLijst = await loadList(
                'maintenance/getMaintenanceLijst',
                'maintenance/loadMaintenanceOverPeriod',
                selectionData,
                true,
                this.$t
            );
            this.totalRows = this.mLijst ? this.mLijst.length : 0;
            this.aangeraakt_teller += 1;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        }
    },
    mounted: function () {
        this.loadData();
    },
    created() {
        // laad de activiteiten en types als al niet gedaan
    }
};
</script>

<style scoped></style>
