export default class TechCalcHelper {
    static calcWWWtemperature(
        milk,
        rwwperc,
        waswaterperc,
        kruidenlist,
        tStrem,
        tNawarm
    ) {
        // aanname hierin is dat de warmteinhoud van weiwrongel gelijk is aan die van water
        let result = 'berekening niet mogelijk';
        let kruidWaterTotaal = 0; // Som(lKruidWater)
        let warmteKruidWater = 0; //Som(lKruidwater * tempKruidWater)
        for (let i = 0; i < kruidenlist.length; i++) {
            const kwt = kruidenlist[i];
            kruidWaterTotaal += kwt.LitersKruidWater;
            warmteKruidWater += kwt.LitersKruidWater * kwt.TempertuurKruidWater;
        }
        const lWeiWrongelRest = (milk * rwwperc) / 100;
        const lWater = (lWeiWrongelRest * waswaterperc) / 100;
        if (lWater > 0) {
            result =
                ((lWater + lWeiWrongelRest + kruidWaterTotaal) * tNawarm -
                    lWeiWrongelRest * tStrem -
                    warmteKruidWater) /
                lWater;
        }

        return result;
    }

    // todo berekening klopt niet
    static calcWWWtemperature2a(
        milk,
        rwwperc,
        waswaterperc,
        rwwperc2a,
        waswaterperc2a,
        kruidenlist,
        tStrem,
        tNawarm,
        tTussen
    ) {
        // aanname hierin is dat de warmteinhoud van weiwrongel gelijk is aan die van water
        let result = 'berekening niet mogelijk';
        let kruidWaterTotaal = 0; // Som(lKruidWater)
        let warmteKruidWater = 0; //Som(lKruidwater * tempKruidWater)
        for (let i = 0; i < kruidenlist.length; i++) {
            const kwt = kruidenlist[i];
            kruidWaterTotaal += kwt.LitersKruidWater;
            warmteKruidWater += kwt.LitersKruidWater * kwt.TempertuurKruidWater;
        }
        const lWeiWrongelRest = (milk * rwwperc) / 100;
        const lWater = (lWeiWrongelRest * waswaterperc) / 100;
        let lwei2a = 0;
        let lWater2a = 0;
        let lWeiWrongelRest2a = 0;
        if (
            rwwperc2a != undefined &&
            rwwperc2a > 0 &&
            tTussen != undefined &&
            tTussen > 0
        ) {
            lWeiWrongelRest2a = ((lWeiWrongelRest + lWater) * rwwperc2a) / 100;
            lwei2a = lWeiWrongelRest + lWater - lWeiWrongelRest2a;
            lWater2a = (lWeiWrongelRest2a * waswaterperc2a) / 100;
        }

        if (lWater > 0) {
            result =
                // (lWater + lWeiWrongelRest2a + kruidWaterTotaal) * tNawarm -
                // lWeiWrongelRest * tStrem -
                // warmteKruidWater -
                // (lwei2a * tTussen) / (lWater + lWater2a);
                (((((((lWater2a + lWeiWrongelRest2a + kruidWaterTotaal) * tNawarm) -
                (lWeiWrongelRest2a * tTussen) - warmteKruidWater ) / lWater2a) * lWater) + 
                (lWeiWrongelRest * tStrem)) / (tTussen))-lWater-lWeiWrongelRest;
        }

        return result;
    }
}

