const mutations = {

    insertTreatmentRecipeLijst(state, lijst) {
        state.treatmentRecipeLijst = [];
        if (lijst != undefined) {
            state.treatmentRecipeLijst = lijst;
        }
    },
    insertTACLijst(state, lijst) {
        state.treatmentActivityLijst = [];
        if (lijst != undefined) {
            state.treatmentActivityLijst = lijst;
        }
    },
    insertTACStatusLijst(state, lijst) {
        state.tacStatusLijst = [];
        if (lijst != undefined) {
            state.tacStatusLijst = lijst;
        }
    },
    insertTreatmentStepLijst(state, lijst) {
        state.treatStepLijst = [];
        if (lijst != undefined) {
            state.treatStepLijst = lijst;
        }
    },
    insertUitbehandeldLijst(state, lijst) {
        state.uitbehandeldLijst = [];
        if (lijst != undefined) {
            state.uitbehandeldLijst = lijst;
        }
    },
};

export default mutations;
