var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{attrs:{"no-body":"","header":_vm.$t('prod_kaasmerkgeg', { ns: 'production' })}},[_c('div',[_c('div',{staticClass:"form-inline"},[_c('b-button',{attrs:{"aria-expanded":_vm.gebruikKaasmerk ? 'true' : 'false',"aria-controls":"collapseMerk","disabled":!_vm.editAllowed},on:{"click":function($event){_vm.gebruikKaasmerk = !_vm.gebruikKaasmerk}}},[_vm._v(_vm._s(_vm.$t('prod_usemarks', { ns: 'production' })))]),_vm._v("  "),_c('b-form-checkbox',{attrs:{"id":"cbSubseries","disabled":!_vm.editAllowed},on:{"change":function($event){return _vm.setChangedSubSeries()}},model:{value:(_vm.showSubseries),callback:function ($$v) {_vm.showSubseries=$$v},expression:"showSubseries"}},[_vm._v(" "+_vm._s(_vm.$t('prod_subseries', { ns: 'production' }))+" ")])],1),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapseMerk"},model:{value:(_vm.gebruikKaasmerk),callback:function ($$v) {_vm.gebruikKaasmerk=$$v},expression:"gebruikKaasmerk"}},[_c('b-card',[(_vm.showSubseries)?_c('b-row',_vm._l((_vm.ucmList),function(ucm,index){return _c('div',{key:index,staticClass:"form-inline"},[_c('b-col',{staticClass:"mx-0 px-0 my-1 py-0",attrs:{"sm":"12"}},[_c('merken-combo',{key:index,attrs:{"editAllowed":_vm.editAllowed,"cheeseProductID":ucm.productID},on:{"change":_vm.setChanged,"delete":_vm.verwijderItem,"copy":_vm.copyItem,"input":_vm.check_doubles,"validComponent":_vm.setMarksValid},model:{value:(_vm.ucmList[index]),callback:function ($$v) {_vm.$set(_vm.ucmList, index, $$v)},expression:"ucmList[index]"}})],1)],1)}),0):_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('prod_baseamountmarks', { ns: 'production' }))+": ")])]),_c('b-col',{attrs:{"sm":"4"}}),_c('b-col',{attrs:{"sm":"5"}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('com_number', {
                                            ns: 'common'
                                        }),"type":"number","step":"1","min":"0","max":"3000","disabled":!_vm.editAllowed},on:{"change":_vm.set_Eindmerk},model:{value:(_vm.aantalKazen),callback:function ($$v) {_vm.aantalKazen=_vm._n($$v)},expression:"aantalKazen"}})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('label',[_vm._v(_vm._s(_vm.$t('prod_startmerk', { ns: 'production' }))+": ")])]),_c('b-col',{attrs:{"sm":"4"}},[(_vm.editKaasmerk)?_c('b-form-select',{attrs:{"options":_vm.flexList,"disabled":!_vm.editAllowed},on:{"change":_vm.set_inputStartLetter},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":""}},[_vm._v("-- "+_vm._s(_vm.$t('com_selecteer', { ns: 'common' }))+" --")])]},proxy:true}],null,false,2446721565),model:{value:(_vm.startStockID),callback:function ($$v) {_vm.startStockID=$$v},expression:"startStockID"}}):_vm._e(),(!_vm.editKaasmerk)?_c('b-form-input',{attrs:{"placeholder":"-","disabled":!_vm.editAllowed},model:{value:(_vm.startLetter),callback:function ($$v) {_vm.startLetter=$$v},expression:"startLetter"}}):_vm._e()],1),_c('b-col',{attrs:{"sm":"5"}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('prod_startnummer', {
                                            ns: 'production'
                                        }),"type":"number","step":"1","disabled":!_vm.editAllowed},on:{"change":_vm.set_inputNummer},model:{value:(_vm.startNummer),callback:function ($$v) {_vm.startNummer=_vm._n($$v)},expression:"startNummer"}})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('label',[_vm._v(_vm._s(_vm.$t('prod_eindmerk', { ns: 'production' }))+": ")])]),_c('b-col',{attrs:{"sm":"4"}},[(_vm.editKaasmerk)?_c('b-form-select',{attrs:{"options":_vm.flexList,"disabled":!_vm.editAllowed},on:{"change":_vm.set_inputEindLetter},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":""}},[_vm._v("-- "+_vm._s(_vm.$t('com_selecteer', { ns: 'common' }))+" --")])]},proxy:true}],null,false,2446721565),model:{value:(_vm.eindStockID),callback:function ($$v) {_vm.eindStockID=$$v},expression:"eindStockID"}}):_vm._e(),(!_vm.editKaasmerk)?_c('b-form-input',{attrs:{"placeholder":"-","disabled":!_vm.editAllowed},model:{value:(_vm.eindLetter),callback:function ($$v) {_vm.eindLetter=$$v},expression:"eindLetter"}}):_vm._e()],1),_c('b-col',{attrs:{"sm":"5"}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('prod_eindnummer', {
                                            ns: 'production'
                                        }),"type":"number","step":"1","disabled":!_vm.editAllowed},on:{"change":_vm.set_inputNummer},model:{value:(_vm.eindNummer),callback:function ($$v) {_vm.eindNummer=_vm._n($$v)},expression:"eindNummer"}})],1)],1)],1),_c('b-alert',{attrs:{"variant":"danger","dismissible":""},model:{value:(_vm.showDismissibleAlert),callback:function ($$v) {_vm.showDismissibleAlert=$$v},expression:"showDismissibleAlert"}},[_vm._v(" "+_vm._s(_vm.alertText)+" ")]),_c('b-alert',{attrs:{"variant":"warning","dismissible":""},model:{value:(_vm.showWarningAlert),callback:function ($$v) {_vm.showWarningAlert=$$v},expression:"showWarningAlert"}},[_vm._v(" "+_vm._s(_vm.reminder)+" ")])],1)],1)],1),_c('b-alert',{attrs:{"variant":"danger","dismissible":""},model:{value:(_vm.showNoStockAlert),callback:function ($$v) {_vm.showNoStockAlert=$$v},expression:"showNoStockAlert"}},[_vm._v(" "+_vm._s(_vm.alertNoStock)+" ")]),_c('b-card',[_c('b-row',[_c('b-col',{staticClass:"mx-0 px-0 my-1 py-0",attrs:{"sm":"9"}},[_c('b-row',{staticClass:"mx-0 px-0 my-1 py-0"},[_c('b-col',{staticClass:"mx-0 px-0 my-1 py-0",attrs:{"sm":"3"}},[_c('label',[_vm._v(_vm._s(_vm.$t('prod_xprogramma', { ns: 'production', val: _vm.$t('prod_pers', { ns: 'production' }) }))+": ")])]),_c('b-col',{staticClass:"mx-0 px-0 my-1 py-0",attrs:{"sm":"9"}},[_c('b-form-input',{attrs:{"id":"input-program","placeholder":_vm.$t('prod_xprogramma', {
                                        ns: 'production',
                                        val: _vm.$t('prod_pers', {
                                            ns: 'production'
                                        })
                                    }),"disabled":!_vm.editAllowed},on:{"change":_vm.set_inputProgramma},model:{value:(_vm.programma),callback:function ($$v) {_vm.programma=$$v},expression:"programma"}})],1)],1)],1),_c('b-col',{staticClass:"mx-0 px-0 my-1 py-0",attrs:{"sm":"3"}},[_c('b-button',{staticClass:"bgc_aux_warning float-right",attrs:{"id":"myDefault","disabled":!_vm.editAllowed},on:{"click":function($event){return _vm.SetDefaults()}}},[_c('font-awesome-icon',{attrs:{"icon":"edit","size":"1x"}}),_vm._v(" "+_vm._s(_vm.$t('com_default', { ns: 'common' }))+" ")],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }