<template>
    <div>U bent niet bevoegd!</div>
</template>

<script>
export default {
    name: 'Unauthorized'
};
</script>

<style scoped>
</style>