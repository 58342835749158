const state = {
    voorraadInfoList: [],
    voorraadInfoListArchived: [],
    voorraadItemLijst: [],
    voorraadMarkInfoList: [],
    voorraadMarkInfoListArchived: [],
    voorraadMarkItemLijst: [],
    selectedVoorraadItem: null,
    selectedVoorraadItemInfo: null,
    stockLocationTypesLijst: [],
    stockItemMarksInfoLijst:[]
};

export default state;
