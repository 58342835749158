/* eslint-disable no-console */
export default function logMessage(message, level) {
    // ?: 'info' | 'warn' | 'error'
    // WHEN RUNNING WEBPACK WITH `PRODUCTION` build,
    // IT WILL REMOVE FOLLWOING CODE.

    if (window.APP_ENV == 'test') {
        if (level === 'error') {
            const mes = getMessage(message);
            console.error(mes);
        } else if (level === 'warn') {
            console.warn(message);
        } else {
            console.log(message);
        }
    }
    return message;
}

const getMessage = (err) => {
    let message = '';
    if (err.response) {
        if (err.response.data.message != undefined) {
            message = 'foutbericht:  ' + err.response.data.message;
        } else {
            if (err.response.data.error != undefined) {
                message = 'ongeldige actie: ' + err.response.data.error;
            } else {
                message = 'onvewachtte fout: ' + err.message ?? err.response.data;
            }
        }
    } else {
        message = 'onbekende fout: ' +  JSON.stringify(err);
    }
    return message;
};