<template>
    <div class="form-inline">
        <b-col sm="5" class="mx-0 px-0 my-0 py-0">
            <b-input-group :prepend="$t('com_categorie', { ns: 'common' })">
                <b-form-select
                    v-model="category"
                    :options="categoryList"
                    :disabled="disabled"
                    @change="set_inputCategory"
                >
                    <template #first>
                        <b-form-select-option value=""
                            >--
                            {{
                                $t('com_selecteer', {
                                    ns: 'common'
                                })
                            }}
                            --</b-form-select-option
                        >
                    </template>
                </b-form-select>
            </b-input-group>
        </b-col>

        <b-col sm="7" class="mx-0 px-0 my-0 py-0">
            <b-input-group
                :prepend="$t('prod_ingredient', { ns: 'production' })"
            >
                <b-form-select
                    v-model="productID"
                    :options="productSubList"
                    :disabled="disabled"
                    @change="set_input"
                >
                    <template #first>
                        <b-form-select-option value=""
                            >--
                            {{
                                $t('com_selecteer', {
                                    ns: 'common'
                                })
                            }}
                            --</b-form-select-option
                        >
                    </template>
                </b-form-select>
            </b-input-group>
        </b-col>
    </div>
</template>
<script>
import Helper from '../../models/Helper';
import { loadList } from '../../models/DataHelper';
export default {
    name: 'ProductSelector',
    data: function () {
        return {
            category: '',
            productID: -1,
            productList: [],
            productSubList: []
        };
    },
    model: {
        prop: 'selected',
        event: 'input'
    },
    props: {
        selected: {
            type: Number,
            required: true
        },
        discrim: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    components: {},
    watch: {},
    computed: {
        categoryList() {
            const lijstDistinct = Helper.distinctList(
                this.productList,
                'categorie'
            );
            const lijst = lijstDistinct.map((x) => ({
                value: x.categorie,
                text: x.categorie
            }));
            if (lijst.length == 1) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.category = lijst[0].value;
                this.set_inputCategory(lijst[0].value);
            }
            return lijst;
        }
    },
    methods: {
        set_input(anid) {
            this.productID = anid;
            this.$emit('change', true);
            this.$emit('input', this.productID);
        },
        set_inputCategory(cat) {
            const lijst = this.productList.filter((x) => x.categorie == cat);
            this.productSubList = lijst.map((x) => ({
                value: x.value,
                text: x.text
            }));
            if (this.productSubList.length == 1) {
                this.set_input(this.productSubList[0].value);
            }
        }
    },
    mounted() {
        const productIN = this.productList.find((x) => x.value == this.selected);
        if (productIN) {
                this.category = productIN.categorie;
                this.set_inputCategory(productIN.categorie);
        }
        this.productID = this.selected;
    },
    async created() {
        const lijst = await loadList(
            'components/getProductsComboLijst',
            'components/loadProductsComboLijst',
            null,
            true,
            this.$t
        );
        if (this.discrim) {
            if (this.discrim.indexOf('!') < 0) {
                this.productList = lijst.filter((c) => {
                    return c.catid == this.discrim;
                });
            } else {
                const loc = this.discrim.replace('!', '');
                this.productList = lijst.filter((c) => {
                    return c.catid != loc;
                });
            }
        } else {
            this.productList = lijst;
        }
        const productIN = lijst.find((x) => x.value == this.selected);
        if (productIN) {
                this.category = productIN.categorie;
                this.set_inputCategory(productIN.categorie);
        }
        this.productID = this.selected;
    }
};
</script>

<style scoped></style>
