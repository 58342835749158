<template>
    <b-card>
        <b-tabs card>
            <b-tab :title="$t('an_result', { ns: 'analytics' })">
                <b-card-text>
                    <b-row>
                        <b-col sm="6">
                            <b-row>
                                <b-col sm="3">
                                    <label for="dateOnderzoek"
                                        >{{
                                            $t('an_meetdatum', {
                                                ns: 'analytics'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <analytics-date-time
                                        id="dateOnderzoek"
                                        v-model="datumOnderzoek"
                                        v-bind:editAllowed="editAllowed"
                                    >
                                    </analytics-date-time>
                                </b-col>
                                <b-col sm="3">
                                    <label for="dateBoeking"
                                        >{{
                                            $t('an_plandatum', {
                                                ns: 'analytics'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <analytics-date-time
                                        id="dateBoeking"
                                        v-model="datumBoeking"
                                        v-bind:editAllowed="editAllowed"
                                    >
                                    </analytics-date-time>
                                </b-col>
                                <b-col sm="3">
                                    <label for="input-itemnaam"
                                        >{{
                                            $t('an_subject', {
                                                ns: 'analytics'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <entity-object-selector
                                        v-model="localItem.meetplekID"
                                        :editAllowed="editAllowed"
                                    ></entity-object-selector>
                                </b-col>

                                <b-col sm="3">
                                    <label
                                        >{{
                                            $t('an_uitvoerder', {
                                                ns: 'analytics'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <lookup-combo
                                        v-model="localItem.uitvoerderID"
                                        v-bind:combotype="lctypeExecutor"
                                        :disabled="!editAllowed"
                                    ></lookup-combo>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col sm="6">
                            <b-row>
                                <b-col sm="3">
                                    <label
                                        >{{
                                            $t('an_test', { ns: 'analytics' })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <assesment-combo
                                        v-model="locAssesmentObject"
                                        v-bind:editAllowed="editAllowed"
                                    ></assesment-combo>
                                </b-col>
                                <b-form-textarea
                                    v-model="localItem.opmerking"
                                    :placeholder="
                                        $t('com_opmerking', { ns: 'common' })
                                    "
                                    rows="3"
                                    max-rows="6"
                                    :disabled="!editAllowed"
                                ></b-form-textarea>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-tab>
            <activity-log
                paramName="assesmentid"
                action="analytics/loadAssesmentActiviteitenLijst"
                :id="localItem.id"
            />
        </b-tabs>
    </b-card>
</template>

<script>
import { AssesmentObject, Assesment } from '../../models/Anayltics';
import LookupCombo from './LookupCombo.vue';
import ActivityLog from '../components/ActivityLog.vue';
import EntityObjectSelector from './EntityObjectSelector.vue';
import AnalyticsDateTime from './AnalyticsDateTime.vue';
import AssesmentCombo from './AssesmentCombo.vue';
export default {
    name: 'AssesmentInfo',
    data: function () {
        return {
            lctypeExecutor: window.constants.LCA_EXECUTOR,
            lctypeQunit: window.constants.LCA_QUNIT,
            lctypeIQ: window.constants.LCA_INVQUANTITY,
            isChanged: false,
            naam: null,
            locItem: null,
            locAssesmentObject: null,
            returnObject: null,
            editAllowed: false,
            datumBoeking: null,
            datumOnderzoek: null
        };
    },
    model: {
        prop: 'visible',
        event: 'changed'
    },
    props: {
        inItem: {
            type: Assesment,
            required: false
        }
    },
    components: {
        LookupCombo,
        AssesmentCombo,
        ActivityLog,
        EntityObjectSelector,
        AnalyticsDateTime
    },
    watch: {},
    computed: {
        localItem() {
            return this.locItem;
        }
    },
    methods: {
        setDatumOnderzoek(value) {
            this.datumOnderzoek = value;
            this.setChanged();
        },
        setDatumBoeking(value) {
            this.datumBoeking = value;
            this.setChanged();
        },
        setChanged() {
            this.isChanged = true;
        },
        setAssesment(item) {
            if (item) {
                this.localItem.linkedlabels = item.linkedlabels;
                this.localItem.grootheidID = item.qualityid;
                this.localItem.labelID = item.qlabelid;
                this.localItem.waarde = item.uitslag;
            }
        }
    },
    mounted() {},
    created() {
        if (this.inItem) {
            this.locItem = this.inItem;
            this.datumOnderzoek = this.locItem.meettijdstip;
            this.datumBoeking = this.locItem.boektijdstip;
            this.locAssesmentObject = new AssesmentObject();
            this.locAssesmentObject.linkedlabels = this.locItem.linkedlabels;
            this.locAssesmentObject.qualityid = this.locItem.grootheidID;
            this.locAssesmentObject.qlabelid = this.locItem.labelID;
            this.locAssesmentObject.uitslag = this.locItem.waarde;
            this.naam = this.locItem.naam;
        }
    }
};
</script>

<style scoped></style>

