<template>
    <b-container fluid style="margin-top: 60px">
        <h1 v-on:click="flipTesten">
            {{ title }} &nbsp;
            <b-button>
                {{ btnTekst }}
            </b-button>
        </h1>
        <b-row>
            <b-col sm="8">
                <period-selector
                    v-model="selectDays"
                    v-bind:periodDays="periodeDagen"
                    v-bind:slackTillDate="slackDagen"
                    @change="setNewDates"
                />
            </b-col>
            <b-col sm="3">
                <b-row>
                    <b-col sm="11">
                        <b-button
                            @click="loadData()"
                            class="bgc_aux_normal"
                            v-b-tooltip.hover
                            :title="$t('com_showdata', { ns: 'common' })"
                            ><font-awesome-icon icon="arrow-down" size="1x" />
                        </b-button>
                        &nbsp;
                        <vue-json-to-csv
                            v-if="dataLoaded"
                            :json-data="displayListForCSV"
                            :csv-title="reportFileName"
                            :separator="';'"
                        >
                            <b-button
                                class="bgc_aux_normal"
                                v-b-tooltip.hover
                                :title="
                                    $t('com_downloadas', {
                                        ns: 'common',
                                        val: 'CSV'
                                    })
                                "
                            >
                                <font-awesome-icon icon="file-csv" size="1x" />
                            </b-button>
                        </vue-json-to-csv>
                        &nbsp;
                        <b-button
                            v-if="dataLoaded"
                            @click="loadGraph()"
                            class="bgc_aux_unknown"
                            v-b-tooltip.hover
                            :title="$t('com_grafiek', { ns: 'common' })"
                            ><font-awesome-icon icon="chart-line" size="1x" />
                        </b-button>
                    </b-col>
                    <b-col sm="1"> </b-col>
                </b-row>
            </b-col>
            <b-col sm="1">
                <b-row class="float-right">
                    <b-col sm="11"> </b-col>
                    <b-col sm="1"> </b-col>
                </b-row>
            </b-col>
        </b-row>

        <div slot="pdfdata">
            <b-row no-gutters>
                <b-col sm="12">
                    <b-col lg="6" class="my-1">
                        <b-form-group
                            :label="$t('com_filter', { ns: 'common' })"
                            label-for="filter-input"
                            label-cols-sm="3"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-0"
                        >
                            <b-input-group size="sm">
                                <b-form-input
                                    id="filter-input"
                                    v-model="filter"
                                    type="search"
                                    :placeholder="
                                        $t('com_typetosearch', { ns: 'common' })
                                    "
                                ></b-form-input>

                                <b-input-group-append>
                                    <b-button
                                        :disabled="!filter"
                                        @click="filter = ''"
                                        >{{
                                            $t('com_wis', { ns: 'common' })
                                        }}</b-button
                                    >
                                </b-input-group-append>
                            </b-input-group>
                            <b-button
                                v-if="
                                    allowEdit &&
                                    checkRight('AnalyticsMeasurementCreate')
                                "
                                size="sm"
                                @click="createNewItem()"
                                class="bgc_aux_normal"
                                ><font-awesome-icon
                                    icon="folder-plus"
                                    size="1x"
                                />
                            </b-button>

                            &nbsp;

                            <b-button
                                v-if="checkRight('AnalyticsMeasurementCreate')"
                                size="sm"
                                @click="scan()"
                                class="bgc_aux_unimportant"
                                ><font-awesome-icon icon="barcode" size="1x" />
                            </b-button>
                        </b-form-group>
                    </b-col>

                    <b-col lg="6" class="my-1">
                        <b-form-group
                            :label="$t('com_perpagina', { ns: 'common' })"
                            label-for="per-page-select"
                            label-cols-sm="6"
                            label-cols-md="4"
                            label-cols-lg="3"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-0"
                        >
                            <b-form-select
                                id="per-page-select"
                                v-model="perPage"
                                :options="pageOptions"
                                size="sm"
                            ></b-form-select>
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                align="fill"
                                size="sm"
                                class="my-0"
                            ></b-pagination>
                        </b-form-group>
                    </b-col>
                    <b-table
                        :items="displayList"
                        :fields="visibleFields"
                        :current-page="currentPage"
                        :per-page="perPage"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        stacked="md"
                        show-empty
                        :empty-text="$t('com_geen_data', { ns: 'common' })"
                        small
                        striped
                        @filtered="onFiltered"
                    >
                        <template v-slot:cell(actions)="row">
                            <div class="form-inline">
                                <b-button
                                    v-if="
                                        checkRight('AnalyticsMeasurementCreate')
                                    "
                                    size="sm"
                                    @click="createNewItem(row.item)"
                                    class="bgc_aux_normal"
                                    ><font-awesome-icon
                                        icon="folder-plus"
                                        size="1x"
                                    />
                                </b-button>

                                <b-button
                                    v-if="
                                        checkRight('AnalyticsMeasurementUpdate')
                                    "
                                    size="sm"
                                    @click="bewerkItem(row.item)"
                                    class="bgc_aux_warning"
                                    ><font-awesome-icon icon="edit" size="1x" />
                                </b-button>
                                <b-button
                                    v-if="
                                        checkRight('AnalyticsMeasurementDelete')
                                    "
                                    size="sm"
                                    @click="verwijderItem(row.item)"
                                    class="bgc_aux_alarm"
                                    ><font-awesome-icon
                                        icon="trash-alt"
                                        size="1x"
                                    />
                                </b-button>
                                <b-button
                                    size="sm"
                                    @click="infoItem(row.item)"
                                    class="bgc_aux_unknown"
                                    ><font-awesome-icon
                                        icon="info-circle"
                                        size="1x"
                                    />
                                </b-button>
                            </div>
                        </template>
                        <template #cell(waarde)="data">
                            <span
                                :class="{
                                    text:
                                        data.item.minlimiet == undefined &&
                                        data.item.maxlimiet == undefined,
                                    tc_aux_checked:
                                        data.item.minlimiet <
                                            data.item.waarde &&
                                        data.item.maxlimiet > data.item.waarde,
                                    tc_aux_highlighted:
                                        data.item.minlimiet >
                                            data.item.waarde ||
                                        data.item.maxlimiet < data.item.waarde
                                }"
                            >
                                {{ data.item.waarde }}
                            </span>
                        </template>
                        <template v-slot:row-details>
                            <measurement-info
                                v-if="!showAssesment"
                                v-bind:inItem="localItem"
                            >
                            </measurement-info>
                            <assesment-info
                                v-if="showAssesment"
                                v-bind:inItem="localItem"
                            >
                            </assesment-info>
                        </template>
                    </b-table>
                    <div v-if="displayList.length == 0">
                        {{ strNoData }}
                    </div>
                </b-col>
            </b-row>
        </div>
        <modal-measurement
            v-if="showDetailsMeas"
            v-model="showDetailsMeas"
            v-bind:inItem="measurement"
            v-bind:isNew="isNew"
            v-bind:barcodeExecutor="barcodeEX"
            v-bind:barcodeEntityObject="barcodeEO"
            v-bind:barcodeSample="barcodeSA"
            @forcerefresh="doRefresh"
            @hide="reset"
        />
        <modal-assesment
            v-if="showDetailsAss"
            v-model="showDetailsAss"
            v-bind:inItem="assesment"
            v-bind:isNew="isNew"
            v-bind:barcodeExecutor="barcodeEX"
            v-bind:barcodeEntityObject="barcodeEO"
            v-bind:barcodeSample="barcodeSA"
            @forcerefresh="doRefresh"
            @hide="reset"
        />
        <modal-graph
            v-if="showGraph"
            v-model="showGraph"
            :graphData="graphData"
            :van="van"
            :tot="tot"
            :isassesment="testen"
            @hide="reset"
        />
        <scan-barcode
            v-if="showScanner"
            v-model="showScanner"
            @result="showMaesurementInsert"
        />
    </b-container>
</template>

<script>
import PeriodSelector from '../components/PeriodSelector.vue';
import ScanBarcode from '../components/ScanBarcode.vue';
import ModalMeasurement from './ModalMeasurement.vue';
import ModalAssesment from './ModalAssesment.vue';
import MeasurementInfo from './MeasurementInfo.vue';
import AssesmentInfo from './AssesmentInfo.vue';
import DateHelper from '../../models/DateHelper';
import Helper from '../../models/Helper';
import VueJsonToCsv from 'vue-json-to-csv';
import { loadItem, loadList } from './utils';
import { Permission } from '../../models/Permissions';
import ModalGraph from './ModalGraph.vue';

export default {
    name: 'AnalyticsBase',
    data: function () {
        return {
            showScanner: false,
            showGraph: false,
            showDetailsMeas: false,
            showDetailsAss: false,
            barcodeEO: null,
            barcodeEX: null,
            barcodeSA: null,
            measurement: null,
            assesment: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [10, 20, 40, { value: 100, text: '100' }],
            filter: null,
            filterOn: [],
            van: null,
            tot: null,
            localActivities: [],
            graphData: [],
            filteredData: [],
            objectNaam: '',
            forcerefresh: false,
            selectDays: null,
            periodeDagen: 60,
            slackDagen: 0,
            testen: false,
            dataLoaded: false,
            isNew: true,
            localItem: null
        };
    },
    components: {
        PeriodSelector,
        ScanBarcode,
        ModalMeasurement,
        ModalAssesment,
        MeasurementInfo,
        AssesmentInfo,
        VueJsonToCsv,
        ModalGraph
    },
    computed: {
        strNoData() {
            return Helper.stringformat(
                this.$t('prod_nomaintenancedata', {
                    ns: 'production'
                }),
                this.van,
                this.tot
            );
        },
        title() {
            return Helper.stringformat(
                '{0}: {1}',
                this.$t('an_resresults', { ns: 'analytics' }),
                this.testen
                    ? this.$t('an_tests', { ns: 'analytics' })
                    : this.$t('an_metingen', { ns: 'analytics' })
            );
        },
        reportFileName: function () {
            let textVan = this.van
                ? new Date(this.van).toLocaleDateString()
                : new Date().toLocaleDateString();
            let textTot = this.tot
                ? new Date(this.tot).toLocaleDateString()
                : new Date().toLocaleDateString();
            textVan = textVan.replaceAll('-', '');
            textTot = textTot.replaceAll('-', '');
            const result = this.van
                ? this.title + '_' + textVan + '_tot_' + textTot
                : 'Onbekend';
            return result;
        },
        btnTekst() {
            //return this.testen ? 'Toon metingen' : 'Toon testen';
            return Helper.stringformat(
                '{0} {1}',
                this.$t('com_show', { ns: 'common' }),
                this.testen
                    ? this.$t('an_metingen', { ns: 'analytics' }).toLowerCase()
                    : this.$t('an_tests', { ns: 'analytics' }).toLowerCase()
            );
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                { key: 'naam', label: 'Naam', sortable: false, visible: true },
                {
                    key: 'meettijdstip',
                    label: this.$t('an_meettijd', { ns: 'analytics' }),
                    sortable: true,
                    visible: true,
                    formatter: 'displayDate'
                }
            ];
            const fieldsInitMeas = [
                {
                    key: 'waarde',
                    label: this.$t('an_result', { ns: 'analytics' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'eenheidNaam',
                    label: this.$t('com_unit', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'meetplekNaam',
                    label: this.$t('an_meetplaats', { ns: 'analytics' }),
                    sortable: true,
                    visible: true
                }
            ];
            const fieldsInitAssesment = [
                {
                    key: 'grootheidNaam',
                    label: this.$t('an_test', { ns: 'analytics' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'labelNaam',
                    label: this.$t('an_beoordeling', { ns: 'analytics' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'meetplekNaam',
                    label: this.$t('an_meetplaats', { ns: 'analytics' }),
                    sortable: true,
                    visible: true
                }
            ];
            this.testen
                ? fieldsInit.push(...fieldsInitAssesment)
                : fieldsInit.push(...fieldsInitMeas);
            return fieldsInit.filter((field) => field.visible);
        },
        displayList() {
            return this.graphData;
        },
        displayListForCSV() {
            const list =
                this.filteredData.length > 0
                    ? this.filteredData.slice(0)
                    : this.graphData.slice(0);

            let lijst = list.map((x) => {
                const result = {};
                for (let i = 0; i < this.visibleFields.length; i++) {
                    const key = this.visibleFields[i].key;
                    const keytrans = this.visibleFields[i].label;
                    const formatter = this.visibleFields[i].formatter;
                    if (x[key])
                        result[keytrans] = formatter
                            ? this.displayDate(x[key])
                            : x[key];
                }
                return result;
            });
            lijst = Helper.replaceValueInObject(lijst, null, '');
            return lijst;
        },
        allowEdit() {
            // todo: wat worden de regels hiervoor?
            return true;
        },
        showAssesment() {
            return this.testen;
        }
    },
    methods: {
        doRefresh() {
            this.forcerefresh = true;
            this.loadData();
        },
        async infoItem(row) {
            if (row._showDetails) {
                this.$set(row, '_showDetails', false);
            } else {
                this.displayList.forEach((row) => {
                    this.$set(row, '_showDetails', false);
                });

                this.measurement = null;
                this.assesment = null; // TODO
                this.testen
                    ? (this.assesment = await loadItem(
                          'analytics/loadAssesment',
                          { assesmentid: row.id },
                          this.$t
                      ))
                    : (this.measurement = await loadItem(
                          'analytics/loadMaesurement',
                          { measurementID: row.id },
                          this.$t
                      ));
                this.localItem = this.testen
                    ? this.assesment
                    : this.measurement;
                this.$set(row, '_showDetails', true);
            }
        },
        flipTesten() {
            this.displayList.forEach((row) => {
                this.$set(row, '_showDetails', false);
            });
            this.testen = !this.testen;
            this.loadData();
        },
        scan() {
            this.showScanner = !this.showScanner;
        },
        reset() {
            this.showDetailsMeas = false;
            this.showDetailsAss = false;
            this.showGraph = false;
        },
        displayDate(value) {
            return DateHelper.convertedToDate(value, true);
        },
        showMaesurementInsert(value) {
            if (value == undefined) return;
            const arr = value.split('-');
            const deel1 = arr.length == 2 ? arr[0] : null;
            switch (deel1) {
                case 'AE':
                    this.barcodeEO = value;
                    break;
                case 'AS':
                    this.barcodeSA = value;
                    break;
                case 'AX':
                    this.barcodeEX = value;
                    break;
                default:
                    break;
            }
            this.showDetailsMeas = !this.showDetailsMeas;
        },
        async bewerkItem(row) {
            this.measurement = null;
            this.assesment = null;
            this.isNew = false;
            if (this.testen) {
                if (row) {
                    this.assesment = await loadItem(
                        'analytics/loadAssesment',
                        { assesmentid: row.id },
                        this.$t
                    );
                }
                this.showDetailsAss = !this.showDetailsAss;
            } else {
                if (row) {
                    this.measurement = await loadItem(
                        'analytics/loadMaesurement',
                        { measurementID: row.id },
                        this.$t
                    );
                }
                this.showDetailsMeas = !this.showDetailsMeas;
            }
        },
        async createNewItem(row) {
            this.measurement = null;
            this.assesment = null;
            this.isNew = true;
            if (this.testen) {
                if (row) {
                    this.assesment = await loadItem(
                        'analytics/loadAssesment',
                        { assesmentid: row.id },
                        this.$t
                    );
                }
                this.showDetailsAss = !this.showDetailsAss;
            } else {
                if (row) {
                    this.measurement = await loadItem(
                        'analytics/loadMaesurement',
                        { measurementID: row.id },
                        this.$t
                    );
                }
                this.showDetailsMeas = !this.showDetailsMeas;
            }
        },
        async verwijderItem(row) {
            const bericht =
                this.$t('com_delete', {
                    ns: 'common',
                    val: this.testen
                        ? this.$t('an_test', { ns: 'analytics' })
                        : this.$t('an_meting', { ns: 'analytics' })
                }) + this.displayDate(row.meettijdstip);
            const doClose = await this.$root.showConfirmationBox(bericht);
            if (doClose) {
                this.testen
                    ? await loadItem(
                          'analytics/deleteAssesment',
                          { assesmentID: row.id },
                          this.$t
                      )
                    : await loadItem(
                          'analytics/deleteMaesurement',
                          { measurementID: row.id },
                          this.$t
                      );
            }
            this.loadData();
        },
        setNewDates(selDates) {
            this.van = selDates.van;
            this.tot = selDates.tot;
            this.loadData();
        },
        async loadData() {
            const selectionData = {
                from: DateHelper.localDateToUTC(this.van),
                until: DateHelper.localDateToUTC(this.tot)
            };
            this.testen
                ? (this.graphData = await loadList(
                      'analytics/getAssesmentLijst',
                      'analytics/loadAssesmentLijst',
                      selectionData,
                      true,
                      this.$t
                  ))
                : (this.graphData = await loadList(
                      'analytics/getMaesurementLijst',
                      'analytics/loadMaesurementLijst',
                      selectionData,
                      true,
                      this.$t
                  ));
            this.totalRows = this.graphData ? this.graphData.length : 0;
            this.dataLoaded = this.totalRows > 0;
        },
        loadGraph() {
            this.showGraph = true;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.filteredData = filteredItems;
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        }
    },
    mounted: async function () {
        await this.loadData();
    },
    created() {
        // laad de activiteiten en types als al niet gedaan
        loadItem('analytics/loadInvestQuantityLijst', null, this.$t);
        loadItem('analytics/loadQunitLijst', null, this.$t);
    }
};
</script>

<style scoped></style>

