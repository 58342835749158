<template>
    <b-modal
        id="modal-recurrencylijst"
        size="xl"
        ref="modal"
        :title="this.formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
    >
        <div class="form-inline">
            <period-selector
                id="selector-recurrency"
                style="width: 85%"
                v-model="selectDays"
                v-bind:periodDays="periodeDagen"
                v-bind:forceToday="true"
                @change="setNewDates"
            />
            <b-button @click="loadData()" class="bgc_aux_normal"
                ><font-awesome-icon icon="arrow-down" size="1x" />&nbsp;{{
                    $t('com_show', { ns: 'common' })
                }}
            </b-button>
        </div>
        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
            {{ alertText }}
        </b-alert>

        <b-table
            striped
            responsive
            :fields="visibleFields"
            :items="displayList"
            stacked="md"
            small
            show-empty
            :empty-text="$t('com_geen_data', { ns: 'common' })"
        >
            <template v-slot:cell(actions)="row">
                <b-button
                    size="sm"
                    @click="editItem(row.item)"
                    class="bgc_aux_warning"
                    ><font-awesome-icon icon="edit" size="1x" />
                </b-button>
                <b-button
                    size="sm"
                    @click="verwijderItem(row.item)"
                    class="bgc_aux_alarm"
                    ><font-awesome-icon icon="trash-alt" size="1x" />
                </b-button>
            </template>
        </b-table>
        <maintenance-pop-form
            v-if="detailsVisibleMaintenance"
            :displayNumber="displayNumber"
            v-model="detailsVisibleMaintenance"
            v-bind:editAllowed="allowEdit"
            v-bind:inItem="reiniging"
            @hide="reset"
            @reiniging_refresh="loadData"
        />
        <modal-assesment
            v-if="detailsVisibleAssesment"
            v-model="detailsVisibleAssesment"
            v-bind:inItem="assesment"
            v-bind:isNew="isNew"
            @forcerefresh="loadData"
            @hide="reset"
        />
        <modal-measurement
            v-if="detailsVisibleMeas"
            v-model="detailsVisibleMeas"
            v-bind:inItem="measurement"
            v-bind:isNew="isNew"
            @forcerefresh="loadData"
            @hide="reset"
        />
    </b-modal>
</template>

<script>
import _ from 'lodash';
import Helper from '../../models/Helper';
import DateHelper from '../../models/DateHelper';
import MaintenancePopForm from '../maintenance/MaintenancePopForm.vue';
import ModalAssesment from '../analytics/ModalAssesment.vue';
import ModalMeasurement from '../analytics/ModalMeasurement.vue';
import { loadItem, loadList } from '../../models/DataHelper';
import PeriodSelector from '../components/PeriodSelector.vue';

export default {
    name: 'RecurrencyPopForm',
    data: function () {
        return {
            showDismissibleAlert: false,
            alertText: '',
            selectDays: null,
            periodeDagen: 1,
            allowEdit: true,
            detailsVisibleMaintenance: false,
            detailsVisibleAssesment: false,
            detailsVisibleMeas: false,
            reiniging: null,
            assesment: null,
            measurement: null,
            isNew: true,
            displayNumber: '??',
            eventList: []
        };
    },
    components: {
        PeriodSelector,
        ModalAssesment,
        ModalMeasurement,
        MaintenancePopForm
    },
    model: {
        prop: 'visible',
        event: 'hide'
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        lijst: {
            type: Array,
            default: () => []
        }
    },
    watch: {},
    computed: {
        formtitle() {
            return this.$t('Herhalingspatronen', { ns: 'recurrency' });
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                {
                    key: 'type',
                    label: this.$t('prod_type', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'wat',
                    label: this.$t('rec_wat', { ns: 'recurrency' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'waarvan',
                    label: this.$t('rec_waarvan', { ns: 'recurrency' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'omschrijving',
                    label: this.$t('rec_omschrijving', { ns: 'recurrency' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'laatstekeer',
                    label: this.$t('rec_last', { ns: 'recurrency' }),
                    sortable: true,
                    visible: true,
                    formatter: this.displayDate
                },
                {
                    key: 'volgendekeer',
                    label: this.$t('rec_next', { ns: 'recurrency' }),
                    sortable: true,
                    visible: true,
                    formatter: this.displayDate
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        displayList() {
            const lijst = this.eventList;
            return lijst;
        },
        isDagInfo() {
            return this.van == this.tot;
        }
    },
    methods: {
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        reset() {
            this.detailsVisibleMaintenance = false;
            this.detailsVisibleAssesment = false;
            this.detailsVisibleMeas = false;
        },
        displayDate(value) {
            return DateHelper.convertedToDate(value, true);
        },
        setNewDates(selDates) {
            this.van = selDates.van;
            this.tot = selDates.tot;
            this.loadData();
        },
        async loadData() {
            const selectionData = {
                from: DateHelper.localDateToUTC(this.van),
                until: DateHelper.localDateToUTC(this.tot)
            };
            this.eventList = await loadItem(
                'recurrency/loadRecurrencyOverPeriod',
                selectionData,
                this.$t
            );
        },
        hide() {
            this.$emit('hide');
        },
        editItem(item) {
            const orignal = this.eventList.find((x) => x.id === item.id);
            switch (orignal.bron) {
                case window.constants.RECCONST_MAINTENANCE: {
                    this.prepareSourceMainenance(orignal.id);
                    break;
                }
                case window.constants.RECCONST_ASSESMENT: {
                    this.prepareSourceAssesment(orignal.id);
                    break;
                }
                case window.constants.RECCONST_EONORMS: {
                    this.prepareSourceMeasurement(orignal.id);
                    break;
                }
                case window.constants.RECCONST_IQ: {
                    return 'iq';
                }
                default:
                    return 'fout';
            }
        },
        async prepareSourceMainenance(id) {
            this.allowEdit = false;
            this.reiniging = await loadItem(
                'maintenance/loadMaintenance',
                { maintenanceID: id },
                this.$t
            );
            this.detailsVisibleMaintenance = !this.detailsVisibleMaintenance;
        },
        async prepareSourceAssesment(id) {
            this.allowEdit = false;
            this.assesment = await loadItem(
                'analytics/loadAssesment',
                { assesmentid: id },
                this.$t
            );
            this.detailsVisibleAssesment = !this.detailsVisibleAssesment;
        },
        async prepareSourceMeasurement(id) {
            this.allowEdit = false;
            const selectednorm = await loadItem(
                'analytics/loadNormById',
                { eonormID: id },
                this.$t
            );
            if (selectednorm) {
                this.measurement = await loadItem(
                    'analytics/loadLastMaesurement',
                    {
                        entityObjectID: selectednorm.meetplekID,
                        investigationQuantityID:
                            selectednorm.onderzoeksgrootheidID
                    },
                    this.$t
                );
            }
            this.detailsVisibleMeas = !this.detailsVisibleMeas;
        },
        async verwijderItem(row) {
            const bericht = this.$t('com_delete', {
                ns: 'common',
                val: this.$t('rec_pattern', { ns: 'recurrency' })
            });
            const doClose = await this.$root.showConfirmationBox(bericht);
            if (doClose) {
                const orignal = this.eventList.find((x) => x.id === row.id);
                switch (orignal.bron) {
                    case window.constants.RECCONST_MAINTENANCE: {
                        const locreiniging = await loadItem(
                            'maintenance/loadMaintenance',
                            { maintenanceID: orignal.id },
                            this.$t
                        );
                        locreiniging.herhaling.Deleted = true;
                        const MaintenanceModel = locreiniging.SetForAdd(
                            'Verwijderd herhalingspatroon'
                        );
                        if (MaintenanceModel)
                            await loadItem(
                                'maintenance/addMaintenance',
                                MaintenanceModel,
                                this.$t
                            );
                        break;
                    }
                    case window.constants.RECCONST_ASSESMENT: {
                        const locassesment = await loadItem(
                            'analytics/loadAssesment',
                            { assesmentid: id },
                            this.$t
                        );
                        locassesment.herhaling.Deleted = true;
                        const addObject = locassesment.SetForAdd();
                        await loadItem(
                            'analytics/saveAssesment',
                            addObject,
                            this.$t
                        );
                        break;
                    }
                    case window.constants.RECCONST_EONORMS: {
                        const locnorm = await loadItem(
                            'analytics/loadNormById',
                            { eonormID: id },
                            this.$t
                        );
                        locnorm.herhaling.Deleted = true;
                        const addObject = locnorm.SetForAdd();
                        await loadItem(
                                'analytics/saveNorm',
                                addObject,
                                this.$t
                            );
                        break;
                    }
                    case window.constants.RECCONST_IQ: {
                        return 'todo';
                    }
                    default:
                        return 'fout';
                }
            }
            this.loadData();
        },
        checkFormValidity() {
            return true;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return;
            }
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-recurrencylijst');
            });
        }
    },
    mounted() {},
    created() {
        this.eventList = this.lijst;
    }
};
</script>

<style scoped></style>
