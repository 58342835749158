import store from '../store';
export { loadList, loadItem, loadGetItem };

class dataObject {
    constructor(data) {
        this.data; // data
        this.func; // functie i18
    }
}

const loadList = async (getCall, dispatchCall, data, forceRefresh, func) => {
    let lijst = store.getters[getCall];
    if (lijst.length < 1 || forceRefresh) {
        const data2 = func ? { data: data, func: func } : data;
        lijst = await store.dispatch(dispatchCall, data2);
        if (lijst == undefined || lijst.length < 1)
            lijst = store.getters[getCall];
    }
    return lijst;
};

const loadGetItem = async (getCall, dispatchCall, data, forceRefresh, func) => {
    let item = store.getters[getCall];
    if (item == undefined || forceRefresh) {
        const data2 = func ? { data: data, func: func } : data;
        item = await store.dispatch(dispatchCall, data2);
    }
    return item;
};

const loadItem = async (dispatchCall, data, func) => {
    const data2 = func ? { data: data, func: func } : data;
    const item = await store.dispatch(dispatchCall, data2);
    return item;
};



