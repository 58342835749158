<template>
    <b-modal
        id="modal-confirmRecipe"
        size="xl"
        ref="modal"
        :title="this.formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
    >
        <b-card @submit.stop.prevent="handleSubmit">
            <b-tabs card>
                <b-tab :title="receptTitle" v-bind:key="1" active>
                    <b-card-text>
                        <b-form-textarea
                            id="opmerking"
                            v-model="receptOpmerking"
                            :placeholder="$t('com_opmerking', { ns: 'common' })"
                            rows="3"
                            max-rows="6"
                        ></b-form-textarea>

                        <b-table
                            bordered
                            fixed
                            responsive
                            :sticky-header="stickyHeader"
                            :fields="fieldsPlanner"
                            :items="recipeList"
                        >
                            <template #cell()="data">
                                <span :class="giveBGclass(data.value)">
                                    {{ displayTekst(data.value) }}
                                </span>
                            </template>
                        </b-table>
                    </b-card-text>
                </b-tab>
                <b-tab :title="behandelTitle" @click="behandelHistorie" lazy>
                    <b-card-text>
                        <b-table
                            bordered
                            fixed
                            responsive
                            :sticky-header="stickyHeader"
                            :fields="fieldsPlanner"
                            :items="treatedList"
                        >
                            <template #cell()="data">
                                <span :class="giveBGclass(data.value)">
                                    {{ displayTekst(data.value) }}
                                </span>
                            </template>
                        </b-table>
                    </b-card-text>
                </b-tab>
                <b-tab :title="combiTitle" @click="combination" lazy>
                    <b-card-text>
                        <b-table
                            bordered
                            fixed
                            responsive
                            :sticky-header="stickyHeader"
                            :fields="fieldsPlanner"
                            :items="combinedList"
                        >
                            <template #cell()="data">
                                <span :class="giveBGclass(data.value)">
                                    {{ displayTekst(data.value) }}
                                </span>
                            </template>
                        </b-table>
                    </b-card-text>
                </b-tab>
                <div v-if="showHistorie">
                    <b-tab
                        :title="$t('com_historie', { ns: 'common' })"
                        @click="behandelHistorie"
                        lazy
                    >
                        <b-card-text>
                            <b-table
                                :items="displayList"
                                :fields="visibleFields"
                                stacked="md"
                                show-empty
                                :empty-text="
                                    $t('com_geen_data', { ns: 'common' })
                                "
                                small
                                striped
                            >
                            </b-table>
                        </b-card-text>
                    </b-tab>
                </div>
            </b-tabs>
        </b-card>
    </b-modal>
</template>

<script lang="ts">
import DateHelper from '../../models/DateHelper';
import Helper from '../../models/Helper';
import { defineComponent } from 'vue';
import { VueExtension } from '../../models/VueExtension';
import { loadList, loadItem } from '../../models/DataHelper';
import {
    TreatmentActivity,
    TreatmentRecipe,
    TreatmentRecipeStep,
    TreatmentWeekPlan
} from '@/models/CheeseTreatment';

export default defineComponent({
    name: 'TreatmentModal',
    data: function () {
        return {
            showSpinner: false,
            stickyHeader: '70vh',
            fieldsPlanner: [
                { key: 'week', label: this.$t('com_week', { ns: 'common' }) },
                {
                    key: 'Day10',
                    label: this.$t('com_dag', { ns: 'common' }) + ' 1 12'
                },
                {
                    key: 'Day15',
                    label: this.$t('com_dag', { ns: 'common' }) + ' 1 24'
                },
                {
                    key: 'Day20',
                    label: this.$t('com_dag', { ns: 'common' }) + ' 2 12'
                },
                {
                    key: 'Day25',
                    label: this.$t('com_dag', { ns: 'common' }) + ' 2 24'
                },
                {
                    key: 'Day30',
                    label: this.$t('com_dag', { ns: 'common' }) + ' 3 12'
                },
                {
                    key: 'Day35',
                    label: this.$t('com_dag', { ns: 'common' }) + ' 3 24'
                },
                {
                    key: 'Day40',
                    label: this.$t('com_dag', { ns: 'common' }) + ' 4 12'
                },
                {
                    key: 'Day45',
                    label: this.$t('com_dag', { ns: 'common' }) + ' 4 24'
                },
                {
                    key: 'Day50',
                    label: this.$t('com_dag', { ns: 'common' }) + ' 5 12'
                },
                {
                    key: 'Day55',
                    label: this.$t('com_dag', { ns: 'common' }) + ' 5 24'
                },
                {
                    key: 'Day60',
                    label: this.$t('com_dag', { ns: 'common' }) + ' 6 12'
                },
                {
                    key: 'Day65',
                    label: this.$t('com_dag', { ns: 'common' }) + ' 6 24'
                },
                {
                    key: 'Day70',
                    label: this.$t('com_dag', { ns: 'common' }) + ' 7 12'
                },
                {
                    key: 'Day75',
                    label: this.$t('com_dag', { ns: 'common' }) + ' 7 24'
                }
            ],
            recipeList: [] as Array<TreatmentWeekPlan>,
            treatedList: [] as Array<TreatmentWeekPlan>,
            combinedList: [] as Array<TreatmentWeekPlan>,
            histList: [] as Array<TreatmentActivity>,
            treatmentList: [] as Array<TreatmentActivity>,
            refreshedRecipe: {} as TreatmentRecipe,
            receptOpmerking: null,

            aangeraakt_teller: 1,
            localChanged: true,
            forceRefresh: false,
            showBakDetails: false,
            editAllowed: true,
            warnAmount: false,
            uitvoerTijdstip: DateHelper.convertedToTime(new Date()),
            partijID: -1,
            firstTimeWait: 24,
            rerenderkey: 1,
            strReceptCaption: this.$t('com_recipe', { ns: 'common' }) + ' {0}',
            strBehandelCaption:
                this.$t('prod_treatment', { ns: 'production' }) +
                ' ' +
                this.$t('com_batch', { ns: 'common' }).toLowerCase() +
                ' : {0}',
            strCombiCaption:
                this.$t('prod_treatment', { ns: 'production' }) +
                ' ' +
                this.$t('com_batch', { ns: 'common' }).toLowerCase() +
                ' : {0}' +
                this.$t('com_tov', {
                    ns: 'common',
                    val: this.$t('com_recipe', { ns: 'common' }).toLowerCase()
                }) +
                ' {1}',
            strReceptOpTijd:
                '{0} ' +
                this.$t('prod_optijd', { ns: 'production' }).toLowerCase() +
                ' {1}x',
            strMutipleBehandel: '{0} {1}x'
        };
    },
    model: {
        prop: 'visible',
        event: 'change'
    },
    props: {
        treatmentRecipeId: {
            type: Number,
            required: true
        },
        batch: {
            type: String,
            required: false
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    components: {},
    watch: {},
    computed: {
        displayList() {
            return this.histList;
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: false },
                { key: 'id', label: 'id', sortable: false, visible: false },
                {
                    key: 'voorraaditem',
                    label: this.$t('com_barcode', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'starttijd',
                    label: this.$t('prod_uitgevoerd', { ns: 'production' }),
                    sortable: true,
                    visible: true,
                    formatter: (value) => {
                        return this.displayDate(value);
                    }
                },
                {
                    key: 'starttijdplan',
                    label: this.$t('prod_gepland', { ns: 'production' }),
                    sortable: true,
                    visible: true,
                    formatter: (value) => {
                        return this.displayDate(value);
                    }
                },
                {
                    key: 'product',
                    label: this.$t('com_product', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'behandeling',
                    label: this.$t('prod_treatment', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'schema',
                    label: this.$t('prod_schema', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'stapnummer',
                    label: this.$t('prod_step', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'coatingproduct',
                    label: this.$t('prod_coating', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'locatie',
                    label: this.$t('com_location', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'startaantal',
                    label: this.$t('com_number', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'statustekst',
                    label: this.$t('com_status', { ns: 'common' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        formtitle() {
            return (
                this.$t('com_detail', { ns: 'common' }) +
                ' ' +
                this.$t('prod_treatment', { ns: 'production' }).toLowerCase()
            );
        },
        receptTitle() {
            const title = Helper.stringformat(
                this.strReceptCaption,
                this.refreshedRecipe.naam
            );
            return title;
        },
        behandelTitle() {
            const title = Helper.stringformat(
                this.strBehandelCaption,
                this.batch
            );
            return title;
        },
        combiTitle() {
            const title = Helper.stringformat(
                this.strCombiCaption,
                this.batch,
                this.refreshedRecipe.naam
            );
            return title;
        },
        showHistorie() {
            return this.batch ? true : false;
        }
    },
    methods: {
        hide() {
            this.$emit('hide');
        },
        showDate(aDate) {
            return DateHelper.convertedToDateShort(aDate);
        },
        displayTekst(value) {
            if (typeof value === 'object' && value !== null) {
                if (Array.isArray(value)) {
                    let metRecept = false;
                    let strBehandeling = null;
                    for (let i = 0; i < value.length; i++) {
                        // zit er één met recept tussen, dan op tijd anders
                        if (value[i].recept) {
                            metRecept = true;
                            strBehandeling = value[i].behandelstap;
                        }
                        strBehandeling =
                            strBehandeling == undefined
                                ? value[i].behandelstap
                                : strBehandeling;
                    }
                    return metRecept
                        ? Helper.stringformat(
                              this.strReceptOpTijd,
                              strBehandeling,
                              value.length - 1
                          )
                        : Helper.stringformat(
                              this.strMutipleBehandel,
                              strBehandeling,
                              value.length - 1
                          );
                } else {
                    return value.Display();
                }
            }
            return value;
        },
        giveBGclass(value) {
            if (typeof value === 'object' && value !== null) {
                if (Array.isArray(value)) {
                    let metRecept = false;
                    let base = value[0];
                    for (let i = 0; i < value.length; i++) {
                        if (value[i].recept) {
                            metRecept = true;
                            base = value[i];
                        }
                    }
                    if (metRecept) {
                        if (base.behandelstapID == 2) return 'bg-coat';
                        if (base.behandelstapID == 1) return 'bg-keer';
                    } else {
                        if (base.behandelstapID == 2) return 'bg-coatBehandel';
                        if (base.behandelstapID == 1) return 'bg-keerBehandel';
                    }
                } else {
                    if (value.recept) {
                        if (value.behandelstapID == 2) return 'bg-coat';
                        if (value.behandelstapID == 1) return 'bg-keer';
                    } else {
                        if (value.behandelstapID == 2) return 'bg-coatBehandel';
                        if (value.behandelstapID == 1) return 'bg-keerBehandel';
                    }
                }
            }
            return null;
        },
        set_changed() {
            this.localChanged = true;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        async handleSubmit() {
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-confirmRecipe');
            });
        },
        giveBakTitle(element) {
            return 'Bak ' + element.number;
        },
        vulRecipeMatrix() {
            const recept = this.refreshedRecipe;
            recept.weekplannen = [];
            this.receptOpmerking = recept.opmerking;
            let runningWeek = 1;
            let weekPlan = new TreatmentWeekPlan({ week: runningWeek });
            const lijst = recept.stappen.filter(
                (x) => x.isDeleted == undefined || x.isDeleted == false
            );
            let totHours = this.firstTimeWait;
            for (let i = 0; i < lijst.length; i++) {
                const days = Math.round((totHours * 10) / 24);
                const realdays = days / 10;
                const remainder = days % 5; //
                // klopt de week nog

                const numberOfWeeks = Math.floor((realdays - 1) / 7) + 1;
                if (numberOfWeeks > runningWeek) {
                    while (runningWeek < numberOfWeeks) {
                        recept.weekplannen.push(weekPlan);
                        runningWeek += 1;
                        weekPlan = new TreatmentWeekPlan({ week: runningWeek });
                    }
                }
                const dayNumber =
                    Math.round(realdays) * 10 -
                    (runningWeek - 1) * 70 +
                    remainder * 5;
                lijst[i].dagnummer = dayNumber;
                lijst[i].dagnummerabsoluut = realdays;
                weekPlan.SetDay(dayNumber, lijst[i]);
                const hoursAfterStep = lijst[i].wachttijd;
                totHours += hoursAfterStep;
            }
            recept.weekplannen.push(weekPlan);
            return recept;
        },
        vulBehandelMatrix() {
            this.treatedList = [];
            let runningWeek = 1;
            let weekPlan = new TreatmentWeekPlan({ week: runningWeek });
            const lijst = this.treatmentList;
            // moet tov tijdstip uit pekel, fromplanned van de eerste is uitpekel inclusief firsttimewait
            const out = lijst[0].starttijdplan;
            const outbrine = DateHelper.addHoursToDate(
                out,
                -this.firstTimeWait
            );
            for (let i = 0; i < lijst.length; i++) {
                if (lijst[i].starttijd != undefined) {
                    const totHours = DateHelper.dateDiffinHours(
                        lijst[i].starttijd,
                        outbrine
                    );
                    const days = Math.round((totHours * 10) / 24);
                    const realdays = days / 10;
                    const remainder = days % 5; //
                    // klopt de week nog

                    const numberOfWeeks = Math.floor((realdays - 1) / 7) + 1;
                    if (numberOfWeeks > runningWeek) {
                        while (runningWeek < numberOfWeeks) {
                            this.treatedList.push(weekPlan);
                            runningWeek += 1;
                            weekPlan = new TreatmentWeekPlan({
                                week: runningWeek
                            });
                        }
                    }
                    const dayNumber =
                        Math.round(realdays) * 10 -
                        (runningWeek - 1) * 70 +
                        remainder * 5;
                    const index = lijst[i].stapnummer - 1;
                    const receptStap = this.refreshedRecipe.stappen[index];
                    const ts = new TreatmentRecipeStep();
                    ts.volgnummer = lijst[i].stapnummer;
                    ts.behandelstap = receptStap.behandelstap;
                    ts.behandelstapID = receptStap.behandelstapID;
                    ts.dagnummer = dayNumber;
                    ts.dagnummerabsoluut = realdays;
                    ts.recept = false;
                    weekPlan.SetDay(dayNumber, ts);
                }
            }
            this.treatedList.push(weekPlan);
        },
        async getData() {
            this.recipeList = [];
            if (this.treatmentRecipeId > -1) {
                this.refreshedRecipe = await loadItem(
                    'treatment/loadTreatmentRecipe',
                    {
                        treatmentRecipeID: this.treatmentRecipeId
                    },
                    this.$t
                );
                const recept = this.vulRecipeMatrix();
                this.recipeList = recept.weekplannen;
            }
        },
        async behandelHistorie() {
            if (this.histList.length < 1) {
                const t = DateHelper.TodayEndOfDay();
                const o = DateHelper.determineVanTot(t, 366);
                const selectionData = {
                    from: DateHelper.localDateToUTC(o.van),
                    until: DateHelper.localDateToUTC(o.tot)
                };
                const tempList = await loadList(
                    'treatment/getPlannedTreatments',
                    'treatment/loadPlannedTreatments',
                    selectionData,
                    true,
                    this.$t
                );
                this.treatmentList = tempList.filter(
                    (x) => x.batchnummer == this.batch
                );
                const filtList = this.treatmentList.filter(
                    (x) => x.statusnummer >= window.constants.STATUS_TREATED
                );
                this.histList = filtList;
            }
            this.vulBehandelMatrix();
        },
        async combination() {
            this.combinedList = [];
            if (this.treatedList.length < 1) await this.behandelHistorie();
            const doRecept = this.recipeList.length > this.treatedList.length;
            const teller = doRecept
                ? this.recipeList.length
                : this.treatedList.length;
            const baselist = doRecept ? this.recipeList : this.treatedList;
            const searchlist = doRecept ? this.treatedList : this.recipeList;
            for (let i = 0; i < teller; i++) {
                const baseWeekplan = baselist[i];
                const searchWeekplan =
                    i < searchlist.length ? searchlist[i] : null;
                const nieuw = baseWeekplan.Merge(searchWeekplan);
                if (nieuw) this.combinedList.push(nieuw);
            }
        },
        displayDate(value) {
            return DateHelper.convertedToDate(value, true);
        }
    },
    mounted() {
        this.getData();
    },
    async created() {
        const lijst = await loadList(
            'masterdata/getConstantsLijst',
            'masterdata/loadApplicationConstants',
            null,
            this.forceRefresh,
            this.$t
        );
        const con = lijst
            ? lijst.find(
                  (x) => x.text == window.constants.APPCONST_HOURSFIRSTTREATMENT
              )
            : null;
        this.firstTimeWait = con ? con.value : null;
    }
});
</script>

<style scoped>
.bg-coat {
    background-color: #c1e881 !important;
}
.bg-keer {
    background-color: hwb(203 55% 7%) !important;
}
.bg-coatBehandel {
    background-color: #506135 !important;
    color: ghostwhite !important;
}
.bg-keerBehandel {
    background-color: hwb(203 28% 52%) !important;
    color: ghostwhite !important;
}
</style>

