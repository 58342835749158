const state = {
    treatmentRecipeLijst: [],
    treatmentActivityLijst: [],
    tacStatusLijst: [],
    treatStepLijst: [],
    uitbehandeldLijst: []
};

export default state;

