<template>
    <b-container fluid style="margin-top: 60px">
        <h1>
            {{
                $t('com_reportfor', {
                    ns: 'common',
                    val: $t('com_dag', {
                        ns: 'common'
                    })
                })
            }}</h1>
        <b-row>
            <b-col sm="9">
                <b-row>
                    <b-col sm="9">
                        <period-selector
                            v-model="selectDays"
                            v-bind:periodDays="periodeDagen"
                            @change="setNewDates"
                        />
                    </b-col>
                    <b-col sm="3">
                        <b-row>
                            <b-col sm="12">
                                <b-form-select
                                    v-model="report"
                                    :options="reports"
                                    @change="clearOutput()"
                                >
                                    <template #first>
                                        <b-form-select-option value=""
                                            >-- {{$t('com_selecteer', { ns: 'common' })}}
                                            --</b-form-select-option
                                        >
                                    </template>
                                </b-form-select>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
            <b-col sm="1">
                <b-row>
                    <b-col sm="12">
                        <b-row>
                            <b-col sm="12">
                                <b-button
                                    @click="loadData()"
                                    class="bgc_aux_normal"
                                    v-b-tooltip.hover
                                    :title="$t('com_showdata', { ns: 'common' })"
                                    ><font-awesome-icon
                                        icon="arrow-down"
                                        size="1x"
                                    />
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
            <b-col sm="2">
                <b-row>
                    <b-col sm="11">
                        <b-row>
                            <b-col sm="12">
                                <div class="form-inline">
                                    <print-to-pdf
                                        v-bind:titel="reportTitle"
                                        v-bind:filenaam="reportFileName"
                                        v-bind:reportList="reportList"
                                    />
                                    <vue-json-to-csv
                                        :json-data="reportList"
                                        :csv-title="reportFileName"
                                        :separator="';'"
                                    >
                                        <b-button
                                            class="bgc_aux_normal"
                                            v-b-tooltip.hover
                                            :title="
                                                $t('com_downloadas', {
                                                    ns: 'common',
                                                    val: 'CSV'
                                                })
                                            "
                                        >
                                            <font-awesome-icon
                                                icon="file-csv"
                                                size="1x"
                                            />
                                        </b-button>
                                    </vue-json-to-csv>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col sm="1"> </b-col>
                </b-row>
            </b-col>
        </b-row>
        <div slot="pdfdata">
            <b-row class="my-1">
                <b-col sm="12">
                    <b-row no-gutters>
                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_filter', { ns: 'common' })"
                                label-for="filter-input"
                                label-cols-sm="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-input-group size="sm">
                                    <b-form-input
                                        id="filter-input"
                                        v-model="filter"
                                        type="search"
                                         :placeholder="$t('com_typetosearch', { ns: 'common' })"
                                    ></b-form-input>

                                    <b-input-group-append>
                                        <b-button
                                            :disabled="!filter"
                                            @click="filter = ''"
                                            >{{ $t('com_wis', { ns: 'common' }) }}</b-button
                                        >
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>

                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_perpagina', { ns: 'common' })"
                                label-for="per-page-select"
                                label-cols-sm="6"
                                label-cols-md="4"
                                label-cols-lg="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-form-select
                                    id="per-page-select"
                                    v-model="perPage"
                                    :options="pageOptions"
                                    size="sm"
                                ></b-form-select>
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                ></b-pagination>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-table
                        striped
                        :items="reportList"
                        :fields="tableFields"
                        primary-key
                        :current-page="currentPage"
                        :per-page="perPage"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        stacked="md"
                        show-empty
                        :empty-text="$t('com_geen_data', { ns: 'common' })"
                        small
                        @filtered="onFiltered"
                    >
                    </b-table>
                    <div v-if="taskCount === 0">
                        {{ $t('com_geen_data', { ns: 'common' }) }}
                    </div>
                </b-col>
            </b-row>
        </div>
    </b-container>
</template>

<script>
import PrintToPdf from './PrintToPdf.vue';
import VueJsonToCsv from 'vue-json-to-csv';
import PeriodSelector from '../components/PeriodSelector.vue';
import DateHelper from '../../models/DateHelper';
import { loadItem } from '../../models/DataHelper';
import Helper from '@/models/Helper';

export default {
    name: 'RapDag',
    data: function () {
        return {
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [10, 20, 40, { value: 100, text: '100' }],
            filter: null,
            filterOn: [],
            tableFields: [],
            van: null,
            tot: null,
            geraakt_teller: 1,
            report: '/Reporting/IngredientsProductionDayReport',
            selectDays: null,
            periodeDagen: 1,
            clearlist: false
        };
    },
    components: {
        PrintToPdf,
        VueJsonToCsv,
        PeriodSelector
    },
    computed: {
        reportList() {
            // eslint-disable-next-line no-unused-vars
            const x = this.geraakt_teller;
            let list = [];
            if (this.clearlist) {
                this.clearOutput();
            } else {
                list = this.$store.getters['rapportage/getReportItems'];
                list = Helper.removeProperty(list, 'id');
                list = DateHelper.convertDateInListToDateTimeString(list,true);
                list = Helper.replaceValueInObject(list, null, '');
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.tableFields = Helper.giveHeaderFields(list, this.$t);
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.totalRows = list ? list.length : 0;
            }
            return list;
        },
        reportFileName: function () {
            let textVan = this.van
                ? new Date(this.van).toLocaleDateString()
                : new Date().toLocaleDateString();
            textVan = textVan.replaceAll('-', '');
            const result = Helper.stringformat(
                this.$t('com_reportop', {
                    ns: 'common'
                }),
                this.selectedOption,
                this.textVan
            );
            return result;
        },
        reportTitle: function () {
            const result = this.selectedOption + ': ' + this.van;
            return result;
        },
        selectedOption: function () {
            const report = this.reports.find((x) => x.value === this.report);
            return report.text;
        },
        reports() {
            return [
                {
                    value: '/Reporting/IngredientsProductionDayReport',
                    alternative: '/Reporting/IngredientsDemandReport',
                    text: this.$t('prod_ingrlijst', { ns: 'production' })
                }
            ];
        }
    },
    methods: {
        clearOutput() {
            this.clearlist = !this.clearlist;
        },
        taskCount() {
            let rijen = 0;
            if (this.reportList != undefined) {
                rijen = this.reportList.length;
            }
            return rijen;
        },
        setNewDates(selDates) {
            this.van = selDates.van;
            this.tot = selDates.tot;
            this.clearOutput();
        },
        async loadData() {            
            const report = this.reports.find((x) => x.value === this.report);
            let payload = null;
            if (this.van == this.tot) {
                const selectionData = {
                    productionDate: DateHelper.localDateToUTC(this.van)
                };
                payload = {
                    data: selectionData,
                    report: report.value
                };
            } else {
                const data = {
                    from: this.van,
                    until: this.tot
                };
                payload = {
                    data: data,
                    report: report.alternative
                };
            }
            loadItem('rapportage/loadReport', payload, this.$t);
            this.geraakt_teller += 1;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        }
    },
    mounted: function () {
        this.totalRows = this.reportList ? this.reportList.length : 0;
        this.clearOutput();
    }
};
</script>

<style scoped></style>
