const getters = {
    getExtDevicesLijst: state =>  {
        return state.extDevicesLijst;
    },
    getExtDeviceTypesLijst: state =>  {
        return state.extDeviceTypesLijst;
    },
    getLinkItemsLijst: state =>  {
        return state.linkItemsLijst;
    }
    
};

export default getters;

