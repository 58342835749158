import Api from '../../../api';
import store from '../../../store';

// data= int stockItemID, int treatmentRecipeID, DateTime startAt, int startStepNumber
const assignSchema = async (data) => {
    //return await Api(store).put('/CheeseTreatment/AssignSchema', data);
    return storeInfo('/CheeseTreatment/AssignSchema', data);
};

// data= ConfirmTreatmentActivityModel
const confirmTreatment = async (data) => {
    return await Api(store).put('/CheeseTreatment/ConfirmTreatment', data);
};

// int treatmentRecipeID
const fetchTreatmentRecipe = async (data) => {
    return fetchInfo('/CheeseTreatment/GetTreatmentRecipe', data);
};

const fetchTreatmentRecipes = async (data) => {
    return fetchInfo('/CheeseTreatment/GetTreatmentRecipes', data);
};
// DateTime from, DateTime until, string filterfields, string filtervalues, string filtertypes
const fetchPlannedTreatments = async (data) => {
    return fetchInfo('/CheeseTreatment/GetPlannedTreatments', data);
};

const fetchTreatmentSteps = async (data) => {
    return fetchInfo('/CheeseTreatment/GetTreatmentSteps', data);
};

const fetchCheeseSchemaEnded = async (data) => {
    return fetchInfo('/CheeseTreatment/GetCheeseSchemaEnded', data);
};

const fetchCheeseWithoutSchema = async (data) => {
    return fetchInfo('/CheeseTreatment/GetCheeseWithoutSchema', data);
};

const fetchTreatmentStatus = async (data) => {
    return fetchInfo('/CheeseTreatment/GetTreatmentStatus', data);
};

// int treatmentActivityID
const fetchTreatmentActivity = async (data) => {
    return fetchInfo('/CheeseTreatment/GetTreatmentActivity', data);
};

//int TreatmentModel
const storeTreatmentCoatingProduct = async (data) => {
    return await Api(store).put('/CheeseTreatment/ChangeTreatmentCoatingProduct', data);
    //return storeInfo('/CheeseTreatment/ChangeTreatmentCoatingProduct', data);
};
//TreatmentModel
const storeTreatmentCoating = async (data) => {
    return await Api(store).put('/CheeseTreatment/AddCoatingTreatment', data);
    //return storeInfo('/CheeseTreatment/AddCoatingTreatment', data);
};
// StoreTreatmentRecipeModel
const storeTreatmentRecipe = async (data) => {
    return await Api(store).put('/CheeseTreatment/StoreTreatmentRecipe', data);
};


const storeInfo = async (apicall, data) => {
    if (data != undefined) {
        return await Api(store).put(
            apicall +
                '?' +
                Object.keys(data)
                    .map(
                        (k) =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    }
};
const fetchInfo = async (apicall, data) => {
    if (data != undefined) {
        return await Api(store).get(
            apicall +
                '?' +
                Object.keys(data)
                    .map(
                        (k) =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    } else {
        return await Api(store).get(apicall, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    }
};

export default {
    assignSchema,
    confirmTreatment,
    fetchTreatmentRecipe,
    fetchTreatmentRecipes,
    fetchPlannedTreatments,
    fetchCheeseSchemaEnded,
    fetchCheeseWithoutSchema,
    fetchTreatmentSteps,
    fetchTreatmentStatus,
    fetchTreatmentActivity,
    storeTreatmentCoatingProduct,
    storeTreatmentCoating,
    storeTreatmentRecipe
};
