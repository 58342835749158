const mutations = {
    insertUnitsComboLijst(state, lijst) {
        state.unitsComboList = [];
        if (lijst != undefined) {
            state.unitsComboList = lijst;
        }
    },
    insertSimpleProductsLijst(state, lijst) {
        state.simpleProductList = [];
        if (lijst != undefined) {
            state.simpleProductList = lijst;
        }
    },
    insertSimpleRecipesLijst(state, lijst) {
        state.simpleRecipeList = [];
        if (lijst != undefined) {
            state.simpleRecipeList = lijst;
        }
    },
    insertSimpleProductionLijst(state, lijst) {
        state.simpleProductionList = [];
        if (lijst != undefined) {
            state.simpleProductionList = lijst;
        }
    },
    insertProductionValueLijst(state, lijst) {
        state.productionValueList = [];
        if (lijst != undefined) {
            state.productionValueList = lijst;
        }
    },
};

export default mutations;

