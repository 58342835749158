import { PRODCAT_ENUM } from '../../../models/ProductCategorie';

const mutations = {
    insertCheeseType(state, item) {
        const ind = state.cheeseTypeList.findIndex(x => {
            return x.id === item.id;
        });
        if (ind === -1) {
            state.cheeseTypeList.push(item);
        } else {
            state.markTypeList.splice(ind, 1, item);
        }
    },
    insertCheeseTypeInfoLijst(state, lijst) {
        state.cheeseTypeInfoList = [];
        if (lijst != undefined) {
            state.cheeseTypeInfoList = lijst;
        }
    },
    insertMarkType(state, item) {
        const ind = state.markTypeList.findIndex(x => {
            return x.id === item.id;
        });
        if (ind === -1) {
            state.markTypeList.push(item);
        } else {
            state.markTypeList.splice(ind, 1, item);
        }
    },
    insertMarkTypeInfo(state, lijst) {
        state.markTypeInfoList = [];
        if (lijst != undefined) {
            state.markTypeInfoList = lijst;
        }
    },
    deleteProduct(state, { item, type }) {
        let lijst = null;
        switch (type) {
            case PRODCAT_ENUM.Kaas:
                lijst = state.cheeseTypeInfoList;
                break;
            case PRODCAT_ENUM.Kaasmerk:
                lijst = state.markTypeInfoList;
                break;
            case PRODCAT_ENUM.Kaasdekmiddel:
                lijst = state.cheeseCoatingList;
                break;
            case PRODCAT_ENUM.Wei:
                lijst = state.wheyTypeInfoList;
                break;
            case PRODCAT_ENUM.Ingrediënt:
            default: {
                break;
            }
        }
        const index = lijst.findIndex(x => {
            return x.id === item.id;
        });
        if (index > -1) {
            lijst.splice(index, 1);
        }
    },
    insertCheeseCoating(state, item) {
        const ind = state.cheeseCoatingList.findIndex(x => {
            return x.id === item.id;
        });
        if (ind === -1) {
            state.cheeseCoatingList.push(item);
        } else {
            state.cheeseCoatingList.splice(ind, 1, item);
        }
    },
    insertCheeseCoatingInfo(state, lijst) {
        state.cheeseCoatingInfoList = [];
        if (lijst != undefined) {
            state.cheeseCoatingInfoList = lijst;
        }
    },
    insertWheyType(state, item) {
        const ind = state.wheyTypeList.findIndex(x => {
            return x.id === item.id;
        });
        if (ind === -1) {
            state.wheyTypeList.push(item);
        } else {
            state.wheyTypeList.splice(ind, 1, item);
        }
    },
    insertWheyTypeInfo(state, lijst) {
        state.wheyTypeInfoList = [];
        if (lijst != undefined) {
            state.wheyTypeInfoList = lijst;
        }
    },
    insertFailureCheese(state, item) {
        const ind = state.failureCheeseList.findIndex(x => {
            return x.id === item.id;
        });
        if (ind === -1) {
            state.failureCheeseList.push(item);
        } else {
            state.failureCheeseList.splice(ind, 1, item);
        }
    },
    insertFailureCheeseInfo(state, lijst) {
        state.failureCheeseInfoList = [];
        if (lijst != undefined) {
            state.failureCheeseInfoList = lijst;
        }
    },
    insertOverige(state, item) {
        const ind = state.overigeList.findIndex(x => {
            return x.id === item.id;
        });
        if (ind === -1) {
            state.overigeList.push(item);
        } else {
            state.overigeList.splice(ind, 1, item);
        }
    },
    insertOverigeInfo(state, lijst) {
        state.overigeInfoList = [];
        if (lijst != undefined) {
            state.overigeInfoList = lijst;
        }
    },
    insertIngredient(state, item) {
        const ind = state.ingredientList.findIndex(x => {
            return x.id === item.id;
        });
        if (ind === -1) {
            state.ingredientList.push(item);
        } else {
            state.ingredientList.splice(ind, 1, item);
        }
        state.selectedIngredient = item;
    },
    insertStarterSubtypeLijst(state, lijst) {
        state.starterSubTypeList = [];
        if (lijst != undefined) {
            state.starterSubTypeList = lijst;
        }
    },
    insertProductsLijst(state, lijst) {
        state.allProductsList = [];
        if (lijst != undefined) {
            state.allProductsList = lijst;
        }
    },
    insertIngredientInfoLijst(state, lijst) {
        state.ingredientInfoList = [];
        if (lijst != undefined) {
            state.ingredientInfoList = lijst;
        }
    },
    deleteIngredient(state, item) {
        const index = state.ingredientInfoList.findIndex(x => {
            return x.id === item.id;
        });
        if (index > -1) {
            state.ingredientInfoList.splice(index, 1);
        }
    },
    insertVoorraadItemLijst(state, lijst) {
        state.voorraadItemLijst = [];
        if (lijst != undefined) {
            state.voorraadItemLijst = lijst;
        }
    },
    insertProductCategoriesLijst(state, lijst) {
        state.productCategorieLijst = [];
        if (lijst != undefined) {
            state.productCategorieLijst = lijst;
        }
    },
    insertIngredientCategoriesLijst(state, lijst) {
        state.ingredientCategorieLijst = [];
        if (lijst != undefined) {
            state.ingredientCategorieLijst = lijst;
        }
    }
};

export default mutations;
