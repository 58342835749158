<template>
    <div id="appCamera" class="web-camera-container">
        <div class="camera-button">
            <b-button
                :class="{
                    bgc_aux_normal: !isCameraOpen,
                    bgc_aux_alarm: isCameraOpen
                }"
                @click="toggleCamera"
            >
                <span v-if="!isCameraOpen">Open Camera</span>
                <span v-else>Close Camera</span>
            </b-button>
        </div>

        <div v-show="isCameraOpen && isLoading" class="camera-loading">
            <ul class="loader-circle">
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </div>

        <div
            v-if="isCameraOpen"
            v-show="!isLoading"
            class="camera-box"
            :class="{ flash: isShotPhoto }"
        >
            <div class="camera-shutter" :class="{ flash: isShotPhoto }"></div>

            <video
                v-show="!isPhotoTaken"
                ref="camera"
                :width="canvasWidth"
                :height="canvasHeight"
                autoplay
            ></video>

            <canvas
                v-show="isPhotoTaken"
                id="photoTaken"
                ref="canvas"
                :width="canvasWidth"
                :height="canvasHeight"
            ></canvas>
        </div>

        <div v-if="isCameraOpen && !isLoading" class="camera-shoot">
            <b-button type="button" class="button" @click="takePhoto">
                <font-awesome-icon icon="camera" size="1x" />
            </b-button>
        </div>

        <!--div v-if="isPhotoTaken && isCameraOpen" class="camera-download">
            <a
                id="downloadPhoto"
                download="my-photo.jpg"
                class="button"
                role="button"
                @click="downloadImage"
            >
                Download
            </a>
        </div -->
    </div>
</template>

<script>
import DateHelper from '@/models/DateHelper';
export default {
    name: 'CameraPicture',
    components: {},
    data() {
        return {
            canvasHeight: 337.5,
            canvasWidth: 450,
            isCameraOpen: false,
            isPhotoTaken: false,
            isShotPhoto: false,
            isLoading: false,
            link: '#'
        };
    },
    props: {
        bijlages: {
            type: Array
        }
    },
    methods: {
        toggleCamera() {
            if (this.isCameraOpen) {
                this.isCameraOpen = false;
                this.isPhotoTaken = false;
                this.isShotPhoto = false;
                this.stopCameraStream();
            } else {
                this.isCameraOpen = true;
                this.createCameraElement();
            }
        },

        createCameraElement() {
            this.isLoading = true;

            const constraints = (window.constraints = {
                audio: false,
                video: true
            });

            navigator.mediaDevices
                .getUserMedia(constraints)
                .then((stream) => {
                    this.isLoading = false;
                    this.$refs.camera.srcObject = stream;
                })
                .catch((error) => {
                    this.isLoading = false;
                    alert(
                        this.$t('com_camerabroke', { ns: 'production' })
                    );
                });
        },

        stopCameraStream() {
            const tracks = this.$refs.camera.srcObject.getTracks();

            tracks.forEach((track) => {
                track.stop();
            });
        },

        takePhoto() {
            if (!this.isPhotoTaken) {
                this.isShotPhoto = true;

                const FLASH_TIMEOUT = 50;

                setTimeout(() => {
                    this.isShotPhoto = false;
                }, FLASH_TIMEOUT);
            }

            this.isPhotoTaken = !this.isPhotoTaken;

            const context = this.$refs.canvas.getContext('2d');
            this.setResizefactor(this.$refs.camera.videoHeight);
            this.canvasHeight =
                this.$refs.camera.videoHeight * this.resizefactor;
            this.canvasWidth = this.$refs.camera.videoWidth * this.resizefactor;
            context.drawImage(
                this.$refs.camera,
                0,
                0,
                this.canvasWidth,
                this.canvasHeight
            );
            const canvas = document
                .getElementById('photoTaken')
                .toDataURL('image/jpeg');
            const uniquePictureName = this.generateCapturePhotoName();
            const capturedPhotoFile = this.dataURLtoFile(
                canvas,
                uniquePictureName + '.jpg'
            );

            this.toggleCamera();
            this.$emit('picture', {
                file: capturedPhotoFile,
                url: canvas
            });
        },
        setResizefactor(height) {
            const maxHeight = window.constants.MAX_IMAGE_HEIGHT;
            if (height > maxHeight) {
                this.resizefactor = maxHeight / height;
            } else {
                this.resizefactor = 1;
            }
        },
        dataURLtoFile(dataURL, filename) {
            const arr = dataURL.split(',');
            const mime = arr[0].match(/:(.*?);/)[1];
            //mimeType = 'image/jpeg',
            const bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
        },
        generateCapturePhotoName() {
            const yyyymmdd_hhmmss =
                DateHelper.DateToYMD() + '_' + DateHelper.DateToHM();
            return yyyymmdd_hhmmss;
        }
        // downloadImage() {
        //     const download = document.getElementById('downloadPhoto');
        //     const canvas = document
        //         .getElementById('photoTaken')
        //         .toDataURL('image/jpeg')
        //         .replace('image/jpeg', 'image/octet-stream');
        //     download.setAttribute('href', canvas);
        // }
    },
    mounted() {
        this.toggleCamera();
    }
};
</script>

<style scoped>
body {
    display: flex;
    justify-content: center;
}
.web-camera-container {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 500px;
}
.web-camera-container .camera-button {
    margin-bottom: 2rem;
}
.web-camera-container .camera-box .camera-shutter {
    opacity: 0;
    width: 450px;
    height: 337.5px;
    background-color: #fff;
    position: absolute;
}
.web-camera-container .camera-box .camera-shutter.flash {
    opacity: 1;
}
.web-camera-container .camera-shoot {
    margin: 1rem 0;
}
.web-camera-container .camera-shoot button {
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}
.web-camera-container .camera-shoot button img {
    height: 35px;
    object-fit: cover;
}
.web-camera-container .camera-loading {
    overflow: hidden;
    height: 100%;
    position: absolute;
    width: 100%;
    min-height: 150px;
    margin: 3rem 0 0 -1.2rem;
}
.web-camera-container .camera-loading ul {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 999999;
    margin: 0;
}
.web-camera-container .camera-loading .loader-circle {
    display: block;
    height: 14px;
    margin: 0 auto;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    transform: translateX(-50%);
    position: absolute;
    width: 100%;
    padding: 0;
}
.web-camera-container .camera-loading .loader-circle li {
    display: block;
    float: left;
    width: 10px;
    height: 10px;
    line-height: 10px;
    padding: 0;
    position: relative;
    margin: 0 0 0 4px;
    background: #999;
    animation: preload 1s infinite;
    top: -50%;
    border-radius: 100%;
}
.web-camera-container .camera-loading .loader-circle li:nth-child(2) {
    animation-delay: 0.2s;
}
.web-camera-container .camera-loading .loader-circle li:nth-child(3) {
    animation-delay: 0.4s;
}
@keyframes preload {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
}
</style>
