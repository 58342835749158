var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticStyle:{"margin-top":"60px"},attrs:{"fluid":""}},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('com_special', { ns: 'common' }))+" "+_vm._s(_vm.$t('com_acties', { ns: 'common' }).toLowerCase())+" ")]),_c('b-card',[_c('b-tabs',{attrs:{"card":""}},[_c('b-tab',{key:1,attrs:{"title":_vm.$t('com_production', { ns: 'common' }),"active":""}},[(_vm.checkRight('BatchDestroy'))?_c('b-row',[_c('b-col',{attrs:{"sm":"1"}}),_c('b-col',{staticClass:"form-inline mx-0 px-0 my-0 py-0",attrs:{"sm":"3"}},[_vm._v(" "+_vm._s(_vm.deleteBak)+" ")]),_c('b-col',{staticClass:"form-inline mx-0 px-0 my-0 py-0",attrs:{"sm":"8"}},[_c('b-input-group',{attrs:{"prepend":_vm.$t('com_production', {
                                    ns: 'common'
                                })}},[_c('b-form-datepicker',{attrs:{"id":"dateVan","date-format-options":{
                                    year: 'numeric',
                                    month: 'short',
                                    day: '2-digit'
                                }},on:{"input":_vm.loadProductionData},model:{value:(_vm.van),callback:function ($$v) {_vm.van=$$v},expression:"van"}})],1),_c('b-input-group',{attrs:{"prepend":_vm.$t('prod_bak', {
                                    ns: 'production'
                                })}},[_c('b-form-select',{attrs:{"options":_vm.bakkenLijst},on:{"change":_vm.setBatchSelection},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":""}},[_vm._v("-- "+_vm._s(_vm.$t('com_selecteer', { ns: 'common' }))+" --")])]},proxy:true}],null,false,2446721565),model:{value:(_vm.batchID),callback:function ($$v) {_vm.batchID=$$v},expression:"batchID"}})],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"bgc_aux_alarm",attrs:{"title":_vm.$t('com_verwijder', { ns: 'common' }),"disabled":!_vm.selected},on:{"click":_vm.destroyBatch}},[_c('font-awesome-icon',{attrs:{"icon":"trash-alt","size":"1x"}})],1)],1)],1):_vm._e()],1),_c('b-tab',{key:2,attrs:{"title":_vm.$t('prod_zuursel', { ns: 'production' })}},[(_vm.checkRight('ProductGroupStarters'))?_c('card-zuursel'):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }