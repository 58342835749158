import { api } from '../api';
import { showBadToast, showGoodToast } from '../../errors/toast';
import logMessage from '../../errors/foutlog';
import { Product } from '../../../models/Product';
import { SimpleRecipe, SimpleRecipeElement } from '../../../models/Recept';
import {
    SimpleProduction,
    SimpleProductBatchElement,
    SimpleProductionValue,
    ProductionValueType,
    AttachmentInfo
} from '../../../models/PlusProduction';

const actions = {
    async loadUnitsComboLijst(context, data) {
        let tempList = [];
        try {
            await api.fetchUnits(data.data).then((response) => {
                tempList = response.data.Items.map((x) => ({
                    value: x.id,
                    text: x.description + ' (' + x.symbol + ')',
                    base: x.baseid,
                    naam: x.description,
                    factor: x.factor ?? 1
                }));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_units', { ns: 'common' })
                }),
                error
            );
        }
        context.commit('insertUnitsComboLijst', tempList);
        return tempList;
    },
    async loadSimpleProductInfo(context, data) {
        let tempList = [];
        try {
            await api.fetchSimpleProductInfo(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new Product(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_products', { ns: 'common' })
                }),
                error
            );
        }
        context.commit('insertSimpleProductsLijst', tempList);
        return tempList;
    },
    async loadSimpleProductionInfoOverPeriod(context, data) {
        let tempList = [];
        try {
            await api
                .fetchSimpleProductionInfoOverPeriod(data.data)
                .then((response) => {
                    tempList = response.data.Items.map(
                        (x) => new SimpleProduction(x)
                    );
                });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_productions', { ns: 'common' })
                }),
                error
            );
        }
        context.commit('insertSimpleProductionLijst', tempList);
        return tempList;
    },
    async loadSimpleProductionInfo(context, data) {
        let tempList = [];
        try {
            await api.fetchSimpleProductionInfo(data.data).then((response) => {
                tempList = response.data.Items.map(
                    (x) => new SimpleProduction(x)
                );
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_productions', { ns: 'common' })
                }),
                error
            );
        }
        context.commit('insertSimpleProductionLijst', tempList);
        return tempList;
    },
    async loadSimpleRecipes(context, data) {
        let tempList = [];
        try {
            await api.fetchSimpleRecipes(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new SimpleRecipe(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_recipes', { ns: 'common' })
                }),
                error
            );
        }
        context.commit('insertSimpleRecipesLijst', tempList);
        return tempList;
    },
    async loadProductUsedProducts(context, data) {
        let tempList = [];
        try {
            await api.fetchProductUsedProducts(data.data).then((response) => {
                tempList = response.data.Items.map(
                    (x) => new SimpleProductBatchElement(x)
                );
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('tien_batchnumbers', { ns: 'tien' })
                }),
                error
            );
        }
        return tempList;
    },
    // int productionID
    async loadProductionAttachInfo(context, data) {
        let tempList = [];
        try {
            await api.fetchProductionAttachInfo(data.data).then((response) => {
                tempList = response.data.Items.map(
                    (x) => new AttachmentInfo(x)
                );
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_bijlagen', { ns: 'common' })
                }),
                error
            );
        }
        return tempList;
    },
    // int productionID, int attachmentID
    async loadProductionAttachment(context, data) {
        let item = null;
        try {
            const response = await api.fetchProductionAttachment(data.data);
            item = response.data;
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_bijlage', { ns: 'common' })
                }),
                error
            );
        }
        return item;
    },
    async loadProductSerialNumber(context, data) {
        let item = null;
        try {
            const response = await api.fetchProductSerialNumber(data.data);
            const tempList = response.data.Items.map((x) => ({
                id: x.id,
                product: x.description,
                endserial: x.endserial
            }));
            item = tempList.length > 0 ? tempList[0].endserial : '';
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('tien_serial', { ns: 'tien' })
                }),
                error
            );
        }
        return item;
    },
    async loadSimpleRecipe(context, data) {
        let item = null;
        try {
            const response = await api.fetchSimpleRecipe(data.data);
            item = new SimpleRecipe(response.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_recipe', { ns: 'common' })
                }),
                error
            );
        }
        return item;
    },
    async loadSimpleProduction(context, data) {
        let item = null;
        try {
            const response = await api.fetchSimpleProduction(data.data);
            item = new SimpleProduction(response.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_production', { ns: 'common' })
                }),
                error
            );
        }
        return item;
    },
    async loadProductionValueTypes(context, data) {
        let tempList = [];
        try {
            await api
                .fetchSimpleProductionValueTypes(data.data)
                .then((response) => {
                    tempList = response.data.map(
                        (x) => new ProductionValueType(x)
                    );
                });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('tien_types', { ns: 'tien' })
                }),
                error
            );
        }
        return tempList;
    },
    async loadProductionValues(context, data) {
        let tempList = [];
        try {
            await api
                .fetchSimpleProductionValues(data.data)
                .then((response) => {
                    tempList = response.data.Items.map(
                        (x) => new SimpleProductionValue(x)
                    );
                });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_prodvalues', { ns: 'common' })
                }),
                error
            );
        }
        context.commit('insertProductionValueLijst', tempList);
        return tempList;
    },
    async loadProductionValue(context, data) {
        let item = null;
        try {
            const response = await api.fetchProductionValue(data.data);
            item = new SimpleProductionValue(response.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_prodvalue', { ns: 'common' })
                }),
                error
            );
        }
        return item;
    },
    async saveProductionValue(context, data) {
        let item = null;
        try {
            const response = await api.storeProductionValue(data.data);
            item = new SimpleProductionValue(response.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('com_prodvalue', { ns: 'common' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('com_prodvalue', { ns: 'common' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return item;
    },

    async saveSimpleProduction(context, data) {
        let item = null;
        try {
            const response = await api.storeSimpleProduction(data.data);
            item = new SimpleProduction(response.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('com_production', { ns: 'common' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('com_production', { ns: 'common' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return item;
    },

    async saveSimpleProductionAttachment(context, data) {
        let item = null;
        try {
            const response = await api.storeSimpleProductionAttachment(
                data.data
            );
            item = new AttachmentInfo(response.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('com_bijlage', { ns: 'common' })
                })
            );
        } catch (error) {
            const mes = await logMessage(error, 'error');
            showBadToast(
                context,
                data.func('com_errorupload', {
                    ns: 'common',
                    val: data.func('com_bijlage', { ns: 'common' })
                }),
                error
            );
        }
        return item;
    },
    async saveSimpleProduct(context, data) {
        let item = null;
        try {
            const response = await api.storeSimpleProduct(data.data);
            item = new Product(response.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('com_product', { ns: 'common' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('com_product', { ns: 'common' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return item;
    },
    async saveSimpleRecipe(context, data) {
        let item = null;
        try {
            const response = await api.storeSimpleRecipe(data.data);
            item = new SimpleRecipe(response.data.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('com_recipe', { ns: 'common' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('com_recipe', { ns: 'common' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return item;
    },

    async deleteSimpleProduction(context, data) {
        try {
            await api.deleteSimpleProduction(data.data);
            showGoodToast(
                context,
                data.func('com_verwijderd', {
                    ns: 'common',
                    val: data.func('com_production', { ns: 'common' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data.func('com_production', { ns: 'common' })
                }),
                error
            );
            logMessage(error, 'error');
        }
    },
    async deleteProductionAttachment(context, data) {
        try {
            await api.deleteSimpleProductionAttachment(data.data);
            showGoodToast(
                context,
                data.func('com_verwijderd', {
                    ns: 'common',
                    val: data.func('com_bijlage', { ns: 'common' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data.func('com_bijlage', { ns: 'common' })
                }),
                error
            );
            logMessage(error, 'error');
        }
    },
    async deleteProductionValue(context, data) {
        try {
            await api.deleteProductionValue(data.data);
            showGoodToast(
                context,
                data.func('com_verwijderd', {
                    ns: 'common',
                    val: data.func('com_prodvalue', { ns: 'common' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data.func('com_prodvalue', { ns: 'common' })
                }),
                error
            );
            logMessage(error, 'error');
        }
    }
};

export default actions;
