const getters = {
    getCheeseTypes: (state) => {
        return state.cheeseTypeList;
    },
    getCheeseTypesInfo: (state) => {
        return state.cheeseTypeInfoList;
    },
    getMarkTypes: (state) => {
        return state.markTypeList;
    },
    getMarkTypesInfo: (state) => {
        return state.markTypeInfoList;
    },
    getCheeseCoatings: (state) => {
        return state.cheeseCoatingList;
    },
    getCheeseCoatingsInfo: (state) => {
        return state.cheeseCoatingInfoList;
    },
    getWheyTypes: (state) => {
        return state.wheyTypeList;
    },
    getWheyTypesInfo: (state) => {
        return state.wheyTypeInfoList;
    },
    getFailureCheese: (state) => {
        return state.failureCheeseList;
    },
    getFailureCheeseInfo: (state) => {
        return state.failureCheeseInfoList;
    },
    getOverige: (state) => {
        return state.overigeList;
    },
    getOverigeInfo: (state) => {
        return state.overigeInfoList;
    },
    getIngredients: (state) => {
        return state.ingredientList;
    },
    getIngredientsInfo: (state) => {
        return state.ingredientInfoList;
    },
    getZuurselSubtypes: (state) => {
        return state.starterSubTypeList;
    },    
    getIngredientsInfoFor: (state) => (id) => {
        const cat = state.ingredientCategorieLijst.find((x) => x.id == id);
        return cat ? cat.lijst : [];
    },
    getStockItemLijst: (state) => {
        return state.voorraadItemLijst;
    },
    getProductCategorieLijst: (state) => {
        return state.productCategorieLijst;
    },
    getProductCategorieByID: (state) => (id) => {
        return state.productCategorieLijst.find((x) => x.id == id);
    },
    getProductCategorieByDescription: (state) => (naam) => {
        return state.productCategorieLijst.find(
            (x) => x.naam.toLowerCase() === naam.toLowerCase()
        );
    },
    getIngredientCategorieLijst: (state) => {
        return state.ingredientCategorieLijst;
    },
    getIngredientCategorieByID: (state) => (id) => {
        return state.ingredientCategorieLijst.find((x) => x.id == id);
    },
    getIngredientProductByID: (state) => (id) => {
        let result = null;
        for (let i = 0; i < state.ingredientCategorieLijst.length; i++) {
            const ingcat = state.ingredientCategorieLijst[i];
            for (let j = 0; j < ingcat.lijst.length; j++) {
                const tempingredient = ingcat.lijst[j];
                if (tempingredient.id == id) {
                    result = tempingredient;
                    return result;
                }
            }
        }
        return result;
    },
    getIngredientCategorieByDescription: (state) => (naam) => {
        return state.ingredientCategorieLijst.find(
            (x) => x.naam.toLowerCase() === naam.toLowerCase()
        );
    },
    getSelectedIngredient: (state) => {
        return state.selectedIngredient;
    },
    getAllProductsLijst: (state) => {
        return state.allProductsList;
    },
    
};

export default getters;
