<template>
    <b-modal
        id="modal-itemMaintenance"
        size="lg"
        ref="modal"
        :title="formtitle"
        :visible="visible"
        :ok-disabled="!magOpslaan"
        @hidden="hide"
        @ok="handleOk"
    >
        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
            {{ alertText }}
        </b-alert>
        <b-card :header="$t('prod_activities', { ns: 'production' })">
            <b-row v-if="'ost' == localDisplayNumber">
                <b-col sm="3">
                    <label>{{ ostLabel }}:</label>
                </b-col>
                <b-col sm="9">
                    <div class="form-inline">
                        <b-form-select
                            v-model="localOst"
                            :options="wrongelbereiderList"
                            :disabled="itemAdded || !editAllowed"
                        >
                            <template #first>
                                <b-form-select-option value=""
                                    >--
                                    {{ $t('com_selecteer', { ns: 'common' }) }}
                                    --</b-form-select-option
                                >
                            </template>
                        </b-form-select>
                    </div>
                </b-col>
            </b-row>
            <b-row
                v-if="
                    'loc' == localDisplayNumber || 'silo' == localDisplayNumber
                "
            >
                <b-col sm="3">
                    <label>{{ locationLabel }}: </label>
                </b-col>
                <b-col sm="9">
                    <div class="form-inline">
                        <b-form-select
                            v-model="localLocation"
                            :options="locationList"
                            :disabled="itemAdded || !editAllowed"
                        >
                            <template #first>
                                <b-form-select-option value=""
                                    >--
                                    {{
                                        $t('com_selecteer', {
                                            ns: 'common'
                                        })
                                    }}
                                    --</b-form-select-option
                                >
                            </template>
                        </b-form-select>
                    </div>
                </b-col>
            </b-row>
            <b-row v-if="'eq' == displayNumber">
                <b-col sm="3">
                    <label>{{ locationLabel }}: </label>
                </b-col>
                <b-col sm="9">
                    <div class="form-inline">
                        <b-form-select
                            v-model="localApparaat"
                            :options="apparatenList"
                            :disabled="itemAdded || !editAllowed"
                        >
                            <template #first>
                                <b-form-select-option value=""
                                    >--
                                    {{
                                        $t('com_selecteer', {
                                            ns: 'common'
                                        })
                                    }}
                                    --</b-form-select-option
                                >
                            </template>
                        </b-form-select>
                    </div>
                </b-col>
            </b-row>

            <b-row>
                <b-col sm="3">
                    <label>
                        {{ $t('prod_bepactietype', { ns: 'production' }) }}
                        :</label
                    >
                </b-col>
                <b-col sm="9">
                    <div class="form-inline">
                        <b-form-select
                            v-model="localType"
                            :options="typeList"
                            :disabled="itemAdded || !editAllowed"
                        >
                            <template #first>
                                <b-form-select-option value=""
                                    >--
                                    {{
                                        $t('com_selecteer', {
                                            ns: 'common'
                                        })
                                    }}
                                    --</b-form-select-option
                                >
                            </template>
                        </b-form-select>
                        <b-button
                            v-if="!showActivities && editAllowed"
                            @click="addActivity('NoActivity')"
                            class="bgc_aux_normal"
                            ><font-awesome-icon icon="arrow-down" size="1x" />
                        </b-button>
                    </div>
                </b-col>
            </b-row>

            <b-row v-if="showActivities">
                <b-col sm="3">
                    <label>
                        {{
                            $t('prod_add', {
                                ns: 'production',
                                val: $t('prod_activity', {
                                    ns: 'production'
                                }).toLowerCase()
                            })
                        }}
                        :</label
                    >
                </b-col>
                <b-col sm="9">
                    <div class="form-inline">
                        <b-form-select
                            v-model="localActivity"
                            :options="activityList"
                            :disabled="!editAllowed"
                        >
                            <template #first>
                                <b-form-select-option value=""
                                    >--
                                    {{
                                        $t('com_selecteer', {
                                            ns: 'common'
                                        })
                                    }}
                                    --</b-form-select-option
                                >
                            </template>
                        </b-form-select>
                        <b-button
                            v-if="editAllowed"
                            @click="addActivity()"
                            class="bgc_aux_normal"
                            ><font-awesome-icon icon="arrow-down" size="1x" />
                        </b-button>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-form-textarea
                    v-model="activiteitOpmerking"
                    :placeholder="$t('com_opmerking', { ns: 'common' })"
                    rows="3"
                    max-rows="6"
                ></b-form-textarea>
            </b-row>
        </b-card>
        <b-tabs card>
            <b-card>
                <b-tab :title="$t('prod_activities', { ns: 'production' })">
                    <b-card-text>
                        <maintenance-items
                            v-bind:maintenanceItemlist="getmaintenanceList"
                        />
                    </b-card-text>
                </b-tab>
                <b-tab :title="$t('rec_pattern', { ns: 'recurrency' })">
                    <b-card-text>
                        <recurrency-base
                            :editAllowed="magOpslaan"
                            :pattern="herhaling"
                            @changed="setChangePattern"
                            @deleted="setDeletePattern"
                        />
                    </b-card-text>
                </b-tab>
            </b-card>
        </b-tabs>
    </b-modal>
</template>

<script>
import { Onderhoud, OnderhoudLijst } from '../../models/Onderhoud';
import Helper from '../../models/Helper';
import { loadItem, loadList } from '../../models/DataHelper';
import MaintenanceItems from './MaintenanceItems.vue';
import { RecurrencyBase } from '../recurrency';

export default {
    name: 'MaintenancePopForm',
    data() {
        return {
            showDismissibleAlert: false,
            alertText: '',
            readonly: false,
            isChanged: false,
            objectOnderhoud: null,
            activiteitOpmerking: '',
            localActivity: window.constants.BASE_MAINTENANCEACTIVITY,
            localType: window.constants.BASE_MAINTENANCETYPE,
            localDisplayNumber: '',
            savePattern: null,
            deletePattern: null,
            localOst: null,
            herhaling: null,
            localApparaat: null,
            tijdstip: null,
            localLocation: null,
            localReiniging: null,
            maintenanceList: [],
            locationList: [],
            apparatenList: [],
            wrongelbereiderList: [],
            activiteitenLijst: [],
            typeLijst: []
        };
    },
    components: {
        MaintenanceItems,
        RecurrencyBase
    },
    model: {
        prop: 'visible',
        event: 'change'
    },
    props: {
        displayNumber: {
            type: String,
            required: false
        },
        visible: {
            type: Boolean,
            default: false
        },
        ostID: {
            type: Number,
            required: false
        },
        editAllowed: {
            type: Boolean,
            default: false
        },
        inItem: {
            type: OnderhoudLijst,
            required: false
        }
    },
    computed: {
        formtitle() {
            return Helper.stringformat(
                this.$t('prod_add', {
                    ns: 'production',
                    val: this.$t('prod_onderhoud', {
                        ns: 'production'
                    }).toLowerCase()
                }),
                this.$t('prod_reiniging', {
                    ns: 'production'
                }).toLowerCase()
            );
        },
        activityList() {
            const flexList =
                this.activiteitenLijst.length > 0
                    ? this.activiteitenLijst.map((x) => ({
                          value: parseInt(x.id),
                          text: x.naam
                      }))
                    : [];

            return this.localType == window.constants.MAINTENANCETYPE_CLEANING
                ? flexList.filter((x) => x.value < 10)
                : flexList.filter((x) => x.value > 9);
        },
        typeList() {
            let flexList = [];
            flexList =
                this.typeLijst.length > 0
                    ? this.typeLijst.map((x) => ({
                          value: parseInt(x.id),
                          text: x.naam
                      }))
                    : [];
            return flexList;
        },
        itemAdded() {
            return this.maintenanceList.length > 0;
        },
        getmaintenanceList() {
            return this.maintenanceList;
        },
        ostLabel() {
            return this.$t('prod_voorobject', {
                ns: 'production',
                val: this.$t('prod_ost', {
                    ns: 'production'
                }).toLowerCase()
            });
        },
        locationLabel() {
            let result = '';
            switch (this.localDisplayNumber) {
                case 'silo':
                    result = this.$t('prod_silo', {
                        ns: 'production'
                    });
                    break;
                case 'loc':
                    result = this.$t('com_location', {
                        ns: 'common'
                    });
                    break;
                case 'eq':
                    result = this.$t('prod_apparaat', {
                        ns: 'production'
                    });
                    break;
                default: {
                    result = this.$t('prod_onbekend', {
                        ns: 'production'
                    });
                    break;
                }
            }
            return this.$t('prod_voorobject', {
                ns: 'production',
                val: result.toLowerCase()
            });
        },
        showActivities() {
            return this.localType >= window.constants.MAINTENANCETYPE_CLEANING;
        },
        magOpslaan() {
            const test = this.inItem == undefined || (this.inItem?.editAllowed) ;
            return test;
        }
    },
    methods: {
        hide() {
            this.opmerking = '';
            this.$emit('hide');
        },
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        addActivity(hoe) {
            let activity = null;
            let typ = null;
            this.isChanged = true;
            typ = this.$store.getters['maintenance/getOnderhoudsTypeByID'](
                this.localType
            );
            const findID = hoe ? 0 : this.localActivity;
            activity =
                this.$store.getters['maintenance/getOnderhoudsActivityByID'](
                    findID
                );
            const nieuwOnderhoud = new Onderhoud({});
            (nieuwOnderhoud.tijdstip = this.tijdstip),
                (nieuwOnderhoud.locatieID = this.localLocation),
                (nieuwOnderhoud.wbID = this.localOst),
                (nieuwOnderhoud.apparaatID = this.localApparaat),
                (nieuwOnderhoud.ondType = typ),
                (nieuwOnderhoud.ondActiviteit = activity),
                (nieuwOnderhoud.opmerking = this.activiteitOpmerking);

            this.maintenanceList.push(nieuwOnderhoud);
            this.activiteitOpmerking = '';
        },
        checkFormValidity() {
            let tekst = '';
            if (this.objectOnderhoud)
                tekst += this.checkObjectValidity(this.objectOnderhoud);
            const valid = !tekst;
            return valid;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        async handleSubmit() {
            if (this.isChanged || this.displayNumber == '??') {
                if (
                    (this.localLocation == undefined &&
                        this.localApparaat == undefined &&
                        this.localOst == undefined) ||
                    this.maintenanceList.length == 0
                ) {
                    this.showAlert(
                        this.$t('com_ongeldigeinput', { ns: 'common' })
                    );
                    return;
                }
                // Exit when the form isn't valid
                if (!this.checkFormValidity()) {
                    return;
                }
                if (this.inItem) {
                    this.saveThisOnderhoudsLijst();
                } else {
                    await this.saveThisOnderhoud();
                }
                this.$emit('reiniging_refresh');
            }
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-itemMaintenance');
            });
        },
        async saveThisOnderhoudsLijst() {
            if (this.savePattern)
                this.localReiniging.herhaling = this.savePattern;
            if (this.deletePattern)
                this.localReiniging.herhaling = this.deletePattern;
            const MaintenanceModel = this.localReiniging.SetForAdd(
                this.activiteitOpmerking
            );
            if (MaintenanceModel)
                await loadItem(
                    'maintenance/addMaintenance',
                    MaintenanceModel,
                    this.$t
                );
            return true;
        },
        async saveThisOnderhoud() {
            let MaintenanceModel = null;
            for (let i = 0; i < this.maintenanceList.length; i++) {
                if (i == 0) {
                    if (this.savePattern)
                        this.maintenanceList[i].herhaling = this.savePattern;
                    if (this.deletePattern)
                        this.maintenanceList[i].herhaling = this.deletePattern;
                    MaintenanceModel = this.maintenanceList[i].SetForAdd();
                }
                const act = this.maintenanceList[i].ondActiviteit
                    ? this.maintenanceList[i].ondActiviteit.id
                    : null;
                const mItem = {
                    remark: this.maintenanceList[i].opmerking,
                    type: this.maintenanceList[i].ondType.id,
                    activity: act
                };
                MaintenanceModel.Items.push(mItem);
            }
            if (MaintenanceModel)
                await loadItem(
                    'maintenance/addMaintenance',
                    MaintenanceModel,
                    this.$t
                );
            return true;
        },
        setChangePattern(data) {
            if (data) {
                this.savePattern = data;
                this.isChanged = true;
            } 
        },
        async setDeletePattern(data) {
            if (data) {
                const bericht = this.$t('com_delete', {
                    ns: 'common',
                    val: this.$t('rec_pattern', { ns: 'recurrency' })
                });
                const doClose = await this.$root.showConfirmationBox(bericht);
                if (doClose) {
                    this.deletePattern = data;
                    this.isChanged = true;
                } else {
                    this.deletePattern = null;
                    this.isChanged = true;
                }
            } 
        },
        async setOnderhoudsActiviteiten() {
            this.activiteitenLijst = await loadList(
                'maintenance/getOnderhoudsActivitiesLijst',
                'maintenance/loadMaintenanceActivities',
                null,
                true,
                this.$t
            );
        },
        async setOnderhoudsTypes() {
            this.typeLijst = await loadList(
                'maintenance/getOnderhoudsTypeLijst',
                'maintenance/loadMaintenaceTypes',
                null,
                true,
                this.$t
            );
        }
    },
    mounted() {
        this.tijdstip = new Date();
        if (this.localReiniging) {
            this.localLocation = this.localReiniging.locatieID;
            this.localApparaat = this.localReiniging.apparaatID;
            this.localOst = this.localReiniging.wbID;
            this.herhaling = this.localReiniging.herhaling;
            if (this.herhaling) {
                this.herhaling.eerstekeer = this.localReiniging.eersteKeer;
                this.herhaling.volgendekeer = this.localReiniging.volgendeKeer;
            }
            this.localActivity = this.localReiniging.regels[0].activteitID;
            this.localType = this.localReiniging.regels[0].typeID;
            for (let i = 0; i < this.localReiniging.regels.length; i++) {
                const item = this.localReiniging.regels[i];
                const typ = this.$store.getters[
                    'maintenance/getOnderhoudsTypeByID'
                ](item.typeID);
                const activity = this.$store.getters[
                    'maintenance/getOnderhoudsActivityByID'
                ](item.activteitID);
                item.ondType = typ;
                item.ondActiviteit = activity;
            }
            this.maintenanceList = this.localReiniging.regels;
        } else {
            this.localLocation = null;
            this.localApparaat = null;
            this.localOst = this.ostID;
            this.localActivity = window.constants.BASE_MAINTENANCEACTIVITY;
            this.localType = window.constants.BASE_MAINTENANCETYPE;
        }
    },
    async created() {
        if (this.inItem) {
            this.localReiniging = this.inItem;
            if (this.localReiniging.apparaatID) {
                this.localDisplayNumber = 'eq';
            } else {
                if (this.localReiniging.wbID) {
                    this.localDisplayNumber = 'ost';
                } else {
                    this.localDisplayNumber = 'loc';
                }
            }
        } else {
            this.localReiniging = null;
            this.localDisplayNumber = this.displayNumber;
        }
        if (this.localDisplayNumber == 'ost') {
            //  haal de wrongelbereiders op
            this.wrongelbereiderList = await loadList(
                'masterdata/getOstLijst',
                'masterdata/loadOsts',
                null,
                true,
                this.$t
            );
        } else {
            if (this.localDisplayNumber == 'eq') {
                this.apparatenList = await loadList(
                    'masterdata/getEquipmentLijst',
                    'masterdata/loadEquipments',
                    null,
                    true,
                    this.$t
                );
            } else {
                const payload = {
                    data: null,
                    filter: this.localDisplayNumber
                };
                await loadItem('masterdata/loadLocations', payload, this.$t);
                this.locationList =
                    this.$store.getters['masterdata/getLocationLijst'];
            }
        }
        await this.setOnderhoudsActiviteiten();
        await this.setOnderhoudsTypes();
    }
};
</script>

