import { api } from '../api';
import { showBadToast, showGoodToast } from '../../errors/toast';
import logMessage from '../../errors/foutlog';
import {
    ProdRun,
    Bak,
    BakIngredient,
    BakInfo,
    BakInfoHist,
    BakDefaults,
    CheeseMarks,
    AlternativeCheese
} from '../../../models/BakInfo';
import { ActiviteitenLog } from '../../../models/ActiviteitenLog';
import { InpekelItem } from '../../../models/BakInfo_helper';
import { Product } from '../../../models/Product';
const actions = {
    async loadBatch(context, data) {
        let item = null;
        try {
            await api.fetchBatch(data.data).then((response) => {
                item = new Bak(response.data);
            });
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_bak', { ns: 'production' })
                }),
                error
            );
        }
        context.commit('insertSelectedBatch', item);
        return item;
    },
    async loadBatchActivityLog(context, data) {
        let tempList = [];
        try {
            await api.fetchBatchActivityLog(data.data).then((response) => {
                tempList = response.data.map((x) => new ActiviteitenLog(x));
            });
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_activities', { ns: 'production' })
                }),
                error
            );
        }
        context.commit('insertSelectedBatchActivities', tempList);
        return tempList;
    },
    async deleteBatch(context, data) {
        try {
            await api.deleteBatch(data.data);
            showGoodToast(
                context,
                data.func('com_verwijderd', {
                    ns: 'common',
                    val: data.func('prod_bak', { ns: 'production' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data.func('prod_bak', { ns: 'production' })
                }),
                error
            );
        }
    },
    async destroyBatch(context, data) {
        try {
            await api.destroyBatch(data.data);
            showGoodToast(
                context,
                data.func('com_verwijderd', {
                    ns: 'common',
                    val: data.func('prod_bak', { ns: 'production' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data.func('prod_bak', { ns: 'production' })
                }),
                error
            );
        }
    },
    async updateBatch(context, data) {
        try {
            let item = null;
            await api.updateBatch(data.data).then((response) => {
                item = new Bak(response.data);
            });
            context.commit('insertSelectedBatch', item);
            return item;
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data
                        .func('prod_bak', { ns: 'production' })
                        .toLowerCase()
                }),
                error
            );
        }
    },
    async loadBatchInfo(context, data) {
        let tempList = [];
        try {
            await api.fetchBatchInfo(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new BakInfo(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_bakinfo', { ns: 'production' })
                }),
                error
            );
        }
        context.commit('insertBatchInfoLijst', tempList);
    },
    async loadBatchInfoOverPeriod(context, data) {
        let tempList = [];
        try {
            await api.fetchBatchInfoOverPeriod(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new BakInfoHist(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_bakinfo', { ns: 'production' })
                }),
                error
            );
        }
        context.commit('insertBatchInfoHistLijst', tempList);
    },
    async addBatches(context, data) {
        try {
            await api.addBatches(data.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('prod_bakken', { ns: 'production' })
                }) + ' ' + data.data.batches
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('prod_bakken', { ns: 'production' })
                }),
                error
            );
        }
    },
    async addStarterSubType(context, data) {
        try {
            await api.setStarterSubType(data.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('prod_zuurseltype', { ns: 'production' })
                })
            );
            return true;
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('prod_zuurseltype', { ns: 'production' })
                }),
                error
            );
            return false;
        }
    },
    async addBatchingredients(context, data) {
        let item = null;
        try {
            const response = await api.addBatchIngredients(data.data);
            item = response.data;
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data
                        .func('prod_ingredient', { ns: 'production' })
                        .toLowerCase()
                }),
                error
            );
        }
        return item;
    },
    async loadBatchIngredients(context, data) {
        let tempList = [];
        try {
            await api.fetchBatchIngredients(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new BakIngredient(x));
            });
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_ingredienten', { ns: 'production' })
                }),
                error
            );
        }
        context.commit('insertBatchIngredientenLijst', tempList);
        return tempList;
    },
    async loadBatchIngredient(context, data) {
        let item = null;
        try {
            await api.fetchBatchIngredient(data.data).then((response) => {
                item = new BakIngredient(response.data);
            });
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_ingredient', { ns: 'production' })
                }),
                error
            );
        }
        context.commit('insertSelectedBatchIngredient', item);
        return item;
    },
    async deleteBatchIngredient(context, data) {
        try {
            await api.deleteBatchIngredient(data.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data.func('prod_ingredient', { ns: 'production' })
                }),
                error
            );
        }
    },

    async loadBatchInbrineAmounts(context, data) {
        let tempList = [];
        try {
            const response = await api.fetchBatchInbrineAmounts(data.data);
            const bakId = response.data.batchID;
            tempList = response.data.amounts.map((x) => {
                const res = new InpekelItem(x);
                res.bakId = bakId;
                return res;
            });
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_inpekelinfo', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertInpekelBatch', tempList);
        return tempList;
    },
    async loadBatchAlternativeCheeseTypes(context, data) {
        let tempList = [];
        try {
            await api.fetchAlternativeCheeseTypes(data.data).then((response) => {
                tempList = response.data.map((x) => new AlternativeCheese(x));
            });
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_alternatieven', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertAlternativeCheese', tempList);
        return tempList;
    },
    async loadBatchDefaults(context, data) {
        let item = null;
        try {
            const response = await api.fetchBatchDefaults(data.data);
            item = new BakDefaults(response.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_bakdefault', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertBatchdefaults', item);
        return item;
    },
    async loadMilkusagefactor(context, data) {
        let getal = null;
        try {
            const response = await api.fetchMilkusagefactoForBatch(data.data);
            getal = response.data;
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_melkfactor', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertMilkusagefactor', getal);
        return getal;
    },
    async loadMilkInfo(context, data) {
        let tempList = [];
        try {
            const response = await api.fetchMilkInfoForDay(data.data);
            tempList = response.data.map((x) => new Product(x));
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_melktype', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertMilkLijst', tempList);
        return tempList;
    },
    async loadStarterInfo(context, data) {
        let tempList = [];
        try {
            const response = await api.fetchStarterSubtypesForDay(data.data);
            tempList = response.data;
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_zuursel', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return tempList;
    },
    async updateBatchInBrine(context, data) {
        try {
            await api.updateBatchInBrine(data.data);
            return true;
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data
                        .func('prod_inpekel', { ns: 'production' })
                        .toLowerCase()
                }),
                error
            );
        }
    },
    async updateBatchMarks(context, data) {
        try {
            await api.setCheeseMarks(data.data);
            return true;
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('com_marks', { ns: 'common' }).toLowerCase()
                }),
                error
            );
        }
    },
    // async loadCheeseMarksForProdrun(context, data) {
    //     let tempList = [];
    //     try {
    //         tempList = await api.fetchCheeseMarksForProdrun(data);
    //         return tempList;
    //     } catch (error) {
    //         showBadToast(context, 'Melding ophalen kaasmerken', error);
    //     }
    // },
    async loadCheeseMarksForBatch(context, data) {
        let item = null;
        try {
            await api.fetchCheeseMarksForBatch(data.data).then((response) => {
                item = new CheeseMarks(response.data);
            });
            context.commit('insertMarksForBatch', item);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_marks', { ns: 'common' })
                }),
                error
            );
        }
        return item;
    },
    async loadMarkType(context, data) {
        let item = null;
        try {
            item = await api.fetchCheeseMarkType(data.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_marks', { ns: 'common' })
                }),
                error
            );
        }
        return item;
    },
    async saveBakTimer(context, data) {
        if (data.data) {
            const tempList = context.state.timerList;
            const index = tempList.findIndex((x) => x.bakID == data.bakID);
            if (index < 0) {
                tempList.push(data.data);
            } else {
                tempList[index] = data.data;
            }
            context.commit('insertTimerLijst', tempList);
        }
    },
    async loadProdrun(context, data) {
        let item = null;
        try {
            const response = await api.fetchProdRun(data.data);
            item = new ProdRun(response.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_production', { ns: 'common' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertProdrun', item);
        return item;
    }
};

export default actions;

