const getters = {
    getMaintenanceInfoLijst: (state) => {
        return state.maintenanceInfoList;
    },
    getMaintenanceLijst: (state) => {
        return state.maintenanceList;
    },
    getSelectedMaintenance: state =>  {
        return state.selectedMaintenance;
    },    
    getOnderhoudsActivitiesLijst: state =>  {
        return state.onderhoudsActivitiesLijst;
    },   
    getOnderhoudsActivityByID: state => (id) => {
        return state.onderhoudsActivitiesLijst.find(x => x.id === id);
    },   
    getOnderhoudsActivityByDescription: state => (description) => {
        return state.onderhoudsActivitiesLijst.find(x => x.description.toLowerCase() === description.toLowerCase());
    }, 
    getOnderhoudsTypeLijst: state =>  {
        return state.onderhoudsTypesLijst;
    },   
    getOnderhoudsTypeByID: state => (id) => {
        return state.onderhoudsTypesLijst.find(x => x.id === id);
    },   
    getOnderhoudsTypeByDescription: state => (description) => {
        return state.onderhoudsTypesLijst.find(x => x.description.toLowerCase() === description.toLowerCase());
    }
    
};

export default getters;

