<template>
    <b-card no-body :header="$t('prod_vormen', { ns: 'production' })">
        <div>
            <b-card>
                <b-row>
                    <b-col sm="3">
                        <label for="input-itemnaam">{{ $t('com_naam', { ns: 'common' }) }}:</label>
                    </b-col>
                    <b-col sm="9">
                        <b-form-input
                            id="input-itemnaam"
                            :placeholder="$t('com_naam', { ns: 'common' })"
                            v-model="naam"
                            :disabled="readonly"
                            @change="setChangedNaam"
                            :state="naam != undefined && naam.length > 2"
                            autofocus
                        ></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="3">
                        <label for="input_vorm">{{$t('prod_vormnaam', { ns: 'production' })}}: </label>
                    </b-col>
                    <b-col sm="9">
                        <b-form-input
                            id="input-vorm"
                            :placeholder="$t('prod_vormnaam', { ns: 'production' })"
                            v-model="vorm"
                            @change="setChangedVorm"
                            :disabled="readonly"
                        ></b-form-input>
                    </b-col>
                    <b-col sm="3">
                        <label for="weight">{{$t('prod_gewichtkaas', { ns: 'production' })}}: </label>
                    </b-col>
                    <b-col sm="9">
                        <b-form-input
                            id="input-weight"
                            :placeholder="$t('prod_gewichtkaas', { ns: 'production' })"
                            type="number"
                            step="0.5"
                            v-model.number="gewicht"
                            @change="set_inputGewicht"
                            :disabled="readonly"
                            :state="validGewicht"
                        ></b-form-input>
                    </b-col>
                </b-row>
            </b-card>
        </div>
    </b-card>
</template>

<script>
import { Vorm } from '../../models/MasterData';

export default {
    name: 'CardVorm',
    data() {
        return {
            gewicht: null,
            naam: '',
            vorm: ''
        };
    },
    components: {},
    model: {
        prop: 'theVorm',
        event: 'changed'
    },
    props: {
        theVorm: {
            type: Vorm,
            default: null
        },
        editAllowed: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        validGewicht() {
            return this.gewicht
                ? (this.gewicht > 0.1 && this.gewicht < 120)
                : true;
        },
        readonly() {
            return !this.editAllowed;
        }
    },
    methods: {
        set_inputGewicht: function() {
            this.theVorm.gewicht = this.gewicht;
            this.setChanged();
        },
        setChangedNaam: function() {
            this.theVorm.naam = this.naam;
            this.setChanged();
        },
        setChangedVorm: function() {
            this.theVorm.vorm = this.vorm;
            this.setChanged();
        },
        setChanged: function() {
            this.$emit(
                'validComponent',
                this.validGewicht && this.theVorm.naam != undefined
            );
            this.$emit('change', true);
        }
    },
    mounted() {
        this.gewicht = this.theVorm.gewicht;
        this.naam = this.theVorm.naam;
        this.vorm = this.theVorm.vorm;
    },
    created() {}
};
</script>
