const getters = {
    getOutbrineInfoLijst: (state) => {
        return state.outbrineInfoLijst;
    },
    getOutbrineInfoHistLijst: (state) => {
        return state.outbrineInfoHistLijst;
    },
    getOutbrineForBGLijst: (state) => {
        return state.outbrineForBGLijst;
    },
    getInbrineForBGLijst: (state) => {
        return state.inbrineForBGLijst;
    },
    getOutbrineInfoBGLijst: (state) => {
        return state.outbrineInfoBGLijst;
    },

    
};

export default getters;

