import Api from '../../../api';
import store from '../../../store';

// entityobject
const fetchEntityObjectByTypename = async (data) => {
    return fetchInfo('/Analytics/EntityObject/GetEntityObjects', data);
};

// DateTime productiondate
const fetchEntityObjectForCheese = async (data) => {
    return fetchInfo('/Analytics/EntityObject/GetCheeseObjects', data);
};

// int key, string typeName
const fetchEntityObjectKeyTypeName = async (data) => {
    return fetchInfo(
        '/Analytics/EntityObject/GetEntityObjectByKeyAndTypeName',
        data
    );
};

// int entityObjectID
const fetchEntityObject = async (data) => {
    return fetchInfo('/Analytics/EntityObject/GetEntityObject', data);
};

// entityobject
const fetchUserDefinedTypeNames = async (data) => {
    return fetchInfo('/Analytics/EntityObject/GetUserDefinedTypeNames', data);
};

const addEntityObject = async (data) => {
    return await Api(store).put(
        '/Analytics/EntityObject/RegisterEntityObject',
        data
    );
};

// int entityobjectID
const deleteEntityObject = async (data) => {
    return deleteInfo('/Analytics/EntityObject/DeleteEntityObject', data);
};

// InvestigationQuantity
const fetchMeasurableQuantities = async (data) => {
    return fetchInfo(
        '/Analytics/InvestigationQuantity/GetMeasurableQuantities',
        data
    );
};

const fetchInvestigationQuantities = async (data) => {
    return fetchInfo(
        '/Analytics/InvestigationQuantity/GetInvestigationQuantities',
        data
    );
};

// int investigationquantityID
const fetchInvestigationQuantity = async (data) => {
    return fetchInfo(
        '/Analytics/InvestigationQuantity/GetInvestigationQuantity',
        data
    );
};

// int investigationquantityID
const deleteInvestigationQuantity = async (data) => {
    return deleteInfo(
        '/Analytics/InvestigationQuantity/DeleteInvestigationQuantity',
        data
    );
};

// int investigationquantityID
const fetchIQActivities = async (data) => {
    return fetchInfo(
        '/Analytics/InvestigationQuantity/GetInvestigationQuantityActivityLog',
        data
    );
};

const addInvestigationQuantity = async (data) => {
    return await Api(store).put(
        '/Analytics/InvestigationQuantity/StoreInvestigationQuantity',
        data
    );
};

const fetchTemplates = async (data) => {
    return fetchInfo('/Analytics/InvestigationQuantity/GetTemplates', data);
};

// int templateID
const fetchTemplate = async (data) => {
    return fetchInfo('/Analytics/InvestigationQuantity/GetTemplate', data);
};

const saveTemplate = async (data) => {
    return await Api(store).put(
        '/Analytics/InvestigationQuantity/StoreTemplate',
        data
    );
};
// Executor
const fetchExecutors = async (data) => {
    return fetchInfo('/Analytics/Executor/GetExecutors', data);
};
// Executor
const fetchExecutorQuantities = async (data) => {
    return fetchInfo('/Analytics/Executor/GetExecutorQuantities', data);
};
// Executor
const fetchExecutorQualities = async (data) => {
    return fetchInfo('/Analytics/Executor/GetExecutorQualities', data);
};
//int executorID
const fetchDevice = async (data) => {
    return fetchInfo('/Analytics/Executor/GetDevice', data);
};
//int executorID
const fetchOrganisation = async (data) => {
    return fetchInfo('/Analytics/Executor/GetOrganisation', data);
};
//int executorID
const fetchPerson = async (data) => {
    return fetchInfo('/Analytics/Executor/GetPerson', data);
};
// DeviceModel
const saveDevice = async (data) => {
    return await Api(store).put('/Analytics/Executor/StoreDevice', data);
};
// OrganisationModel
const saveOrganisation = async (data) => {
    return await Api(store).put('/Analytics/Executor/StoreOrganisation', data);
};
// PersonModel
const savePerson = async (data) => {
    return await Api(store).put('/Analytics/Executor/StorePerson', data);
};
// int executorID
const deleteExecutor = async (data) => {
    return deleteInfo('/Analytics/Executor/DeleteExecutor', data);
};

// norms
const fetchNorms = async (data) => {
    return fetchInfo('/Analytics/Norm/GetNorms', data);
};
// int entityObjectID, int invQuantityID
const fetchNorm = async (data) => {
    return fetchInfo('/Analytics/Norm/GetNorm', data);
};
// int eonormID
const fetchNormById = async (data) => {
    return fetchInfo('/Analytics/Norm/GetNormByID', data);
};

const saveNorm = async (data) => {
    return await Api(store).put('/Analytics/Norm/StoreNorm', data);
};

// int eonormID
const fetchNormActivities = async (data) => {
    return fetchInfo('/Analytics/Norm/GetNormActivityLog', data);
};

// int eonormID
const deleteNorm = async (data) => {
    return deleteInfo('/Analytics/Norm/DeleteNorm', data);
};
// masterdata
const fetchQuantities = async (data) => {
    return fetchInfo('/Analytics/MasterData/GetQuantities', data);
};

const fetchQunits = async (data) => {
    return fetchInfo('/Analytics/MasterData/GetQUnits', data);
};

const fetchQualities = async (data) => {
    return fetchInfo('/Analytics/MasterData/GetQualities', data);
};

const fetchQlabels = async (data) => {
    return fetchInfo('/Analytics/MasterData/GetQualityLabels', data);
};

// Measurement
// DateTime from, DateTime until
const fetchMeasurements = async (data) => {
    return fetchInfo('/Analytics/Measurement/GetMeasurements', data);
};

// int measurementID
const fetchMeasurement = async (data) => {
    return fetchInfo('/Analytics/Measurement/GetMeasurement', data);
};

// int entityObjectID, int investigationQuantityID
const fetchLastMeasurement = async (data) => {
    return fetchInfo('/Analytics/Measurement/GetLastMeasurement', data);
};

// int measurementID
const deleteMeasurement = async (data) => {
    return deleteInfo('/Analytics/Measurement/DeleteMeasurement', data);
};

// int measurementID
const fetchMeasurementActivities = async (data) => {
    return fetchInfo('/Analytics/Measurement/GetMeasurementActivityLog', data);
};

//MeasurementModel mm
const saveMaesurement = async (data) => {
    return await Api(store).put(
        '/Analytics/Measurement/StoreMeasurement',
        data
    );
};

// Assesment
// DateTime from, DateTime until
const fetchAssesments = async (data) => {
    return fetchInfo('/Analytics/Assesment/GetAssesments', data);
};

// int assesmentid
const fetchAssesment = async (data) => {
    return fetchInfo('/Analytics/Assesment/GetAssesment', data);
};

// int assesmentid
const fetchAssesmentActivities = async (data) => {
    return fetchInfo('/Analytics/Assesment/GetAssesmentActivityLog', data);
};

// AssesmentModel am
const saveAssesment = async (data) => {
    return await Api(store).put('/Analytics/Assesment/StoreAssesment', data);
};
// int assesmentID
const deleteAssesment = async (data) => {
    return deleteInfo('/Analytics/Measurement/DeleteAssesment', data);
};

// Samples
// DateTime from, DateTime until
const fetchSamples = async (data) => {
    return fetchInfo('/Analytics/Sample/GetSamples', data);
};

// int sampleID
const fetchSample = async (data) => {
    return fetchInfo('/Analytics/Sample/GetSample', data);
};

const fetchSampleTypes = async (data) => {
    return fetchInfo('/Analytics/MasterData/GetSampleTypes', data);
};

// int sampleID
const deleteSample = async (data) => {
    return deleteInfo('/Analytics/Sample/DeleteSample', data);
};

// int sampleID
const fetchSampleActivities = async (data) => {
    return fetchInfo('/Analytics/Sample/GetSampleActivityLog', data);
};

//SampleModel sm
const saveSample = async (data) => {
    return await Api(store).put('/Analytics/Sample/StoreSample', data);
};

// FileUpLoadModel pm
const storeAttachment = async (data) => {
    const headers = {
        'Content-Type': 'multipart/form-data' //;boundary=<calculated when request is sent></calculated>
    };
    return await Api(store, headers).post(
        '/Analytics/Attachment/StoreAttachment',
        data
    );
};
// int id, int attachmentType
const fetchAttachmentInfo = async (data) => {
    return fetchInfo('/Analytics/Attachment/GetAttachmentInfo', data);
};
// int attachmentID, int id, int attachmentType
const fetchAttachment = async (data) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Content-Disposition': 'attachment;'
    };
    const responseType = 'arraybuffer';
    return fetchInfoAtt(
        '/Analytics/Attachment/GetAttachment',
        data,
        headers,
        responseType
    );
};

//  int productionID, int attachmentID
const deleteAttachment = async (data) => {
    return deleteInfo('/Analytics/Attachment/DeleteAttachment', data);
};

const fetchInfoAtt = async (apicall, data, headers, responsetype) => {
    if (data != undefined) {
        return await Api(store).get(
            apicall +
                '?' +
                Object.keys(data)
                    .map(
                        (k) =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: headers,
                responseType: responsetype
            }
        );
    } else {
        return await Api(store).get(apicall, {
            headers: headers,
            responseType: responsetype
        });
    }
};

const deleteInfo = async (apicall, data) => {
    if (data != undefined) {
        return await Api(store).delete(
            apicall +
                '?' +
                Object.keys(data)
                    .map(
                        (k) =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    } else {
        return await Api(store).get(apicall, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    }
};

const fetchInfo = async (apicall, data) => {
    if (data != undefined) {
        return await Api(store).get(
            apicall +
                '?' +
                Object.keys(data)
                    .map(
                        (k) =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    } else {
        return await Api(store).get(apicall, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    }
};

export default {
    fetchEntityObjectByTypename,
    fetchEntityObjectForCheese,
    fetchEntityObjectKeyTypeName,
    fetchUserDefinedTypeNames,
    fetchEntityObject,
    addEntityObject,
    deleteEntityObject,
    fetchMeasurableQuantities,
    fetchInvestigationQuantities,
    fetchIQActivities,
    fetchExecutors,
    fetchExecutorQuantities,
    fetchExecutorQualities,
    fetchDevice,
    fetchOrganisation,
    fetchPerson,
    saveDevice,
    saveOrganisation,
    savePerson,
    deleteExecutor,
    fetchTemplates,
    fetchTemplate,
    saveTemplate,
    fetchQuantities,
    fetchQunits,
    fetchQualities,
    fetchQlabels,
    fetchMeasurements,
    fetchMeasurement,
    fetchLastMeasurement,
    fetchMeasurementActivities,
    deleteMeasurement,
    fetchNorm,
    fetchNormById,
    fetchNorms,
    saveNorm,
    deleteNorm,
    fetchNormActivities,
    saveMaesurement,
    fetchAssesments,
    fetchAssesment,
    fetchAssesmentActivities,
    saveAssesment,
    deleteAssesment,
    fetchInvestigationQuantity,
    deleteInvestigationQuantity,
    addInvestigationQuantity,
    fetchSamples,
    fetchSample,
    deleteSample,
    fetchSampleActivities,
    saveSample,
    fetchSampleTypes,
    storeAttachment,
    fetchAttachmentInfo,
    fetchAttachment,
    deleteAttachment
};

