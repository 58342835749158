var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-modal',{ref:"modal",attrs:{"id":"modal-confirmdetail","size":"xl","title":this.formtitle,"visible":_vm.visible},on:{"hidden":_vm.hide,"ok":_vm.handleOk}},[_c('b-card',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-alert',{attrs:{"variant":"danger","dismissible":""},model:{value:(_vm.showDismissibleAlert),callback:function ($$v) {_vm.showDismissibleAlert=$$v},expression:"showDismissibleAlert"}},[_vm._v(" "+_vm._s(_vm.alertText)+" ")]),_c('b-tabs',{attrs:{"card":""}},[_c('b-tab',{key:1,attrs:{"title":_vm.$t('prod_treatment', {
                        ns: 'production'
                    }),"active":""}},[_c('b-card-text',[_c('div',[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-input-group',{attrs:{"prepend":_vm.$t('prod_action', {
                                            ns: 'production'
                                        })}},[_c('b-form-input',{attrs:{"locale":"nl","readonly":""},model:{value:(_vm.behandeling),callback:function ($$v) {_vm.behandeling=$$v},expression:"behandeling"}})],1),_c('b-input-group',{attrs:{"prepend":_vm.$t('prod_uitgevoerd', {
                                            ns: 'production'
                                        })}},[_c('b-form-timepicker',{attrs:{"locale":"nl","disabled":!_vm.editAllowed},on:{"input":_vm.set_changed},model:{value:(_vm.uitvoerTijdstip),callback:function ($$v) {_vm.uitvoerTijdstip=$$v},expression:"uitvoerTijdstip"}})],1),(_vm.needsCoatingProduct)?_c('div',[(_vm.baseTAC.coatingproduct)?_c('div',[_c('b-input-group',{attrs:{"prepend":_vm.$t('prod_coating', {
                                                    ns: 'production'
                                                })}},[_c('b-form-select',{key:"rerenderkey",class:{
                                                    tc_aux_highlighted:
                                                        _vm.warnForStockAmount
                                                },attrs:{"disabled":!_vm.editAllowed},on:{"change":_vm.set_inputPartij},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":""}},[_vm._v("-- "+_vm._s(_vm.$t( 'com_selecteer', { ns: 'common' } ))+" --")])]},proxy:true}],null,false,3481965277),model:{value:(_vm.partijID),callback:function ($$v) {_vm.partijID=$$v},expression:"partijID"}},_vm._l((_vm.itemStockList),function(option){return _c('option',{key:option.value,class:{
                                                        tc_aux_highlighted:
                                                            option.warn
                                                    },domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.text)+" ")])}),0)],1)],1):_c('div',[_c('b',[_vm._v(_vm._s(_vm.strNoCoating))])])]):_vm._e(),_c('b-form-textarea',{attrs:{"id":"bakopmerking","placeholder":_vm.$t('com_opmerking', {
                                            ns: 'common'
                                        }),"rows":"3","max-rows":"6"},on:{"change":_vm.set_changed},model:{value:(_vm.refreshedTreatment.opmerking),callback:function ($$v) {_vm.$set(_vm.refreshedTreatment, "opmerking", $$v)},expression:"refreshedTreatment.opmerking"}})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('show-row-col',{attrs:{"showLabel":_vm.$t('com_kaassoort', {
                                            ns: 'common'
                                        }),"showObject":_vm.baseTAC.product}}),_c('show-row-col',{attrs:{"showLabel":_vm.$t('com_number', {
                                            ns: 'common'
                                        }),"showObject":_vm.baseTAC.startaantal}}),_c('show-row-col',{attrs:{"showLabel":_vm.$t('com_location', {
                                            ns: 'common'
                                        }),"showObject":_vm.baseTAC.locatie}}),_c('show-row-col',{attrs:{"showLabel":_vm.$t('prod_gepland', {
                                            ns: 'production'
                                        }),"showObject":_vm.planned}}),_c('hr'),_c('b-row',{staticClass:"mx-0 px-0 my-0 py-0"},[_c('b-table',{attrs:{"items":_vm.marksList,"fields":_vm.visibleMarksFields,"stacked":"md","show-empty":"","empty-text":_vm.$t('com_geen_data', {
                                                ns: 'common'
                                            }),"small":"","striped":"","responsive":""},scopedSlots:_vm._u([{key:"cell(startmerk)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.startcharacters)+"   "+_vm._s(data.item.startnumber)+" ")]}},{key:"cell(eindmerk)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.endcharacters)+"   "+_vm._s(data.item.endnumber)+" ")]}}])})],1)],1)],1)],1)])],1),_c('activity-log',{attrs:{"paramName":"measurementID","action":"treatment/loadTreatmentActiviteitenLijst","id":_vm.treatmentActivityId}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }