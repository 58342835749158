<template>
    <b-container fluid style="margin-top: 60px">
        <div>
            <b-dropdown
                v-if="checkRight('CheeseTreatmentCreate')"
                id="dropdown-form"
                :text="
                    $t('com_newx', {
                        ns: 'common',
                        val: $t('prod_treatment', {
                            ns: 'production'
                        }).toLowerCase()
                    })
                "
                ref="dropdown"
                size="sm"
            >
                <b-dropdown-form style="width: 340px">
                    <b-form-group :label="strTreamentFor" @submit.stop.prevent>
                        <span>
                            <label for="input-vatvorm"
                                >{{ $t('com_kaasvorm', { ns: 'common' }) }}:
                            </label>
                            <lookup-combo
                                id="input-vatvorm"
                                :placeholder="
                                    $t('com_kaasvorm', { ns: 'common' })
                                "
                                v-model="selectedVormID"
                                v-bind:forcerefresh="forcerefresh"
                                v-bind:combotype="lctypeShapes"
                            ></lookup-combo>
                        </span>
                        <span>
                            <label for="input-behandelrecept"
                                >{{
                                    $t('prod_treatmentschema', {
                                        ns: 'production'
                                    })
                                }}:
                            </label>
                            <lookup-combo
                                id="input-behandelrecept"
                                :placeholder="
                                    $t('prod_treatmentschema', {
                                        ns: 'production'
                                    })
                                "
                                v-model="selectedBehandelID"
                                v-bind:forcerefresh="forcerefresh"
                                v-bind:combotype="lctypeTreatmentRecipe"
                            ></lookup-combo>
                        </span>
                        <b-form-checkbox
                            id="cbPrefered"
                            v-model="preferedTreatment"
                        >
                            {{ $t('com_voorkeuze', { ns: 'common' }) }}
                        </b-form-checkbox>
                    </b-form-group>
                    <b-button size="sm" @click="createNewItem" class="mr-1"
                        ><font-awesome-icon icon="folder-plus" size="1x" />
                        {{ $t('prod_toevoegen', { ns: 'production' }) }}
                    </b-button>
                </b-dropdown-form>
            </b-dropdown>
        </div>

        <b-table
            :items="groupedDisplayList"
            :fields="visibleFields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            stacked="md"
            show-empty
            :empty-text="$t('com_geen_data', { ns: 'common' })"
            small
            striped
            @filtered="onFiltered"
            @row-clicked="toonBehandelingen"
        >
            <template v-slot:cell(actions)="row">
                <b-button
                    size="sm"
                    @click="toonBehandelingen(row.item)"
                    class="bgc_aux_unknown"
                    ><font-awesome-icon icon="info-circle" size="1x" />
                </b-button>
            </template>

            <template v-slot:row-details>
                <cheese-treatment-items
                    v-bind:inList="itemList"
                    @delete="deleteItem"
                />
            </template>
        </b-table>
    </b-container>
</template>

<script lang="ts">
import _ from 'lodash';
import { Permission } from '../../models/Permissions';
import { VueExtension } from '../../models/VueExtension';
import { defineComponent } from 'vue';
import { CheeseTypeTreatmentRecipe } from '@/models/CheeseTreatment';
import { LookupCombo } from '../components';
import CheeseTreatmentItems from './CheeseTreatmentItems.vue';

export default defineComponent({
    name: 'CheeseTreatmentsBase',
    data: function () {
        return {
            lctypeShapes: window.constants.LCSHAPES,
            lctypeTreatmentRecipe: window.constants.LCTRR,
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [10, 20, 40, { value: 100, text: '100' }],
            filter: null,
            filterOn: [],
            newPrintVersion: false,
            geprint: false,
            localList: [],
            displayFilterCount: 1,
            aangeraakt: 0,
            selectedVormID: -1,
            selectedBehandelID: -1,
            preferedTreatment: false,
            forcerefresh: false,
            strOtherDefaults:
                this.$t('prod_warnotherdefault', { ns: 'production' }),
            strDubbel: 
                this.$t('prod_warnschemainlist', { ns: 'production' }),
            strTreamentFor:
                this.$t('prod_treatment', { ns: 'production' }) +
                ' ' +
                this.$t('com_voor', { ns: 'common' }).toLowerCase()
        };
    },
    props: {
        inItem: {
            type: Array<CheeseTypeTreatmentRecipe>,
            default: []
        }
    },
    components: {
        LookupCombo,
        CheeseTreatmentItems
    },
    computed: {
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                {
                    key: 'vorm',
                    label: this.$t('prod_vorm', { ns: 'production' }) ,
                    sortable: true,
                    visible: true
                },
                {
                    key: 'behandelingen.length',
                    label:  this.$t('com_number', { ns: 'common' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        displayList() {
            const b = this.aangeraakt;
            const a = this.inItem;
            a.forEach((t) => {
                this.$set(t, '_showDetails', false);
            });
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.totalRows = a ? a.length : 0;
            return a;
        },
        groupedDisplayList() {
            const a = this.aangeraakt;
            if (this.displayList == undefined) return null;
            const x = _(this.displayList.slice(0))
                .groupBy((x) => x.vormID)
                .map((value) => {
                    return {
                        id: value[0].id,
                        vorm: value[0].vorm,
                        behandelingen: value
                    };
                })
                .value();
            return _.sortBy(x, ['boektijdstip', 'locatie']);
        },
        itemList() {
            return this.localList;
        }
    },
    methods: {
        deleteItem(item) {
            this.$emit('delete', item);
            this.aangeraakt += 1;
        },
        async createNewItem() {
            // check dubbel toevoegen
            const dubbel = this.displayList.filter(
                (x) =>
                    x.vormID == this.selectedVormID &&
                    x.behandelreceptID == this.selectedBehandelID
            );
            if (dubbel.length > 0) {
                (this.$root as VueExtension).showMessageBox(this.strDubbel);
                return;
            }
            const a = new CheeseTypeTreatmentRecipe();
            const lijst1 = this.$store.getters['treatment/getTreatmentRecipes'];
            const o1 = lijst1?.find((x) => x.id == this.selectedBehandelID);
            const lijst2 =
                this.$store.getters['components/getShapesComboLijst'];
            const o2 = lijst2?.find((x) => x.value == this.selectedVormID);
            a.behandelreceptID = this.selectedBehandelID;
            a.behandelrecept = o1 ? o1.naam : null;
            a.vormID = this.selectedVormID;
            a.vorm = o2 ? o2.text : null;
            a.default = this.preferedTreatment;
            if (this.preferedTreatment) {
                // check of er niet een ander al op default staat
                const def = this.displayList.filter((x) => x.default);
                if (def.length > 0) {
                    const message = this.strOtherDefaults;
                    const doChange = await (
                        this.$root as VueExtension
                    ).showConfirmationBox(message);
                    if (doChange) {
                        this.$emit('resetdefault');
                        this.aangeraakt += 1;
                    } else {
                        a.default = false;
                    }
                }
            }
            this.$emit('add', a);
            (this.$refs as VueExtension).dropdown.hide(true);
            this.aangeraakt += 1;
        },
        toonBehandelingen(row) {
            if (row._showDetails) {
                this.$set(row, '_showDetails', false);
            } else {
                this.groupedDisplayList.forEach((row) => {
                    this.$set(row, '_showDetails', false);
                });

                const item = row.item ? row.item : row;
                this.localList = item.behandelingen;
                this.$set(row, '_showDetails', true);
            }
        },
        reset() {},
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return (this.$root as VueExtension).handleRight(id);
        }
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.displayList ? this.displayList.length : 0;
    },
    created() {}
});
</script>

<style scoped></style>

