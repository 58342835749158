var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"60px"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"8"}},[_c('period-selector',{attrs:{"periodDays":_vm.periodeDagen},on:{"change":_vm.setNewDates},model:{value:(_vm.selectDays),callback:function ($$v) {_vm.selectDays=$$v},expression:"selectDays"}})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('lookup-combo',{attrs:{"combotype":_vm.lctypeCheeses},on:{"change":_vm.setChangedProduct},model:{value:(_vm.productID),callback:function ($$v) {_vm.productID=$$v},expression:"productID"}})],1)],1)],1),_c('b-col',{attrs:{"sm":"1"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"bgc_aux_normal",attrs:{"title":_vm.$t('com_showdata', { ns: 'common' })},on:{"click":function($event){return _vm.loadData()}}},[_c('font-awesome-icon',{attrs:{"icon":"arrow-down","size":"1x"}})],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-card',{attrs:{"no-body":"","header":_vm.strInUit}},[_c('CardChart',{key:_vm.componentKey4,staticStyle:{"width":"100%","max-width":"700px"},attrs:{"displayNumber":_vm.showbar,"van":_vm.chartVan,"tot":_vm.chartTot,"productID":_vm.chartProductID,"dataSource":_vm.graphDataSource(4)}})],1)],1),_c('b-col',[_c('b-card',{attrs:{"no-body":"","header":_vm.$t('log_verdeling', {
                        ns: 'logistics',
                        val: _vm.$t('com_kaassoorten', {
                            ns: 'common'
                        })
                    })}},[_c('CardChart',{key:_vm.componentKey3,staticStyle:{"width":"100%","max-width":"700px"},attrs:{"displayNumber":_vm.showdoughnut,"van":_vm.chartVan,"tot":_vm.chartTot,"productID":_vm.chartProductID,"dataSource":_vm.graphDataSource(3)}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-card',{attrs:{"no-body":"","header":_vm.$t('log_verdeling', {
                        ns: 'logistics',
                        val: _vm.$t('prod_xtemperatuur', {
                            ns: 'production',
                            val: _vm.$t('prod_strem', {
                                ns: 'production'
                            })
                        })
                    })}},[_c('CardChart',{key:_vm.componentKey1,staticStyle:{"width":"100%","max-width":"700px"},attrs:{"displayNumber":_vm.showbar,"van":_vm.chartVan,"tot":_vm.chartTot,"productID":_vm.chartProductID,"dataSource":_vm.graphDataSource(1)}})],1)],1),_c('b-col',[_c('b-card',{attrs:{"no-body":"","header":_vm.$t('log_verdeling', {
                        ns: 'logistics',
                        val: _vm.$t('prod_xtemperatuur', {
                            ns: 'production',
                            val: _vm.$t('prod_nawarm', {
                                ns: 'production'
                            })
                        })
                    })}},[_c('CardChart',{key:_vm.componentKey2,staticStyle:{"width":"100%","max-width":"700px"},attrs:{"displayNumber":_vm.showbar,"van":_vm.chartVan,"tot":_vm.chartTot,"productID":_vm.chartProductID,"dataSource":_vm.graphDataSource(2)}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }