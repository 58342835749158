const state = {
    templateList: [],
    quantityList: [],
    qunitList: [],
    qualityList: [],
    qlabelList: [],
    entityObjectList: [],
    iqList: [],
    miqList: [],
    executorList: [],
    executorQualityList: [],
    executorQuantityList: [],
    maesurementList: [],
    assesmentList: [],
    normList:[],
    eoTypeNamesList:[],
    sampleList: [],
    sampleTypeList: []
};

export default state;
