const mutations = {
    insertRecipe(state, item) {
            state.recipe = item;
    },
    insertRecipeInfoLijst(state, lijst) {
        state.recipeInfoList = [];
        if (lijst != undefined) {
            state.recipeInfoList = lijst;
        }
    },
    insertClosedRecipeInfoLijst(state, lijst) {
        state.closedRecipeInfoList = [];
        if (lijst != undefined) {
            state.closedRecipeInfoList = lijst;
        }
    },
    deleteRecipe(state, item) {
        state.recipe = null;
        const index =  state.recipeInfoList.findIndex(x => {
            return x.id === item.id;
        });
        if (index > -1) {
            state.recipeInfoList.splice(index, 1);
        }
    }
};

export default mutations;
