<template>
    <b-container fluid style="margin-top: 60px">
        <h1 v-on:click="flipClosed">
            {{ title }} &nbsp;
            <b-button>
                {{ btnTekst }}
            </b-button>
        </h1>
        <b-row>
            <b-col sm="12">
                <div v-if="showFilter">
                    <b-row no-gutters>
                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_filter', { ns: 'common' })"
                                label-for="filter-input"
                                label-cols-sm="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-input-group size="sm">
                                    <b-form-input
                                        id="filter-input"
                                        v-model="filter"
                                        type="search"
                                        :placeholder="
                                            $t('com_typetosearch', {
                                                ns: 'common'
                                            })
                                        "
                                    ></b-form-input>

                                    <b-input-group-append>
                                        <b-button
                                            :disabled="!filter"
                                            @click="filter = ''"
                                            >{{
                                                $t('com_wis', { ns: 'common' })
                                            }}</b-button
                                        >
                                    </b-input-group-append>
                                </b-input-group>

                                <b-button
                                    v-if="checkRight('RecipeCreate')"
                                    :disabled="gesloten"
                                    size="sm"
                                    @click="createNewItem"
                                    class="mr-1"
                                    ><font-awesome-icon
                                        icon="folder-plus"
                                        size="1x"
                                    />
                                    {{ strNieuw }}
                                </b-button>
                            </b-form-group>
                        </b-col>

                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_perpagina', { ns: 'common' })"
                                label-for="per-page-select"
                                label-cols-sm="6"
                                label-cols-md="4"
                                label-cols-lg="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-form-select
                                    id="per-page-select"
                                    v-model="perPage"
                                    :options="pageOptions"
                                    size="sm"
                                ></b-form-select>
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                ></b-pagination>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div v-else>
                    <b-button
                        v-if="checkRight('RecipeCreate')"
                        :disabled="gesloten"
                        size="sm"
                        @click="createNewItem"
                        class="mr-1"
                        ><font-awesome-icon icon="folder-plus" size="1x" />
                        {{ strNieuw }}
                    </b-button>
                </div>

                <b-table
                    :items="displayList"
                    :fields="visibleFields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    stacked="md"
                    show-empty
                    :empty-text="$t('com_geen_data', { ns: 'common' })"
                    small
                    striped
                    @filtered="onFiltered"
                    @row-clicked="toonDetails"
                >
                    <template v-slot:cell(actions)="row">
                        <b-button
                            size="sm"
                            v-if="magBewerken"
                            @click="editItem(row.item)"
                            :disabled="gesloten"
                            class="bgc_aux_warning"
                            ><font-awesome-icon icon="edit" size="1x" />
                        </b-button>
                        <b-button
                            size="sm"
                            v-if="checkRight('RecipeClose')"
                            @click="verwijderItem(row.item)"
                            :disabled="gesloten"
                            class="bgc_aux_alarm"
                            ><font-awesome-icon icon="trash-alt" size="1x" />
                        </b-button>
                        <b-button
                            size="sm"
                            @click="toonDetails(row.item)"
                            class="bgc_aux_unknown"
                            ><font-awesome-icon icon="info-circle" size="1x" />
                        </b-button>
                    </template>

                    <template v-slot:cell(alert)="row">
                        <div v-if="row.item.alert != undefined">
                            <b-badge
                                v-if="row.item.alert === 0"
                                variant="success"
                            >
                                ok
                            </b-badge>
                            <b-badge
                                v-else-if="row.item.alert < 0"
                                variant="success"
                            >
                                ok +
                            </b-badge>
                            <b-badge v-else variant="warning">
                                {{ row.item.alert }}
                            </b-badge>
                        </div>
                    </template>

                    <template v-slot:row-details>
                        <recipe-card
                            v-model="selectedRecipe"
                            v-bind:editAllowed="false"
                            v-bind:closedRecipesList="selectedRecipeClosedList"
                        >
                        </recipe-card>
                    </template>
                </b-table>
            </b-col>
        </b-row>

        <recipe-modal
            v-if="detailsVisible"
            :inItem="currentItem"
            v-bind:kaasNaam="kaasnaamSelected"
            v-model="detailsVisible"
            @hide="reset"
        />
    </b-container>
</template>

<script>
import _ from 'lodash';
import { Recept } from '../../models/Recept';
import RecipeModal from './RecipeModal';
import RecipeCard from './RecipeCard';
import { loadItem } from '../../models/DataHelper';
import Helper from '@/models/Helper';
import { Permission } from '../../models/Permissions';

export default {
    name: 'RecipeInfoBase',
    data: function () {
        return {
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [10, 20, 40, { value: 100, text: '100' }],
            filter: null,
            filterOn: [],
            currentItem: null,
            detailsVisible: false,
            kaasnaamSelected: '',
            gesloten: false,
            counter: 1,
            displayFilterCount: 20,
            strNieuw: this.$t('com_newx', {
                ns: 'common',
                val: this.$t('com_recipe', {
                    ns: 'common'
                })
            })
        };
    },
    props: {},
    components: {
        RecipeModal,
        RecipeCard
    },
    computed: {
        title() {
            return this.gesloten
                ? this.$t('com_gesloten', {
                      ns: 'common',
                      val: this.$t('com_recipes', {
                          ns: 'common'
                      }).toLowerCase()
                  })
                : this.$t('com_beschikbaar', {
                      ns: 'common',
                      val: this.$t('com_recipes', {
                          ns: 'common'
                      }).toLowerCase()
                  });
        },
        btnTekst() {
            return Helper.stringformat(
                '{0} {1}',
                this.$t('com_show', { ns: 'common' }),
                this.gesloten
                    ? this.$t('com_open', { ns: 'common' }).toLowerCase()
                    : this.$t('com_gesloten', {
                          ns: 'common',
                          val: ''
                      }).toLowerCase()
            );
        },
        showFilter() {
            return this.displayList
                ? this.displayList.length > this.displayFilterCount
                : false;
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                {
                    key: 'kaasnaam',
                    label: this.$t('com_kaassoort', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'kaasinternID',
                    label: this.$t('com_internalid', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'vanaf',
                    label: this.$t('prod_validfrom', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'tot',
                    label: this.$t('com_tot', { ns: 'common' }),
                    sortable: true,
                    visible: this.gesloten
                },
                {
                    key: 'alert',
                    label: this.$t('com_recipeincompletezg', { ns: 'common' }),
                    visible: !this.gesloten
                },
                {
                    key: 'progWB',
                    label: this.$t('prod_wbprog', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'pekelDuur',
                    label: this.$t('prod_pekelduur', { ns: 'production' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        displayList() {
            const lijst = this.gesloten
                ? this.$store.getters['recipe/getClosedRecipeInfo']
                : this.$store.getters['recipe/getOpenRecipeInfo'];
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.totalRows = lijst ? lijst.length : 0;
            return _.sortBy(lijst, ['kaasnaam']);
        },
        selectedRecipe() {
            // eslint-disable-next-line no-unused-vars
            const veranderd = this.counter;
            //return this.$store.getters['recipe/getRecipe'];
            return this.currentItem;
        },
        selectedRecipeClosedList() {
            const closedList = this.$store.getters[
                'recipe/getClosedRecipeInfoForKaas'
            ](this.kaasnaamSelected);
            return closedList;
        },
        magBewerken() {
            return this.currentItem
                ? this.checkRight('RecipeUpdate')
                : this.checkRight('RecipeCreate');
        }
    },
    methods: {
        flipClosed() {
            this.gesloten = !this.gesloten;
            this.reloadData();
        },
        createNewItem() {
            this.currentItem = new Recept();
            this.kaasnaamSelected = '';
            this.detailsVisible = true;
        },
        async editItem(item) {
            // haal item op met details
            this.kaasnaamSelected = item.kaasnaam;
            this.currentItem = await loadItem(
                'recipe/loadRecipeComplete',
                { recipeid: item.id },
                this.$t
            );
            this.detailsVisible = true;
        },
        async verwijderItem(item) {
            const doClose = await this.$root.showConfirmationBox(
                this.$t('com_delete', {
                    ns: 'common',
                    val: this.$t('com_recipe', { ns: 'common' }).toLowerCase()
                }) +
                    item.id +
                    ' : ' +
                    item.kaasnaam
            );
            if (doClose) {
                loadItem(
                    'recipe/deleteRecipe',
                    {
                        recipeid: item.id
                    },
                    this.$t
                );
            }
        },
        async toonDetails(row) {
            if (row._showDetails) {
                this.$set(row, '_showDetails', false);
            } else {
                this.displayList.forEach((row) => {
                    this.$set(row, '_showDetails', false);
                });
                this.kaasnaamSelected = row.kaasnaam;
                this.currentItem = await loadItem(
                    'recipe/loadRecipeComplete',
                    { recipeid: row.id },
                    this.$t
                );
                this.$set(this, 'counter', (this.counter += 1));
                this.$set(row, '_showDetails', true);
            }
        },
        reset() {
            this.currentItem = null;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        reloadData() {
            if (this.gesloten) {
                loadItem('recipe/loadClosedRecipeInfo', null, this.$t);
            } else {
                loadItem('recipe/loadOpenRecipeInfo', null, this.$t);
            }
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        }
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.displayList ? this.displayList.length : 0;
    },
    created() {
        loadItem('recipe/loadOpenRecipeInfo', null, this.$t);
        const func = this.$t;
        // ToDo: haal altijd alles op, bij grote lijsten is dit niet handig!!
        loadItem('product/loadIngredientCategories', null, func).then(
            async (ingrcatArray) => {
                await Promise.all(
                    ingrcatArray.map(async (ingrcat) => {
                        ingrcat.lijst = await loadItem(
                            'product/loadIngredientInfo',
                            { category: ingrcat.naam },
                            func
                        );
                    })
                );
            }
        );

        loadItem('recipe/loadClosedRecipeInfo', null, this.$t);
    }
};
</script>

<style scoped></style>

