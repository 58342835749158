import Api from '../../../api';
import store from '../../../store';

//#region Voorraad
// data= DeliveryNoteModel
const addDeliveryNote = async (data) => {
    return await Api(store).put('/DeliveryNote/StoreDeliveryNote', data);
};

// deliverynoteID
const fetchDeliveryNote = async (data) => {
    return fetchInfo('/DeliveryNote/GetDeliveryNote', data);
};
// printednoteID
const fetchDeliveryNotePrinted = async (data) => {
    return fetchInfo('/DeliveryNote/GetPrintedDeliveryNote', data);
};
const fetchStockItemsForCheeseMark = async data => {
    return fetchInfo('/StockItem/GetStockItemsForCheeseMark', data);
};

const fetchDeliveryNotes = async (data) => {
    return fetchInfo('/DeliveryNote/GetDeliveryNotes', data);
};

const fetchDeliveryNotesPrinted = async (data) => {
    return fetchInfo('/DeliveryNote/GetPrintedDeliveryNotes', data);
};
//int deliverynoteID, int mergeOption, int printOption
const fetchPrintedNoteView = async (data) => {
    return fetchInfo('/DeliveryNote/showPrintedNote', data);
};

// int deliverynoteID, int mergeOption, int printOption
const storePrintedNote = async (data) => {
    return storeInfo('/DeliveryNote/storePrintedNote', data);
};

// deliverynoteID
const fetchDeliveryNotePrintOptions = async (data) => {
    return fetchInfo('/DeliveryNote/GetDeliveryNoteLinePrintOptions', data);
};
// deliverynoteID
const fetchPrintedNoteCreated = async (data) => {
    return fetchInfo('/DeliveryNote/PrintedNoteCreated', data);
};

// printednoteID
const storePrintedNotePrinted = async (data) => {
    return storeInfo('/DeliveryNote/PrintedNotePrinted', data);
};

// companyid
const fetchCompany = async (data) => {
    return fetchInfo('/Company/GetCompany', data);
};

// data= int id, DateTime lastupdated
const deleteDeliveryNote = async (data) => {
    //return await Api(store).delete('/DeliveryNote/DeleteDeliveryNote', data);
    return await Api(store).delete(
        '/DeliveryNote/DeleteDeliveryNote?' +
            Object.keys(data)
                .map(
                    (k) =>
                        encodeURIComponent(k) +
                        '=' +
                        encodeURIComponent(data[k] ?? '')
                )
                .join('&'),
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
    );
};
//#endregion Voorraad

const storeInfo = async (apicall, data) => {
    if (data != undefined) {
        return await Api(store).put(
            apicall +
                '?' +
                Object.keys(data)
                    .map(
                        (k) =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    } 
};
const fetchInfo = async (apicall, data) => {
    if (data != undefined) {
        return await Api(store).get(
            apicall +
                '?' +
                Object.keys(data)
                    .map(
                        (k) =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    } else {
        return await Api(store).get(apicall, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    }
};

export default {
    addDeliveryNote,
    fetchDeliveryNote,
    fetchDeliveryNotePrinted,
    fetchDeliveryNotes,
    fetchDeliveryNotesPrinted,
    fetchPrintedNoteView,
    fetchPrintedNoteCreated,
    storePrintedNote,
    storePrintedNotePrinted,
    fetchDeliveryNotePrintOptions,
    deleteDeliveryNote,
    fetchCompany,
    fetchStockItemsForCheeseMark
};
