const showGoodToast = (context, bericht) => {
    context.commit(
        'setNotification',
        {
            type: 'succes',
            title: 'Succes',
            message: bericht
        },
        { root: true }
    );
};

const showBadToast = (context, titel, bericht) => {
    // het is een error
    const status = bericht.response ? bericht.response.status : '';
    const errormessage = (status==401) ? 'Niet bevoegd' : getMessage(bericht);
    context.commit(
        'setNotification',
        {
            type: 'error',
            title: status + ' ' + titel ?? 'Melding',
            message: errormessage
        },
        { root: true }
    );
};

const getMessage = (err) => {
    let message = '';
    if (err.response) {
        if (err.response.data.message != undefined) {
            message = 'melding:  ' + err.response.data.message;
        } else {
            if (err.response.data.error != undefined) {
                message = 'ongeldige actie: ' + err.response.data.error;
            } else {
                message = 'onvewachtte melding: ' + err.response.data;
            }
        }
    } else {
        message = 'onbekende fout: ' +  err;
    }
    return message;
};

export { showGoodToast, showBadToast };
