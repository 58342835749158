<template>
    <b-container fluid style="margin-top: 60px">
        <h1 v-on:click="flipClosed">
            {{ title }} &nbsp;
            <b-button>
                {{ btnTekst }}
            </b-button>
        </h1>
        <b-row>
            <b-col sm="12">
                <div v-if="showFilter">
                    <b-row no-gutters>
                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_filter', { ns: 'common' })"
                                label-for="filter-input"
                                label-cols-sm="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-input-group size="sm">
                                    <b-form-input
                                        id="filter-input"
                                        v-model="filter"
                                        type="search"
                                        :placeholder="
                                            $t('com_typetosearch', {
                                                ns: 'common'
                                            })
                                        "
                                    ></b-form-input>

                                    <b-input-group-append>
                                        <b-button
                                            :disabled="!filter"
                                            @click="filter = ''"
                                            >{{
                                                $t('com_wis', { ns: 'common' })
                                            }}</b-button
                                        >
                                    </b-input-group-append>
                                </b-input-group>

                                <b-button
                                    v-if="!gesloten"
                                    size="sm"
                                    @click="createNewItem"
                                    class="mr-1"
                                    ><font-awesome-icon
                                        icon="folder-plus"
                                        size="1x"
                                    />
                                    {{ strNieuw }}
                                </b-button>
                            </b-form-group>
                        </b-col>

                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_perpagina', { ns: 'common' })"
                                label-for="per-page-select"
                                label-cols-sm="6"
                                label-cols-md="4"
                                label-cols-lg="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-form-select
                                    id="per-page-select"
                                    v-model="perPage"
                                    :options="pageOptions"
                                    size="sm"
                                ></b-form-select>
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                ></b-pagination>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div v-else>
                    <b-button
                        v-if="!gesloten"
                        size="sm"
                        @click="createNewItem"
                        class="mr-1"
                        ><font-awesome-icon icon="folder-plus" size="1x" />
                        {{ strNieuw }}
                    </b-button>
                </div>

                <b-table
                    :items="groupedDisplayList"
                    :fields="visibleFields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    stacked="md"
                    show-empty
                    :empty-text="$t('com_geen_data', { ns: 'common' })"
                    small
                    striped
                    @filtered="onFiltered"
                    @row-clicked="toonVoorraad"
                >
                    <template v-slot:cell(actions)="row">
                        <b-button
                            size="sm"
                            @click="addItem(row.item)"
                            class="bgc_aux_normal"
                            ><font-awesome-icon icon="folder-plus" size="1x" />
                        </b-button>
                        <b-button
                            size="sm"
                            @click="toonVoorraad(row.item)"
                            class="bgc_aux_unknown"
                            ><font-awesome-icon icon="info-circle" size="1x" />
                        </b-button>
                    </template>

                    <template v-slot:row-details>
                        <mark-stock-items
                            v-bind:stocklist="stockList"
                            v-bind:gesloten="gesloten"
                        >
                        </mark-stock-items>
                    </template>
                </b-table>
            </b-col>
        </b-row>

        <item-stock-modal
            v-if="detailsVisible"
            :inItem="currentItem"
            v-model="detailsVisible"
            v-bind:editAllowed="editAllowed"
            @hide="reset"
        />
    </b-container>
</template>

<script>
import _ from 'lodash';
import { MerkVoorraadItem } from '../../models/Voorraad';
import MarkStockItems from './MarkStockItems';
import ItemStockModal from './ItemStockModal';
import Helper from '@/models/Helper';
import { loadItem } from '@/models/DataHelper';

export default {
    name: 'MarkStockInfoBase',
    data: function () {
        return {
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [10, 20, 40, { value: 100, text: '100' }],
            filter: null,
            filterOn: [],
            currentItem: null,
            detailsVisible: false,
            gesloten: false,
            localSIlist: [],
            displayFilterCount: 20,
            strNieuw: this.$t('com_newx', {
                ns: 'common',
                val: this.$t('prod_stockitem', {
                    ns: 'production'
                })
            })
        };
    },
    props: {},
    components: {
        MarkStockItems,
        ItemStockModal
    },
    computed: {
        title() {
            return this.gesloten
                ? this.$t('com_gesloten', {
                      ns: 'common',
                      val: this.$t('com_stock', {
                          ns: 'common'
                      }).toLowerCase()
                  })
                : this.$t('com_beschikbaar', {
                      ns: 'common',
                      val: this.$t('com_stock', {
                          ns: 'common'
                      }).toLowerCase()
                  });
        },
        showFilter() {
            return this.displayList
                ? this.displayList.length > this.displayFilterCount
                : false;
        },
        btnTekst() {
            return Helper.stringformat(
                '{0} {1}',
                this.$t('com_show', { ns: 'common' }),
                this.gesloten
                    ? this.$t('com_open', { ns: 'common' }).toLowerCase()
                    : this.$t('com_gesloten', {
                          ns: 'common',
                          val: ''
                      }).toLowerCase()
            );
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                {
                    key: 'naam',
                    label: this.$t('com_product', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'stockitems.length',
                    label: this.$t('com_number', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'hh',
                    label: this.$t('com_amount', { ns: 'common' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        displayList() {
            const result = this.gesloten
                ? this.$store.getters['stock/getMarkStockInfoArchived']
                : this.$store.getters['stock/getMarkStockInfo'];
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.totalRows = result ? result.length : 0;
            return result;
        },
        groupedDisplayList() {
            if (this.displayList == undefined) return null;
            const x = _(this.displayList.slice(0))
                .groupBy((x) => x.naam)
                .map((value, key) => {
                    const quantity = _.sumBy(value, 'aantal');
                    return {
                        naam: key,
                        hh: quantity,
                        stockitems: value
                    };
                })
                .value();
            return _.sortBy(x, ['naam']);
        },
        stockList() {
            return this.localSIlist;
        },
        editAllowed() {
            return true;
        }
    },
    methods: {
        flipClosed() {
            this.gesloten = !this.gesloten;
            this.reloadData();
        },
        createNewItem() {
            this.currentItem = new MerkVoorraadItem();
            this.currentItem.unitID = window.constants.STUKS;
            this.currentItem.baseUnit = window.constants.STUKS;
            //this.currentItem.SetForSave();
            this.detailsVisible = true;
        },
        async getDetailItem(item) {
            return await loadItem(
                'stock/loadMarkStockItem',
                {
                    stockitemid: item.id
                },
                this.$t
            );
        },
        async addItem(item) {
            const t = await this.getDetailItem(item.stockitems[0]);
            this.currentItem = new MerkVoorraadItem();
            this.currentItem.prodcatNaam = item.prodcatNaam;
            this.currentItem.prodcatID = item.prodcatID;
            this.currentItem.ingrcatNaam = item.ingrcatNaam;
            this.currentItem.categorie = item.categorie;
            this.currentItem.naam = item.naam;
            this.currentItem.eenheid = item.stockitems[0].eenheid;
            this.currentItem.locatie = item.stockitems[0].locatie;

            this.currentItem.productID = t.productID;
            this.currentItem.vetklasse = t.vetklasse;
            this.currentItem.unitID = t.unitID;
            this.currentItem.baseUnit = t.unitID;
            this.currentItem.locationID = t.locationID;
            this.currentItem.supplierID = t.supplierID;
            // this.currentItem.SetForSave();
            this.detailsVisible = true;
        },
        toonVoorraad(row) {
            if (row._showDetails) {
                this.$set(row, '_showDetails', false);
            } else {
                this.groupedDisplayList.forEach((row) => {
                    this.$set(row, '_showDetails', false);
                });
                this.localSIlist = row.item
                    ? row.item.stockitems
                    : row.stockitems;
                this.$set(row, '_showDetails', true);
            }
        },
        reset() {
            this.currentItem = null;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        reloadData() {
            if (this.gesloten) {
                loadItem('stock/loadMarkStockInfoArchived', null, this.$t);
            } else {
                loadItem('stock/loadMarkStockInfo', null, this.$t);
            }
        }
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.displayList ? this.displayList.length : 0;
    },
    created() {
        loadItem('stock/loadMarkStockInfo', null, this.$t);
    }
};
</script>

<style scoped></style>

