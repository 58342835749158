<template>
    <b-modal
        id="modal-itemSeasonValue"
        size="lg"
        ref="modal"
        :title="formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
    >
        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
            {{ alertText }}
        </b-alert>
        <b-card :header="headerMonth">
            <b-row>
                <b-col sm="3">
                    <label
                        >{{
                            $t('prod_melkfactor', {
                                ns: 'production'
                            })
                        }}:</label
                    >
                </b-col>
                <b-col sm="9">
                    <b-form-input
                        type="number"
                        step="0.01"
                        v-model.number="milkfactor"
                        @change="setChanged"
                    ></b-form-input>
                </b-col>
            </b-row>
        </b-card>
    </b-modal>
</template>

<script>
import Helper from '../../models/Helper';
import { loadItem, loadList } from '../../models/DataHelper';
import { SeasonValue } from '@/models/Recept';

export default {
    name: 'SeasonValuePopForm',
    data() {
        return {
            showDismissibleAlert: false,
            alertText: '',
            melkfactor: 1
        };
    },
    components: {},
    model: {
        prop: 'visible',
        event: 'change'
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        seizoenswaarde: {
            type: SeasonValue,
            required: true
        }
    },
    computed: {
        formtitle() {
            return this.$t('com_edit', {
                ns: 'common',
                val: this.$t('prod_seasonvalue', {
                    ns: 'production'
                }).toLowerCase()
            });
        },
        headerMonth() {
            return Helper.stringformat(
                '{0} {1} ({2})',
                this.$t('com_maand', { ns: 'common' }),
                this.seizoenswaarde.maand,
                this.seizoenswaarde.MaandNaam(this.$t)
            );
        },
        milkfactor: {
            get: function () {
                return this.melkfactor.toFixed(2);
            },
            set: function (newValue) {
                this.melkfactor = newValue;
            }
        }
    },
    methods: {
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        hide() {
            this.opmerking = '';
            this.$emit('hide');
        },
        setChanged() {
            this.isChanged = true;
        },
        checkFormValidity() {
            const valid = true;
            return valid;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        handleSubmit() {
            if (this.isChanged) {
                // Exit when the form isn't valid
                if (!this.checkFormValidity()) {
                    return;
                }

                this.$emit('melkfactor', this.milkfactor);
            }
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-itemSeasonValue');
            });
        }
    },
    mounted() {},
    created() {
        this.melkfactor = this.seizoenswaarde.factor ?? 1;
    }
};
</script>

