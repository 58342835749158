<template>
    <b-container fluid class="bs-example">
        <b-card no-body class="mb-1">
            &nbsp;
            <b-table
                striped
                responsive
                :fields="visibleFields"
                :items="sortedList"
            >
                <template v-slot:cell(actions)="row">
                    <b-button
                        v-if="checkRight('StockDelete')"
                        size="sm"
                        @click="verwijderItem(row.item)"
                        class="bgc_aux_alarm"
                        ><font-awesome-icon icon="trash-alt" size="1x" />
                    </b-button>
                </template>

                <template v-slot:cell(default)="row">
                    <b-badge v-if="row.item.default" variant="success">
                        {{ $t('com_default', { ns: 'common' }) }}
                    </b-badge>
                </template>
            </b-table>
        </b-card>
    </b-container>
</template>

<script lang="ts">
import { CheeseTypeTreatmentRecipe } from '@/models/CheeseTreatment';
import { VueExtension } from '../../models/VueExtension';
import { defineComponent } from 'vue';
import { Permission } from '../../models/Permissions';
import Helper from '../../models/Helper';
export default defineComponent({
    name: 'CheeseTreatmentItems',
    data() {
        return {
            selected: [],
            strDelete:
                this.$t('com_delete', {
                    ns: 'common',
                    val: this.$t('prod_treatmentschema', {
                        ns: 'production'
                    }).toLowerCase()
                }) +
                ' {0} ' +
                this.$t('prod_voorobject', {
                    ns: 'production',
                    val: this.$t('prod_vorm', {
                        ns: 'production'
                    }).toLowerCase()
                }) +
                '{1}'
        };
    },
    props: {
        inList: {
            type: Array<CheeseTypeTreatmentRecipe>,
            required: true
        }
    },
    components: {},
    computed: {
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                {
                    key: 'behandelreceptID',
                    label: 'Id',
                    sortable: false,
                    visible: true
                },
                {
                    key: 'behandelrecept',
                    label: this.$t('prod_treatmentschema', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'default',
                    label: this.$t('com_default', { ns: 'common' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        sortedList() {
            return this.inList;
        }
    },
    methods: {
        reset() {},
        async verwijderItem(item) {
            const message = Helper.stringformat(
                this.strDelete,
                item.behandelrecept,
                item.vorm
            );
            const doDelete = await (
                this.$root as VueExtension
            ).showConfirmationBox(message);
            if (doDelete) {
                this.$emit('delete', item);
            }
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return (this.$root as VueExtension).handleRight(id);
        }
    },
    mounted() {}
});
</script>

<style scoped></style>

