<template>
    <b-container fluid class="bs-example">
        <b-card no-body class="mb-1">
            &nbsp;
            <b-table
                striped
                responsive
                :fields="visibleFields"
                :items="sortedList"
            >
                <template v-slot:cell(actions)="row">
                    <b-button
                        v-if="checkRight('StockDelete')"
                        size="sm"
                        @click="verwijderItem(row.item)"
                        class="bgc_aux_alarm"
                        ><font-awesome-icon icon="trash-alt" size="1x" />
                    </b-button>
                </template>
            </b-table>
        </b-card>
    </b-container>
</template>

<script>
import _ from 'lodash';
import { Permission } from '../../models/Permissions';
import { loadItem, loadList } from '@/models/DataHelper';
import DateHelper from '../../models/DateHelper';
export default {
    name: 'BrineIngredients',
    data() {
        return {
            currentItem: null,
            detailsVisible: false,
            newAmount: null,
            selected: []
        };
    },
    props: {
        addedIngredients: {
            type: Array,
            required: true
        }
    },
    components: {},
    computed: {
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                {
                    key: 'id',
                    label: this.$t('com_barcode', { ns: 'common' }),
                    sortable: false,
                    visible: false
                },
                {
                    key: 'datum',
                    label: this.$t('com_datum', { ns: 'common' }),
                    sortable: true,
                    visible: true,
                    formatter: 'displayDate'
                },
                {
                    key: 'productNaam',
                    label: this.$t('com_product', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'partijNummer',
                    label: this.$t('com_batch', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'hoeveelheid',
                    label: this.$t('com_amount', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'eenheid',
                    label: this.$t('com_unit', { ns: 'common' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        sortedList() {
            return _.sortBy(this.addedIngredients, ['productNaam', 'datum']);
        }
    },
    methods: {
        reset() {
            this.detailsVisible = false;
            this.currentItem = null;
        },
        async getDetailItem(item) {
            return await loadItem(
                'outbrine/loadStockLocIngredient',
                {
                    stocklocationingredientid: item.id
                },
                this.$t
            );
        },
        async verwijderItem(item) {
            this.getDetailItem(item).then((value) => {
                const refreshedItem = value;
                this.$root
                    .showConfirmationBox(
                        this.$t('com_delete', {
                            ns: 'common',
                            val: this.$t('prod_toevoeging', {
                                ns: 'production'
                            })
                        }) +
                            item.id +
                            ' : ' +
                            item.partijNummer
                    )
                    .then((res) => {
                        if (res == true) {
                            loadItem(
                                'outbrine/delStockLocIngredient',
                                {
                                    ID: refreshedItem.id,
                                    Stocklocationid: refreshedItem.plaatsID,
                                    LastUpdated: refreshedItem.gewijzigd
                                },
                                this.$t
                            );
                        }
                    })
                    .then((x) => {
                        const test = this.addedIngredients
                            .map((x) => x.id)
                            .indexOf(refreshedItem.id);
                        if (test > -1) {
                            this.addedIngredients.splice(test, 1);
                        }
                    });
            });
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        },
        displayDate(value) {
            return DateHelper.convertedToDate(value, true);
        }
    },
    mounted() {}
};
</script>

<style scoped>
::v-deep .table > tbody > tr.b-table-row-selected,
::v-deep .table > tbody > tr.b-table-row-selected > td,
::v-deep .table > tbody > tr.b-table-row-selected > th {
    background-color: #00a1de;
    color: ghostwhite !important;
}
.overflow-class /deep/ .dropdown-menu {
    max-height: 200px;
    overflow-y: auto;
}
</style>

