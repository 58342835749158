
const state = {
    outbrineInfoLijst: [],
    outbrineInfoHistLijst: [],
    outbrineForBGLijst: [],
    inbrineForBGLijst: [],
    outbrineInfoBGLijst: []
};

export default state;
