
const state = {  
    ostLijst: [],
    locationLijst: [],
    shapesLijst: [],
    companyLijst: [], 
    constantsLijst: [],
    stockLocationTypesLijst:[],
    versionInfo: null,
    packageVersion: [],
    equipmentLijst:[],
    berichtenLijst: [],
    reportgroupLijst: []
};

export default state;
