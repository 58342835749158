import { INGRCAT_ENUM } from '../../models/IngredientCategorie';
import {
    BakIngredient,
    CheeseMarks,
    AlternativeCheese
} from '../../models/BakInfo';
import { RecIngredient } from '../../models/Recept';
import {
    BIlijst,
    Alternativeslijst,
    BakStrem,
    BakMelk,
    BakWWW,
    BakDrain,
    BakOverpomp,
    BakInpekel,
    InpekelItem
} from '../../models/BakInfo_helper';
import { Feature } from '../../models/Features';
import DateHelper from '../../models/DateHelper';
import { loadList, loadItem } from '../../models/DataHelper';
import store from '../../store';
import _ from 'lodash';

const loadSubObject = (bak, subtype, func) => {
    if (bak.type !== 'Bak')
        throw func('prod_nobakobject', { ns: 'production' });
    // bepaal de factor voor de bak op basis van de meest recente gegevens

    const operator = store.getters['account/getLoggedInUser'];
    const calciumFeature = Feature.enum('conf_storeCalciumWithRennet');
    const conserveerFeature = Feature.enum('conf_storeConserveerWithMilk');
    // test, uit features
    const showCalcium = operator.listContainsFeature(calciumFeature)
        ? window.constants.BI_SHOW_STREM
        : window.constants.BI_SHOW_MELK;
    const showConserveer = operator.listContainsFeature(conserveerFeature)
        ? window.constants.BI_SHOW_MELK
        : window.constants.BI_SHOW_WWW;
    const result = determineProdFactor(bak);
    const prodFactor = result.prodCalcFactor;
    const recipeMelk = result.recipeMelk;
    // welk object moeten we terug geven?
    let nieuw = null;
    switch (subtype) {
        case window.constants.BI_SHOW_MELK: {
            // bij melk kan een melkproduct worden gebruikt dat niet in het recept zit
            const newbak = addAddedMilkToRecingredients(bak, INGRCAT_ENUM.Melk);
            nieuw = new BakMelk(newbak);
            nieuw.prodcalcFactor = prodFactor;
            nieuw.bakIngredientenMelk = loadBILijst(
                bak,
                INGRCAT_ENUM.Melk,
                prodFactor,
                func
            );
            nieuw.timerIngredient =
                nieuw.bakIngredientenMelk.ingredientCategorieNaam;
            if (showCalcium == subtype) {
                nieuw.bakIngredientenCalcium = loadBILijst(
                    bak,
                    INGRCAT_ENUM.Calcium,
                    prodFactor,
                    func
                );
            } else {
                nieuw.bakIngredientenCalcium = [];
            }
            if (showConserveer == subtype) {
                nieuw.bakIngredientenConserveermiddel = loadBILijst(
                    bak,
                    INGRCAT_ENUM.Conserveermiddel,
                    prodFactor,
                    func
                );
            } else {
                nieuw.bakIngredientenConserveermiddel = [];
            }
            nieuw.bakIngredientenKleursel = loadBILijst(
                bak,
                INGRCAT_ENUM.Kleursel,
                prodFactor,
                func
            );
            nieuw.bakIngredientenZuursel = loadBILijst(
                bak,
                INGRCAT_ENUM.Zuursel,
                prodFactor,
                func
            );
            break;
        }
        case window.constants.BI_SHOW_STREM: {
            nieuw = new BakStrem(bak);
            if (showCalcium == subtype) {
                nieuw.bakIngredientenCalcium = loadBILijst(
                    bak,
                    INGRCAT_ENUM.Calcium,
                    prodFactor,
                    func
                );
            } else {
                nieuw.bakIngredientenCalcium = [];
            }
            nieuw.bakIngredientenStremsel = loadBILijst(
                bak,
                INGRCAT_ENUM.Stremsel,
                prodFactor,
                func
            );
            nieuw.timerIngredient =
                nieuw.bakIngredientenStremsel.ingredientCategorieNaam;
            break;
        }
        case window.constants.BI_SHOW_WWW: {
            nieuw = new BakWWW(bak);
            nieuw.melk = recipeMelk;
            nieuw.prodcalcFactor = prodFactor;
            if (showConserveer == subtype) {
                nieuw.bakIngredientenConserveermiddel = loadBILijst(
                    bak,
                    INGRCAT_ENUM.Conserveermiddel,
                    prodFactor,
                    func
                );
            } else {
                nieuw.bakIngredientenConserveermiddel = [];
            }
            nieuw.bakIngredientenKruiden = loadBILijst(
                bak,
                INGRCAT_ENUM.Kruiden,
                prodFactor,
                func
            );
            nieuw.bakIngredientenAromas = loadBILijst(
                bak,
                INGRCAT_ENUM.Aromas,
                prodFactor,
                func
            );
            break;
        }
        case window.constants.BI_SHOW_MERKEN: {
            // haal opgelegde merken op
            const kaasmerk = loadKaasmerken(recipeMelk, prodFactor, bak);
            nieuw = new BakDrain(bak);
            const defaults = store.getters['production/getBatchDefaults'];
            nieuw.productID = defaults.productID;
            nieuw.prodcalcFactor = prodFactor;
            nieuw.bakKaasMerk = kaasmerk;
            //nieuw.bakKaasMerk = loadKaasMerkVoorstel(kaasmerk);
            break;
        }
        case window.constants.BI_SHOW_OVERPOMP: {
            nieuw = new BakOverpomp(bak);
            nieuw.melk = recipeMelk;
            nieuw.prodcalcFactor = prodFactor;
            nieuw.bakIngredientenZout = loadBILijst(
                bak,
                INGRCAT_ENUM.Zout,
                prodFactor,
                func
            );
            const IngredientenKruiden = loadBILijst(
                bak,
                INGRCAT_ENUM.Kruiden,
                prodFactor,
                func
            );
            const defaults = store.getters['production/getBatchDefaults'];
            nieuw.productID = defaults.productID;
            const lijstobject = loadAlternatives(bak, IngredientenKruiden);
            nieuw.bakIngredientenAlternatief = lijstobject.bilijstArray;
            nieuw.bakIngredientenAltBase = lijstobject.bilijstBaseArray;
            break;
        }
        case window.constants.BI_SHOW_INPEKEL: {
            nieuw = new BakInpekel(bak);
            nieuw.prodcalcFactor = prodFactor;
            const defaults = store.getters['production/getBatchDefaults'];
            nieuw.productID = defaults.productID;
            if (defaults.merkTypeID != undefined) {
                nieuw.merkenOpgelegd = bak.kaasMerken != undefined;
            }
            // lijst is al geladen bij het aanklikken
            const tempList = store.getters['production/getBatchInpekelList'];
            if (nieuw.bakStats.length > 0) {
                // we hebben merken ingevuld dus pakken dat over
                const newInpekelItemArray = [];
                for (let i = 0; i < nieuw.bakStats.length; i++) {
                    const bs = nieuw.bakStats[i];
                    if (bs.inPekelTijd == undefined) {
                        // er zijn wel merken opgelegd maar nog niet ingepekeld. Met tijd zit hij alin templist
                        const ii = new InpekelItem({
                            bakId: bak.bakId,
                            updated: bs.gewijzigd
                        });
                        ii.inpekelTijd = DateHelper.convertedToTime();
                        // todo bs.merkAantal is er niet meer
                        ii.amount =
                            bs.defAantal > 0 ? bs.defAantal : bs.merkAantal;
                        ii.vormID = bs.vormID;
                        ii.productID = bs.productID;
                        // voor het gemak van de gebruiker doen we deze er wel gewoon in
                        // result.nouse = true;
                        newInpekelItemArray.push(ii);
                    }
                }
                if (newInpekelItemArray.length > 0) {
                    tempList.push(...newInpekelItemArray);
                }
            } else {
                const kaasmerk = loadKaasmerken(recipeMelk, prodFactor, bak);
                nieuw.opgelegdeMerken = kaasmerk.kazen;
                if (kaasmerk.subSeries.length > 0) {
                    // we bepalen inpekeamounts op basis van de merken
                    const newInpekelItemArray = [];
                    // check of de alternatieven al in de inpekellijst zitten, dan niets doen
                    for (let i = 0; i < kaasmerk.subSeries.length; i++) {
                        const km = kaasmerk.subSeries[i];
                        const ii = new InpekelItem({ bakId: bak.id });
                        ii.inpekelTijd = DateHelper.convertedToTime();
                        ii.productID = km.productID;
                        ii.markTypeID = km.markTypeID;
                        ii.amount = km.kazen;
                        ii.vormID = km.vormID;
                        newInpekelItemArray.push(ii);
                    }
                    if (newInpekelItemArray.length > 0) {
                        tempList.push(...newInpekelItemArray);
                    }
                } else {
                    const newInpekelItemArray = loadInBrineAmounts(
                        recipeMelk,
                        prodFactor,
                        bak.id
                    );
                    if (newInpekelItemArray.length > 0) {
                        newInpekelItemArray.map(
                            (x) =>
                                (x.inpekelTijd = DateHelper.convertedToTime())
                        );
                        tempList.push(...newInpekelItemArray);
                    }
                }
            }
            nieuw.inpekelAmounts = tempList;
            break;
        }
        case window.constants.BI_SHOW_INGREDIENT:
        default:
            break;
    }
    return nieuw;
};

const loadKaasmerken = (recipeMelk, prodFactor, bak) => {
    const defaults = store.getters['production/getBatchDefaults'];
    let kaasmerk = bak.kaasMerken;

    const nieuw = kaasmerk ? kaasmerk.gewijzigd == undefined : true;
    // als merknummmer is ingevoerd dan teruggeven  want dan is het edit, anders invullen meest waarschijnlijk
    // todo shape
    if (nieuw) {
        kaasmerk = new CheeseMarks({
            batchID: defaults.id,
            merkProductID: defaults.merkTypeProductID,
            productID: defaults.productID,
            markTypeID: defaults.merkTypeID
        });
        const newInpekelItemArray = loadInBrineAmounts(
            recipeMelk,
            prodFactor,
            bak.id
        );
        const sum = _.sumBy(newInpekelItemArray, 'amount');
        kaasmerk.kazen = sum;
        // todo check of binnen de serie valt
        // voeg ook de series per vorm / alternatief toe
        if (newInpekelItemArray.length > 0) {
            for (let i = 0; i < newInpekelItemArray.length; i++) {
                const subkaasmerk = new CheeseMarks({
                    batchID: defaults.id,
                    productID: newInpekelItemArray[i].productID,
                    markTypeID: defaults.merkTypeID
                });
                subkaasmerk.kazen = newInpekelItemArray[i].amount;
                subkaasmerk.vormID = newInpekelItemArray[i].vormID;
                subkaasmerk.merkProductID = defaults.merkTypeProductID;
                kaasmerk.subSeries.push(subkaasmerk);
            }
            kaasmerk.subSeries.sort((a, b) => b.kazen - a.kazen);
        }
    } else {
        kaasmerk.vormID = defaults.vormID;
        kaasmerk.vormGewicht = defaults.gewicht;
        kaasmerk.merkProductID = defaults.merkTypeProductID;
        kaasmerk.productID = defaults.productID;
    }
    return kaasmerk;
};

const loadInBrineAmounts = (recipeMelk, prodFactor, bakId) => {
    let result = [];
    const tempList = store.getters['production/getBatchInpekelList'];
    const defaults = store.getters['production/getBatchDefaults'];
    const tempListAlt = store.getters['production/getAlternativeCheeseList'];
    const melkfactorVoorSeizoen =
        store.getters['production/getMilkusagefactor'];
    let kilos = 0;
    let aantal = 0;
    let vormID = 0;
    let productID = 0;
    if (defaults) {
        if (defaults.melkgebruik > 1) {
            kilos =
                (recipeMelk * prodFactor) /
                (defaults.melkgebruik * melkfactorVoorSeizoen);
            if (defaults.gewicht > 0) {
                aantal = Math.round(kilos / defaults.gewicht);
            } else {
                aantal = Math.round(kilos / 10); // geen gewicht dan standaard 10 kilo
            }
            vormID = defaults.vormID;
            productID = defaults.productID;
        }
    }
    const arrayAltToAdd = giveAlternativesForList(
        tempList,
        tempListAlt,
        bakId,
        vormID
    );
    if (arrayAltToAdd.length > 0) {
        result = arrayAltToAdd;
        let totKilos = 0;
        for (const item of result) {
            const shape = store.getters['components/getShapebyID'](item.vormID);
            const calcKilos =
                shape == undefined ? 0 : item.amount * shape.gewicht;
            totKilos += calcKilos;
        }
        kilos = kilos - totKilos;
    }
    // de templist nog toevoegen, als er al ingepekeld is dan wel dat meenemen
    result.push(...tempList);
    if (tempList.length > 0) {
        const remaining = kilos - determineInbrineKilos(tempList, bakId);
        let ii = new InpekelItem({ bakId: bakId });
        if (remaining > 0) {
            // voeg een inpekelitem toe met de resterende hoeveelheid
            // todo indien meer dan 5%??
            ii.inpekelTijd = DateHelper.convertedToTime();
            ii.kilos = Math.round(remaining);
            ii.amount = Math.round(remaining / defaults.gewicht);
            ii.nouse = true;
            ii.vormID = vormID;
            ii.productID = productID;
        } else {
            // doe niets meer, er is al teveel / genoeg toegevoegd
            ii = new InpekelItem({ bakId: bakId });
            ii.kilos = Math.round(remaining);
            ii.nouse = true;
            ii.vormID = vormID;
            ii.productID = productID;
        }
        result.push(ii);
    } else {
        const ii = new InpekelItem({ bakId: bakId });
        ii.inpekelTijd = DateHelper.convertedToTime();
        ii.amount = aantal;
        ii.vormID = vormID;
        ii.productID = productID;
        // voor het gemak van de gebruiker doen we deze er wel gewoon in
        // result.nouse = true;
        result.push(ii);
    }
    return result;
};

const determineInbrineKilos = (batchInPekelList, bakId) => {
    const prodLijst = batchInPekelList.filter(
        (x) => x.isDeleted == false && x.bakId == bakId
    );
    let totKilos = 0;
    for (const item of prodLijst) {
        if (item.kilos) {
            totKilos += item.kilos;
        } else {
            const shape = store.getters['components/getShapebyID'](item.vormID);
            const calcKilos =
                shape == undefined ? 0 : item.amount * shape.gewicht;
            totKilos += calcKilos;
        }
    }
    return totKilos;
};
const giveAlternativesForList = (batchInPekelList, lijstAlt, bakId, vormID) => {
    const result = [];
    // check of de alternatieven al in de inpekellijst zitten, dan niets doen
    for (let i = 0; i < lijstAlt.length; i++) {
        const item = batchInPekelList.find(
            (x) => x.productID == lijstAlt[i].productID
        );
        if (item) {
            // hij zit al in de lijst  dus we gaan gewoon verder
            // todo: check op vorm?
        } else {
            const ii = new InpekelItem({ bakId: bakId });
            ii.inpekelTijd = DateHelper.convertedToTime();
            ii.amount = lijstAlt[i].aantal;
            ii.vormID = vormID;
            ii.productID = lijstAlt[i].productID;
            ii.wijzigbaar = false;
            // voor het gemak van de gebruiker doen we deze er wel gewoon in
            // result.nouse = true;
            result.push(ii);
        }
    }
    return result;
};

const loadAlternatives = (bak, IngredientenKruiden) => {
    const bilijstArray = [];
    const bilijstBaseArray = [];
    const kruidenLijst = bak.recIngredienten.filter(
        (x) => x.isDeleted == false && x.ingredientCatID == INGRCAT_ENUM.Kruiden
    );
    const alternativeLijst = bak.bakIngredienten.filter(
        (x) => x.isDeleted == false && x.alternatief != undefined
    );
    if (alternativeLijst.length > 0) {
        // er zijn alternatieven. kan zijn kruiden bij naturel maar ook naturel uit kruid
        // naturel uit kruid kan er maar 1 zijn, kruid uit naturel kan meer zijn
        // groeperen per alternatieve kaas ==> NIEUW (misbruik van BIlijst??)
        const arrKaasproducten = [];
        for (let i = 0; i < alternativeLijst.length; i++) {
            const item = alternativeLijst[i];
            const arrAlt = laadAlternatieveKaas(item.alternatieven);
            for (let j = 0; j < arrAlt.length; j++) {
                item.bakID = bak.id;
                item.alternatieven = arrAlt;
                const productID = arrAlt[j].productID;
                const aantal = arrAlt[j].aantal;
                const exists = arrKaasproducten.find(
                    (x) => x.productID == productID
                );
                if (exists) {
                    exists.bakIngredienten.push(item);
                } else {
                    const nieuw = new Alternativeslijst({ id: bak.id });
                    nieuw.productID = productID;
                    nieuw.aantal = aantal;
                    nieuw.isAlternatief = true;
                    nieuw.bakIngredienten = [];
                    nieuw.showIngredient = kruidenLijst.length == 0;
                    nieuw.bakIngredienten.push(item);
                    bilijstArray.push(nieuw);
                    arrKaasproducten.push(nieuw);
                }
            }
        }
    } else {
        if (IngredientenKruiden.length > 0) {
            const kruid1 = IngredientenKruiden[0].bakIngredienten[0];
            const nieuw = new Alternativeslijst({ id: bak.id });
            nieuw.productID = 1; // todo
            nieuw.aantal = 0;
            nieuw.isAlternatief = true;
            nieuw.showIngredient = kruidenLijst.length == 0;
            nieuw.bakIngredienten = [];
            nieuw.bakIngredienten.push(kruid1);
            bilijstBaseArray.push(nieuw);
        }
    }
    return { bilijstArray, bilijstBaseArray };
};

const laadAlternatieveKaas = (alt) => {
    const result = [];
    const altKaasArray = alt.split(';');
    for (let i = 0; i < altKaasArray.length; i++) {
        if (altKaasArray[i].indexOf(',') > 0) {
            const altKaas = altKaasArray[i].split(',');
            const ac = new AlternativeCheese({
                productID: altKaas[0],
                amount: altKaas[1]
            });
            result.push(ac);
        }
    }
    return result;
};

const addAddedMilkToRecingredients = (bak, ingredientCatID) => {
    const ingredientCat =
        store.getters['product/getIngredientCategorieByID'](ingredientCatID);
    const recLijst = bak.recIngredienten.filter(
        (x) => x.ingredientCatID == ingredientCatID && x.isDeleted == false
    );
    const prodLijst = bak.bakIngredienten.filter(
        (x) => x.ingredientCat == ingredientCat.naam && x.isDeleted == false
    );
    if (recLijst == undefined || prodLijst == undefined) return bak;
    for (let i = prodLijst.length - 1; i >= 0; i--) {
        const productID = prodLijst[i].productID;
        const test = recLijst.find((x) => x.productID === productID);
        if (test) prodLijst.splice(i, 1);
    }
    if (prodLijst.length > 0) {
        for (let i = prodLijst.length - 1; i >= 0; i--) {
            // er is een product toegevoegd in deze categorie dat niet in het recept zat
            // voor weergave voeg hem toe aan recingredient
            const baseRI = recLijst[0];
            const newRI = new RecIngredient({
                id: -2,
                recipeID: baseRI.recipeID,
                igCategoryID: baseRI.ingredientCatID,
                productID: prodLijst[i].productID,
                amount: prodLijst[i].hoeveelheid,
                perUnitID: prodLijst[i].eenheidID ?? baseRI.eenheid
            });
            bak.recIngredienten.push(newRI);
        }
    }
    return bak;
};

const loadBILijst = (bak, ingredientCatID, prodFactor, func) => {
    const isZuursel = ingredientCatID == INGRCAT_ENUM.Zuursel;
    const r = isZuursel
        ? determineRIAmountForZuursel(bak, prodFactor, func)
        : determineRIAmountForCat(bak, ingredientCatID, prodFactor, func);
    return r;
};

const giveNewBIwithAmounts = (prod, rec, amountToAdd) => {
    let result = null;
    if (prod.lijst.length > 0) {
        //if (rec.unit == prod.unit) {
        const remaining = amountToAdd - prod.amount + Number.EPSILON;
        if (remaining > 0) {
            // voeg een bi toe met de resterende hoeveelheid
            // todo indien meer dan 5%??
            result = new BakIngredient();
            result.hoeveelheid =
                Math.round((remaining / prod.calcFactor) * 100) / 100;
            result.eenheidID = prod.eenheidID ?? prod.unitid;
            result.eenheid = prod.unit;
            result.productID = rec.productID;
            result.nouse = true;
        } else {
            // doe niets meer, er is al teveel / genoeg toegevoegd
            result = new BakIngredient();
            result.hoeveelheid =
                Math.round((remaining / prod.calcFactor) * 100) / 100;
            result.eenheidID = prod.eenheidID ?? prod.unitid;
            result.eenheid = prod.unit;
            result.productID = rec.productID;
            result.nouse = true;
        }

        // } else {
        //     // verschillende eenheden // todo
        // }
    } else {
        result = new BakIngredient();
        result.hoeveelheid =
            Math.round((amountToAdd / rec.calcFactor) * 100) / 100;
        result.eenheidID = rec.unitid;
        result.productID = rec.productID;
        // voor het gemak van de gebruiker doen we deze er wel gewoon in
        // result.nouse = true;
    }
    return result;
};

// bepaal de batchingredients
// ToDO straks geavanceerder
const determineBIAmountForCat = (bak, ingredientCatString, productID) => {
    let prodUnit = null;
    let prodUnitID = null;
    let prodAmount = 0;
    let prodCalcFactor = 1;
    let prodLijst = [];
    if (bak.bakIngredienten != undefined && bak.bakIngredienten.length > 0) {
        prodLijst = bak.bakIngredienten.filter(
            (x) =>
                x.ingredientCat == ingredientCatString &&
                x.isDeleted == false &&
                x.productID == productID
        );
        //&& x.alternatief == undefined nu kan je gen extra kruid aan enkele kazen toevoegen
        const tempArr = prodLijst.map((x) => x.hoeveelheid * x.factor);
        prodAmount = _.sum(tempArr);
        const o = _.first(prodLijst); // todo: dit is qend
        prodUnit = o ? o.eenheid : null; // todo: dit is qend
        prodUnitID = o ? o.eenheidID : null; // todo: dit is qend
        if (!prodUnitID) {
            prodUnitID = store.getters['components/getUnitIDbyName'](prodUnit);
        }
        prodCalcFactor = o ? o.factor : 1; // todo: dit is qend
    }
    return {
        amount: prodAmount,
        unit: prodUnit,
        unitid: prodUnitID,
        calcFactor: prodCalcFactor,
        lijst: prodLijst
    };
};

const determineRIAmountForZuursel = (bak, prodFactor, func) => {
    const result = [];
    const ingredientCat = store.getters['product/getIngredientCategorieByID'](
        INGRCAT_ENUM.Zuursel
    );
    if (bak.recIngredienten != undefined && bak.recIngredienten.length > 0) {
        const recLijst = bak.recIngredienten.filter(
            (x) =>
                x.ingredientCatID == INGRCAT_ENUM.Zuursel &&
                x.isDeleted == false
        );
        // // indien special dan hoeft er maar 1 van de specials in beeld te komen als er al is toegevoegd
        const SpecialAdded = [];
        const arrSpecialsToRemove = [];
        // voor recingredient moet ieder product afgemeld kunnen worden, niet optellen dus
        if (recLijst.length > 0) {
            for (let i = 0; i < recLijst.length; i++) {
                const nieuw = new BIlijst({ id: bak.id });
                nieuw.special = recLijst[i].heeftSubType;
                nieuw.checkAmount = true;
                nieuw.prodFactor = prodFactor;
                const eenheid = store.getters['components/getUnitbyID'](
                    recLijst[i].eenheid
                );
                const ingredientProduct = store.getters[
                    'product/getIngredientProductByID'
                ](recLijst[i].productID);
                if (ingredientProduct) {
                    nieuw.productNaam = ingredientProduct.naam;
                } else {
                    loadItem(
                        'product/loadIngredient',
                        {
                            productID: recLijst[i].productID
                        },
                        func
                    ).then((x) => {
                        nieuw.productNaam = x.naam;
                        nieuw.special = x.zuurselSubType;
                        nieuw.isDeleted = x.isDeleted;
                    });
                }
                nieuw.ingredientCategorieNaam = ingredientCat.naam;
                nieuw.ingredientCategorieID = ingredientCat.id;
                nieuw.recipeAmount = recLijst[i].hoeveelheid * eenheid.factor;
                nieuw.recipeUnitID = recLijst[i].eenheid;
                nieuw.productID = recLijst[i].productID;

                const p = determineBIAmountForCat(
                    bak,
                    ingredientCat.naam,
                    nieuw.productID
                ); // todo productID
                nieuw.bakIngredienten = p.lijst;
                const isToegevoegd = p.lijst.length > 0;

                const amountToAdd = nieuw.recipeAmount * prodFactor;
                const productenLijst = []; // todo weg?
                const r = {
                    amount: nieuw.recipeAmount,
                    lijst: productenLijst,
                    unitid: nieuw.recipeUnitID,
                    productID: nieuw.productID,
                    calcFactor: eenheid.factor
                };
                const nieuwBI = giveNewBIwithAmounts(p, r, amountToAdd);
                let dontPush = false;
                if (nieuwBI) {
                    nieuwBI.bakID = nieuw.bakId;
                    nieuwBI.special = nieuw.special;
                    if (nieuw.special) {
                        if (!SpecialAdded.includes(nieuw.special)) {
                            nieuw.bakIngredienten.push(nieuwBI);
                            if (isToegevoegd) SpecialAdded.push(nieuw.special);
                        } else {
                            dontPush = true;
                        }
                    } else {
                        nieuw.bakIngredienten.push(nieuwBI);
                    }
                }

                if (!SpecialAdded.includes(nieuw.special) && nieuw.special) {
                    arrSpecialsToRemove.push(nieuw);
                }
                if (!dontPush) {
                    result.push(nieuw);
                }
            }
            if (SpecialAdded.length > 0 && arrSpecialsToRemove.length > 0) {
                // we moeten de onterecht toegevoegde nog even weggooien
                for (let i = 0; i < arrSpecialsToRemove.length; i++) {
                    for (let j = result.length - 1; j >= 0; --j) {
                        if (
                            result[j].productID ==
                            arrSpecialsToRemove[i].productID
                        ) {
                            result.splice(j, 1);
                        }
                    }
                }
            }
        }
    }
    return result;
};

const determineRIAmountForCat = (bak, ingredientCatID, prodFactor, func) => {
    const result = [];
    const isMelk = ingredientCatID == INGRCAT_ENUM.Melk;
    const ingredientCat =
        store.getters['product/getIngredientCategorieByID'](ingredientCatID);
    if (bak.recIngredienten != undefined && bak.recIngredienten.length > 0) {
        const recLijst = bak.recIngredienten.filter(
            (x) => x.ingredientCatID == ingredientCatID && x.isDeleted == false
        );
        // indien special dan hoeft er maar 1 van de specials in beeld te komen als er al is toegevoegd
        const arrAddedSpecials = [];
        // voor recingredient moet ieder product afgemeld kunnen worden, niet optellen dus
        if (recLijst.length > 0) {
            for (let i = 0; i < recLijst.length; i++) {
                const nieuw = new BIlijst({ id: bak.id });
                nieuw.special = recLijst[i].heeftSubType;
                nieuw.checkAmount = !isMelk;
                nieuw.prodFactor = prodFactor;
                const eenheid = store.getters['components/getUnitbyID'](
                    recLijst[i].eenheid
                );
                const ingredientProduct = store.getters[
                    'product/getIngredientProductByID'
                ](recLijst[i].productID);
                if (ingredientProduct) {
                    nieuw.productNaam = ingredientProduct.naam;
                } else {
                    loadItem(
                        'product/loadIngredient',
                        {
                            productID: recLijst[i].productID
                        },
                        func
                    ).then((x) => {
                        nieuw.productNaam = x.naam;
                        nieuw.special = x.zuurselSubType;
                        nieuw.isDeleted = x.isDeleted;
                    });
                }

                nieuw.ingredientCategorieNaam = ingredientCat.naam;
                nieuw.ingredientCategorieID = ingredientCat.id;
                nieuw.recipeAmount = recLijst[i].hoeveelheid * eenheid.factor;
                nieuw.recipeUnitID = recLijst[i].eenheid;
                nieuw.productID = recLijst[i].productID;

                const p = determineBIAmountForCat(
                    bak,
                    ingredientCat.naam,
                    nieuw.productID
                ); // todo productID
                nieuw.bakIngredienten = p.lijst;

                const amountToAdd = nieuw.recipeAmount * prodFactor;
                const productenLijst = []; // todo weg?
                const r = {
                    amount: nieuw.recipeAmount,
                    lijst: productenLijst,
                    unitid: nieuw.recipeUnitID,
                    productID: nieuw.productID,
                    calcFactor: eenheid.factor
                };
                const nieuwBI = giveNewBIwithAmounts(p, r, amountToAdd);
                if (nieuwBI) {
                    nieuwBI.bakID = nieuw.bakId;
                    nieuw.bakIngredienten.push(nieuwBI);
                }
                result.push(nieuw);
            }
        }
    }
    return result;
};

// bepaal de factor voor de bak op basis van de meest recente gegevens
// ToDO straks geavanceerder indien Oe en Ov bekend zijn
const determineProdFactor = (bak) => {
    let recMelk = 1;
    let prodMelk = 1;
    if (bak.recIngredienten != undefined) {
        const recMelkLijst = bak.recIngredienten.filter(
            (x) =>
                x.ingredientCatID == INGRCAT_ENUM.Melk && x.isDeleted == false
        );
        const tempArr = recMelkLijst.map((x) => x.hoeveelheid * x.factor);
        recMelk = _.sum(tempArr);
    }
    if (bak.bakIngredienten != undefined) {
        const ingrtCat = store.getters['product/getIngredientCategorieByID'](
            INGRCAT_ENUM.Melk
        );
        const prodMelkLijst = bak.bakIngredienten.filter(
            (x) => x.ingredientCat == ingrtCat.naam && x.isDeleted == false
        );
        const tempArr2 = prodMelkLijst.map((x) => x.hoeveelheid * x.factor);
        prodMelk = _.sum(tempArr2);
    }
    const calcFactor = prodMelk > 1 && recMelk != 0 ? prodMelk / recMelk : 1;
    return {
        prodCalcFactor: calcFactor,
        recipeMelk: recMelk
    };
};

export { loadSubObject };
