const getters = {
    getStockItemLijst: state =>  {
        return state.voorraadItemLijst;
    }, 
    getStockInfo: state =>  {
        return state.voorraadInfoList;
    }, 
    getStockInfoArchived: state =>  {
        return state.voorraadInfoListArchived;
    },
    getMarkStockItemLijst: state =>  {
        return state.voorraadMarkItemLijst;
    }, 
    getMarkStockInfo: state =>  {
        return state.voorraadMarkInfoList;
    }, 
    getMarkStockInfoArchived: state =>  {
        return state.voorraadMarkInfoListArchived;
    }, 
    getSelectedItem: state =>  {
        return state.selectedVoorraadItem;
    },
    getSelectedItemInfo: state =>  {
        return state.selectedVoorraadItemInfo;
    },    
    getStockLocationTypes: state =>  {
        return state.stockLocationTypesLijst;
    },  
    getStockItemMarksInfo: state =>  {
        return state.stockItemMarksInfoLijst;
    },  
    getStockLocationTypeByID: state => (id) => {
        return state.stockLocationTypesLijst.find(x => x.id === id);
    },   
    getStockLocationTypeByDescription: state => (description) => {
        return state.stockLocationTypesLijst.find(x => x.description.toLowerCase() === description.toLowerCase());
    }
    
};

export default getters;

