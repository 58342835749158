<template>
    <b-row class="justify-content-center">
        <b-col md="3">
            <login-form v-bind:navigeerUrl="getNavigatieURL" />
        </b-col>
    </b-row>
</template>

<script>
import LoginForm from './LoginForm';
//import Mgr from './security';

export default {
    name: 'LoginPage',
    data: function() {
        return {
            navigeerUrl: './'
        };
    },
    components: {
        'login-form': LoginForm
    },
    computed: {
        availableGroups() {
            return this.$store.getters['account/getGroepen'];
        },
        getNavigatieURL: function() {
            return this.navigeerUrl;
        }
    },
    methods: {
    },
    mounted() {
        this.navigeerUrl = this.$router.currentRoute.query.ReturnUrl;

    },
    created() {}
};
</script>

<style scoped></style>
