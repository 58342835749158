export { PRODCAT_ENUM, ProductCategorie };

const PRODCAT_ENUM = Object.freeze({
    Kaas: 1,
    Ingrediënt: 2,
    Kaasmerk: 3,
    Kaasdekmiddel: 4,
    Wei: 5,
    Onvolwaardig: 6,
    Overige: 7
});
export default PRODCAT_ENUM;

class ProductCategorie {
    constructor(data) {
        this.id = -1;
        this.type = 'ProductCategorie',
        data = data ?? {};
        this.isDeleted = false;
        this.isChanged = false;
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false,
                enumerable: true
            },
            naam: {
                value: data.description,
                writable: true,
                enumerable: true
            },
            eenheid: {
                value: data.unitid,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = (this.id == undefined || this.id < 0);
    }
}
