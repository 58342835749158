const mutations = {
    insertEntityObjectLijst(state, item) {
        state.entityObjectList = item;
    },
    insertTemplateViewLijst(state, lijst) {
        state.templateList = [];
        if (lijst != undefined) {
            state.templateList = lijst;
        }
    },  
    insertQuantityLijst(state, lijst) {
        state.quantityList = [];
        if (lijst != undefined) {
            state.quantityList = lijst;
        }
    },  
    insertQunitLijst(state, lijst) {
        state.qunitList = [];
        if (lijst != undefined) {
            state.qunitList = lijst;
        }
    }, 
    insertQualityLijst(state, lijst) {
        state.qualityList = [];
        if (lijst != undefined) {
            state.qualityList = lijst;
        }
    },  
    insertQlabelLijst(state, lijst) {
        state.qlabelList = [];
        if (lijst != undefined) {
            state.qlabelList = lijst;
        }
    }, 
    insertIQLijst(state, lijst) {
        state.iqList = [];
        if (lijst != undefined) {
            state.iqList = lijst;
        }
    }, 
    insertMIQLijst(state, lijst) {
        state.miqList = [];
        if (lijst != undefined) {
            state.miqList = lijst;
        }
    }, 
    insertExecutorLijst(state, lijst) {
        state.executorList = [];
        if (lijst != undefined) {
            state.executorList = lijst;
        }
    }, 
    insertExecutorQuantityLijst(state, lijst) {
        state.executorQuantityList = [];
        if (lijst != undefined) {
            state.executorQuantityList = lijst;
        }
    }, 
    insertExecutorQualityLijst(state, lijst) {
        state.executorQualityList = [];
        if (lijst != undefined) {
            state.executorQualityList = lijst;
        }
    }, 
    insertMaesurementLijst(state, lijst) {
        state.maesurementList = [];
        if (lijst != undefined) {
            state.maesurementList = lijst;
        }
    },
    insertAssesmentLijst(state, lijst) {
        state.assesmentList = [];
        if (lijst != undefined) {
            state.assesmentList = lijst;
        }
    },
    insertNormenLijst(state, lijst) {
        state.normList = [];
        if (lijst != undefined) {
            state.normList = lijst;
        }
    },
    insertEOTypeNamesLijst(state, lijst) {
        state.eoTypeNamesList = [];
        if (lijst != undefined) {
            state.eoTypeNamesList = lijst;
        }
    },
    insertSampleLijst(state, lijst) {
        state.sampleList = [];
        if (lijst != undefined) {
            state.sampleList = lijst;
        }
    },
    insertSampleTypeLijst(state, lijst) {
        state.sampleTypeList = [];
        if (lijst != undefined) {
            state.sampleTypeList = lijst;
        }
    },
    
};

export default mutations;
