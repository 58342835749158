<template>
    <b-card no-body>
        <b-card-header class="container-fluid">
            {{ $t('prod_vullen', { ns: 'production' }) }}
            <b-form-checkbox
                class="float-right"
                v-if="configUseFahrenheit"
                v-model="showFahrenheit"
            >
                {{ $t('prod_usefahrenheit', { ns: 'production' }) }}
            </b-form-checkbox>
        </b-card-header>
        <div>
            <card-bakingredient-lijst
                v-if="theBakMelk.bakIngredientenMelk.length > 0"
                v-model="theBakMelk.bakIngredientenMelk"
                v-bind:editAllowed="editAllowed"
                v-bind:baseDateTime="theBakMelk.boekDatum"
                @change="setChangedMilk"
                @validComponent="setMelkValid"
            />
            <card-bakingredient-lijst
                :key="componentKey + 1000"
                v-if="theBakMelk.bakIngredientenZuursel.length > 0"
                v-model="theBakMelk.bakIngredientenZuursel"
                v-bind:editAllowed="editAllowed"
                v-bind:baseDateTime="theBakMelk.boekDatum"
                v-bind:preselected="voorkeurZuursel"
                @change="setChanged"
                @validComponent="setZuurselValid"
                @zuurseltijd="set_inputZuurselTijd"
            />
            <card-bakingredient-lijst
                :key="componentKey + 2000"
                v-if="theBakMelk.bakIngredientenCalcium.length > 0"
                v-model="theBakMelk.bakIngredientenCalcium"
                v-bind:editAllowed="editAllowed"
                v-bind:baseDateTime="theBakMelk.boekDatum"
                @change="setChanged"
                @validComponent="setCalciumValid"
            />
            <card-bakingredient-lijst
                :key="componentKey + 3000"
                v-if="theBakMelk.bakIngredientenKleursel.length > 0"
                v-model="theBakMelk.bakIngredientenKleursel"
                v-bind:editAllowed="editAllowed"
                v-bind:baseDateTime="theBakMelk.boekDatum"
                @change="setChanged"
                @validComponent="setKleurselValid"
            />
            <card-bakingredient-lijst
                :key="componentKey + 4000"
                v-if="theBakMelk.bakIngredientenConserveermiddel.length > 0"
                v-model="theBakMelk.bakIngredientenConserveermiddel"
                v-bind:editAllowed="editAllowed"
                v-bind:baseDateTime="theBakMelk.boekDatum"
                @change="setChanged"
                @validComponent="setConserveerValid"
            />
            <b-card>
                <b-col sm="9" class="mx-0 px-0 my-1 py-0">
                    <b-row class="mx-0 px-0 my-1 py-0">
                        <b-col v-if="hastempTherm" sm="3" class="mx-0 px-0 my-1 py-0">
                            <label for="input_therm"
                                >{{
                                    $t('prod_xtemperatuur', {
                                        ns: 'production',
                                        val: $t('prod_therm', {
                                            ns: 'production'
                                        })
                                    })
                                }}:
                            </label>
                        </b-col>
                        <b-col v-if="hastempTherm" sm="9" class="mx-0 px-0 my-1 py-0">
                            <temperature-input
                                id="input-therm"
                                v-model.number="tempTherm"
                                @input="set_inputThermTemperatuur"
                                :editAllowed="editAllowed"
                                :showFahrenheit="showFahrenheit"
                                :minvalue="minThermTemp"
                                :maxvalue="maxThermTemp"
                                :placeholderC="
                                    $t('com_temperatuur', { ns: 'common' })
                                "
                            />
                        </b-col>
                        <b-col v-if="hastempPasteur" sm="3" class="mx-0 px-0 my-1 py-0">
                            <label for="input_past"
                                >{{
                                    $t('prod_xtemperatuur', {
                                        ns: 'production',
                                        val: $t('prod_past', {
                                            ns: 'production'
                                        })
                                    })
                                }}:
                            </label>
                        </b-col>
                        <b-col v-if="hastempPasteur" sm="9" class="mx-0 px-0 my-1 py-0">
                            <temperature-input
                                id="input-past"
                                v-model.number="tempPasteur"
                                @input="set_inputPastTemperatuur"
                                @valid="set_validTemp"
                                :editAllowed="editAllowed"
                                :showFahrenheit="showFahrenheit"
                                :minvalue="minPastTemp"
                                :maxvalue="maxPastTemp"
                                :placeholderC="
                                    $t('com_temperatuur', { ns: 'common' })
                                "
                            />
                        </b-col>
                        <b-col sm="3" class="mx-0 px-0 my-1 py-0">
                            <label
                                >{{
                                    $t('prod_ost', {
                                        ns: 'production'
                                    })
                                }}:
                            </label>
                        </b-col>
                        <b-col sm="9" class="mx-0 px-0 my-1 py-0">
                            <b-form-select
                                v-model="ostID"
                                :options="wrongelbereiderList"
                                @change="set_inputWB"
                                :disabled="!editAllowed"
                            >
                                <template #first>
                                    <b-form-select-option value=""
                                        >--
                                        {{
                                            $t('com_selecteer', {
                                                ns: 'common'
                                            })
                                        }}
                                        --</b-form-select-option
                                    >
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col sm="3" class="mx-0 px-0 my-1 py-0">
                            <label
                                >{{
                                    $t('prod_xprogramma', {
                                        ns: 'production',
                                        val: $t('prod_ost', {
                                            ns: 'production'
                                        })
                                    })
                                }}:
                            </label>
                        </b-col>
                        <b-col sm="9" class="mx-0 px-0 my-1 py-0">
                            <b-form-input
                                id="input-program"
                                :placeholder="
                                    $t('prod_xprogramma', {
                                        ns: 'production',
                                        val: $t('prod_ost', {
                                            ns: 'production'
                                        })
                                    })
                                "
                                v-model="programma"
                                @change="set_inputProgramma"
                                :disabled="!editAllowed"
                            ></b-form-input>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col sm="3" class="mx-0 px-0 my-1 py-0">
                    <b-row class="mx-0 px-0 my-1 py-0">
                        <b-col>
                            <b-button
                                @click="SetDefaults()"
                                class="bgc_aux_warning float-right "
                                :disabled="!editAllowed"
                                ><font-awesome-icon
                                    icon="edit"
                                    size="1x"
                                />&nbsp;{{
                                    $t('com_default', { ns: 'common' })
                                }}
                            </b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-card>
        </div>
    </b-card>
</template>

<script>
import { BakMelk } from '../../models/BakInfo_helper';
import { Feature } from '../../models/Features';
import { loadList } from '../../models/DataHelper';
import Helper from '../../models/Helper';
import { TemperatureInput } from '../components';
import CardBakingredientLijst from './CardBakingredientLijst';
import _ from 'lodash';

export default {
    name: 'CardMelk',
    data() {
        return {
            hastempPasteur: false,
            hastempTherm: false,
            grCelcius: window.constants.GRADEN_C,
            grFahrenheit: window.constants.GRADEN_F,
            minThermTemp: window.constants.MIN_TEMP_THERM,
            maxThermTemp: window.constants.MAX_TEMP_THERM,
            minPastTemp: window.constants.MIN_TEMP_PAST,
            maxPastTemp: window.constants.MAX_TEMP_PAST,
            showFahrenheit: true,
            validTemperatuur: true,
            tempPasteur: null,
            tempTherm: null,
            programma: null,
            ostID: -1,
            melkValid: true,
            zuurselValid: true,
            kleurselValid: true,
            calciumValid: true,
            conserveerValid: true,
            wrongelbereiderList: [],
            milkCalcFactor: 1,
            prevMelk: 0,
            recipeMilk: 0,
            lokBakIngredient: null,
            componentKey: 0,
            preselectedStarter: -1
        };
    },
    components: {
        CardBakingredientLijst,
        TemperatureInput
    },
    model: {
        prop: 'theBakMelk',
        event: 'changedRecipe'
    },
    props: {
        theBakMelk: {
            type: BakMelk,
            default: null
        },
        editAllowed: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        voorkeurZuursel() {
            return this.preselectedStarter;
        },
        configUseFahrenheit() {
            const useF = this.checkFeature('conf_useFahrenheitinProduction');
            const useTemp = this.hastempTherm || this.hastempPasteur;
            return useF && useTemp;
        }
    },
    methods: {
        checkFeature(feature) {
            const key = Feature.enum(feature);
            return this.$root.handleFeature(key);
        },
        set_validTemp(value) {
            this.validTemperatuur = value;
        },
        set_inputZuurselTijd(value) {
            this.theBakMelk.zuurselTijdstip = value;
            this.setChanged();
        },
        set_inputThermTemperatuur() {
            this.theBakMelk.thermTemp = this.tempTherm ? this.tempTherm : null;
            this.setChanged();
        },
        set_inputPastTemperatuur() {
            this.theBakMelk.pastTemp = this.tempPasteur
                ? this.tempPasteur
                : null;
            this.setChanged();
        },
        set_inputProgramma() {
            this.theBakMelk.WBprogram = this.programma ? this.programma : null;
            this.setChanged();
        },
        set_inputWB() {
            this.theBakMelk.ostID = this.ostID ? this.ostID : null;
            this.setChanged();
        },
        setZuurselValid(value) {
            this.zuurselValid = value;
            this.setChanged();
        },
        setMelkValid(value) {
            this.melkValid = value;
            this.setChanged();
        },
        setKleurselValid(value) {
            this.kleurselValid = value;
            this.setChanged();
        },
        setCalciumValid(value) {
            this.calciumValid = value;
            this.setChanged();
        },
        setConserveerValid(value) {
            this.conserveerValid = value;
            this.setChanged();
        },
        setChanged() {
            this.$emit(
                'validComponent',
                this.validTemperatuur &&
                    this.melkValid &&
                    this.zuurselValid &&
                    this.calciumValid &&
                    this.conserveerValid &&
                    this.kleurselValid
            );
            this.$emit('change', true);
        },
        setChangedMilk() {
            // herbereken hoeveelheden
            const newMelk = this.calcTot(this.theBakMelk.bakIngredientenMelk);
            this.prevMelk = this.prevMelk == 0 ? newMelk : this.prevMelk;
            this.milkCalcFactor = newMelk / this.prevMelk;
            this.prevMelk = newMelk;
            this.theBakMelk.prodcalcFactor = newMelk / this.recipeMilk;
            this.adjustListAmounts(this.theBakMelk.bakIngredientenCalcium);
            this.adjustListAmounts(this.theBakMelk.bakIngredientenZuursel);
            this.adjustListAmounts(this.theBakMelk.bakIngredientenKleursel);
            this.adjustListAmounts(
                this.theBakMelk.bakIngredientenConserveermiddel
            );
            this.setChanged();
        },
        async SetDefaults() {
            const selectionData = { recipeid: this.theBakMelk.receptID };
            const recipe = await loadList(
                'recipe/getRecipe',
                'recipe/loadRecipeComplete',
                selectionData,
                true,
                this.$t
            );
            this.tempPasteur = recipe.recNorm.pasteurisatieTemp;
            this.tempTherm = recipe.recNorm.thermisatieTemp;
            this.programma = recipe.recProduction.progWB;
            this.set_inputPastTemperatuur();
            this.set_inputThermTemperatuur();
            this.set_inputProgramma();
        },
        calcTot(list) {
            let result = 0;
            for (let i = 0; i < list.length; i++) {
                const tempArr2 = list[i].bakIngredienten.map(
                    (x) => (x.hoeveelheid ?? 0) * x.factor
                );
                const subTot = _.sum(tempArr2);
                result += subTot;
            }
            return result;
        },
        adjustListAmounts(list) {
            for (let i = 0; i < list.length; i++) {
                for (let j = 0; j < list[i].bakIngredienten.length; j++) {
                    this.lokBakIngredient = list[i].bakIngredienten[j];
                    if (
                        this.lokBakIngredient.nouse == false &&
                        this.lokBakIngredient.isNew
                    ) {
                        const hoeveelheid = (
                            this.lokBakIngredient.hoeveelheid *
                            this.milkCalcFactor
                        ).toFixed(3);
                        this.lokBakIngredient.hoeveelheid = hoeveelheid;
                        list[i].bakIngredienten[j].hoeveelheid = hoeveelheid;
                        this.componentKey += 1;
                    }
                }
            }
        }
    },
    async mounted() {
        //  haal de wrongelbereiders op
        this.wrongelbereiderList = await loadList(
            'masterdata/getOstLijst',
            'masterdata/loadOsts',
            { productionlineid: window.constants.DEFPRODLINE },
            false,
            this.$t
        );
        this.tempPasteur = this.theBakMelk.pastTemp;
        this.tempTherm = this.theBakMelk.thermTemp;
        this.ostID = this.theBakMelk.ostID ?? -1;
        if (this.ostID == -1) {
            if (this.wrongelbereiderList.length == 1)
                this.ostID = this.wrongelbereiderList[0].value;
        }
        this.programma = this.theBakMelk.WBprogram;
        const recipe = this.$store.getters['recipe/getRecipe'];
        this.hastempPasteur = recipe.recNorm.pasteurisatieTemp != undefined;
        this.hastempTherm = recipe.recNorm.thermisatieTemp != undefined;
        this.prevMelk = this.calcTot(this.theBakMelk.bakIngredientenMelk);
        this.recipeMilk = this.prevMelk;
        this.showFahrenheit = this.configUseFahrenheit;
    },
    created() {
        const prod = this.$store.getters['production/getSelectedProdrun'];
        this.preselectedStarter = prod.zuurselTypeID;
    }
};
</script>
