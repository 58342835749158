<template>
    <b-card no-body class="mb-1">
        &nbsp;
        <b-table
            striped
            responsive
            :fields="detailFields"
            :items="localObjects"
            stacked="md"
            show-empty
            :empty-text="$t('com_geen_data', { ns: 'common' })"
            small
        >
            <template v-slot:cell(actions)="row">
                <div class="form-inline">
                    <b-button
                        v-if="checkRight('AnalyticsEnityObjectCreate')"
                        size="sm"
                        @click="bewerkItem(row.item)"
                        class="bgc_aux_warning"
                        ><font-awesome-icon icon="edit" size="1x" />
                    </b-button>
                    <b-button
                        v-if="checkRight('AnalyticsEnityObjectDelete')"
                        size="sm"
                        @click="verwijderItem(row.item)"
                        class="bgc_aux_alarm"
                        ><font-awesome-icon icon="trash-alt" size="1x" />
                    </b-button>
                </div>
            </template>
        </b-table>
        <modal-entity-object
            v-if="detailsVisible"
            :inItem="eo"
            v-model="detailsVisible"
            v-bind:typeName="theTypeName"
            @hide="reset"
        />
    </b-card>
</template>

<script>
import { Permission } from '../../models/Permissions';
import ModalEntityObject from './ModalEntityObject.vue';
import { loadList, loadItem } from './utils';
export default {
    name: 'EntityObjectInfo',
    data: function () {
        return {
            detailsVisible: false,
            theTypeName: window.constants.ADATA_STOCKLOCATION,
            eo: null
        };
    },
    model: {
        prop: 'visible',
        event: 'changed'
    },
    props: {
        inItems: {
            type: Array,
            required: true
        }
    },
    components: {
        ModalEntityObject
    },
    watch: {},
    computed: {
        detailFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                {
                    key: 'barcode',
                    label: this.$t('com_barcode', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'naam',
                    label: this.$t('com_naam', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'veldref',
                    label: this.$t('an_key', { ns: 'analytics' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'template',
                    label: this.$t('an_meetschema', { ns: 'analytics' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        localObjects() {
            return this.inItems;
        }
    },
    methods: {
        reset() {
            this.detailsVisible = false;
        },
        async bewerkItem(row) {
            if (row) {
                this.eo = row;
            }
            this.isNew = false;
            this.detailsVisible = !this.detailsVisible;
        },
        async verwijderItem(row) {
            const bericht =
                this.$t('com_delete', {
                    ns: 'common',
                    val: this.$t('an_eo', { ns: 'analytics' })
                }) + row.naam;
            const doClose = await this.$root.showConfirmationBox(bericht);
            if (doClose) {
                await loadItem(
                    'analytics/deleteAssesment',
                    {
                        assesmentID: row.id
                    },
                    this.$t
                );
            }
            this.loadData();
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        }
    },
    mounted() {},
    created() {}
};
</script>

<style scoped></style>

