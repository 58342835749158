<template>
    <div class="form-inline">
        <b-input-group>
            <b-input-group :prepend="$t('com_tabel', { ns: 'common' })">
                <b-form-select
                    v-model="selectedTable"
                    :options="linkedtables"
                    :disabled="!editAllowed"
                    @change="set_inputType"
                >
                    <template #first>
                        <b-form-select-option value=""
                            >--
                            {{ $t('com_selecteer', { ns: 'common' }) }}
                            --</b-form-select-option
                        >
                    </template>
                </b-form-select>
            </b-input-group>
            <div v-if="productrelated" class="form-inline">
                <b-input-group :prepend="$t('com_selectie', { ns: 'common' })">
                    <b-form-select
                        v-model="productID"
                        :options="productCatLijst"
                        :disabled="!editAllowed"
                        @change="set_input"
                    >
                        <template #first>
                            <b-form-select-option value=""
                                >--
                                {{ $t('com_selecteer', { ns: 'common' }) }}
                                --</b-form-select-option
                            >
                        </template>
                    </b-form-select>
                </b-input-group>
            </div>
        </b-input-group>
    </div>
</template>
<script>
import { PRODCAT_ENUM } from '../../models/ProductCategorie';
import DateHelper from '../../models/DateHelper';
import { loadItem, loadList } from '../../models/DataHelper';
export default {
    name: 'ExternalLinkSelector',
    data: function () {
        return {
            linkedtables: [
                {
                    value: window.constants.ELT_PRODUCT,
                    text: this.$t('com_product', { ns: 'common' })
                }
            ],
            selectedTable: window.constants.ELT_PRODUCT,
            productrelated: true,
            productCatLijst: null,
            productID: -1,
            ADDNUMBER: 100
        };
    },
    model: {
        prop: 'selected',
        event: 'input'
    },
    props: {
        selected: {
            type: Number,
            default: -1
        },
        editAllowed: {
            type: Boolean,
            default: true
        }
    },
    watch: {},
    components: {},
    computed: {},
    methods: {
        set_input(value) {
            this.$emit('inputtype', this.selectedTable);
            const subtype =
                value < this.ADDNUMBER
                    ? window.constants.PRODUCTCAT
                    : window.constants.INGREDIENTCAT;
            this.$emit('inputsubtype', subtype);
            const returnid =
                value < this.ADDNUMBER ? value : value - this.ADDNUMBER;
            //this.selected = returnid;
            this.$emit('input', returnid);
        },
        set_inputType(value) {
            switch (value) {
                case window.constants.ELT_PRODUCT: {
                    this.selectedTable = window.constants.ELT_PRODUCT;
                    this.productrelated = true;
                    break;
                }
                default: {
                    this.selectedTable = window.constants.ELT_PRODUCT;
                    this.productrelated = true;
                    break;
                }
            }
        }
    },
    mounted() {
        this.set_inputType(window.constants.ELT_PRODUCT);
    },
    async created() {
        const ingredientCatLijst = await loadList(
            'product/getIngredientCategorieLijst',
            'product/loadIngredientCategories',
            null,
            false,
            this.$t
        );
        const productCatLijst = await loadList(
            'product/getProductCategorieLijst',
            'product/loadProductCategories',
            null,
            false,
            this.$t
        );
        const baseList = productCatLijst.filter(
            (x) => x.id != PRODCAT_ENUM.Ingrediënt
        );
        this.productCatLijst = baseList.map((x) => ({
            value: x.id,
            text: x.naam
        }));
        const addLijst = ingredientCatLijst.map((x) => ({
            value: this.ADDNUMBER + x.id,
            text: x.naam
        }));
        this.productCatLijst.push(...addLijst);
    }
};
</script>

<style scoped></style>
