<template>
    <b-modal
        id="modal-plusprodvalue"
        size="lg"
        ref="modal"
        :title="this.formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
        @change="change"
    >
        <b-card @submit.stop.prevent="handleSubmit">
            <div>
                <b-row>
                    <b-col sm="3">
                        <label for="input-itemnaam">{{
                            $t('com_naam', { ns: 'common' })
                        }}</label>
                    </b-col>
                    <b-col sm="9">
                        <b-form-input
                            id="input-itemnaam"
                            v-model="localItem.naam"
                            :state="
                                localItem.naam != undefined &&
                                localItem.naam.length > 2
                            "
                            @change="setChanged"
                        ></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="3">
                        <label>Type:</label>
                    </b-col>
                    <b-col sm="9">
                        <b-form-radio-group
                            v-if="this.inItem.isNew"
                            v-model="selectedRadio"
                            :options="optionsRadio"
                            value-field="item"
                            text-field="name"
                            :disabled="setDisabled"
                        ></b-form-radio-group>
                        <b-form-input
                            v-else
                            v-model="typeName"
                            readonly
                        ></b-form-input>
                    </b-col>
                </b-row>
            </div>
            <b-alert
                v-model="showDismissibleAlert"
                variant="danger"
                dismissible
            >
                {{ alertText }}
            </b-alert>
        </b-card>
    </b-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { VueExtension } from '../../models/VueExtension';
import { SimpleProductionValue } from '../../models/PlusProduction';
import { loadItem } from '@/models/DataHelper';

export interface IItem {
    item: number;
    name: string;
}
export default defineComponent({
    name: 'PlusProductValueModal',
    data: function () {
        return {
            showDismissibleAlert: false,
            alertText: '',
            selectedRadio: 2,
            optionsRadio: [] as Array<IItem>,
            perUnitsId: 0,
            localNaam: '',
            opmerking: '',
            theID: -1,
            disabled: false,
            strNewProdValue: this.$t('com_makenew', {
                ns: 'common',
                val: this.$t('com_prodvalue', { ns: 'common' })
            }),
            strEditProdValue: this.$t('com_edit', {
                ns: 'common',
                val: this.$t('com_prodvalue', { ns: 'common' })
            })
        };
    },
    model: {
        prop: 'visible',
        event: 'change'
    },
    props: {
        inItem: {
            type: SimpleProductionValue,
            required: true
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    components: {},
    watch: {},
    computed: {
        setDisabled() {
            return !this.inItem.isNew;
        },
        formtitle() {
            return this.inItem.isNew
                ? this.strNewProdValue
                : this.strEditProdValue;
        },
        localItem(): SimpleProductionValue {
            return this.inItem;
        },
        typeName() {
            return this.getTypeName();
        }
    },
    methods: {
        getTypeName() {
            const t = this.inItem.type;
            const o = this.optionsRadio.find((x) => x.item == t);
            return o ? o.name : '?';
        },
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        hide() {
            this.$emit('hide');
        },
        change(visible) {
            this.setChanged();
            this.$emit('change', visible);
        },
        setChanged() {
            this.localItem.isChanged = true;
        },
        checkFormValidity() {
            const invalidBasis = this.localItem.CheckValidity(this.$t);
            if (invalidBasis) this.showAlert(invalidBasis);
            const valid = this.localItem != undefined && !invalidBasis;
            return valid;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return;
            }
            this.saveThisProduct();
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-plusprodvalue');
            });
        },
        async saveThisProduct() {
            this.localItem.isChanged = true;
            this.localItem.type = this.localItem.type ?? this.selectedRadio;
            const model = this.localItem.insertNew();
            await loadItem('tienplus/saveProductionValue', model, this.$t);
        }
    },
    async mounted() {
        const lijst = await loadItem('tienplus/loadProductionValueTypes', {});
        this.optionsRadio = lijst.map((x) => ({
            item: x.id,
            name: x.naam
        }));
    },
    created() {}
});
</script>

<style scoped></style>
