<template>
    <b-container fluid style="margin-top: 60px">
        <h1 @click="showBerichten()">{{ $t('nav_uitpekelen', { ns: 'nav' }) }}</h1>
        <b-row>
            <b-col sm="7" class="mx-0 px-0 my-0 py-0">
                <period-selector
                    v-model="selectDays"
                    v-bind:periodDays="periodeDagen"
                    @change="setNewDates"
                />
            </b-col>

            <b-col sm="2" class="mx-0 px-0 my-0 py-0">
                <b-col sm="12">
                    <b-input-group
                        :prepend="$t('prod_todo', { ns: 'production' })"
                    >
                        <b-input-group-prepend is-text>
                            <b-form-checkbox
                                id="checkbox-todo"
                                v-model="tedoen"
                                name="checkbox-todo"
                                value="onlyToDo"
                                unchecked-value="all"
                                @input="loadData()"
                            >
                            </b-form-checkbox>
                        </b-input-group-prepend>
                    </b-input-group>
                </b-col>
            </b-col>
            <b-col sm="1" class="mx-0 px-0 my-0 py-0">
                <b-row v-if="!isDagInfo">
                    <b-col sm="11">
                        <b-button
                            @click="loadData()"
                            class="bgc_aux_normal"
                            v-b-tooltip.hover
                            :title="$t('com_showdata', { ns: 'common' })"
                            ><font-awesome-icon icon="arrow-down" size="1x" />
                        </b-button>
                    </b-col>
                    <b-col sm="1"> </b-col>
                </b-row>
            </b-col>
            <b-col sm="1" class="mx-0 px-0 my-0 py-0">
                <b-row class="float-right">
                    <b-col sm="11">
                        <b-button
                            v-if="isDagInfo"
                            @click="addBrineIngredients()"
                            class="bgc_aux_unimportant"
                            v-b-tooltip.hover
                            :title="$t('prod_addpekel', { ns: 'production' })"
                            >{{
                                $t('prod_toevoeging', {
                                    ns: 'production'
                                })
                            }}...
                        </b-button>
                    </b-col>
                    <b-col sm="1"> </b-col>
                </b-row>
            </b-col>
            <b-col sm="1" class="mx-0 px-0 my-0 py-0">
                <b-row class="float-right">
                    <b-col sm="11"> </b-col>
                    <b-col sm="1"> </b-col>
                </b-row>
            </b-col>
        </b-row>

        <b-row>
            <b-col sm="12">
                <div v-if="showFilter">
                    <b-row no-gutters>
                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_filter', { ns: 'common' })"
                                label-for="filter-input"
                                label-cols-sm="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-input-group size="sm">
                                    <b-form-input
                                        id="filter-input"
                                        v-model="filter"
                                        type="search"
                                        :placeholder="
                                            $t('com_typetosearch', {
                                                ns: 'common'
                                            })
                                        "
                                    ></b-form-input>

                                    <b-input-group-append>
                                        <b-button
                                            :disabled="!filter"
                                            @click="filter = ''"
                                            >{{
                                                $t('com_wis', { ns: 'common' })
                                            }}</b-button
                                        >
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>

                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_perpagina', { ns: 'common' })"
                                label-for="per-page-select"
                                label-cols-sm="6"
                                label-cols-md="4"
                                label-cols-lg="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-form-select
                                    id="per-page-select"
                                    v-model="perPage"
                                    :options="pageOptions"
                                    size="sm"
                                ></b-form-select>
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                ></b-pagination>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <b-table
                    :items="displayList"
                    :fields="visibleFields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    stacked="md"
                    show-empty
                    :empty-text="$t('com_geen_data', { ns: 'common' })"
                    small
                    striped
                    @filtered="onFiltered"
                    @row-clicked="toonDetail"
                >
                    <template v-slot:cell(actions)="row">
                        <div class="form-inline">
                            <b-button
                                v-if="allowEdit && checkRight('BatchOutBrine')"
                                size="sm"
                                @click="outbrineItem(row.item)"
                                class="bgc_aux_warning"
                                ><font-awesome-icon icon="edit" size="1x" />
                            </b-button>
                            <b-button
                                size="sm"
                                @click="toonDetail(row.item)"
                                class="bgc_aux_unknown"
                                ><font-awesome-icon
                                    icon="info-circle"
                                    size="1x"
                                />
                            </b-button>
                        </div>
                    </template>

                    <template v-slot:cell(uitgepekeld)="row">
                        <b-badge v-if="row.item.uitgepekeld" variant="success">
                            ok
                        </b-badge>
                        <b-badge v-else variant="warning"> -- </b-badge>
                    </template>

                    <template v-slot:row-details>
                        <outbrine-card
                            v-model="uitgepekeldLijst"
                            v-bind:editAllowed="false"
                        >
                        </outbrine-card>
                    </template>
                </b-table>
                <div v-if="displayList.length == 0">
                    {{ strNoData }}
                </div>
            </b-col>
        </b-row>
        <outbrine-pop-form
            v-if="detailsVisible"
            :inPekellijst="ingepekeldLijst"
            :uitPekellijst="uitgepekeldLijst"
            :bakgroepLijst="batchgroupgepland"
            :bakgroep="bakgroep"
            :editAllowed="allowEdit"
            v-model="detailsVisible"
            v-bind:baseDateTime="van"
            @hide="reset"
        />
        <brine-ingredients-modal
            v-if="showBrineIngredients"
            v-model="showBrineIngredients"
            :baseDate="van"
            @hide="reset"
        />
    </b-container>
</template>

<script>
import OutbrinePopForm from './OutbrinePopForm';
import OutbrineCard from './OutbrineCard.vue';
import BrineIngredientsModal from './BrineIngredientsModal.vue';
import PeriodSelector from '../components/PeriodSelector.vue';
import DateHelper from '../../models/DateHelper';
import Helper from '../../models/Helper';
import { loadItem } from '../../models/DataHelper';
import { Permission } from '../../models/Permissions';

export default {
    name: 'OutbrineBase',
    data: function () {
        return {
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [10, 20, 40, { value: 100, text: '100' }],
            filter: null,
            filterOn: [],
            van: null,
            tot: null,
            uitgepekeldLijst: null,
            ingepekeldLijst: null,
            batchgroupgepland: null,
            bakgroep: null,
            detailsVisible: false,
            showBrineIngredients: false,
            gesloten: false,
            selectedProductID: null,
            selectedAmount: 1,
            aangeraakt_teller: 1,
            selectDays: null,
            periodeDagen: 1,
            displayFilterCount: 10,
            tedoen: 'all'
        };
    },
    components: {
        OutbrineCard,
        OutbrinePopForm,
        PeriodSelector,
        BrineIngredientsModal
    },
    computed: {
        strNoData() {
            return Helper.stringformat(
                this.$t('prod_nomaintenancedata', {
                    ns: 'production'
                }),
                this.van,
                this.tot
            );
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: this.isDagInfo },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                {
                    key: 'productieDatum',
                    label: this.$t('com_datum', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                { key: 'bakno', label: 'Bak#', sortable: true, visible: true },
                {
                    key: 'bakgroepInternID',
                    label: 'BG#',
                    sortable: true,
                    visible: true
                },
                {
                    key: 'kaasnaam',
                    label: this.$t('com_kaassoort', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'uitgepekeld',
                    label: this.$t('com_klaar', { ns: 'common' }),
                    visible: true
                },
                {
                    key: 'aantal',
                    label: this.$t('com_number', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'vorm',
                    label: this.$t('prod_vorm', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'inpekeltijd',
                    label: this.$t('prod_inpekel', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'kooilaag',
                    label: this.$t('prod_kooi', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'urenInPekel',
                    label: this.$t('prod_uurinpekel', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'uitpekel',
                    label: this.$t('prod_uitpekel', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'stocklocationNaam',
                    label: this.$t('com_location', { ns: 'common' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        displayList() {
            // eslint-disable-next-line no-unused-vars
            const veranderd = this.aangeraakt_teller;
            let templist =
                this.van == this.tot
                    ? this.$store.getters['outbrine/getOutbrineInfoLijst']
                    : this.$store.getters['outbrine/getOutbrineInfoHistLijst'];
            if (this.tedoen == 'onlyToDo') {
                templist = templist.filter((x) => x.uitgepekeld == false);
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.totalRows = templist ? templist.length : 0;
            return templist;
        },
        showFilter() {
            return this.displayList
                ? this.displayList.length > this.displayFilterCount
                : false;
        },
        isDagInfo() {
            return this.van == this.tot;
            //return this.van.toDateString() == this.tot.toDateString();
        },
        allowEdit() {
            // todo: wat worden de regels hiervoor?
            return this.isDagInfo;
        }
    },
    methods: {
        reset() {
            this.uitgepekeldLijst = [];
            this.detailsVisible = false;
            this.showBrineIngredients = false;
        },
        async getOutbrineAmountsItem(item) {
            this.bakgroep = item.bakgroepInternID;
            await loadItem(
                'outbrine/loadOutbrineAmountsforBG',
                {
                    batchgroup: item.bakgroepInternID
                },
                this.$t
            );
            return this.$store.getters['outbrine/getOutbrineForBGLijst'];
        },
        async getInbrineAmountsItem(item) {
            await loadItem(
                'outbrine/loadInbrineAmountsforBG',
                {
                    batchgroup: item.bakgroepInternID
                },
                this.$t
            );
            return this.$store.getters['outbrine/getInbrineForBGLijst'];
        },
        async getTimesForBatchgroup(item) {
            await loadItem(
                'outbrine/loadBGOutbrineInfo',
                {
                    batchgroup: item.bakgroepInternID
                },
                this.$t
            );
            return this.$store.getters['outbrine/getOutbrineInfoBGLijst'];
        },
        async outbrineItem(item) {
            this.uitgepekeldLijst = await this.getOutbrineAmountsItem(item);
            this.ingepekeldLijst = await this.getInbrineAmountsItem(item);
            this.batchgroupgepland = await this.getTimesForBatchgroup(item);
            await loadItem('components/loadShapesComboLijst', null, this.$t);
            await loadItem('components/loadCheesesComboLijst', null, this.$t);
            this.detailsVisible = true;
        },
        async toonDetail(row) {
            const refreshedItem = await this.getOutbrineAmountsItem(row);
            if (row._showDetails) {
                this.$set(row, '_showDetails', false);
            } else {
                this.displayList.forEach((row) => {
                    this.$set(row, '_showDetails', false);
                });

                this.uitgepekeldLijst = refreshedItem;
                this.aangeraakt_teller += 1;
                this.$set(row, '_showDetails', true);
            }
        },
        addBrineIngredients() {
            this.showBrineIngredients = !this.showBrineIngredients;
        },
        setNewDates(selDates) {
            this.van = selDates.van;
            this.tot = selDates.tot;
            if (this.isDagInfo) this.loadData();
        },
        loadData() {
            if (this.isDagInfo) {
                const selectionData = {
                    outBrineDate: DateHelper.localDateToUTC(this.van)
                };
                loadItem('outbrine/loadOutbrineInfo', selectionData, this.$t);
            } else {
                const selectionData = {
                    from: DateHelper.localDateToUTC(this.van),
                    until: DateHelper.localDateToUTC(this.tot)
                };
                loadItem(
                    'outbrine/loadOutbrineInfoOverPeriod',
                    selectionData,
                    this.$t
                );
            }
            this.aangeraakt_teller += 1;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        },
        showBerichten() {
            const meldingen =
                this.$store.getters['masterdata/getBerichtenLijst'];
            if (meldingen.length > 0) {
                Helper.showBerichten(meldingen, this.$t, this.$bvToast);
            }
        }
    },
    mounted: function () {
        this.loadData();
        this.totalRows = this.displayList ? this.displayList.length : 0;
    },
    created() {
        this.showBerichten();
    }
};
</script>

<style scoped></style>

