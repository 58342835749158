<template>
    <b-container fluid style="margin-top: 60px">
        <h1>{{ title }}</h1>
        <b-card>
            <b-tabs card>
                <b-tab
                    :title="$t('com_product', { ns: 'common' })"
                    v-bind:key="1"
                    @click="loadData(PROD)"
                    active
                >
                </b-tab>
                <b-tab @click="loadData(STOCK)" lazy>
                    <template #title>
                        <b-spinner
                            v-if="showSpinner"
                            type="border"
                            small
                        ></b-spinner>
                        {{ $t('com_stock', { ns: 'common' }) }}
                    </template>
                </b-tab>
            </b-tabs>
            <div>
                <div v-if="showFilter">
                    <b-row no-gutters>
                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="
                                    $t('com_filter', {
                                        ns: 'common'
                                    })
                                "
                                label-for="filter-input"
                                label-cols-sm="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-input-group size="sm">
                                    <b-form-input
                                        id="filter-input"
                                        v-model="filter"
                                        type="search"
                                        :placeholder="
                                            $t('com_typetosearch', {
                                                ns: 'common'
                                            })
                                        "
                                    ></b-form-input>

                                    <b-input-group-append>
                                        <b-button
                                            :disabled="!filter"
                                            @click="filter = ''"
                                            >{{
                                                $t('com_wis', {
                                                    ns: 'common'
                                                })
                                            }}</b-button
                                        >
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>

                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="
                                    $t('com_perpagina', {
                                        ns: 'common'
                                    })
                                "
                                label-for="per-page-select"
                                label-cols-sm="6"
                                label-cols-md="4"
                                label-cols-lg="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-form-select
                                    id="per-page-select"
                                    v-model="perPage"
                                    :options="pageOptions"
                                    size="sm"
                                ></b-form-select>
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                ></b-pagination>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <b-table
                    striped
                    :items="reportList"
                    :fields="tableFields"
                    primary-key
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    stacked="md"
                    show-empty
                    :empty-text="$t('com_geen_data', { ns: 'common' })"
                    small
                    @filtered="onFiltered"
                >
                </b-table>
                <div v-if="taskCount === 0">
                    {{ $t('com_geen_data', { ns: 'common' }) }}
                </div>
            </div>
        </b-card>
    </b-container>
</template>

<script>
import Helper from '@/models/Helper';
import { loadList } from '@/models/DataHelper';
import DateHelper from '@/models/DateHelper';

export default {
    name: 'ExternalLinksErrors',
    data: function () {
        return {
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [10, 20, 40, { value: 100, text: '100' }],
            filter: null,
            filterOn: [],
            tableFields: [],
            list: [],
            showSpinner: false,
            report: '/Reporting/ExternalProductErrorReport',
            displayFilterCount:20,
            PROD: 'product',
            STOCK: 'voorraad'
        };
    },
    props: {},
    components: {},

    computed: {
        reportList() {
            return this.list;
        },
        showFilter() {
            return this.reportList
                ? this.reportList.length > this.displayFilterCount
                : false;
        },
        title() {
            return this.$t('com_errorladen', {
                ns: 'common',
                val: this.$t('com_extkoppelingen', {
                    ns: 'common'
                }).toLowerCase()
            });
        }
    },
    methods: {
        taskCount() {
            let rijen = 0;
            if (this.reportList != undefined) {
                rijen = this.reportList.length;
            }
            return rijen;
        },
        async loadData(item) {
            let report = '';
            this.showSpinner = true;
            switch (item) {
                case this.PROD:
                    report = '/Reporting/ExternalProductErrorReport';
                    break;
                case this.STOCK:
                    report = '/Reporting/ExternalStockItemErrorReport';
                    break;
                default:
                    report = '/Reporting/ExternalProductErrorReport';
            }
            const payload = {
                data: null,
                report: report
            };
            this.list = await loadList(
                'rapportage/getReportItems',
                'rapportage/loadReport',
                payload,
                true,
                this.$t
            );
            this.list = Helper.removeProperty(this.list, 'id');
            this.list = DateHelper.convertDateInListToDateTimeString(
                this.list,
                true
            );
            this.list = Helper.replaceValueInObject(this.list, null, '');
            this.tableFields = Helper.giveHeaderFields(this.list, this.$t);
            this.totalRows = this.list ? this.list.length : 0;
            this.showSpinner = false;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        }
    },
    mounted() {
        this.loadData(this.PROD);
    }
};
</script>

<style scoped></style>
