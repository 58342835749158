<template>
    <div>
        <b-col :sm="setSize" class="mx-0 px-0 my-0 py-0">
            <b-input-group :append="grCelcius">
                <b-form-input
                    id="input-past"
                    :placeholder="
                        $t('com_temperatuur', {
                            ns: 'common'
                        })
                    "
                    type="number"
                    step="0.1"
                    v-model.number="displayC"
                    @change="set_inputTemperatuurC"
                    :disabled="!editAllowed"
                    :state="validTemperatuurC"
                    :formatter="numberFormat"
                ></b-form-input>
            </b-input-group>
        </b-col>
        <b-col v-if="showFahrenheit" :sm="setSize" class="mx-0 px-0 my-0 py-0">
            <b-input-group :append="grFahrenheit">
                <b-form-input
                    id="input-past"
                    :placeholder="
                        $t('com_temperatuur', {
                            ns: 'common'
                        })
                    "
                    type="number"
                    step="0.1"
                    v-model.number="displayF"
                    @change="set_inputTemperatuurF"
                    :disabled="!editAllowed"
                    :state="validTemperatuurF"
                    :formatter="numberFormat"
                ></b-form-input>
            </b-input-group>
        </b-col>
    </div>
</template>
<script>
import Helper from '../../models/Helper';

export default {
    name: 'TemperatureInput',
    data: function () {
        return {
            grCelcius: window.constants.GRADEN_C,
            grFahrenheit: window.constants.GRADEN_F,
            tempC: null,
            tempF: null,
            displayC: null,
            displayF: null
        };
    },
    model: {
        prop: 'selected',
        event: 'input'
    },
    props: {
        selected: {
            type: Number,
            required: false
        },
        minvalue: {
            type: Number,
            required: false
        },
        maxvalue: {
            type: Number,
            required: false
        },
        editAllowed: {
            type: Boolean,
            default: false
        },
        showFahrenheit: {
            type: Boolean,
            default: false
        },
        placeholderC: {
            type: String
        }
    },
    watch: {
        showFahrenheit() {
            this.setTemperatures();
        },
        selected() {
            this.setTemperatures();
        }
    },
    components: {},
    computed: {
        setSize() {
            return this.showFahrenheit ? 6 : 12;
        },
        validTemperatuurC() {
            return this.tempC && this.minvalue && this.maxvalue
                ? this.tempC > this.minvalue && this.tempC < this.maxvalue
                : true;
        },
        validTemperatuurF() {
            return this.tempF && this.minvalue && this.maxvalue
                ? this.tempF > Helper.CelsiusToFahrenheit(this.minvalue) &&
                      this.tempF < Helper.CelsiusToFahrenheit(this.maxvalue)
                : true;
        }
    },
    methods: {
        numberFormat(value) {
            return Helper.Round(value, 1);
        },
        set_input() {
            const valid = this.validTemperatuurC;
            this.$emit('change', true);
            this.$emit('valid', valid);
            this.$emit('input', this.tempC);
        },
        set_inputTemperatuurC(val) {
            this.tempC = val ? val : null;
            if (this.showFahrenheit) {
                this.tempF = Helper.CelsiusToFahrenheit(val);
                this.displayF = Helper.Round(this.tempF,1);
            }
            this.set_input();
        },
        set_inputTemperatuurF(val) {
            this.displayF = val;
            if (this.showFahrenheit) {
                this.tempC = Helper.FahrenheitToCelsius(val);
                this.displayC = Helper.Round(this.tempC,1);
            }
            this.set_input();
        },
        setTemperatures() {
            this.tempC = this.selected;
            this.displayC = Helper.Round(this.tempC,1);
            if (this.showFahrenheit) {
                this.tempF = Helper.CelsiusToFahrenheit(this.tempC);
                this.displayF = Helper.Round(this.tempF,1);
            }
        }
    },
    mounted() {
        this.setTemperatures();
    },
    created() {}
};
</script>

<style scoped></style>

