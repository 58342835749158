<template>
    <b-card>
        <b-row class="mx-0 px-0 my-1 py-0 form-inline">
            {{ strOccurs1 }} &nbsp;
            <b-form-input
                id="input-interval"
                type="number"
                step="1"
                v-model.number="localInterval"
                style="width: 25%"
                @change="setChanged"
                :disabled="!editAllowed"
            ></b-form-input>
            &nbsp;{{ strOccurs2 }}
        </b-row>
        <b-row class="mx-0 px-0 my-1 py-0">
            <b-form-group>
                <b-form-checkbox-group
                    id="cbgWeeks"
                    v-model="localdagen"
                    :options="locallijstDagen"
                    @change="setChanged"
                    :disabled="!editAllowed"
                ></b-form-checkbox-group>
            </b-form-group>
        </b-row>
    </b-card>
</template>

<script>
import { recurrencyHelper, RECURRENCY_ENUM } from '../../models/Recurrency';
export default {
    name: 'CardWeekly',
    data() {
        return {
            localdagen: [],
            localInterval: 1,
            strOccurs1: this.$t('rec_occursevery', { ns: 'recurrency' }),
            strOccurs2: this.$t('rec_occurs2week', { ns: 'recurrency' })
        };
    },
    components: {},
    props: {
        dagen: {
            type: Array,
            default() {
                return [];
            }
        },
        interval: {
            type: Number,
            default: 1
        },
        editAllowed: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        locallijstDagen() {
            return recurrencyHelper.lijstDagen(this.$t);
        }
    },
    methods: {
        setChanged() {
            const o = {
                type: RECURRENCY_ENUM.Weekly,
                interval: this.localInterval,
                dagen: this.localdagen
            };
            this.$emit('changed', o);
        }
    },
    mounted() {},
    async created() {
        this.localdagen = this.dagen;
        this.localInterval = this.interval;
    }
};
</script>

