<template>
    <b-navbar
        class="b-navbar"
        type="dark"
        variant="primary"
        toggleable="lg"
        fixed="top"
    >
        <b-navbar-brand @click="showVersion()" href="#">{{
            appTitle
        }}</b-navbar-brand>
        <b-toast
            id="versiontoast"
            variant="info"
            solid
            toaster="b-toaster-top-left"
            :title="$t('prod_version', { ns: 'production' })"
        >
            <pre>
                {{ versieSoftware }} 
                {{ versieDatabase }}
                {{ versieDataGebruik }}
            </pre>
            <hr />
            <a :href="`mailto:${theEmail}`"
                ><font-awesome-icon icon="envelope" size="1x" /> Mail support</a
            >
            <br />
            <a v-if="!isVersion10" href="tel:+31-885585223"
                ><font-awesome-icon icon="phone" size="1x" />
                {{ $t('nav_belSupport', { ns: 'nav' }) }} (088 5585223)</a
            >
        </b-toast>

        <b-navbar-toggle
            v-if="isLoggedIn"
            target="navDropdownCollapse"
        ></b-navbar-toggle>
        <b-collapse v-if="isLoggedIn" id="navDropdownCollapse" is-nav>
            <b-navbar-nav>
                <b-nav-item :to="{ name: 'dashboard' }" v-if="!isVersion10"
                    >Dashboard</b-nav-item
                >
                <b-nav-item-dropdown
                    :text="$t('nav_rapportage', { ns: 'nav' })"
                >
                    <b-dropdown-item
                        v-if="!isVersion10"
                        :to="{ name: 'rapitemdag' }"
                        >{{
                            $t('nav_rapitemdag', { ns: 'nav' })
                        }}</b-dropdown-item
                    >
                    <b-dropdown-item
                        v-if="!isVersion10"
                        :to="{ name: 'rapitemalgemeen' }"
                        >{{
                            $t('nav_rapitemalgemeen', { ns: 'nav' })
                        }}</b-dropdown-item
                    >
                    <b-dropdown-item :to="{ name: 'rapitemperiode' }">{{
                        $t('nav_rapitemperiode', { ns: 'nav' })
                    }}</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'rapitemproduction' }">{{
                        $t('nav_rapitemproduction', { ns: 'nav' })
                    }}</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'rapitemtracktrace' }">{{
                        $t('nav_rapitemtracktrace', { ns: 'nav' })
                    }}</b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item-dropdown
                    :text="$t('nav_productie', { ns: 'nav' })"
                    v-if="!isVersion10"
                >
                    <b-dropdown-item :to="{ name: 'productie' }">{{
                        $t('nav_productie', { ns: 'nav' })
                    }}</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'uitpekelen' }">{{
                        $t('nav_uitpekelen', { ns: 'nav' })
                    }}</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'recepten' }">{{
                        $t('nav_recepten', { ns: 'nav' })
                    }}</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'reinigen' }">{{
                        $t('nav_reinigen', { ns: 'nav' })
                    }}</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'voorraad' }">{{
                        $t('nav_voorraad', { ns: 'nav' })
                    }}</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'voorraadmerken' }">{{
                        $t('nav_voorraadmerken', { ns: 'nav' })
                    }}</b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item-dropdown
                    :text="$t('nav_behandeling', { ns: 'nav' })"
                    v-if="isVersion30"
                >
                    <b-dropdown-item :to="{ name: 'geplandbehandel' }">{{
                        $t('nav_geplandbehandel', { ns: 'nav' })
                    }}</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'behandelstatus' }">{{
                        $t('nav_behandelstatus', { ns: 'nav' })
                    }}</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'behandelschemas' }">{{
                        $t('nav_behandelschemas', { ns: 'nav' })
                    }}</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'behandelingen' }">{{
                        $t('nav_behandelingen', { ns: 'nav' })
                    }}</b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item-dropdown
                    :text="$t('nav_logistiek', { ns: 'nav' })"
                    v-if="isVersion30"
                >
                    <b-dropdown-item :to="{ name: 'uitleveren' }">{{
                        $t('nav_uitleveren', { ns: 'nav' })
                    }}</b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item-dropdown
                    :text="$t('nav_onderzoek', { ns: 'nav' })"
                    v-if="isVersion30"
                >
                    <b-dropdown-item :to="{ name: 'resultaten' }">{{
                        $t('nav_resultaten', { ns: 'nav' })
                    }}</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'monsters' }">{{
                        $t('nav_monsters', { ns: 'nav' })
                    }}</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'onderwerpen' }">{{
                        $t('nav_onderwerpen', { ns: 'nav' })
                    }}</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'uitvoerders' }">{{
                        $t('nav_uitvoerders', { ns: 'nav' })
                    }}</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'metingen' }">{{
                        $t('nav_metingen', { ns: 'nav' })
                    }}</b-dropdown-item>
                </b-nav-item-dropdown>

                <b-nav-item-dropdown
                    :text="$t('nav_10plus', { ns: 'nav' })"
                    v-if="isVersion10 || useVersion10"
                >
                    <b-dropdown-item :to="{ name: '10product' }">{{
                        $t('nav_10plusproduct', { ns: 'nav' })
                    }}</b-dropdown-item>
                    <b-dropdown-item :to="{ name: '10productvalue' }">{{
                        $t('nav_10productvalue', { ns: 'nav' })
                    }}</b-dropdown-item>
                    <b-dropdown-item :to="{ name: '10recept' }">{{
                        $t('nav_10plusrecept', { ns: 'nav' })
                    }}</b-dropdown-item>
                    <b-dropdown-item :to="{ name: '10productie' }">{{
                        $t('nav_10plusproductie', { ns: 'nav' })
                    }}</b-dropdown-item>
                    <b-dropdown-item
                        v-if="isVersion10"
                        :to="{ name: '10voorraad' }"
                        >{{
                            $t('nav_10plusvoorraad', { ns: 'nav' })
                        }}</b-dropdown-item
                    >
                </b-nav-item-dropdown>

                <b-nav-item-dropdown
                    :text="$t('nav_producten', { ns: 'nav' })"
                    v-if="!isVersion10"
                >
                    <b-dropdown-item
                        :to="{ name: 'productinfobase', params: { cat: Kaas } }"
                        >{{
                            $t('nav_productKaas', { ns: 'nav' })
                        }}</b-dropdown-item
                    >
                    <b-dropdown-item
                        :to="{
                            name: 'productinfobase',
                            params: { cat: Kaasmerk }
                        }"
                        >{{
                            $t('nav_productKaasmerken', { ns: 'nav' })
                        }}</b-dropdown-item
                    >
                    <b-dropdown-item
                        :to="{
                            name: 'productinfobase',
                            params: { cat: Kaasdekmiddel }
                        }"
                        >{{
                            $t('nav_productKaasdekmiddel', { ns: 'nav' })
                        }}</b-dropdown-item
                    >
                    <b-dropdown-item
                        :to="{
                            name: 'productinfobase',
                            params: { cat: Onvolwaardig }
                        }"
                        >{{
                            $t('nav_productOnvolwaardig', { ns: 'nav' })
                        }}</b-dropdown-item
                    >
                    <b-dropdown-item
                        :to="{ name: 'productinfobase', params: { cat: Wei } }"
                        >{{
                            $t('nav_productWei', { ns: 'nav' })
                        }}</b-dropdown-item
                    >
                    <b-dropdown-item
                        :to="{
                            name: 'productinfobase',
                            params: { cat: Overige }
                        }"
                        >{{
                            $t('nav_productOverige', { ns: 'nav' })
                        }}</b-dropdown-item
                    >
                    <b-dropdown-item
                        :to="{
                            name: 'ingredientinfobase',
                            params: { ingcat: Melk }
                        }"
                        >{{
                            $t('nav_ingredientMelk', { ns: 'nav' })
                        }}</b-dropdown-item
                    >
                    <b-dropdown-item
                        :to="{
                            name: 'ingredientinfobase',
                            params: { ingcat: Zuursel }
                        }"
                        >{{
                            $t('nav_ingredientZuursel', { ns: 'nav' })
                        }}</b-dropdown-item
                    >
                    <b-dropdown-item
                        :to="{
                            name: 'ingredientinfobase',
                            params: { ingcat: Stremsel }
                        }"
                        >{{
                            $t('nav_ingredientStremsel', { ns: 'nav' })
                        }}</b-dropdown-item
                    >
                    <b-dropdown-item
                        v-if="showIngredient(Kleursel)"
                        :to="{
                            name: 'ingredientinfobase',
                            params: { ingcat: Kleursel }
                        }"
                        >{{
                            $t('nav_ingredientKleursel', { ns: 'nav' })
                        }}</b-dropdown-item
                    >
                    <b-dropdown-item
                        v-if="showIngredient(Conserveermiddel)"
                        :to="{
                            name: 'ingredientinfobase',
                            params: { ingcat: Conserveermiddel }
                        }"
                        >{{
                            $t('nav_ingredientConserveermiddel', { ns: 'nav' })
                        }}</b-dropdown-item
                    >
                    <b-dropdown-item
                        v-if="showIngredient(Kruiden)"
                        :to="{
                            name: 'ingredientinfobase',
                            params: { ingcat: Kruiden, func: $t }
                        }"
                        >{{
                            $t('nav_ingredientKruiden', { ns: 'nav' })
                        }}</b-dropdown-item
                    >
                    <b-dropdown-item
                        v-if="showIngredient(Calcium)"
                        :to="{
                            name: 'ingredientinfobase',
                            params: { ingcat: Calcium }
                        }"
                        >{{
                            $t('nav_ingredientCalcium', { ns: 'nav' })
                        }}</b-dropdown-item
                    >
                    <b-dropdown-item
                        v-if="showIngredient(Aromas)"
                        :to="{
                            name: 'ingredientinfobase',
                            params: { ingcat: Aromas }
                        }"
                        >{{
                            $t('nav_ingredientAromas', { ns: 'nav' })
                        }}</b-dropdown-item
                    >
                    <b-dropdown-item
                        v-if="showIngredient(Zout)"
                        :to="{
                            name: 'ingredientinfobase',
                            params: { ingcat: Zout }
                        }"
                        >{{
                            $t('nav_ingredientZout', { ns: 'nav' })
                        }}</b-dropdown-item
                    >
                </b-nav-item-dropdown>
                <b-nav-item-dropdown
                    v-if="useExternals"
                    :text="$t('nav_external', { ns: 'nav' })"
                >
                    <b-dropdown-item :to="{ name: 'externaldevice' }">{{
                        $t('nav_registraties', { ns: 'nav' })
                    }}</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'externallink' }">{{
                        $t('nav_koppelingen', { ns: 'nav' })
                    }}</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'externalerror' }">{{
                        $t('nav_errors', { ns: 'nav' })
                    }}</b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item-dropdown
                    :text="$t('nav_stamgegevens', { ns: 'nav' })"
                >
                    <b-dropdown-item
                        :to="{
                            name: 'masterdatabase',
                            params: { typeBase: Company }
                        }"
                        >{{
                            $t('nav_masterCompany', { ns: 'nav' })
                        }}</b-dropdown-item
                    >
                    <b-dropdown-item
                        :to="{
                            name: 'masterdatabase',
                            params: { typeBase: Vorm }
                        }"
                        >{{
                            $t('nav_masterVorm', { ns: 'nav' })
                        }}</b-dropdown-item
                    >
                    <b-dropdown-item
                        v-if="!isVersion10"
                        :to="{
                            name: 'masterdatabase',
                            params: { typeBase: Locatie }
                        }"
                        >{{
                            $t('nav_masterLocatie', { ns: 'nav' })
                        }}</b-dropdown-item
                    >
                    <b-dropdown-item
                        v-if="!isVersion10"
                        :to="{
                            name: 'masterdatabase',
                            params: { typeBase: Equipment }
                        }"
                        >{{
                            $t('nav_masterEquipment', { ns: 'nav' })
                        }}</b-dropdown-item
                    >
                    <b-dropdown-item
                        v-if="!isVersion10"
                        :to="{
                            name: 'masterdatabase',
                            params: { typeBase: Ost }
                        }"
                        >{{
                            $t('nav_masterOst', { ns: 'nav' })
                        }}</b-dropdown-item
                    >
                    <b-dropdown-item
                        v-if="isVersion30 && false"
                        :to="{
                            name: 'masterdatabase',
                            params: { typeBase: Reporting }
                        }"
                        >{{
                            $t('nav_masterreporting', { ns: 'nav' })
                        }}</b-dropdown-item
                    >
                </b-nav-item-dropdown>

                <b-nav-item-dropdown right>
                    <!-- Using 'button-content' slot -->
                    <template #button-content>
                        <em>
                            {{
                                $t('nav_loggedinUser', {
                                    ns: 'nav',
                                    val: username
                                })
                            }}
                        </em>
                    </template>
                    <b-dropdown-item :to="{ name: 'profiel' }">{{
                        $t('nav_baseProfiel', { ns: 'nav' })
                    }}</b-dropdown-item>
                    <b-dropdown-item
                        v-if="!isVersion10"
                        :to="{ name: 'herstel' }"
                        >{{
                            $t('nav_baseHerstel', { ns: 'nav' })
                        }}</b-dropdown-item
                    >
                    <b-dropdown-item @click="showChangePass()">{{
                        $t('nav_baseChangepass', { ns: 'nav' })
                    }}</b-dropdown-item>
                    <hr />
                    <b-dropdown-item
                        v-if="isAdmin && !isVersion10"
                        @click="showResetPass()"
                        >{{
                            $t('nav_baseResetpass', { ns: 'nav' })
                        }}</b-dropdown-item
                    >
                    <b-dropdown-item v-on:click="handleLogOut">{{
                        $t('nav_baseLogout', { ns: 'nav' })
                    }}</b-dropdown-item>
                </b-nav-item-dropdown>
                <div v-if="languages">
                    <b-nav-item-dropdown right>
                        <template #button-content>
                            <span :class="selectedLanguageCSS"></span>
                        </template>
                        <span v-for="lng in Object.keys(languages)" :key="lng">
                            <b-dropdown-item
                                v-if="$i18next.resolvedLanguage !== lng"
                                v-on:click="$i18next.changeLanguage(lng)"
                                >{{
                                    languages[lng].nativeName
                                }}</b-dropdown-item
                            >
                        </span>
                    </b-nav-item-dropdown>
                </div>
            </b-navbar-nav>
        </b-collapse>
        <recurring-events-button
            class="float-right"
            v-if="isLoggedIn && !isVersion10"
            @show="showReccurencyEvents"
        />
        <reset-pass-modal
            v-if="resetVisible"
            v-model="resetVisible"
            @hide="reset"
        />
        <change-pass-modal
            v-if="changeVisible"
            v-model="changeVisible"
            @hide="reset"
        />
        <recurrency-pop-form
            v-if="recurrencyVisible"
            v-model="recurrencyVisible"
            :lijst = lijst
            @hide="reset"
        />
    </b-navbar>
</template>

<script>
import { PRODCAT_ENUM } from '../../models/ProductCategorie';
import { INGRCAT_ENUM } from '../../models/IngredientCategorie';
import Helper from '../../models/Helper';
import { loadItem, loadList } from '../../models/DataHelper';
import { Feature } from '../../models/Features';
import { ResetPassModal, ChangePassModal } from '../gebruiker';
import { RecurringEventsButton, RecurrencyPopForm } from '../recurrency';
import '/node_modules/flag-icons/css/flag-icons.min.css';
export default {
    name: 'Navigation',
    data: function () {
        return {
            Kaas: PRODCAT_ENUM.Kaas,
            Ingrediënt: PRODCAT_ENUM.Ingrediënt,
            Kaasmerk: PRODCAT_ENUM.Kaasmerk,
            Kaasdekmiddel: PRODCAT_ENUM.Kaasdekmiddel,
            Wei: PRODCAT_ENUM.Wei,
            Onvolwaardig: PRODCAT_ENUM.Onvolwaardig,
            Overige: PRODCAT_ENUM.Overige,

            Melk: INGRCAT_ENUM.Melk,
            Zuursel: INGRCAT_ENUM.Zuursel,
            Stremsel: INGRCAT_ENUM.Stremsel,
            Kleursel: INGRCAT_ENUM.Kleursel,
            Conserveermiddel: INGRCAT_ENUM.Conserveermiddel,
            Calcium: INGRCAT_ENUM.Calcium,
            Zout: INGRCAT_ENUM.Zout,
            Kruiden: INGRCAT_ENUM.Kruiden,
            Aromas: INGRCAT_ENUM.Aromas,
            Vetten: INGRCAT_ENUM.Vetten,
            Grootzuursel: INGRCAT_ENUM.Grootzuursel,

            Vorm: window.constants.MDATA_VORM,
            Ost: window.constants.MDATA_OST,
            Locatie: window.constants.MDATA_LOCATION,
            Company: window.constants.MDATA_COMPANY,
            Equipment: window.constants.MDATA_EQUIPMENT,
            Reporting: window.constants.MDATA_REPORTGROUP,

            versieSoftware: 'regel 1',
            versieDatabase: 'regel 2',
            versieDataGebruik: 'regel 3',

            resetVisible: false,
            recurrencyVisible: false,
            changeVisible: false,
            theEmail: '',
            lijst: [],

            languages: {
                en: { nativeName: 'English' },
                es: { nativeName: 'Español' },
                de: { nativeName: 'Deutsch' },
                nl: { nativeName: 'Nederlands' }
            }
        };
    },
    components: {
        ResetPassModal,
        ChangePassModal,
        RecurringEventsButton,
        RecurrencyPopForm
    },
    computed: {
        selectedLanguage() {
            return this.$i18next.resolvedLanguage;
        },
        selectedLanguageCSS() {
            const lang =
                this.$i18next.resolvedLanguage == 'en'
                    ? 'gb'
                    : this.$i18next.resolvedLanguage;
            return ' fi fi-' + lang;
        },
        isLoggedIn: function () {
            const loggedIn =
                this.$store.getters['account/hasValidatedJWTToken'];
            return loggedIn;
        },
        username: function () {
            const operator = this.$store.getters['account/getLoggedInUser'];
            const naam = operator ? operator.naam : 'Piet';
            return naam;
        },
        isAdmin: function () {
            const operator = this.$store.getters['account/getLoggedInUser'];
            const res = operator ? operator.isAdmin : false;
            return res;
        },
        appTitle: function () {
            const version = this.$store.getters['masterdata/getPackageVersion'];
            const naam = Helper.stringformat('CHEESEIT {0}+', version ?? '-');
            return naam;
        },
        isVersion30() {
            return (
                this.$store.getters['masterdata/getPackageVersion'] >=
                window.constants.VERSION30
            );
        },
        isVersion10() {
            return (
                this.$store.getters['masterdata/getPackageVersion'] ==
                window.constants.VERSION10
            );
        },
        useVersion10() {
            return this.checkFeature('conf_CH10Enabled');
        },
        useExternals() {
            const useExternals = this.checkFeature(
                'conf_useExternalConnections'
            );
            return useExternals;
        }
    },
    methods: {
        reset() {
            this.resetVisible = false;
            this.recurrencyVisible = false;
            this.changeVisible = false;
        },
        showResetPass() {
            this.resetVisible = true;
        },
        showChangePass() {
            this.changeVisible = true;
        },
        showReccurencyEvents(lijst) {
            this.lijst = lijst ; 
            this.recurrencyVisible = true;
        },
        async showVersion() {
            // haal versie
            await loadItem('masterdata/loadVersionInfo', null, this.$t);
            const versieInfo = this.$store.getters['masterdata/getVersionInfo'];
            this.versieSoftware = versieInfo.git
                ? `Software: ${versieInfo.tag} (${versieInfo.git}).`
                : `Software: ${versieInfo.tag}.`;
            this.versieDatabase = `Database: ${versieInfo.database}.`;

            const gebruikInfo = await loadItem(
                'masterdata/loadGebruikInfo',
                null,
                this.$t
            );
            this.versieDataGebruik = `Attachments: files-> ${gebruikInfo.files}, (${gebruikInfo.data} mB).`;

            const adres = 'cheeseit_support@auxzenze.nl';
            const subject = Helper.stringformat(
                '[SUPPORT {0}]: {1} {2}',
                this.appTitle,
                this.versieSoftware,
                this.versieDatabase
            );
            // todo werkt niet mooi met de nieuwe regel
            const body = encodeURIComponent(
                subject +
                    '\r\n' +
                    Helper.stringformat(
                        '[ATTACHMENTS]: {0} files ({1})',
                        gebruikInfo.files,
                        gebruikInfo.data
                    )
            );
            this.theEmail = Helper.stringformat(
                '{0}?subject={1}&body={2}',
                adres,
                subject,
                body
            );

            this.$bvToast.show('versiontoast');
        },
        handleLogOut() {
            this.$store.commit('account/setJWTToken', null);
            const manager = this.$store.getters['account/getLogInManager'];
            if (manager != undefined) manager.signOut();
            loadItem('account/loadUserManager', null);
        },
        checkRight(recht) {
            this.$root.handleRight(recht);
        },
        checkFeature(feature) {
            const key = Feature.enum(feature);
            return this.$root.handleFeature(key);
        },
        showIngredient(catid) {
            const ingredientCat =
                this.$store.getters['product/getIngredientCategorieByID'](
                    catid
                );
            return ingredientCat == undefined ? false : true;
        }
    }
};
</script>

<style scoped></style>

