<template>
    <div>
        <b-alert v-model="showWarningAlert" variant="warning" dismissible>
            {{ reminder }}
        </b-alert>
        <lookup-combo
            v-model="localSelected.qualityid"
            v-bind:combotype="lctypeQuality"
            @input="set_inputQ"
            :disabled="!editAllowed"
        ></lookup-combo>
        <b-form-group>
            <b-form-radio-group
                key="rerenderkey"
                v-model="localSelected.qlabelid"
                :options="selOptions"
                @change="set_input"
                :disabled="!editAllowed"
            ></b-form-radio-group>
        </b-form-group>
        <b-card no-body class="mb-1" v-if="subtypesAvailable">
            <div class="groups" v-if="editAllowed">
                <div class="group">
                    <b-card
                        :header="
                            $t('com_beschikbaar', {
                                ns: 'common',
                                val: $t('an_labels', {
                                    ns: 'analytics'
                                }).toLowerCase()
                            })
                        "
                    >
                        <Container
                            group-name="LinkedLabels"
                            :get-child-payload="getChildPayload1"
                            @drop="onDrop('availableList', $event)"
                        >
                            <Draggable
                                v-for="item in availableList"
                                :key="item.id"
                            >
                                <div class="draggable-item">
                                    {{ item.naam }}
                                </div>
                            </Draggable>
                        </Container>
                    </b-card>
                </div>
                <div class="group">
                    <b-card
                        :header="
                            $t('an_vantoepassing', {
                                ns: 'analytics'
                            })
                        "
                    >
                        <Container
                            group-name="LinkedLabels"
                            :get-child-payload="getChildPayload2"
                            @drop="onDrop('selectedList', $event)"
                        >
                            <Draggable
                                v-for="item in selectedList"
                                :key="item.id"
                            >
                                <div class="draggable-item">
                                    {{ item.naam }}
                                </div>
                            </Draggable>
                        </Container>
                    </b-card>
                </div>
            </div>
            <div v-else>
                <div v-for="item in selectedList" :key="item.id">
                    <b-form-input v-model="item.naam" readonly></b-form-input>
                </div>
            </div>
        </b-card>
    </div>
</template>
<script>
import LookupCombo from './LookupCombo.vue';
import { Container, Draggable } from 'vue-dndrop';
import { AssesmentObject } from '../../models/Anayltics';
import Helper from '../../models/Helper';
import { loadList } from './utils';
export default {
    name: 'AssesmentCombo',
    data: function () {
        return {
            lctypeQuality: window.constants.LCA_QUALITY,
            baseOptions: [
                { text: this.$t('an_fout', { ns: 'analytics' }), value: 0 },
                { text: this.$t('an_goed', { ns: 'analytics' }), value: 1 }
            ],
            specOptions: [],
            availableList: [],
            selectedList: [],
            aangeraakt: 1,
            rerenderkey: 1,
            isInList: true,
            productNaam: '',
            startObject: null,
            showWarningAlert: false,
            subtypesAvailable: false,
            baseString: '-2',
            reminder: ''
        };
    },
    model: {
        prop: 'selected',
        event: 'input'
    },
    props: {
        selected: {
            type: AssesmentObject,
            required: false
        },
        editAllowed: {
            type: Boolean,
            default: true
        }
    },
    components: {
        LookupCombo,
        Container,
        Draggable
    },
    computed: {
        localSelected() {
            return this.startObject;
        },
        existing() {
            return this.selected;
        },
        selOptions() {
            return this.specOptions.length > 0
                ? this.specOptions
                : this.baseOptions;
        }
    },
    methods: {
        applyDrag(arr, dragResult) {
            const { removedIndex, addedIndex, payload } = dragResult;
            if (removedIndex === null && addedIndex === null) return arr;

            const result = [...arr];
            let itemToAdd = payload;

            if (removedIndex !== null) {
                itemToAdd = result.splice(removedIndex, 1)[0];
            }

            if (addedIndex !== null) {
                result.splice(addedIndex, 0, itemToAdd);
            }

            return result;
        },
        onDrop(collection, dropResult) {
            this.touched = true;
            this[collection] = this.applyDrag(this[collection], dropResult);
            this.localSelected.linkedlabels = this.selectedList.map(
                (x) => x.id
            );
            this.$emit('change', true);
            this.$emit('input', this.localSelected);
        },
        getChildPayload1(index) {
            return this.availableList[index];
        },
        getChildPayload2(index) {
            return this.selectedList[index];
        },
        showReminder(tekst) {
            this.reminder = tekst;
            this.showWarningAlert = true;
        },
        set_input() {
            this.aangeraakt += 1;
            this.$emit('change', true);
            this.$emit('input', this.localSelected);
        },
        async set_inputQ() {
            this.$nextTick(async () => {
                const baseQuality = this.localSelected
                    ? this.localSelected.qualityid
                    : -1;
                this.specOptions = await this.getLabelOptions(baseQuality);
                const lijst = await loadList(
                    'analytics/getQlabelLijst',
                    'analytics/loadQlabelLijst',
                    null,
                    this.forcerefresh,
                    this.$t
                );
                let avail = lijst.filter(
                    (x) => x.qualityID == baseQuality && x.type > 0
                );
                this.subtypesAvailable = avail.length > 0;
                const sel = this.localSelected.linkedlabels;
                const listsA = Helper.fromListByListID(avail, sel);
                // haal de sel uit avail
                const lists = Helper.reduceListWithList(
                    avail,
                    listsA.resultList
                );
                avail = lists.list1;
                this.availableList = avail;
                this.selectedList = listsA.resultList;
                this.rerenderkey += 1;
            });
        },
        async getLabelOptions(id) {
            const lijst = await loadList(
                'analytics/getQlabelLijst',
                'analytics/loadQlabelLijst',
                null,
                this.forcerefresh,
                this.$t
            );
            const fil = lijst.filter(
                (x) => x.qualityID == id && (x.type == undefined || x.type == 0)
            );
            const result = [];
            for (let i = 0; i < fil.length; i++) {
                const o = { value: fil[i].id, text: fil[i].naam };
                result.push(o);
            }
            result.sort(
                (firstItem, secondItem) => firstItem.value - secondItem.value
            );
            return result;
        }
    },
    mounted() {},
    created() {
        if (this.selected) {
            this.startObject = this.selected;
            this.set_inputQ();
        } else {
            this.startObject = new AssesmentObject();
        }
    }
};
</script>

<style lang="css" scoped>
.groups {
    display: flex;
    justify-content: stretch;
}

.group {
    flex: 1;
}
</style>

