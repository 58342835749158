import Api from '../../../api';
import store from '../../../store';
//import Oidc from 'oidc-client';

/**
 * Attempt a login action
 *
 * @param data Object with the following data { username, password, organisation,  grant_type }
 * @return {Promise<any>}
 */
const login = async data => {
    return await Api(store).post(
        'oauth/token',
        Object.keys(data)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(data[k]))
            .join('&'),
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
    );
};

const loginOIDC = async data => {
    return fetchInfo('/account/CanLogIn', data);
};
const loginCallbackGet = async data => {
    return fetchInfo('/account/login', data);
};

const loginCallback = async data => {
    return await Api(store).post(
        'account/login',
        Object.keys(data)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(data[k]))
            .join('&'),
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
    );
};
const loginCallbackObject = async data => {
    return await Api(store).post(
        'account/login', data 
    );
};

/**
 * Fetch all relavant tasks for logged in user
 *
 * @param data Object with the following data { 
                "us_id": "1"}
 * @return {Promise<any>}  {

 */
const fetchUser = async data => {
    return fetchInfo('/account/GetUser', data);
};

const fetchUserPermissions = async data => {
    return fetchInfo('/Authorize/GetUserPermissions', data);
};

/**
 * Fetch all relavant tasks for logged in user
 *
 * @param data Object with the following data { 
                "username": "1",
                "groupname": "1",
            }
 * @return {Promise<any>}  {

 */
const fetchUserInGroups = async data => {
    return fetchInfo('/Authorize/GetUserInGroup', data);
};
const fetchPermissions = async data => {
    return fetchInfo('/Authorize/GetPermissions', data);
};
const fetchPackagePermissions = async data => {
    return fetchInfo('/Authorize/GetPackagePermissions', data);
};
const fetchGroups = async data => {
    return fetchInfo('/Authorize/GetUserGroups', data);
};

const fetchGroupPermissions = async data => {
    return fetchInfo('/Authorize/GetGroupPermissions', data);
};
const fetchOperators = async data => {
    return fetchInfo('/Authorize/GetUsers', data);
};





const fetchInfo = async (apicall, data) => {
    if (data != undefined) {
        return await Api(store).get(
            apicall +
                '?' +
                Object.keys(data)
                    .map(
                        k =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    } else {
        return await Api(store).get(apicall, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    }
};


export default {
    login,
    fetchUser,
    fetchOperators,
    fetchUserPermissions,
    fetchUserInGroups,
    fetchPermissions,
    fetchPackagePermissions,
    fetchGroupPermissions,
    fetchGroups,
    loginOIDC,
    loginCallback,
    loginCallbackGet,
    loginCallbackObject
};
