<template>
    <b-card no-body :header="$t('prod_kaasmerkgeg', { ns: 'production' })">
        <div>
            <div class="form-inline">
                <b-button
                    :aria-expanded="gebruikKaasmerk ? 'true' : 'false'"
                    aria-controls="collapseMerk"
                    @click="gebruikKaasmerk = !gebruikKaasmerk"
                    :disabled="!editAllowed"
                    >{{ $t('prod_usemarks', { ns: 'production' }) }}</b-button
                >&nbsp;
                <b-form-checkbox
                    id="cbSubseries"
                    v-model="showSubseries"
                    @change="setChangedSubSeries()"
                    :disabled="!editAllowed"
                >
                    {{ $t('prod_subseries', { ns: 'production' }) }}
                </b-form-checkbox>
            </div>
            <b-collapse
                id="collapseMerk"
                v-model="gebruikKaasmerk"
                class="mt-2"
            >
                <b-card>
                    <b-row v-if="showSubseries">
                        <div
                            class="form-inline"
                            v-for="(ucm, index) in ucmList"
                            v-bind:key="index"
                        >
                            <b-col sm="12" class="mx-0 px-0 my-1 py-0">
                                <merken-combo
                                    v-bind:key="index"
                                    v-model="ucmList[index]"
                                    v-bind:editAllowed="editAllowed"
                                    v-bind:cheeseProductID="ucm.productID"
                                    @change="setChanged"
                                    @delete="verwijderItem"
                                    @copy="copyItem"
                                    @input="check_doubles"
                                    @validComponent="setMarksValid"
                                />
                            </b-col>
                        </div>
                    </b-row>

                    <b-row v-else>
                        <b-col sm="12">
                            <b-row>
                                <b-col sm="3">
                                    <label>
                                        {{
                                            $t('prod_baseamountmarks', {
                                                ns: 'production'
                                            })
                                        }}:
                                    </label>
                                </b-col>
                                <b-col sm="4"> </b-col>
                                <b-col sm="5">
                                    <b-form-input
                                        :placeholder="
                                            $t('com_number', {
                                                ns: 'common'
                                            })
                                        "
                                        type="number"
                                        step="1"
                                        min="0"
                                        max="3000"
                                        v-model.number="aantalKazen"
                                        @change="set_Eindmerk"
                                        :disabled="!editAllowed"
                                    ></b-form-input>
                                </b-col>
                                <b-col sm="3">
                                    <label
                                        >{{
                                            $t('prod_startmerk', {
                                                ns: 'production'
                                            })
                                        }}:
                                    </label>
                                </b-col>
                                <b-col sm="4">
                                    <b-form-select
                                        v-if="editKaasmerk"
                                        v-model="startStockID"
                                        :options="flexList"
                                        :disabled="!editAllowed"
                                        @change="set_inputStartLetter"
                                    >
                                        <template #first>
                                            <b-form-select-option value=""
                                                >--
                                                {{
                                                    $t('com_selecteer', {
                                                        ns: 'common'
                                                    })
                                                }}
                                                --</b-form-select-option
                                            >
                                        </template>
                                    </b-form-select>
                                    <b-form-input
                                        v-if="!editKaasmerk"
                                        placeholder="-"
                                        v-model="startLetter"
                                        :disabled="!editAllowed"
                                    ></b-form-input>
                                </b-col>
                                <b-col sm="5">
                                    <b-form-input
                                        :placeholder="
                                            $t('prod_startnummer', {
                                                ns: 'production'
                                            })
                                        "
                                        type="number"
                                        step="1"
                                        v-model.number="startNummer"
                                        @change="set_inputNummer"
                                        :disabled="!editAllowed"
                                    ></b-form-input>
                                </b-col>
                                <b-col sm="3">
                                    <label
                                        >{{
                                            $t('prod_eindmerk', {
                                                ns: 'production'
                                            })
                                        }}:
                                    </label>
                                </b-col>
                                <b-col sm="4">
                                    <b-form-select
                                        v-if="editKaasmerk"
                                        v-model="eindStockID"
                                        :options="flexList"
                                        :disabled="!editAllowed"
                                        @change="set_inputEindLetter"
                                    >
                                        <template #first>
                                            <b-form-select-option value=""
                                                >--
                                                {{
                                                    $t('com_selecteer', {
                                                        ns: 'common'
                                                    })
                                                }}
                                                --</b-form-select-option
                                            >
                                        </template>
                                    </b-form-select>
                                    <b-form-input
                                        v-if="!editKaasmerk"
                                        placeholder="-"
                                        v-model="eindLetter"
                                        :disabled="!editAllowed"
                                    ></b-form-input>
                                </b-col>
                                <b-col sm="5">
                                    <b-form-input
                                        :placeholder="
                                            $t('prod_eindnummer', {
                                                ns: 'production'
                                            })
                                        "
                                        type="number"
                                        step="1"
                                        v-model.number="eindNummer"
                                        @change="set_inputNummer"
                                        :disabled="!editAllowed"
                                    ></b-form-input>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-alert
                            v-model="showDismissibleAlert"
                            variant="danger"
                            dismissible
                        >
                            {{ alertText }}
                        </b-alert>
                        <b-alert
                            v-model="showWarningAlert"
                            variant="warning"
                            dismissible
                        >
                            {{ reminder }}
                        </b-alert>
                    </b-row>
                </b-card>
            </b-collapse>
            <b-alert v-model="showNoStockAlert" variant="danger" dismissible>
                {{ alertNoStock }}
            </b-alert>
            <b-card>
                <b-row>
                    <b-col sm="9" class="mx-0 px-0 my-1 py-0">
                        <b-row class="mx-0 px-0 my-1 py-0">
                            <b-col sm="3" class="mx-0 px-0 my-1 py-0">
                                <label
                                    >{{
                                        $t('prod_xprogramma', {
                                            ns: 'production',
                                            val: $t('prod_pers', {
                                                ns: 'production'
                                            })
                                        })
                                    }}:
                                </label>
                            </b-col>
                            <b-col sm="9" class="mx-0 px-0 my-1 py-0">
                                <b-form-input
                                    id="input-program"
                                    :placeholder="
                                        $t('prod_xprogramma', {
                                            ns: 'production',
                                            val: $t('prod_pers', {
                                                ns: 'production'
                                            })
                                        })
                                    "
                                    v-model="programma"
                                    @change="set_inputProgramma"
                                    :disabled="!editAllowed"
                                ></b-form-input>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col sm="3" class="mx-0 px-0 my-1 py-0">
                        <b-button
                            id="myDefault"
                            @click="SetDefaults()"
                            :disabled="!editAllowed"
                            class="bgc_aux_warning float-right"
                            ><font-awesome-icon icon="edit" size="1x" />&nbsp;{{
                                $t('com_default', { ns: 'common' })
                            }}
                        </b-button>
                    </b-col>
                </b-row>
            </b-card>
        </div>
    </b-card>
</template>

<script>
import { BakDrain } from '../../models/BakInfo_helper';
import { MerkenCombo } from '../components';
import { loadList, loadItem } from '../../models/DataHelper';
import { Feature } from '../../models/Features';
import { CheeseMarks } from '@/models/BakInfo';
//import Helper from '../../models/Helper';

export default {
    name: 'CardMerkPers',
    data() {
        return {
            overpomptijdstip: null,
            programma: null,
            gebruikKaasmerk: true,
            kaasmerk: null,
            aantalKazen: 0,
            aantalKazenBasis: 0,
            eindNummerBasis: null,
            startNummer: null,
            eindNummer: null,
            startStockID: null,
            eindStockID: null,
            startLetter: null,
            eindLetter: null,
            markstockList: [],
            validMarks: true,
            showDismissibleAlert: false,
            showNoStockAlert: false,
            showWarningAlert: false,
            showSubseries: false,
            alertText: '',
            reminder: '',
            aangeraakt: 1,
            alertNoStock: this.$t('prod_nostock', {
                ns: 'production',
                val: this.$t('com_marks', {
                    ns: 'common'
                })
            }),
            messageLetterBeideMerken: this.$t('prod_beideletters', {
                ns: 'production'
            }),
            messageLetterEindMerk: this.$t('prod_eindletter', {
                ns: 'production'
            }),
            messageNummerVerplicht: this.$t('prod_nummerverplicht', {
                ns: 'production'
            }),
            messageStartKleinerDanEind: this.$t('prod_startkleinereind', {
                ns: 'production'
            }),
            messageVoorstel: this.$t('prod_marksuggestion', {
                ns: 'production'
            }),
            messageUniek: this.$t('prod_warnuniekinlijst2', {
                ns: 'production'
            }),
            messageMaxSeries: this.$t('prod_max2series', { ns: 'production' })
        };
    },
    components: { MerkenCombo },
    model: {
        prop: 'theBakDrain',
        event: 'changedRecipe'
    },
    props: {
        theBakDrain: {
            type: BakDrain,
            default: null
        },
        editAllowed: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ucmList() {
            // eslint-disable-next-line no-unused-vars
            const tel = this.aangeraakt;
            return this.theBakDrain.bakKaasMerk.subSeries.filter(
                (x) =>
                    x.isDeleted == false &&
                    (x.nouse == undefined || x.nouse == false)
            );
        },
        flexList() {
            return this.markstockList.map((x) => ({
                value: x.id,
                text:
                    x.letters +
                    ' (' +
                    x.startnummer +
                    ' - ' +
                    x.eindnummer +
                    ' )',
                stockItemId: x.id,
                letter: x.letters,
                start: x.startnummer,
                eind: x.eindnummer
            }));
        },
        editKaasmerk() {
            return this.markstockList.length > 0 && this.showCombo;
        },
        showCombo() {
            let result = true;
            if (!this.theBakDrain.bakKaasMerk.suggestion) {
                const objTest = this.flexList.find(
                    (x) =>
                        x.letter == this.startLetter &&
                        x.start <= this.startNummer &&
                        x.eind > this.startNummer
                );
                result = objTest != undefined;
            }
            return result;
        },
        validMark() {
            if (!this.gebruikKaasmerk) {
                return true;
            }
            let result = true;
            if (this.startLetter == undefined) {
                result = this.eindLetter == undefined;
                if (!result) {
                    this.showAlert(this.messageLetterBeideMerken);
                    return result;
                }
            } else {
                result = this.eindLetter != undefined;
                if (!result) {
                    this.showAlert(this.messageLetterEindMerk);
                    return result;
                }
            }
            result =
                this.startNummer != undefined && this.eindNummer != undefined;
            if (!result) {
                this.showAlert(this.messageNummerVerplicht);
                return result;
            }
            if (this.startLetter == this.eindLetter) {
                result =
                    this.startNummer < this.eindNummer &&
                    this.startStockID == this.eindStockID;
                if (!result) {
                    this.showAlert(this.messageStartKleinerDanEind);
                    return result;
                }
            }
            return this.check_maxStockItemNumber();
        }
    },
    methods: {
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        showReminder(tekst) {
            this.reminder = tekst;
            this.showWarningAlert = true;
        },
        setMarksValid: function (valid) {
            this.validMarks = valid;
        },
        async verwijderItem(subserie) {
            const item = this.theBakDrain.bakKaasMerk.subSeries.find((x) => {
                return (
                    x.bakId === subserie.bakId &&
                    x.vormID === subserie.vormID &&
                    x.productID === subserie.productID &&
                    x.nouse == undefined
                );
            });
            if (item) {
                if (item.isNew) {
                    const index =
                        this.theBakDrain.bakKaasMerk.subSeries.findIndex(
                            (x) => {
                                return (
                                    x.bakId === subserie.bakId &&
                                    x.vormID === subserie.vormID &&
                                    x.productID === subserie.productID &&
                                    x.nouse == undefined
                                );
                            }
                        );
                    if (index > -1) {
                        this.theBakDrain.bakKaasMerk.subSeries.splice(index, 1);
                        this.aangeraakt += 1;
                        this.showDismissibleAlert = false;
                    }
                } else {
                    const doClose = await this.$root.showConfirmationBox(
                        this.$t('com_delete', {
                            ns: 'common',
                            val: this.$t('prod_subserie', {
                                ns: 'production'
                            })
                        }) +
                            subserie.bakId +
                            ':' +
                            subserie.vormID
                    );
                    if (doClose) {
                        item.isDeleted = true;
                        this.aangeraakt += 1;
                        this.setChanged();
                    }
                }
                this.check_doubles();
            }
        },
        check_doubles() {
            this.$nextTick(() => {
                const x = _(this.theBakDrain.bakKaasMerk.subSeries.slice(0))
                    .groupBy((x) => `"${x.vormID}+${x.productID}"`)
                    .map((value, key) => ({
                        categorie: key,
                        subserie: value
                    }))
                    .value();
                const y = x.filter((o) => o.subserie.length > 1);
                this.validMarks = !(y.length > 0);
                if (y.length > 0) {
                    this.showAlert(this.messageUniek);
                } else {
                    this.check_maxStockItemNumber();
                }
            });
        },
        check_maxStockItemNumber() {
            // maximaal 2 merkvoorraaditems in het spel zijn. 3 is errorer mogen
            const a = this.theBakDrain.bakKaasMerk.subSeries.slice(0);
            const arrStockIds = a.map((x) => x.beginStockItemID);
            const b = a.map((x) => x.eindStockItemID);
            arrStockIds.push(...b);
            arrStockIds.push(this.theBakDrain.bakKaasMerk.beginStockItemID);
            arrStockIds.push(this.theBakDrain.bakKaasMerk.eindStockItemID);
            const unique = arrStockIds.filter(
                (value, index, array) => array.indexOf(value) === index
            );
            if (unique.length > 2) {
                this.showAlert(this.messageMaxSeries);
                return false;
            }
            return true;
        },
        copyItem(merkitem) {
            const nieuw = new CheeseMarks({
                batchID: merkitem.bakId,
                productID: merkitem.productID,
                merkProductID: merkitem.merkProductID,
                markTypeID: merkitem.markTypeID
            });
            nieuw.kazen = 0;
            nieuw.vormID = merkitem.vormID;
            nieuw.beginStockItemID = merkitem.beginStockItemID;
            nieuw.eindStockItemID = merkitem.eindStockItemID;
            nieuw.startLetter = merkitem.startLetter;
            nieuw.eindLetter = merkitem.eindLetter;
            this.theBakDrain.bakKaasMerk.subSeries.push(nieuw);
            this.aangeraakt += 1;
            this.setChanged();
        },
        set_inputProgramma: function () {
            this.theBakDrain.Persprogram = this.programma;
            this.setChanged();
        },
        set_inputStartLetter() {
            const obj = this.flexList.find((x) => x.value == this.startStockID);
            this.startLetter = obj ? obj.letter : null;
            if (this.eindStockID == undefined) {
                this.eindStockID = this.startStockID;
                this.eindLetter = this.startLetter;
            }
            this.kaasmerk.beginStockItemID = this.startStockID;
            this.kaasmerk.startLetter = this.startLetter;
            this.kaasmerk.eindLetter = this.eindLetter;
            this.kaasmerk.isChanged = true;
            this.theBakDrain.bakKaasMerk = this.kaasmerk;
            this.setChanged();
        },
        set_inputEindLetter() {
            const obj = this.flexList.find((x) => x.value == this.eindStockID);
            this.eindLetter = obj ? obj.letter : null;
            this.kaasmerk.eindStockItemID = this.eindStockID;
            this.kaasmerk.eindLetter = this.eindLetter;
            this.kaasmerk.isChanged = true;
            this.theBakDrain.bakKaasMerk = this.kaasmerk;
            this.setChanged();
        },
        set_inputNummer() {
            this.kaasmerk.startNummer = this.startNummer;
            this.kaasmerk.eindNummer = this.eindNummer;
            this.kaasmerk.isChanged = true;
            this.theBakDrain.bakKaasMerk = this.kaasmerk;
            this.setChanged();
        },
        setChanged() {
            this.$emit('validComponent', this.validMark);
            this.$emit('saveSubseries', this.showSubseries);
            this.$emit('change', true);
        },
        setChangedSubSeries() {
            if (this.showSubseries) {
                if (this.kaasmerk.subSeries.length < 1) {
                    // subserie aanmaken
                    const nieuw = new CheeseMarks({
                        batchID: this.kaasmerk.bakId,
                        productID: this.kaasmerk.productID,
                        merkProductID: this.kaasmerk.merkProductID,
                        markTypeID: this.kaasmerk.markTypeID
                    });
                    nieuw.kazen = this.kaasmerk.kazen;
                    nieuw.vormID = this.kaasmerk.vormID;
                    nieuw.beginStockItemID = this.kaasmerk.beginStockItemID;
                    nieuw.eindStockItemID = this.kaasmerk.eindStockItemID;
                    nieuw.startLetter = this.kaasmerk.startLetter;
                    nieuw.eindLetter = this.kaasmerk.eindLetter;
                    nieuw.startNummer = this.kaasmerk.startNummer;
                    nieuw.eindNummer = this.kaasmerk.eindNummer;
                    this.kaasmerk.subSeries.push(nieuw);
                    this.theBakDrain.bakKaasMerk = this.kaasmerk;
                    this.aangeraakt += 1;
                }
            }
            this.setChanged();
        },
        set_Eindmerk() {
            this.kaasmerk.startNummer = this.startNummer;
            const verschil = this.aantalKazen - this.aantalKazenBasis;
            this.kaasmerk.eindNummer = this.eindNummerBasis + verschil;
            this.eindNummer = this.kaasmerk.eindNummer;
            this.kaasmerk.isChanged = true;
            this.theBakDrain.bakKaasMerk = this.kaasmerk;
            this.setChanged();
        },
        async SetDefaults() {
            const selectionData = { recipeid: this.theBakDrain.receptID };
            const recipe = await loadList(
                'recipe/getRecipe',
                'recipe/loadRecipeComplete',
                selectionData,
                true,
                this.$t
            );
            this.programma = recipe.recProduction.progPers;
        },
        async loadKaasMerkVoorstel(km) {
            if (km.merkProductID != undefined) {
                const marktype = await loadItem(
                    'production/loadMarkType',
                    { productID: km.merkProductID },
                    this.$t
                );
                let merkFilled = false;
                if (marktype.data.lastUsedLetter != undefined) {
                    const objTest = this.flexList.find(
                        (x) =>
                            x.letter == marktype.data.lastUsedLetter &&
                            x.start <= marktype.data.lastUsedNumber &&
                            x.eind > marktype.data.lastUsedNumber
                    );
                    if (objTest) {
                        km.startLetter = marktype.data.lastUsedLetter;
                        km.eindLetter = marktype.data.lastUsedLetter;
                        km.startNummer = marktype.data.lastUsedNumber + 1;
                        merkFilled = true;
                    }
                }
                if (!merkFilled) {
                    // nieuw merkenserie
                    if (this.flexList.length > 0) {
                        km.startLetter = this.flexList[0].letter;
                        km.eindLetter = this.flexList[0].letter;
                        km.startNummer = this.flexList[0].start;
                    }
                }
                km.eindNummer = km.startNummer + km.kazen - 1;
                const obj = this.flexList.find(
                    (x) =>
                        x.letter == km.startLetter &&
                        x.start <= km.startNummer &&
                        x.eind > km.startNummer
                );
                // this.startStockID = obj ? obj.stockItemId : null;
                if (obj) {
                    // we hebben een startserie
                    this.kaasmerk.beginStockItemID = obj.stockItemId;
                    this.kaasmerk.eindStockItemID = obj.stockItemId;
                    // als ze starten met een serie dan is lastUsedNumber hier null
                    if (km.startNummer == undefined && obj != undefined) {
                        // waarschijnlijk start nieuwe serie dus is startnummer nummer 1 van die serie
                        km.startNummer = obj.start;
                    }
                    km.eindNummer = km.startNummer + km.kazen - 1;
                } else {
                    const obj1 = this.flexList.find(
                        (x) =>
                            x.letters == km.eindLetter &&
                            x.start < km.eindNummer &&
                            x.eind >= km.eindNummer
                    );
                    const stockID1 = obj1 ? obj1.stockItemId : null;
                    this.kaasmerk.eindStockItemID = stockID1;
                }
                // nieuw: vul ook het voorstel voor de subseries, zijn gesorteerd op belangrijkheid (aantal)
                let start = km.startNummer;
                const lijst = km.subSeries.filter(
                    (x) =>
                        x.isDeleted == false &&
                        (x.nouse == undefined || x.nouse == false)
                );
                for (let i = 0; i < lijst.length; i++) {
                    lijst[i].beginStockItemID = this.kaasmerk.beginStockItemID;
                    lijst[i].eindStockItemID = this.kaasmerk.eindStockItemID; // klopt meestal
                    lijst[i].startLetter = km.startLetter;
                    lijst[i].eindLetter = km.startLetter;
                    lijst[i].startNummer = start;
                    lijst[i].eindNummer =
                        lijst[i].startNummer + lijst[i].kazen - 1;
                    start = lijst[i].startNummer + lijst[i].kazen;
                }
                this.aangeraakt += 1;
            } else {
                if (this.flexList.length > 0) {
                    this.kaasmerk.startStockItemID =
                        this.flexList[0].stockItemId;
                    this.kaasmerk.eindStockItemID =
                        this.flexList[0].stockItemId;
                    km.startNummer = this.flexList[0].start;
                    km.eindNummer = km.startNummer + km.kazen - 1;
                }
            }
            this.kaasmerk = km;
            if (this.eindNummerBasis == undefined)
                this.eindNummerBasis = this.kaasmerk.eindNummer;
            this.setMerken();
            this.setChanged();
            return km;
        },
        setMerken() {
            this.startStockID = this.kaasmerk.beginStockItemID;
            this.eindStockID = this.kaasmerk.eindStockItemID;
            this.startLetter = this.kaasmerk.startLetter;
            this.eindLetter = this.kaasmerk.eindLetter;
            this.startNummer = this.kaasmerk.startNummer;
            this.eindNummer = this.kaasmerk.eindNummer;
            this.aantalKazen = this.kaasmerk.kazen;
        },
        showMarkSubSeries() {
            const operator = this.$store.getters['account/getLoggedInUser'];
            const feat = Feature.enum('conf_useCheeseMarkSubseries');
            // test, uit features
            const doshow = operator.listContainsFeature(feat);
            return doshow;
        }
    },
    mounted() {
        this.programma = this.theBakDrain.Persprogram;
        this.kaasmerk = this.theBakDrain.bakKaasMerk;
        this.aantalKazenBasis = this.kaasmerk.kazen;
        this.setMerken();
    },
    async created() {
        // haal de merkenseries op die op voorraad zijn voor deze kaassoort
        if (this.theBakDrain.bakKaasMerk.merkProductID != undefined) {
            this.markstockList = await loadList(
                'stock/getMarkStockItemLijst',
                'stock/loadMarkStockByProduct',
                {
                    productid: this.theBakDrain.bakKaasMerk.merkProductID
                },
                true,
                this.$t
            );
            this.showNoStockAlert = this.markstockList.length < 1;
        }
        // klopt dit??
        this.gebruikKaasmerk = this.markstockList.length > 0;
        this.showSubseries = this.showMarkSubSeries();
        if (this.theBakDrain.bakKaasMerk.suggestion) {
            this.showReminder(this.messageVoorstel);
            this.theBakDrain.bakKaasMerk = await this.loadKaasMerkVoorstel(
                this.theBakDrain.bakKaasMerk
            );
        } else {
            if (!this.showSubseries)
                this.showSubseries =
                    this.theBakDrain.bakKaasMerk.beginStockItemID != undefined
                        ? this.theBakDrain.bakKaasMerk.subSeries.length > 0
                        : false;
        }
        const locLijst = await loadList(
            'components/getCheesesComboLijst',
            'components/loadCheesesComboLijst',
            null,
            false,
            this.$t
        );
    }
};
</script>
<style scoped>
/*  
  CSS 
  Remember to use a # before the name because it's an id you're trying to reach
*/
#myButton {
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
}
</style>

