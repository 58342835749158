
const state = {
    batchInfoList: [],
    batchInfoHistList: [],
    batchIngredientenList: [],
    batchInpekelList: [],
    selectedBatch: null ,
    selectedBatchIngredient: null,
    batchDefaults: null,
    marksForBatch: null,
    milkusagefactor: 1,
    milkList: [],
    alternativeCheeseList: [],
    batchActivityList: [],
    timerList: [],
    selectedProdRun: []
};

export default state;
