<template>
    <b-card
        no-body
        :header="
            $t('com_user', {
                ns: 'common'
            })
        "
    >
        <div>
            <!-- maak / toon groep -->
            <b-form-group class="form-inline">
                <b-form-input
                    id="display-naam"
                    :placeholder="
                        $t('com_naam', {
                            ns: 'common'
                        })
                    "
                    v-model="displayNaam"
                    :state="checkName"
                ></b-form-input>
                <b-form-input
                    id="gebruikers-naam"
                    :placeholder="
                        $t('com_gebruikersnaam', {
                            ns: 'common'
                        })
                    "
                    v-model="gebruikersNaam"
                ></b-form-input>
                <b-form-input
                    id="gebruikers-mail"
                    :placeholder="
                        $t('com_mail', {
                            ns: 'common'
                        })
                    "
                    v-model="gebruikersMail"
                ></b-form-input>
                <b-button
                    v-if="checkRight('UserCreate') && checkUserLicenses"
                    @click="addNewGebruiker()"
                    class="bgc_aux_normal"
                    ><font-awesome-icon icon="arrow-down" size="1x" />
                </b-button>
                &nbsp;
                <b-button @click="loadData()" class="bgc_aux_normal"
                    ><font-awesome-icon icon="sync" size="1x" />&nbsp;Ververs
                </b-button>
            </b-form-group>
            <b-table
                :items="operatorList"
                :fields="gebruikers"
                stacked="md"
                show-empty
                :empty-text="$t('com_geen_data', { ns: 'common' })"
                small
                striped
                @row-clicked="toonDetail"
            >
                <template v-slot:cell(actions)="row">
                    <div class="form-inline">
                        <b-button
                            v-if="checkRight('UserDelete')"
                            size="sm"
                            @click="verwijderItem(row.item)"
                            class="bgc_aux_alarm"
                            ><font-awesome-icon icon="trash-alt" size="1x" />
                        </b-button>
                        <b-button
                            size="sm"
                            @click="toonDetail(row.item)"
                            class="bgc_aux_unknown"
                            ><font-awesome-icon icon="info-circle" size="1x" />
                        </b-button>
                    </div>
                </template>
                <template v-slot:row-details>
                    <b-table
                        :items="filteredGroupList"
                        :fields="groepen"
                        stacked="md"
                        show-empty
                        :empty-text="$t('com_geen_data', { ns: 'common' })"
                        small
                        striped
                    >
                    </b-table>
                </template>
            </b-table>
            <br />
        </div>
    </b-card>
</template>

<script>
import { loadItem, loadList } from '@/models/DataHelper';
import { Permission } from '../../models/Permissions';
export default {
    name: 'CardGebruikers',
    data() {
        return {
            operatorList: null,
            gebruikersNaam: null,
            displayNaam: null,
            gebruikersMail: null,
            selectedOperator: null,
            forceRefresh: false,
            userlicenses: 1,
            gebruikers: [
                { key: 'actions', label: ' ' },
                {
                    key: 'naam',
                    label: this.$t('com_gebruikersnaam', {
                        ns: 'common'
                    }),
                    sortable: true
                },
                {
                    key: 'mail',
                    label: this.$t('com_mail', {
                        ns: 'common'
                    }),
                    sortable: true
                },
                {
                    key: 'login',
                    label: this.$t('com_login', {
                        ns: 'common'
                    }),
                    sortable: true
                }
            ],
            groepen: [
                {
                    key: 'groupname',
                    label: this.$t('com_groep', {
                        ns: 'common'
                    }),
                    sortable: true
                }
            ]
        };
    },
    props: {},
    computed: {
        selectedUserName() {
            return this.selectedOperator ? this.selectedOperator.naam : '';
        },
        filteredGroupList() {
            const thisid = this.selectedOperator
                ? this.selectedOperator.id
                : -1;
            return this.$store.getters['account/getUserInGroups'](thisid);
        },
        checkUserLicenses() {
            const useramount = this.operatorList.length;
            return this.userlicenses > useramount ? true : false;
        },
        checkName() {
            return this.displayNaam && this.displayNaam.length > 2
                ? true
                : false;
        }
    },
    methods: {
        toonDetail(item) {
            this.selectedOperator = item;
            if (item._showDetails) {
                this.$set(item, '_showDetails', false);
            } else {
                this.operatorList.forEach((item) => {
                    this.$set(item, '_showDetails', false);
                });
                this.$set(item, '_showDetails', true);
            }
        },
        async verwijderItem(item) {
            const doClose = await this.$root.showConfirmationBox(
                this.$t('com_delete', {
                    ns: 'common',
                    val: this.$t('com_gebruiker', { ns: 'common' })
                }) + item.naam
            );
            if (doClose) {
                const selectionData = {
                    userid: item.id
                };
                await loadItem('gebruiker/deleteUser', selectionData, this.$t);
            }
            this.forceRefresh = true;
            await loadItem('account/loadOperators', null, this.$t);
            this.operatorList = this.$store.getters['account/getOperators'];
            this.loadUserLicenses();
        },
        async addNewGebruiker() {
            if (this.checkValid()) {
                const RegisterUserModel = {
                    Email: this.gebruikersMail,
                    Username: this.gebruikersNaam,
                    DisplayName: this.displayNaam
                };
                this.forceRefresh = true;
                loadItem('gebruiker/createUser', RegisterUserModel, this.$t)
                    .then(loadItem('account/loadOperators', null, this.$t))
                    .then(this.getOperators());
                this.gebruikersMail = null;
                this.gebruikersNaam = null;
            }
        },
        async getOperators() {
            this.operatorList = this.$store.getters['account/getOperators'];
            if (
                this.operatorList == undefined ||
                this.operatorList.length == 0 ||
                this.forceRefresh
            ) {
                await loadItem('account/loadOperators', null, this.$t);
                this.operatorList = this.$store.getters['account/getOperators'];
            }
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        },
        async loadData() {
            this.forceRefresh = true;
            await loadItem('account/loadOperators', null, this.$t);
            this.operatorList = this.$store.getters['account/getOperators'];
        },
        async loadUserLicenses() {
            const lijst = await loadList(
                'masterdata/getConstantsLijst',
                'masterdata/loadApplicationConstants',
                null,
                this.forceRefresh,
                this.$t
            );
            const con = lijst
                ? lijst.find((x) => x.text == window.constants.APPCONST_MAXUSER)
                : null;
            this.aantal = con ? con.value : 1;
            this.userlicenses = this.aantal;
        },
        checkValid() {
            let result = true;
            if (!this.displayNaam) {
                this.$root.showMessageBox(
                    this.$t('com_isrequired', {
                        ns: 'common',
                        val: '',
                        prop: this.$t('com_naam', { ns: 'common' })
                    })
                );
                result = false;
            }
            if (this.gebruikersNaam && this.gebruikersMail) {
                this.$root.showMessageBox(
                    this.$t('com_warnemailuser', { ns: 'common' })
                );
                result = false;
            }
            return result;
        }
    },
    async mounted() {
        this.loadUserLicenses();
    },
    created() {
        this.getOperators();
    }
};
</script>

