<template>
    <div>
        <b-input-group v-if="existing">
            <b-form-select
                key="rerenderkey"
                v-if="existing"
                v-model="localSelected"
                :options="flexList"
                :disabled="disabled"
                @change="set_input"
            >
                <template #first>
                    <b-form-select-option value=""
                        >--
                        {{ $t('com_selecteer', { ns: 'common' }) }}
                        --</b-form-select-option
                    >
                </template>
            </b-form-select>

            <b-dropdown
                v-if="showfilter"
                text="..."
                ref="dropdown"
                size="sm"
                boundary="viewport"
            >
                <div class="fluid-container">
                    <b-dropdown-form>
                        <div>
                            <b-form-group
                                label="Filter:"
                                label-for="dropdown-form-filter"
                            >
                                <b-form-row>
                                    <b-col sm="10"
                                        ><b-form-input
                                            id="dropdown-form-filter"
                                            v-model="filterTekst"
                                            placeholder="filter"
                                        ></b-form-input>
                                    </b-col>
                                    <b-col sm="2"
                                        ><b-button
                                            class="float-right"
                                            variant="primary"
                                            @click="filterLijst()"
                                            ><font-awesome-icon
                                                icon="search"
                                                size="1x"
                                        /></b-button>
                                    </b-col>
                                </b-form-row>
                            </b-form-group>
                        </div>
                        <b-dropdown-item
                            v-if="filterActive"
                            @click="clearFilter()"
                            >{{
                                $t('com_wis', { ns: 'common' })
                            }}</b-dropdown-item
                        >
                    </b-dropdown-form>
                </div></b-dropdown
            >
        </b-input-group>
        <b-form-input
            v-if="!existing"
            v-model="productNaam"
            readonly
        ></b-form-input>
    </div>
</template>
<script>
import { loadList, loadItem } from './utils';
import DateHelper from '../../models/DateHelper';
export default {
    name: 'LookupCombo',
    data: function () {
        return {
            errorMessage: '',
            localSelected: '',
            rerenderkey: 1,
            flexList: [],
            flexListNonFiltered: [],
            isInList: true,
            productNaam: '',
            filterTekst: '',
            baseFilter: true
        };
    },
    model: {
        prop: 'selected',
        event: 'input'
    },
    props: {
        combotype: {
            type: Number
        },
        selected: {
            //type: Number
        },
        discrim: {
            type: String,
            default: ''
        },
        zelf: {
            type: Object
        },
        disabled: {
            type: Boolean,
            default: false
        },
        forcerefresh: {
            type: Boolean,
            default: false
        },
        showfilter: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        discrim() {
            this.refreshLists();
        },
        selected() {
            this.localSelected = this.selected;
        }
    },
    computed: {
        existing() {
            return this.checkExisting(); //this.isInList;
        },
        filterActive() {
            return this.filterTekst.length > 0;
        }
    },
    methods: {
        filterLijst() {
            if (this.flexListNonFiltered.length == 0)
                this.flexListNonFiltered = this.flexList;
            if (this.baseFilter) {
                this.flexList = this.flexList.filter(
                    (x) =>
                        x.item.neemtijdstip != undefined &&
                        x.item.neemtijdstip.length > 0
                );
            }
            this.flexList = this.flexList.filter((x) =>
                x.text.includes(this.filterTekst)
            );
            this.rerenderkey += 1;
            this.$refs.dropdown.hide(true);
        },
        clearFilter() {
            this.baseFilter = false;
            this.filterTekst = '';
            this.flexList = this.flexListNonFiltered;
            this.rerenderkey += 1;
        },
        set_input: function () {
            const item = this.flexList.find(
                (x) => x.value == this.localSelected
            );
            this.$emit('change', true);
            this.$emit('input', item ? this.localSelected : '');
            this.$emit('inputnaam', item ? item.text : '');
        },
        async checkExisting() {
            //TODO MAKEN
            const item = this.flexList.find((x) => x.value == this.selected);
            this.isInList = item;
            //this.productNaam = this.isInList.text;
            if (!this.isInList && this.selected) {
                switch (this.combotype) {
                    case window.constants.LCA_TEMPLATEOBJECT: {
                        // todo
                        this.productNaam = await loadItem(
                            'analytics/loadEntityObject',
                            { entityObjectID: this.selected },
                            this.$t
                        );
                        break;
                    }
                    case window.constants.LCA_ENTITYOBJECT: {
                        this.productNaam = await loadItem(
                            'analytics/loadEntityObject',
                            { entityObjectID: this.selected },
                            this.$t
                        );
                        break;
                    }
                    case window.constants.LCA_QUNIT:
                    case window.constants.LCA_QLABEL:
                    default: {
                        return [];
                    }
                }
            }
            return this.isInList;
        },
        refreshLists: async function () {
            switch (this.combotype) {
                case window.constants.LCA_QUNIT: {
                    const templijst = await loadList(
                        'analytics/getQunitLijst',
                        'analytics/loadQunitLijst',
                        null,
                        this.forcerefresh,
                        this.$t
                    );
                    this.flexList = this.discrim
                        ? templijst.filter(
                              (x) =>
                                  x.baseid == this.discrim ||
                                  x.id == this.discrim
                          )
                        : templijst;
                    this.flexList = this.flexList.map((x) => ({
                        value: x.id,
                        text: x.naam + ' (' + x.symbool + ')',
                        item: x
                    }));
                    break;
                }
                case window.constants.LCA_QLABEL: {
                    const templijst = await loadList(
                        'analytics/getQlabelLijst',
                        'analytics/loadQlabelLijst',
                        null,
                        this.forcerefresh,
                        this.$t
                    );
                    this.flexList = this.discrim
                        ? templijst.filter(
                              (x) =>
                                  x.qualityID == this.discrim &&
                                  (x.type == undefined || x.type == 0)
                          )
                        : templijst;
                    this.flexList = this.flexList.map((x) => ({
                        value: x.id,
                        text: x.naam,
                        item: x
                    }));
                    break;
                }
                case window.constants.LCA_QUANTITY: {
                    const templijst = await loadList(
                        'analytics/getQuantityLijst',
                        'analytics/loadQuantityLijst',
                        null,
                        this.forcerefresh,
                        this.$t
                    );
                    this.flexList = templijst;
                    this.flexList = this.flexList.map((x) => ({
                        value: x.id,
                        text: x.naam + ' (' + x.eenheid + ')',
                        item: x
                    }));
                    break;
                }
                case window.constants.LCA_QUALITY: {
                    const templijst = await loadList(
                        'analytics/getQualityLijst',
                        'analytics/loadQualityLijst',
                        null,
                        this.forcerefresh,
                        this.$t
                    );
                    this.flexList = templijst;
                    this.flexList = this.flexList.map((x) => ({
                        value: x.id,
                        text: x.naam,
                        item: x
                    }));
                    break;
                }
                case window.constants.LCA_TEMPLATEOBJECT: {
                    let templijst = await loadList(
                        'analytics/getTemplateLijst',
                        'analytics/loadTemplateLijst',
                        null,
                        this.forcerefresh,
                        this.$t
                    );
                    templijst = Object.values(
                        templijst.reduce((a, b) => {
                            if (!a[b.templateid]) a[b.templateid] = b;
                            return a;
                        }, {})
                    );
                    if (this.discrim) {
                        if (this.discrim.indexOf('!') < 0) {
                            templijst = this.templijst.filter((c) => {
                                return c.templateid == this.discrim;
                            });
                        } else {
                            const loc = this.discrim.replace('!', '');
                            templijst = this.templijst.filter((c) => {
                                return c.templateid != loc;
                            });
                        }
                    }
                    this.flexList = templijst.map((x) => ({
                        value: x.templateid,
                        text: x.templatenaam,
                        item: x
                    }));
                    break;
                }
                case window.constants.LCA_INVQUANTITY: {
                    let templijst = await loadList(
                        'analytics/getMaesurableIQLijst',
                        'analytics/loadInvestQuantityLijst',
                        null,
                        this.forcerefresh,
                        this.$t
                    );
                    if (this.discrim) {
                        const test = templijst.filter(
                            (x) => x.uitvoerder == this.discrim
                        );
                        templijst = test.length > 0 ? test : templijst;
                    }

                    this.flexList = templijst.map((x) => ({
                        value: x.id,
                        text: x.naam,
                        item: x
                    }));
                    break;
                }
                case window.constants.LCA_EXECUTOR: {
                    const templijst = await loadList(
                        'analytics/getExecutorLijst',
                        'analytics/loadExecutorLijst',
                        null,
                        this.forcerefresh,
                        this.$t
                    );
                    this.flexList = templijst.map((x) => ({
                        value: x.id,
                        text: x.naam,
                        item: x
                    }));

                    break;
                }
                case window.constants.LCA_ENTITYOBJECT: {
                    const templijst = await loadList(
                        'analytics/getEntityObjectLijst',
                        'analytics/loadEntityObjectByTypename',
                        null,
                        this.forcerefresh,
                        this.$t
                    );
                    const filtered = this.discrim
                        ? templijst.filter(
                              (x) => x.typenaambasis == this.discrim
                          )
                        : templijst;
                    this.flexList = filtered.map((x) => ({
                        value: x.id,
                        text: x.naam,
                        item: x
                    }));
                    break;
                }
                case window.constants.LCA_SAMPLETYPE: {
                    const templijst = await loadList(
                        'analytics/getMonsterTypeLijst',
                        'analytics/loadSampleTypeLijst',
                        null,
                        this.forcerefresh,
                        this.$t
                    );
                    this.flexList = templijst.map((x) => ({
                        value: x.id,
                        text: x.naam,
                        item: x
                    }));
                    break;
                }
                case window.constants.LCA_SAMPLES: {
                    const nu = new Date();
                    const toen = new Date();
                    toen.setFullYear(nu.getFullYear() - 1);
                    const selectionData = {
                        from: DateHelper.localDateToUTC(toen),
                        until: DateHelper.localDateToUTC(nu)
                    };
                    const templijst = await loadList(
                        'analytics/getMonsterLijst',
                        'analytics/loadSampleLijst',
                        selectionData,
                        this.forcerefresh,
                        this.$t
                    );
                    this.flexList = templijst.map((x) => ({
                        value: x.id,
                        text:
                            x.naam +
                            ' (' +
                            x.meetplekNaam +
                            ', ' +
                            x.barcode +
                            ')',
                        item: x
                    }));
                    this.filterLijst();
                    break;
                }
                default: {
                    return [];
                }
            }
        }
    },
    beforeUpdate() {},
    mounted() {
        this.localSelected = this.selected ?? '';
    },
    created() {
        this.refreshLists();
    }
};
</script>

<style scoped></style>

