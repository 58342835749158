export { INGRCAT_ENUM, IngredientCategorie };

const INGRCAT_ENUM = Object.freeze({
    Melk: 1,
    Zuursel: 2,
    Stremsel: 3,
    Kleursel: 4,
    Conserveermiddel: 5,
    Calcium: 6,
    Zout: 7,
    Kruiden: 8,
    Aromas: 9,
    Vetten: 10,
    Grootzuursel: 11
});
export default INGRCAT_ENUM;

class IngredientCategorie {
    constructor(data) {
        this.id = -1;
        this.type = 'IngredientCategorie';
        data = data ?? {};
        this.isDeleted = false;
        this.isChanged = false;
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false
            },
            naam: {
                type: String,
                value: data.description,
                writable: true,
                enumerable: true
            },
            eenheid: {
                value: data.unitid,
                writable: true,
                enumerable: true
            },
            kleur: {
                value: data.color, // todo
                writable: true,
                enumerable: true
            },
            lijst: {
                value: [],
                writable: true,
                enumerable: true
            }
        });
        this.isNew = (this.id == undefined || this.id < 0);
    }

    // tijdelijk
    categorieColor() {
        switch (this.id) {
            case INGRCAT_ENUM.Melk:
                return 'DeepSkyBlue';
            case INGRCAT_ENUM.Zuursel:
                return 'GhostWhite';
            case INGRCAT_ENUM.Stremsel:
                return 'Khaki';
            case INGRCAT_ENUM.Kleursel:
                return 'Tomato';
            case INGRCAT_ENUM.Conserveermiddel:
                return 'SaddleBrown';
            case INGRCAT_ENUM.Calcium:
                return 'LightGrey';
            case INGRCAT_ENUM.Zout:
                return 'LightSalmon';
            case INGRCAT_ENUM.Kruiden:
                return 'DarkSeaGreen';
            case INGRCAT_ENUM.Aromas:
                return 'MediumPurple';
            case INGRCAT_ENUM.Vetten:
                return 'GhostWhite';
            case INGRCAT_ENUM.Grootzuursel:
                return 'GhostWhite';
            default:
                return 'GhostWhite';
        }
    }
}
