
const state = {
    unitsComboList: [],
    marktypesComboList: [],
    milktypeIGComboList: [],
    IGComboList: [], 
    shapesComboList: [], 
    fatclassComboList: [], 
    cheesesComboList: [], 
    cheeseAlternativesComboList: [],
    cheesesWithoutRecipeComboList: [],
    FailureCheeseComboList: [],
    companiesComboList: [],
    productsComboList: [],
    locationsComboList: [],
    IngredientComboList: [],
    brineIngrComboList: [],
    simpleProdComboList: []

};

export default state;
