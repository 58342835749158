<template>
    <div>
        <b-col :sm="12" class="mx-0 px-0 my-0 py-0">
            <b-input-group :append="eenheid">
                <b-form-input
                    :placeholder="placeholder"
                    type="number"
                    step="0.1"
                    v-model.number="displayVal"
                    @change="set_inputVal"
                    :disabled="!editAllowed"
                    :state="validVal"
                    :formatter="numberFormat"
                ></b-form-input>
            </b-input-group>
        </b-col>
    </div>
</template>
<script>
import Helper from '../../models/Helper';

export default {
    name: 'UnitInput',
    data: function () {
        return {
            eenheid: '',
            tempVal: null,
            displayVal: null
        };
    },
    model: {
        prop: 'selected',
        event: 'input'
    },
    props: {
        selected: {
            type: Number,
            required: false
        },
        minvalue: {
            type: Number,
            required: false
        },
        maxvalue: {
            type: Number,
            required: false
        },
        unitId: {
            type: Number,
            required: false
        },
        editAllowed: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String
        }
    },
    watch: {
        selected() {
            this.setVals();
        },
        unitId() {
            this.setUnit();
        }
    },
    components: {},
    computed: {
        validVal() {
            return this.tempVal && this.minvalue && this.maxvalue
                ? this.tempVal > this.minvalue && this.tempVal < this.maxvalue
                : true;
        }
    },
    methods: {
        numberFormat(value) {
            return Helper.Round(value, 1);
        },
        set_input() {
            const valid = this.validTemperatuurC;
            this.$emit('change', true);
            this.$emit('valid', valid);
            this.$emit('input', this.tempVal);
        },
        set_inputVal(val) {
            this.tempVal = val ? val : null;
            this.set_input();
        },
        setVals() {
            this.tempVal = this.selected;
            this.displayVal = Helper.Round(this.tempVal, 1);
            this.setUnit(this.unitId);
        },
        setUnit(val) {
            const oUnit = this.$store.getters['components/getUnitbyID'](val);
            this.eenheid = oUnit?.symbol;
        }
    },
    mounted() {
        this.setVals();
    },
    created() {}
};
</script>

<style scoped></style>

