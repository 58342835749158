<template>
    <b-container fluid style="margin-top: 60px">
        <h1>{{ title }}</h1>
        <b-row>
            <b-col sm="12">
                <div v-if="showFilter">
                    <b-row no-gutters>
                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_filter', { ns: 'common' })"
                                label-for="filter-input"
                                label-cols-sm="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-input-group size="sm">
                                    <b-form-input
                                        id="filter-input"
                                        v-model="filter"
                                        type="search"
                                        :placeholder="
                                            $t('com_typetosearch', {
                                                ns: 'common'
                                            })
                                        "
                                    ></b-form-input>

                                    <b-input-group-append>
                                        <b-button
                                            :disabled="!filter"
                                            @click="filter = ''"
                                            >{{
                                                $t('com_wis', { ns: 'common' })
                                            }}</b-button
                                        >
                                    </b-input-group-append>
                                </b-input-group>

                                <b-button
                                    v-if="
                                        !gesloten &&
                                        checkRight('MasterDataCreate')
                                    "
                                    size="sm"
                                    @click="createNewItem"
                                    class="mr-1"
                                    ><font-awesome-icon
                                        icon="folder-plus"
                                        size="1x"
                                    />
                                    {{
                                        $t('com_newx', {
                                            ns: 'common',
                                            val: $t('com_item', {
                                                ns: 'common'
                                            })
                                        })
                                    }}
                                </b-button>
                            </b-form-group>
                        </b-col>

                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_perpagina', { ns: 'common' })"
                                label-for="per-page-select"
                                label-cols-sm="6"
                                label-cols-md="4"
                                label-cols-lg="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-form-select
                                    id="per-page-select"
                                    v-model="perPage"
                                    :options="pageOptions"
                                    size="sm"
                                ></b-form-select>
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                ></b-pagination>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div v-else>
                    <b-button
                        v-if="!gesloten && checkRight('MasterDataCreate')"
                        size="sm"
                        @click="createNewItem"
                        class="mr-1"
                        ><font-awesome-icon icon="folder-plus" size="1x" />
                        {{
                            $t('com_newx', {
                                ns: 'common',
                                val: $t('com_item', {
                                    ns: 'common'
                                })
                            })
                        }}
                    </b-button>
                </div>

                <b-table
                    :items="displayList"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    stacked="md"
                    show-empty
                    :empty-text="$t('com_geen_data', { ns: 'common' })"
                    small
                    striped
                    @filtered="onFiltered"
                >
                    <template v-slot:cell(actions)="row">
                        <b-button
                            v-if="checkRight('MasterDataUpdate')"
                            size="sm"
                            @click="editItem(row.item)"
                            class="bgc_aux_warning"
                            ><font-awesome-icon icon="edit" size="1x" />
                        </b-button>
                        <b-button
                            v-if="checkRight('MasterDataDelete')"
                            size="sm"
                            @click="verwijderItem(row.item)"
                            class="bgc_aux_alarm"
                            ><font-awesome-icon icon="trash-alt" size="1x" />
                        </b-button>
                    </template>
                </b-table>
            </b-col>
        </b-row>

        <master-data-modal
            v-if="detailsVisible"
            :inItem="currentItem"
            v-model="detailsVisible"
            v-bind:editAllowed="true"
            v-bind:displayNumber="localdisplayNumber"
            @hide="reset"
        />
    </b-container>
</template>

<script>
import MasterDataModal from './MasterDataModal.vue';
import {
    Vorm,
    Ost,
    Location,
    Company,
    Equipment
} from '../../models/MasterData';
import {
    ReportGroup
} from '../../models/Anayltics';
import { loadMasterData, loadData } from './loaddata';
import { Permission } from '../../models/Permissions';
import logMessage from '../errors/foutlog';
import { loadItem } from '@/models/DataHelper';

export default {
    name: 'MasterDataBase',
    data: function () {
        return {
            baseFields: [
                { key: 'actions', label: ' ', visible: true },
                { key: 'value', label: 'Id', sortable: false, visible: false },
                {
                    key: 'text',
                    label: this.$t('com_naam', { ns: 'common' }),
                    sortable: true,
                    visible: true
                }
            ],
            fields: [],
            dynamicFields: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [10, 20, 40, { value: 100, text: '100' }],
            filter: null,
            filterOn: [],
            currentItem: null,
            detailsVisible: false,
            gesloten: false,
            displayFilterCount: 20
        };
    },
    props: {
        typeBase: {
            type: String,
            required: true
        }
    },
    components: {
        MasterDataModal
    },
    computed: {
        title() {
            switch (this.typeBase) {
                case window.constants.MDATA_VORM:
                    return this.$t('prod_vormen', { ns: 'production' });
                case window.constants.MDATA_OST:
                    return this.$t('prod_osts', { ns: 'production' });
                case window.constants.MDATA_LOCATION:
                    return this.$t('com_locations', { ns: 'common' });
                case window.constants.MDATA_EQUIPMENT:
                    return this.$t('prod_apparaten', { ns: 'production' });
                case window.constants.MDATA_COMPANY:
                    return this.$t('com_companies', { ns: 'common' });
                case window.constants.MDATA_REPORTGROUP:
                    return this.$t('rep_reportgroups', { ns: 'report' });
                default: {
                    return '';
                }
            }
        },
        showFilter() {
            return this.displayList
                ? this.displayList.length > this.displayFilterCount
                : false;
        },
        displayList() {
            let lijst = null;
            switch (this.typeBase) {
                case window.constants.MDATA_VORM:
                    lijst = this.$store.getters['masterdata/getShapesLijst'];
                    break;
                case window.constants.MDATA_OST:
                    lijst = this.$store.getters['masterdata/getOstLijst'];
                    break;
                case window.constants.MDATA_LOCATION:
                    lijst = this.$store.getters['masterdata/getLocationLijst'];
                    break;
                case window.constants.MDATA_EQUIPMENT:
                    lijst = this.$store.getters['masterdata/getEquipmentLijst'];
                    break;
                case window.constants.MDATA_COMPANY:
                    lijst = this.$store.getters['masterdata/getCompanyLijst'];
                    break;
                case window.constants.MDATA_REPORTGROUP:
                    lijst =
                        this.$store.getters['masterdata/getReportGroupLijst'];
                    break;
                default: {
                    return [];
                }
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.totalRows = lijst ? lijst.length : 0;
            return lijst;
        },
        localdisplayNumber() {
            return this.typeBase;
        }
    },
    methods: {
        async getItem(id) {
            let temp = null;
            switch (this.typeBase) {
                case window.constants.MDATA_VORM:
                    temp = await loadItem(
                        'masterdata/loadShape',
                        {
                            shapeid: id
                        },
                        this.$t
                    );
                    break;
                case window.constants.MDATA_OST:
                    temp = await loadItem(
                        'masterdata/loadOst',
                        {
                            ostid: id
                        },
                        this.$t
                    );
                    break;
                case window.constants.MDATA_LOCATION:
                    temp = await loadItem(
                        'masterdata/loadLocation',
                        {
                            stockLocationID: id
                        },
                        this.$t
                    );
                    break;
                case window.constants.MDATA_EQUIPMENT:
                    temp = await loadItem(
                        'masterdata/loadEquipment',
                        {
                            equipmentid: id
                        },
                        this.$t
                    );
                    break;
                case window.constants.MDATA_COMPANY:
                    temp = await loadItem(
                        'masterdata/loadCompany',
                        {
                            companyid: id
                        },
                        this.$t
                    );
                    break;
                case window.constants.MDATA_REPORTGROUP:
                    temp = await loadItem(
                        'masterdata/loadReportGroup',
                        {
                            reportingid: id
                        },
                        this.$t
                    );
                    break;
                default: {
                    return [];
                }
            }
            return temp;
        },
        createNewItem() {
            let temp = null;
            switch (this.typeBase) {
                case window.constants.MDATA_VORM:
                    temp = new Vorm();
                    break;
                case window.constants.MDATA_OST:
                    temp = new Ost();
                    break;
                case window.constants.MDATA_LOCATION:
                    temp = new Location(null, null);
                    break;
                case window.constants.MDATA_EQUIPMENT:
                    temp = new Equipment();
                    break;
                case window.constants.MDATA_COMPANY:
                    temp = new Company();
                    break;
                case window.constants.MDATA_REPORTGROUP:
                    temp = new ReportGroup();
                    break;
                default: {
                    return [];
                }
            }
            this.currentItem = temp;
            this.currentItem.type = this.giveName();
            this.detailsVisible = true;
        },
        async editItem(item) {
            this.currentItem = await this.getItem(item.id);
            this.currentItem.type = this.giveName();
            this.currentItem.SetForSave();
            this.detailsVisible = true;
        },
        giveName() {
            switch (this.typeBase) {
                case window.constants.MDATA_VORM:
                    return this.$t('prod_vorm', { ns: 'production' });
                case window.constants.MDATA_OST:
                    return this.$t('prod_ost', { ns: 'production' });
                case window.constants.MDATA_LOCATION:
                    return this.$t('com_location', { ns: 'common' });
                case window.constants.MDATA_EQUIPMENT:
                    return this.$t('prod_apparaat', { ns: 'production' });
                case window.constants.MDATA_COMPANY:
                    return this.$t('com_company', { ns: 'common' });
                case window.constants.MDATA_REPORTGROUP:
                    return this.$t('rep_reportgroup', { ns: 'report' });
                default: {
                    return '';
                }
            }
        },
        async verwijderItem(item) {
            const doClose = await this.$root.showConfirmationBox(
                this.$t('com_delete', {
                    ns: 'common',
                    val: item.id + ' : ' + item.text
                })
            );
            if (doClose) {
                const locItem = await this.getItem(item.id);
                const deldata = {
                    id: locItem.id,
                    lastupdated: locItem.gewijzigd
                };
                switch (this.typeBase) {
                    case window.constants.MDATA_VORM:
                        await loadItem(
                            'masterdata/deleteShape',
                            deldata,
                            this.$t
                        );
                        break;
                    case window.constants.MDATA_OST:
                        await loadItem(
                            'masterdata/deleteOst',
                            deldata,
                            this.$t
                        );
                        break;
                    case window.constants.MDATA_LOCATION:
                        await loadItem(
                            'masterdata/deleteLocation',
                            deldata,
                            this.$t
                        );
                        break;
                    case window.constants.MDATA_EQUIPMENT:
                        await loadItem(
                            'masterdata/deleteEquipment',
                            deldata,
                            this.$t
                        );
                        break;

                    case window.constants.MDATA_COMPANY:
                        await loadItem(
                            'masterdata/deleteCompany',
                            deldata,
                            this.$t
                        );
                        break;
                    case window.constants.MDATA_REPORTGROUP:
                        await loadItem(
                            'masterdata/deleteReportGroup',
                            deldata,
                            this.$t
                        );
                        break;
                    default: {
                        return [];
                    }
                }
                await loadMasterData(this.typeBase, this.$t);
            }
        },
        reset() {
            this.currentItem = null;
            this.detailsVisible = false;
            loadMasterData(this.displayNumber, this.$t);
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        setData(val) {
            this.dynamicFields = val;
            this.fields = this.baseFields.slice();
            if (this.dynamicFields != []) {
                this.fields.push(...this.dynamicFields);
            }
            this.fields = this.fields.filter((field) => field.visible);
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        }
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.displayList ? this.displayList.length : 0;
    },
    async beforeRouteEnter(to, from, next) {
        next(async (vm) => {
            const func = vm.$t;
            const lijst = await loadData(to, func);
            vm.setData(lijst);
        });
    },
    beforeRouteUpdate(to, from, next) {
        const func = this.$t;
        loadData(to, func)
            .then((val) => {
                this.setData(val);
                next();
            })
            .catch(function (error) {
                next();
                logMessage(error, 'error');
            });
    },
    created() {}
};
</script>

<style scoped></style>

