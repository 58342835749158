<template>
    <div>
        <lookup-combo
            id="productid"
            v-model="localSelected.productID"
            v-bind:combotype="lctypeSimple"
            v-bind:discrimArray="discrimArray"
            :disabled="setDisabledProduct"
            @input="set_inputProduct"
        ></lookup-combo>
        <!--         <b-input-group>
            <template #prepend>
                <b-input-group-text>Product</b-input-group-text>
            </template>
            <lookup-combo
                id="productnaam"
                v-model="localSelected.id"
                v-bind:combotype="lctypeSimple"
                v-bind:discrimArray="discrimArray"
                :disabled="setDisabledProduct"
                @input="set_inputProduct"
            ></lookup-combo>
        </b-input-group> -->
        <div class="form-inline">
            <b-col :sm="sizeCol" class="mx-0 px-0 my-0 py-0">
                <b-input-group prepend="hh" :label="$t('com_amount', { ns: 'common' })">
                    <b-form-input
                        id="input-hoeveelheid"
                        :placeholder="$t('com_amount', { ns: 'common' })"
                        type="number"
                        step="1"
                        v-model.number="localSelected.hoeveelheid"
                        @change="set_inputHH"
                        :disabled="setDisabled"
                        :readonly="readonly"
                    ></b-form-input>
                </b-input-group>
            </b-col>
            <b-col :sm="sizeCol" class="mx-0 px-0 my-0 py-0">
                <lookup-combo
                    v-model="localSelected.eenheidID"
                    v-bind:combotype="lctypeUnits"
                    :disabled="setDisabledUnit"
                    :readonly="readonly"
                    @change="set_input"
                ></lookup-combo>
            </b-col>
            <b-col :sm="sizeCol" class="mx-0 px-0 my-0 py-0" v-if="isKaas">
                <lookup-combo
                    v-model="localSelected.vormID"
                    v-bind:combotype="lctypeShapes"
                    :disabled="setDisabledUnit"
                    :readonly="readonly"
                    @change="set_input"
                ></lookup-combo>
            </b-col>
        </div>

        <div
            class="form-inline"
            v-if="showBatchNumber && !(showSerial || showSerialForced)"
        >
            <b-col sm="12" class="mx-0 px-0 my-0 py-0">
                <b-input-group
                    :prepend="$t('com_batch', { ns: 'common' })"
                    :label="$t('com_batchnumber', { ns: 'common' })"
                >
                    <b-form-input
                        id="input-batch"
                        :placeholder="$t('com_batchnumber', { ns: 'common' })"
                        v-model="localSelected.batch"
                        @change="set_input"
                        :disabled="setDisabled"
                        :readonly="readonly"
                    ></b-form-input>
                    <template #append>
                        <b-dropdown>
                            <b-dropdown-item
                                v-for="(item, key) in batchNumbers"
                                :key="key"
                                @click="setBatchNumber(item)"
                            >
                                {{ item.text }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </b-input-group>
            </b-col>
        </div>
        <div class="form-inline" v-if="showSerial || showSerialForced">
            <b-col sm="12" class="mx-0 px-0 my-0 py-0">
                <b-input-group
                    :prepend="$t('com_mark', { ns: 'common' })"
                    :label="$t('tien_serial', { ns: 'common' })"
                >
                    <b-form-input
                        id="input-serialstart"
                        :placeholder="$t('com_start', { ns: 'common' })"
                        v-model="localSelected.startmerk"
                        @change="set_input"
                        :disabled="setDisabled"
                        :readonly="readonly"
                    ></b-form-input>
                    <template #append>
                        <b-dropdown>
                            <b-dropdown-item
                                v-for="(item, key) in serial"
                                :key="key"
                                @click="setSerial(item)"
                            >
                                {{ item.text }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                    <b-form-input
                        id="input-serialend"
                        :placeholder="$t('com_eind', { ns: 'common' })"
                        v-model="localSelected.eindmerk"
                        @change="set_input"
                        :disabled="setDisabled"
                        :readonly="readonly"
                    ></b-form-input>
                </b-input-group>
            </b-col>
        </div>
        <b-form-textarea
            class="mx-0 px-0 my-0 py-0"
            id="input-itemremark"
            :placeholder="$t('com_opmerking', { ns: 'common' })"
            v-model="localSelected.opmerking"
            @change="set_input"
            :disabled="setDisabled"
            :readonly="readonly"
            rows="2"
        ></b-form-textarea>
        <div class="form-inline mx-0 px-0 my-0 py-0"></div>
    </div>
</template>
<script lang="ts">
import Helper from '@/models/Helper';
import { defineComponent } from 'vue';
import {
    SimpleProduction,
    SimpleProductionElement
} from '../../models/PlusProduction';
import { loadItem, loadList, loadGetItem } from '../../models/DataHelper';
import { LookupCombo } from '../components';
export default defineComponent({
    name: 'PlusProducionCombo',
    data: function () {
        return {
            lctypeSimple: window.constants.LCSIMPLEPRODUCT,
            lctypeUnitsAll: window.constants.LCPERUNITS,
            lctypeShapes: window.constants.LCSHAPES,
            lctypeUnitsBase: window.constants.LCPERUNITSBASEONLY, // product mag alleen in basiseenheid
            tienpluskaas: window.constants.TIENPLUSKAAS,
            showSerialForced: false,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            batchNumbers: [] as Array<any>,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            serial: [] as Array<any>,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            productLookupLijst: [] as Array<any>
        };
    },
    model: {
        prop: 'selected',
        event: 'input'
    },
    props: {
        selected: {
            type: [SimpleProductionElement, SimpleProduction],
            required: true
        },
        discrimArray: {
            type: Array<SimpleProductionElement>
        },
        showBatchNumber: {
            type: Boolean,
            default: true
        },
        showSerial: {
            type: Boolean,
            default: false
        },
        editAllowed: {
            type: Boolean,
            default: true
        },
        readonly: {
            type: Boolean,
            default: false
        },
        isIngredient: {
            type: Boolean,
            default: false
        }
    },
    components: {
        LookupCombo
    },
    computed: {
        localSelected() {
            return this.selected;
        },
        setDisabled() {
            return !this.editAllowed && this.readonly;
        },
        setDisabledUnit() {
            if (this.readonly) return true;
            if (!this.showBatchNumber && !this.localSelected.isNew) return true;
            return !this.editAllowed && this.readonly;
        },
        setDisabledProduct() {
            if (!this.localSelected.isNew) return true;
            return !this.editAllowed && this.readonly;
        },
        lctypeUnits() {
            return this.isIngredient
                ? this.lctypeUnitsAll
                : this.lctypeUnitsBase;
        },
        isKaas() {
            return (
                this.selected.productType != undefined &&
                this.selected.productType == window.constants.TIENPLUSKAAS
            );
        },
        sizeCol() {
            return this.isKaas ? 4 : 6;
        }
    },
    methods: {
        set_input() {
            this.$emit('change', true);
            this.$emit('input', this.localSelected);
        },
        set_inputHH(hh) {
            if (this.showSerial) {
                // eslint-disable-next-line no-prototype-builtins
                if (this.selected.hasOwnProperty('startmerk')) {
                    const a = this.selected as SimpleProductionElement;
                    if (a.startmerk) {
                        a.eindmerk = Helper.calcSerial(a.startmerk, hh - 1);
                    }
                }
            }
            this.set_input();
        },
        set_inputProduct(id) {
            if (this.showBatchNumber && !this.showSerial)
                this.showBatchNumbers(id);
            if (this.showSerial) this.showSerials(id);
            const product = this.productLookupLijst.find((x) => x.id == id);
            if (product?.eenheidID) this.selected.eenheidID = product.eenheidID;
            if (product?.type) this.selected.productType = product.type;
            if (product) {
                this.showSerialForced =
                    product.type != undefined && product.type == -1
                        ? true
                        : false;
                if (this.showSerialForced) {
                    this.showSerials();
                } else {
                    this.showBatchNumbers();
                }
            }
            this.$emit('productChange', this.localSelected.productID);
        },
        setBatchNumber(item) {
            this.selected.batch = item.text;
        },
        setSerial(item) {
            // eslint-disable-next-line no-prototype-builtins
            if (this.selected.hasOwnProperty('startmerk')) {
                const a = this.selected as SimpleProductionElement;
                a.startmerk = Helper.calcSerial(item.text, 1);
                a.eindmerk = Helper.calcSerial(
                    item.text,
                    this.localSelected.hoeveelheid ?? 2
                );
            }
        },
        async showBatchNumbers(id = null) {
            if (id || this.selected.productID > -1) {
                const data = { productID: id ?? this.selected.productID };
                const lijst = await loadItem(
                    'tienplus/loadProductUsedProducts',
                    data,
                    this.$t
                );
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const fieldsInit = [] as Array<any>;
                if (lijst.length > 0) {
                    for (let i = 0; i < lijst.length; i++) {
                        const o = {
                            key: lijst[i].id,
                            value: lijst[i].batch,
                            text: lijst[i].batch,
                            visible: true
                        };
                        fieldsInit.push(o);
                    }
                    this.batchNumbers = fieldsInit.filter((x) => x.visible);
                }
            }
        },
        async showSerials(id = null) {
            if (id || this.selected.productID > -1) {
                const data = { productID: id ?? this.selected.productID };
                const serialstring = await loadItem(
                    'tienplus/loadProductSerialNumber',
                    data,
                    this.$t
                );
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const fieldsInit = [] as Array<any>;
                if (serialstring) {
                    const o = {
                        key: this.selected.id,
                        value: serialstring,
                        text: serialstring,
                        visible: true
                    };
                    fieldsInit.push(o);
                    this.serial = fieldsInit.filter((x) => x.visible);
                }
            }
        }
    },
    async mounted() {
        const lijst = await loadList(
            'components/getSimpleProdComboLijst',
            'components/loadSimpleProducts',
            null,
            false,
            this.$t
        );
        this.productLookupLijst = lijst;
        if (this.selected.productID > -1) {
            const product = this.productLookupLijst.find(
                (x) => x.id == this.selected.productID
            );
            //if (product?.eenheidID) this.selected.eenheidID = product.eenheidID;
            if (product?.type) this.selected.productType = product.type;
        }
        if (this.showBatchNumber && !this.showSerial) this.showBatchNumbers();
        if (this.showSerial) this.showSerials();
    },
    created() {
        if (this.showBatchNumber && !this.showSerial) this.showBatchNumbers();
        if (this.showSerial) this.showSerials();
    }
});
</script>

<style scoped></style>

