<template>
    <div class="form-inline">
        <b-col sm="5" class="mx-0 px-0 my-0 py-0">
            <b-input-group :prepend="$t('com_categorie', { ns: 'common' })">
                <b-form-select
                    v-model="category"
                    :options="categoryList"
                    @change="set_inputCategory"
                >
                    <template #first>
                        <b-form-select-option value=""
                            >--
                            {{
                                $t('com_selecteer', {
                                    ns: 'common'
                                })
                            }}
                            --</b-form-select-option
                        >
                    </template>
                </b-form-select>
            </b-input-group>
        </b-col>

        <b-col sm="7" class="mx-0 px-0 my-0 py-0">
            <b-input-group
                :prepend="$t('prod_ingredient', { ns: 'production' })"
            >
                <b-form-select
                    v-model="ingredientID"
                    :options="ingredientSubList"
                    @change="set_input"
                >
                    <template #first>
                        <b-form-select-option value=""
                            >--
                            {{
                                $t('com_selecteer', {
                                    ns: 'common'
                                })
                            }}
                            --</b-form-select-option
                        >
                    </template>
                </b-form-select>
            </b-input-group>
        </b-col>
    </div>
</template>
<script>
import Helper from '../../models/Helper';
import { loadList } from '../../models/DataHelper';
export default {
    name: 'IngredientSelector',
    data: function () {
        return {
            category: '',
            ingredientID: -1,
            ingredientList: [],
            ingredientSubList: []
        };
    },
    model: {
        prop: 'selected',
        event: 'input'
    },
    props: {
        selected: {
            type: Number,
            required: true
        }
    },
    components: {},
    watch: {},
    computed: {
        categoryList() {
            const lijstDistinct = Helper.distinctList(
                this.ingredientList,
                'category'
            );
            const lijst = lijstDistinct.map((x) => ({
                value: x.category,
                text: x.category
            }));
            if (lijst.length == 1) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.category = lijst[0].value;
                this.set_inputCategory(lijst[0].value);
            }
            return lijst;
        }
    },
    methods: {
        set_input(anid) {
            this.ingredientID = anid;
            this.$emit('change', true);
            this.$emit('input', this.ingredientID);
        },
        set_inputCategory(cat) {
            const lijst = this.ingredientList.filter((x) => x.category == cat);
            this.ingredientSubList = lijst.map((x) => ({
                value: x.value,
                text: x.naam
            }));
            if (this.ingredientSubList.length == 1) {
                this.set_input(this.ingredientSubList[0].value);
            }
        }
    },
    mounted() {
        this.ingredientID = this.selected;
    },
    async created() {
        this.ingredientList = await loadList(
            'components/getIngredientComboLijst',
            'components/loadIngredientsForGroupComboLijst',
            null,
            true,
            this.$t
        );
    }
};
</script>

<style scoped></style>
