import Api from '../../../api';
import store from '../../../store';

const fetchTrackTrace = async data => {
    return fetchInfo('/Reporting/TrackAndTraceReport', data);
};

const fetchTrackTraceMerk = async data => {
    return fetchInfo('/Reporting/FindCheeseMark', data);
};

const fetchTrackTraceLot = async data => {
    return fetchInfo('/Reporting/FindLotNumber', data);
};

const fetchProductionReport = async data => {
    return fetchInfo('/Reporting/ProductionReport', data);
};

const fetchIngredientsReport = async data => {
    return fetchInfo('/Reporting/IngredientsReport', data);
};

const fetchTrackTraceMerk10plus = async data => {
    return fetchInfo('/Reporting/FindSimpleSerialNumber', data);
};


// funties voor grafieken

const fetchProductionMilkConsumption = async data => {
    return fetchInfo('/Reporting/ProductionMilkConsumption', data);
};

// report = /Reporting/IngredientsProductionDayReport
const fetchInfo = async (apicall, data) => {
    if (data != undefined) {
        return await Api(store).get(
            apicall +'?' +
                Object.keys(data)
                    .map(
                        k =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    } else {
        return await Api(store).get(apicall, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    }
};



export default {
    fetchInfo,
    fetchTrackTrace,
    fetchTrackTraceMerk,
    fetchTrackTraceMerk10plus,
    fetchTrackTraceLot,
    fetchProductionReport,
    fetchIngredientsReport,
    fetchProductionMilkConsumption
};
