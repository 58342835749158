<template>
    <b-modal
        id="modal-graph"
        size="xl"
        ref="modal"
        :title="this.formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
    >
        <div>
            <b-card>
                <b-row class="form-inline">
                    <b-col sm="4" class="mx-0 px-0 my-0 py-0">
                        <b-form-select
                            v-model="naam"
                            :options="naamLijst"
                            @change="filterData"
                        >
                            <template #first>
                                <b-form-select-option value=""
                                    >--
                                    {{
                                        $t('com_selecteer', {
                                            ns: 'common'
                                        })
                                    }}
                                    --</b-form-select-option
                                >
                            </template>
                        </b-form-select>
                    </b-col>
                    <b-col sm="4" class="mx-0 px-0 my-0 py-0">
                        <b-form-select
                            v-model="plaats"
                            :options="plaatsLijst"
                            @change="AddToDisplayList"
                        >
                            <template #first>
                                <b-form-select-option value=""
                                    >--
                                    {{
                                        $t('com_selecteer', {
                                            ns: 'common'
                                        })
                                    }}
                                    --</b-form-select-option
                                >
                            </template>
                        </b-form-select>
                    </b-col>
                    <b-col sm="4" class="mx-0 px-0 my-0 py-0">
                        <b-list-group v-for="item in toonLijst" :key="item.id">
                            <b-list-group-item
                                class="d-flex justify-content-between align-items-center"
                            >
                                {{ displayItem(item.id) }}
                                <b-badge variant="primary" pill>{{
                                    displayNumber(item.id)
                                }}</b-badge>
                                <b-button
                                    @click="verwijderItem(item.id)"
                                    class="bgc_aux_alarm"
                                    ><font-awesome-icon
                                        icon="trash-alt"
                                        size="1x"
                                    />
                                </b-button>
                            </b-list-group-item>
                        </b-list-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12" class="mx-0 px-0 my-0 py-0">
                        <line-chart-generator
                            v-if="showGraph"
                            :chart-options="currentOptions"
                            :chart-data="currentData"
                            :chart-id="currentChartId"
                            :dataset-id-key="currentDatasetIdKey"
                            :plugins="plugins"
                            :css-classes="cssClasses"
                            :styles="styles"
                            :width="width"
                            :height="height"
                        />
                        <bar
                            v-if="showBar"
                            :chart-options="currentOptions"
                            :chart-data="currentData"
                            :chart-id="currentChartId"
                            :dataset-id-key="currentDatasetIdKey"
                            :plugins="plugins"
                            :css-classes="cssClasses"
                            :styles="styles"
                            :width="width"
                            :height="height"
                        />
                    </b-col>
                </b-row>
            </b-card>
        </div>
    </b-modal>
</template>

<script>
import { Bar, Line as LineChartGenerator } from 'vue-chartjs/legacy';
import { Chart as ChartJS } from 'chart.js/auto';
import moment from 'moment';
import 'chartjs-adapter-moment';
import _ from 'lodash';
import Helper from '../../models/Helper';
import DateHelper from '../../models/DateHelper';
export default {
    name: 'ModalGraph',
    data: function () {
        return {
            rerenderkey: 1,
            showGraph: false,
            showBar: false,
            naamLijst: [],
            plaatsLijst: [],
            toonLijst: [],
            naam: '',
            plaats: '',
            graphMinTijd: '',
            graphMaxTijd: '',
            currentOptions: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        title: {
                            display: true,
                            text: this.$t('com_waarde', { ns: 'common' })
                        }
                    },
                    x: {
                        type: 'time',
                        time: {
                            unit: 'day',
                            unitStepSize: 10,
                            tooltipFormat: 'MM-DD HH:mm',
                            min: this.graphMinTijd,
                            max: this.graphMaxTijd
                        },
                        title: {
                            display: true,
                            text: this.$t('com_tijdstip', { ns: 'common' })
                        },
                        ticks: {
                            source: 'auto'
                        }
                    }
                }
            },
            cssClasses: '',
            styles: {},
            plugins: [{}],
            width: 600,
            height: 400,
            currentData: { labels: [], datasets: [] },
            currentChartId: 'Line',
            currentDatasetIdKey: 'label',
            backgroundColorArray: [
                '#E6BB7A',
                '#ADE68A',
                '#E68473',
                '#5CAAE6',
                '#E267E6',
                '#41B883',
                '#E46651',
                '#00D8FF',
                '#DD1B16'
            ]
        };
    },
    model: {
        prop: 'visible',
        event: 'changed'
    },
    props: {
        graphData: {
            type: Array,
            required: true
        },
        van: {
            type: String,
            required: true
        },
        tot: {
            type: String,
            required: true
        },
        isassesment: {
            type: Boolean,
            required: true
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    components: {
        LineChartGenerator,
        Bar
    },
    watch: {},
    computed: {
        formtitle() {
            const low = Helper.stringformat(
                'Data periode: {0} tot {1}',
                this.van,
                this.tot
            );
            return low;
        }
    },
    methods: {
        filterData(value) {
            const filteredLijst = this.graphData.filter((x) => x.naam == value);
            const lijst = Helper.distinctList(filteredLijst, 'meetplekNaam');
            this.plaatsLijst = lijst.map((x) => ({
                text: x.meetplekNaam,
                value: x.id
            }));
            this.plaatsID = lijst.length > 0 ? lijst[0].id : -1;
        },
        AddToDisplayList(value) {
            const o = this.graphData.find((x) => x.id == value);
            // todo groupby en check  toegevoegd
            this.toonLijst.push(o);
            const ds = this.makeDataSetLine(o);
            this.showGraph = !this.isassesment;
            this.showBar = this.isassesment;
        },
        displayItem(id) {
            const o = this.graphData.find((x) => x.id == id);
            const naam = Helper.stringformat(
                '{0} : {1}',
                o.naam,
                o.meetplekNaam
            );
            return naam;
        },
        displayNumber(id) {
            const obj = this.graphData.find((x) => x.id == id);
            const data = this.graphData.filter(
                (x) =>
                    x.onderzoeksgrootheidNaam == obj.onderzoeksgrootheidNaam &&
                    x.meetplekNaam == obj.meetplekNaam
            );
            return data.length;
        },
        verwijderItem(id) {
            const o = this.graphData.find((x) => x.id == id);
            const naam = Helper.stringformat(
                '{0} : {1}',
                o.naam,
                o.meetplekNaam
            );
            for (let i = this.currentData.datasets.length - 1; i >= 0; i--) {
                if (naam == this.currentData.datasets[i].label) {
                    this.currentData.datasets.splice(i, 1);
                }
            }
            for (let i = this.toonLijst.length - 1; i >= 0; i--) {
                if (id == this.toonLijst[i].id) {
                    this.toonLijst.splice(i, 1);
                }
            }
            this.currentData = {
                ...this.currentData,
                datasets: this.currentData.datasets
            };
            this.showGraph = !this.isassesment;
            this.showBar = this.isassesment;
        },
        makeDataSetLine(obj) {
            const data = this.graphData.filter(
                (x) =>
                    x.onderzoeksgrootheidNaam == obj.onderzoeksgrootheidNaam &&
                    x.meetplekNaam == obj.meetplekNaam
            );
            const label = Helper.stringformat(
                '{0} : {1}',
                obj.naam,
                obj.meetplekNaam
            );
            if (this.isassesment) {
                const lijst = _(data.slice(0))
                    .groupBy((x) => x.labelNaam)
                    .map((value, key) => {
                        const quantity = value.length;
                        return {
                            naam: key,
                            aantal: quantity,
                            items: value
                        };
                    })
                    .value();
                const color = this.getColor(this.toonLijst.length - 1);
                const ds = {
                    data: lijst.map((x) => x.aantal),
                    label: label,
                    backgroundColor: color,
                    stack: 'stack 1'
                };
                this.currentData.datasets.push(ds);
                this.currentOptions = {
                    ...this.currentOptions,
                    scales: {
                        y: {
                            stacked: true,
                            title: {
                                display: true,
                                text: this.$t('com_waarde', { ns: 'common' })
                            }
                        },
                        x: {
                            stacked: true
                        }
                    }
                };
                this.currentData = {
                    ...this.currentData,
                    labels: this.currentData.labels.concat(
                        lijst.map((x) => x.naam)
                    )
                };
            } else {
                const waardexy = data.map((x) => ({
                    x: x.meettijdstip,
                    y: x.waarde
                }));
                const color = this.getColor(this.toonLijst.length - 1);
                const ds = {
                    data: waardexy,
                    label: label,
                    lineTension: 0,
                    fill: false,
                    borderColor: color
                };
                this.currentData.datasets.push(ds);
            }
            this.currentData = {
                ...this.currentData,
                datasets: this.currentData.datasets
            };
            return this.currentData;
        },
        getColor(nummer) {
            const a = nummer > 8 ? nummer % 9 : nummer;
            return this.backgroundColorArray[a];
        },
        hide() {
            this.$emit('hide', this.returnObject);
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        async handleSubmit() {
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-graph');
            });
        }
    },
    async mounted() {},
    created() {
        if (this.graphData.length > 0) {
            const lijst = Helper.distinctList(this.graphData, 'naam');
            this.naamLijst = lijst.map((x) => x.naam);
        }
        this.graphMinTijd = moment(this.van);
        this.graphMaxTijd = moment(this.tot);
    }
};
</script>

<style scoped></style>

