<template>
    <b-modal
        id="modal-itemrecipe"
        size="xl"
        ref="modal"
        :title="this.formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
        @change="change"
    >
        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
            {{ alertText }}
        </b-alert>
        <recipe-card
            @submit.stop.prevent="handleSubmit"
            v-model="localItem"
            v-bind:editAllowed="allowEdit"
            v-bind:closedRecipesList="null"
            v-bind:kaasNaam="kaasNaam"
        >
        </recipe-card>
    </b-modal>
</template>

<script>
import RecipeCard from './RecipeCard';
import { loadItem } from '../../models/DataHelper';

export default {
    name: 'RecipeModal',
    data: function () {
        return {
            showDismissibleAlert: false,
            alertText: '',
            perUnitsId: 0,
            melkId: 0,
            localNaam: '',
            opmerking: '',
            lokaalItem: null,
            showkaas: false,
            showkaasmerk: false,
            showmelk: false,
            allowEdit: true
        };
    },
    model: {
        prop: 'visible',
        event: 'change'
    },
    props: {
        inItem: {
            type: Object,
            default: null
        },
        visible: {
            type: Boolean,
            default: false
        },
        kaasNaam: {
            type: String
        }
    },
    components: {
        RecipeCard
    },
    watch: {},
    computed: {
        availableGroups() {
            return this.$store.getters['account/getGroepen'];
        },
        formtitle() {
            if (this.inItem == undefined) return '??';
            if (this.inItem.productID == undefined || this.inItem.productID < 0)
                return  this.$t('com_new', { ns: 'common' }) + ' '+  this.$t('com_recipe', { ns: 'common' });
            return this.inItem.kaasnaam ?? this.$t('com_next', { ns: 'common' }) + ' '+  this.$t('com_recipe', { ns: 'common' });
        },
        localItem() {
            if (this.inItem == undefined) return null; // kan niet
            if (this.inItem.productID == undefined) {
                //?
            }
            return this.inItem;
        }
    },
    methods: {
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        hide() {
            this.opmerking = '';
            this.$emit('hide');
        },
        change(visible) {
            this.setChanged();
            this.$emit('change', visible);
        },
        setChanged() {
            this.localItem.isChanged = true;
        },
        checkFormValidity() {
            const invalidBasis = this.localItem.CheckValidity(this.$t);
            if (invalidBasis) this.showAlert(invalidBasis);
            const invalidNorm = this.localItem.recNorm.CheckValidity(this.$t);
            if (invalidNorm) this.showAlert(invalidNorm);
            const invalidComp = this.localItem.recComposition.CheckValidity(this.$t);
            if (invalidComp) this.showAlert(invalidComp);
            const invalidProd = this.localItem.recProduction.CheckValidity(this.$t);
            if (invalidProd) this.showAlert(invalidProd);
            let invalidRec = '';
            for (let i = 0; i < this.localItem.recIngredienten.length; i++) {
                let localAlert = '';
                localAlert = this.localItem.recIngredienten[i].CheckValidity(this.$t);
                if (localAlert) {
                    this.showAlert(localAlert);
                    invalidRec += localAlert;
                }
            }
            const valid =
                this.localItem.recIngredienten.length > 0 &&
                !invalidBasis &&
                !invalidNorm &&
                !invalidComp &&
                !invalidProd &&
                !invalidRec;
            return valid;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return;
            }
            this.saveThisRecept();
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-itemrecipe');
            });
        },
        async saveThisRecept() {
            this.localItem.isChanged = true;
            const saveRecipe = this.localItem.SetForSave();
            if (this.localItem.isNew) {
                // moet opnieuw ophalen ivm data in combo, gaat dit goed op deze plek??
                loadItem(
                    'components/emptyCheesesWithoutRecipeComboLijst',
                    []
                );
            }
            loadItem('recipe/saveRecipe', saveRecipe, this.$t);
        }
    },
    mounted() {},
    created() {}
};
</script>

<style scoped></style>
