import { render, staticRenderFns } from "./PlusProductionCombo.vue?vue&type=template&id=cea683fa&scoped=true"
import script from "./PlusProductionCombo.vue?vue&type=script&lang=ts"
export * from "./PlusProductionCombo.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cea683fa",
  null
  
)

export default component.exports