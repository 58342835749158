
const state = {
    unitsComboList: [],
    simpleProductList: [],
    simpleRecipeList: [],
    simpleProductionList: [],
    productionValueList: []
};

export default state;
