var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticStyle:{"margin-top":"60px"},attrs:{"fluid":""}},[_c('h1',[_vm._v(_vm._s(_vm.formtitle))]),_c('b-row',[_c('b-col',{attrs:{"sm":"8"}},[_c('period-selector',{attrs:{"periodDays":_vm.periodeDagen,"slackTillDate":_vm.slackDagen},on:{"change":_vm.setNewDates},model:{value:(_vm.selectDays),callback:function ($$v) {_vm.selectDays=$$v},expression:"selectDays"}})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"11"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"bgc_aux_normal",attrs:{"title":_vm.$t('com_showdata', { ns: 'common' })},on:{"click":function($event){return _vm.loadData()}}},[_c('font-awesome-icon',{attrs:{"icon":"arrow-down","size":"1x"}})],1)],1),_c('b-col',{attrs:{"sm":"1"}})],1)],1),_c('b-col',{attrs:{"sm":"1"}},[_c('b-row',{staticClass:"float-right"},[_c('b-col',{attrs:{"sm":"11"}}),_c('b-col',{attrs:{"sm":"1"}})],1)],1)],1),_c('div',{attrs:{"slot":"pdfdata"},slot:"pdfdata"},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"sm":"12"}},[_c('b-col',{staticClass:"my-1",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":_vm.$t('com_filter', { ns: 'common' }),"label-for":"filter-input","label-cols-sm":"3","label-align-sm":"right","label-size":"sm"}},[_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-form-input',{attrs:{"id":"filter-input","type":"search","placeholder":_vm.$t('com_typetosearch', { ns: 'common' })},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.filter},on:{"click":function($event){_vm.filter = ''}}},[_vm._v(_vm._s(_vm.$t('com_wis', { ns: 'common' })))])],1)],1),_c('b-dropdown',{ref:"dropdown",attrs:{"id":"dropdown-form","text":_vm.$t('com_newx', {
                                    ns: 'common',
                                    val: _vm.$t('com_actie', { ns: 'common' })
                                }),"size":"sm"}},[_c('b-dropdown-form',{staticStyle:{"width":"340px"}},[_c('b-form-group',{attrs:{"label":_vm.$t('prod_voorobject', {
                                            ns: 'production',
                                            val: _vm.$t('prod_object', {
                                                ns: 'production'
                                            }).toLowerCase()
                                        }),"label-for":"dropdown-form"},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('b-form-radio-group',{attrs:{"options":_vm.optionsRadio,"value-field":"item","text-field":"name"},model:{value:(_vm.selectedRadio),callback:function ($$v) {_vm.selectedRadio=$$v},expression:"selectedRadio"}})],1),(
                                        _vm.checkRight(
                                            'MaintenanceCleaningCreate'
                                        )
                                    )?_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm"},on:{"click":_vm.createNewItem}},[_c('font-awesome-icon',{attrs:{"icon":"folder-plus","size":"1x"}}),_vm._v(" "+_vm._s(_vm.$t('prod_toevoegen', { ns: 'production' }))+" ")],1):_vm._e()],1)],1)],1)],1),_c('b-col',{staticClass:"my-1",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":_vm.$t('com_perpagina', { ns: 'common' }),"label-for":"per-page-select","label-cols-sm":"6","label-cols-md":"4","label-cols-lg":"3","label-align-sm":"right","label-size":"sm"}},[_c('b-form-select',{attrs:{"id":"per-page-select","options":_vm.pageOptions,"size":"sm"},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"align":"fill","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1),_c('b-table',{attrs:{"items":_vm.groupedDisplayList,"fields":_vm.visibleFields,"current-page":_vm.currentPage,"per-page":_vm.perPage,"filter":_vm.filter,"filter-included-fields":_vm.filterOn,"stacked":"md","show-empty":"","empty-text":_vm.$t('com_geen_data', { ns: 'common' }),"small":"","striped":""},on:{"filtered":_vm.onFiltered,"row-clicked":_vm.toonDetail},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(row){return [_c('div',{staticClass:"form-inline"},[_c('b-button',{staticClass:"bgc_aux_warning",attrs:{"size":"sm"},on:{"click":function($event){return _vm.toonItem(row.item)}}},[_c('font-awesome-icon',{attrs:{"icon":"search","size":"1x"}})],1),(
                                    _vm.checkRight('MaintenanceCleaningDelete')
                                )?_c('b-button',{staticClass:"bgc_aux_alarm",attrs:{"size":"sm"},on:{"click":function($event){return _vm.verwijderItem(row.item)}}},[_c('font-awesome-icon',{attrs:{"icon":"trash-alt","size":"1x"}})],1):_vm._e(),_c('b-button',{staticClass:"bgc_aux_unknown",attrs:{"size":"sm"},on:{"click":function($event){return _vm.toonDetail(row.item)}}},[_c('font-awesome-icon',{attrs:{"icon":"info-circle","size":"1x"}})],1)],1)]}},{key:"cell(herhalingIDs)",fn:function(row){return [(row.item.actief > 0)?_c('font-awesome-icon',{staticClass:"tc_aux_checked",attrs:{"icon":"check","size":"1x"}}):_vm._e(),(row.item.actief == 0 && row.item.herhalingIDs > 0)?_c('font-awesome-icon',{staticClass:"tc_aux_unimportant",attrs:{"icon":"check","size":"1x"}}):_vm._e()]}},{key:"row-details",fn:function(){return [_c('b-card',{staticClass:"mb-1",attrs:{"no-body":""}},[_vm._v("   "),_c('b-table',{attrs:{"striped":"","responsive":"","fields":_vm.detailFields,"items":_vm.localActivities,"primary-key":""}})],1)]},proxy:true}])}),(_vm.mLijst.length == 0)?_c('div',[_vm._v(" "+_vm._s(_vm.strNoData)+" ")]):_vm._e()],1)],1)],1),(_vm.detailsVisible)?_c('maintenance-pop-form',{attrs:{"displayNumber":_vm.selectedRadio,"editAllowed":_vm.allowEdit,"inItem":_vm.reiniging},on:{"hide":_vm.reset,"reiniging_refresh":_vm.loadData},model:{value:(_vm.detailsVisible),callback:function ($$v) {_vm.detailsVisible=$$v},expression:"detailsVisible"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }