import { api } from '../api';
import { showBadToast, showGoodToast } from '../../errors/toast';
import {
    TreatmentRecipe,
    TreatmentRecipeView,
    TreatmentActivity,
    TreatmentStep,
    TacStatusView
} from '../../../models/CheeseTreatment';
import logMessage from '../../errors/foutlog';

const actions = {
    //#region TreatmentRecipe
    async loadTreatmentRecipe(context, data) {
        try {
            const response = await api.fetchTreatmentRecipe(data.data);
            const item = new TreatmentRecipe(response.data);
            return item;
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_recipe', { ns: 'common' })
                }),
                error
            );
            logMessage(error, 'error');
        }
    },
    async loadTreatmentRecipes(context, data) {
        let tempList = [];
        try {
            await api.fetchTreatmentRecipes(data.data).then((response) => {
                tempList = response.data.Items.map(
                    (x) => new TreatmentRecipeView(x)
                );
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_recipes', { ns: 'common' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertTreatmentRecipeLijst', tempList);
        return tempList;
    },
    async loadTreatmentSteps(context, data) {
        let tempList = [];
        try {
            await api.fetchTreatmentSteps(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new TreatmentStep(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('prod_treatmentsteps', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertTreatmentStepLijst', tempList);
        return tempList;
    },

    // DateTime from, DateTime until, string filterfields, string filtervalues, string filtertypes
    async loadPlannedTreatments(context, data) {
        let tempList = [];
        try {
            await api.fetchPlannedTreatments(data.data).then((response) => {
                tempList = response.data.Items.map(
                    (x) => new TreatmentActivity(x)
                );
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_treatments', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertTACLijst', tempList);
        return tempList;
    },
    async loadTreatmentStatus(context, data) {
        let tempList = [];
        try {
            await api.fetchTreatmentStatus(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new TacStatusView(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_status', { ns: 'common' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertTACStatusLijst', tempList);
        return tempList;
    },
    async loadTreatmentWithoutSchema(context, data) {
        let tempList = [];
        try {
            await api.fetchCheeseWithoutSchema(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new TacStatusView(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_beeindigd', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertUitbehandeldLijst', tempList);
        return tempList;
    },
    async loadSchemasEnded(context, data) {
        let tempList = [];
        try {
            await api.fetchCheeseSchemaEnded(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new TacStatusView(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_treatments', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return tempList;
    },

    // data= ConfirmTreatmentActivityModel
    async confirmTreatment(context, data) {
        let item = null;
        try {
            const response = await api.confirmTreatment(data.data);
            item = new TreatmentActivity(response.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('prod_treatment', { ns: 'production' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_treatment', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return item;
    },
    // int treatmentActivityID
    async loadTreatment(context, data) {
        let item = null;
        try {
            const response = await api.fetchTreatmentActivity(data.data);
            item = new TreatmentActivity(response.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_treatment', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return item;
    },

    // data= int stockItemID, int treatmentRecipeID, DateTime startAt, int startStepNumber
    async assignSchema(context, data) {
        try {
            const response = await api.assignSchema(data.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('prod_treatmentschema', { ns: 'production' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_treatmentschema', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
    },
    //TreatmentModel
    async storeTreatmentCoatingProduct(context, data) {
        let item = null;
        try {
            const response = await api.storeTreatmentCoatingProduct(data.data);
            item = new TreatmentStep(response.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('prod_coating', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return item;
    },

    //TreatmentModel
    async storeTreatmentCoating(context, data) {
        let item = null;
        try {
            const response = await api.storeTreatmentCoating(data.data);
            item = new TreatmentStep(response.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('prod_coating', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return item;
    },

    async storeTreatmentRecipe(context, data) {
        let item = null;
        try {
            const response = await api.storeTreatmentRecipe(data.data);
            item = new TreatmentRecipe(response.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('prod_treatmentschema', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return item;
    }

    //#endregion TreatmentRecipe
};

export default actions;

