<template>
    <b-modal
        id="modal-masterdata"
        size="lg"
        ref="modal"
        :title="formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
    >
        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
            {{ alertText }}
        </b-alert>
        <card-vorm
            v-if="mVorm === displayNumber"
            @submit.stop.prevent="handleSubmit"
            v-model="localItem"
            v-bind:editAllowed="editAllowed"
            @change="setChanged"
        />
        <card-ost
            v-if="mOst === displayNumber"
            @submit.stop.prevent="handleSubmit"
            v-model="localItem"
            v-bind:editAllowed="editAllowed"
            @change="setChanged"
        />
        <card-location
            v-if="mLocation === displayNumber"
            @submit.stop.prevent="handleSubmit"
            v-model="localItem"
            v-bind:editAllowed="editAllowed"
            @change="setChanged"
        />
        <card-equipment
            v-if="mEquipment === displayNumber"
            @submit.stop.prevent="handleSubmit"
            v-model="localItem"
            v-bind:editAllowed="editAllowed"
            @change="setChanged"
        />
        <card-company
            v-if="mCompany === displayNumber"
            @submit.stop.prevent="handleSubmit"
            v-model="localItem"
            v-bind:editAllowed="editAllowed"
            @change="setChanged"
        />
        <card-report-groups
            v-if="mReportGroup === displayNumber"
            @submit.stop.prevent="handleSubmit"
            v-model="localItem"
            v-bind:editAllowed="editAllowed"
            @change="setChanged"
        />
    </b-modal>
</template>

<script>
import CardVorm from './CardVorm.vue';
import CardOst from './CardOst.vue';
import CardLocation from './CardLocation.vue';
import CardCompany from './CardCompany.vue';
import CardEquipment from './CardEquipment.vue';
import CardReportGroups from './CardReportGroups.vue';
import { loadMasterData } from './loaddata';
import { loadItem } from '../../models/DataHelper';
export default {
    name: 'MasterDataModal',
    data: function () {
        return {
            showDismissibleAlert: false,
            alertText: '',
            isChanged: false,
            mVorm: window.constants.MDATA_VORM,
            mOst: window.constants.MDATA_OST,
            mLocation: window.constants.MDATA_LOCATION,
            mEquipment: window.constants.MDATA_EQUIPMENT,
            mCompany: window.constants.MDATA_COMPANY,
            mReportGroup: window.constants.MDATA_REPORTGROUP,
            objectVorm: null,
            objectOst: null,
            objectLocation: null,
            objectCompany: null,
            objectEquipment: null,
            objectReportGroup: null
        };
    },
    model: {
        prop: 'visible',
        event: 'changed'
    },
    props: {
        inItem: {
            type: Object,
            required: true
        },
        displayNumber: {
            type: String,
            required: true
        },
        visible: {
            type: Boolean,
            default: false
        },
        editAllowed: {
            type: Boolean,
            default: false
        }
    },
    components: {
        CardVorm,
        CardOst,
        CardLocation,
        CardCompany,
        CardEquipment,
        CardReportGroups
    },
    watch: {},
    computed: {
        formtitle() {
            return this.inItem.isNew
                ? this.$t('com_makenew', {
                      ns: 'common',
                      val: this.inItem.type.toLowerCase()
                  })
                : this.$t('com_edit', {
                      ns: 'common',
                      val: this.inItem.type.toLowerCase()
                  });
        },
        localItem() {
            switch (this.displayNumber) {
                case window.constants.MDATA_VORM:
                    return this.objectVorm;
                case window.constants.MDATA_OST:
                    return this.objectOst;
                case window.constants.MDATA_LOCATION:
                    return this.objectLocation;
                case window.constants.MDATA_COMPANY:
                    return this.objectCompany;
                case window.constants.MDATA_EQUIPMENT:
                    return this.objectEquipment;
                case window.constants.MDATA_REPORTGROUP:
                    return this.objectReportGroup;
                default: {
                    return [];
                }
            }
        },
        magEdit() {
            return this.editAllowed;
        }
    },
    methods: {
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        hide() {
            this.opmerking = '';
            this.$emit('hide');
        },
        setChanged() {
            this.isChanged = true;
        },
        checkFormValidity() {
            const invalidBasis = this.localItem.CheckValidity(this.$t);
            if (invalidBasis) this.showAlert(invalidBasis);
            const valid = this.localItem != undefined && !invalidBasis;
            return valid;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (this.isChanged) {
                if (!this.checkFormValidity()) {
                    return;
                }
                this.saveThisProduct();
            }
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-masterdata');
            });
        },
        async saveThisProduct() {
            this.localItem.SetForSave();

            switch (this.displayNumber) {
                case window.constants.MDATA_VORM: {
                    await loadItem(
                        'masterdata/addShape',
                        this.localItem.SetForUpdate(),
                        this.$t
                    );
                    break;
                }
                case window.constants.MDATA_OST: {
                    await loadItem(
                        'masterdata/addOst',
                        this.localItem.SetForUpdate(),
                        this.$t
                    );
                    break;
                }
                case window.constants.MDATA_LOCATION: {
                    await loadItem(
                        'masterdata/addLocation',
                        this.localItem.SetForUpdate(),
                        this.$t
                    );
                    break;
                }
                case window.constants.MDATA_COMPANY: {
                    await loadItem(
                        'masterdata/addCompany',
                        this.localItem.SetForUpdate(),
                        this.$t
                    );
                    break;
                }
                case window.constants.MDATA_EQUIPMENT: {
                    await loadItem(
                        'masterdata/addEquipment',
                        this.localItem.SetForUpdate(),
                        this.$t
                    );
                    break;
                }
                case window.constants.MDATA_REPORTGROUP: {
                    await loadItem(
                        'masterdata/addReportGroup',
                        this.localItem.SetForUpdate(),
                        this.$t
                    );
                    break;
                }
                default: {
                    return [];
                }
            }
            await loadMasterData(this.displayNumber, this.$t);
        }
    },
    mounted() {
        switch (this.displayNumber) {
            case window.constants.MDATA_VORM: {
                this.objectVorm = this.inItem;
                break;
            }
            case window.constants.MDATA_OST: {
                this.objectOst = this.inItem;
                break;
            }
            case window.constants.MDATA_LOCATION: {
                this.objectLocation = this.inItem;
                break;
            }
            case window.constants.MDATA_COMPANY: {
                this.objectCompany = this.inItem;
                break;
            }
            case window.constants.MDATA_EQUIPMENT: {
                this.objectEquipment = this.inItem;
                break;
            }
            case window.constants.MDATA_REPORTGROUP: {
                this.objectReportGroup = this.inItem;
                break;
            }
            default: {
                return [];
            }
        }
    },
    created() {}
};
</script>

<style scoped></style>

