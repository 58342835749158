import jwt_decode from 'jwt-decode';
import Operator from '../../../models/Operator';
import { Permission } from '../../../models/Permissions';
import { api } from '../api';
import logMessage from '../../errors/foutlog';
import { showBadToast, showGoodToast } from '../../errors/toast';

const actions = {
    validateToken() {
        const token = this.state.account.jwtToken;
        if (token) {
            try {
                const decoded = jwt_decode(token);
                const exp = decoded.exp;
                if (exp - Date.now() / 1000 >= 0) {
                    // Token is valid
                } else {
                    this.commit('account/setJWTToken', null);
                }
            } catch (error) {
                logMessage(error, 'error');
                this.commit('account/setJWTToken', null);
            }
        }
    },
    async loadUser(context, manager) {
        const user = null;
        try {
            const user = await manager.getUser();
            this.commit('account/setAuthUser', user);
        } catch (error) {
            logMessage(error, 'error');
            showBadToast(context, 'Melding authenticatie user', error);
        }
        return user;
    },
    async loadUserManager(context, manager) {
        const user = null;
        try {
            this.commit('account/setUserManager', manager);
        } catch (error) {
            showBadToast(context, 'Melding authenticatie usermanager', error);
        }
        return user;
    },
    // async resetPassword(context, data) {
    //     data.Token = this.state.account.jwtToken;
    //     try {
    //         // eslint-disable-next-line no-unused-vars
    //         const response = await api.resetPassword(data);
    //         showGoodToast(context, 'Wachtwoord gewijzigd');
    //     } catch (error) {
    //         logMessage(error, 'error');
    //         showBadToast(context, 'Melding wijzigen wachtwoord', error);
    //     }
    // },
    async reloadLoggedInUser(context, data) {
        let loggedInUser = null;
        let featurelist = [];
        try {
            const response = await api.fetchUser(data.data);
            loggedInUser = new Operator(response.data, data.func);
            featurelist = response.data.features;
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('com_user', { ns: 'common' })
                }),
                error
            );
            logMessage(error, 'error');
            loggedInUser = null;
        }
        this.commit('account/setUser', loggedInUser);
        this.commit('account/setFeaturesLijst', featurelist);
    },
    async loadUserInGroups(context, data) {
        // zonder data dan krijg je alles
        let tempList = [];
        try {
            await api.fetchUserInGroups(data.data).then((response) => {
                tempList = response.data.Items.map((x) => ({
                    userid: x.id,
                    username: x.username,
                    groupname: x.groupname
                }));
            });
        } catch (error) {
            logMessage(error, 'error');
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_groepen', { ns: 'common' })
                }),
                error
            );
        }
        this.commit('account/setUsersAndGroup', tempList);
        return tempList;
    },
    async loadPermissions(context, data) {
        let tempList = [];
        try {
            await api.fetchPermissions(data.data).then((response) => {
                tempList = response.data.map((x) => new Permission(x, data.func));
            });
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_permissies', { ns: 'common' })
                }),
                error
            );
        }
        this.commit('account/setPermissionsLijst', tempList);
    },
    async loadPackagePermissions(context, data) {
        let tempList = [];
        try {
            await api.fetchPackagePermissions(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new Permission(x, data.func));
            });
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_permissies', { ns: 'common' })
                }),
                error
            );
        }
        this.commit('account/setPackagePermissionsLijst', tempList);
    },
    async loadGroupPermissions(context, data) {
        let tempList = [];
        try {
            await api.fetchGroupPermissions(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new Permission(x, data.func));
            });
        } catch (error) {
            logMessage(error, 'error');
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_permissies', { ns: 'common' })
                }),
                error
            );
        }
        this.commit('account/setGroupPermissionsLijst', tempList);
    },
    async loadGroups(context, data) {
        let tempList = [];
        try {
            await api.fetchGroups(data.data).then((response) => {
                tempList = response.data.Items.map((x) => ({
                    value: x.id,
                    text: x.description
                }));
            });
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_groepen', { ns: 'common' })
                }),
                error
            );
        }
        this.commit('account/setGroupsLijst', tempList);
        return tempList;
    },
    async loadOperators(context, data) {
        let tempList = [];
        try {
            await api.fetchOperators(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new Operator(x));
                // tempList = response.data.Items.map((x) => ({
                //     value: x.id,
                //     text: x.description
                // }));
            });
        } catch (error) {
            logMessage(error, 'error');
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_operators', { ns: 'common' })
                }),
                error
            );
        }
        this.commit('account/setOperatorsLijst', tempList);
        return tempList;
    },
    // eslint-disable-next-line no-unused-vars
    async loadUserLicenses(context, data) {
        const amount = 2;
        // TODO
        // try {
        //     const response = await api.fetchUserLicenseAmount(data);
        //     amount = response.data.amount;
        // } catch (error) {
        //     showBadToast(context, 'Melding ophalen licenties', error);
        // }
        return amount;
    }
};

export default actions;

