<template>
    <b-container fluid style="margin-top: 60px">
        <div class="form-inline">
            <h1 v-on:click="reloadData">
                {{ formTitle }} &nbsp;
                <b-button
                    class="bgc_aux_normal"
                    v-b-tooltip.hover
                    :title="$t('com_showdata', { ns: 'common' })"
                    ><font-awesome-icon icon="arrow-down" size="1x" />
                </b-button>
            </h1>
        </div>
        <b-row>
            <b-col sm="12">
                <div v-if="showFilter">
                    <b-row no-gutters>
                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_filter', { ns: 'common' })"
                                label-for="filter-input"
                                label-cols-sm="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-input-group size="sm">
                                    <b-form-input
                                        id="filter-input"
                                        v-model="filter"
                                        type="search"
                                        :placeholder="
                                            $t('com_typetosearch', {
                                                ns: 'common'
                                            })
                                        "
                                    ></b-form-input>

                                    <b-input-group-append>
                                        <b-button
                                            :disabled="!filter"
                                            @click="filter = ''"
                                            >{{
                                                $t('com_wis', { ns: 'common' })
                                            }}</b-button
                                        >
                                    </b-input-group-append>
                                </b-input-group>

                                <!--                                 <b-input-group size="sm">
                                    <b-button
                                        v-if="
                                            !treated &&
                                            checkRight('DeliveryCreate')
                                        "
                                        size="sm"
                                        @click="createNewItem"
                                        class="mr-1"
                                        ><font-awesome-icon
                                            icon="folder-plus"
                                            size="1x"
                                        />
                                        Extra behandeling
                                    </b-button>
                                </b-input-group> -->
                            </b-form-group>
                        </b-col>

                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_perpagina', { ns: 'common' })"
                                label-for="per-page-select"
                                label-cols-sm="6"
                                label-cols-md="4"
                                label-cols-lg="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-form-select
                                    id="per-page-select"
                                    v-model="perPage"
                                    :options="pageOptions"
                                    size="sm"
                                ></b-form-select>
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                ></b-pagination>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div v-else>
                    <!--         <b-input-group>
                        <b-button
                            v-if="!treated && checkRight('DeliveryCreate')"
                            size="sm"
                            @click="createNewItem"
                            class="mr-1"
                            ><font-awesome-icon icon="folder-plus" size="1x" />
                                        Extra behandeling
                        </b-button>
                    </b-input-group> -->
                </div>

                <b-table
                    :items="displayList"
                    :fields="visibleFields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    stacked="md"
                    show-empty
                    :empty-text="$t('com_geen_data', { ns: 'common' })"
                    small
                    striped
                    @filtered="onFiltered"
                >
                    <template v-slot:cell(actions)="row">
                        <b-button
                            v-if="checkRight('CheeseTreatmentCreate')"
                            size="sm"
                            :disabled="!checkIsCoating(row.item)"
                            @click="addItem(row.item)"
                            class="bgc_aux_normal"
                            ><font-awesome-icon icon="edit" size="1x" />
                        </b-button>
                        <b-button
                            v-if="checkRight('CheeseTreatmentUpdate')"
                            :disabled="!checkIsCoating(row.item)"
                            size="sm"
                            @click="editItem(row.item)"
                            class="bgc_aux_warning"
                            ><font-awesome-icon icon="info-circle" size="1x" />
                        </b-button>
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <treatment-step-modal
            v-if="detailsVisible"
            v-model="detailsVisible"
            :treatmentStepId="currentID"
            :treatmentStep="currentObject"
            :treatmentStepList="stepsList"
            @hide="reset"
            @change="applyChange"
        />
    </b-container>
</template>

<script lang="ts">
import _ from 'lodash';
import TreatmentStepModal from './TreatmentStepModal.vue';
import { defineComponent } from 'vue';
import { loadList, loadItem } from '../../models/DataHelper';
import { TreatmentStep } from '@/models/CheeseTreatment';
import { Permission } from '../../models/Permissions';
import { VueExtension } from '../../models/VueExtension';

export default defineComponent({
    name: 'TreatmentStepsBase',
    data: function () {
        return {
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [10, 20, 40, { value: 100, text: '100' }],
            filter: null,
            filterOn: [],
            recipeID: -1,
            batchnummer: '',
            currentID: -1,
            currentObject: {} as TreatmentStep | null,
            detailsVisible: false,
            displayFilterCount: 20,
            aangeraakt: 0,

            showDetails: false,
            van: null,
            tot: null,
            selectDays: null,
            periodeDagen: 1,
            editDagen: -4,
            forceRefresh: false,
            stepsList: [] as Array<TreatmentStep>,
            strNotInList: this.$t('prod_nietinlijst', {
                ns: 'production',
                val: this.$t('com_batch', { ns: 'common' })
            }),
            formTitle: this.$t('prod_treatments', { ns: 'production' })
        };
    },
    props: {},
    components: {
        TreatmentStepModal
    },
    computed: {
        showFilter(): boolean {
            return this.displayList
                ? this.displayList.length > this.displayFilterCount
                : false;
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'id', sortable: false, visible: false },
                {
                    key: 'naam',
                    label: this.$t('prod_treatment', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'product',
                    label: this.$t('com_product', { ns: 'common' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        displayList() {
            return this.stepsList;
        },
        allowEdit() {
            // todo: wat worden de regels hiervoor?
            const editDate = new Date();
            editDate.setDate(editDate.getDate() - this.editDagen);
            const tempDate = new Date(editDate).toISOString().substring(0, 10);
            //const magNog = this.van ? tempDate < this.van : true;
            return true;
        }
    },
    methods: {
        addItem(item) {
            this.currentID = -1;
            this.currentObject = item;
            this.detailsVisible = true;
        },
        editItem(item) {
            this.currentID = item ? parseInt(item.id) : -1;
            this.currentObject = item;
            this.detailsVisible = true;
        },
        async applyChange(item) {
            if (item) {
                const selectionData = item.SetForConfirm();
                if (item.id < 0) {
                    const response = await loadItem(
                        'treatment/storeTreatmentCoating',
                        selectionData,
                        this.$t
                    );
                } else {
                    const response = await loadItem(
                        'treatment/storeTreatmentCoatingProduct',
                        selectionData,
                        this.$t
                    );
                }
                this.forceRefresh = true;
                this.reset();
                this.reloadData();
            }
        },
        reset() {
            this.currentID = -1;
            this.currentObject = null;
            this.detailsVisible = false;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async reloadData() {
            const tempList = await loadList(
                'treatment/getTreatmentSteps',
                'treatment/loadTreatmentSteps',
                null,
                this.forceRefresh,
                this.$t
            );
            this.totalRows = tempList ? tempList.length : 0;
            this.stepsList = tempList;
            this.forceRefresh = false;
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return (this.$root as VueExtension).handleRight(id);
        },
        checkIsCoating(row) {
            if (row) {
                // return row.productID == undefined ? false : true; //systeem data
                return (
                    row.id == window.constants.COATING_STEPID ||
                    row.id > window.constants.START_SEQ
                );
            }
            return false;
        }
    },
    mounted() {
        // Set the initial number of items
        this.reloadData();
        this.totalRows = this.displayList ? this.displayList.length : 0;
    },
    async created() {
        const lijst = await loadList(
            'masterdata/getConstantsLijst',
            'masterdata/loadApplicationConstants',
            null,
            this.forceRefresh,
            this.$t
        );
        const con = lijst
            ? lijst.find((x) => x.text == window.constants.APPCONST_EDITDAYS)
            : null;
        this.editDagen = con ? con.value : 30;
    }
});
</script>

<style scoped></style>

