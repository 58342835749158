<template>
    <div>
        <b-col sm="6" class="mx-0 px-0 my-0 py-0">
            <b-input-group class="mb-2">
                <b-input-group-prepend is-text>
                    <b-form-checkbox
                        class="mr-n2"
                        v-model="isnullable"
                        value="notnull"
                        unchecked-value="null"
                        @input="set_inputCB"
                        :disabled="!editAllowed"
                    >
                        <span class="sr-only">Niet</span>
                    </b-form-checkbox>
                    <b-form-checkbox
                        class="mr-n2"
                        v-model="status"
                        value="time"
                        unchecked-value="date"
                        @input="set_inputCB"
                        :disabled="!editAllowed"
                    >
                        <span class="sr-only">{{$t('com_met', { ns: 'common' }) + ' ' + $t('com_tijd', { ns: 'common' })}}</span>
                    </b-form-checkbox>
                </b-input-group-prepend>
                <b-form-datepicker
                    v-if="nullable"
                    :date-format-options="{
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit'
                    }"
                    v-model="datum"
                    @input="set_input"
                    :disabled="!editAllowed"
                ></b-form-datepicker>
            </b-input-group>
        </b-col>
        <b-col sm="6" v-if="metTijd" class="mx-0 px-0 my-0 py-0">
            <b-input-group class="mb-2" :prepend="$t('com_tijd', { ns: 'common' })">
                <b-form-timepicker
                    v-model="tijd"
                    locale="nl"
                    @input="set_input"
                    :disabled="!editAllowed"
                ></b-form-timepicker>
            </b-input-group>
        </b-col>
    </div>
</template>

<script>
import DateHelper from '../../models/DateHelper';
export default {
    name: 'AnalyticsDateTime',
    data: function () {
        return {
            status: 'time',
            isnullable: 'notnull',
            datum: null,
            tijd: null,
            isCloseable: false
        };
    },
    model: {
        prop: 'selected',
        event: 'input'
    },
    props: {
        selected: {},
        editAllowed: {
            type: Boolean,
            default: true
        },
        startNull: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        metTijd() {
            return this.status == 'time' && this.nullable;
        },
        nullable() {
            return this.isnullable == 'notnull';
        }
    },
    methods: {
        set_input() {
            const temptijd = this.maakTijd();
            this.$emit('change', temptijd);
        },
        set_inputCB() {
            const resDatum =
                this.isnullable == 'notnull' ? this.datum ?? new Date() : null;
            const resTijd =
                this.status == 'time'
                    ? this.tijd ?? DateHelper.convertedToTime(new Date())
                    : null;
            this.datum = resDatum;
            this.tijd = resTijd;
            this.set_input();
        },
        maakTijd() {
            let res = this.datum;
            if (this.metTijd) {
                res = DateHelper.convertedFromTimepicker(this.datum, this.tijd);
            }
            res = this.isnullable == 'notnull' ? res : null;
            return res;
        }
    },
    mounted() {},
    created() {
        this.datum = DateHelper.convertedToDateTimeString(this.selected);
        this.tijd = DateHelper.convertedToTime(this.selected);
        this.isnullable =
            this.selected == undefined
                ? 'null'
                : this.startNull
                ? 'null'
                : 'notnull';
        this.status = this.isnullable == 'null' ? 'date' : 'time';
        this.set_input();
    }
};
</script>

<style scoped></style>
