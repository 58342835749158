import DateHelper from './DateHelper';
import Helper from './Helper';
export {
    TreatmentRecipe,
    TreatmentRecipeStep,
    TreatmentWeekPlan,
    TreatmentStep,
    TreatmentRecipeView,
    CheeseTypeTreatmentRecipe,
    TreatmentActivity,
    TacStatusView
};

class CheeseTypeTreatmentRecipe {
    constructor(data) {
        this.id = -1;
        data = data ?? {};
        this.id = data.id ?? -1;
        this.isNew = this.id < 0;
        this.isDeleted = false;
        this.isChanged = false;
        this.gewijzigd = data.updated;
        this.vormID = data.shapeID;
        this.vorm = data.shape;
        this.behandelreceptID = data.treatmentRecipeID;
        this.behandelrecept = data.treatmentRecipe;
        this.default = data.isDefault;
    }

    SetForUpdate() {
        const CheeseTypeTreatmentRecipeModel = {
            ShapeID: this.vormID,
            Shape: this.vorm,
            TreatmentRecipeID: this.behandelreceptID,
            TreatmentRecipe: this.behandelrecept,
            IsDefault: this.default ?? false,
            Updated: this.gewijzigd
        };
        return CheeseTypeTreatmentRecipeModel;
    }
}

class TreatmentStep {
    constructor(data) {
        this.id = -1;
        data = data ?? {};
        this.id = data.id ?? -1;
        this.isNew = this.id < 0;
        this.isDeleted = false;
        this.isChanged = false;
        this.gewijzigd = data.updated;
        this.naam = data.description;
        this.product = data.coating;
        this.productID = data.pt_id;
        this.opmerking = data.remark;
        
    }
    Display() {
        return this.naam;
    }
    SetForConfirm() {
        const TreatmentModel = {
            ID: this.id,
            Name: this.naam,
            Remark: this.opmerking,
            CoatingProductID: this.productID
        };
        return TreatmentModel;
    }
}

class TreatmentRecipeStep {
    constructor(data) {
        this.id = -1;
        data = data ?? {};
        this.id = data.id ?? -1;
        this.isNew = this.id < 0;
        this.isDeleted = false;
        this.isChanged = false;
        this.gewijzigd = data.updated;
        this.wachttijd = data.afterStep;
        this.opmerking = data.remark;
        this.volgnummer = data.stepNumber;
        this.behandelstap = data.treatment;
        this.behandelstapID = data.treatmentStepID;

        this.dagnummer = null; // voor tonen
        this.dagnummerabsoluut = null; // voor tonen
        this.recept = true; // voor tonen
    }

    Display() {
        return this.behandelstap;
    }

    SetForSave() {
        const TreatmentStepModel = {
            ID:  this.id,
            TreatmentStepID: this.behandelstapID,
            StepNumber: this.volgnummer,
            AfterStep: this.wachttijd
        };
        return TreatmentStepModel;
    }
}

class TreatmentRecipe {
    constructor(data) {
        this.id = -1;
        data = data ?? {};
        this.id = data.id ?? -1;
        this.isNew = this.id < 0;
        this.isDeleted = false;
        this.isChanged = false;
        
        this.inGebruik = data.inUse;
        this.standaard = data.isDefault;
        this.naam = data.descriptivetext ?? data.naam;
        this.opmerking = data.remark ?? data.opmerking;
        this.stappen = data.steps
            ? data.steps.map((x) => new TreatmentRecipeStep(x))
            : [];

        this.weekplannen = []; // voor tonen
    }
    CheckValidity(func) {
        let warnText = '';
        if (this.wachttijd) {
            if (this.naam == undefined )
                warnText = Helper.concatString(
                    warnText,
                    func('com_isrequired', {
                        ns: 'common',
                        val: '',
                        prop: func('com_naam', { ns: 'common' })
                    })
                );
        }
        return warnText;
    }

    SetForSave() {
        const steps = [];
        for (let i = 0; i < this.stappen.length; i++) {
            const obj = this.stappen[i].SetForSave();
            steps.push(obj);
        }
        const StoreTreatmentRecipeModel = {
            ID: this.id,
            Descriptivetext: this.naam,
            IsDefault: this.standaard == "true" ? true : false,
            Remark: this.opmerking,
            Steps: steps
        };
        return StoreTreatmentRecipeModel;
    }
}

class TreatmentWeekPlan {
    constructor(data) {
        this.week = data.week;
        this.Day10 = null;
        this.Day15 = null;
        this.Day20 = null;
        this.Day25 = null;
        this.Day30 = null;
        this.Day35 = null;
        this.Day40 = null;
        this.Day45 = null;
        this.Day50 = null;
        this.Day55 = null;
        this.Day60 = null;
        this.Day65 = null;
        this.Day70 = null;
        this.Day75 = null;
    }

    Merge(data) {
        if (data == undefined || this.week != data.week) return null;
        this.week = data.week;
        this.Day10 = this.returnValue(this.Day10, data.Day10);
        this.Day15 = this.returnValue(this.Day15, data.Day15);
        this.Day20 = this.returnValue(this.Day20, data.Day20);
        this.Day25 = this.returnValue(this.Day25, data.Day25);
        this.Day30 = this.returnValue(this.Day30, data.Day30);
        this.Day35 = this.returnValue(this.Day35, data.Day35);
        this.Day40 = this.returnValue(this.Day40, data.Day40);
        this.Day45 = this.returnValue(this.Day45, data.Day45);
        this.Day50 = this.returnValue(this.Day50, data.Day50);
        this.Day55 = this.returnValue(this.Day55, data.Day55);
        this.Day60 = this.returnValue(this.Day60, data.Day60);
        this.Day65 = this.returnValue(this.Day65, data.Day65);
        this.Day70 = this.returnValue(this.Day70, data.Day70);
        this.Day75 = this.returnValue(this.Day75, data.Day75);
        return this;
    }

    returnValue(deze, data) {
        if (deze == undefined) {
            return data;
        } else {
            if (data == undefined) {
                return deze;
            } else {
                return [deze, data];
            }
        }
    }

    SetDay(dayNumber, detail) {
        switch (dayNumber) {
            case 10: {
                this.Day10 = detail;
                break;
            }
            case 15: {
                this.Day15 = detail;
                break;
            }
            case 20: {
                this.Day20 = detail;
                break;
            }
            case 25: {
                this.Day25 = detail;
                break;
            }
            case 30: {
                this.Day30 = detail;
                break;
            }
            case 35: {
                this.Day35 = detail;
                break;
            }
            case 40: {
                this.Day40 = detail;
                break;
            }
            case 45: {
                this.Day45 = detail;
                break;
            }
            case 50: {
                this.Day50 = detail;
                break;
            }
            case 55: {
                this.Day55 = detail;
                break;
            }
            case 60: {
                this.Day60 = detail;
                break;
            }
            case 65: {
                this.Day65 = detail;
                break;
            }
            case 70: {
                this.Day70 = detail;
                break;
            }
            case 75: {
                this.Day75 = detail;
                break;
            }
        }
    }
}

class TreatmentRecipeView {
    constructor(data) {
        this.id = -1;
        data = data ?? {};
        this.id = data.id ?? -1;
        this.isNew = this.id < 0;
        this.isDeleted = false;
        this.isChanged = false;
        this.naam = data.description;
        this.stap = data.step;
        this.stapnummer = data.stepnumber;
        this.opmerking = data.remark;
        this.wachttijd = data.afterstep;
        this.status = data.status;
    }
}
class TreatmentActivity {
    constructor(data) {
        this.id = -1;
        data = data ?? {};
        this.id = data.id ?? -1;
        this.isNew = this.id < 0;
        this.isDeleted = false;
        this.isChanged = false;
        this.gewijzigd = data.updated;

        this.schema = data.recipe;
        this.schemaID = data.recipeid;
        this.batchnummer = data.batchnumber;
        this.startaantal = data.entitiesasstart;
        this.uitvalaantal = data.failures; // nog niet
        this.starttijd = data.from;
        this.starttijdplan = data.fromplanned;
        this.locatie = data.location;
        this.product = data.product;
        this.coatingproduct = data.coating;
        this.coatingproductID = data.coatingid;
        this.behandelstapID = data.treatmentStepID;

        this.opmerking = data.remark;
        this.stapnummer = data.sequencenumber;
        this.vorm = data.shape;
        this.statustekst = data.status;
        this.statusnummer = data.statusnumber;
        this.voorraaditem = data.stockitemid;
        this.behandeling = data.treatment;

        this.herplannen = false;
    }

    get regeltekst() {
        return this.productnaam;
    }
    CheckValidity(func) {
        let warnText = '';
        if (this.wachttijd) {
            if (this.wachttijd == undefined || this.wachttijd < 0)
                warnText = Helper.concatString(
                    warnText,
                    func('com_isrequired', {
                        ns: 'common',
                        val: '',
                        prop: func('prod_wachttijd', { ns: 'production' })
                    }) +' / '+ func('prod_meerdannul', {
                        ns: 'production',
                        val: func('prod_wachttijd', { ns: 'production' })
                    }) 
                );
        }
        return warnText;
    }

    // SetForSave() { // todo???
    //     return {
    //         Amount: this.aantal,
    //         StockItemID: this.itemstockID > -1 ? this.itemstockID : this.id,
    //         BookDate: DateHelper.localDateToUTC(this.boektijdstip)
    //     };
    // }

    SetForConfirm() {
        const ConfirmTreatmentActivityModel = {
            ID: this.id,
            Remark: this.opmerking,
            CoatingStockItemID: this.voorraaditem,
            ReplanActivities: this.herplannen,
            TreatedAt: DateHelper.localDateToUTC(this.starttijd),
            Updated: DateHelper.localDateToUTC(this.gewijzigd)
        };
        return ConfirmTreatmentActivityModel;
    }
}

class TacStatusView {
    /**
     * @param data
     */
    constructor(data) {
        data = data ?? {};
        this.id = data.id ?? -1;
        this.batchnummer = data.batchnumer;
        this.productiedatum = data.productiondate;
        this.product = data.product;
        this.vorm = data.shape;
        this.locatie = data.location;
        this.aantal = data.amount;
        this.gemaakt = data.created; //??
        this.gepland = data.planned; // nog gepland
        this.inbehandeling = data.busy;
        this.gedaan = data.done;
        this.nietgedaan = data.not_treated;
        this.totaal = data.total;
    }
}
